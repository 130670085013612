import { useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'store';
import { updateOrganisationPlanLayoutPartially } from 'store/organisation-plan-template/organisation-plan-template.slice';
import { OrganisationPlanDividerWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanDividerWidgetType';

interface IPreviewDividerOrganisationPlan {
  element: OrganisationPlanDividerWidgetType;
}

const PreviewDividerOrganisationPlan = ({ element }: IPreviewDividerOrganisationPlan) => {
  const dispatch = useDispatch();
  const { widgetId, sectionId } = element;
  useEffect(() => {
    dispatch(updateOrganisationPlanLayoutPartially({ layout: { i: widgetId, isResizable: false }, sectionId: sectionId ? sectionId : '' }));
    return () => {
      dispatch(
        updateOrganisationPlanLayoutPartially({ layout: { i: widgetId, isResizable: true }, sectionId: sectionId ? sectionId : '' })
      );
    };
  }, [dispatch, sectionId, widgetId]);

  return <Box />;
};

export default PreviewDividerOrganisationPlan;
