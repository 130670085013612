import { GRID_LAYOUT_SIZE } from 'modules/builder/constants/builder';

export const getWidgetRowsAmount = (widgetOffsetHeight: number) => {
  const numberOfRows = (widgetOffsetHeight + GRID_LAYOUT_SIZE.rowGap) / (GRID_LAYOUT_SIZE.rowHeight + GRID_LAYOUT_SIZE.rowGap);
  return Math.floor(numberOfRows + 1);
};

interface IFormatMetadata {
  privacyType: string;
  userName: string;
  link: string;
  downloadLink: string;
}
export const formatMetadata = ({ privacyType, downloadLink, userName, link }: IFormatMetadata) => {
  return JSON.stringify({
    privacyType,
    userName,
    link,
    downloadLink
  });
};
