import { alpha, Box, useTheme } from '@mui/material';

interface IDragHandleProps {
  isDraggable?: boolean;
}

const DragHandle = ({ isDraggable }: IDragHandleProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'absolute',
        top: -5,
        left: -5,
        width: 'calc(100% + 10px)',
        height: 'calc(100% + 10px)',
        zIndex: 12,
        pointerEvents: 'none', // Pass through clicks
        '&:hover .edge': {
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
          transition: 'background-color 0.1s ease-in-out 0.5s',
          cursor: isDraggable ? ['-webkit-grab', '-moz-grab', 'grab'] : 'default'
        }
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '10px',
          pointerEvents: 'auto', // Enable interactions on the edge
          borderRadius: '6px 6px 0 0'
        }}
        className="edge on-draggable"
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '10px',
          pointerEvents: 'auto',
          borderRadius: '0 0 6px 6px'
        }}
        className="edge on-draggable"
      />
      <Box
        sx={{
          position: 'absolute',
          top: 10,
          bottom: 0,
          left: 0,
          width: '10px',
          height: 'calc(100% - 20px)',
          pointerEvents: 'auto'
        }}
        className="edge on-draggable"
      />
      <Box
        sx={{
          position: 'absolute',
          top: 10,
          bottom: 0,
          right: 0,
          width: '10px',
          height: 'calc(100% - 20px)',
          pointerEvents: 'auto'
        }}
        className="edge on-draggable"
      />
    </Box>
  );
};

export default DragHandle;
