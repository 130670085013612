import { lazy } from 'react';
import Loadable from 'shared/components/Loadable';
import GuestGuard from 'shared/components/route-guard/GuestGuard';
import CommonLayout from 'shared/layouts/CommonLayout';
import { CREATE_REQUEST_URL } from './constants';

const Login = Loadable(lazy(() => import('pages/Auth/Login')));
const LoginWithCompany = Loadable(lazy(() => import('pages/Auth/LoginWithCompany')));
const ForgotPassword = Loadable(lazy(() => import('pages/Auth/ForgotPassword')));
const CheckMail = Loadable(lazy(() => import('pages/Auth/CheckMail')));
const CheckMailSelfRegistration = Loadable(lazy(() => import('pages/Auth/CheckMailSelfRegistration')));
const ResetPassword = Loadable(lazy(() => import('pages/Auth/ResetPassword')));
const CodeVerification = Loadable(lazy(() => import('pages/Auth/CodeVerification')));
const LoginAdmin = Loadable(lazy(() => import('pages/Auth/LoginAdmin')));
const CreatePassword = Loadable(lazy(() => import('pages/Auth/CreatePassword')));
const CreateRequestByCustomer = Loadable(lazy(() => import('pages/Auth/CreateRequestByCustomer')));

// ==============================|| AUTH ROUTING ||============================== //

export const AuthRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: '/',
          element: <LoginWithCompany />
        },
        {
          path: 'login/:companyName',
          element: <Login />
        },
        {
          path: 'login-admin',
          element: <LoginAdmin />
        },
        {
          path: 'forgot-password',
          element: <ForgotPassword />
        },
        {
          path: 'forgot-password/:companyName',
          element: <ForgotPassword />
        },
        {
          path: 'check-mail',
          element: <CheckMail />
        },
        {
          path: 'check-mail/:companyName',
          element: <CheckMail />
        },
        {
          path: `${CREATE_REQUEST_URL}/check-mail/:companyName`,
          element: <CheckMailSelfRegistration />
        },
        {
          path: 'password/reset',
          element: <ResetPassword />
        },
        {
          path: 'code-verification',
          element: <CodeVerification />
        },
        {
          path: 'create-password',
          element: <CreatePassword />
        },
        {
          path: `${CREATE_REQUEST_URL}/:companyName/:slug`,
          element: <CreateRequestByCustomer />
        }
      ]
    }
  ]
};
