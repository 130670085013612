import { useInfiniteQuery } from 'react-query';
import { useMemo } from 'react';
import axios from 'axios';
import { IEventManager } from 'shared/types/event';

interface IEventManagersParams {
  data: IEventManager[];
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
}

const fetchAllEventManagers = async (page = 1, searchManagers: string) => {
  return axios
    .get(`${import.meta.env.VITE_REACT_APP_API_URL}/dashboards/filters/events/managers`, {
      params: {
        search: searchManagers,
        pageNumber: page,
        pageSize: 1000
      }
    })
    .then((res) => res.data);
};

export const useGetAllEventManagersInfinite = (searchManagers: string) => {
  const query = useInfiniteQuery({
    queryKey: ['all-event-managers', searchManagers],
    queryFn: async ({ pageParam = 1 }) => await fetchAllEventManagers(pageParam, searchManagers),
    getNextPageParam: (lastPage: IEventManagersParams, allPages: IEventManagersParams[]) => {
      const MAX_USERS = Math.ceil(lastPage.totalRecords / 10);
      if (allPages.length < MAX_USERS) {
        return allPages.length + 1;
      } else {
        return undefined;
      }
    }
  });

  const data = useMemo(() => {
    return query.data?.pages.map((x) => x.data).flat(1);
  }, [query.data?.pages]);

  return { ...query, data, query };
};
