interface ITableModalDataTest {
  primaryColumnLabel: string;
  primaryColumn: string;
  primaryColumnIntlId: string;
  columns: { label: string; name: string; intlId: string }[];
}

export const TableModalDataTest: ITableModalDataTest[] = [
  {
    primaryColumnLabel: 'Event',
    primaryColumn: 'eventName',
    primaryColumnIntlId: 'invoices.table-column-event',
    columns: [
      { label: 'Event Date', intlId: 'events.event-Date', name: 'eventDate' },
      { label: 'Due Date', intlId: 'dashboard-table.due-date', name: 'dueDate' },
      { label: 'Venue', intlId: 'settings.venue', name: 'venue' },
      { label: 'Number of Guests', intlId: 'dashboard-table.number-of-guests', name: 'numberOfGuests' },
      { label: 'Questionnaire Revenue', intlId: 'dashboard-table.questionnaire-revenue', name: 'questionnaireRevenue' },
      { label: 'Event State', intlId: 'dashboard-table.event-state', name: 'eventState' },
      { label: 'Invoice Amount', intlId: 'dashboard-table.invoice-amount', name: 'invoiceAmount' },
      { label: 'Questionnaire', intlId: 'events.questionnaire', name: 'questionnaire' },
      { label: 'Event-Manager', intlId: 'tenants.event-manager', name: 'eventManager' },
      { label: 'Event type', intlId: 'events.event-type', name: 'eventType' },
      { label: 'Customer', intlId: 'tenants.customer', name: 'customer' },
      { label: 'Day of creation', intlId: 'dashboard-table.day-of-creation', name: 'dayOfCreation' },
      { label: 'Pre-payment', intlId: 'dashboard-table.pre-payment', name: 'invoicePrePaymentAmount' }
    ]
  },
  {
    primaryColumnLabel: 'Venue',
    primaryColumn: 'venue',
    primaryColumnIntlId: 'settings.venue',
    columns: [
      { label: 'Number of Guests', intlId: 'dashboard-table.number-of-guests', name: 'numberOfGuests' },
      { label: 'Questionnaire Revenue', intlId: 'dashboard-table.questionnaire-revenue', name: 'questionnaireRevenue' },
      { label: 'Invoice Amount', intlId: 'dashboard-table.invoice-amount', name: 'invoiceAmount' },
      { label: 'Number of Events', intlId: 'dashboard-table.number-of-events', name: 'numberOfEvents' },
      { label: 'Pre-payment', intlId: 'dashboard-table.pre-payment', name: 'invoicePrePaymentAmount' }
    ]
  },
  {
    primaryColumnLabel: 'Event Type',
    primaryColumn: 'eventType',
    primaryColumnIntlId: 'events.event-type',
    columns: [
      { label: 'Number of Guests', intlId: 'dashboard-table.number-of-guests', name: 'numberOfGuests' },
      { label: 'Questionnaire Revenue', intlId: 'dashboard-table.questionnaire-revenue', name: 'questionnaireRevenue' },
      { label: 'Invoice Amount', intlId: 'dashboard-table.invoice-amount', name: 'invoiceAmount' },
      { label: 'Number of Events', intlId: 'dashboard-table.number-of-events', name: 'numberOfEvents' },
      { label: 'Pre-payment', intlId: 'dashboard-table.pre-payment', name: 'invoicePrePaymentAmount' }
    ]
  },
  {
    primaryColumnLabel: 'Questionnaire',
    primaryColumn: 'questionnaire',
    primaryColumnIntlId: 'events.questionnaire',
    columns: [
      { label: 'Number of Guests', intlId: 'dashboard-table.number-of-guests', name: 'numberOfGuests' },
      { label: 'Questionnaire Revenue', intlId: 'dashboard-table.questionnaire-revenue', name: 'questionnaireRevenue' },
      { label: 'Invoice Amount', intlId: 'dashboard-table.invoice-amount', name: 'invoiceAmount' },
      { label: 'Number of Events', intlId: 'dashboard-table.number-of-events', name: 'numberOfEvents' },
      { label: 'Pre-payment', intlId: 'dashboard-table.pre-payment', name: 'invoicePrePaymentAmount' }
    ]
  },
  {
    primaryColumnLabel: 'Event-Manager',
    primaryColumn: 'eventManager',
    primaryColumnIntlId: 'tenants.event-manager',
    columns: [
      { label: 'Venue', intlId: 'settings.venue', name: 'venue' },
      { label: 'Number of Guests', intlId: 'dashboard-table.number-of-guests', name: 'numberOfGuests' },
      { label: 'Questionnaire Revenue', intlId: 'dashboard-table.questionnaire-revenue', name: 'questionnaireRevenue' },
      { label: 'Invoice Amount', intlId: 'dashboard-table.invoice-amount', name: 'invoiceAmount' },
      { label: 'Day of creation', intlId: 'dashboard-table.day-of-creation', name: 'dayOfCreation' },
      { label: 'Number of Events', intlId: 'dashboard-table.number-of-events', name: 'numberOfEvents' },
      { label: 'Pre-payment', intlId: 'dashboard-table.pre-payment', name: 'invoicePrePaymentAmount' }
    ]
  },
  {
    primaryColumnLabel: 'Event State',
    primaryColumn: 'eventState',
    primaryColumnIntlId: 'dashboard-table.event-state',
    columns: [
      { label: 'Number of Guests', intlId: 'dashboard-table.number-of-guests', name: 'numberOfGuests' },
      { label: 'Questionnaire Revenue', intlId: 'dashboard-table.questionnaire-revenue', name: 'questionnaireRevenue' },
      { label: 'Invoice Amount', intlId: 'dashboard-table.invoice-amount', name: 'invoiceAmount' },
      { label: 'Number of Events', intlId: 'dashboard-table.number-of-events', name: 'numberOfEvents' },
      { label: 'Pre-payment', intlId: 'dashboard-table.pre-payment', name: 'invoicePrePaymentAmount' }
    ]
  },
  {
    primaryColumnLabel: 'Month',
    primaryColumn: 'month',
    primaryColumnIntlId: 'dashboard-table.month',
    columns: [
      { label: 'Number of Guests', intlId: 'dashboard-table.number-of-guests', name: 'numberOfGuests' },
      { label: 'Questionnaire Revenue', intlId: 'dashboard-table.questionnaire-revenue', name: 'questionnaireRevenue' },
      { label: 'Invoice Amount', intlId: 'dashboard-table.invoice-amount', name: 'invoiceAmount' },
      { label: 'Number of Events', intlId: 'dashboard-table.number-of-events', name: 'numberOfEvents' },
      { label: 'Pre-payment', intlId: 'dashboard-table.pre-payment', name: 'invoicePrePaymentAmount' }
    ]
  },
  {
    primaryColumnLabel: 'Year',
    primaryColumn: 'year',
    primaryColumnIntlId: 'dashboard-table.year',
    columns: [
      { label: 'Number of Guests', intlId: 'dashboard-table.number-of-guests', name: 'numberOfGuests' },
      { label: 'Questionnaire Revenue', intlId: 'dashboard-table.questionnaire-revenue', name: 'questionnaireRevenue' },
      { label: 'Invoice Amount', intlId: 'dashboard-table.invoice-amount', name: 'invoiceAmount' },
      { label: 'Number of Events', intlId: 'dashboard-table.number-of-events', name: 'numberOfEvents' },
      { label: 'Pre-payment', intlId: 'dashboard-table.pre-payment', name: 'invoicePrePaymentAmount' }
    ]
  },
  {
    primaryColumnLabel: 'Invoice',
    primaryColumn: 'invoice',
    primaryColumnIntlId: 'events.invoice',
    columns: [
      { label: 'Event', intlId: 'dashboard-table.event', name: 'eventName' },
      { label: 'Event Date', intlId: 'events.event-Date', name: 'eventDate' },
      { label: 'Venue', intlId: 'settings.venue', name: 'venue' },
      { label: 'Number of Guests', intlId: 'dashboard-table.number-of-guests', name: 'numberOfGuests' },
      { label: 'Questionnaire Revenue', intlId: 'dashboard-table.questionnaire-revenue', name: 'questionnaireRevenue' },
      { label: 'Event State', intlId: 'dashboard-table.event-state', name: 'eventState' },
      { label: 'Questionnaire', intlId: 'events.questionnaire', name: 'questionnaire' },
      { label: 'Event-Manager', intlId: 'tenants.event-manager', name: 'eventManager' },
      { label: 'Event type', intlId: 'events.event-type', name: 'eventType' },
      { label: 'Customer', intlId: 'tenants.customer', name: 'customer' },
      { label: 'Invoice ID-Number', intlId: 'dashboard-table.invoice-id-number', name: 'invoiceId' },
      { label: 'Invoice Amount', intlId: 'dashboard-table.invoice-amount', name: 'invoiceAmount' },
      { label: 'Day of creation', intlId: 'dashboard-table.day-of-creation', name: 'dayOfCreation' },
      { label: 'Number of Events', intlId: 'dashboard-table.number-of-events', name: 'numberOfEvents' },
      { label: 'Pre-payment', intlId: 'dashboard-table.pre-payment', name: 'invoicePrePaymentAmount' }
    ]
  },
  {
    primaryColumnLabel: 'Invoice State',
    primaryColumn: 'invoiceState',
    primaryColumnIntlId: 'dashboard-table.invoice-state',
    columns: [
      { label: 'Number of Guests', intlId: 'dashboard-table.number-of-guests', name: 'numberOfGuests' },
      { label: 'Questionnaire Revenue', intlId: 'dashboard-table.questionnaire-revenue', name: 'questionnaireRevenue' },
      { label: 'Invoice Amount', intlId: 'dashboard-table.invoice-amount', name: 'invoiceAmount' },
      { label: 'Pre-payment', intlId: 'dashboard-table.pre-payment', name: 'invoicePrePaymentAmount' }
    ]
  }
];
