import { configureStore, EnhancedStore } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import { TypedUseSelectorHook, useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { combineReducers } from 'redux';
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import builder from './builder/builder.slice';
import dashboard from './dashboard/dashboard.slice';
import menu from './menu';
import organisationPlanTemplateBuilder from './organisation-plan-template/organisation-plan-template.slice';
import pagination from './pagination/pagination.slice';
import profileReducer from './profile/profile.reducer';
import snackbar from './snackbar';
enableMapSet();
const reducer = combineReducers({
  menu,
  snackbar,
  pagination,
  builder,
  dashboard,
  organisationPlanTemplateBuilder,
  profile: profileReducer
});

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

const persister = persistStore(store as EnhancedStore);

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();
const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { dispatch, persister, store, useDispatch, useSelector };
