import { Delete20Regular } from '@fluentui/react-icons';
import { Draggable } from '@hello-pangea/dnd';
import { TableCell, TextField, useTheme } from '@mui/material';
import { FieldArrayRenderProps } from 'formik';
import { bruttoArticle } from 'modules/articles/utils/pricingCalc';
import { EEditorArticleItemType, IEditorArticle } from 'modules/editor/types/editor';
import {
  calculateAmount,
  calculateBruttoIfChangedNetto,
  calculateNettoIfChangedBrutto,
  getNameOfPriceField,
  getNameOfTotalPriceField
} from 'modules/invoices/utils/calculateHelpers';
import FormatPrice from 'modules/pricing-sidebar/components/FormatPrice';
import { TO_FIXED_FOR_PRICE } from 'modules/pricing-sidebar/constants/inputs';
import { QuestionnaireTaxType } from 'modules/questionnaires/types';
import { ComponentType } from 'react';
import { useIntl } from 'react-intl';
import NumericInput from 'shared/components/NumberInputField';
import { EArticlesValueIOfferEdit, IOfferArticleBaseAndOptionalType, IOfferEditor } from 'shared/types/offer';
import { skipEnterInTextField } from 'shared/utils/form';
import * as Styles from './TableDynamicRow.styles';

interface ICustomInputHanlerParams {
  name: string;
  value: string | number;
  article: IOfferArticleBaseAndOptionalType;
  articleIndex: number;
  articleItemIndex: number;
  taxType: QuestionnaireTaxType;
}

interface ITableDynamicRow {
  currencySign: string | undefined;
  nameFields: EArticlesValueIOfferEdit;
}

const TableDynamicRow: ComponentType<(FieldArrayRenderProps & ITableDynamicRow) | void> = (props) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  if (!props?.push) {
    return null;
  }
  const {
    form: { handleChange, setFieldValue },
    currencySign,
    nameFields,
    ...arrayHelpers
  } = props;
  const values: IOfferEditor = props.form.values as IOfferEditor;

  const customeInputHandler = ({ name, value, article, articleItemIndex, taxType, articleIndex }: ICustomInputHanlerParams) => {
    const item = article.priceItems[articleItemIndex];
    const newBrutto = calculateBruttoIfChangedNetto(name, value, taxType, article?.tax);
    const newNetto = calculateNettoIfChangedBrutto(name, value, taxType, article?.tax);
    const updatedItem = {
      ...item,
      [name]: value,
      ...newNetto,
      ...newBrutto
    };
    const newArticleItems = article.priceItems.map((articleItem, index) => (index === articleItemIndex ? updatedItem : articleItem));
    const newArticle: IOfferArticleBaseAndOptionalType = {
      ...article,
      totalNet: calculateAmount(newArticleItems, QuestionnaireTaxType.Net),
      totalGross: calculateAmount(newArticleItems, QuestionnaireTaxType.Gross),
      priceItems: newArticleItems
    };
    setFieldValue(`${nameFields}.${articleIndex}`, newArticle);
  };

  const numberInputTaxHandler = ({
    value,
    article,
    articleIndex
  }: {
    value: string | number;
    article?: IOfferArticleBaseAndOptionalType;
    articleIndex: number;
  }) => {
    const newPriceItemsArticle =
      article?.priceItems?.map((item) => ({
        ...item,
        priceBrutto: bruttoArticle(item.priceNetto ?? 0, !!value ? Number(value) : 0)
      })) ?? [];
    setFieldValue(`${props.nameFields}.${articleIndex}`, {
      ...article,
      priceItems: newPriceItemsArticle,
      tax: value,
      totalNet: calculateAmount(newPriceItemsArticle, QuestionnaireTaxType.Net),
      totalGross: calculateAmount(newPriceItemsArticle, QuestionnaireTaxType.Gross)
    } as IEditorArticle);
  };

  const priceFieldName = getNameOfPriceField(values.taxType);
  const totalPriceFieldName = getNameOfTotalPriceField(values.taxType);

  return (
    <>
      {values?.[nameFields]?.map((article, index) => (
        <Draggable key={article.id ?? article._innerId} draggableId={article.id ? `${article.id}` : `${article._innerId}`} index={index}>
          {(provided) => (
            <Styles.TableRow
              key={article.id ?? article._innerId}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <TableCell>
                <Styles.Box>
                  <Styles.Typography>{index + 1}</Styles.Typography>
                </Styles.Box>
              </TableCell>
              <TableCell sx={{ minWidth: 350 }}>
                <TextField
                  sx={{ backgroundColor: theme.palette.background.paper, borderRadius: '6px' }}
                  onKeyDown={skipEnterInTextField}
                  fullWidth
                  name={`${nameFields}.${index}.name`}
                  value={values[nameFields as EArticlesValueIOfferEdit]?.[index]?.name}
                  onChange={handleChange}
                  placeholder={formatMessage({ id: 'invoices.invoice-editor-description-placeholder' })}
                />
              </TableCell>
              <TableCell sx={{ width: 80, minWidth: 80 }}>
                <NumericInput
                  isDecimal
                  maxDecimalDigits={2}
                  showDecimalZeros
                  textAlign="right"
                  name={`${nameFields}.${index}.tax`}
                  value={values[nameFields as EArticlesValueIOfferEdit]?.[index]?.tax ?? 0}
                  onHandleInputChange={(value) =>
                    numberInputTaxHandler({ value, article: article as IOfferArticleBaseAndOptionalType, articleIndex: index })
                  }
                  startAdornment={'%'}
                />
              </TableCell>
              <Styles.TableCellNestedRow>
                {article?.priceItems?.map(
                  (item: any, itemIndex: number) =>
                    item?.type !== EEditorArticleItemType.SecondUnit && (
                      <Styles.NestedCellBox key={item.id ?? itemIndex} isMarginTopUsed={!!itemIndex} sx={{ minWidth: 155 }}>
                        <NumericInput
                          textAlign="right"
                          showDecimalZeros
                          name={priceFieldName}
                          maxDecimalDigits={TO_FIXED_FOR_PRICE}
                          onHandleInputChange={(value) =>
                            customeInputHandler({
                              value,
                              name: priceFieldName,
                              article: article as IOfferArticleBaseAndOptionalType,
                              articleIndex: index,
                              articleItemIndex: itemIndex,
                              taxType: values.taxType
                            })
                          }
                          isCurrency
                        />
                      </Styles.NestedCellBox>
                    )
                )}
              </Styles.TableCellNestedRow>
              <Styles.TableCellNestedRow>
                {article?.priceItems?.map((item: any, itemIndex: number) => (
                  <Styles.NestedCellBox key={item.id ?? itemIndex} isMarginTopUsed={!!itemIndex} sx={{ minWidth: 220 }}>
                    <TextField
                      sx={{ backgroundColor: theme.palette.background.paper, borderRadius: '6px' }}
                      onKeyDown={skipEnterInTextField}
                      fullWidth
                      onChange={handleChange}
                      name={`${nameFields}.${index}.priceItems.${itemIndex}.unit`}
                      value={item.unit}
                      placeholder={formatMessage({ id: 'invoices.invoice-editor-unit-placeholder' })}
                    />
                  </Styles.NestedCellBox>
                ))}
              </Styles.TableCellNestedRow>
              <Styles.TableCellNestedRow sx={{ width: 108 }}>
                {article?.priceItems?.map((item: any, itemIndex: number) => (
                  <Styles.NestedCellBox key={item.id ?? itemIndex} isMarginTopUsed={!!itemIndex} sx={{ minWidth: 120 }}>
                    <NumericInput
                      showDecimalZeros
                      isDecimal
                      maxDecimalDigits={2}
                      textAlign="right"
                      name="count"
                      value={item.count ?? 0}
                      onHandleInputChange={(value) =>
                        customeInputHandler({
                          value,
                          name: 'count',
                          article: article as IOfferArticleBaseAndOptionalType,
                          articleIndex: index,
                          articleItemIndex: itemIndex,
                          taxType: values.taxType
                        })
                      }
                      allowNegative
                    />
                  </Styles.NestedCellBox>
                ))}
              </Styles.TableCellNestedRow>
              <TableCell sx={{ minWidth: 170 }}>
                <Styles.Typography sx={{ textAlign: 'right' }}>
                  <FormatPrice value={article[totalPriceFieldName] ?? 0} isHideSymbol />
                </Styles.Typography>
              </TableCell>
              <TableCell>
                <Styles.IconButton onClick={arrayHelpers.handleRemove(index)} color="error">
                  <Delete20Regular />
                </Styles.IconButton>
              </TableCell>
            </Styles.TableRow>
          )}
        </Draggable>
      ))}
    </>
  );
};

export default TableDynamicRow;
