import { Box, Button, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const WrapperContent = styled(Box)(() => ({
  position: 'relative',
  '& .MuiDivider-root.p-bottom': {
    position: 'absolute',
    bottom: 0,
    width: 'calc(100% + 55px)',
    left: '-33px'
  }
}));

export const HeaderContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
}));

export const ButtonStack = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  margin: 0
}));

export const ButtonImport = styled(Button)(() => ({
  lineHeight: 1,
  minHeight: '41px',
  color: '#000000'
}));

export const BlockTooltip = styled(Box)(() => ({
  position: 'relative'
}));
