import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IEmailTemplateVariable,
  useGetEmailTemplateDataByType,
  useGetEmailTemplateVariablesByType
} from 'api/hooks/email-templates/useGetEmailTemplates';
import { EMAIL_TEMPLATES } from 'modules/settings/components/EmailTemplates/constants';

interface IUseGetEmailTemplate {
  code?: string;
}

interface IuseEmailTemplateData {
  isEditorShown?: boolean;
  subject: string;
  template: string;
  variables?: IEmailTemplateVariable[];
  setSubject?: (data: string) => void;
  setTemplate?: (data: string) => void;
}

const useEmailTemplateData = ({ code }: IUseGetEmailTemplate): IuseEmailTemplateData => {
  const [isEditorShown, setEditorShown] = useState(false);
  const [subject, setSubject] = useState('');
  const [template, setTemplate] = useState('');

  const { data: emailTemplateBEData } = useGetEmailTemplateDataByType({ type: code || '' });

  const { data: emailTemplateBEVariables } = useGetEmailTemplateVariablesByType({
    type: code || ''
  });

  const setDataToState = useCallback(() => {
    if (isEditorShown || !code) return;
    if (!!emailTemplateBEData?.status) {
      setSubject(!!emailTemplateBEData?.data?.subject ? emailTemplateBEData?.data?.subject : EMAIL_TEMPLATES[`${code}`]?.subject);
      setTemplate(!!emailTemplateBEData?.data?.template ? emailTemplateBEData?.data?.template : EMAIL_TEMPLATES[`${code}`]?.template);
      setEditorShown(true);
    }
  }, [emailTemplateBEData?.status, emailTemplateBEData?.data?.subject, emailTemplateBEData?.data?.template, isEditorShown, code]);

  useEffect(() => {
    setDataToState();
  }, [setDataToState]);

  const handleSubject = useCallback((data: string) => {
    setSubject(data);
  }, []);

  const handleTemplate = useCallback((data: string) => {
    setTemplate(data);
  }, []);

  return useMemo(
    () => ({
      isEditorShown,
      subject,
      template,
      variables: emailTemplateBEVariables,
      setSubject: handleSubject,
      setTemplate: handleTemplate
    }),
    [emailTemplateBEVariables, isEditorShown, subject, template, handleSubject, handleTemplate]
  );
};

export default useEmailTemplateData;
