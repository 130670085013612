import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';
import { BuildingHome20Regular } from '@fluentui/react-icons';
import { useGetVenuesFilter } from 'modules/dashboard/hooks/useGetVenuesFilter';
import { textError } from 'shared/utils/infoText';
import { useInfoMessage } from 'shared/hooks/useInfoMessage';
import { useUpdateWidgetById } from 'api/hooks/dashboard/useUpdateWidgetByID';
import { IUpdateWidgetStore } from 'modules/dashboard/components/widgets/TableDashboardWidget/components/UpperHeaderTable';
import { containsText } from 'modules/dashboard/utils';
import { WidgetsDashboardType } from 'modules/dashboard/types';
import GeneralFilter from './GeneralFilter';

interface IVenuesFilter {
  widget?: WidgetsDashboardType;
  isCustomize: boolean;
  updateWidgetFilters: ({ field, value }: IUpdateWidgetStore) => void;
}
const VenuesFilter = ({ widget, updateWidgetFilters, isCustomize }: IVenuesFilter) => {
  const { data: venuesData, hasNextPage, fetchNextPage } = useGetVenuesFilter('');
  const [searchText, setSearchText] = useState('');
  const { successMessage, errorMessage } = useInfoMessage();
  const intl = useIntl();
  const newArray =
    venuesData &&
    venuesData.map((item) => {
      return { value: item.id, label: item.name };
    });
  const { mutateAsync: updateWidgetById, isLoading } = useUpdateWidgetById({
    onSuccess() {
      successMessage(intl.formatMessage({ id: 'dashboard.dashboard-widget-edited-successfully' }));
    },
    options: {
      onError() {
        errorMessage(textError(intl.formatMessage({ id: 'dashboard.dashboard-widget-edited-failed' })));
      }
    },
    widgetId: widget?.id?.toString() || ''
  });

  const handleAllVenuesChange = async (data: (number | string)[]) => {
    if (!widget) return;

    // Check if the value has changed
    const hasValueChanged = !isEqual(widget.filters?.venues, data);

    if (!widget.id || isCustomize) {
      updateWidgetFilters({ field: 'venues', value: data });
    }

    if (widget.id && !isCustomize && hasValueChanged) {
      await updateWidgetById(
        {
          ...widget,
          filters: {
            ...widget.filters,
            venues: data as number[]
          },
          systemId: widget.systemId
        },
        {
          onSuccess: () => {
            updateWidgetFilters({ field: 'venues', value: data });
          }
        }
      );
    }
  };

  const onInputChange = (searchText: string) => {
    setSearchText(searchText);
  };

  const displayedOptions = useMemo(
    () => newArray && newArray.filter((option) => containsText(option.label, searchText)),
    [newArray, searchText]
  );

  return (
    <GeneralFilter
      onInputChange={onInputChange}
      isUpdated={isLoading}
      sx={{}}
      sxControl={{}}
      values={widget?.filters?.venues || []}
      onChange={handleAllVenuesChange}
      label={intl.formatMessage({ id: 'dashboard-table.all-venues' })}
      tooltipLabel={intl.formatMessage({ id: 'dashboard-table.filter-by-venues' })}
      icon={<BuildingHome20Regular />}
      textAllOptions={intl.formatMessage({ id: 'dashboard-table.all-venues' })}
      allOptions={displayedOptions || []}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
    />
  );
};

export default VenuesFilter;
