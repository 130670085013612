import { DragDropContext, DropResult, Droppable } from '@hello-pangea/dnd';
import { useDispatch } from 'store';
import { reorderSectionsByDnd } from 'store/organisation-plan-template/organisation-plan-template.slice';

interface ISectionDndContainer {}

const SectionDndContainer = ({ children }: React.PropsWithChildren<ISectionDndContainer>) => {
  const dispatch = useDispatch();

  const reorderSectionByDnd = (dropResult: DropResult) => {
    if (typeof dropResult?.destination?.index !== 'number') return;
    if (dropResult.destination.index === dropResult.source.index) return;
    const indexFrom = dropResult.source.index;
    const indexTo = dropResult.destination.index;
    dispatch(reorderSectionsByDnd({ indexFrom, indexTo }));
  };

  return (
    <DragDropContext onDragEnd={reorderSectionByDnd}>
      <Droppable droppableId="sections">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SectionDndContainer;
