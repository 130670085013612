import { Box, styled } from '@mui/material';

export const DashedContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  border: `1px dashed ${theme.palette.secondary.light}`,
  borderRadius: '6px',
  backgroundColor: theme.palette.secondary.lighter,
  '@media print': {
    backgroundColor: '#f5f5f5',
    border: `1px dashed #d9d9d9`
  }
}));
