import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormHelperText, Grid, InputLabel, Stack, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { useGetInvoiceNextNumber } from 'api/hooks/invoicesSettings/useGetInvoiceNextNumber';
import { IInvoiceDialogForm } from 'shared/types/invoice';
import { useAuth } from 'shared/contexts/AuthContext';
import InvoiceStatusSelect from './InvoiceStatusSelect';

const InvoiceModalNumberAndState = () => {
  const intl = useIntl();
  const { tokenInfo } = useAuth();
  const { values, errors, touched, setFieldValue, handleChange } = useFormikContext<IInvoiceDialogForm>();
  const { data } = useGetInvoiceNextNumber({ tennantId: tokenInfo?.tenant, enabled: !values?.id });

  useEffect(() => {
    if (data?.formattedNumber && !values?.id && !values?.number) {
      setFieldValue('number', data.formattedNumber, false);
    }
  }, [data, setFieldValue, values?.id, values?.number]);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25}>
          <InputLabel htmlFor="invoice-number">
            <FormattedMessage id="invoices.modal-field-serial-number" />
          </InputLabel>
          <TextField
            InputProps={{
              readOnly: true
            }}
            id="invoice-number"
            fullWidth
            value={values.number}
            name="number"
            placeholder={intl.formatMessage({ id: 'settings.enter' })}
          />
          <FormHelperText id="invoice-number-helper">
            <FormattedMessage id="invoices.modal-field-readonly" />
          </FormHelperText>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25}>
          <InputLabel htmlFor="state">
            <FormattedMessage id="invoices.modal-field-state" />
          </InputLabel>
          <InvoiceStatusSelect
            name="state"
            value={values?.state}
            isSentEmail={!!values?.isSentEmail}
            handleChange={handleChange}
            readOnly={!values?.id}
          />
          {!values?.id && (
            <FormHelperText id="invoice-state-helper">
              <FormattedMessage id="invoices.modal-field-readonly" />
            </FormHelperText>
          )}
          {touched?.state && errors?.state && (
            <FormHelperText error id="invoice-state-helper">
              {errors.state}
            </FormHelperText>
          )}
        </Stack>
      </Grid>
    </>
  );
};

export default InvoiceModalNumberAndState;
