import { ILinkObjectForSelect } from 'modules/builder/components/SettingsBar/LinkingTab/types';
import { BUILDER_WIDGET_TYPES, BuilderPageItemType, WidgetsType } from 'modules/builder/types';
import { MultipleChoiceWidgetType, SingleChoiceWidgetType } from 'modules/builder/types/choiceWidgetsType';
import { MultipleImgChoiceWdigetType, SingleImgChoiceWidgetType } from 'modules/builder/types/imageWidgetsTypes';
import { LinkingAction } from 'shared/types/questioner-template-link';

interface ILinkWithWidget extends ILinkObjectForSelect {
  widget: WidgetsType;
}
/**
 * Util function that extract widget that used as a source for link, get it from page entity;
 * @param pages - array of pages.
 * @param links - array of link rules.
 */
const getSourceWidgetFromPage = (page: BuilderPageItemType | undefined, link: ILinkObjectForSelect): ILinkWithWidget | null => {
  if (page?.usedWidgets) {
    const widget = page.usedWidgets.find((widget) => widget.widgetId === link.source.widgetId);
    return {
      ...link,
      widget: widget
    } as ILinkWithWidget;
  }
  return null;
};

/**
 * Util function to extend link entity with according widget that used as a source for link;
 * @param pages - array of pages.
 * @param links - array of link rules.
 */
export const getLinkWithWidgets = (pages: BuilderPageItemType[], links: ILinkObjectForSelect[]): ILinkWithWidget[] => {
  return links
    .map((link) => {
      const source = link.source;
      const pageWithLink = pages.find((page) => page.systemId === source.pageSystemId);

      if (source.subPageSystemId && pageWithLink?.childPages) {
        const subPageWithLink = pageWithLink.childPages.find((subPage) => subPage.systemId === source.subPageSystemId);
        return getSourceWidgetFromPage(subPageWithLink, link);
      } else {
        return getSourceWidgetFromPage(pageWithLink, link);
      }
    })
    .filter((link) => !!link?.widget?.widgetId) as ILinkWithWidget[];
};

/**
 * Util function that compares user answers to link rule
 * @param linksWithWidgets - extended link entity with widget that used as a source inside.
 */
export const compareUserAnswerToLinkRule = (linksWithWidgets: ILinkWithWidget[]) => {
  return linksWithWidgets
    .map((linkObj) => {
      const isWidgetTypeSingleChoice =
        linkObj.widget.type === BUILDER_WIDGET_TYPES.singleChoice || linkObj.widget.type === BUILDER_WIDGET_TYPES.singleImageChoice;
      const isWidgetTypeMultipleChoice =
        linkObj.widget.type === BUILDER_WIDGET_TYPES.multipleChoice || linkObj.widget.type === BUILDER_WIDGET_TYPES.multipleImageChoice;

      if (isWidgetTypeSingleChoice) {
        const widget = linkObj.widget as SingleChoiceWidgetType | SingleImgChoiceWidgetType;
        if (linkObj?.source?.optionId === widget.selectedOption) {
          return linkObj.action;
        }
      }
      if (isWidgetTypeMultipleChoice) {
        const widget = linkObj.widget as MultipleChoiceWidgetType | MultipleImgChoiceWdigetType;
        if (linkObj?.source?.optionId && widget.selectedOptions.includes(linkObj?.source?.optionId)) {
          return linkObj.action;
        }
      }
      return null;
    })
    .filter((action) => !!action) as LinkingAction[];
};
interface IaddCurrentPageWidgetsToAllPagesParams {
  builderPages?: BuilderPageItemType[];
  currentPageId?: string;
  currentSubPageId?: string;
  usedWidgetsOnCurrentPage: WidgetsType[];
}

/**
 * Util function that replace widget on some page to widget from app state with answers
 * @param builderPages - array of pages.
 * @param currentPageId - id of current page.
 * @param currentSubPageId - id of current sub page.
 * @param usedWidgetsOnCurrentPage - widgets from store.
 */
const addCurrentPageWidgetsToAllPages = ({
  builderPages,
  currentPageId,
  currentSubPageId,
  usedWidgetsOnCurrentPage
}: IaddCurrentPageWidgetsToAllPagesParams) => {
  return builderPages?.map((page) => {
    if (page.id === currentPageId) {
      if (currentSubPageId) {
        return {
          ...page,
          childPages: page.childPages
            ? page.childPages?.map((subPage) => {
                if (subPage.id === currentSubPageId) {
                  return {
                    ...subPage,
                    usedWidgets: usedWidgetsOnCurrentPage
                  };
                }
                return subPage;
              })
            : null
        };
      }
      return {
        ...page,
        usedWidgets: usedWidgetsOnCurrentPage
      };
    }
    return page;
  });
};
/**
 * This util function creates copy of existing array of widgets, and update `isHidden` widget
 * flag according linking rules.
 * @param widgets array of widgets;
 * @param links array of linking rules;
 * @param allPages array of all pages, because widget can have link from previous page;
 * @param pageId id of current page
 * @param subPageId id of curren sub page
 * @returns array of widgets
 */
const applyLinkingRulesOnWidgets = (
  widgets: WidgetsType[],
  links: ILinkObjectForSelect[],
  allPages: BuilderPageItemType[],
  pageId: string,
  subPageId: string | undefined
): WidgetsType[] => {
  const newWidgetsForCurrentPage = [...widgets];
  newWidgetsForCurrentPage.forEach((widget, indx, array) => {
    const linkRulesForWidget = links.filter((link) => link.target.widgetId === widget.widgetId);
    if (!linkRulesForWidget.length) return;
    const newAllPages = addCurrentPageWidgetsToAllPages({
      builderPages: allPages,
      currentPageId: pageId,
      currentSubPageId: subPageId,
      usedWidgetsOnCurrentPage: array
    });
    const sourceLinksWithWidget = newAllPages ? getLinkWithWidgets(newAllPages, linkRulesForWidget) : [];
    const linksWithWidgetIgnoredHidden = sourceLinksWithWidget.filter((link) => !link.widget.isHidden);
    const defaultIsHidden = linkRulesForWidget.some((link) => link.action === LinkingAction.show);
    const actions = compareUserAnswerToLinkRule(linksWithWidgetIgnoredHidden);

    if (!actions.length) {
      array[indx] = { ...widget, isHidden: defaultIsHidden };
      return;
    }
    const isHidden = actions[actions.length - 1] === LinkingAction.hide;
    array[indx] = { ...widget, isHidden };
  });
  return newWidgetsForCurrentPage;
};
/**
 * This util function prepare data to update `isHidden` widget flag
 * @param widgets array of widgets;
 * @param links array of linking rules;
 * @param allPages array of all pages, because widget can have link from previous page;
 * @param pageId id of current page
 * @param subPageId id of curren sub page
 * @returns array of widgets
 */
export const updateIsHiddenForAllWidgetsOnCurrentPage = (
  widgets: WidgetsType[],
  links: ILinkObjectForSelect[],
  allPages: BuilderPageItemType[],
  pageId: string,
  subPageId: string | undefined
) => {
  const currentPage = allPages.find((page) => page.id === pageId);
  const systemPageId = currentPage?.systemId;
  const systemSubPageId = subPageId ? currentPage?.childPages?.find((page) => page.id === pageId)?.systemId : undefined;
  const amountOfLinksForPage = links.filter((link) => {
    if (systemSubPageId) {
      return link.target.subPageSystemId === systemSubPageId && link.target.pageSystemId === systemPageId;
    }
    return link.target.pageSystemId === systemPageId && link.target.widgetId;
  }).length;

  let result = widgets;
  for (let index = 0; index < amountOfLinksForPage; index++) {
    /*
      need more than one loop through array of widgets, because when one widget change his
      hidden state - another widget can depends on that change according to link rule.
    */
    result = applyLinkingRulesOnWidgets(result, links, allPages, pageId, subPageId);
  }
  return result;
};
