import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

const LogoIcon = () => {
  const logoEventCloud = '/assets/images/icons/logo-dark.svg';
  const theme = useTheme();

  return <img src={theme.palette.mode === 'dark' ? logoEventCloud : logoEventCloud} alt="Event Cloud" width="35" height="35" />;
};

export default LogoIcon;
