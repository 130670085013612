import { v4 as uuidv4 } from 'uuid';
import { MIN_NUMBER_OF_LABELS } from 'modules/builder/constants/builderWidgets/singleChoiceWidget';
import { SingleImgChoiceWidgetType } from 'modules/builder/types/imageWidgetsTypes';
/**
 * Util function to get initial options for just added widget
 */
export const createInitPhotos = (numOfLabel: number = MIN_NUMBER_OF_LABELS): SingleImgChoiceWidgetType['dataImage'] =>
  new Array(numOfLabel)
    .fill(null)
    .map((_) => ({ id: uuidv4(), labelImage: '', data: null, numericallyValue: null, secondUnitValue: null, countOfGuests: null }));
