import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import Loadable from 'shared/components/Loadable';
import AuthGuard from 'shared/components/route-guard/AuthGuard';
import MainLayout from 'shared/layouts/MainLayout';
import { QUESTIONNAIRES_ROUTES } from './constants';

const QuestionnairesPage = Loadable(lazy(() => import('pages/Questionnaires')));
const QuestionnairePreviewById = Loadable(lazy(() => import('pages/Questionnaires/QuestionnairePreviewById')));

export const QuestionnairesRoutes = {
  path: QUESTIONNAIRES_ROUTES.QUESTIONNAIRES,
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    {
      path: '',
      element: <MainLayout />,
      children: [
        {
          path: '',
          element: (
            <AuthGuard role={['tenant_admin', 'customer', 'venue_manager', 'admin']}>
              <QuestionnairesPage />
            </AuthGuard>
          )
        }
      ]
    },
    {
      path: QUESTIONNAIRES_ROUTES.QUESTIONNAIRES_PREVIEW,
      element: (
        <AuthGuard role={['tenant_admin', 'customer', 'venue_manager', 'admin']}>
          <QuestionnairePreviewById />
        </AuthGuard>
      )
    },
    {
      path: QUESTIONNAIRES_ROUTES.QUESTIONNAIRES_PREVIEW_SUB,
      element: (
        <AuthGuard role={['tenant_admin', 'customer', 'venue_manager', 'admin']}>
          <QuestionnairePreviewById />
        </AuthGuard>
      )
    }
  ]
};
