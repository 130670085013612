import { useQuery } from 'react-query';
import axios from 'axios';
import { EventType } from 'shared/types/event';

const fetchEventsFilter = (): Promise<EventType[]> => {
  return axios.get('/dashboards/filters/events/types').then((res) => res.data);
};

export function useGetEventsFilter() {
  return useQuery(['events-types'], fetchEventsFilter, {
    select: (data) =>
      data.map((el) => {
        return { value: el, label: el };
      })
  });
}
