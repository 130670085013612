import { DEFAULT_LABELS_BY_TYPE } from 'modules/builder/constants/defaultLabelsForLinking';
import { BUILDER_WIDGET_TYPES, BuilderPageItemType, WidgetsType } from 'modules/builder/types';
import { QuestionnairePageType } from 'modules/questionnaires/types/questionnaries';
import { QuestionnaireTemplatePageItem } from 'shared/types/questionnaire-template';
import { ILinkingPageNav, ILinkingSubPageNav, ILinkingWidgetNav } from './types';

type typesForLabel =
  | BUILDER_WIDGET_TYPES.multipleChoice
  | BUILDER_WIDGET_TYPES.singleChoice
  | BUILDER_WIDGET_TYPES.singleImageChoice
  | BUILDER_WIDGET_TYPES.multipleImageChoice;
/**
 * Util function to set default name for label if user did't set it.
 * @param index - index of widget
 * @param type - type of widget used to main label text
 */
const createDefaultLabelName = (index: number, type: typesForLabel): string => {
  const newIndex = index + 1; // avoid 0 index;
  return `${DEFAULT_LABELS_BY_TYPE[type]} ${newIndex}`;
};

/**
 * Util function that convert widgets list to list of options for select.
 * @param data - array of widgets
 * @param targetWidgetId - id of widget that used for link as a target. Need it to avoid linking widget for themselves
 */
const transformWidgetForSelect = (data: WidgetsType[], targetWidgetId: WidgetsType['widgetId'] | null): ILinkingWidgetNav[] => {
  if (!data) return [];
  return data
    .map((widget, index) => {
      if (widget.widgetId === targetWidgetId) return null; // avoid case when target id same as source widget;
      if (widget.type === BUILDER_WIDGET_TYPES.singleChoice || widget.type === BUILDER_WIDGET_TYPES.multipleChoice) {
        return {
          id: widget.widgetId,
          label: widget.label ? widget.label : createDefaultLabelName(index, widget.type),
          options: widget.optionLabels ?? [],
          type: widget.type
        } as ILinkingWidgetNav;
      }
      if (widget.type === BUILDER_WIDGET_TYPES.singleImageChoice || widget.type === BUILDER_WIDGET_TYPES.multipleImageChoice) {
        return {
          id: widget.widgetId,
          label: widget.label ? widget.label : createDefaultLabelName(index, widget.type),
          options:
            widget.dataImage?.map((option, index) => ({
              id: option.id,
              label: option.labelImage ? option.labelImage : `image ${index + 1}`
            })) ?? [],
          type: widget.type
        } as ILinkingWidgetNav;
      }
      return null;
    })
    .filter((widget) => !!widget) as ILinkingWidgetNav[];
};

/**
 * Util function for filtering pages. Puprpose of it to avoid user set source of link from next page widgets.
 * @param currentPageNumber - current page number
 * @param pages - array of pages for filtering
 */
const filterPageWithHigherOrder = (currentPageNumber: BuilderPageItemType['pageNumber'] | undefined, pages: BuilderPageItemType[]) => {
  if (typeof currentPageNumber !== 'number') return pages;
  return pages.filter((page) => page.pageNumber <= currentPageNumber);
};

/**
 * Util function to convert sub pages to collapsible section in select.
 * @param pages - array of pages
 * @param targetWidgetId - id of widget that used for link as a target. Need it to avoid linking widget for themselves
 * @param currentPage - object of current page.
 * @param targeusedWidgetsFromCurrentPagetWidgetId - array of widgets that probably not saved yet.
 */
const transformSubpageForSelect = (
  pages: BuilderPageItemType['childPages'],
  targetWidgetId: WidgetsType['widgetId'] | null,
  currentPage: QuestionnaireTemplatePageItem | undefined,
  usedWidgetsFromCurrentPage: WidgetsType[]
): ILinkingSubPageNav[] => {
  if (!pages) return [];
  return pages.map((page) => {
    const widgets: WidgetsType[] | undefined = currentPage?.systemId === page.systemId ? usedWidgetsFromCurrentPage : page?.usedWidgets;

    const usedWidgetsConverted = transformWidgetForSelect(widgets ?? [], targetWidgetId);
    return {
      id: page.systemId,
      label: page.name,
      widgets: usedWidgetsConverted
    };
  });
};

/**
 * Util function to convert pages to collapsible section in select.
 * @param pages - array of pages
 * @param targetWidgetId - id of widget that used for link as a target. Need it to avoid linking widget for themselves
 * @param currentPage - object of current page.
 * @param targeusedWidgetsFromCurrentPagetWidgetId - array of widgets that probably not saved yet.
 */
export const transformPageForSelect = (
  pages: BuilderPageItemType[],
  targetWidgetId: WidgetsType['widgetId'] | null,
  currentPage: QuestionnaireTemplatePageItem | undefined,
  usedWidgetsFromCurrentPage: WidgetsType[]
): ILinkingPageNav[] => {
  if (!pages) return [];
  return pages
    .map((page) => {
      if (page.type === QuestionnairePageType.WelcomePage) return null;
      const widgets: WidgetsType[] | undefined = currentPage?.systemId === page.systemId ? usedWidgetsFromCurrentPage : page?.usedWidgets;

      const usedWidgetsConverted = transformWidgetForSelect(widgets ?? [], targetWidgetId);
      const subPages =
        page.childPages && page.systemId !== currentPage?.systemId
          ? transformSubpageForSelect(page.childPages, targetWidgetId, currentPage, usedWidgetsFromCurrentPage)
          : null;
      return {
        id: page.systemId,
        label: page.name,
        subPages,
        widgets: usedWidgetsConverted
      } as ILinkingPageNav;
    })
    .filter((page) => !!page) as ILinkingPageNav[];
};

/**
 * Util function to filter pages and sub pages to avoid recursion link.
 * @param pages - array of pages.
 * @param pageId - page id.
 * @param subPageId - sub page id.
 * @param widgetId - widget id. if widget id exist - this filtering is not needed.
 */
export const filterPagesToAvoidRecursionLink = (
  pages: BuilderPageItemType[],
  pageId: number,
  subPageId?: number | null,
  widgetId?: WidgetsType['widgetId'] | null
) => {
  if (widgetId) return pages;
  if (typeof pageId === 'number' && typeof subPageId === 'number') {
    return pages.map((page) => {
      if (Number(page.id) === pageId && !!page.childPages) {
        return {
          ...page,
          childPages: page.childPages.filter((subPage) => Number(subPage.id) !== subPageId)
        };
      }
      return page;
    });
  }
  return pages.filter((page) => Number(page.id) !== pageId);
};
/**
 * Util function to filter pages by order. To avoid case to create link on future answers.
 * @param pages - array of pages.
 * @param currentPageNumber - current page order.
 * @param parentPageNumber - order of parent. In case when current page is sub page - for filtering should be used order of parent
 * @param parentPageId - id of parent page
 */
export const filterPagesByOrder = (
  pages: BuilderPageItemType[],
  currentPageNumber: BuilderPageItemType['pageNumber'] | undefined,
  parentPageNumber: BuilderPageItemType['pageNumber'] | undefined | null,
  parentPageId: number | undefined | null
): BuilderPageItemType[] => {
  const filteredPagesByOrder = filterPageWithHigherOrder(parentPageNumber ?? currentPageNumber, pages);
  const filteredPagesAndSubpagesByOrder = filteredPagesByOrder.map((page) => {
    if (page.childPages && Number(page.id) === parentPageId) {
      return {
        ...page,
        childPages: filterPageWithHigherOrder(currentPageNumber, page.childPages)
      };
    }
    return page;
  });
  return filteredPagesAndSubpagesByOrder;
};
