import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { ErrorCircle20Regular } from '@fluentui/react-icons';
import * as Styles from 'shared/components/RemoveDialog/RemoveDialog.styles';

interface IDialogImportWarning {
  isOpen: boolean;
  closeDialog: () => void;
  handleImportWithReplacement: () => void;
  handleImportAll: () => void;
}
const DialogImportWarning = ({ isOpen, closeDialog, handleImportWithReplacement, handleImportAll }: IDialogImportWarning) => {
  const intl = useIntl();
  return (
    <Dialog open={isOpen} onClose={closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <Box sx={{ p: 1, py: 1.5, width: 460 }}>
        <DialogTitle
          id="alert-dialog-title"
          display="flex"
          alignItems="center"
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}
        >
          <Styles.WarningIcon>
            <ErrorCircle20Regular className={'warning'} />
          </Styles.WarningIcon>
          {intl.formatMessage({ id: 'invoices.invoice-editor-import-articles-warning-title' })}
        </DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={handleImportWithReplacement}>
            <FormattedMessage id="invoices.invoice-editor-import-articles-warning-btn-replacement" />
          </Button>
          <Button type="button" variant="outlined" onClick={handleImportAll}>
            <FormattedMessage id="invoices.invoice-editor-import-articles-warning-btn-all" />
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DialogImportWarning;
