import * as React from 'react';
import { Checkbox, FormControlLabel, Radio } from '@mui/material';

interface ICustomFormControlLabel {
  multiple: boolean;
  checkBoxValues: { [key: string]: boolean };
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  label: string;
}
const CustomFormControlLabel = ({
  multiple,
  checkBoxValues,
  handleCheckboxChange,
  handleRadioChange,
  name,
  label
}: ICustomFormControlLabel) => {
  const ControlComponent = multiple ? Checkbox : Radio;
  const handleChange = multiple ? handleCheckboxChange : handleRadioChange;

  return (
    <FormControlLabel
      control={<ControlComponent checked={checkBoxValues[name]} onChange={handleChange} name={multiple ? name : 'option'} value={name} />}
      label={label}
    />
  );
};

export default CustomFormControlLabel;
