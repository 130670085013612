import { Box, Button as ButtonMui } from '@mui/material';
import { styled } from '@mui/material/styles';

export const WidgetWrapper = styled(Box)(() => ({
  height: '100%',
  paddingLeft: '0px'
}));

export const ContentWrapper = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const Button = styled(ButtonMui)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.lighter
  }
}));
