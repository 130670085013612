import { IntlShape } from 'react-intl';
import { ICalculateParams } from 'shared/types/invoice';
import { QuestionnaireTaxType } from 'modules/questionnaires/types';
import { getNameOfTotalPriceField, roundNumbers } from 'modules/invoices/utils/calculateHelpers';
import { IEditorArticle, ValuesEditorType } from 'modules/editor/types/editor';

/* Function to convert string or undefined to number
 * @param total - value with type number | string | undefined
 */
const valueToNumber = (total?: number | string) => {
  if (typeof total === 'number') {
    return total;
  }
  if (!total) {
    return 0;
  }
  return Number(total);
};

/* Function to calculate Total of the invoice with depending on QuestionnaireTaxType */
export const calculateTotal = (articles: IEditorArticle[], taxType: QuestionnaireTaxType, discount?: number): number => {
  if (!articles?.length) return 0;
  const fieldName = getNameOfTotalPriceField(taxType);
  const result = (articles as IEditorArticle[])?.reduce((accumulator, invoiceArticle) => {
    const valueRounded = roundNumbers(invoiceArticle[fieldName]);
    return roundNumbers(accumulator + Number(valueRounded ?? 0));
  }, 0);
  const sum = !!result ? result : 0;
  if (discount && typeof discount === 'number') {
    const transformedDiscount = discount / 100;
    const value = sum - sum * transformedDiscount;
    return roundNumbers(value);
  }

  return roundNumbers(sum);
};

const calcTaxValueForNet = (total: number, tax: number): number => {
  return (total * tax) / 100;
};

const calcTaxValueForGross = (total: number, tax: number): number => {
  return (total / (1 + tax / 100)) * (tax / 100);
};

/* Function to generate list of different taxes and their sum-up totalsNetto for showing in ResultCalculation */
export const listTaxValues = (
  articles: ValuesEditorType['articles'],
  taxType: QuestionnaireTaxType,
  intl?: IntlShape
): ICalculateParams[] => {
  const objTaxAndSum: { [key: string]: number } = {};
  const fieldName = getNameOfTotalPriceField(taxType);

  articles
    .filter((a) => !!a.tax)
    .map((ar) => {
      const totalPrice = valueToNumber(ar[fieldName]);
      objTaxAndSum[ar.tax] = objTaxAndSum.hasOwnProperty(ar.tax) ? objTaxAndSum[ar.tax] + totalPrice : totalPrice;
      return ar;
    });

  return (
    Object.entries(objTaxAndSum).map(([taxName, totalAriclePrice]) => {
      let resultRounded = 0;
      if (taxType === QuestionnaireTaxType.Net) {
        const result = calcTaxValueForNet(valueToNumber(totalAriclePrice), valueToNumber(taxName));
        resultRounded = roundNumbers(result);
      } else {
        const result = calcTaxValueForGross(valueToNumber(totalAriclePrice), valueToNumber(taxName));
        resultRounded = roundNumbers(result);
      }
      return {
        title: intl
          ? `${intl.formatMessage({ id: 'invoices.invoice-editor-result-tax' })} ${valueToNumber(taxName)}%`
          : `${valueToNumber(taxName)}%}`,
        value: !!resultRounded ? resultRounded : 0,
        name: `${taxName}%`
      };
    }) ?? []
  );
};

const calculateFinalAmountArticlesTaxTypeGross = (articles: IEditorArticle[]) => {
  return articles?.reduce((ac, article) => {
    return ac + valueToNumber(article?.totalGross);
  }, 0);
};

/* Function to calculate result Amount with Gross and Discount */
export const calculateFinalAmount = (values: ValuesEditorType, taxes: ICalculateParams[]) => {
  if (values.taxType === QuestionnaireTaxType.Net) {
    const totalNet = calculateTotal(values.articles, QuestionnaireTaxType.Net);
    const taxesSum = Object.entries(taxes).reduce((acc, [_, data]) => {
      return acc + valueToNumber(data.value);
    }, 0);
    const sum = totalNet + taxesSum;
    if (values.discount) {
      const result = sum - (sum * values?.discount) / 100;
      return roundNumbers(result);
    }
    return roundNumbers(sum ?? 0);
  }
  const finalAmount = calculateFinalAmountArticlesTaxTypeGross(values.articles);
  if (values.discount) {
    const result = finalAmount - (finalAmount * values?.discount) / 100;
    return roundNumbers(result);
  }
  return finalAmount ? finalAmount : 0;
};

export const calculateNetTotalForGross = (totalGross: number, taxes: ICalculateParams[], discount?: number) => {
  let result = totalGross;
  if (discount && typeof discount === 'number') {
    const transformedDiscount = discount / 100;
    const value = result - result * transformedDiscount;
    result = roundNumbers(value);
  }
  const taxesSum = Object.entries(taxes).reduce((acc, [_, data]) => {
    return acc + valueToNumber(data.value);
  }, 0);
  result = result - taxesSum;

  return roundNumbers(result);
};
