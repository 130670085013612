import { Layout } from 'react-grid-layout';
import { WidgetsDashboardType } from 'modules/dashboard/types';

interface IMapOfWidgets {
  [key: string]: WidgetsDashboardType[];
}
/**
 * Util function to create mapped object that divides widgets into group by `widget.groupId` field
 * @param allWidgets - array of all widgets for dashboard
 * @returns `{ [guid: string]: WidgetsDashboardType[] }`
 */
export const createMapOfWidgetsGroup = (allWidgets: WidgetsDashboardType[]): IMapOfWidgets => {
  const widgetsMapByGroupId = allWidgets.reduce(
    (acc, widgetItem) => {
      if (!widgetItem?.groupId) return acc;
      if (acc[widgetItem.groupId]) {
        return {
          ...acc,
          [widgetItem.groupId]: [...acc[widgetItem.groupId], widgetItem]
        };
      }
      return {
        ...acc,
        [widgetItem.groupId]: [widgetItem]
      };
    },
    {} as { [key: string]: WidgetsDashboardType[] }
  );
  return widgetsMapByGroupId;
};

/**
 * Util function to skip layout items that should be hidden base on `isActive` field in `widget.data` object
 * @param allWidgets - array of all widgets for dashboard
 * @param layoutArray - array of layout items
 * @returns `Layout[]`
 */
export const filterDashboardLayout = (allWidgets: WidgetsDashboardType[], layoutArray: Layout[]): Layout[] => {
  const widgetsMapByGroupId = createMapOfWidgetsGroup(allWidgets);
  const notHiddenWidgetsIds = allWidgets
    .filter((widget) => {
      if (!widget.groupId) return true;
      if (widgetsMapByGroupId[widget.groupId]?.length > 1) {
        return !!widget.data?.isActive;
      }
      return true;
    })
    .map((widget) => widget.systemId);
  return layoutArray.filter((layoutItem) => notHiddenWidgetsIds.includes(layoutItem.i));
};

/**
 * Util function that switch `isActive` field for widget group
 * @param allWidgets - array of all widgets for dashboard
 * @param prevWidgetId - id of previous active widget
 * @param newWidgetId - id of new active widget
 * @returns `WidgetsDashboardType[]`
 */
export const switchIsActiveForGroup = (
  allWidgets: WidgetsDashboardType[],
  prevWidgetId: WidgetsDashboardType['systemId'],
  newWidgetId: WidgetsDashboardType['systemId']
): WidgetsDashboardType[] => {
  return allWidgets.map((w) => {
    if (w.systemId === prevWidgetId) {
      return {
        ...w,
        data: { ...w.data, isActive: false }
      };
    }
    if (w.systemId === newWidgetId) {
      return {
        ...w,
        data: { ...w.data, isActive: true }
      };
    }
    return w;
  });
};
