import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

interface INavGroup {
  drawerOpen: boolean;
}
export const Content = styled(Box)(() => ({
  display: 'flex',
  height: '100%',
  flexDirection: 'column'
}));

export const NavGroup = styled(Box)<INavGroup>(({ drawerOpen }) => ({
  flexGrow: '1',
  pt: drawerOpen ? 2 : 0,
  '& > ul:first-of-type': { mt: 0 }
}));

export const MenuGroup = styled(Box)<INavGroup>(({ drawerOpen }) => ({
  width: '100%',
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: drawerOpen ? 2 : 0,
  paddingBottom: '20px',
  '& > ul:first-of-type': { marginTop: 0 }
}));
