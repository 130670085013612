import { useNavigate, useParams } from 'react-router';
import { MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { ArrowLeft20Regular } from '@fluentui/react-icons';
import { useGetQuestionnaireInfoById } from 'api/hooks/questionnaires/useGetQuestionnaireInfoById';
import { LOGO_PATH_BASE_ON_QUESTIONNAIRE_SOURCE } from 'modules/questionnaires/constants';
import { useGetQuestionnairesSourse } from 'modules/questionnaires/hooks/useGetQuestionnairesSourse';

const BuilderHeader = () => {
  const { questionnaireId } = useParams();
  const questionnaireSource = useGetQuestionnairesSourse();

  const { data: questionnaireData } = useGetQuestionnaireInfoById({
    id: (questionnaireId && questionnaireId) || undefined,
    entitySource: questionnaireSource
  });
  const navigate = useNavigate();
  const intl = useIntl();

  const url = LOGO_PATH_BASE_ON_QUESTIONNAIRE_SOURCE[questionnaireSource];

  const handleGoHomeClick = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    // TODO: sergio. refactor from boolean to enum logic here
    navigate(url);
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <Stack spacing={2} direction="row" alignItems="center">
        <Tooltip arrow enterDelay={2000} title={intl.formatMessage({ id: 'builder.go-home' })}>
          <Button startIcon={<ArrowLeft20Regular />} variant="outlined" color="secondary" size="small" onClick={handleGoHomeClick}>
            Zurück
          </Button>
        </Tooltip>
        {questionnaireData && <Typography sx={{ fontSize: '16px', lineHeight: '24px' }}>{questionnaireData.name}</Typography>}
      </Stack>
    </Box>
  );
};

export default BuilderHeader;
