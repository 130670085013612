import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, CircularProgress, InputLabel, Stack, TextField, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import TooltipWrapper from 'shared/components/TooltipWrapper';
import * as BaseStyles from 'modules/invoices/components/Invoices.styles';
import DialogImportWarning from 'modules/invoices/components/DialogImportWarning';
import { IEditorArticle } from 'modules/editor/types/editor';
import { addBasePriceUnitWordingToArticle, addInnerIdToArticle, updateOrderArticle } from 'modules/invoices/utils/prepareData';
import { useGetOfferArticleItemsSelected } from 'api/hooks/offers/useGetOfferArticleItemsSelected';
import { EOfferEditorArticleType, IOfferEditor } from 'shared/types/offer';
import { checkIsImportedArticle, generateListOfferArticlesFromDBArticles } from 'modules/offers/utils/prepareData';
import * as Styles from './GreetingTextImportButtons.styles';

interface IIsImportAgain {
  isOpen: boolean;
  items: IEditorArticle[];
  type: EOfferEditorArticleType | null;
}

const initialIsImportAgain: IIsImportAgain = { isOpen: false, items: [], type: null };

const GreetingTextImportButtons = () => {
  const intl = useIntl();
  const theme = useTheme();
  const [importedAgain, setImportedAgain] = useState<IIsImportAgain>(initialIsImportAgain);
  const { getFieldProps, values, setFieldValue } = useFormikContext<IOfferEditor>();
  const { mutateAsync: getArticles, isLoading: isArticlesLoading } = useGetOfferArticleItemsSelected();

  const handleCloseWarningImport = () => {
    setImportedAgain(initialIsImportAgain);
  };

  const handleImportArticles = async () => {
    if (!values?.id) return;
    const type = EOfferEditorArticleType.Imported;
    const res = await getArticles(values?.id);

    const articlesWithInnerId = addInnerIdToArticle(res);
    const resArticles = addBasePriceUnitWordingToArticle(articlesWithInnerId, intl);

    if (checkIsImportedArticle(values?.articles as IEditorArticle<EOfferEditorArticleType>[] | [], type)) {
      return setImportedAgain({
        isOpen: true,
        items: resArticles,
        type: EOfferEditorArticleType.Imported
      });
    }
    setFieldValue('articles', updateOrderArticle(values?.articles, resArticles));
  };

  const handleImportWithReplacement = () => {
    if (!importedAgain?.type) return;
    const newArticles = generateListOfferArticlesFromDBArticles(
      importedAgain?.type,
      values?.articles as IEditorArticle<EOfferEditorArticleType>[],
      importedAgain?.items as IEditorArticle<EOfferEditorArticleType>[]
    );
    setFieldValue('articles', updateOrderArticle(newArticles));
    handleCloseWarningImport();
  };

  const handleImportAll = () => {
    setFieldValue('articles', updateOrderArticle(values?.articles, importedAgain?.items));
    handleCloseWarningImport();
  };

  const tooltipTextForDisabledButtons = () => {
    if (!!values?.data?.id) return null;
    return (
      <TooltipWrapper title={'invoices.invoice-editor-tooltip-assigned-event'}>
        <Styles.TooltipBox />
      </TooltipWrapper>
    );
  };

  return (
    <>
      <BaseStyles.WrapperContent>
        <Styles.Greeting>
          <Stack spacing={1}>
            <InputLabel htmlFor="greeting-message">
              <FormattedMessage id="offer.editor-greeting-message" />
            </InputLabel>
            <TextField
              sx={{ backgroundColor: theme.palette.background.paper }}
              multiline
              minRows={2}
              fullWidth
              id="greeting-message"
              variant="outlined"
              placeholder={intl.formatMessage({ id: 'settings.autosize-height-based-on-content-lines' })}
              {...getFieldProps('settingsTemplate.welcomeText')}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
            <h3>
              <FormattedMessage id="offer.selected-articles" />
            </h3>

            <Styles.BlockTooltip>
              <Button
                fullWidth
                size="medium"
                variant="outlined"
                color="secondary"
                onClick={handleImportArticles}
                disabled={isArticlesLoading || !values?.data}
                startIcon={isArticlesLoading && <CircularProgress size={'20px'} />}
              >
                <FormattedMessage id="offer.editor-import-selected-articles" />
              </Button>
              {tooltipTextForDisabledButtons()}
            </Styles.BlockTooltip>
          </Stack>
        </Styles.Greeting>
        <DialogImportWarning
          isOpen={importedAgain?.isOpen}
          closeDialog={handleCloseWarningImport}
          handleImportWithReplacement={handleImportWithReplacement}
          handleImportAll={handleImportAll}
        />
      </BaseStyles.WrapperContent>
    </>
  );
};

export default GreetingTextImportButtons;
