import { PropsWithChildren } from 'react';
import { Layout } from 'react-grid-layout';
import { Box } from '@mui/material';
import useUpdateOrganisationPlanLayoutPartially from 'modules/organisation-plan-templates/hooks/useUpdateOrganisationPlanLayoutPartially';

interface IPreviewWidgetWrapperOrgPlan {
  widgetId: string;
  sectionId: string;
  layoutItem: Layout;
  dependencies?: Array<unknown>;
}

const PreviewWidgetWrapperOrgPlan = ({
  widgetId,
  sectionId,
  layoutItem,
  children,
  dependencies
}: PropsWithChildren<IPreviewWidgetWrapperOrgPlan>) => {
  const ref = useUpdateOrganisationPlanLayoutPartially({
    widgetId,
    sectionId,
    layoutItem,
    dependencies: dependencies || []
  });

  return <Box ref={ref}>{children}</Box>;
};

export default PreviewWidgetWrapperOrgPlan;
