export const TOKEN = 'ec-token';
export const TENANT_URL = 'ec-tenant-url';

export const TEXT_SOME_ERROR: string = 'Something went wrong';

export const COUNT_EVENTS_FOR_SKIP_QUESTION_LIST = 1;

export const USERNAME_OR_PASSWORD_IS_INCORRECT = 'Username or password is incorrect';

export const CREATE_REQUEST_URL = 'create-request';
