import { Layout } from 'react-grid-layout';
import { Box } from '@mui/material';
import useUpdateOrganisationPlanLayoutPartially from 'modules/organisation-plan-templates/hooks/useUpdateOrganisationPlanLayoutPartially';
import { OrganisationPlanUploadAreaWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanUploadAreaWithReplacementType';
import { renderInputLabel } from 'modules/organisation-plan-preview/utils/renderInputLabelHandler';
import { GeneralLabelTypographyWrapper } from 'modules/organisation-plan-preview/components/OrganisationPlanPreview.styles';
import UploadedFilesPreview from './UploadedFilesPreview';
import * as Styles from './PreviewUploadWidget.styles';

interface IPreviewUploadWidgetProps {
  element: OrganisationPlanUploadAreaWithReplacementType;
  layoutItem: Layout;
}

const PreviewUploadWidget = ({ element, layoutItem }: IPreviewUploadWidgetProps) => {
  const { label, replacementLabel, data, isMainLabelHidden, isInlinePreview, isInlineLink, widgetId, sectionId } = element;

  const ref = useUpdateOrganisationPlanLayoutPartially({
    widgetId,
    sectionId,
    layoutItem,
    dependencies: [data.uploadedFile]
  });
  return (
    <Styles.BoxWrapper ref={ref}>
      <GeneralLabelTypographyWrapper isMainLabelHidden={!!isMainLabelHidden}>
        {renderInputLabel(replacementLabel, label)}
      </GeneralLabelTypographyWrapper>
      <Box sx={{ display: 'flex', gap: '20px', flexDirection: 'row' }}>
        <UploadedFilesPreview data={data} isInlinePreview={isInlinePreview} isInlineLink={isInlineLink} />
      </Box>
    </Styles.BoxWrapper>
  );
};

export default PreviewUploadWidget;
