import { ChangeEvent } from 'react';
import { PalettesProps, presetDarkPalettes, presetPalettes } from '@ant-design/colors';
import { Card, FormControlLabel, Radio, RadioGroup, Stack, Tooltip } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import useConfig from 'shared/hooks/useConfig';
import { PresetColor } from 'shared/types/config';
import useGeneralSettings from 'shared/hooks/useGeneralSettings';
import { Square48Filled } from '@fluentui/react-icons';
import AnimateButton from 'shared/components/@extended/AnimateButton';

interface ColorProps {
  id: PresetColor;
  primary: string;
  lighter: string;
  label: string;
  shadow: string;
}

const ColorScheme = () => {
  const theme = useTheme();
  const { mode, presetColor, onChangePresetColor } = useConfig();
  const customerSettings = useGeneralSettings();
  const mainColor = customerSettings.loginViewSetting ? customerSettings.loginViewSetting.loginAccentColor : '';
  const colors: PalettesProps = mode === 'dark' ? presetDarkPalettes : presetPalettes;
  const { blue } = colors;
  const colorOptions: ColorProps[] = [
    {
      id: 'theme5',
      primary: mode === 'dark' ? '#d26415' : '#f27013',
      lighter: mode === 'dark' ? '#32221a' : '#fff4e6',
      label: 'Orange',
      shadow: `0 0 0 2px ${alpha(mode === 'dark' ? '#d26415' : '#f27013', 0.2)}`
    },
    {
      id: 'default',
      primary: blue[5],
      lighter: blue[0],
      label: 'Blue',
      shadow: `0 0 0 2px ${alpha(blue[5], 0.2)}`
    },
    {
      id: 'theme7',
      primary: mode === 'dark' ? '#05934c' : '#00a854',
      lighter: mode === 'dark' ? '#1a2721' : '#d1e8d99c',
      label: 'Green',
      shadow: `0 0 0 2px ${alpha(mode === 'dark' ? '#05934c' : '#00a854', 0.2)}`
    },
    {
      id: 'theme6',
      primary: mode === 'dark' ? '#288d99' : '#2aa1af',
      lighter: mode === 'dark' ? '#1c2628' : '#e1f0ef',
      label: 'Cyan',
      shadow: `0 0 0 2px ${alpha(mode === 'dark' ? '#288d99' : '#2aa1af', 0.2)}`
    },
    {
      id: 'custom',
      primary: mainColor,
      lighter: `${mainColor}20`,
      label: 'Custom',
      shadow: `0 0 0 2px ${alpha(mode === 'dark' ? '#d26415' : '#f27013', 0.2)}`
    }
  ];

  const handlePresetColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangePresetColor(event.target.value as PresetColor);
  };

  return (
    <RadioGroup row value={presetColor} onChange={handlePresetColorChange}>
      <Stack direction="row" padding="0px 5px">
        {colorOptions.map((color, index) => (
          <Tooltip key={index} arrow sx={{ zIndex: '999999' }} title={color.label} placement="bottom">
            <FormControlLabel
              control={<Radio value={color.id} sx={{ display: 'none' }} />}
              label={
                <AnimateButton>
                  <Card
                    sx={{
                      height: '48px',
                      padding: '0px',

                      bgcolor: alpha(theme.palette.secondary.lighter, 0.5),
                      boxShadow:
                        presetColor === color.id
                          ? `rgba(0, 0, 0, 0.01) 0px 6px 6px 0px, ${color.primary} 0px 0px 0px 1px`
                          : `rgba(0, 0, 0, 0.01) 0px 6px 6px 0px, rgba(0, 0, 0, 0.07) 0px 0px 0px 1px`,
                      borderRadius: '10px'
                    }}
                  >
                    <Square48Filled color={color.primary} />
                  </Card>
                </AnimateButton>
              }
            />
          </Tooltip>
        ))}
      </Stack>
    </RadioGroup>
  );
};

export default ColorScheme;
