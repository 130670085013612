import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Grid } from '@mui/material';
import { useDispatch } from 'store';
import { setDraggableItemOrganisationPlan } from 'store/organisation-plan-template/organisation-plan-template.slice';
import { ISideBarWidget, ORGANISATION_PLAN_WIDGET_TYPES } from 'modules/organisation-plan-templates/types';
import OrganisationPlanWidgetsPaletteItem from 'modules/organisation-plan-templates/components/OrganisationPlanWidgetsPaletteItem';
import { ORGANISATION_PLAN_BUILDER_SIDEBAR_WIDGETS_LIST } from './widgetList';
import * as Styles from './WidgetBar.styles';

interface IWidgetsBarProps {
  onIsPaddingSection: (isPadding: boolean) => void;
}

const WidgetsBar = ({ onIsPaddingSection }: IWidgetsBarProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const onDragStart = (item: ISideBarWidget) => {
    onIsPaddingSection(true);
    dispatch(setDraggableItemOrganisationPlan(item));
  };
  const onDragEnd = () => {
    dispatch(setDraggableItemOrganisationPlan(null));
    onIsPaddingSection(false);
  };
  return (
    <>
      <Grid item>
        <Styles.WidgetBarTypography>
          <FormattedMessage id="builder.select-elements" />
        </Styles.WidgetBarTypography>
      </Grid>
      <Styles.WidgetBarWrapper>
        <Styles.WidgetBarWrapperBox>
          {ORGANISATION_PLAN_BUILDER_SIDEBAR_WIDGETS_LIST.map((item, index) => {
            return (
              <Box
                key={`${item.label}-${index}`}
                className="droppable-element"
                draggable={item.widget.type === ORGANISATION_PLAN_WIDGET_TYPES.infoFromQuestionnaire ? false : true}
                unselectable="on"
                onDragStart={() => onDragStart(item)}
                onDragEnd={onDragEnd}
              >
                <OrganisationPlanWidgetsPaletteItem
                  imgUrl={item.imgUrl}
                  label={intl.formatMessage({ id: item.formatId })}
                  customImageSize={item.customImageSize}
                />
              </Box>
            );
          })}
        </Styles.WidgetBarWrapperBox>
      </Styles.WidgetBarWrapper>
    </>
  );
};

export default WidgetsBar;
