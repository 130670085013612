import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const WrapperContent = styled(Box)(() => ({
  paddingLeft: 33,
  paddingRight: 33,
  position: 'relative',
  '& .MuiDivider-root.p-bottom': {
    position: 'absolute',
    bottom: 0,
    width: 'calc(100% + 55px)',
    left: '-33px'
  }
}));
