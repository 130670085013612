import { ERequestStatus } from 'shared/types/requests';

export const REQUEST_STATE_PENDING = {
  intlId: 'requests.status-pending',
  color: '#FA8C16',
  bgColor: '#FFF7E6'
};

export const REQUEST_STATE_CHIPS = {
  [ERequestStatus.NewCreated]: {
    intlId: 'requests.status-new-created',
    color: '#1890FF',
    bgColor: '#E6F7FF'
  },
  [ERequestStatus.ReadyToSend]: {
    intlId: 'requests.status-ready-to-sent',
    color: '#13C2C2',
    bgColor: '#E6FFFB'
  },
  [ERequestStatus.Clarification]: {
    intlId: 'requests.status-clarification',
    color: '#EB2F96',
    bgColor: '#FFF0F6'
  },
  [ERequestStatus.SentPerEmail]: {
    intlId: 'requests.status-sent-per-email',
    color: '#52C41A',
    bgColor: '#F6FFED'
  },
  [ERequestStatus.SentPerMail]: {
    intlId: 'requests.status-sent-per-mail',
    color: '#52C41A',
    bgColor: '#F6FFED'
  },
  [ERequestStatus.Accepted]: {
    intlId: 'requests.status-accepted',
    color: '#52C41A',
    bgColor: '#F6FFED'
  },
  [ERequestStatus.OnHold]: {
    intlId: 'requests.status-on-hold',
    color: '#FA8C16',
    bgColor: '#FFF7E6'
  },
  [ERequestStatus.Cancelled]: {
    intlId: 'requests.status-canceled',
    color: '#F5222D',
    bgColor: '#FFF1F0'
  },
  [ERequestStatus.CreationFailed]: {
    intlId: 'requests.status-creation-failed',
    color: '#F5222D',
    bgColor: '#FFF1F0'
  },
  [ERequestStatus.Declined]: {
    intlId: 'requests.status-declined',
    color: '#F5222D',
    bgColor: '#FFF1F0'
  }
};

export const DISABLE_REQUESTS_STATUS_AFTER_SENT_EMAIL = [
  ERequestStatus.NewCreated,
  ERequestStatus.ReadyToSend,
  ERequestStatus.SentPerMail,
  ERequestStatus.OnHold
];
