import { DragDropContext, DropResult, Droppable } from '@hello-pangea/dnd';
import { TableBody } from '@mui/material';
interface ITableDndContainer {
  updateOrder: (indexFrom: number, indexTo: number) => void;
}

const TableDndContainer = ({ children, updateOrder }: React.PropsWithChildren<ITableDndContainer>) => {
  const reorderSectionByDnd = (dropResult: DropResult) => {
    if (typeof dropResult?.destination?.index !== 'number') return;
    if (dropResult.destination.index === dropResult.source.index) return;
    const indexFrom = dropResult.source.index;
    const indexTo = dropResult.destination.index;
    updateOrder(indexFrom, indexTo);
  };

  return (
    <DragDropContext onDragEnd={reorderSectionByDnd}>
      <Droppable droppableId="table-rows">
        {(provided) => (
          <TableBody {...provided.droppableProps} ref={provided.innerRef}>
            {children}
            {provided.placeholder}
          </TableBody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default TableDndContainer;
