import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  InputLabel
} from '@mui/material';
import debounce from 'lodash/debounce';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { useGetTagsInfinite } from 'modules/dashboard/hooks/useGetTagsInfinite';
import { ETagData, ITagInfo, ITags, WidgetsDashboardType } from 'modules/dashboard/types';
import { updateWidget } from 'store/dashboard/dashboard.slice';
import { ITableDashboardWidgetColumn } from 'modules/dashboard/types/TableDashboardWidgetType';
import CustomFormControlLabel from 'modules/dashboard/components/widgets/KeyFigureWidget/CustomFormControlLabel';
import AutocompleteTagsInfiniteSelect from './AutocompleteTagsInifiteSelect';

interface IModalTags {
  open: boolean;
  selectedColumns?: ITableDashboardWidgetColumn[];
  multiple: boolean;
  widget: WidgetsDashboardType | undefined;
  previousTags: ITagInfo[];
  handleClose: (tags?: ITags[]) => void;
  handleChangeOption?: (label: string, tagData: ITagInfo[]) => void;
}

const checkboxes = [
  { name: 'Revenue', intlId: 'dashboard.revenue' },
  { name: 'NumberBookings', intlId: 'dashboard.number-of-bookings' },
  { name: 'NumberUnitsSold', intlId: 'dashboard.number-of-units-sold' }
];

const ModalTags = ({ open, handleClose, widget, multiple, selectedColumns, handleChangeOption, previousTags }: IModalTags) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [searchTags, setSearchTags] = useState('');
  const [selectedTag, setSelectedTags] = useState<ITags[]>([]);
  const [selectedTagInfo, setSelectedTagInfo] = useState<ITagInfo[]>([]);
  const [checkBoxValues, setCheckBoxValues] = useState<{ [key: string]: boolean }>({
    Revenue: false,
    NumberBookings: false,
    NumberUnitsSold: false
  });

  const isSelectSomeBoxValue = !!Object.keys(checkBoxValues).find((i) => checkBoxValues[i]);

  const selectedDataCorrect = isSelectSomeBoxValue && !!selectedTag?.length;

  const {
    data: dataTags,
    isFetchingNextPage: tagsFetchingNextPage,
    hasNextPage: tagsHasNextPage,
    fetchNextPage: tagsFetchNextPage
  } = useGetTagsInfinite(searchTags);

  const debouncedRequest = useMemo(() => {
    return debounce(async (tag: string) => {
      setSearchTags(tag.length ? tag : '');
    }, 1000);
  }, []);

  const handleChangeTags = (tags: ITags[] | null) => {
    if (!tags) return;
    setSelectedTags(tags);
  };

  const onChangeInput = (_e: React.SyntheticEvent, tags: string, reason: string) => {
    if (reason === 'input') {
      debouncedRequest(tags);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckBoxValues({ ...checkBoxValues, [event.target.name]: event.target.checked });
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckBoxValues({
      Revenue: event.target.value === ETagData.Revenue,
      NumberBookings: event.target.value === ETagData.NumberBookings,
      NumberUnitsSold: event.target.value === ETagData.NumberUnitsSold
    });
  };

  useEffect(() => {
    const selectedBoxes = Object.entries(checkBoxValues)
      .filter(([_, value]) => value)
      .map(([key]) => key);

    // This code will add a new TagInfo object to the selectedTagInfo array for each tag in selectedTag
    const newTagInfoArray = selectedTag
      .map((tag) => {
        return selectedBoxes.map((statisticType) => ({
          articleTagId: tag.id,
          name: tag.name,
          statisticType: statisticType as ETagData
        }));
      })
      .flat();

    const returnArray = [...newTagInfoArray, ...previousTags];
    setSelectedTagInfo(returnArray);
    // TODO: fix issue with useEffect dependencies
  }, [checkBoxValues, selectedTag]);

  const handleAddTags = () => {
    if (!widget) return;

    dispatch(
      updateWidget({
        widgetId: widget!.systemId,
        widget: {
          dashboardWidgetType: widget!.dashboardWidgetType,
          dashboardWidgetTags: [...selectedTagInfo],
          dataTags: dataTags
        }
      })
    );
    setSelectedTagInfo([]);
    setSelectedTags([]);
    handleClose(dataTags);
    if (handleChangeOption) {
      handleChangeOption('TagInformation', [...selectedTagInfo]);
    }
    setCheckBoxValues({ Revenue: false, NumberBookings: false, NumberUnitsSold: false });
  };

  const filteredDataTags = useMemo(() => {
    if (selectedColumns?.length && dataTags) {
      return dataTags.filter((tag) => {
        // Find the matching widget tag by ID
        const matchingWidgetTag = selectedColumns.filter((widgetTag) => widgetTag.id === tag.id);
        // Check if the matching widget tag has the same data
        if (matchingWidgetTag && matchingWidgetTag.length === 3) {
          return false; // Exclude this tag
        }
        return true; // Include this tag
      });
    }

    return dataTags; // If no filtering criteria matched, return the original dataTags
  }, [dataTags, selectedColumns]);

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle>
        <FormattedMessage id="dashboard.add-tags" />
        <IconButton size="small" sx={{ marginRight: '10px' }} onClick={() => handleClose()}>
          <Dismiss24Regular />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: '0', width: '500px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AutocompleteTagsInfiniteSelect
              multiple={multiple}
              open={open}
              options={filteredDataTags}
              hasNextPage={tagsHasNextPage}
              onChange={handleChangeTags}
              onInputChange={onChangeInput}
              loading={tagsFetchingNextPage}
              fetchNextPage={tagsFetchNextPage}
              sx={{
                '& .MuiOutlinedInput-root': { borderRadius: '0 !important', paddingLeft: '10px' },
                '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogContent dividers sx={{ padding: '10px 20px' }}>
        <Grid item xs={12}>
          <InputLabel>
            <FormattedMessage id="dashboard.select-statistic" />
          </InputLabel>
          <FormControl>
            <FormGroup>
              {checkboxes.map((checkbox, index) => (
                <CustomFormControlLabel
                  key={index}
                  multiple={multiple}
                  checkBoxValues={checkBoxValues}
                  handleCheckboxChange={handleCheckboxChange}
                  handleRadioChange={handleRadioChange}
                  name={checkbox.name}
                  label={intl.formatMessage({ id: checkbox.intlId })}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button disabled={!selectedDataCorrect} onClick={handleAddTags} sx={{ width: '100%' }} variant={'contained'}>
          <FormattedMessage id="dashboard-table.add" /> {selectedTag.length} tags
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalTags;
