import { lazy } from 'react';
import Loadable from 'shared/components/Loadable';
import AuthGuard from 'shared/components/route-guard/AuthGuard';
import MainLayout from 'shared/layouts/MainLayout';

const TenantsPage = Loadable(lazy(() => import('pages/Tenants')));
const CreateTenant = Loadable(lazy(() => import('pages/Tenants/CreateTenant')));
const EditTenant = Loadable(lazy(() => import('pages/Tenants/EditTenant')));

export const TenantsRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/tenants',
          element: <TenantsPage />
        },
        {
          path: '/tenant',
          children: [
            {
              path: ':id',
              element: <EditTenant />
            },
            {
              path: 'create',
              element: <CreateTenant />
            }
          ]
        }
      ]
    }
  ]
};
