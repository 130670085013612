import { Layout } from 'react-grid-layout';
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4, validate } from 'uuid';
import { WidgetsDashboardType } from 'modules/dashboard/types';
import { DROPPING_ELEMENT } from 'modules/builder/constants';
import { addAndOrderLayoutElement } from 'shared/utils/addAndOrderLayoutElement';
import { createMapOfWidgetsGroup } from 'modules/dashboard/components/widgets/TableDashboardWidget/utils/multipleTable';
import {
  IAddDashboardNewELementPayload,
  IDashboard,
  IDashboardState,
  IUpdateWidgetPayload,
  IaddNewWidgetInWidgetGroupPayload,
  updateDashboardLayoutPartiallyPayloadType
} from './dashboard.interfaces';
import { checkHasDataChangesDashboard, setInitialDataDashboardReducer, setInitialStateDashboardReducer } from './dashboard.action-creators';

export const usedWidgetsEntityAdapter = createEntityAdapter<WidgetsDashboardType>({
  selectId: (widget) => widget.systemId
});

const initialState: IDashboardState = {
  layout: [],
  usedWidgets: usedWidgetsEntityAdapter.getInitialState({}),
  isDashboardCustomize: false,
  activeDashboardId: null,
  isDataChanged: false,
  dashboardName: '',
  activeDashboard: {
    id: 0,
    name: '',
    widgets: []
  },
  initialState: {
    dashboardName: '',
    widgets: [],
    layout: [],
    changedFields: []
  }
};

interface ISetActiveDashboardPayload {
  activeDashboard: IDashboard;
  name: string;
  id: string;
  layout: Layout[];
}

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    checkHasDataChangesDashboard,
    setInitialData: setInitialDataDashboardReducer,
    setCustomizeDashboard(state, action: PayloadAction<boolean>) {
      state.isDashboardCustomize = action.payload;
    },
    setDefaultInitialState: setInitialStateDashboardReducer,
    setActiveDashboard(state, { payload: { activeDashboard, name, id, layout } }: PayloadAction<ISetActiveDashboardPayload>) {
      state.activeDashboard = activeDashboard;
      state.dashboardName = name;
      state.activeDashboardId = id;
      state.layout = layout;
    },
    setNameDashboard(state, action: PayloadAction<string>) {
      state.dashboardName = action.payload;
      dashboard.caseReducers.checkHasDataChangesDashboard(state, {
        type: 'checkHasDataChangesDashboard',
        payload: { dashboardName: true }
      });
    },
    updateWidget(state: IDashboardState, { payload: { widgetId, widget } }: PayloadAction<IUpdateWidgetPayload>) {
      const prevWidgetsState = { ...state.usedWidgets.entities[widgetId] };
      usedWidgetsEntityAdapter.updateOne(state.usedWidgets, {
        id: widgetId,
        changes: { ...prevWidgetsState, ...widget } as WidgetsDashboardType
      });
    },
    updateDashboardLayoutReducer(state: IDashboardState, { payload }: PayloadAction<Layout[]>) {
      const { selectById, selectAll } = usedWidgetsEntityAdapter.getSelectors();
      const allLayouts = selectAll(state.usedWidgets)
        .map((w) => w.layout)
        .map((l) => payload.find((layoutItem) => layoutItem.i === l?.i) ?? l)
        .filter((l) => !!l) as Layout[];
      const newWidgets = allLayouts
        .map((l) => {
          const relatedWidget = selectById(state.usedWidgets, l.i);
          if (!relatedWidget) return null;
          return { ...relatedWidget, layout: l };
        })
        .filter((widget) => !!widget) as WidgetsDashboardType[];
      const widgetsMapByGroupId = createMapOfWidgetsGroup(newWidgets);
      const res = Object.values(widgetsMapByGroupId)
        .map((groupArray) => {
          if (groupArray.length === 1) return groupArray;
          const activeWidget = groupArray.find((w) => w.data?.isActive);
          return groupArray.map((w) =>
            w.systemId === activeWidget?.systemId ? w : { ...w, layout: { ...activeWidget?.layout, i: w.systemId } }
          );
        })
        .flat(1) as WidgetsDashboardType[];
      const layouts = res.map((w) => w.layout).filter((l) => !!l) as Layout[];
      usedWidgetsEntityAdapter.setAll(state.usedWidgets, res);
      if (layouts?.find((i) => i.i === DROPPING_ELEMENT)) {
        state.layout = layouts;
        return;
      }
      const sortedLayout = [...layouts].sort((layout1, layout2) => layout1.y - layout2.y);
      state.layout = [...sortedLayout];
      dashboard.caseReducers.checkHasDataChangesDashboard(state, {
        type: 'checkHasDataChangesDashboard',
        payload: { layout: true }
      });
    },
    updateDashboardLayoutPartially(state: IDashboardState, { payload }: PayloadAction<updateDashboardLayoutPartiallyPayloadType>) {
      const widget = usedWidgetsEntityAdapter.getSelectors().selectById(state.usedWidgets, payload.i);
      const newLayout = state.layout.map((layout) => {
        if (layout.i === payload.i) {
          return {
            ...layout,
            ...payload
          };
        }
        return layout;
      });
      const sortedLayout = newLayout.sort((layout1, layout2) => layout1.y - layout2.y);
      state.layout = [...sortedLayout];
      if (widget) {
        usedWidgetsEntityAdapter.updateOne(state.usedWidgets, {
          id: payload.i,
          changes: { layout: { ...widget.layout, ...payload } as Layout }
        });
      }
      dashboard.caseReducers.checkHasDataChangesDashboard(state, {
        type: 'checkHasDataChangesDashboard',
        payload: { layout: true }
      });
    },
    deleteDashboardWidget(state, { payload: { systemId, groupId } }: PayloadAction<{ systemId: string; groupId?: string }>) {
      const { selectAll } = usedWidgetsEntityAdapter.getSelectors();
      let newWidgets = selectAll(state.usedWidgets);
      if (groupId) {
        let isFlagActiveOnce = false;
        newWidgets = selectAll(state.usedWidgets).map((w) => {
          if (w.groupId === groupId && w.systemId !== systemId && !isFlagActiveOnce) {
            isFlagActiveOnce = true;
            return {
              ...w,
              data: { ...w.data, isActive: true }
            };
          }
          return w;
        });
      }
      state.layout = state.layout.filter((layout) => layout.i !== systemId);
      newWidgets = newWidgets.filter((w) => w.systemId !== systemId);
      usedWidgetsEntityAdapter.setAll(state.usedWidgets, newWidgets);
      dashboard.caseReducers.checkHasDataChangesDashboard(state, {
        type: 'checkHasDataChangesDashboard',
        payload: { widgets: true }
      });
    },
    addNewDashboardElement(state: IDashboardState, { payload: { layout, widget } }: PayloadAction<IAddDashboardNewELementPayload>) {
      const widgetId = uuidv4();
      const { layout: updatedLayout } = addAndOrderLayoutElement(state.layout, layout, widgetId);
      state.layout = updatedLayout;
      const newLayout = updatedLayout.find((layoutItem) => layoutItem.i === widgetId) ?? layout;
      usedWidgetsEntityAdapter.addOne(state.usedWidgets, { ...widget, systemId: widgetId, layout: newLayout } as WidgetsDashboardType);
      dashboard.caseReducers.checkHasDataChangesDashboard(state, {
        type: 'checkHasDataChangesDashboard',
        payload: { widgets: true }
      });
    },
    setWidgets(state, { payload }: PayloadAction<WidgetsDashboardType[]>) {
      usedWidgetsEntityAdapter.setAll(state.usedWidgets, payload);
      dashboard.caseReducers.checkHasDataChangesDashboard(state, {
        type: 'checkHasDataChangesDashboard',
        payload: { layout: true, widgets: true }
      });
    },
    addNewWidgetInWidgetGroup(
      state: IDashboardState,
      { payload: { widget, defaultName } }: PayloadAction<IaddNewWidgetInWidgetGroupPayload>
    ) {
      const widgetId = uuidv4();
      const layout = widget.layout ?? state.layout.find((layoutItem) => layoutItem.i === widget.systemId);
      if (layout) {
        const { layout: updatedLayout } = addAndOrderLayoutElement(state.layout, layout, widgetId);
        state.layout = updatedLayout;
      } else {
        console.error('layout of widget that is source of group creation is missing');
      }
      const widgetsAmountOfThisGroup = usedWidgetsEntityAdapter
        .getSelectors()
        .selectAll(state.usedWidgets)
        .filter((widgetItem) => validate(widgetItem.groupId) && widgetItem.groupId === widget.groupId).length;
      const newWidgetData = { ...widget.data, isActive: true, name: `${defaultName} ${widgetsAmountOfThisGroup + 1}` };
      usedWidgetsEntityAdapter.updateOne(state.usedWidgets, {
        id: widget.systemId,
        changes: { ...widget, data: { ...widget.data, isActive: false } }
      });
      const tags: WidgetsDashboardType['tags'] = widget.dashboardWidgetTags.map((tag) => ({
        id: tag.articleTagId,
        statisticType: tag.statisticType
      }));
      const dashboardWidgetTags = widget.dashboardWidgetTags.map((tag) => ({
        statisticType: tag.statisticType,
        articleTagId: tag.articleTagId
      }));
      const copyOfWidget = { ...widget, tags: tags, dashboardWidgetTags, layout: { ...layout, i: widgetId } };
      delete copyOfWidget.id;
      usedWidgetsEntityAdapter.addOne(state.usedWidgets, {
        ...copyOfWidget,
        systemId: widgetId,
        data: newWidgetData
      } as WidgetsDashboardType);
      dashboard.caseReducers.checkHasDataChangesDashboard(state, {
        type: 'checkHasDataChangesDashboard',
        payload: { widgets: true }
      });
    },
    deleteDashboardWidgetGroup(state, { payload: groupId }: PayloadAction<string>) {
      const { selectAll } = usedWidgetsEntityAdapter.getSelectors();
      let newLayout = [...state.layout];
      const allWidgets = selectAll(state.usedWidgets).filter((widget) => {
        if (widget.groupId === groupId) {
          newLayout = newLayout.filter((item) => item.i !== widget.systemId);
          return false;
        }
        return true;
      });
      state.layout = newLayout;
      usedWidgetsEntityAdapter.setAll(state.usedWidgets, allWidgets);
      dashboard.caseReducers.checkHasDataChangesDashboard(state, {
        type: 'checkHasDataChangesDashboard',
        payload: { widgets: true }
      });
    }
  }
});

export const {
  setWidgets,
  deleteDashboardWidget,
  setActiveDashboard,
  setCustomizeDashboard,
  setNameDashboard,
  updateWidget,
  setDefaultInitialState,
  addNewDashboardElement,
  updateDashboardLayoutReducer,
  setInitialData,
  addNewWidgetInWidgetGroup,
  deleteDashboardWidgetGroup,
  updateDashboardLayoutPartially
} = dashboard.actions;

export default dashboard.reducer;
