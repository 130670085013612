import { MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IBoxMenuItem {
  isBorderTop?: boolean;
  isBorderBottom?: boolean;
}

export const BoxMenuItem = styled(MenuItem)<IBoxMenuItem>(({ isBorderTop, isBorderBottom }) => ({
  borderTop: isBorderTop ? `3px solid rgba(0 0 0/0.1)` : 'none',
  borderBottom: isBorderBottom ? `3px solid rgba(0 0 0/0.1)` : 'none',
  padding: '8px 12px !important'
}));
