import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Form, FormikProvider, useFormik } from 'formik';
import PageTitle from 'shared/components/PageTitle';
import { useGetOfferById } from 'api/hooks/offers/useGetOfferById';
import OfferEditorHeader from 'modules/offers/components/OfferEditorHeader';
import { IOfferEditor, IOfferForSave } from 'shared/types/offer';
import { selectPaginationRequests } from 'store/pagination/pagination.selectors';
import { useEditOffer } from 'api/hooks/offers/useEditOffer';
import { textError } from 'shared/utils/infoText';
import { useInfoMessage } from 'shared/hooks/useInfoMessage';
import OfferEditorDetails from 'modules/offers/components/OfferEditorDetails';
import OfferEditorTable from 'modules/offers/components/OfferEditorTable';
import GreetingTextImportButtons from 'modules/offers/components/GreetingTextImportButtons';
import { useGetOfferItemsSelected } from 'api/hooks/offers/useGetOfferItemsSelected';
import { prepareDataForItemsRequest } from 'modules/invoices/utils/prepareData';
import { useUpdateOfferItems } from 'api/hooks/offers/useUpdateOfferItems';
import EditorFooter from 'modules/editor/components/EditorFooter';
import { EEditorType } from 'modules/editor/types/editor';
import { prepareOfferEditorForSave, prepareOfferEditorToForm } from 'modules/offers/utils/prepareData';

const OfferEditor = () => {
  const intl = useIntl();
  const { offerId } = useParams();
  const { successMessage, errorMessage } = useInfoMessage();
  const paginationRequests = useSelector(selectPaginationRequests);
  const offerIdNumber = offerId ? Number(offerId) : undefined;

  const { data: offerItemsSelected } = useGetOfferItemsSelected(offerIdNumber);
  const { data: offerEditorData, isLoading: isEditorLoading } = useGetOfferById({ offerId: Number(offerId) });
  const { mutateAsync: mutateEditOffer, isLoading: isLoadingEdit } = useEditOffer({
    id: offerIdNumber,
    paginationRequests,
    options: {
      onError(error) {
        errorMessage(textError(error));
      }
    }
  });
  const { mutateAsync: saveItemsAsync, isLoading: isOfferItemsSaving } = useUpdateOfferItems({});

  const defaultInitData = useMemo(() => prepareOfferEditorToForm({}), []);

  const formik = useFormik<IOfferEditor>({
    initialValues: defaultInitData,
    onSubmit: async (values) => {
      if (!values?.id) {
        return;
      }
      const dataForSaveOffer: IOfferForSave = prepareOfferEditorForSave(values);
      await mutateEditOffer(dataForSaveOffer);
      const itemsValues = prepareDataForItemsRequest(values.articles);
      const itemsValuesOptional = prepareDataForItemsRequest(values.articlesOptional);
      const finalItems = [...itemsValues, ...itemsValuesOptional];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { articles, articlesOptional, ...restValues } = values;
      await saveItemsAsync(
        { id: values.id!, items: finalItems },
        {
          onSuccess: () => {
            successMessage(intl.formatMessage({ id: 'offer.editor-update-success' }));
          },
          onError(error) {
            errorMessage(textError(error));
          }
        }
      );
    }
  });

  const { resetForm } = formik;

  useEffect(() => {
    if (!offerEditorData || !offerItemsSelected) return;
    resetForm({ values: prepareOfferEditorToForm({ data: offerEditorData, articles: offerItemsSelected }) });
  }, [resetForm, offerEditorData, offerItemsSelected]);

  return (
    <>
      <PageTitle title={offerEditorData?.offerNumber} />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
          <OfferEditorHeader isLoading={isEditorLoading || isLoadingEdit || isOfferItemsSaving} />
          <OfferEditorDetails />
          <GreetingTextImportButtons />
          <OfferEditorTable />
          <EditorFooter type={EEditorType.Offers} />
        </Form>
      </FormikProvider>
    </>
  );
};

export default OfferEditor;
