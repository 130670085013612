import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IReplacementInputField {
  isImageChoice?: boolean;
}

export const ReplacementInputField = styled(TextField)<IReplacementInputField>(({ theme, isImageChoice }) => ({
  backgroundColor: theme.palette.secondary.lighter,
  width: isImageChoice ? '167px' : '100%',
  padding: 0,
  marginTop: '0px',
  marginBottom: 0,
  height: '26px',

  '& input': {
    padding: '3px 7px',
    fontWeight: '450'
  },

  '& .Mui-focused': {
    boxShadow: 'none',
    borderColor: 'none'
  },

  '& fieldset': {
    border: `1px dashed ${theme.palette.secondary.light}`,
    borderRadius: '4px'
  }
}));
