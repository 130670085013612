import { useInfiniteQuery } from 'react-query';
import { useMemo } from 'react';
import axios from 'axios';
import { ITableDataParams } from 'api/hooks/dashboard/useGetDashboardTable';
import { IPagination } from 'shared/types/table';

interface IuseGetShareTableWidgetsForPreview {
  token?: string;
  dataSort: IPagination | null;
}
interface IFetch {
  token?: string;
  pageParam?: number;
  OrderField?: string;
  OrderType?: string;
}
const fetchInfoShareTable = async ({ token, pageParam, OrderField, OrderType }: IFetch) => {
  const additionalOrderField = !!OrderField ? `&OrderField=${OrderField}` : '';
  const additionalOrderType = !!OrderField ? `&OrderType=${OrderType}` : '';
  return axios.get(`dashboard/table/${token}?PageNumber=${pageParam}${additionalOrderField}${additionalOrderType}`).then((res) => res.data);
};

export function useGetShareTableWidgetsForPreview({ token, dataSort }: IuseGetShareTableWidgetsForPreview) {
  const getQueryKey = !!dataSort?.orderField ? ['share-table', token, dataSort] : ['share-table', token];

  const query = useInfiniteQuery({
    enabled: !!token,
    queryKey: getQueryKey,
    queryFn: async (data) => {
      const ordField = !!dataSort?.orderField ? { OrderField: dataSort.orderField } : {};
      const ordType = !!dataSort?.orderType ? { OrderType: dataSort.orderType } : {};
      return await fetchInfoShareTable({ token, pageParam: data?.pageParam ?? 1, ...ordField, ...ordType });
    },
    getNextPageParam: (lastPage: ITableDataParams, allPages: ITableDataParams[]) => {
      const MAX_ROWS = Math.ceil(lastPage.totalCount / 10);
      if (allPages.length < MAX_ROWS) {
        return allPages.length + 1;
      } else {
        return undefined;
      }
    }
  });

  const data = useMemo(() => {
    return query.data?.pages.flatMap((page) => page.rows);
  }, [query.data?.pages]);

  return { ...query, data, query, mainData: query.data?.pages[query.data?.pages.length - 1] };
}
