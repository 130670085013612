import { RootState } from 'store/index';
import { orgPlanLinksEntityAdapter, sectionsEntityAdapter, usedWidgetsEntityAdapter } from './organisation-plan-template.slice';

export const { selectById: selectOrganizationPlanWidgetById, selectAll: selectOrganizationPlanAllWidgets } =
  usedWidgetsEntityAdapter.getSelectors<RootState>((state) => state.organisationPlanTemplateBuilder.usedWidgets);

export const organizationPlanTemplateBuilderUsedWidgets = (state: RootState) => state.organisationPlanTemplateBuilder.usedWidgets;
export const selectDraggableItemOrganizationPlan = (state: RootState) => state.organisationPlanTemplateBuilder.draggableItemOrgPlan;

export const selectIsActiveInfoFromQuestionnaireSettings = (state: RootState) =>
  state.organisationPlanTemplateBuilder.activeInfoFromQuestionnaireSettings;
export const selectIsActiveInfoFromQuestionnaireSettingsSectionId = (state: RootState) =>
  state.organisationPlanTemplateBuilder.activeInfoFromQuestionnaireSettingsSectionId;
export const {
  selectById: selectOrganizationPlanSectionById,
  selectAll: selectOrganizationPlanAllSections,
  selectIds: selectOrgPlanSectionsIds
} = sectionsEntityAdapter.getSelectors<RootState>((state) => state.organisationPlanTemplateBuilder.sections);
export const { selectAll: selectAllOrgPlanLinks } = orgPlanLinksEntityAdapter.getSelectors<RootState>(
  (state) => state.organisationPlanTemplateBuilder.links
);

export const organizationPlanTemplateBuilderActiveLinkingSettings = (state: RootState) =>
  state.organisationPlanTemplateBuilder.activeLinkingSettings;
export const editWidgetSettings = (state: RootState) => state.organisationPlanTemplateBuilder.editWidgetSettings;
export const selectHeaderOptions = (state: RootState) => state.organisationPlanTemplateBuilder.headerOptions;
export const selectIsEvent = (state: RootState) => state.organisationPlanTemplateBuilder.isEvent;
export const selectGetBaseState = (state: RootState) => ({
  usedWidgets: state.organisationPlanTemplateBuilder.usedWidgets,
  sections: state.organisationPlanTemplateBuilder.sections,
  links: state.organisationPlanTemplateBuilder.links,
  headerOptions: state.organisationPlanTemplateBuilder.headerOptions,
  primaryState: state.organisationPlanTemplateBuilder.primaryState
});
