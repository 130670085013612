import { UseMutationOptions, useMutation } from 'react-query';
import axios from 'axios';
import { IEditorArticle } from 'modules/editor/types/editor';
import { API_OFFERS_ENDPOINTS } from 'shared/types/offer';

const fetchOfferArticleItems = (id: number): Promise<IEditorArticle[]> => {
  return axios.get(API_OFFERS_ENDPOINTS.GET_ITEMS_SELECTED(id)).then((res) => res.data);
};

interface IuseGetOfferPrepaymentItems<TError, TContext> {
  options?: Omit<UseMutationOptions<IEditorArticle[], TError, number, TContext>, 'mutationFn' | 'onSuccess' | 'variables'>;
  onSuccess?: (response: IEditorArticle[]) => void;
}

export function useGetOfferArticleItemsSelected<TError, TContext>({ options }: IuseGetOfferPrepaymentItems<TError, TContext> = {}) {
  return useMutation(fetchOfferArticleItems, { ...options });
}
