import { ReactNode } from 'react';
/* file extension icons*/
import { FileExtensionByType } from 'modules/organisation-plan-preview/constants';

const CSV = '/assets/images/file-extensions/csv.png';
const DOCS = '/assets/images/file-extensions/docs.png';
const JPG = '/assets/images/file-extensions/jpg.png';
const PDF = '/assets/images/file-extensions/pdf.png';
const PNG = '/assets/images/file-extensions/png.png';
const PPTX = '/assets/images/file-extensions/pptx.png';
const SVG = '/assets/images/file-extensions/svg.png';
const TXT = '/assets/images/file-extensions/txt.png';
const XLSX = '/assets/images/file-extensions/xlsx.png';

const fileExtensionIconByType: Record<string, ReactNode> = {
  [FileExtensionByType.pdf]: PDF,
  [FileExtensionByType.docs]: DOCS,
  [FileExtensionByType.docx]: DOCS,
  [FileExtensionByType.pptx]: PPTX,
  [FileExtensionByType.txt]: TXT,
  [FileExtensionByType.csv]: CSV,
  [FileExtensionByType.xlsx]: XLSX,
  [FileExtensionByType.png]: PNG,
  [FileExtensionByType.jpg]: JPG,
  [FileExtensionByType.jpeg]: JPG,
  [FileExtensionByType.svg]: SVG
};

export const renderFileExtensionIconHandler = (fileName: string) => {
  if (fileName) {
    const splitValues = fileName.split('.');
    return fileExtensionIconByType[splitValues[splitValues.length - 1]] as string;
  }
};
