import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, BoxProps, Tooltip } from '@mui/material';
import { Image16Regular, ImageProhibited20Regular } from '@fluentui/react-icons';
import { getStorageFileUrl } from 'shared/utils/getStorageFileUrl';
import { IMAGE_FIT_TYPE } from 'modules/builder/types/imageWidgetsTypes';
import { IQuestionnaireImageItemData } from 'modules/questionnaires/types/questionnaries';
import { SingleActionButton } from 'shared/components/ReplacementText/Buttons/Button.styles';
import * as Styles from './OrganisationPlanImageUploadWidget.styles';

interface IOrganisationPlanImageUploadWidgetProps {
  value: IQuestionnaireImageItemData[] | null;
  containerProps?: BoxProps;
  additionalProps?: BoxProps;
  multiple?: boolean;
  typeImage: IMAGE_FIT_TYPE;
  placeholderText?: string;
  single?: boolean;
  id: any;
  typeWidget: string;
  isImageHidden?: boolean;
  onImageHiddenChange?: (value: boolean) => void;
}

const OrganisationPlanImageUploadWidget = ({
  value,
  containerProps,
  additionalProps,
  typeImage,
  isImageHidden,
  onImageHiddenChange
}: IOrganisationPlanImageUploadWidgetProps) => {
  const NoImage = '/assets/images/builder/noimage.png';
  const intl = useIntl();
  const [isImageHiddenState, setImageHidden] = useState<boolean>(!!isImageHidden);
  const hideImageHandler = () => {
    setImageHidden(!isImageHiddenState);
    onImageHiddenChange?.(!isImageHiddenState);
  };

  return (
    <Box width={containerProps?.width || '100%'} height={containerProps?.height || '100px'} position="relative" {...containerProps}>
      <Styles.ImageUploadWidgetBoxWrapper {...additionalProps}>
        <Styles.ImageUploadWidgetValueBoxWrapper>
          <Styles.ImageBoxContainer
            sx={{
              objectFit: typeImage
            }}
          >
            {value ? (
              <Styles.ImageContainer
                style={{ objectFit: typeImage || 'cover' }}
                src={getStorageFileUrl(value[0].fileUrl, value[0].type)}
                alt="preview"
              />
            ) : (
              <Styles.NoImageContainer src={NoImage} alt="preview" />
            )}
          </Styles.ImageBoxContainer>
        </Styles.ImageUploadWidgetValueBoxWrapper>
      </Styles.ImageUploadWidgetBoxWrapper>
      <Tooltip
        arrow
        enterDelay={2000}
        placement="top"
        title={intl.formatMessage({ id: isImageHiddenState ? 'organisation.show-image' : 'organisation.hide-image' })}
      >
        <SingleActionButton isFixed={true} isActive={isImageHiddenState} onClick={hideImageHandler}>
          {isImageHiddenState ? <ImageProhibited20Regular /> : <Image16Regular />}
        </SingleActionButton>
      </Tooltip>
      {isImageHiddenState && <Styles.ShadowBox />}
    </Box>
  );
};

export default OrganisationPlanImageUploadWidget;
