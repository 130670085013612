import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { IUserFormData } from 'shared/types/user';
import { useGetDataByRole } from 'shared/hooks/useGetDataByRole';

const CreateCustomerFormInitials = () => {
  const intl = useIntl();
  const inputRef = useRef();
  const { values, setFieldValue, errors, touched, handleBlur, handleChange } = useFormikContext<IUserFormData>();
  const { disableForEventManager } = useGetDataByRole();

  const handleChangeData = (nameField: string, event: SelectChangeEvent<string>) => {
    setFieldValue(nameField, event.target.value);
  };

  return (
    <Box sx={{ p: '20px 0' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}>
          <Stack spacing={1.25}>
            <InputLabel htmlFor="select-user">
              <FormattedMessage id="users.select" />
            </InputLabel>
            <Select
              fullWidth
              value={values.gender}
              name="gender"
              disabled={disableForEventManager}
              onChange={(e: SelectChangeEvent<string>) => handleChangeData('gender', e)}
            >
              <MenuItem value="Male">
                <FormattedMessage id="users.mr" />
              </MenuItem>
              <MenuItem value="Female">
                <FormattedMessage id="users.ms" />
              </MenuItem>
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Stack spacing={1.25}>
            <InputLabel htmlFor="user-name">
              <FormattedMessage id="events.customer-name" />
            </InputLabel>
            <TextField
              fullWidth
              id="customer-name"
              value={values.name}
              name="name"
              disabled={disableForEventManager}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'users.name' })}
              inputRef={inputRef}
            />
            {touched.name && errors.name && (
              <FormHelperText error id="customer-name-helper">
                {errors.name}
              </FormHelperText>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Stack spacing={1.25}>
            <InputLabel htmlFor="user-surname">
              <FormattedMessage id="events.customer-surname" />
            </InputLabel>
            <TextField
              fullWidth
              id="customer-surname"
              value={values.surname}
              name="surname"
              disabled={disableForEventManager}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'settings.surname' })}
            />
            {touched.surname && errors.surname && (
              <FormHelperText error id="customer-surname-helper">
                {errors.surname}
              </FormHelperText>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateCustomerFormInitials;
