import { DropResult } from '@hello-pangea/dnd';
import {
  EArticlesValueIOfferEdit,
  EOfferEditorArticleOptionalType,
  EOfferEditorArticleType,
  IOfferArticleBaseAndOptionalType,
  IOfferEditor
} from 'shared/types/offer';
import { IEditorArticle } from 'modules/editor/types/editor';

const typeTransformObject = {
  [EOfferEditorArticleType.Custom]: EOfferEditorArticleOptionalType.Optional,
  [EOfferEditorArticleType.Imported]: EOfferEditorArticleOptionalType.OptionalImported,
  [EOfferEditorArticleOptionalType.Optional]: EOfferEditorArticleType.Custom,
  [EOfferEditorArticleOptionalType.OptionalImported]: EOfferEditorArticleType.Imported
};

/*
 * Function to change type of Article that was moved to different Table
 * (Base Table of Articles and Optional Table of Articles)
 * @param article - article from list of IOfferEditor['article'] or IOfferEditor['articlesOption']
 * */
export const changeTypeOfArticle = (
  article: IOfferArticleBaseAndOptionalType
): EOfferEditorArticleOptionalType | EOfferEditorArticleType => {
  return typeTransformObject[article.type];
};
interface INewDataOfArticles {
  dropResult: DropResult;
  values: IOfferEditor;
}

/*
 * Function to update param 'order' of articles IOfferEditor['article'] or IOfferEditor['articlesOption']
 * @param articles - IOfferEditor['article'] or IOfferEditor['articlesOption']
 * */
export const updateOrderEditorTable = (articles: IOfferArticleBaseAndOptionalType[]) => {
  return articles.map((article, index) => ({ ...article, order: index + 1 }));
};

/*
 * Function to get new offerEditor data after moving an Article in the Tables
 * @param dropResult - result after move Article
 * @param values - data of OfferEditor
 * */
export const newDataOfArticles = ({ dropResult, values }: INewDataOfArticles): IOfferEditor | undefined => {
  if (typeof dropResult?.destination?.index !== 'number') return;
  const { source, destination } = dropResult;

  const sourceArticles = [...values[source.droppableId as EArticlesValueIOfferEdit]] as IEditorArticle<
    EOfferEditorArticleType | EOfferEditorArticleOptionalType
  >[];

  // Moving an article to different Table:
  if (source.droppableId !== destination.droppableId) {
    const keyofIOfferArticles: keyof IOfferEditor = destination.droppableId as keyof IOfferEditor;
    const destRow: IOfferArticleBaseAndOptionalType[] = values[keyofIOfferArticles] as IEditorArticle<
      EOfferEditorArticleType | EOfferEditorArticleOptionalType
    >[];
    const destItems = [...destRow];
    const [articleMoved] = sourceArticles?.splice(source.index, 1) ?? [];

    const newType = changeTypeOfArticle(articleMoved);
    destItems.splice(destination.index, 0, { ...articleMoved, type: newType });

    return {
      ...values,
      [source.droppableId]: updateOrderEditorTable(sourceArticles),
      [destination.droppableId]: updateOrderEditorTable(destItems)
    };
  }
  // Moving an article in one table:
  const [articleMoved] = sourceArticles.splice(source.index, 1);

  sourceArticles.splice(destination.index, 0, articleMoved as IEditorArticle<EOfferEditorArticleType>);
  return {
    ...values,
    [source.droppableId]: updateOrderEditorTable(sourceArticles)
  };
};

/* Function to check if table is OptionalTable */
export const checkIsTableOptional = (tableName: string) => {
  return tableName === EArticlesValueIOfferEdit.ArticlesOptional;
};
