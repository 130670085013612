import { ITableWidgetColumnRules, ITableWidgetRowRules, TABLE_COLUMN_TYPES } from 'modules/builder/types/tableWidgetType';
import { useCurrentDateFormatter, useCurrentDateTimeFormatter, useCurrentTimeFormatter } from 'shared/hooks/regionalSettings';

interface IOrganisationPlanTableEditableCellProps {
  value: any;
  row: number;
  column: number;
  settings?: ITableWidgetColumnRules;
  settingsRow?: ITableWidgetRowRules;
  isRowSettingsAvailable?: boolean;
}

const OrganisationPlanTableEditableCell = ({ value: initialValue, settings }: IOrganisationPlanTableEditableCellProps) => {
  const dateValue = initialValue ? new Date(initialValue) : null;

  if (settings?.type === TABLE_COLUMN_TYPES.time) {
    const time = useCurrentTimeFormatter(dateValue?.toISOString());
    return time;
  }

  if (settings?.type === TABLE_COLUMN_TYPES.date) {
    const date = useCurrentDateFormatter(dateValue?.toISOString());
    return date;
  }

  if (settings?.type === TABLE_COLUMN_TYPES.dateTime) {
    const dateTime = useCurrentDateTimeFormatter(dateValue?.toISOString());
    return dateTime;
  }

  if (settings?.type === TABLE_COLUMN_TYPES.number) {
    return initialValue;
  }

  return initialValue;
};

export default OrganisationPlanTableEditableCell;
