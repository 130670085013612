import { Box, IconButton, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';

interface MenuButtonStyleProps {
  isIconHidden: boolean;
}
export const MenuButton = styled(IconButton)<MenuButtonStyleProps>(({ isIconHidden }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  padding: 0,
  ...(isIconHidden && {
    display: 'none'
  })
}));

export const MenuWrapper = styled(Menu)(() => ({
  p: 0,
  '& .MuiMenu-list': {
    p: 0
  }
}));

export const WidgetWrapper = styled(Box)(() => ({
  height: '100%'
}));
