import { addDays, format } from 'date-fns';
import { QuestionnaireTaxType } from 'modules/questionnaires/types';
import {
  EOfferEditorArticleOptionalType,
  EOfferEditorArticleType,
  EOfferType,
  IOffer,
  IOfferArticleBaseAndOptionalType,
  IOfferArticleOptionalType,
  IOfferArticleType,
  IOfferBD,
  IOfferDetailsSaveWithDiscount,
  IOfferDialogForm,
  IOfferEditor,
  IOfferForCreateFromEvent,
  IOfferForSave,
  IOfferSendEmail,
  IPrepareDataToSendEmailOffer
} from 'shared/types/offer';
import { ERequestStatus, IRequest } from 'shared/types/requests';
import { objectWithData } from 'shared/utils/prepareValue';
import { IEditorArticle } from 'modules/editor/types/editor';
import { IEvent } from 'shared/types/event';
import { sortOrderArticles } from 'modules/invoices/utils/prepareData';
import { IOfferSettings } from 'shared/types/tenants';

interface IGetTextVariable {
  values: IOfferSendEmail;
  text: string;
  customUrl?: string;
}

interface IPrepareOfferEditorToForm {
  data?: IOfferBD;
  articles?: IOfferArticleBaseAndOptionalType[];
}
/**
 * util to prepare data for form
 * @param offer source object or null for create form
 * @returns offer form data
 */
export const prepareOfferDataForForm = (offer: IOfferBD | null, offerSettings: IOfferSettings | null): IOfferDialogForm => {
  const recipientDataFromRequest = !!offer?.request?.contactPeople?.length ? offer?.request?.contactPeople[0] : null;
  const dt = new Date();
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
  const validUntil = addDays(dtDateOnly, offerSettings?.validityDays ?? 0);
  const validUntilWithoutTz = format(validUntil, 'yyyy-MM-dd');

  return {
    id: offer?.id,
    offerNumber: offer?.offerNumber ?? '',
    state: offer?.state ?? ERequestStatus.NewCreated,
    taxType: offer?.offerDetails?.taxType ?? QuestionnaireTaxType.Net,
    offerDate: offer?.offerDate ?? new Date(),
    validUntil: offer?.validUntil ?? validUntilWithoutTz,
    requestedEventDate: offer?.request?.requestedEventDate,
    request: offer?.request,
    offerTemplate: offer?.offerDetails?.offerTemplate ?? null,
    recipient: offer?.offerDetails?.recipient ?? recipientDataFromRequest,
    venue: offer?.offerDetails?.venue ?? offer?.request?.venue ?? null,
    isSentEmail: !!offer?.isSentEmail
  };
};

/**
 * util to prepare data for form
 * @param event source object for create form
 * @returns offer form data
 */
export const prepareOfferDataForFormFromEvent = (
  event: IEvent | null,
  offerNumber: string | null,
  offerSettings: IOfferSettings | null
): IOfferDialogForm => {
  const recipientDataFromEvent = !!event?.contactPeople?.length ? event?.contactPeople[0] : null;
  const dt = new Date();
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
  const validUntil = addDays(dtDateOnly, offerSettings?.validityDays ?? 0);
  const validUntilWithoutTz = format(validUntil, 'yyyy-MM-dd');

  return {
    offerNumber: offerNumber ?? '',
    state: ERequestStatus.NewCreated,
    taxType: event?.questionnaire?.taxType ?? QuestionnaireTaxType.Net,
    offerDate: dt,
    validUntil: validUntilWithoutTz ?? null,
    requestedEventDate: event?.eventDate,
    offerTemplate: null,
    recipient: recipientDataFromEvent ?? null,
    venue: event?.venue ?? null
  };
};

export const prepareOfferDataForSave = (data: IOfferDialogForm): IOfferForSave => {
  return {
    ...objectWithData('id', data?.id),
    state: data?.state,
    offerDate: data?.offerDate,
    validUntil: data?.validUntil,
    offerDetails: {
      taxType: data?.taxType,
      offerTemplateId: data?.offerTemplate?.id,
      venueId: data?.venue?.id,
      recipientId: data?.recipient?.id
    }
  };
};

/**
 * util function to prepare data for post request payload
 * @param data data from form
 * @param eventId id of event
 * @returns `object` for payload
 */
export const prepareOfferDataForCreateFromEvent = (data: IOfferDialogForm, eventId: number): IOfferForCreateFromEvent => {
  return {
    eventId,
    offerDate: data?.offerDate,
    validUntil: data?.validUntil,
    offerDetails: {
      taxType: data?.taxType,
      offerTemplateId: data?.offerTemplate?.id,
      venueId: data?.venue?.id,
      recipientId: data?.recipient?.id
    }
  };
};

/**
 * Function to prepare OfferEditor data to backend
 * @param values OfferEditor data from Form
 */
export const prepareOfferEditorForSave = (values: IOfferEditor): IOfferForSave<IOfferDetailsSaveWithDiscount> => {
  return {
    id: values?.id,
    state: values?.state,
    offerDate: values?.baseDates?.offerDate?.toString(),
    validUntil: values.baseDates?.validUntil?.toString() ?? '',
    offerDetails: {
      taxType: values?.taxType,
      offerTemplateId: values?.settingsTemplate?.id,
      venueId: values?.venue?.id,
      recipientId: values?.recipient?.id,
      appearance: {
        welcomeText: values?.settingsTemplate?.welcomeText,
        closingText: values?.settingsTemplate?.closingText,
        footers: values?.settingsTemplate?.footerTexts,
        optionalText: values?.settingsTemplate?.optionalText
      },
      discount: typeof values.discount === 'number' ? values.discount : 0
    }
  };
};

/**
 * util to prepare initial Invoice data based on the event
 * @param request selected event
 */
export const prepareOfferInitialByRequest = (request?: IRequest): IOffer | undefined => {
  if (!request?.id) return;
  const venueData = request?.venue
    ? {
        venue: {
          id: request?.venue?.id,
          name: request?.venue?.name
        },
        venueId: request?.venue?.id
      }
    : {};

  return {
    number: '',
    taxType: request?.questionnaire?.taxType ?? QuestionnaireTaxType.Gross,
    state: ERequestStatus.NewCreated,
    offerDate: new Date(),
    validUntil: new Date(),
    requestedEventDate: new Date(),
    ...venueData,
    request: { id: request?.id, name: request?.name ?? '', contactPeople: request?.contactPeople },
    requestId: request?.id,
    finalAmount: 0,
    tenantId: Number(request?.questionnaire?.tenantId)
  } as IOffer;
};

/** Function to prepare texts of Offer Editor from Settings Template
 * @param data - data of Offer from backend
 */
const prepareSettingsTemplateOfferEditorToForm = (data: IPrepareOfferEditorToForm['data']): IOfferEditor['settingsTemplate'] => {
  return data?.offerDetails?.offerTemplate
    ? {
        ...data?.offerDetails?.offerTemplate,
        welcomeText: data?.offerDetails?.appearance?.welcomeText ?? data?.offerDetails?.offerTemplate?.welcomeText ?? '',
        optionalText: data?.offerDetails?.appearance?.optionalText ?? data?.offerDetails?.offerTemplate?.optionalText ?? '',
        closingText: data?.offerDetails?.appearance?.closingText ?? data?.offerDetails?.offerTemplate?.closingText ?? '',
        footerTexts: data?.offerDetails?.appearance?.footers ?? data?.offerDetails?.offerTemplate?.footerTexts ?? []
      }
    : undefined;
};
/**
 * Function to prepare data from Form of Offer Editor to backend
 * @param data - data of Offer from backend
 * @param articles - Items data from backend (items Selected)
 * @param articlesOption - Items data from backend
 */
export const prepareOfferEditorToForm = ({ data, articles }: IPrepareOfferEditorToForm): IOfferEditor => {
  const articlesMain =
    (articles?.filter(
      (article) => article.type === EOfferEditorArticleType.Custom || article.type === EOfferEditorArticleType.Imported
    ) as IOfferArticleType[]) ?? ([] as IOfferArticleType[]);
  const optionalArticles =
    (articles?.filter(
      (article) =>
        article.type === EOfferEditorArticleOptionalType.Optional || article.type === EOfferEditorArticleOptionalType.OptionalImported
    ) as IOfferArticleOptionalType[]) ?? ([] as IOfferArticleOptionalType[]);
  const offerData = !!data?.request ? (data?.request as IOfferEditor['data']) : data?.event;

  return {
    id: data?.id,
    number: data?.offerNumber,
    recipient: data?.offerDetails?.recipient,
    name: data?.name,
    type: EOfferType.offer,
    taxType: data?.offerDetails?.taxType ?? QuestionnaireTaxType.Gross, //Gross
    state: data?.state ?? ERequestStatus.NewCreated,
    baseDates: {
      requestedEventDate: data?.request?.requestedEventDate ?? '',
      offerDate: data?.offerDate ?? '',
      validUntil: data?.validUntil ?? '',
      eventDate: data?.event?.eventDate ?? ''
    },
    venue: data?.offerDetails?.venue,
    settingsTemplate: prepareSettingsTemplateOfferEditorToForm(data),
    data: offerData ?? null,
    finalAmount: data?.offerDetails?.finalAmount ?? 0,
    tenantId: data?.offerDetails?.venue?.tenantId,
    isSentEmail: !!data?.isSentEmail,
    articles: sortOrderArticles(articlesMain) ?? [],
    articlesOptional: sortOrderArticles(optionalArticles) ?? [],
    senderAddress: data?.offerDetails?.offerTemplate?.senderAddress,
    legalTax: 0,
    discount: data?.offerDetails?.discount ?? 0
  };
};

/**
 * Function to prepare data from Form of Offer Editor to backend
 * @param values - data of Offer from backend
 */
const prepareOfferVariables = (values?: IOfferSendEmail): { [key: string]: string } => {
  return {
    '[[name]]': values?.recipient?.name ?? '',
    '[[last_name]]': values?.recipient?.surname ?? '',
    '[[partner_name]]': values?.data?.contactPeople ? values?.data?.contactPeople[0]?.name : '',
    '[[partner_last_name]]': values?.data?.contactPeople?.length ? values?.data?.contactPeople[0]?.surname : '',
    '[[go_to_login]]': values?.customUrl ?? '',
    '[[email]]': values?.recipient?.email ?? '',
    '[[phone_number]]': values?.recipient?.phoneNumber ?? '',
    '[[offer_number]]': values?.number ?? '',
    '[[requested_date]]': values?.requestedDate ?? '',
    '[[offer_date]]': values?.offerDate ?? '',
    '[[valid_until_date]]': values?.validUntil ?? '',
    '[[confirm_account]]': values?.confirmAccount ?? '',
    '[[go_to_request]]': values?.goToRequest ?? ''
  };
};

/**
 * function to convert text-template to output text
 * @param values invoice
 * @param text text that needs to be converted
 */
export const getTextVariableOffer = ({ values, text }: IGetTextVariable): string => {
  const regex = /(\[\[)[a-zA-Z _]+(\]\])/g;
  const regexToRemoveExtraLinks = /a href="<a href="\[\[go_to_request\]\]">.*?<\/a>"/g;

  const res = text?.replace(regex, (match) => {
    return prepareOfferVariables(values)[match.replaceAll(' ', '_')];
  });

  return res?.replace(regexToRemoveExtraLinks, () => {
    return 'a href="[[go_to_request]]"';
  });
};

/**
 * Function to prepare data for Form Send Email
 * @param offer - offer for Form Send Email
 * @param subject - subject of emailTemplate
 * @param template - template of emailTemplate
 */
export const prepareDataToSendEmailOffer = ({ offer, subject, template }: IPrepareDataToSendEmailOffer) => {
  return {
    id: offer.id,
    number: offer.number,
    message: getTextVariableOffer({ values: offer, text: template }),
    subject: getTextVariableOffer({ values: offer, text: subject }),
    recipient: offer?.recipient,
    data: offer.data
  };
};

/* Method to check if ItemList has  some article of type, which was imported early */
export const checkIsImportedArticle = (
  articles: IEditorArticle<EOfferEditorArticleType>[] = [],
  type: EOfferEditorArticleType
): boolean => {
  return !!articles?.some((a: IEditorArticle<EOfferEditorArticleType>) => a.syncKey && a.type === EOfferEditorArticleType[type]);
};

/* Method generate List Items with replacement */
export const generateListOfferArticlesFromDBArticles = (
  type: EOfferEditorArticleType,
  articles: IEditorArticle<EOfferEditorArticleType>[] | [],
  resArticles: IEditorArticle<EOfferEditorArticleType>[] | []
): IEditorArticle<EOfferEditorArticleType>[] => {
  const resArticlesClearFromOldImports: IEditorArticle<EOfferEditorArticleType>[] = [];
  resArticles.map((res) => {
    const findSyncKey = articles?.find(
      (a: IEditorArticle<EOfferEditorArticleType>) => a?.syncKey === res?.syncKey && a.type === EOfferEditorArticleType[type]
    );
    if (!findSyncKey) {
      resArticlesClearFromOldImports.push(res);
    }
    return res;
  });

  return [...articles, ...resArticlesClearFromOldImports];
};

/**
 * Util function to check if ItemList has  some article of type, which was imported early
 * @param articles - array of articles
 * @param type - specific type of aricle
 * @returns `boolean`
 */
export const checkIsImportedOptionalArticle = (
  articles: IEditorArticle<EOfferEditorArticleOptionalType>[] = [],
  type: EOfferEditorArticleOptionalType
): boolean => {
  return !!articles?.some(
    (a: IEditorArticle<EOfferEditorArticleOptionalType>) => a.syncKey && a.type === EOfferEditorArticleOptionalType[type]
  );
};

/**
 * util function generate List Items with replacement
 * @param type - certain type of articles
 * @param articles - array of articles
 * @param resArticles - old array of articles
 * @returns
 */
export const generateListOfferArticlesFromDBOptionalArticles = (
  type: EOfferEditorArticleOptionalType,
  articles: IEditorArticle<EOfferEditorArticleOptionalType>[],
  resArticles: IEditorArticle<EOfferEditorArticleOptionalType>[]
): IEditorArticle<EOfferEditorArticleOptionalType>[] => {
  const resArticlesClearFromOldImports: IEditorArticle<EOfferEditorArticleOptionalType>[] = [];
  resArticles.map((res) => {
    const findSyncKey = articles?.find(
      (a: IEditorArticle<EOfferEditorArticleOptionalType>) =>
        a?.syncKey === res?.syncKey && a.type === EOfferEditorArticleOptionalType[type]
    );
    if (!findSyncKey) {
      resArticlesClearFromOldImports.push(res);
    }
    return res;
  });

  return [...articles, ...resArticlesClearFromOldImports];
};
