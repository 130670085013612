import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { IDashboard } from 'store/dashboard/dashboard.interfaces';
import { DashboardQueryKey } from 'shared/types/dasboard';
import { updateDataInArray } from 'modules/dashboard/utils';
import { IPaginateDashboardParam } from './useGetDashboardInfinite';

type MutationContext =
  | {
      previousInvoiceTemplates: IDashboard | undefined;
      newInvoiceTemplate: IDashboard;
    }
  | undefined;

const useEditDashboard = (data: IDashboard): Promise<IDashboard> => {
  return axios.put(`/dashboards/${data.id}`, data).then((res) => res.data);
};

export function useUpdateDashboard<TError>({
  options = {},
  onSuccess
}: {
  options?: Omit<UseMutationOptions<IDashboard, TError, IDashboard, MutationContext>, 'mutationFn' | 'onSuccess' | 'variables'>;
  onSuccess?: ((data: IDashboard) => void | Promise<unknown>) | undefined;
}) {
  const queryClient = useQueryClient();
  return useMutation(useEditDashboard, {
    ...options,
    onSuccess: (data) => {
      const previousDashboard: IPaginateDashboardParam | undefined = queryClient.getQueryData([DashboardQueryKey.Dashboard]);

      // Update the data in the query result
      if (previousDashboard) {
        const updatedDashboard = updateDataInArray(previousDashboard, data);
        queryClient.setQueryData([DashboardQueryKey.Dashboard], updatedDashboard);
      }

      if (onSuccess) {
        onSuccess(data);
      }
    },
    onSettled: (_, error) => {
      if (error) {
        queryClient.invalidateQueries([DashboardQueryKey.Dashboard]);
      }
    }
  });
}
