import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxWrapper = styled(Box)(() => ({
  marginBottom: '20px'
}));

export const TypographyLink = styled(Typography)(() => ({
  fontWeight: 500,
  fontSize: '14px',
  color: '#428FFE',
  textDecoration: 'underline'
}));

export const BoxImage = styled(Box)(() => ({
  width: '200px',
  height: '200px',
  overflow: 'hidden',
  '& img': {
    minHeight: '100%',
    width: '100%',
    objectFit: 'cover'
  }
}));

export const ContainerNoImage = styled(Box)(() => ({
  display: 'flex',
  gap: '20px',
  flexDirection: 'column'
}));

export const NoImageLink = styled(Link)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  '& img': {
    height: '50px',
    width: '50px'
  }
}));

export const ImageSize = {
  height: '100%',
  width: '100%'
};
