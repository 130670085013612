// ==============================|| OVERRIDES - AUTOCOMPLETE ||============================== //

import { CaretDown20Filled } from '@fluentui/react-icons';
import { Paper } from '@mui/material';
import { Theme } from '@mui/material/styles';

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      defaultProps: {
        popupIcon: <CaretDown20Filled />,
        PaperComponent: Paper
      },
      styleOverrides: {
        root: {
          '& .MuiIconButton-root': {
            padding: '4px',
            '& svg': {
              color: theme.palette.secondary.main
            },
            '&:hover': {
              backgroundColor: 'transparent'
            }
          },
          '&.MuiOutlinedInput-root': {
            padding: '0px 0px !important'
          },
          '& .MuiInputBase-input': {
            padding: '0 !important'
          },
          '& .MuiInputBase-root': {
            padding: '8px 12px !important'
          }
        },
        popper: {
          '& .MuiPaper-root': {
            boxShadow:
              theme.palette.mode === 'dark'
                ? 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(255, 255, 255, 0.04) 0px 0px 0px 1px inset'
                : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px inset'
          },
          '& .MuiAutocomplete-listbox': {
            padding: '0px',
            backgroundColor: 'transparent',
            '& .MuiAutocomplete-option': {
              padding: '12px 16px'
            }
          }
        },
        input: {
          padding: '8px 12px'
        },
        popupIndicator: {
          width: 'auto',
          height: 'auto'
        },
        clearIndicator: {
          width: 'auto',
          height: 'auto'
        }
      }
    }
  };
}
