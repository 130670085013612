import { ChangeEventHandler, useEffect, useRef } from 'react';
import { Layout } from 'react-grid-layout';
import { useIntl } from 'react-intl';
import { getOrganisationPlanWidgetRowsAmount } from 'modules/organisation-plan-templates/utils/getOrganisationPlanWidgetRowsAmount';
import { useDispatch } from 'store';
import {
  updateOrganisationPlanLayoutPartially,
  updateOrganisationPlanWidget
} from 'store/organisation-plan-template/organisation-plan-template.slice';
import { OrganisationPlanNotesWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanNotesWidgetType';
import * as Styles from './PreviewNoteWidget.styles';

interface PreviewNoteWidgetProps {
  layoutItem: Layout;
  element: OrganisationPlanNotesWidgetType;
}

const PreviewNoteWidget = ({ layoutItem, element }: PreviewNoteWidgetProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { widgetId, type, sectionId, noteText, content } = element;

  const onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    const newValue = e.target.value;
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as OrganisationPlanNotesWidgetType['type'], noteText: newValue }
      })
    );
  };

  const refBlock = useRef<HTMLDivElement>(null);
  const refText2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof refBlock?.current?.offsetHeight !== 'number' || typeof refText2?.current?.offsetHeight !== 'number') return;
    const textHeight = getOrganisationPlanWidgetRowsAmount(refText2?.current?.offsetHeight);
    if (textHeight > 1) {
      dispatch(
        updateOrganisationPlanLayoutPartially({
          layout: {
            h: textHeight,
            i: widgetId
          },
          sectionId: sectionId || ''
        })
      );
    }
  }, [refBlock?.current?.offsetHeight, refText2?.current?.offsetHeight, content, layoutItem?.w, dispatch, sectionId, widgetId]);

  return (
    <Styles.BoxWrapper ref={refBlock}>
      <section ref={refText2}>
        <Styles.TextFieldWrapper
          variant="filled"
          fullWidth={true}
          multiline={true}
          minRows={1}
          maxRows={100}
          placeholder={intl.formatMessage({ id: 'organisation.notes' })}
          id="outlined-name"
          value={noteText}
          onChange={(e) => onChange(e)}
        />
      </section>
    </Styles.BoxWrapper>
  );
};

export default PreviewNoteWidget;
