import { UseMutationOptions, UseQueryResult, useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { OrganisationPlanTemplateBuilderItem } from 'shared/types/organisation-plan';

interface IUpdateQuestionnaireTemplateBuilderId {
  pageId: number;
  questionnaireTemplateId: number;
  data: OrganisationPlanTemplateBuilderItem;
}

const updateOrganizationTemplateIdBuilder = ({
  pageId,
  questionnaireTemplateId,
  data
}: IUpdateQuestionnaireTemplateBuilderId): Promise<OrganisationPlanTemplateBuilderItem> => {
  return axios.put(`questionnaires/templates/${questionnaireTemplateId}/plans/${pageId}/builder`, data).then((res) => res.data);
};

const getOrganizationPlanTemplateBuilderById = (
  pageId: number,
  questionnaireTemplateId: number
): Promise<OrganisationPlanTemplateBuilderItem> => {
  return axios.get(`questionnaires/templates/${questionnaireTemplateId}/plans/${pageId}/builder`).then((res) => res.data);
};

/**
 * Query to get questionnaire template page by id
 * @param organisationPlanId - organisation plan id
 * @param questionnaireTemplateId - questionnaireTemplateId id
 */

export function useUpdateOrganizationTemplateBuilderById<TError>({
  options = {},
  onSuccess
}: {
  options?: Omit<
    UseMutationOptions<OrganisationPlanTemplateBuilderItem, TError, IUpdateQuestionnaireTemplateBuilderId, any>,
    'mutationFn' | 'onSuccess' | 'variables'
  >;
  onSuccess?: () => void;
}) {
  return useMutation(updateOrganizationTemplateIdBuilder, {
    ...options,
    // Update cache after success request
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
    // Always refetch after error:
    onSettled: () => {}
  });
}

export const useGetOrganizationPlanTemplateBuilderById = (
  id: number,
  questionnaireTemplateId: number
): UseQueryResult<OrganisationPlanTemplateBuilderItem> => {
  return useQuery([`questionnaires/templates/${questionnaireTemplateId}/plans/${id}/builder`], () =>
    getOrganizationPlanTemplateBuilderById(id, questionnaireTemplateId)
  );
};
