import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import { IVenue } from 'shared/types/venue';
import { IPaginatedResult } from 'shared/types/paginatedResult';

const fetchVenues = async (page = 1, search?: string, isActive?: boolean): Promise<IPaginatedResult<IVenue>> => {
  return axios
    .get(`${import.meta.env.VITE_REACT_APP_API_URL}/venues`, { params: { pageNumber: page, name: search, isActive } })
    .then((res) => res.data);
};

interface IProps {
  isActive?: boolean;
  search?: string;
}

export const useGetVenuesInfinity = ({ isActive, search }: IProps = {}) => {
  const query = useInfiniteQuery({
    queryKey: ['venues-select', { search }],
    queryFn: async ({ pageParam = 1 }) => await fetchVenues(pageParam, search, isActive),
    getNextPageParam: (lastPage: IPaginatedResult<IVenue>) => {
      const MAX_TEMPLATES = Math.ceil(lastPage.totalRecords / lastPage.pageSize);
      if (lastPage.pageNumber < MAX_TEMPLATES) {
        return lastPage.pageNumber + 1;
      } else {
        return undefined;
      }
    }
  });

  const data = useMemo(() => {
    return query.data?.pages
      .map((x) => x.data)
      .flat(1)
      .filter((item) => !!item) as IVenue[];
  }, [query.data?.pages]);

  return { ...query, data };
};
