import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GeneralLabelBoxWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  marginBottom: '40px',
  width: '74%'
}));

export const ImageChoiceWidgetBox = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: '20px',
  height: '100%',
  flexWrap: 'wrap'
}));

export const BoxWidget = styled(Box)(() => ({
  display: 'flex',
  width: '205px',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  gap: '10px',
  borderRadius: '4px',
  marginBottom: 4
}));

export const WrapperButton = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  position: 'absolute',
  bottom: '10px'
}));
