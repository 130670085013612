import { useIntl } from 'react-intl';
import { Stack, Tooltip } from '@mui/material';
import { ArrowSwap16Regular, Eye16Regular, EyeOff16Regular } from '@fluentui/react-icons';
import { HideLabelButton, ReplacementTextButton } from './Button.styles';

interface IReplacementTextButtons {
  isReplacementValueShown?: boolean;
  replaceValueCallback?: () => void;
  isLabelShown?: boolean;
  labelShownCallback?: () => void;
}

const ReplacementTextButtons = ({
  isReplacementValueShown,
  replaceValueCallback,
  isLabelShown,
  labelShownCallback
}: IReplacementTextButtons) => {
  const intl = useIntl();

  const replacementMessage = intl.formatMessage({
    id: isReplacementValueShown ? 'organisation.remove-replacement' : 'organisation.add-replacement'
  });

  const labelShownMessage = intl.formatMessage({ id: isLabelShown ? 'organisation.hide-label' : 'organisation.show-label' });

  return (
    <Stack pl={1} direction="row">
      {!isLabelShown && (
        <Tooltip arrow enterDelay={2000} placement="top" title={replacementMessage}>
          <ReplacementTextButton isActive={isReplacementValueShown} onClick={replaceValueCallback}>
            <ArrowSwap16Regular />
          </ReplacementTextButton>
        </Tooltip>
      )}
      {!isReplacementValueShown && (
        <Tooltip arrow enterDelay={2000} placement="top" title={labelShownMessage}>
          <HideLabelButton isActive={isLabelShown} onClick={labelShownCallback}>
            {isLabelShown ? <EyeOff16Regular /> : <Eye16Regular />}
          </HideLabelButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default ReplacementTextButtons;
