import { lazy } from 'react';
import Loadable from 'shared/components/Loadable';
import AuthGuard from 'shared/components/route-guard/AuthGuard';
import MainLayout from 'shared/layouts/MainLayout';
import InvoiceEditor from 'pages/Invoices/InvoiceEditor';
import { INVOICES_ROUTES } from './constants/paths';

const InvoicesPage = Loadable(lazy(() => import('pages/Invoices')));

export const InvoicesRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: INVOICES_ROUTES.ROOT_LINK,
          element: <InvoicesPage />
        }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout isHeaderVisible={false} isLeftMenuVisible={false} />
        </AuthGuard>
      ),
      children: [
        {
          path: INVOICES_ROUTES.LINK_BY_INVOICE_ID,
          element: <InvoiceEditor />
        }
      ]
    }
  ]
};
