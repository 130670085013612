import { FormattedMessage } from 'react-intl';
import { Question20Regular } from '@fluentui/react-icons';
import { NavItemType } from 'shared/types/menu';

const applications: NavItemType = {
  id: 'group-applications',
  title: <FormattedMessage id="applications" />,
  type: 'group',
  children: [
    {
      id: 'chat',
      title: <FormattedMessage id="chat" />,
      type: 'item',
      url: '/mantis/apps/chat',
      icon: Question20Regular,
      breadcrumbs: false
    },
    {
      id: 'calendar',
      title: <FormattedMessage id="calendar" />,
      type: 'item',
      url: '/mantis/apps/calendar',
      icon: Question20Regular
    },
    {
      id: 'customer',
      title: <FormattedMessage id="customer" />,
      type: 'collapse',
      icon: Question20Regular,
      children: [
        {
          id: 'customer-list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/mantis/apps/customer/list'
        }
      ]
    },
    {
      id: 'profile',
      title: <FormattedMessage id="profile" />,
      type: 'collapse',
      icon: Question20Regular,
      children: [
        {
          id: 'user-profile',
          title: <FormattedMessage id="user-profile" />,
          type: 'item',
          url: '/mantis/apps/profiles/user/personal'
        },
        {
          id: 'account-profile',
          title: <FormattedMessage id="account-profile" />,
          type: 'item',
          url: '/mantis/apps/profiles/account/basic'
        },
        {
          id: 'user-list',
          title: <FormattedMessage id="user-list" />,
          type: 'item',
          url: '/mantis/apps/profiles/user-list'
        },
        {
          id: 'user-card',
          title: <FormattedMessage id="user-card" />,
          type: 'item',
          url: '/mantis/apps/profiles/user-card'
        }
      ]
    },

    {
      id: 'e-commerce',
      title: <FormattedMessage id="e-commerce" />,
      type: 'collapse',
      icon: Question20Regular,
      children: [
        {
          id: 'products',
          title: <FormattedMessage id="products" />,
          type: 'item',
          url: '/mantis/apps/e-commerce/products'
        },
        {
          id: 'product-details',
          title: <FormattedMessage id="product-details" />,
          type: 'item',
          url: '/mantis/apps/e-commerce/product-details/1',
          breadcrumbs: false
        },
        {
          id: 'product-list',
          title: <FormattedMessage id="product-list" />,
          type: 'item',
          url: '/mantis/apps/e-commerce/product-list',
          breadcrumbs: false
        },
        {
          id: 'add-new-product',
          title: <FormattedMessage id="add-new-product" />,
          type: 'item',
          url: '/mantis/apps/e-commerce/add-new-product'
        },
        {
          id: 'checkout',
          title: <FormattedMessage id="checkout" />,
          type: 'item',
          url: '/mantis/apps/e-commerce/checkout'
        }
      ]
    }
  ]
};

export default applications;
