import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ILabelRowStyles {
  isHidden?: boolean;
}

interface IOriginalTextWrapperStyles {
  isImageChoice?: boolean;
}

export const LabelRow = styled(Box)<ILabelRowStyles>(({ theme, isHidden }) => ({
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: isHidden ? theme.palette.action.disabled : 'transparent',
  '& p': {
    color: theme.palette.text.primary
  },
  background: isHidden
    ? theme.palette.mode === 'dark'
      ? `repeating-linear-gradient(-45deg, #ffffff15, #ffffff15 10px, #ffffff07 10px, #ffffff07 20px)`
      : `repeating-linear-gradient(-45deg, #00000015, #00000015 10px, #00000007 10px, #00000007 20px)`
    : ''
}));

export const LabelBoxWrapper = styled(Box)(() => ({
  paddingBottom: '8px'
}));

export const OriginalTextWrapper = styled(Box)<IOriginalTextWrapperStyles>(({ isImageChoice }) => ({
  paddingLeft: '5px',
  width: isImageChoice ? '135px' : '',
  fontWeight: 450
}));
