import { useCallback, useRef } from 'react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { Search20Regular } from '@fluentui/react-icons';
import * as Styles from './DashboardFilter.styles';

interface IGeneralFilterOption {
  value: number | string;
  label: string;
}

interface IAllFilter {
  allOptions: IGeneralFilterOption[];
  textAllOptions: string;
  hasNextPage?: boolean;
  label: string;
  icon?: React.ReactNode;
  values?: (string | number)[];
  onChange: (data: (string | number)[]) => void;
  fetchNextPage?: () => void;
  sxControl?: SxProps;
  onInputChange: (value: string) => void;
  sx?: SxProps;
  isUpdated: boolean;
  tooltipLabel?: string;
}

const GeneralFilter = ({
  allOptions,
  sxControl,
  icon,
  values,
  textAllOptions,
  hasNextPage,
  tooltipLabel,
  onInputChange,
  fetchNextPage,
  onChange
}: IAllFilter) => {
  const intl = useIntl();
  const [value, setValue] = React.useState<(string | number)[]>(values || []);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const observer = useRef<IntersectionObserver | null>(null);

  const lastOptionElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (observer.current) {
        observer.current.disconnect();
        observer.current = null;
      }

      if (node && hasNextPage && fetchNextPage) {
        observer.current = new IntersectionObserver(async (entries) => {
          if (entries[0].isIntersecting) {
            fetchNextPage();
          }
        });

        observer.current.observe(node);
      }
    },
    [fetchNextPage, hasNextPage]
  );

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Escape') {
      e.stopPropagation();
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option: IGeneralFilterOption | 'all') => {
    if (option === 'all') {
      setValue((prevValue) => {
        const newValue = prevValue.length === 0 || prevValue.length < allOptions.length ? allOptions.map((opt) => opt.value) : [];
        onChange(newValue);
        return newValue;
      });
    } else {
      setValue((prevValue) => {
        const newValue = prevValue.includes(option.value)
          ? prevValue.filter((value) => value !== option.value)
          : [...prevValue, option.value];
        onChange(newValue);
        return newValue;
      });
    }
  };

  const getSelectedLabels = () => {
    return value
      .map((selectedValue) => {
        const option = allOptions.find((option) => option.value === selectedValue);
        return option ? option.label : '';
      })
      .join(', ');
  };

  const isActive = !!getSelectedLabels();

  const theme = useTheme();

  return (
    <FormControl sx={{ ...sxControl }}>
      <Tooltip arrow enterDelay={1300} title={`${tooltipLabel}`}>
        <Styles.FilterButton onClick={handleClick}>
          <>
            {icon}
            {isActive && <Box sx={{ padding: '0 5px' }}>{getSelectedLabels() || null}</Box>}
          </>
        </Styles.FilterButton>
      </Tooltip>

      <Styles.FilterMenu
        anchorOrigin={{
          vertical: 40,
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box sx={{ position: 'sticky', top: 0, zIndex: 999 }}>
          <Styles.SearchTextField
            size="medium"
            autoFocus
            placeholder={intl.formatMessage({ id: 'dashboard-table.type-to-search' })}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search20Regular />
                </InputAdornment>
              )
            }}
            onChange={(e) => onInputChange(e.target.value)}
            onKeyDown={onKeyDown}
          />
          <Divider />
          <Divider />
        </Box>
        <Box sx={{ maxHeight: '250px', overflowY: 'auto' }}>
          <MenuItem value="all" onClick={() => handleOptionClick('all')}>
            <ListItemIcon>
              <Checkbox checked={value.length === 0} />
            </ListItemIcon>
            <Typography sx={{ color: theme.palette.text.primary }}>
              <ListItemText primary={textAllOptions} />
            </Typography>
          </MenuItem>
          {allOptions &&
            allOptions.map(
              (option) =>
                option.label !== '' && (
                  <MenuItem
                    ref={allOptions[allOptions.length - 1].value === option.value ? lastOptionElementRef : null}
                    key={option.value}
                    value={option.value}
                    onClick={() => handleOptionClick(option)}
                  >
                    <ListItemIcon>
                      <Checkbox checked={value.includes(option.value)} />
                    </ListItemIcon>
                    <Typography sx={{ color: theme.palette.text.primary }}>
                      <ListItemText primary={option.label} />
                    </Typography>
                  </MenuItem>
                )
            )}
        </Box>
      </Styles.FilterMenu>
    </FormControl>
  );
};

export default GeneralFilter;
