import { FormattedMessage } from 'react-intl';
import { CalendarStar20Regular, Call20Regular, Person20Regular, PersonSupport20Regular, Star20Regular } from '@fluentui/react-icons';
import { OrganisationPlanTemplateBuilderHeaderOptions } from 'shared/types/organisation-plan';
import { IGuestAndRoomsCustomQuestion } from 'modules/builder/types';
import { IOrganisationPlanPreviewHeaderItem } from 'modules/organisation-plan-preview/components/OrganisationPlanPreviewOrganisersAndGuestsSection';

export const organisersAndGuestsSectionPreparedData = (headerOptions: OrganisationPlanTemplateBuilderHeaderOptions) => {
  const eventName = headerOptions.showEventName
    ? {
        icon: <Star20Regular />,
        text: headerOptions.name
      }
    : undefined;

  const eventDate = headerOptions.showEventDate
    ? {
        icon: <CalendarStar20Regular />,
        text: <FormattedMessage id="events.event-Date" />
      }
    : undefined;

  const contactPerson = headerOptions.showContactPerson
    ? {
        icon: <Call20Regular />,
        text: <FormattedMessage id="events.contact-person" />
      }
    : undefined;

  const eventManager = headerOptions.showEventManager
    ? {
        icon: <PersonSupport20Regular />,
        text: <FormattedMessage id="organisation.manager" />
      }
    : undefined;

  const guests = headerOptions.showNumberOfGuests
    ? headerOptions.guestDisplayOptions
        .map(
          (g: IGuestAndRoomsCustomQuestion) =>
            g.isCheck && {
              icon: <Person20Regular />,
              text: `${g.label}: -----`
            }
        )
        .filter((n: any) => !!n)
    : [];

  return [eventName, eventDate, contactPerson, eventManager, ...guests].filter((n) => !!n) as IOrganisationPlanPreviewHeaderItem[];
};
