import axios from 'axios';
import { useQuery } from 'react-query';
import { ICurrency } from 'shared/types/tenants';

const fetchAllCurrency = (): Promise<ICurrency[]> => {
  return axios.get(`/currencies`).then((res) => {
    return res.data;
  });
};
export function useGetCurrencies() {
  return useQuery(['currencies'], fetchAllCurrency);
}

const fetchAllLanguages = (): Promise<any> => {
  return axios.get(`/languages`).then((res) => {
    return res.data;
  });
};
export function useGetLanguages() {
  return useQuery(['languages'], fetchAllLanguages);
}
