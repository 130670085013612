import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { IPaginatedResult } from 'shared/types/paginatedResult';
import { IPagination } from 'shared/types/table';
import { IUser } from 'shared/types/user';

type MutationContext =
  | {
      previousUsers: IPaginatedResult<IUser> | undefined;
      newUser: IUser;
    }
  | undefined;

interface IuseEditProps<TError> {
  options?: Omit<UseMutationOptions<IUser, TError, IUser, MutationContext>, 'mutationFn' | 'onSuccess' | 'variables'>;
  onSuccess?: ((data: IUser, variables: IUser, context: MutationContext) => void | Promise<unknown>) | undefined;
  id?: number;
  pagination: IPagination;
}

const updateUser = (userInfo: IUser): Promise<IUser> => {
  return axios.put(`users/${userInfo.id}`, userInfo).then((res) => res.data);
};

export function useEditUser<TError>({ options = {}, onSuccess, id, pagination }: IuseEditProps<TError>) {
  const queryClient = useQueryClient();

  return useMutation([{ id: `${id}` }], updateUser, {
    ...options,
    // Update cache after success request
    onSuccess: (data, variables, context) => {
      const previousUsers: IPaginatedResult<IUser> | undefined = queryClient.getQueryData(['users', pagination]);
      const prev = previousUsers?.data ? previousUsers.data : [];
      const newData = prev.map((user) => {
        if (user.id !== data.id) return user;
        return { ...user, ...data };
      });

      queryClient.setQueryData(['users', pagination], { ...previousUsers, data: newData });
      queryClient.setQueryData(['user', { id: `${data.id}` }], data);

      if (onSuccess) {
        onSuccess(data, variables, context);
      }
    },

    // Optimistic Update
    /*
    onMutate: async (newUser) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['users']);

      // Snapshot the previous value
      const previousUsers: IPaginatedResult<IUser> | undefined = queryClient.getQueryData(['users']);
      const prev = previousUsers?.data ? previousUsers.data : [];

      // Optimistically update to the new value
      queryClient.setQueryData(['users'], { ...previousUsers, data: [...prev, newUser] });

      // Return a context with the previous and new users
      return { previousUsers, newUser };
    },
    */

    // If the mutation fails, use the context we returned above
    // onError: (err, newUser, context: MutationContext) => {
    //   if (context) {
    //     queryClient.setQueryData(['users'], context?.previousUsers);
    //   }
    // },
    // Always refetch after error:
    onSettled: (_, error) => {
      if (error) {
        // do something
        // queryClient.invalidateQueries(['user', { id: `${id}` }]);
        queryClient.invalidateQueries(['users']);
      }
    }
  });
}
