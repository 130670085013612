import { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';
import axios, { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';
import { TENANT_URL, TOKEN } from 'modules/auth/constants';
import { SessionAction, SessionCache, SessionDispatch, SessionState } from 'modules/auth/types/authContext';
import { ISetSessionParams, ITokenInfo } from 'modules/auth/types/loginTypes';
import { IAxiosErrorResponse } from 'shared/types/axiosErrorResponse';

const removeFromStorage = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(TENANT_URL);
};

export const axiosProxyInstance = axios.create({
  baseURL: import.meta.env.VITE_REACT_APP_API_URL,
  timeout: 1000
});

axios.defaults.baseURL = import.meta.env.VITE_REACT_APP_API_URL;

const setDefaultAuthHeaders = (token: string) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const setInterceptor = (cb: VoidFunction) => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError<IAxiosErrorResponse>) => {
      const err = error.response?.data;
      if (error.response?.status === 401) {
        cb();
        removeFromStorage();
        return Promise.reject(err ?? error);
      }

      if (err) {
        return Promise.reject(err);
      }
      if (error.isAxiosError && error.code === 'ERR_NETWORK') {
        return Promise.reject(error.message);
      }
      return Promise.reject(error);
    }
  );
};

const hasTokenExpired = (token: string) => {
  const decodedJwt: {
    exp: number;
    iat: number;
  } = jwtDecode(token);

  const expDate = new Date(decodedJwt.exp * 1000);
  const currentDate = new Date();

  return expDate.getTime() <= currentDate.getTime();
};

const AuthContext = createContext<SessionState | undefined>(undefined);
const AuthDispatchContext = createContext<SessionDispatch | undefined>(undefined);
const cache: SessionCache = {
  state: null
};

const initialState: SessionState = {
  tokenInfo: null,
  token: null,
  status: null
};

const SessionReducer = (state: SessionState = initialState, action: SessionAction): SessionState => {
  switch (action.type) {
    case 'ADD_SESSION': {
      return { ...action.payload, status: 'authenticated' };
    }
    case 'REMOVE_SESSION': {
      return { ...initialState, status: 'unauthenticated' };
    }
    case 'LOADING': {
      return {
        ...state,
        status: 'loading'
      };
    }
    default:
      return state;
  }
};
const getToken = () => {
  return localStorage.getItem(TOKEN);
};

const getTokenInfo = () => {
  const token = getToken();
  if (!token) return;
  return jwtDecode(token) as ITokenInfo;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [state, dispatch] = useReducer(SessionReducer, cache.state || initialState);
  cache.state = state;

  const getSessionOnInitLoad = () => {
    setInterceptor(() => {
      dispatch({ type: 'REMOVE_SESSION' });
    });
    const token = getToken();
    const tokenInfo = getTokenInfo();
    if (!token || !tokenInfo) {
      dispatch({ type: 'REMOVE_SESSION' });
      return;
    }

    if (hasTokenExpired(token)) {
      removeFromStorage();
      dispatch({ type: 'REMOVE_SESSION' });
      return;
    }
    setDefaultAuthHeaders(token);

    dispatch({
      type: 'ADD_SESSION',
      payload: { token, tokenInfo }
    });
  };

  useEffect(() => {
    getSessionOnInitLoad();
  }, []);

  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
    </AuthDispatchContext.Provider>
  );
};

export const useAuth = () => {
  const state = useContext(AuthContext);
  const dispatch = useContext(AuthDispatchContext);

  if (state === undefined || dispatch === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  const setSession = (response: ISetSessionParams) => {
    localStorage.setItem(TOKEN, response.token);
    if (response.tenantUrl) {
      localStorage.setItem(TENANT_URL, response.tenantUrl);
    } else {
      localStorage.removeItem(TENANT_URL);
    }
    setDefaultAuthHeaders(response.token);
    dispatch({ type: 'ADD_SESSION', payload: response });
  };

  const removeSession = () => {
    const tenantUrl = localStorage.getItem(TENANT_URL);
    removeFromStorage();

    if (tenantUrl) {
      window.location.assign(`${window.location.origin}/login/${tenantUrl}`);
      return;
    }
    window.location.assign(`${window.location.origin}/login-admin`);
  };

  const setLoading = () => {
    dispatch({ type: 'LOADING' });
  };
  const resetPassword = async (_email: string) => {};
  const firebaseEmailPasswordSignIn = () => {};
  const updateProfile = () => {};
  const firebaseRegister = () => {};

  return {
    ...state,
    user: { name: 'mock name', role: state.tokenInfo?.role },
    isLoggedIn: state.status === 'authenticated',
    setSession,
    logout: removeSession,
    setLoading,
    resetPassword,
    firebaseEmailPasswordSignIn,
    updateProfile,
    firebaseRegister
  };
};
