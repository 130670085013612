import { styled } from '@mui/material/styles';
import {
  Box as MuiBox,
  IconButton as MuiIconButton,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  TextField as MuiTextField,
  Typography as MuiTypography
} from '@mui/material';

export const TableCellNestedRow = styled(MuiTableCell)(() => ({
  '.MuiTableRow-root &.MuiTableCell-root:first-of-type': {
    padding: 12
  },
  '.MuiTableRow-root &.MuiTableCell-root:last-of-type': {
    padding: 12
  }
}));

export const TableRow = styled(MuiTableRow)(() => ({
  verticalAlign: 'top',
  '.MuiTableBody-root &:hover': {
    backgroundColor: 'unset'
  }
}));

export const TextField = styled(MuiTextField)(({ theme }) => ({
  '.MuiInputBase-root': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '6px'
  }
}));

export const Typography = styled(MuiTypography)(() => ({
  paddingTop: '10px'
}));

export const Box = styled(MuiBox)(() => ({
  display: 'flex'
}));

export const IconButton = styled(MuiIconButton)(() => ({
  marginTop: '2px',
  marginLeft: '4px'
}));

interface INestedCellBoxProps {
  isMarginTopUsed: boolean;
}

export const NestedCellBox = styled(MuiBox)<INestedCellBoxProps>(({ isMarginTopUsed }) => ({
  marginTop: isMarginTopUsed ? '4px' : undefined,
  width: '100%'
}));
