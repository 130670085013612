import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { PersonSupport20Regular } from '@fluentui/react-icons';
import { useGetAllEventManagersInfinite } from 'modules/dashboard/hooks/useGetAllEventManagers';
import { useUpdateWidgetById } from 'api/hooks/dashboard/useUpdateWidgetByID';
import { useInfoMessage } from 'shared/hooks/useInfoMessage';
import { textError } from 'shared/utils/infoText';
import { IUpdateWidgetStore } from 'modules/dashboard/components/widgets/TableDashboardWidget/components/UpperHeaderTable';
import { WidgetsDashboardType } from 'modules/dashboard/types';
import GeneralFilter from './GeneralFilter';

interface IEventManagersFilter {
  widget?: WidgetsDashboardType;
  isCustomize: boolean;
  updateWidgetFilters: ({ field, value }: IUpdateWidgetStore) => void;
}

const EventManagersFilter = ({ widget, updateWidgetFilters, isCustomize }: IEventManagersFilter) => {
  const [searchEventManagers, setSearchEventManagers] = useState('');

  const {
    data: dataAllEventManagers,
    isFetchingNextPage: allEventFetchingNextPage,
    hasNextPage: allEventHasNextPage,
    fetchNextPage: allEventFetchNextPage
  } = useGetAllEventManagersInfinite(searchEventManagers);

  const intl = useIntl();
  const { successMessage, errorMessage } = useInfoMessage();

  const { mutateAsync: updateWidgetById, isLoading } = useUpdateWidgetById({
    onSuccess() {
      successMessage(intl.formatMessage({ id: 'dashboard.dashboard-widget-edited-successfully' }));
    },
    options: {
      onError() {
        errorMessage(textError(intl.formatMessage({ id: 'dashboard.dashboard-widget-edited-failed' })));
      }
    },
    widgetId: widget?.id?.toString() || ''
  });

  const debouncedRequestQuestionnaire = useMemo(() => {
    return debounce(async (managers: string) => {
      setSearchEventManagers(managers.length ? managers : '');
    }, 1000);
  }, []);

  const handleChangeEventManagers = async (managers: (string | number)[]) => {
    if (!managers || !widget) return;

    // Check if the value has changed
    const hasValueChanged = !isEqual(widget.filters?.eventManagers, managers);

    if (!widget.id || isCustomize) updateWidgetFilters({ field: 'eventManagers', value: managers });

    if (widget.id && !isCustomize && hasValueChanged) {
      await updateWidgetById(
        {
          ...widget,
          filters: {
            ...widget.filters,
            eventManagers: managers as number[]
          },
          systemId: widget.systemId
        },
        {
          onSuccess: () => {
            updateWidgetFilters({ field: 'eventManagers', value: managers });
          }
        }
      );
    }
  };

  const onChangeInputEventManagers = (managers: string) => {
    debouncedRequestQuestionnaire(managers);
  };

  const newValue = dataAllEventManagers?.map((el) => {
    return {
      value: el.id,
      label: el.fullName
    };
  });

  return (
    <GeneralFilter
      sx={{ width: '170px' }}
      values={widget?.filters?.eventManagers || []}
      hasNextPage={allEventHasNextPage}
      fetchNextPage={allEventFetchNextPage}
      onChange={handleChangeEventManagers}
      onInputChange={onChangeInputEventManagers}
      sxControl={{}}
      label={intl.formatMessage({ id: 'dashboard-table.all-event-managers' })}
      tooltipLabel={intl.formatMessage({ id: 'dashboard-table.filter-by-eventmanager' })}
      icon={<PersonSupport20Regular />}
      textAllOptions={intl.formatMessage({ id: 'dashboard-table.all-event-managers' })}
      allOptions={newValue || []}
      isUpdated={isLoading || allEventFetchingNextPage}
    />
  );
};

export default EventManagersFilter;
