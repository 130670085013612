import { isOrganisationPlanWidgetOnly } from 'modules/organisation-plan-templates/utils/isOrganisationPlanWidgetOnly';
import { OrganisationPlanTemplateBuilderItem } from 'shared/types/organisation-plan';

interface IPreparedHeaderOptionsProps {
  organizationPlanTemplateBuilderData: OrganisationPlanTemplateBuilderItem;
}

/**
 * Util function to get widgets and filtered sections for set organisation plan structure.
 * @param param.organizationPlanTemplateBuilderData organisation plan template builder data (layout, item, sections, links).
 * @returns object with prepared widgets data and filtered sections.
 */
export const getWidgetsAndFilteredSectionsOrgPlanTemplate = ({ organizationPlanTemplateBuilderData }: IPreparedHeaderOptionsProps) => {
  const widgets = (organizationPlanTemplateBuilderData?.items || []).map((w) => {
    if (isOrganisationPlanWidgetOnly(w.type)) {
      return {
        ...w,
        ...w.layout,
        widgetId: w.widgetKey
      };
    }
    return {
      ...w.widget?.layout,
      ...w.alternativeLabels,
      id: w.id,
      sectionId: w.sectionId,
      widgetKey: w.widgetKey,
      showOnlySelectedAnswer: w.showOnlySelectedAnswer,
      linkedWidgetId: w.widget?.widgetId,
      widgetId: w.widgetKey
    };
  });

  const sections = organizationPlanTemplateBuilderData?.sections || [];

  const filteredSections = sections
    .sort((a, b) => a.order - b.order)
    .map((section) => ({
      ...section,
      layout: section.layout.filter((layout) => widgets.some((widget) => widget.widgetId === layout.i))
    }));

  return { widgets, filteredSections };
};
