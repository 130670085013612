import { useInfiniteQuery } from 'react-query';
import { useMemo } from 'react';
import axios from 'axios';
import { ITags } from 'modules/dashboard/types';

interface ITagsParam {
  data: ITags[];
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
}

const fetchTags = async (page = 1, searchTags: string) => {
  return axios
    .get(`${import.meta.env.VITE_REACT_APP_API_URL}/article-tags`, {
      params: {
        name: searchTags,
        pageNumber: page
      }
    })
    .then((res) => res.data);
};

export const useGetTagsInfinite = (searchTags: string) => {
  const query = useInfiniteQuery({
    queryKey: ['tags', searchTags],
    queryFn: async ({ pageParam = 1 }) => await fetchTags(pageParam, searchTags),
    getNextPageParam: (lastPage: ITagsParam, allPages: ITagsParam[]) => {
      const MAX_USERS = Math.ceil(lastPage.totalRecords / 10);
      if (allPages.length < MAX_USERS) {
        return allPages.length + 1;
      } else {
        return undefined;
      }
    }
  });

  const data = useMemo(() => {
    return query.data?.pages.map((x) => x.data).flat(1);
  }, [query.data?.pages]);

  return { ...query, data, query };
};
