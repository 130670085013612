import { useQueryClient } from 'react-query';
import { useIntl } from 'react-intl';
import { Box, FormControl, MenuItem, Select, SelectProps, Stack, Tooltip, Typography } from '@mui/material';
import { TableDashboardWidgetType } from 'modules/dashboard/types/TableDashboardWidgetType';
import { setWidgets, updateWidget } from 'store/dashboard/dashboard.slice';
import { useDispatch, useSelector } from 'store';
import { IGeneralValue } from 'modules/dashboard/types';
import DashboardFilters from 'modules/dashboard/components/DashboardFilters';
import { DashboardQueryKey } from 'shared/types/dasboard';
import { selectAllDashboardsWidgets, selectDashboardName } from 'store/dashboard/dasboard.selectors';
import { useUpdateDashboard } from 'modules/dashboard/hooks/useUpdateDashboard';
import { useInfoMessage } from 'shared/hooks/useInfoMessage';
import { textError } from 'shared/utils/infoText';
import { LayoutForNewWidget } from 'store/dashboard/dashboard.interfaces';
import {
  createMapOfWidgetsGroup,
  switchIsActiveForGroup
} from 'modules/dashboard/components/widgets/TableDashboardWidget/utils/multipleTable';
import { transferTagsBeforeSave } from 'modules/dashboard/components/widgets/TableDashboardWidget/utils/transformTagsData';
import HeaderTableActions from './HeaderTableActions';

interface IUpperHeaderTable {
  widget: TableDashboardWidgetType | undefined;
  isCustomize: boolean;
}

interface ITimeRange {
  from: Date | null;
  to: Date | null;
  type: string;
}

export interface IUpdateWidgetStore {
  field: string;
  value: (string | number)[] | string | ITimeRange | IGeneralValue[];
}

const UpperHeaderTable = ({ widget, isCustomize }: IUpperHeaderTable) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { successMessage, errorMessage } = useInfoMessage();

  const nameDashboard = useSelector(selectDashboardName);
  const allWidgets = useSelector(selectAllDashboardsWidgets);
  const { isLoading: isLoadingDashboard, mutateAsync: updateDashboard } = useUpdateDashboard({
    onSuccess() {
      successMessage(intl.formatMessage({ id: 'dashboard.dashboard-widget-edited-successfully' }));
    },
    options: {
      onError() {
        errorMessage(textError(intl.formatMessage({ id: 'dashboard.dashboard-widget-edited-failed' })));
      }
    }
  });

  const widgetsMapByGroupId = createMapOfWidgetsGroup(allWidgets);
  const optionsForGroup =
    widget?.groupId && Array.isArray(widgetsMapByGroupId[widget?.groupId]) ? widgetsMapByGroupId[widget?.groupId] : [];

  const updateWidgetFilters = async ({ field, value }: IUpdateWidgetStore) => {
    if (!widget) return;
    dispatch(
      updateWidget({
        widgetId: widget!.systemId,
        widget: {
          ...widget,
          dashboardWidgetType: widget!.dashboardWidgetType,
          filters: {
            ...widget.filters,
            [field]: value
          }
        }
      })
    );
    await queryClient.invalidateQueries([DashboardQueryKey.DashboardTable, widget.id]);
  };

  const setActiveWidgetInGroup: SelectProps<string>['onChange'] = async (event) => {
    if (!widget?.systemId) return;
    const value = event.target.value;
    const updatedWidgets = switchIsActiveForGroup(allWidgets, widget?.systemId, value);
    const widgetsWithTags = updatedWidgets.map((widget) => transferTagsBeforeSave(widget));

    if (!isCustomize) {
      await updateDashboard({
        id: Number(widget.userDashboardId),
        name: nameDashboard,
        widgets: widgetsWithTags as LayoutForNewWidget[]
      });
    }
    dispatch(setWidgets(updatedWidgets));
  };

  const textNameTableInitial = intl.formatMessage({ id: 'dashboard.custom-table' });
  const isGroupExist = optionsForGroup.length > 1;
  return (
    <>
      <Box width={'200px'}>
        {isGroupExist && (
          <FormControl variant="outlined">
            <Box>
              <Select onChange={setActiveWidgetInGroup} value={widget?.systemId} disableUnderline disabled={isLoadingDashboard}>
                {optionsForGroup.map((w, index) => (
                  <MenuItem key={w.systemId} value={w.systemId}>
                    {w?.data?.name ?? `${intl.formatMessage({ id: 'dashboard.custom-table' })} ${index || ''}`.trim()}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </FormControl>
        )}
        {!isGroupExist && (
          <Tooltip
            arrow
            title={widget?.data?.name ?? textNameTableInitial}
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: '300px'
                }
              }
            }}
          >
            <Typography textOverflow={'ellipsis'} overflow={'hidden'} whiteSpace={'nowrap'}>
              {widget?.data?.name ?? textNameTableInitial}
            </Typography>
          </Tooltip>
        )}
      </Box>
      <Stack sx={{ alignItems: 'flex-end', justifyItems: 'center' }} spacing={1} direction={'row'}>
        <DashboardFilters widget={widget} updateWidgetFilters={updateWidgetFilters} />
        <HeaderTableActions isGroupWidget={optionsForGroup.length > 1} widget={widget} isCustomize={isCustomize} />
      </Stack>
    </>
  );
};

export default UpperHeaderTable;
