import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { IUser } from 'shared/types/user';

interface IattachImageForUserParams {
  id: number;
  file: File;
}

interface IattachImageForUserResponse {
  imageUrl: 'string';
}

const attachImageForUser = ({ id, file }: IattachImageForUserParams): Promise<IattachImageForUserResponse> => {
  const dataForBody = new FormData();

  dataForBody.append('picture', file, file.name);
  id && dataForBody.append('userId', id.toString());
  return axios
    .put(`users/${id}/profile/picture`, dataForBody, {
      headers: { 'content-type': file.type }
    })
    .then((res) => res.data);
};

export function useAddImageToUser<TError>({
  options = {},
  onSuccess
}: {
  options?: Omit<UseMutationOptions<any, TError, { id: number; file: any }, any>, 'mutationFn' | 'onSuccess' | 'variables'>;
  onSuccess?: (url: string) => void;
}) {
  const queryClient = useQueryClient();

  return useMutation(['user'], attachImageForUser, {
    ...options,
    // Update cache after success request
    onSuccess: (data, variables) => {
      if (onSuccess) {
        const prevUser: IUser | undefined = queryClient.getQueryData(['user', { id: `${variables.id}` }]);
        if (prevUser) {
          prevUser.profilePictureUrl = data.imageUrl;
          queryClient.setQueryData(['user', { id: `${variables.id}` }], prevUser);
        }
        onSuccess(data?.imageUrl);
      }
    },

    // Always refetch after error:
    onSettled: (_, error) => {
      if (error) {
        // do something
        queryClient.invalidateQueries(['users']);
      }
    }
  });
}
