import { useQuery } from 'react-query';
import axios from 'axios';
import { EInvoicesQueryKeys, IInvoiceEditor } from 'shared/types/invoice';

const fetchInvoiceItems = (id: number): Promise<IInvoiceEditor['articles']> => {
  return axios.get(`${EInvoicesQueryKeys.GetInvoices}/${id}/items`).then((res) => res.data);
};

export function useGetInvoiceItems(invoiceId?: number) {
  const queryKey = [EInvoicesQueryKeys.GetInvoiceItems, { invoiceId }];
  return useQuery(queryKey, () => (invoiceId ? fetchInvoiceItems(invoiceId) : undefined), {
    enabled: typeof invoiceId === 'number'
  });
}
