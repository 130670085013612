import { roundNumbers } from 'modules/invoices/utils/calculateHelpers';

/**
 * Method to return number value to price in German/English format with custom separators
 * @param value - value
 * @param languageCode - regional settings of tenant
 * @param decimalSeparator - custom decimal separator
 * @param groupSeparator - custom group separator
 */
export const formatPriceByLanguage = (value: undefined | string | number, decimalSeparator?: string, groupSeparator?: string) => {
  if (value !== undefined) {
    const valueAsNumber = Number(value);
    const valueRounded = typeof valueAsNumber === 'number' ? roundNumbers(valueAsNumber) : value;
    const [integerPart, decimalPart] = (typeof valueRounded === 'number' ? valueRounded.toFixed(2) : Number(valueRounded).toFixed(2)).split(
      '.'
    );
    const regex = /\B(?=(\d{3})+(?!\d))/g;
    const formattedInteger = integerPart.replace(regex, groupSeparator || ',');
    const formattedDecimal = decimalPart ? `${decimalSeparator || '.'}${decimalPart}` : '';

    return `${formattedInteger}${formattedDecimal}`;
  }
  return value;
};
