import { SelectProps } from '@mui/material';
import { InvoiceStatus } from 'shared/types/invoice';
import { useGetDataByRole } from 'shared/hooks/useGetDataByRole';
import StatusSelect from 'shared/components/StatusSelect';
import { DISABLE_INVOICE_STATUS_AFTER_SENT_EMAIL } from 'modules/invoices/constants/allInvoiceTable';

interface IInvoiceStatusSelect {
  name: string;
  value: InvoiceStatus;
  handleChange: SelectProps['onChange'];
  isSentEmail: boolean;
}

const InvoiceStatusSelect = ({
  name,
  handleChange,
  value,
  isSentEmail,
  ref: _ref,
  type: _type,
  ...rest
}: IInvoiceStatusSelect & SelectProps) => {
  const { invoiceStateChips, invoiceDisabledStatus } = useGetDataByRole();
  const disablesMenu = isSentEmail ? DISABLE_INVOICE_STATUS_AFTER_SENT_EMAIL : (invoiceDisabledStatus ?? []);
  return (
    <StatusSelect
      {...rest}
      name={name}
      value={value}
      handleChange={handleChange}
      options={invoiceStateChips}
      disabledStatus={disablesMenu}
    />
  );
};

export default InvoiceStatusSelect;
