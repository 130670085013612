import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const WarningIcon = styled(Box)(({ theme }) => ({
  marginRight: '10px',
  '& .error svg': {
    color: `${theme.palette.error.main} !important`
  },
  '& .warning svg': {
    color: `${theme.palette.warning.main} !important`
  },
  '& .success svg': {
    color: `${theme.palette.success.main} !important`
  }
}));
