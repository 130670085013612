import { UseMutationOptions, useMutation } from 'react-query';
import axios from 'axios';

interface IUpdateShareTable {
  id: number;
}
interface IPromiseShareTable {
  id: number;
  sharingToken: string;
}
const updateShareTable = ({ id }: IUpdateShareTable): Promise<IPromiseShareTable> => {
  return axios.patch(`dashboard/table/${id}/share`).then((res) => res.data);
};

interface UseUpdateShareTableOptions<TError> {
  options?: Omit<UseMutationOptions<IUpdateShareTable, TError, any, IUpdateShareTable>, 'mutationFn' | 'onSuccess' | 'variables'>;
  onSuccess?: () => void;
}

export function useUpdateShareTable<TError>(props: UseUpdateShareTableOptions<TError> = {}) {
  const { options = {}, onSuccess } = props;

  return useMutation(updateShareTable, {
    ...options,
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
    onSettled: () => {}
  });
}
