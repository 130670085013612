import { styled } from '@mui/material/styles';
import { Dialog, DialogActions, Grid, IconButton, InputLabel } from '@mui/material';

export const WrapperDialog = styled(Dialog)(() => ({
  '& form': {
    backgroundColor: 'white'
  }
}));

export const WrapperContentEmail = styled(Grid)(({ theme }) => ({
  padding: '5px 0',
  '& .rdw-editor-wrapper': {
    minHeight: 300
  },
  '& .Mui-disabled': {
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: 6,
    backgroundColor: theme.palette.secondary.light
  }
}));

export const AttachmentLabel = styled(InputLabel)(() => ({
  padding: '0 10px 10px 0px'
}));

export const ButtonsAction = styled(DialogActions)(() => ({
  padding: 10
}));

export const CloseButtonTop = styled(IconButton)(() => ({
  position: 'absolute',
  top: 10,
  right: 10
}));
