import { ITableDashboardWidgetColumn } from 'modules/dashboard/types/TableDashboardWidgetType';

export const reorderAvailableColumns = (
  availableColumns: ITableDashboardWidgetColumn[],
  selectedColumns: ITableDashboardWidgetColumn[]
): ITableDashboardWidgetColumn[] => {
  const selectedColumnNames = selectedColumns.map(({ name }) => name);

  const sortedSelectedColumns = [...selectedColumns].sort((a, b) => a.order - b.order);
  const sortedRemainingColumns = [...availableColumns]
    .filter((item) => !selectedColumnNames.includes(item.name))
    .sort((a, b) => a.order - b.order);

  return [...sortedSelectedColumns, ...sortedRemainingColumns];
};
