import axios from 'axios';
import { QueryKey, UseQueryOptions, useQuery } from 'react-query';
import { useAuth } from 'shared/contexts/AuthContext';
import { IPaginatedResult } from 'shared/types/paginatedResult';
import { IPagination } from 'shared/types/table';
import { IUser } from 'shared/types/user';

interface IuseGetUserByIdProps {
  id?: string;
}

const fetchUserById = (id: string): Promise<IUser> => {
  return axios.get(`users/${id}`).then((res) => res.data);
};

const fetchAllUsers = (pagination?: IPagination, isActive?: boolean): Promise<IPaginatedResult<IUser>> => {
  return axios.get(`users/`, { params: { ...pagination, ...(isActive !== undefined && { isActive }) } }).then((res) => res.data);
};

export function useGetCurrentUser(options?: Omit<UseQueryOptions<IUser, unknown, IUser, QueryKey>, 'queryKey' | 'queryFn'> | undefined) {
  const { tokenInfo } = useAuth();
  const finalOptions = options ?? ({} as Omit<UseQueryOptions<IUser, unknown, IUser, QueryKey>, 'queryKey' | 'queryFn'> | undefined);
  return useQuery<IUser>(['user', { id: tokenInfo?.sub }], () => fetchUserById(tokenInfo?.sub ?? ''), {
    enabled: !!tokenInfo?.sub,
    ...finalOptions
  });
}

export function useGetUserById({ id }: IuseGetUserByIdProps) {
  return useQuery<IUser>(['user', { id }], () => fetchUserById(id ?? ''), { enabled: !!id, refetchOnMount: true });
}

export function useGetUsers(pagination?: IPagination, isActive?: boolean) {
  const queryKey = pagination ? ['users', pagination, { isActive }] : ['users'];
  return useQuery(queryKey, () => fetchAllUsers(pagination, isActive));
}
