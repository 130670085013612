import { EEditorType } from 'modules/editor/types/editor';

export const intlEditor = {
  btnLock: 'invoices.invoice-editor-button-lock',
  btnUnLock: {
    [EEditorType.Invoices]: 'invoices.modal-title-update',
    [EEditorType.Offers]: 'invoices.invoice-editor-button-lock'
  },
  details: {
    billedTo: {
      [EEditorType.Invoices]: 'invoices.invoice-editor-billed-to',
      [EEditorType.Offers]: 'offer.label-offer-to'
    },
    number: {
      [EEditorType.Invoices]: 'invoices.invoice-editor-invoice-number',
      [EEditorType.Offers]: 'offer.modal-field-serial-number'
    },
    date: {
      [EEditorType.Invoices]: 'invoices.invoice-editor-date-of-invoice',
      [EEditorType.Offers]: 'offer.modal-field-requested-event-date'
    },
    performanceDate: {
      [EEditorType.Invoices]: 'invoices.invoice-editor-performance-date',
      [EEditorType.Offers]: 'offer.date-of-offer'
    }
  },
  closingText: {
    [EEditorType.Invoices]: 'invoices.invoice-editor-invoice-closing-message',
    [EEditorType.Offers]: 'offer.editor-closing-message'
  }
};
