import { useIntl } from 'react-intl';
import { Tooltip } from '@mui/material';

interface ITooltipWrapper {
  children: JSX.Element;
  title?: string;
}

const TooltipWrapper = ({ children, title }: ITooltipWrapper) => {
  const intl = useIntl();
  if (!title?.length) {
    return children;
  }
  return (
    <Tooltip arrow title={intl.formatMessage({ id: title }) ?? ''}>
      {children}
    </Tooltip>
  );
};

export default TooltipWrapper;
