import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { ISideBarWidget, OrganisationPlanWidgetsType } from 'modules/organisation-plan-templates/types';
import { IGuestAndRoomsCustomQuestion } from 'modules/builder/types';
import { IOrganisationPlanSection } from 'modules/organisation-plan-templates/types/organisationPlanSection';
import { IOrgPlanLinkObjectForSelect } from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateLinkingTab/types';
import { OrganisationPlanTemplateBuilderHeaderOptions } from 'shared/types/organisation-plan';
import {
  IAddNewOrganisationPlanElementsPayload,
  IEditWidgetPayload,
  ILinkIdsPayload,
  IOrganisationPlanTemplateState,
  IUpdateLinkPayload,
  IUpdateOrganisationPlanBuilderLayout
} from './organization-plan-template.types';
import {
  addNewOrganisationPlanElementReducer,
  addNewOrganisationPlanSectionReducer,
  deleteOrganisationPlanSectionReducer,
  deleteOrganisationPlanWidgetReducer,
  orgPlanApplyLinkingRulesReducer,
  reorderSectionsByDndReducer,
  setManyWidgetsReducer,
  updateOrganisationPlanLayoutPartialReducer,
  updateOrganisationPlanWidgetReducer,
  updateShowOnlySelectedAnswerWidgetReducer
} from './organization-plan-template.action-creators';

export const usedWidgetsEntityAdapter = createEntityAdapter<OrganisationPlanWidgetsType>({
  selectId: (widget) => widget.widgetId
});
export const sectionsEntityAdapter = createEntityAdapter<IOrganisationPlanSection>({
  selectId: (section) => section.sectionId
});
export const orgPlanLinksEntityAdapter = createEntityAdapter<IOrgPlanLinkObjectForSelect>({
  selectId: (link) => link.linkId
});

const initialHeaderOptions = {
  name: '',
  showEventName: false,
  showEventDate: false,
  showContactPerson: false,
  showEventManager: false,
  showNumberOfGuests: false,
  guestDisplayOptions: []
};

// initial state
const initialState: IOrganisationPlanTemplateState = {
  sections: sectionsEntityAdapter.getInitialState({}),
  usedWidgets: usedWidgetsEntityAdapter.getInitialState({}),
  activeInfoFromQuestionnaireSettings: '',
  editWidgetSettings: null,
  activeLinkingSettings: null,
  draggableItemOrgPlan: null,
  activeInfoFromQuestionnaireSettingsSectionId: '',
  links: orgPlanLinksEntityAdapter.getInitialState({}),
  headerOptions: initialHeaderOptions,
  isPreviewPageOrgPlan: false,
  isEvent: false,
  primaryState: {
    links: [],
    usedWidgets: [],
    sections: [],
    headerOptions: initialHeaderOptions
  }
};

const organisationPlanTemplateBuilder = createSlice({
  name: 'organisationPlanTemplateBuilder',
  initialState,
  reducers: {
    addNewOrganisationPlanSection: addNewOrganisationPlanSectionReducer,
    setManyWidgets: setManyWidgetsReducer,
    deleteOrganisationPlanSection: deleteOrganisationPlanSectionReducer,
    addNewOrganisationPlanElement: addNewOrganisationPlanElementReducer,
    reorderSectionsByDnd: reorderSectionsByDndReducer,
    updateOrganisationPlanWidget: updateOrganisationPlanWidgetReducer,
    updateShowOnlySelectedAnswerWidget: updateShowOnlySelectedAnswerWidgetReducer,
    updateOrganisationPlanLayoutPartially: updateOrganisationPlanLayoutPartialReducer,
    deleteOrganisationPlanWidget: deleteOrganisationPlanWidgetReducer,
    orgPlanApplyLinkingRules: orgPlanApplyLinkingRulesReducer,
    setDraggableItemOrganisationPlan(state, action: PayloadAction<ISideBarWidget | null>) {
      state.draggableItemOrgPlan = action.payload;
    },
    updateOrganisationPlanBuilderLayout(state, { payload: { layout, sectionId } }: PayloadAction<IUpdateOrganisationPlanBuilderLayout>) {
      sectionsEntityAdapter.updateOne(state.sections, { id: sectionId, changes: { layout } });
    },
    setOrganisationPlanStructure(
      state,
      { payload: { sections, links, usedWidgets } }: PayloadAction<IAddNewOrganisationPlanElementsPayload>
    ) {
      orgPlanLinksEntityAdapter.setAll(state.links, links);
      usedWidgetsEntityAdapter.setAll(state.usedWidgets, usedWidgets);
      sectionsEntityAdapter.setAll(state.sections, sections);
    },
    setActiveInfoFromQuestionnaireSettings(state, { payload }: PayloadAction<string>) {
      state.activeLinkingSettings = null;
      state.activeInfoFromQuestionnaireSettings = payload;
      state.editWidgetSettings = null;
    },
    setActiveInfoFromQuestionnaireSettingsSectionId(state, { payload }: PayloadAction<string>) {
      state.activeLinkingSettings = null;
      state.activeInfoFromQuestionnaireSettingsSectionId = payload;
      state.editWidgetSettings = null;
    },
    setTitleOfSection(state, { payload: { sectionId, title } }: PayloadAction<{ sectionId: string; title: string }>) {
      const section = sectionsEntityAdapter.getSelectors().selectById(state.sections, sectionId);
      if (section) {
        sectionsEntityAdapter.updateOne(state.sections, { id: sectionId, changes: { ...section, name: title } });
      }
    },
    updateActiveLinkingSettings(state, { payload }: PayloadAction<IOrganisationPlanTemplateState['activeLinkingSettings']>) {
      state.activeLinkingSettings = payload;
      state.activeInfoFromQuestionnaireSettings = '';
      state.activeInfoFromQuestionnaireSettingsSectionId = '';
    },
    setEditWidgetSettings(state, { payload }: PayloadAction<IEditWidgetPayload | null>) {
      state.editWidgetSettings = payload;
    },

    addOrgPlanLink(state, action: PayloadAction<Omit<IOrgPlanLinkObjectForSelect, 'linkId'>>) {
      const linkId = uuidv4();
      orgPlanLinksEntityAdapter.addOne(state.links, { ...action.payload, linkId });
    },
    updateOrgPlanLink(state, { payload }: PayloadAction<IUpdateLinkPayload>) {
      const prevLinkState = { ...state.usedWidgets.entities[payload.linkId] };
      orgPlanLinksEntityAdapter.updateOne(state.links, {
        id: payload.linkId,
        changes: { ...prevLinkState, ...payload.link }
      });
    },
    removeOrgPlanLink(state, { payload }: PayloadAction<IOrgPlanLinkObjectForSelect['linkId']>) {
      orgPlanLinksEntityAdapter.removeOne(state.links, payload);
    },
    removeOrgPlanLinks(state, { payload }: PayloadAction<ILinkIdsPayload>) {
      orgPlanLinksEntityAdapter.removeMany(state.links, payload);
    },
    setAllHeaderOptions(state, { payload }: PayloadAction<OrganisationPlanTemplateBuilderHeaderOptions>) {
      state.headerOptions = payload;
    },
    setHeaderOptionByField(
      state,
      {
        payload: { field, value }
      }: PayloadAction<{
        field: string;
        value: string | boolean | IGuestAndRoomsCustomQuestion[];
      }>
    ) {
      state.headerOptions = { ...state.headerOptions, [field]: value };
    },
    setIsEvent(state, { payload }: PayloadAction<boolean>) {
      state.isEvent = payload;
    },
    setIsPreviewOrgPlan(state, { payload }: PayloadAction<boolean>) {
      state.isPreviewPageOrgPlan = payload;
    },
    setPrimaryState(
      state,
      { payload: { sections, links, usedWidgets, headerOptions } }: PayloadAction<IOrganisationPlanTemplateState['primaryState']>
    ) {
      state.primaryState = { sections, links, usedWidgets, headerOptions };
    },
    restartPrimaryState(
      state,
      { payload: { usedWidgets, sections, links, headerOptions } }: PayloadAction<IOrganisationPlanTemplateState['primaryState']>
    ) {
      state.primaryState = {
        links: links,
        sections: sections,
        usedWidgets: usedWidgets,
        headerOptions: headerOptions
      };
    }
  }
});

export default organisationPlanTemplateBuilder.reducer;

export const {
  updateOrganisationPlanBuilderLayout,
  addNewOrganisationPlanElement,
  updateOrganisationPlanWidget,
  setManyWidgets,
  setIsPreviewOrgPlan,
  setActiveInfoFromQuestionnaireSettings,
  setActiveInfoFromQuestionnaireSettingsSectionId,
  updateOrganisationPlanLayoutPartially,
  deleteOrganisationPlanWidget,
  setOrganisationPlanStructure,
  updateShowOnlySelectedAnswerWidget,
  setDraggableItemOrganisationPlan,
  addNewOrganisationPlanSection,
  deleteOrganisationPlanSection,
  reorderSectionsByDnd,
  setTitleOfSection,
  updateActiveLinkingSettings,
  addOrgPlanLink,
  updateOrgPlanLink,
  removeOrgPlanLink,
  orgPlanApplyLinkingRules,
  setEditWidgetSettings,
  removeOrgPlanLinks,
  setAllHeaderOptions,
  setHeaderOptionByField,
  setIsEvent,
  setPrimaryState,
  restartPrimaryState
} = organisationPlanTemplateBuilder.actions;
