import { Helmet } from 'react-helmet-async';

interface IPageTitleProps {
  title?: string;
}

/**
 * Renders the title of the page. If no title is provided, "Event Cloud" is used as the default title.
 * @param {IPageTitleProps} props - The props object containing the title to be rendered.
 * @returns {JSX.Element} - The rendered page title wrapped in a Helmet component.
 */
const PageTitle = ({ title }: IPageTitleProps) => {
  return (
    <Helmet>
      <title>{title ?? 'Event Cloud'}</title>
    </Helmet>
  );
};

export default PageTitle;
