import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IGeneralLabelTypographyWrapper {
  isMainLabelHidden: boolean;
}
export const GeneralLabelTypographyWrapper = styled(Typography)<IGeneralLabelTypographyWrapper>(({ isMainLabelHidden, theme }) => ({
  fontWeight: 600,
  fontSize: '14px',
  color: theme.palette.text.primary,
  marginBottom: '0px',
  paddingRight: '8px',
  display: isMainLabelHidden ? 'none' : 'block',
  '@media print': {
    paddingTop: '0px !important'
  }
}));

export const TypographyValueWrapper = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '14px',
  color: theme.palette.text.primary
}));
