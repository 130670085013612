import { useQuery } from 'react-query';
import axios from 'axios';
import { IOrganisationPlanTemplate } from 'shared/types/organisation-plan';
import { IPagination } from 'shared/types/table';

const fetchAllOrganisationPlanTemplates = (
  id: number,
  type: 'events' | 'questionnaire-templates',
  pagination?: IPagination
): Promise<IOrganisationPlanTemplate[]> => {
  const activeUrl = type === 'events' ? `/questionnaires/${id}/plans` : `/questionnaires/templates/${id}/plans`;
  return axios.get(activeUrl, { params: pagination }).then((res) => {
    return res.data;
  });
};

export const useGetOrganisationPlanTemplates = (
  id: number | null,
  type: 'events' | 'questionnaire-templates',
  pagination?: IPagination
) => {
  const queryKey = pagination ? ['organisation-plan-templates', id, type, pagination] : ['organisation-plan-templates', id, type];
  return useQuery(
    queryKey,
    () => {
      if (!id) return undefined;
      return fetchAllOrganisationPlanTemplates(id, type, pagination);
    },
    {
      enabled: !!id
    }
  );
};
