import { AddCircle16Regular, Edit16Regular } from '@fluentui/react-icons';
import { Button, Grid, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import { useGetDataByRole } from 'shared/hooks/useGetDataByRole';
import { IOfferDialogForm } from 'shared/types/offer';

interface IOfferModalUserActionsProps {
  openUserModal: (isNew: boolean) => void;
}

const OfferModalUserActions = ({ openUserModal }: IOfferModalUserActionsProps) => {
  const { values } = useFormikContext<IOfferDialogForm>();
  const { disableForEventManager } = useGetDataByRole();

  const handleNewCustomer = () => openUserModal(true);
  const handleUpdateCustomer = () => openUserModal(false);

  return (
    <Grid item xs={12}>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" gap="10px">
        <Button
          size="small"
          sx={{ borderRadius: '60px' }}
          variant="outlined"
          color="secondary"
          type="button"
          startIcon={<AddCircle16Regular />}
          onClick={handleNewCustomer}
          disabled={disableForEventManager}
        >
          <FormattedMessage id="events.create-new-customer" />
        </Button>
        <Button
          disabled={!Number.isInteger(values?.recipient?.id)}
          size="small"
          sx={{ borderRadius: '60px' }}
          variant="outlined"
          color="secondary"
          type="button"
          startIcon={<Edit16Regular />}
          onClick={handleUpdateCustomer}
        >
          <FormattedMessage id="invoices.modal-update-customer-btn" />
        </Button>
      </Stack>
    </Grid>
  );
};

export default OfferModalUserActions;
