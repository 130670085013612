import { Layout } from 'react-grid-layout';
import { useIntl } from 'react-intl';
import { TimeFormatEnum } from 'shared/configs/config';
import { useCurrentDateFormat, useCurrentTimeFormat } from 'shared/hooks/regionalSettings';
import { OrganisationPlanTimePickerWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanTimePickerWithReplacementType';
import {
  GeneralLabelTypographyWrapper,
  TypographyValueWrapper
} from 'modules/organisation-plan-preview/components/OrganisationPlanPreview.styles';
import PreviewWidgetWrapperOrgPlan from 'modules/organisation-plan-preview/components/PreviewWidgetWrapperOrgPlan';
import { applyFormatToDateTimePickerValue } from './util';
import * as Styles from './PreviewTimePickerWidget.styles';

interface IPreviewTimePickerWidgetProps {
  element: OrganisationPlanTimePickerWithReplacementType;
  layoutItem: Layout;
}

const PreviewTimePickerWidget = ({ element, layoutItem }: IPreviewTimePickerWidgetProps) => {
  const { replacementLabel, label, date, isMainLabelHidden, widgetId, sectionId, typeValue } = element;
  const dateFormat = useCurrentDateFormat();
  const timeFormat = useCurrentTimeFormat();
  const intl = useIntl();
  const isTwelveHoursFormat = timeFormat === TimeFormatEnum.Twelve;
  const dateAccordingToFormat = applyFormatToDateTimePickerValue({
    type: typeValue,
    date,
    dateFormat: dateFormat.format,
    isTwelveHoursFormat
  });
  const labelAfterCheck = replacementLabel || label || intl.formatMessage({ id: 'organisation.no-label-provided' });
  return (
    <PreviewWidgetWrapperOrgPlan widgetId={widgetId} sectionId={sectionId} layoutItem={layoutItem}>
      {!!element?.date && (
        <>
          <Styles.BoxLabelWrapper>
            <GeneralLabelTypographyWrapper isMainLabelHidden={!!isMainLabelHidden}>{labelAfterCheck}</GeneralLabelTypographyWrapper>
            <TypographyValueWrapper>{dateAccordingToFormat}</TypographyValueWrapper>
          </Styles.BoxLabelWrapper>
        </>
      )}
    </PreviewWidgetWrapperOrgPlan>
  );
};

export default PreviewTimePickerWidget;
