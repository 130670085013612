import { Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainLayoutBoxWrapper = styled(Box)(() => ({
  display: 'flex',
  width: '100%'
}));

export const MainLayoutContainerWrapper = styled(Container)(() => ({
  position: 'relative',
  minHeight: 'calc(100dvh - 110px)',
  display: 'flex',
  flexDirection: 'column'
}));

export const NoContainerBoxWrapper = styled(Box)(() => ({
  position: 'relative',
  minHeight: 'calc(100dvh - 110px)',
  display: 'flex',
  flexDirection: 'column'
}));
