import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IOrganisationPlanDividerBoxWrapper {
  isPreview: boolean;
}

interface IOrganisationPlanDividerBoxIcon {
  iconSize: number;
}

export const BoxWrapper = styled(Box)<IOrganisationPlanDividerBoxWrapper>(({ isPreview }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  position: 'relative',
  ...(isPreview && {
    visibility: 'hidden'
  })
}));

export const DividerArrowIcon = styled(Box)<IOrganisationPlanDividerBoxIcon>(({ iconSize }) => ({
  '& svg': {
    display: 'block',
    width: `${iconSize === 17 ? 6 : 12}px`,
    height: `${iconSize === 17 ? 6 : 12}px`
  }
}));

export const DeleteIcon = styled(IconButton)<IOrganisationPlanDividerBoxIcon>(({ iconSize }) => ({
  position: 'absolute',
  right: '20px',
  color: 'red',
  fontSize: `${iconSize === 17 ? 10 : 12}px`,
  zIndex: 9999
}));
