import { styled } from '@mui/material/styles';
import { TableCell as MuiTableCell, TableRow as MuiTableRow } from '@mui/material';

export const TableCellNestedRow = styled(MuiTableCell)(() => ({
  '.MuiTableRow-root &.MuiTableCell-root:first-of-type': {
    padding: 12
  },
  '.MuiTableRow-root &.MuiTableCell-root:last-of-type': {
    padding: 12
  }
}));

export const TableHeaderRow = styled(MuiTableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '.MuiTableCell-root': {
    textTransform: 'capitalize'
  }
}));
