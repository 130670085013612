import { ReactNode } from 'react';
import * as React from 'react';
import { IEventManager, IEventType, ILoginViewSetting } from 'shared/types/event';
import { ITenant } from 'shared/types/tenants';
import { IAdditionalContacts, IPartner } from 'shared/types/user';
import { IVenue, IVenueRoom } from 'shared/types/venue';
import { Roles } from 'modules/auth/types';

export type IAppearanceSettings = {
  backgroundColor: string;
  accentColor: string;
  textColor: string;
  fontFamily: string;
  logoImage?: any;
  logoImagePath?: string;
  headlineSize: string;
  textSize: string;
};

interface ILogoImage {
  id: number;
  type: string;
  fileUrl: string;
  fileName: string;
}

export enum QuestionnaireTaxType {
  Gross = 'Gross',
  Net = 'Net'
}

interface Language {
  id: number;
  name: string;
  code: string;
  locale: string;
}

export interface Address {
  country: string;
  city: string;
  street: string;
  postalCode: string;
}

interface Address2 {
  country: string;
  state: string;
  city: string;
  street: string;
  postalCode: string;
}

interface Details {
  companyName: string;
  tinNumber: string;
  address: Address2;
  partner: IPartner;
}

export interface ContactPeople {
  id: number;
  email: string;
  phoneNumber: string;
  name: string;
  surname: string;
  gender: string;
  profilePictureUrl: string;
  isActive: boolean;
  additionalContacts: IAdditionalContacts[];
  details?: Details;
}
interface IInfoCommon {
  id: number;
  name: string;
  eventType: string;
  language: Language;
  venue: Venue;
  contactPeople: ContactPeople[];
  questionnaire: MainQuestioners;
  eventDate?: Date;
  deadLine?: Date;
  eventManager?: IEventManager;
  requestManager?: IEventManager;
  isActive?: boolean;
  requestedEventDate?: Date;
}

export interface IEventInfo extends IInfoCommon {
  eventDate: Date;
  deadLine: Date;
  eventManager: IEventManager;
  isActive: boolean;
}

interface MainQuestioners {
  id: number;
  questionnaireTemplateId: number;
  name: string;
  taxType: string;
  isActive: boolean;
  venue: Venue;
  createdAt: string;
  tenant: Tenant;
}

export interface Venue {
  id: number;
  name: string;
  address: Address;
  isActive: boolean;
  venueRooms: IVenueRoom[] | [];
  venueManagers?: IEventManager[];
  tenantId: number;
}

export interface Tenant {
  id: number;
  name: string;
  customUrl: string;
  isActive: boolean;
  loginViewSetting: ILoginViewSetting;
  admin: Admin;
}

export interface Admin {
  id: number;
  email: string;
  phoneNumber: string;
  name: string;
  surname: string;
  gender: string;
  profilePictureUrl: string;
  isActive: boolean;
  additionalContacts: IAdditionalContacts[];
}

export interface IRequestInfo extends IInfoCommon {
  requestedEventDate: Date;
}

export interface IQuestionnaire<T = IInfoCommon> {
  id: number;
  name: string;
  isActive: boolean;
  isActiveMasterTemplate: boolean;
  taxType: QuestionnaireTaxType;
  eventType: IEventType;
  isEventManagerHidden: boolean;
  eventManagerHiddenUntil: number;
  defaultEventManager: IEventManager | null;
  appearanceSettings: IAppearanceSettings;
  questionnaireTemplateId?: number;
  createdAt?: string;
  info?: T;
  venue?: IVenue;
  logoImage?: ILogoImage;
  tenantId?: number;
  linkedEntityType?: string;
}

export interface IQuestionnaireFormData {
  name: IQuestionnaire['name'];
  isActive: IQuestionnaire['isActive'];
  isActiveMasterTemplate: IQuestionnaire['isActiveMasterTemplate'];
  createdAt: IQuestionnaire['createdAt'];
  venue: IQuestionnaire['venue'];
  taxType: { id: number; value: string } | IQuestionnaire['taxType'];
  eventType: IQuestionnaire['eventType'];
  isEventManagerHidden: IQuestionnaire['isEventManagerHidden'];
  eventManagerHiddenUntil: IQuestionnaire['eventManagerHiddenUntil'];
  defaultEventManager: IQuestionnaire['defaultEventManager'];
  backgroundColor: IQuestionnaire['appearanceSettings']['backgroundColor'];
  accentColor: IQuestionnaire['appearanceSettings']['accentColor'];
  textColor: IQuestionnaire['appearanceSettings']['textColor'];
  fontFamily: IQuestionnaire['appearanceSettings']['fontFamily'];
  headlineSize: IQuestionnaire['appearanceSettings']['headlineSize'];
  textSize: IQuestionnaire['appearanceSettings']['textSize'];
  logoImage?: any;
  logo?: {
    fileUrl: string;
    id: number;
    type: string;
  };
  tenant?: ITenant;
  submit: null;
}

export interface IQuestionnaireContextMenuProps {
  messageId: string;
  icon: ReactNode;
  onClick: () => void;
  className?: string;
  roles?: Roles[];
  disabled?: boolean;
}

export type IQuestionnaireActive = {
  id: number;
  isActive: boolean;
};
export type IQuestionnaireDuplicate = {
  id: number;
};

export type QuestionnairesPostPayload = Omit<IQuestionnaire, 'id'>;

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export interface IHandleDrawer {
  (anchor: Anchor, open: boolean): (event: React.KeyboardEvent | React.MouseEvent) => void;
}
