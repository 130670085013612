import { lazy } from 'react';
import Loadable from 'shared/components/Loadable';
import CommonLayout from 'shared/layouts/CommonLayout';

const AppContactUS = Loadable(lazy(() => import('pages/Auth/ContactUs')));

export const ContactRoutes = {
  path: '/',
  element: <CommonLayout layout="simple" />,
  children: [
    {
      path: 'contact-us',
      element: <AppContactUS />
    }
  ]
};
