import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { IInvoiceDialogForm } from 'shared/types/invoice';
import { IVenue } from 'shared/types/venue';
import { useGetDataByRole } from 'shared/hooks/useGetDataByRole';
import SelectVenue from 'shared/components/SelectVenue';
import SelectContactPerson from 'shared/components/SelectContactPerson';
import { IContactPeople } from 'shared/types/event';

const InvoiceModalRecipientAndVenue = () => {
  const { values, errors, touched, setFieldValue, validateField } = useFormikContext<IInvoiceDialogForm>();
  const { disableForEventManager } = useGetDataByRole();

  useEffect(() => {
    if (values.venue?.id && errors?.venue) {
      validateField('venue');
    }
  }, [values.venue, errors, validateField]);

  const handleChangeRecipient = (selectedObject: IContactPeople[] | IContactPeople | null | []) => {
    if (!selectedObject) return;
    setFieldValue('recipient', selectedObject);
  };

  const handleChangeVenues = (selectedVenues: IInvoiceDialogForm['venue'] | undefined) => {
    if (!selectedVenues) return;
    setFieldValue('venue', selectedVenues ?? null);
  };

  const disabledRecipient = disableForEventManager && !!values?.id;

  return (
    <>
      <Grid item xs={12} sm={6}>
        <SelectContactPerson
          value={values?.recipient as IContactPeople}
          handleChange={handleChangeRecipient}
          label="invoices.modal-field-recipient"
          placeholder="events.select-here"
          isError={Boolean(touched['recipient'] && errors['recipient'])}
          isDisabled={disabledRecipient}
          errorText={errors['recipient']}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectVenue
          value={values.venue as IVenue | null}
          isDisabled={disableForEventManager}
          onChange={handleChangeVenues}
          isError={Boolean(touched['venue'] && errors['venue'])}
        />
      </Grid>
    </>
  );
};

export default InvoiceModalRecipientAndVenue;
