import { QUESTIONNAIRES_EVENT_PREVIEW_ROUTES } from 'modules/events/constants';
import { QUESTIONNAIRES_REQUEST_PREVIEW_ROUTES } from 'modules/requests/constant/paths';

export const CUSTOMER_QUESTIONNAIRES_ROUTES = {
  baseUrl: 'questionnaires-list',
  customerQuestionnaires: 'questionnaires-list/',
  customerQuestionnairesAbsoluteRoute: '/questionnaires-list/'
};

export const ALLOWED_CUSTOMER_ROUTES = [
  QUESTIONNAIRES_EVENT_PREVIEW_ROUTES.ROOT_LINK,
  QUESTIONNAIRES_REQUEST_PREVIEW_ROUTES.ROOT_LINK,
  CUSTOMER_QUESTIONNAIRES_ROUTES.baseUrl
];
