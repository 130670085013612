import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Menu, Button, Typography, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Localization from './Localization';
import Customization from './Customization';
import useGeneralSettings from 'shared/hooks/useGeneralSettings';
import { getStorageFileUrl, typeImages } from 'shared/utils/getStorageFileUrl';
import Avatar from 'shared/components/@extended/Avatar';
import LogOut from './LogOut';

const ProfileSection = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { user } = useGeneralSettings();
  const avatar = user?.profilePictureUrl ?? '';

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const localization = useMemo(() => <Localization onHandleMenuClose={handleClose} />, []);

  const iconBackColorOpen = theme.palette.action.selected;

  return (
    <Box display="flex" justifyContent="end" width="100%">
      <Button
        variant="text"
        size="small"
        aria-label="open profile-section"
        ref={anchorRef}
        aria-controls={open ? 'profile-section-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{
          color: 'text.primary',
          backgroundColor: open ? iconBackColorOpen : 'transparent',
          padding: '5px 12px 5px 7px',
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        }}
        startIcon={<Avatar alt="profile user" src={getStorageFileUrl(avatar, typeImages.Internal)} size="xs" />}
      >
        {!user ? (
          <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100px' }} />
        ) : (
          <Typography variant="subtitle1">{user?.name + ' ' + user?.surname}</Typography>
        )}
      </Button>

      <Menu
        sx={{ mt: '5px' }}
        id="localization-grow"
        anchorEl={anchorRef.current}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {localization}
        <Customization onCloseMenu={handleClose} />
        <LogOut />
      </Menu>
    </Box>
  );
};

export default ProfileSection;
