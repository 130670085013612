import { Box } from '@mui/material';
import { DashedContainer } from 'shared/components/DashedContainer';
import { IOrganisationPlanPreview } from 'shared/types/organisation-plan-preview';
import OrganisationPlanPreviewGuestsSectionOptionInfo from './OrganisationPlanPreviewGuestsSectionOptionInfo';
import OrganisationPlanPreviewNumberOfGuestsInfo from './OrganisationPlanPreviewNumberOfGuestsInfo';
import {
  OrganisersAndGuestsInfo,
  OrganisersAndGuestsInfoDescription,
  OrganisersAndGuestsInfoItem,
  OrganisersAndGuestsInfoTitle
} from './OrganisationPlanPreviewOrganisersAndGuestsSection.styles';

// local interface
export interface IOrganisationPlanPreviewHeaderItem {
  icon: JSX.Element;
  text: string;
}

interface IOrganisationPlanPreviewHeader {
  title?: string;
  organisationPlanInfo?: IOrganisationPlanPreview;
  items?: IOrganisationPlanPreviewHeaderItem[];
}

const OrganisationPlanPreviewMainInfoSection = ({ title, organisationPlanInfo, items }: IOrganisationPlanPreviewHeader) => {
  const showEventName = organisationPlanInfo?.builder?.organizationPlanSettings?.showEventName && organisationPlanInfo?.event?.name;
  const showEventDate = organisationPlanInfo?.builder?.organizationPlanSettings?.showEventDate && organisationPlanInfo?.event?.eventDate;
  const showContactPerson =
    organisationPlanInfo?.builder?.organizationPlanSettings?.showContactPerson && organisationPlanInfo?.event?.contactPeople?.length;
  const showEventManager =
    organisationPlanInfo?.builder?.organizationPlanSettings?.showEventManager && organisationPlanInfo?.event?.eventManager?.name;
  const showNumberOfGuests =
    organisationPlanInfo?.builder?.organizationPlanSettings?.showNumberOfGuests &&
    organisationPlanInfo?.builder?.organizationPlanSettings?.guestDisplayOptions?.length;

  return (
    <DashedContainer>
      <OrganisersAndGuestsInfoTitle>{title ? title : organisationPlanInfo?.name}</OrganisersAndGuestsInfoTitle>
      <OrganisersAndGuestsInfo>
        {showEventName ? (
          <OrganisationPlanPreviewGuestsSectionOptionInfo type="eventName" content={organisationPlanInfo.event.name} />
        ) : null}
        {showEventDate ? (
          <OrganisationPlanPreviewGuestsSectionOptionInfo type="eventDate" content={`${organisationPlanInfo.event.eventDate}`} />
        ) : null}
        {showContactPerson ? (
          <OrganisationPlanPreviewGuestsSectionOptionInfo
            type="contactPerson"
            content={`${organisationPlanInfo.event.contactPeople[0].name} ${organisationPlanInfo.event.contactPeople[0].surname}`}
          />
        ) : null}
        {showEventManager ? (
          <OrganisationPlanPreviewGuestsSectionOptionInfo
            type="manager"
            content={`${organisationPlanInfo.event.eventManager.name} ${organisationPlanInfo.event.eventManager.surname}`}
          />
        ) : null}
        {showNumberOfGuests ? (
          <OrganisationPlanPreviewNumberOfGuestsInfo
            guestNumberOptions={organisationPlanInfo?.builder?.organizationPlanSettings?.guestDisplayOptions}
            guestOptionsValues={organisationPlanInfo?.guestCategories}
          />
        ) : null}
        {items && items.length
          ? items.map((i, index) => (
              <OrganisersAndGuestsInfoItem key={`person_${index}`}>
                <Box display="flex" mr={0.75} sx={{ color: '#1890FF' }}>
                  {i.icon}
                </Box>
                <OrganisersAndGuestsInfoDescription>{i.text}</OrganisersAndGuestsInfoDescription>
              </OrganisersAndGuestsInfoItem>
            ))
          : null}
      </OrganisersAndGuestsInfo>
    </DashedContainer>
  );
};

export default OrganisationPlanPreviewMainInfoSection;
