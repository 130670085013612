import { createBrowserRouter } from 'react-router-dom';
import { CustomerQuestionnairesRoutes } from 'modules/questionnaires-customer/routes';
import { ArticlesRoutes } from 'modules/articles/routes';
import { AuthRoutes } from 'modules/auth/routes';
import { BuilderRoutes } from 'modules/builder/routes';
import { ContactRoutes } from 'modules/contact/routes';
import { DashboardRoutes } from 'modules/dashboard/routes';
import { EventsRoutes } from 'modules/events/routes';
import { MaintenanceRoutes } from 'modules/maintenance/routes';
import { OrganisationPlanRoutes } from 'modules/organisation-plan-preview/routes';
import { OrganisationPlanTemplateRoutes } from 'modules/organisation-plan-templates/routes';
import { QuestionnairesRoutes } from 'modules/questionnaires/routes';
import { SettingsRoutes } from 'modules/settings/routes';
import { TenantsRoutes } from 'modules/tenants/routes';
import { UserRoutes } from 'modules/users/routes';
import { InvoicesRoutes } from 'modules/invoices/routes';
import { OffersRoutes } from 'modules/offers/routes';
import App from 'app';
import { TableRoutes } from 'modules/dashboard/tableRoutes';
import { RequestsRoutes } from 'modules/requests/routes';
import { lazy } from 'react';
import Loadable from 'shared/components/Loadable';

const Error404 = Loadable(lazy(() => import('pages/Maintenance/Error404')));

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      AuthRoutes,
      ArticlesRoutes,
      BuilderRoutes,
      ContactRoutes,
      DashboardRoutes,
      EventsRoutes,
      MaintenanceRoutes,
      QuestionnairesRoutes,
      SettingsRoutes,
      TenantsRoutes,
      UserRoutes,
      OrganisationPlanRoutes,
      TableRoutes,
      OrganisationPlanTemplateRoutes,
      CustomerQuestionnairesRoutes,
      InvoicesRoutes,
      RequestsRoutes,
      OffersRoutes,
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
]);
