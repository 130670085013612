import isEqual from 'lodash/isEqual';
import { omitBy } from 'lodash';
import { IOrganisationPlanTemplateState } from 'store/organisation-plan-template/organization-plan-template.types';
import {
  orgPlanLinksEntityAdapter,
  sectionsEntityAdapter,
  usedWidgetsEntityAdapter
} from 'store/organisation-plan-template/organisation-plan-template.slice';
import { OrganisationPlanTemplateBuilderHeaderOptions } from 'shared/types/organisation-plan';
import { IOrganisationPlanSection } from 'modules/organisation-plan-templates/types/organisationPlanSection';

/* Method to clean from value = undefined in objects of Array */
const cleanObjectFromUndefined = (arr: Array<IOrganisationPlanSection>) => {
  return arr.map((el) => {
    const clearLayout = el.layout.map((layout) => {
      return omitBy(layout, (v) => v == null);
    });
    return { ...el, layout: clearLayout };
  });
};

/* Method to compare the initial state (primaryState) and the real state of the page organisation-plan-templates */
export const checkHasDataChangesOrgPlan = (data: {
  links: IOrganisationPlanTemplateState['links'];
  sections: IOrganisationPlanTemplateState['sections'];
  usedWidgets: IOrganisationPlanTemplateState['usedWidgets'];
  headerOptions: OrganisationPlanTemplateBuilderHeaderOptions;
  primaryState: IOrganisationPlanTemplateState['primaryState'];
}): boolean => {
  const allWidgetsForCurrentPage = usedWidgetsEntityAdapter.getSelectors().selectAll(data?.usedWidgets);
  const isEqualWidgets = !isEqual(data?.primaryState?.usedWidgets, allWidgetsForCurrentPage);

  const allSectionForCurrentPage = sectionsEntityAdapter.getSelectors().selectAll(data?.sections);
  const isEqualSections = !isEqual(
    cleanObjectFromUndefined(data?.primaryState?.sections),
    cleanObjectFromUndefined(allSectionForCurrentPage)
  );

  const allLinksForCurrentPage = orgPlanLinksEntityAdapter.getSelectors().selectAll(data?.links);
  const isEqualLinks = !isEqual(data?.primaryState?.links, allLinksForCurrentPage);

  const isEqualHeader = !isEqual(data?.primaryState?.headerOptions, data?.headerOptions);
  return isEqualWidgets || isEqualSections || isEqualLinks || isEqualHeader;
};
