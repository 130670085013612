import { Layout } from 'react-grid-layout';

/**
 * Adds a new layout element to an existing layout and orders the elements based on their position.
 *
 * This function first removes an element with id '__dropping-elem__' from the layout. It then checks if there are
 * other elements at the same position as the new element. If there are, it adds the new element to the start
 * of the layout. If there are no elements at the same position, it simply adds the new element to the layout.
 * Finally, it sorts the layout elements based on their 'y' coordinate.
 *
 * @param {Layout[]} existingLayout - The existing layout to which the new element should be added.
 * @param {Layout} newLayoutElement - The new layout element to add to the layout.
 * @param {string} widgetId - The id for the new layout element.
 *
 * @returns {{layout: Layout[], widgetId: string}} - The updated layout and the id of the new element.
 */
export function addAndOrderLayoutElement(existingLayout: Layout[], newLayoutElement: Omit<Layout, 'i'>, widgetId: string) {
  let filteredLayout = existingLayout.filter((layout) => layout.i !== '__dropping-elem__');

  // Check for elements at the same position as the new widget
  const samePositionElements = filteredLayout.filter((el) => el.x === newLayoutElement.x && el.y === newLayoutElement.y);

  // If there are elements at the same position, rearrange the order
  if (samePositionElements.length > 0) {
    // Generate a new layout array with the 'dropping-element' added first
    filteredLayout = [{ ...newLayoutElement, i: widgetId }, ...filteredLayout];
  } else {
    // If there are no elements at the same position, simply add the new element
    filteredLayout.push({ ...newLayoutElement, i: widgetId });
  }

  const sortedLayout = filteredLayout.sort((layout1, layout2) => layout1.y - layout2.y);

  return { layout: sortedLayout, widgetId };
}
