import { styled } from '@mui/material';
import { alpha } from '@mui/system';

interface ICustomToolbarProps {
  isPanelHidden?: boolean;
  isEmail?: boolean;
}

const modalStyles = (theme: any) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: '10px !important',
  borderColor: theme.palette.mode === 'dark' ? '#ffffff20' : '#ffffff20',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
  height: 'fit-content',
  maxHeight: '250px',
  minWidth: '240px',
  width: 'fit-content',
  padding: '10px'
});

const inputStyles = (theme: any) => ({
  padding: '5px 3px !important',
  maxHeight: '20px !important',
  fontSize: '14px !important',
  backgroundColor: theme.palette.mode === 'light' ? '#ffffff50' : '#00000020',
  borderRadius: '4px !important',
  border: theme.palette.mode === 'dark' ? '1px solid #ffffff10' : '1px solid #00000015',
  marginBottom: '10px'
});

const buttonStyles = (theme: any) => ({
  color: theme.palette.text.primary,
  margin: '0 0 0 10px',
  backgroundColor: theme.palette.mode === 'light' ? '#fffffff20' : '#ffffff20',
  backdropFilter: 'blur(15px)',
  borderRadius: '4px !important',
  border: 'none !important',
  boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
  '&:hover': {
    boxShadow: `rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, ${theme.palette.primary.light} 0px 0px 0px 1px`,
    color: theme.palette.primary.main
  },
  '&:disabled': {
    backgroundColor: theme.palette.mode === 'light' ? '#00000005' : '#ffffff20',
    boxShadow: 'none',
    cursor: 'not-allowed',
    color: '#00000050'
  }
});

const buttonBarStyles = (theme: any) => ({
  margin: '0px 0px 0px auto'
});

export const CustomToolbar = styled('div')<ICustomToolbarProps>(({ theme, isPanelHidden, isEmail = false }) => ({
  '& .wrapperClassName': {
    border: 'none !important',
    borderRadius: '6px !important',
    boxShadow:
      theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, 0.02) 0px 0px 34px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px'
        : 'rgba(0, 0, 0, 0.02) 0px 0px 34px 0px, rgba(255, 255, 255, 0.04) 0px 0px 0px 1px'
  },
  '& .rdw-editor-toolbar': {
    position: 'sticky !important',
    top: isEmail ? '-25px !important' : '0px !important',
    zIndex: '9 !important',
    backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#3a3a3a',
    margin: '0px',
    padding: '10px 5px 5px 5px !important',
    borderRadius: '6px 6px 0 0',
    justifyContent: 'left',
    border: 'none !important',
    width: 'fit-content',
    minWidth: 'fit-content',
    '& .svg': {
      fontSize: 'initial !important',
      color: `${theme.palette.primary.main} !important`
    }
  },
  '& .rdw-colorpicker-modal': { ...modalStyles(theme), left: 'unset !important', right: '0px !important' },
  '& .rdw-link-modal': { ...modalStyles(theme), left: 'unset !important', right: '1px !important' },
  '& .rdw-image-modal': modalStyles(theme),
  '& .rdw-emoji-modal': modalStyles(theme),
  '& .rdw-embedded-modal': modalStyles(theme),

  '& .rdw-link-modal-input': inputStyles(theme),
  '& .rdw-image-modal-url-input': inputStyles(theme),
  '& .rdw-embedded-modal-link-input': inputStyles(theme),
  '& .rdw-embedded-modal-size-input': inputStyles(theme),
  '& .rdw-image-modal-size-input': inputStyles(theme),

  '& .rdw-image-modal-btn': buttonStyles(theme),
  '& .rdw-link-modal-btn': buttonStyles(theme),
  '& .rdw-embedded-modal-btn': buttonStyles(theme),

  '& .rdw-link-modal-buttonsection': buttonBarStyles(theme),
  '& .rdw-image-modal-btn-section': buttonBarStyles(theme),
  '& .rdw-embedded-modal-btn-section': buttonBarStyles(theme),

  '& .rdw-embedded-modal-header-option': {
    width: '100%'
  },
  '& .rdw-image-modal-header-option': {
    width: '100%'
  },
  '& .rdw-image-modal-header': {
    margin: '0px'
  },
  '& .rdw-dropdown-wrapper': {
    border: theme.palette.mode === 'light' ? `1px solid #00000010 !important` : '1px solid #ffffff20 !important',
    backgroundColor: theme.palette.mode === 'light' ? `#00000007 !important` : '#ffffff20 !important',
    '&:hover': {
      boxShadow: 'none !important',
      borderColor: theme.palette.mode === 'light' ? `${theme.palette.primary.light} !important` : `${theme.palette.primary.main} !important`
    },
    borderRadius: '4px',
    '& .rdw-dropdown-carettoopen': {
      filter: theme.palette.mode === 'light' ? 'none' : 'invert(0.8)'
    },
    '& .rdw-dropdown-optionwrapper': {
      width: 'fit-content',
      backgroundColor: theme.palette.background.paper,
      marginTop: '2px',
      borderRadius: '4px',
      border: 'none',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',

      '& .rdw-dropdownoption-active': {
        backgroundColor: `${alpha(theme.palette.primary.light, 0.8)} !important`
      },
      '& .rdw-dropdownoption-default:hover': {
        backgroundColor: theme.palette.mode === 'light' ? `${theme.palette.secondary.light} !important` : '#ffffff10 !important'
      }
    }
  },

  '& .rdw-option-wrapper': {
    background: 'transparent !important',
    marginRight: `0px !important`,
    border: `none !important`,
    boxShadow: `none !important`,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    height: '20px',
    borderRadius: `4px`,
    '& img': {
      filter: theme.palette.mode === 'light' ? 'none' : 'invert(0.8)'
    }
  },

  '& .rdw-option-wrapper:hover': {
    background: theme.palette.mode === 'light' ? `${theme.palette.secondary.light} !important` : `${theme.palette.action.hover} !important`
  },

  '& .rdw-option-active': {
    background:
      theme.palette.mode === 'light' ? `${theme.palette.secondary.main} !important` : `${theme.palette.action.selected} !important`,
    color: '#fff !important'
  },

  '& .rdw-italic-wrapper .rdw-option-wrapper': {
    fontSize: '0 !important'
  },

  '& .rdw-editor-main': {
    backgroundColor: theme.palette.mode === 'light' ? 'transparent !important' : '#ffffff10',
    borderRadius: '0 0 6px 6px',
    borderTop: isPanelHidden
      ? 'none'
      : theme.palette.mode === 'light'
        ? `1px solid rgba(0, 0, 0, 0.08) !important`
        : '1px solid rgba(0, 0, 0, 0.04) !important',
    padding: '0px 15px !important',
    overflow: 'auto',
    '& .public-DraftEditorPlaceholder-root': {
      color: `${theme.palette.text.secondary} !important`
    },
    '& .public-DraftEditor-content': {
      color: `${theme.palette.text.primary} !important`
    }
  }
}));
