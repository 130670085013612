import { FormattedMessage, useIntl } from 'react-intl';
import { FormHelperText, Grid, InputLabel, Stack, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { IOfferDialogForm } from 'shared/types/offer';
import OfferStatusSelect from 'modules/offers/containers/OfferStatusSelect';

const OfferModalNumberAndState = () => {
  const intl = useIntl();
  const { values, handleChange } = useFormikContext<IOfferDialogForm>();

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25}>
          <InputLabel htmlFor="offer-number">
            <FormattedMessage id="offer.modal-field-serial-number" />
          </InputLabel>
          <TextField
            InputProps={{
              readOnly: true
            }}
            id="offer-number"
            fullWidth
            value={values.offerNumber}
            name="offerNumber"
            placeholder={intl.formatMessage({ id: 'settings.enter' })}
          />
          <FormHelperText id="offer-number-helper">
            <FormattedMessage id="invoices.modal-field-readonly" />
          </FormHelperText>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25}>
          <InputLabel htmlFor="state">
            <FormattedMessage id="invoices.modal-field-state" />
          </InputLabel>
          <OfferStatusSelect readOnly={true} value={values.state} handleChange={handleChange} />
          <FormHelperText id="offer-number-helper">
            <FormattedMessage id="invoices.modal-field-readonly" />
          </FormHelperText>
        </Stack>
      </Grid>
    </>
  );
};

export default OfferModalNumberAndState;
