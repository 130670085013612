import { ReactQueryDevtools } from 'react-query/devtools';
import { Outlet } from 'react-router-dom';
import Snackbar from 'shared/components/@extended/Snackbar';
import { HidePrintElement } from 'shared/components/HidePrintElement';
import Locales from 'shared/components/Locales';
import PageTitle from 'shared/components/PageTitle';
import ReloadPrompt from 'shared/components/ReloadPrompt';
import RTLLayout from 'shared/components/RTLLayout';
import ScrollTop from 'shared/components/ScrollTop';
import ThemeCustomization from 'shared/themes';

const App = () => {
  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <ScrollTop>
            <>
              <ReloadPrompt />
              <PageTitle />
              <Outlet />
              <Snackbar />
              <HidePrintElement>{import.meta.env.DEV && <ReactQueryDevtools initialIsOpen={false} />}</HidePrintElement>
            </>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
