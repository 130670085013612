import { Layout } from 'react-grid-layout';
import { useIntl } from 'react-intl';
import { OrganisationPlanInputTextWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanInputTextWithReplacementType';
import { renderInputLabel } from 'modules/organisation-plan-preview/utils/renderInputLabelHandler';
import {
  GeneralLabelTypographyWrapper,
  TypographyValueWrapper
} from 'modules/organisation-plan-preview/components/OrganisationPlanPreview.styles';
import PreviewWidgetWrapperOrgPlan from 'modules/organisation-plan-preview/components/PreviewWidgetWrapperOrgPlan';

interface IPreviewTextWithLabelInputWidgetProps {
  element: OrganisationPlanInputTextWithReplacementType;
  layoutItem: Layout;
}

const PreviewTextWithLabelInputWidget = ({ element, layoutItem }: IPreviewTextWithLabelInputWidgetProps) => {
  const { replacementLabel, label, value, isMainLabelHidden, widgetId, sectionId } = element;
  const intl = useIntl();

  return (
    <PreviewWidgetWrapperOrgPlan widgetId={widgetId} sectionId={sectionId} layoutItem={layoutItem}>
      {!!value && (
        <>
          <GeneralLabelTypographyWrapper isMainLabelHidden={!!isMainLabelHidden}>
            {renderInputLabel(replacementLabel, label)}
          </GeneralLabelTypographyWrapper>
          <TypographyValueWrapper>{value ? value : intl.formatMessage({ id: 'organisation.no-content-provided' })}</TypographyValueWrapper>
        </>
      )}
    </PreviewWidgetWrapperOrgPlan>
  );
};

export default PreviewTextWithLabelInputWidget;
