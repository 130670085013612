import axios from 'axios';
import { API_QUESTIONNAIRES_ENDPOINTS } from 'api/endpoints/questionnaires';
import { QuestionnairePageItemDetails } from 'modules/questionnaires/types/questionnaries';
import {
  CreateUpdateQuestionnaireTemplatePageRequestData,
  EQuestionnairesSource,
  OrderPagePayloadType,
  QuestionnaireTemplatePageItem
} from 'shared/types/questionnaire-template';
import { IAttachFilePlaygroundPageById, IAttachFileQuestionnairePageAnswerById } from 'api/types/questionnaire-api-types';
/**
 * Method to get questionnaire template page data by id
 * @param id - questionnaire template page id
 * @param entitySource - can be one value from enum: event, template or request.
 * @param tenantId - tenant id. Optional.
 */
export const fetchQuestionnaireTemplatePageById = async (
  id: number,
  entitySource: EQuestionnairesSource,
  tenantId?: number
): Promise<QuestionnairePageItemDetails> => {
  const endpoins = API_QUESTIONNAIRES_ENDPOINTS[entitySource];
  try {
    const resp = await axios.get(endpoins.GET_QUESTIONNAIRE_PAGE_BY_ID(id), { params: { tenantId } });
    return resp.data;
  } catch (e) {
    throw new Error(`Failed to load Questionnaire template page with id ${id}`);
  }
};

/**
 * Get questionnaire templates pages list by id and tenant
 * @param id - questionnaire template id
 * @param tenantId - tenant id. Optional.
 * @param entitySource - can be one value from enum: event, template or request.
 */
export const fetchQuestionnaireTemplatePages = async (
  id: number,
  entitySource: EQuestionnairesSource
): Promise<QuestionnaireTemplatePageItem[]> => {
  const endpoins = API_QUESTIONNAIRES_ENDPOINTS[entitySource];
  const isTemplate = entitySource === EQuestionnairesSource.template;
  try {
    const resp = await axios.get(endpoins.GET_QUESTIONNAIRE_PAGES, {
      params: {
        [isTemplate ? 'QuestionnaireTemplateId' : 'questionnaireId']: id
      }
    });
    return resp.data;
  } catch (e) {
    throw new Error(`Failed to load Questionnaire template pages with template id ${id}`);
  }
};

/**
 * Method to create new questionnaire template page
 * @param data - request payload
 * @param entitySource - can be one value from enum: event, template or request.
 */
export const createQuestionnaireTemplatePage = async <T>(
  data: CreateUpdateQuestionnaireTemplatePageRequestData<unknown, T>,
  entitySource: EQuestionnairesSource
): Promise<QuestionnaireTemplatePageItem> => {
  const endpoins = API_QUESTIONNAIRES_ENDPOINTS[entitySource];
  try {
    const resp = await axios.post(endpoins.POST_QUESTIONNAIRE_PAGES, data);
    return resp.data;
  } catch (e) {
    throw new Error('Failed to create the new questionnaire template page.');
  }
};

/**
 * Method to update questionnaire template page via patch request
 * @param id - page id
 * @param data - request payload
 * @param entitySource - can be one value from enum: event, template or request.
 */
export const updateQuestionnaireTemplatePagePatch = async <T>(
  id: number,
  data: Partial<CreateUpdateQuestionnaireTemplatePageRequestData<unknown, T>>,
  entitySource: EQuestionnairesSource
): Promise<QuestionnaireTemplatePageItem> => {
  const endpoins = API_QUESTIONNAIRES_ENDPOINTS[entitySource];

  try {
    const res = await axios.patch(endpoins.PUT_QUESTIONNAIRE_PAGE_BY_ID(id), data);
    return res.data;
  } catch (e) {
    throw new Error(`Failed to update questionnaire template page with id ${id}.`);
  }
};

/**
 * Method to update questionnaire template page via patch request
 * @param id - page id
 * @param data - request payload
 * @param entitySource - can be one value from enum: event, template or request.
 */
export const updateQuestionnaireTemplatePage = async <T = unknown>(
  id: number,
  data: CreateUpdateQuestionnaireTemplatePageRequestData<unknown, T>,
  entitySource: EQuestionnairesSource
): Promise<QuestionnaireTemplatePageItem<T>> => {
  const endpoins = API_QUESTIONNAIRES_ENDPOINTS[entitySource];

  try {
    return axios.put(endpoins.PUT_QUESTIONNAIRE_PAGE_BY_ID(id), data).then((res) => res.data);
  } catch (e) {
    throw new Error(`Failed to update questionnaire template page with id ${id}.`);
  }
};

/**
 * Method to delete questionnaire template page by id
 * @param id - questionnaire template page id
 * @param entitySource - can be one value from enum: event, template or request.
 * @param tenantId - tenant id. Optional.
 */
export const deleteQuestionnaireTemplatePage = async (
  id: number,
  entitySource: EQuestionnairesSource,
  tenantId?: number
): Promise<void> => {
  const endpoins = API_QUESTIONNAIRES_ENDPOINTS[entitySource];
  try {
    return axios.delete(endpoins.DELETE_QUESTIONNAIRE_PAGE_BY_ID(id), { params: { tenantId } });
  } catch (e) {
    throw new Error(`Failed to load Questionnaire template page with id ${id}`);
  }
};

/**
 * Method to get questionnaire template page categories data by id questionnaire
 * @param id - questionnaire template page id
 */
export const fetchQuestionnaireTemplatePageCategoriesGuestRooms = async (id: string, entitySource: EQuestionnairesSource) => {
  const endpoins = API_QUESTIONNAIRES_ENDPOINTS[entitySource];
  return axios.get(endpoins.GET_CATEGORIES_BY_ID(id)).then((res) => res.data);
};

export const attachFileQuestionnairePageAnswerById = ({ data }: IAttachFileQuestionnairePageAnswerById) => {
  const dataForBody = new FormData();
  if (data) {
    dataForBody.append('tenantId', data.tenantId);
    dataForBody.append('questionnaireId', data.questionnaireId);
    dataForBody.append('questionnairePageId', data.questionnairePageId);
    dataForBody.append('widgetId', data.widgetId);
    dataForBody.append('attachment', data.attachment);
  }

  return axios
    .post(`/questionnaires/${data.questionnaireId}/attachments`, dataForBody, {
      headers: { 'content-type': data.fileName }
    })
    .then((res) => res.data);
};
export const attachFilePlaygroundPageById = ({ data }: IAttachFilePlaygroundPageById) => {
  const dataForBody = new FormData();
  if (data) {
    dataForBody.append('tenantId', data.tenantId);
    dataForBody.append('questionnaireId', data.questionnaireId);
    dataForBody.append('questionnaireTemplateId', data.questionnaireTemplateId);
    dataForBody.append('widgetId', data.widgetId);
    dataForBody.append('File', data.attachment);
    dataForBody.append('fileName', data.fileName);
  }

  return axios
    .post(`/tenant-files`, dataForBody, {
      headers: { 'content-type': data.fileName }
    })
    .then((res) => res.data);
};

/**
 * Method to reorder questionnaire pages request
 * @param id - page id
 * @param data - request payload
 * @param entitySource - can be one value from enum: event, template or request.
 */
export const updateOrderQuestionnariesPages = async (
  id: string,
  data: OrderPagePayloadType,
  entitySource: EQuestionnairesSource
): Promise<any> => {
  const endpoins = API_QUESTIONNAIRES_ENDPOINTS[entitySource];
  try {
    const res = await axios.put(endpoins.PUT_UPDATE_PAGES_ORDER(id), { pages: data });
    return res.data;
  } catch (e) {
    throw new Error(`Failed to update order of pages on that questionnaire ${id}.`);
  }
};
