import { UseMutationOptions, useMutation } from 'react-query';
import axios from 'axios';
import { INoteWidgets } from 'shared/types/organisation-plan-preview';

interface IUpdateOrganisationPlanPreviewNotes {
  token: string;
  notes: INoteWidgets[];
}

const updateNoteWidgetsForPreview = ({ token, notes }: IUpdateOrganisationPlanPreviewNotes): Promise<void> => {
  return axios
    .put('organization-plans/public/builder/notes', {
      token,
      notes
    })
    .then((res) => {
      return res.data;
    });
};

export function usePutOrganisationPlanPreviewNoteWidgets<TError, TContext>({
  options = {},
  onSuccess
}: {
  options?: Omit<UseMutationOptions<void, TError, IUpdateOrganisationPlanPreviewNotes, TContext>, 'mutationFn' | 'onSuccess' | 'variables'>;
  onSuccess?: () => void;
}) {
  return useMutation(updateNoteWidgetsForPreview, {
    ...options,
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    }
  });
}
