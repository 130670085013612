import { Layout } from 'react-grid-layout';
import { Box, TableContainer } from '@mui/material';
import OrganisationPlanTableEditableCell from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanTableWithReplacementWidget/OrganisationPlanTableEditableCell';
import { OrganisationPlanTableWithReplacementWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanTableWithReplacementWidgetType';
import { renderInputLabel } from 'modules/organisation-plan-preview/utils/renderInputLabelHandler';
import { GeneralLabelTypographyWrapper } from 'modules/organisation-plan-preview/components/OrganisationPlanPreview.styles';
import PreviewWidgetWrapperOrgPlan from 'modules/organisation-plan-preview/components/PreviewWidgetWrapperOrgPlan';
import * as Styles from './PreviewTableWidget.styles';

interface IPreviewTableWidgetProps {
  element: OrganisationPlanTableWithReplacementWidgetType;
  layoutItem: Layout;
}

const PreviewTableWidget = ({ element, layoutItem }: IPreviewTableWidgetProps) => {
  const {
    _activeRow,
    label,
    replacementLabel,
    dataTitles,
    dataContent,
    isMainLabelHidden,
    widgetId,
    sectionId,
    columnSettings,
    rowSettings
  } = element;
  const isRowSettingsAvailable = typeof _activeRow === 'number';

  return (
    <PreviewWidgetWrapperOrgPlan widgetId={widgetId} sectionId={sectionId} layoutItem={layoutItem}>
      <GeneralLabelTypographyWrapper isMainLabelHidden={!!isMainLabelHidden}>
        {renderInputLabel(replacementLabel, label)}
      </GeneralLabelTypographyWrapper>
      <Styles.BoxTableContainerWrapper>
        <TableContainer>
          <Styles.TableWrapper>
            <Styles.TableHeadWrapper>
              <Styles.TableHeadRowWrapper>
                {dataTitles &&
                  dataTitles.length &&
                  dataTitles.map((title, key: number) => (
                    <Styles.TableHeadCellWrapper key={`th_${key}`}>{title.value}</Styles.TableHeadCellWrapper>
                  ))}
              </Styles.TableHeadRowWrapper>
            </Styles.TableHeadWrapper>
            <Styles.TableBodyWrapper>
              {dataContent &&
                dataContent.length &&
                dataContent.map((row, key: number) => {
                  return (
                    <Styles.TableBodyRowWrapper key={`row_${key}`}>
                      {row &&
                        Object.values(row.data).map((value, key: number) => (
                          <td key={`row_th_${key}`}>
                            <Box position="relative">
                              <OrganisationPlanTableEditableCell
                                value={value.value}
                                row={+row.rowId}
                                column={+value.columnId}
                                settings={columnSettings.find((settings) => settings.columnId === value.columnId)}
                                settingsRow={rowSettings.find((settings) => settings.rowId === row.rowId)}
                                isRowSettingsAvailable={isRowSettingsAvailable}
                              />
                            </Box>
                          </td>
                        ))}
                    </Styles.TableBodyRowWrapper>
                  );
                })}
            </Styles.TableBodyWrapper>
          </Styles.TableWrapper>
        </TableContainer>
      </Styles.BoxTableContainerWrapper>
    </PreviewWidgetWrapperOrgPlan>
  );
};

export default PreviewTableWidget;
