import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Form, FormikProvider, useFormik } from 'formik';
import InvoiceEditorDetails from 'modules/invoices/components/InvoiceEditorDetails';
import EditorFooter from 'modules/editor/components/EditorFooter';
import GreetingTextImportButtons from 'modules/invoices/components/GreetingTextImportButtons/index';
import { IInvoiceEditor } from 'shared/types/invoice';
import { useGetInvoiceItems } from 'api/hooks/invoices/useGetInvoiceItems';
import { usePatchUpdateInvoice } from 'api/hooks/invoices/usePatchUpdateInvoice';
import { useSelector } from 'store';
import { selectPaginationInvoices } from 'store/pagination/pagination.selectors';
import { useGetInvoiceEditorById } from 'api/hooks/invoices/useGetInvoiceEditorById';
import { useInfoMessage } from 'shared/hooks/useInfoMessage';
import { textError } from 'shared/utils/infoText';
import { useUpdateInvoiceItems } from 'api/hooks/invoices/useUpdateInvoiceItems';
import { prepareDataForItemsRequest, prepareDataForPatchRequest, prepareInvoiceEditorToForm } from 'modules/invoices/utils/prepareData';
import InvoiceEditorHeader from 'modules/invoices/components/InvoiceEditorHeader';
import PageTitle from 'shared/components/PageTitle';
import EditorResultCalculation from 'modules/editor/components/EditorResultCalculation';
import InvoiceEditorTable from 'modules/invoices/components/InvoiceEditorTable';
import { EEditorType } from 'modules/editor/types/editor';

const InvoiceEditor = () => {
  const intl = useIntl();
  const { invoiceId } = useParams();
  const pagination = useSelector(selectPaginationInvoices);
  const { successMessage, errorMessage } = useInfoMessage();
  const invoiceIdNumber = invoiceId ? Number(invoiceId) : undefined;

  const { data: invoiceItems } = useGetInvoiceItems(invoiceIdNumber);
  const { data: invoiceEditorData, isLoading: isEditorLoading } = useGetInvoiceEditorById({ invoiceId: invoiceIdNumber });
  const { mutateAsync: saveEditorAsync, isLoading: isEditorSaving } = usePatchUpdateInvoice({
    id: invoiceIdNumber,
    pagination,
    options: {
      onError(error) {
        errorMessage(textError(error));
      }
    }
  });

  const { mutateAsync: saveItemsAsync, isLoading: isInvoiceItemsSaving } = useUpdateInvoiceItems({});

  const formik = useFormik<IInvoiceEditor>({
    initialValues: prepareInvoiceEditorToForm(invoiceEditorData),
    onSubmit: async (values) => {
      await saveEditorAsync(prepareDataForPatchRequest(values));
      const itemsWithDefaultValues = prepareDataForItemsRequest(values.articles);
      await saveItemsAsync(
        { invoiceId: values.id!, items: itemsWithDefaultValues },
        {
          onSuccess: () => {
            successMessage(intl.formatMessage({ id: 'invoices.update-invoice-success' }));
          },
          onError(error) {
            errorMessage(textError(error));
          }
        }
      );
    }
  });
  const { resetForm } = formik;

  useEffect(() => {
    if (!invoiceEditorData || !invoiceItems) return;
    const sortedInvoiceItems = invoiceItems.sort((item1, item2) => item1.order - item2.order);
    resetForm({ values: prepareInvoiceEditorToForm(invoiceEditorData, sortedInvoiceItems) });
  }, [resetForm, invoiceEditorData, invoiceItems]);

  return (
    <>
      <PageTitle title={invoiceEditorData?.number} />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
          <InvoiceEditorHeader isLoading={isEditorLoading || isEditorSaving || isInvoiceItemsSaving} />
          <InvoiceEditorDetails />
          <GreetingTextImportButtons />
          <InvoiceEditorTable />
          <EditorResultCalculation />
          <EditorFooter type={EEditorType.Invoices} />
        </Form>
      </FormikProvider>
    </>
  );
};

export default InvoiceEditor;
