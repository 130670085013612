import { useState } from 'react';
import 'cropperjs/dist/cropper.css';
import { Layout } from 'react-grid-layout';
import { useDispatch } from 'react-redux';
import ReplacementTextHeading from 'shared/components/ReplacementText/Heading';
import { updateOrganisationPlanWidget } from 'store/organisation-plan-template/organisation-plan-template.slice';
import PreviewWidgetWrapperOrgPlan from 'modules/organisation-plan-preview/components/PreviewWidgetWrapperOrgPlan';
import { MultipleImageChoiceWithReplacementWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanImageWidgetTypes';
import OrganisationPlanImageChoice from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanImageChoiceWithReplacementWidget/OrganisationPlanImageChoice';
import OrganisationPlanImageChoiceWrapper from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanImageChoiceWithReplacementWidget/OrganisationPlanImageChoiceWrapper';

interface IOrganisationPlanMultipleImageChoiceWidgetProps {
  widget: MultipleImageChoiceWithReplacementWidgetType;
  isSingleChoice: boolean;
  layoutItem: Layout;
}

const OrganisationPlanMultipleImageChoiceWidget = ({
  widget,
  isSingleChoice,
  layoutItem
}: IOrganisationPlanMultipleImageChoiceWidgetProps) => {
  const dispatch = useDispatch();
  const {
    widgetId,
    dataImage,
    selectedOptions,
    typeImage,
    type,
    label,
    sectionId,
    replacementLabel,
    replacementOptionsLabels,
    isMainLabelHidden
  } = widget;
  const [isHeadingOpen, setIsHeadingOpen] = useState(false);

  const replaceGeneralLabelHandler = (replacementLabel: string) => {
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as MultipleImageChoiceWithReplacementWidgetType['type'], replacementLabel }
      })
    );
  };

  const changeGeneralLabelVisibilityHandler = (isMainLabelHidden: boolean) => {
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as MultipleImageChoiceWithReplacementWidgetType['type'], isMainLabelHidden }
      })
    );
  };

  return (
    <OrganisationPlanImageChoiceWrapper widgetId={widgetId} sectionId={sectionId}>
      <PreviewWidgetWrapperOrgPlan
        widgetId={widgetId}
        sectionId={sectionId}
        layoutItem={layoutItem}
        dependencies={[isHeadingOpen, replacementOptionsLabels]}
      >
        <ReplacementTextHeading
          onChange={(e) => replaceGeneralLabelHandler(e.target.value)}
          value={replacementLabel}
          content={label}
          isLabelHiddenValue={isMainLabelHidden}
          onLabelHiddenChange={(val) => changeGeneralLabelVisibilityHandler(val)}
          onReplacementLabelHiddenChange={() => replaceGeneralLabelHandler('')}
          isHeadingReplacementOpen={(value) => setIsHeadingOpen(value)}
        />
        <OrganisationPlanImageChoice
          typeImage={typeImage}
          id={widgetId}
          data={dataImage}
          value={selectedOptions}
          multiple={true}
          isSingleChoice={isSingleChoice}
          typeWidget={type}
          replacementOptionsLabels={replacementOptionsLabels}
        />
      </PreviewWidgetWrapperOrgPlan>
    </OrganisationPlanImageChoiceWrapper>
  );
};

export default OrganisationPlanMultipleImageChoiceWidget;
