import { useQuery } from 'react-query';
import axios from 'axios';
import { API_INVOICES_ENDPOINTS, EInvoicesQueryKeys, IInvoice } from 'shared/types/invoice';

interface IuseGetInvoiceInfoByIdParams {
  id?: string | undefined;
}

const fetchInvoiceInfoById = (id: string): Promise<IInvoice> => {
  return axios.get(`${API_INVOICES_ENDPOINTS.GET_BY_ID(id)}`).then((res) => res.data);
};

export function useGetInvoiceInfoById({ id }: IuseGetInvoiceInfoByIdParams) {
  return useQuery<IInvoice>([EInvoicesQueryKeys.GetSingleInvoice, { id }], () => fetchInvoiceInfoById(id ?? ''), {
    enabled: !!id,
    refetchOnMount: true
  });
}
