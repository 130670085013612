import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import { getMenuItems } from 'shared/components/menu-items/dashboard';
import { useAuth } from 'shared/contexts/AuthContext';
import { RootStateProps } from 'shared/types/root';
import menuMantis from 'shared/components/menu-items/menuMantis';
import MenuButtom from 'shared/components/MenuButtom';
import filterNavItemsByEnvironment from 'shared/utils/filterNavItemsByEnvironment';
import NavGroup from './NavGroup';
import * as Styles from './Navigation.styles';

const Navigation = () => {
  const location = useLocation();
  const { tokenInfo } = useAuth();

  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;

  const menuItemsMap = location.pathname.includes('mantis/') ? menuMantis : getMenuItems(tokenInfo?.role);
  const filteredMenuItemsMap = filterNavItemsByEnvironment(menuItemsMap.items);
  const navGroups = filteredMenuItemsMap.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });
  return (
    <Styles.Content>
      <Styles.NavGroup drawerOpen={drawerOpen}>{navGroups}</Styles.NavGroup>
      <Styles.MenuGroup drawerOpen={drawerOpen}>
        <MenuButtom drawerOpen={drawerOpen} />
      </Styles.MenuGroup>
    </Styles.Content>
  );
};

export default Navigation;
