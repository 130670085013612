import { FC, useState } from 'react';
import * as React from 'react';
import { Layout } from 'react-grid-layout';
import { useIntl } from 'react-intl';
import { Box, TableSortLabel } from '@mui/material';
import { sortOrderType } from 'modules/builder/components/widgets/TableWidget/types';
import { IDataColumn, IDataRow } from 'modules/builder/types/tableWidgetType';
import { sortByColumn } from 'modules/builder/utils/builder-widgets/table';
import { useDispatch } from 'store';
import ReplacementTextHeading from 'shared/components/ReplacementText/Heading';
import { updateOrganisationPlanWidget } from 'store/organisation-plan-template/organisation-plan-template.slice';
import useUpdateOrganisationPlanLayoutPartially from 'modules/organisation-plan-templates/hooks/useUpdateOrganisationPlanLayoutPartially';
import { OrganisationPlanTableWithReplacementWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanTableWithReplacementWidgetType';
import * as Styles from './OrganisationPlanTableWithReplacementWidget.styles';
import OrganisationPlanTableEditableCell from './OrganisationPlanTableEditableCell';

interface IOrganisationPlanTableWithReplacementWidgetProps {
  widget: OrganisationPlanTableWithReplacementWidgetType;
  layoutItem: Layout;
}

const OrganisationPlanTableWithReplacementWidget: FC<IOrganisationPlanTableWithReplacementWidgetProps> = ({ widget, layoutItem }) => {
  const { _activeRow, columnSettings, dataTitles, dataContent, widgetId, label, rowSettings, type, replacementLabel, isMainLabelHidden } =
    widget;
  const [sortedColumnId, setSortedColumnId] = useState<null | IDataColumn['columnId']>(null);
  const [order, setOrder] = useState<sortOrderType>('asc');
  const [isHeadingOpen, setIsHeadingOpen] = useState(false);
  const ref = useUpdateOrganisationPlanLayoutPartially({
    widgetId,
    sectionId: widget.sectionId,
    layoutItem,
    additionalLayoutChanges: { w: 2, isResizable: false, resizeHandles: [] },
    dependencies: [isHeadingOpen]
  });
  const intl = useIntl();
  const dispatch = useDispatch();
  const isRowSettingsAvailable = typeof _activeRow === 'number';

  const replaceGeneralLabelHandler = (replacementLabel: string) => {
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as OrganisationPlanTableWithReplacementWidgetType['type'], replacementLabel }
      })
    );
  };

  const changeGeneralLabelVisibilityHandler = (isMainLabelHidden: boolean) => {
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as OrganisationPlanTableWithReplacementWidgetType['type'], isMainLabelHidden }
      })
    );
  };

  const saveSortProperties = (_event: React.MouseEvent<unknown>, columnId: IDataRow['rowId']) => {
    setOrder((prev) => {
      if (prev === undefined) return 'asc';
      if (prev === 'asc') return 'desc';
      return undefined;
    });
    setSortedColumnId(columnId);
  };

  return (
    <Styles.BoxWrapper ref={ref}>
      <ReplacementTextHeading
        onChange={(e) => replaceGeneralLabelHandler(e.target.value)}
        value={replacementLabel}
        content={label || intl.formatMessage({ id: 'organisation.no-label-provided' })}
        isLabelHiddenValue={isMainLabelHidden}
        onLabelHiddenChange={(val) => changeGeneralLabelVisibilityHandler(val)}
        onReplacementLabelHiddenChange={() => replaceGeneralLabelHandler('')}
        isHeadingReplacementOpen={(value) => setIsHeadingOpen(value)}
      />
      <Styles.TableContainerWrapper>
        <Styles.TableWrapper aria-label="simple table">
          <Styles.TableHeadWrapper>
            <Styles.TableHeadRowWrapper key="title">
              {dataTitles.map(({ value, columnId }, index) => (
                <Styles.TableHeadCellWrapper key={columnId}>
                  <Box display="flex" justifyContent="center" position="relative">
                    <TableSortLabel
                      active={sortedColumnId === columnId && !!order}
                      direction={sortedColumnId === columnId ? order : 'asc'}
                      onClick={(event) => saveSortProperties(event, columnId)}
                    >
                      <OrganisationPlanTableEditableCell value={value} row={0} column={index} />
                    </TableSortLabel>
                  </Box>
                </Styles.TableHeadCellWrapper>
              ))}
            </Styles.TableHeadRowWrapper>
          </Styles.TableHeadWrapper>
          <Styles.TableBodyWrapper>
            {sortByColumn(sortedColumnId, order, dataContent, columnSettings).map(({ rowId, data: rowData }, rowIndex) => {
              return (
                <Styles.TableBodyRowWrapper key={`rowId-${rowId}`}>
                  {Object.values(rowData).map(({ columnId, value }, columnIndex) => (
                    <td key={`${rowId}-${columnId}`}>
                      <OrganisationPlanTableEditableCell
                        value={value}
                        row={rowIndex}
                        column={columnIndex}
                        settings={columnSettings.find((settings) => settings.columnId === columnId)}
                        settingsRow={rowSettings.find((settings) => settings.rowId === rowId)}
                        isRowSettingsAvailable={isRowSettingsAvailable}
                      />
                    </td>
                  ))}
                </Styles.TableBodyRowWrapper>
              );
            })}
          </Styles.TableBodyWrapper>
        </Styles.TableWrapper>
      </Styles.TableContainerWrapper>
    </Styles.BoxWrapper>
  );
};
export default OrganisationPlanTableWithReplacementWidget;
