/**
 * Function to check if value is not undefined, then returns object with FieldName and Value.
 * @param fieldName - questionnaire template id
 * @param value - token from org plan for not authorized users.
 * */
export const objectWithData = (fieldName: string, value?: number | string | object) => {
  return !!value ? { [fieldName]: value } : {};
};

/**
 * Function to check if value is not undefined, then returns string = Value as string or ''.
 * @param value - data
 * */
export const stringFromData = (value?: number | string): string => {
  return !!value ? value.toString() : '';
};
