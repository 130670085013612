import { ReactNode, useMemo } from 'react';
import { PanelLeftContract24Regular, PanelLeftExpand24Regular } from '@fluentui/react-icons';
import { AppBar, AppBarProps, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
}

const HeaderWithSidebar = ({ open, handleDrawerToggle }: Props) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const headerContent = useMemo(() => <HeaderContent />, []);

  const iconBackColorOpen = theme.palette.primary.main;

  const mainHeader: ReactNode = (
    <Toolbar variant="dense">
      <IconButton
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        color="secondary"
        size="small"
        sx={{ color: open ? iconBackColorOpen : 'text.primary', ml: { xs: 0, lg: -2 } }}
      >
        {!open ? <PanelLeftExpand24Regular /> : <PanelLeftContract24Regular />}
      </IconButton>
      {headerContent}
    </Toolbar>
  );

  const appBar: AppBarProps = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      border: 'none',
      boxShadow: 'rgba(27, 31, 35, 0.08) 0px 0px 0px 1px'
    }
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

export default HeaderWithSidebar;
