import { ChangeEvent, ReactElement } from 'react';
import ReplacementTextHeading from 'shared/components/ReplacementText/Heading';
import { IOrganisationPlanChoiceOptionLabels } from 'modules/organisation-plan-templates/types/organisationPlanChoiceWidgetType';

interface IOrganisationPlanChoiceLabelProps {
  option: IOrganisationPlanChoiceOptionLabels;
  selectedValues: string | string[];
  isCheckbox: boolean;
  replaceOptionLabelsHandler: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    option: IOrganisationPlanChoiceOptionLabels
  ) => void;
  replacementLabel?: string | null;
  isLabelHiddenValue?: boolean;
  onLabelHiddenChange?: (value: boolean) => void;
  isHeadingReplacementOpen?: (value: boolean) => void;
  onReplacementLabelHiddenChange?: () => void;
}

const OrganisationPlanChoiceLabel = ({
  option,
  isCheckbox,
  replaceOptionLabelsHandler,
  replacementLabel,
  isLabelHiddenValue,
  onLabelHiddenChange,
  isHeadingReplacementOpen,
  onReplacementLabelHiddenChange
}: IOrganisationPlanChoiceLabelProps): ReactElement => {
  return (
    <ReplacementTextHeading
      isCheckbox={isCheckbox}
      onChange={(event) => replaceOptionLabelsHandler(event, option)}
      value={replacementLabel || ''}
      content={option.label}
      isLabelHiddenValue={isLabelHiddenValue}
      onLabelHiddenChange={onLabelHiddenChange}
      onReplacementLabelHiddenChange={onReplacementLabelHiddenChange}
      isHeadingReplacementOpen={(value) => (isHeadingReplacementOpen ? isHeadingReplacementOpen(value) : {})}
    />
  );
};

export default OrganisationPlanChoiceLabel;
