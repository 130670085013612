import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GuardProps } from 'modules/auth/types';
import { CUSTOMER_QUESTIONNAIRES_ROUTES } from 'modules/questionnaires-customer/constants';
import { useAuth } from 'shared/contexts/AuthContext';
import { PREVIEW } from 'modules/questionnaires/constants';

const GuestGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem(PREVIEW) || location.pathname.includes(CUSTOMER_QUESTIONNAIRES_ROUTES.baseUrl)) {
      return;
    }
    if (isLoggedIn) {
      navigate('/dashboard', { replace: true });
    }
  }, [isLoggedIn, navigate, location]);

  return children;
};

export default GuestGuard;
