import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PreviewTemplateSectionWrapper = styled(Box)(({ theme }) => ({
  marginTop: '0px',
  marginBottom: '10px',
  border: `1px dashed ${theme.palette.secondary.light}`,
  borderRadius: '6px',
  width: '100%',
  '@media print': { marginTop: '0 !important', border: `1px dashed #d9d9d9` }
}));
