import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Stack, useTheme } from '@mui/material';
import { Square12Filled } from '@fluentui/react-icons';
import { EventCreationStatusType } from 'shared/types/event';
import { EVENT_STATUS } from 'modules/events/constants';
import { HtmlTooltip } from 'modules/events/components/ReactTableEvents.styles';
import { REQUEST_STATE_CHIPS } from 'modules/requests/constant/stateRequest';
import { ERequestStatus } from 'shared/types/requests';

interface ITableCellStatus {
  type?: 'Event' | 'Request';
  status?: EventCreationStatusType | ERequestStatus | 'Pending';
  fullLength?: boolean;
}

const TableCellStatus = ({ type, status, fullLength = false }: ITableCellStatus) => {
  const intl = useIntl();
  const theme = useTheme();

  const statusData =
    !!status && type === 'Event'
      ? (EVENT_STATUS[status as EventCreationStatusType] ?? {
          name: 'None',
          intlId: 'events.state-none',
          color: '#ffffff'
        })
      : (REQUEST_STATE_CHIPS[status as ERequestStatus] ?? {
          name: 'None',
          intlId: 'events.state-none',
          color: '#ffffff'
        });

  return (
    <>
      {fullLength ? (
        <Box>
          <Stack
            sx={{
              backgroundColor: theme.palette.secondary.lighter,
              color: statusData?.color,
              fontSize: theme.typography.pxToRem(12),
              border: `0.1rem solid ${statusData?.color}`,
              borderRadius: '6px',
              fontWeight: 600,
              maxWidth: '160px'
            }}
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <Square12Filled style={{ color: statusData?.color }} />
            <Box>
              <FormattedMessage id={statusData?.intlId} />
            </Box>
          </Stack>
        </Box>
      ) : (
        statusData && (
          <Stack sx={{ maxWidth: '30px' }} direction="row" spacing={1} alignItems="center" justifyContent="center">
            <HtmlTooltip color={statusData.color} placement="right" title={intl.formatMessage({ id: statusData.intlId })}>
              <Square12Filled style={{ color: statusData.color }} />
            </HtmlTooltip>
          </Stack>
        )
      )}
    </>
  );
};

export default TableCellStatus;
