import { useLocation } from 'react-router';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { QUESTIONNAIRES_EVENT_EDIT_ROUTES } from 'modules/events/constants';
import { BUILDER_ROUTES } from 'modules/builder/constants/builder';
import { QUESTIONNAIRES_REQUEST_EDIT_ROUTES } from 'modules/requests/constant/paths';
import { useIsPageForPreviewQuestionnaires } from 'modules/questionnaires/hooks/useIsPageForPreviewQuestionnaires';
import BuilderHeader from './BuilderHeader';
import ProfileSection from './ProfileSection';

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const location = useLocation();
  const isPreview = useIsPageForPreviewQuestionnaires();

  const isBuilderMode = [
    BUILDER_ROUTES.BUILDER,
    QUESTIONNAIRES_EVENT_EDIT_ROUTES.ROOT_LINK,
    QUESTIONNAIRES_REQUEST_EDIT_ROUTES.ROOT_LINK
  ].some((val) => location.pathname.includes(val));

  return (
    <>
      {isBuilderMode && !isPreview && <BuilderHeader />}
      <ProfileSection />
    </>
  );
};

export default HeaderContent;
