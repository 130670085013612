import { Dispatch, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';
import { alpha, Grid, InputLabel, ListItemText, MenuItem, Select, Stack, styled } from '@mui/material';
import { CheckboxChecked20Filled, ChevronDown20Regular, ChevronRight20Regular } from '@fluentui/react-icons';
import { getPlainTextFromHTML } from 'shared/utils/getPlainTextFromHTML';
import { BuilderPageItemType, WidgetsTypeOnlySupportedByOrganisationPlan } from 'modules/builder/types';
import { AVAILABLE_WIDGET_TYPES_OF_QUESTIONNAIRE } from 'modules/organisation-plan-templates/types';
import {
  IChosenSubpage,
  IOrganisationPlanDynamicFields
} from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateQuestionnaireInfoSettings/index';
import { findPageByType } from 'modules/builder/utils/findPageByType';
import { QuestionnairePageType } from 'modules/questionnaires/types/questionnaries';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';

interface OrganisationPlanDynamicFieldsProps {
  infoSettingField: IOrganisationPlanDynamicFields;
  builderPages: BuilderPageItemType[];
  chosenPage: string;
  setChosenPage: Dispatch<SetStateAction<string>>;
  filteredBuilderWidgetsByPageName: WidgetsTypeOnlySupportedByOrganisationPlan[];
  chosenWidget: string;
  setChosenWidget: Dispatch<SetStateAction<string>>;
  setSelectedWidgetId: Dispatch<SetStateAction<string>>;
  setChosenSubpage: Dispatch<SetStateAction<IChosenSubpage | undefined>>;
}

const OrganisationPlanDynamicFields = ({
  infoSettingField,
  builderPages,
  chosenPage,
  setChosenPage,
  filteredBuilderWidgetsByPageName,
  chosenWidget,
  setChosenWidget,
  setSelectedWidgetId,
  setChosenSubpage
}: OrganisationPlanDynamicFieldsProps) => {
  const intl = useIntl();
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.content}`]: {
      padding: '8px 12px',
      borderRadius: 0,
      margin: 0
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      '& .close': {
        opacity: 0.2
      }
    },
    [`& .${treeItemClasses.groupTransition}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
    }
  }));

  const selectMainpageHandler = (chosenPage: string) => {
    setChosenPage(chosenPage);
    setChosenWidget('');
  };

  const selectSubpageHandler = (e: React.MouseEvent, chosenPage: string, subpageData?: IChosenSubpage) => {
    e.stopPropagation();
    setChosenPage(chosenPage as string);
    setChosenSubpage(subpageData);
    setChosenWidget('');
  };

  const handleChangeWidget = (value: string, widgetId: string) => {
    setSelectedWidgetId(widgetId);
    setChosenWidget(value);
  };

  const renderInitialValueHandler = (value: string, label: string) => {
    if (label === intl.formatMessage({ id: 'organisation.select-page' })) return chosenPage !== '' ? value.split('with_page_id')[0] : label;
    return chosenWidget !== '' ? value.split('with_widget_id')[0] : label;
  };

  const renderWidgetsHandler = (widgets: WidgetsTypeOnlySupportedByOrganisationPlan[]) => {
    let widgetsAvailableOnlyForOrganisationPlan = widgets.filter((widget) =>
      Object.keys(AVAILABLE_WIDGET_TYPES_OF_QUESTIONNAIRE).includes(widget.type)
    );
    const guestAndRoomsPage = findPageByType(builderPages, QuestionnairePageType.GuestsAndRooms);
    if (chosenPage.split('with_page_id')[0] === guestAndRoomsPage?.name) {
      widgetsAvailableOnlyForOrganisationPlan = widgetsAvailableOnlyForOrganisationPlan.sort((a, b) => a.type.localeCompare(b.type));
    }

    const getWidgetDescription = (widgetType: string) => {
      switch (widgetType) {
        case 'inputText':
          return intl.formatMessage({ id: 'organisation.input-text' });
        case 'inputInteger':
          return intl.formatMessage({ id: 'organisation.input-integer' });
        case 'time':
          return intl.formatMessage({ id: 'organisation.time' });
        case 'singleChoice':
          return intl.formatMessage({ id: 'organisation.single-choice' });
        case 'multipleChoice':
          return intl.formatMessage({ id: 'organisation.multiple-choice' });
        case 'singleImageChoice':
          return intl.formatMessage({ id: 'organisation.single-image-choice' });
        case 'multipleImageChoice':
          return intl.formatMessage({ id: 'organisation.multiple-image-choice' });
        case 'table':
          return intl.formatMessage({ id: 'organisation.table' });
        case 'uploadArea':
          return intl.formatMessage({ id: 'organisation.upload-area' });
        default:
          return '';
      }
    };

    return widgetsAvailableOnlyForOrganisationPlan.map((widget) => {
      const description = getWidgetDescription(widget.type);
      const widgetLabel = widget.label ? getPlainTextFromHTML('p', widget.label) : description;
      return (
        <MenuItem
          sx={{ '& svg': { color: '#00000070 !important' } }}
          divider
          key={widget.widgetId}
          value={`${widget.label ? getPlainTextFromHTML('p', widget.label) : widget.type}with_widget_id${widget.widgetId}`}
          id={widget.widgetId}
          onClick={() =>
            handleChangeWidget(
              `${widget.label ? getPlainTextFromHTML('p', widget.label) : widget.type}with_widget_id${widget.widgetId}`,
              widget.widgetId
            )
          }
        >
          <ListItemText primary={widgetLabel} secondary={widget.label ? description : ''} />
        </MenuItem>
      );
    });
  };

  return (
    <Grid item xs={12} order={infoSettingField.label === intl.formatMessage({ id: 'organisation.select-page' }) ? 1 : 3}>
      <Stack spacing={1.25}>
        <InputLabel id={infoSettingField.id}>{infoSettingField.label}</InputLabel>
        <Select
          value={infoSettingField.label === intl.formatMessage({ id: 'organisation.select-page' }) ? chosenPage : chosenWidget}
          labelId={infoSettingField.id}
          id={infoSettingField.id}
          name={infoSettingField.id}
          displayEmpty
          renderValue={(value) => renderInitialValueHandler(value, infoSettingField.label)}
          disabled={infoSettingField.isDisabled}
          open={isSelectOpen}
          onOpen={() => setIsSelectOpen(true)}
          onClose={() => setIsSelectOpen(false)}
        >
          <SimpleTreeView
            aria-label="customized"
            slots={{
              expandIcon: ChevronRight20Regular,
              collapseIcon: ChevronDown20Regular
            }}
            sx={{ overflowX: 'hidden', flexGrow: 1, maxHeight: '50vh' }}
          >
            {infoSettingField.label === intl.formatMessage({ id: 'organisation.select-page' })
              ? builderPages.map((page) => {
                  return !!page.childPages?.length ? (
                    <>
                      {!!page.usedWidgets && page.childPages && (
                        <>
                          {chosenPage.split('with_page_id')[1] === page.id && (
                            <CheckboxChecked20Filled style={{ position: 'absolute', right: 0, margin: '10px' }} />
                          )}
                          <CustomTreeItem
                            onClick={(e) => selectSubpageHandler(e, `${page.name}with_page_id${page.id}`)}
                            label={page.name}
                            itemId={page.id}
                          >
                            {page.childPages.map((subPage) => (
                              <>
                                {chosenPage.split('with_page_id')[1] === subPage.id && (
                                  <CheckboxChecked20Filled style={{ position: 'absolute', right: 0, margin: '10px' }} />
                                )}
                                <CustomTreeItem
                                  itemId={subPage.id}
                                  label={subPage.name}
                                  onClick={(e) =>
                                    selectSubpageHandler(e, `${subPage.name}with_page_id${subPage.id}`, {
                                      pageId: page.id,
                                      pageName: page.name,
                                      subpageId: subPage.id
                                    })
                                  }
                                />
                              </>
                            ))}
                          </CustomTreeItem>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {page.usedWidgets && page.usedWidgets.length > 0 && (
                        <>
                          {chosenPage.split('with_page_id')[1] === page.id && (
                            <CheckboxChecked20Filled style={{ position: 'absolute', right: 0, margin: '10px' }} />
                          )}
                          <CustomTreeItem
                            onClick={() => selectMainpageHandler(`${page.name}with_page_id${page.id}`)}
                            itemId={page.id}
                            label={page.name}
                          />
                        </>
                      )}
                    </>
                  );
                })
              : null}
            {infoSettingField.label === intl.formatMessage({ id: 'organisation.widget-field' }) && filteredBuilderWidgetsByPageName.length
              ? renderWidgetsHandler(filteredBuilderWidgetsByPageName)
              : null}
          </SimpleTreeView>
        </Select>
      </Stack>
    </Grid>
  );
};

export default OrganisationPlanDynamicFields;
