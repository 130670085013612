import { FormattedMessage } from 'react-intl';
import { Settings24Regular } from '@fluentui/react-icons';
import { NavItemType } from 'shared/types/menu';

const icons = {
  Settings24Regular
};

const settingsProd: NavItemType = {
  id: 'group-settings',
  type: 'group',
  children: [
    {
      id: 'settings',
      title: <FormattedMessage id="settings.settings" />,
      type: 'item',
      url: '/settings/personal',
      breadcrumbs: false,
      icon: icons.Settings24Regular
    }
  ]
};

const settings: NavItemType = settingsProd;

export default settings;
