import { AddCircle20Regular } from '@fluentui/react-icons';
import { Button, Grid } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import { ILinkTarget } from 'shared/types/questioner-template-link';
import LinkItem from './LinkItem';
import { IDynamicFieldsProps } from './types';

const DynamicFields = ({ options, links, addNewLinkHandler, removeLinkHanlder, updateLinkHandler }: IDynamicFieldsProps): ReactElement => {
  return (
    <>
      {links?.map((linkObj, index, array) => (
        <LinkItem
          key={linkObj.linkId}
          linkObj={{ ...linkObj, target: 'target' in linkObj ? (linkObj.target as Partial<ILinkTarget>) : {} }}
          index={index}
          totalLinks={array.length}
          options={options}
          updateLinkHandler={updateLinkHandler}
          removeLinkHanlder={removeLinkHanlder}
        />
      ))}
      <Grid container>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }} mt="10px">
          <Button fullWidth color="primary" variant="text" size="small" startIcon={<AddCircle20Regular />} onClick={addNewLinkHandler}>
            <FormattedMessage id="builder.add-additional-linking-rule" />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default DynamicFields;
