import { useRef, useState } from 'react';
import { CheckmarkCircle20Filled, ChevronRight20Regular, Translate20Regular } from '@fluentui/react-icons';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useConfig from 'shared/hooks/useConfig';
import { I18n } from 'shared/types/config';
import { useGetLanguages } from 'api/hooks/base';
import { FormattedMessage } from 'react-intl';

interface IMenuListProps {
  onHandleMenuClose: () => void;
}

const Localization = ({ onHandleMenuClose }: IMenuListProps) => {
  const { data: languagesAll } = useGetLanguages();
  const theme = useTheme();

  const { i18n, onChangeLocalization } = useConfig();

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    onHandleMenuClose();
  };

  const handleListItemClick = (lang: I18n) => {
    onChangeLocalization(lang);
    setOpen(false);
    onHandleMenuClose();
  };

  const iconBackColorOpen = theme.palette.action.selected;
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';
  return (
    <>
      <MenuItem
        color="secondary"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : 'none', minWidth: '250px' }}
        aria-label="open localization"
        ref={anchorRef}
        aria-controls={open ? 'localization-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <ListItemIcon>
          <Translate20Regular />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id="settings.language" />} />

        <ChevronRight20Regular />
      </MenuItem>
      <Menu
        sx={{ marginTop: '5px', marginRight: '-5px' }}
        id="simple-menu"
        anchorEl={anchorRef.current}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {languagesAll &&
          languagesAll.map((lang: any, index: number) => (
            <MenuItem key={index} divider sx={{ backgroundColor: 'none' }} onClick={() => handleListItemClick(lang.code)}>
              <ListItemIcon>{i18n === lang.code && <CheckmarkCircle20Filled />}</ListItemIcon>
              <ListItemText primary={lang.name} />
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default Localization;
