import { GRID_LAYOUT_SIZE_ORGANISATION_PLAN } from 'modules/organisation-plan-templates/constants/organisationPlanTemplateBuilder';

export const getOrganisationPlanWidgetRowsAmount = (widgetOffsetHeight: number, content?: string) => {
  const rowGap = GRID_LAYOUT_SIZE_ORGANISATION_PLAN.rowGap;
  const rowHeight = GRID_LAYOUT_SIZE_ORGANISATION_PLAN.rowHeight;
  const numberOfRows = (widgetOffsetHeight + rowGap) / (rowHeight + rowGap);

  if (content) return content.trim() ? numberOfRows - 1 : numberOfRows + 0.5;

  return Math.floor(numberOfRows + 1);
};
