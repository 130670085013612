import { useQuery } from 'react-query';
import axios from 'axios';
import { IPaginatedResult } from 'shared/types/paginatedResult';
import { ITenant, ITenantPayload } from 'shared/types/tenants';
import { IPagination } from 'shared/types/table';

interface IuseGetTenantByIdProps {
  id?: string;
}

export const fetchTenantById = (id: string): Promise<ITenantPayload> => {
  return axios.get(`tenants/${id}`).then((res) => res.data);
};

const fetchAllTenants = (pagination?: IPagination): Promise<IPaginatedResult<ITenant>> => {
  return axios.get(`/tenants`, { params: pagination }).then((res) => {
    return res.data;
  });
};

export function useGetTenantById({ id }: IuseGetTenantByIdProps) {
  return useQuery<ITenantPayload>(['tenant', { id }], () => fetchTenantById(id ?? ''), { enabled: !!id, refetchOnMount: true });
}

export function useGetTenants(pagination?: IPagination) {
  const queryKey = pagination ? ['tenants', pagination] : ['tenants'];

  return useQuery(queryKey, () => fetchAllTenants(pagination));
}
