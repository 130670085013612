import {
  Box,
  Button,
  Card,
  Chip,
  InputLabel,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';
import { IAppearanceSettings } from 'modules/questionnaires/types';
import MainCard from 'shared/components/MainCard';

interface ITableWidgetStylesProps {
  appearanceSettings?: IAppearanceSettings;
  isShareTable?: boolean;
  isCustomize?: boolean;
}

interface IStateBackground {
  colors: {
    backgroundColor: string;
    color: string;
  };
}

export const TableWidgetWrapper = styled(Card)<ITableWidgetStylesProps>(({ theme, isShareTable }) => ({
  height: isShareTable ? '100vh' : '100%',
  width: '100%',
  position: 'relative',
  border: 'none',
  borderRadius: isShareTable ? '0' : '8px',
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.secondary.lighter : theme.palette.secondary.A200,
  boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px'
}));

export const TableContainerWrapper = styled(TableContainer)<ITableWidgetStylesProps>(({ isShareTable, theme }) => ({
  overflow: 'auto',
  height: isShareTable ? 'calc(100vh - 55px)' : '100%',
  maxHeight: isShareTable ? '100%' : '500px',
  maxWidth: '100%',
  '& .MuiButton-root': {
    color: `initial !important`,
    border: `1px solid initial !important`,
    '& span *': {
      color: `initial !important`
    }
  },
  //styling of scrollbar for all browsers in x and y direction

  '&::-webkit-scrollbar': {
    width: '10px',
    height: '10px'
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.secondary.lighter : theme.palette.secondary.A300
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.secondary.light : theme.palette.secondary.light
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.secondary.light
  },
  '&::-webkit-scrollbar-corner': {
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.secondary.lighter : theme.palette.secondary.A300 /* Color of the scrollbar corner */
  }
}));

export const TableCard = styled(MainCard)(() => ({
  padding: '10px 10px 10px 10px',
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 6,
  width: '100%',
  borderRadius: '0 !important',
  border: 'none',
  borderBottom: `2px solid rgb(0 0 0 / 10%)`
}));

export const TableUpperHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

export const TableHeadWrapper = styled(TableHead)<ITableWidgetStylesProps>(() => ({
  backgroundColor: 'transparent',
  border: 'none',
  position: 'sticky',
  top: '-1px'
}));

export const BodyTableRow = styled(TableRow)(() => ({
  cursor: 'pointer',
  backgroundColor: 'transparent',
  border: 'none'
}));

export const BodyTableCell = styled(TableCell)(() => ({
  '&.event-name': { overflowWrap: 'anywhere' },
  '&.event-date p': { fontSize: '13px' },
  border: 'none'
}));

export const TableNameSelect = {
  lineHeight: 1,
  '& .MuiOutlinedInput-input.MuiInputBase-input': {
    lineHeight: 1,
    height: '4px',
    width: '110px',
    fontWeight: 'bold'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
};

export const StateBackground = styled(Box)<IStateBackground>(({ colors }) => ({
  display: 'inline-block',
  borderRadius: '7px',
  padding: '5px 10px 5px 10px',
  ...colors
}));

export const TableHeaderRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.secondary.lighter : theme.palette.secondary.A200,

  '& > th': { textTransform: 'inherit', border: 'none' },
  '& > th:first-of-type': {
    width: '70px',
    left: '-1px',
    position: 'sticky',
    zIndex: 10,
    top: 0,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.secondary.lighter : theme.palette.secondary.A200
  },
  position: 'sticky',
  top: 0,
  zIndex: 5,
  '.MuiTableCell-root:first-of-type': { paddingLeft: '12px' }
}));

export const TableHeadWrapperBox = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.lighter,
  border: `none`,
  position: 'sticky',
  top: '-1px',
  zIndex: 8
}));

export const TableBodyRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '.MuiTableCell-root:first-of-type': { paddingLeft: '12px' },
  '& > td:first-of-type': {
    width: '90px',
    left: '0px',
    position: 'sticky',
    backgroundColor: theme.palette.background.paper,
    zIndex: 1
  }
}));

export const TableFooterRow = styled(TableRow)(({ theme }) => ({
  '& > td': { textTransform: 'inherit', padding: '0 0 5px 0', '& p': { paddingRight: '10px' } },
  '& > td:first-of-type': {
    width: '70px',
    left: '0px',
    position: 'sticky',
    backgroundColor: theme.palette.secondary.lighter,
    zIndex: 10,
    padding: '0 0 5px 0 !important'
  },
  '& > td:last-of-type': {
    padding: '0 0px 5px 0 !important'
  },
  position: 'sticky',
  bottom: '-1px',
  backgroundColor: theme.palette.secondary.lighter,
  zIndex: 5
}));

export const HeaderMainText = {
  fontWeight: 'bold'
};

export const HeaderSecondaryText = {
  color: 'gray',
  fontSize: '12px'
};

export const FooterText = styled(Typography)(({ theme }) => ({
  borderTop: `2px solid rgba(0, 0, 0, 0.10)`,
  fontWeight: 'bold',
  fontSize: '12px'
}));

export const WrapperValue = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 0.5
}));
interface StyledButtonProps {
  isShrink: boolean;
}

export const StyleButton = styled(Button)<StyledButtonProps>(({ isShrink, theme }) => ({
  height: '40px',
  width: '110px',
  justifyContent: isShrink ? 'flex-start' : 'flex-end',
  '& .MuiButton-endIcon svg path': {
    color: `${theme.palette.primary.main} !important`
  }
}));

export const MainChip = styled(Chip)(() => ({
  backgroundColor: 'transparent',
  padding: '0px',
  height: 'auto'
}));

export const TimeRangeLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: '50%',
  zIndex: '999',
  lineHeight: '20px',
  top: '-6px'
}));

export const GeneralFilterChip = styled(Chip)(() => ({
  backgroundColor: 'transparent',
  alignItems: 'flex-start',
  fontSize: '14px',
  paddingTop: '0px',
  height: 'auto'
}));

const animationPattern = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

export const LoaderOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.secondary.lighter,
  zIndex: 10,
  animation: `${animationPattern} 0.3s ease-in-out`
}));

interface ITableHeaderCell {
  isFirstColumn: boolean;
}

export const TableHeaderCell = styled(TableCell)<ITableHeaderCell>(({ isFirstColumn }) => ({
  textAlign: isFirstColumn ? 'start' : 'center'
}));

export const Footer = styled(TableFooter)(({ theme }) => ({
  '& .MuiTableRow-root': {
    bottom: '-3px',
    '&>td': { textAlign: 'right', borderTop: `2px solid rgb(0 0 0/10%)` }
  }
}));
