import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import { EventCreationStatusType, IEvent } from 'shared/types/event';
import { IPaginatedResult } from 'shared/types/paginatedResult';

const fetchEvents = async (page = 1, isActive?: boolean, search?: string): Promise<IPaginatedResult<IEvent<string>>> => {
  return axios.get<IPaginatedResult<IEvent<string>>>('/events', { params: { pageNumber: page, isActive, search } }).then((res) => {
    const filterData = res.data.data.filter((i) => i?.eventState !== EventCreationStatusType.Pending);
    return { ...res.data, data: filterData ?? [] };
  });
};

interface IParams {
  isActive?: boolean;
  searchValue?: string;
}

export const useGetEventListInfinite = ({ isActive, searchValue }: IParams) => {
  const search = searchValue === '' ? undefined : searchValue;
  const query = useInfiniteQuery({
    queryKey: ['events-for-select', { search }],
    queryFn: async ({ pageParam = 1 }) => await fetchEvents(pageParam, isActive, search),
    getNextPageParam: (lastPage: IPaginatedResult<IEvent<string>>, allPages: IPaginatedResult<IEvent<string>>[]) => {
      const maxItems = Math.ceil(lastPage.totalRecords / lastPage.pageSize);

      if (allPages.length < maxItems) {
        return allPages.length + 1;
      } else {
        return undefined;
      }
    }
  });

  const data = useMemo(() => {
    return query.data?.pages
      .map((x) => x.data)
      .flat(1)
      .filter((item) => !!item) as IEvent<string>[];
  }, [query.data?.pages]);

  return { ...query, data };
};
