import { useQuery } from 'react-query';
import axios from 'axios';
import { IOfferSettings } from 'shared/types/tenants';

export enum EOfferKey {
  settings = 'offer-settings',
  templates = 'offer-templates',
  template = 'offer-template'
}

interface IUseGetByIdProps {
  id?: string;
}

const fetchOfferSettingsByTenantId = (id: string): Promise<IOfferSettings> => {
  return axios.get(`/tenants/${id}/${EOfferKey.settings}`).then((res) => res.data);
};

export function useGetOfferSettings({ id }: IUseGetByIdProps) {
  return useQuery([EOfferKey.settings, { id }], () => fetchOfferSettingsByTenantId(id ?? ''), {
    enabled: !!id,
    refetchOnMount: true
  });
}
