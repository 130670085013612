import { lazy } from 'react';
import Loadable from 'shared/components/Loadable';
import AuthGuard from 'shared/components/route-guard/AuthGuard';
import MainLayout from 'shared/layouts/MainLayout';
import { SETTINGS_ROUTES } from './constants';

const SettingsPage = Loadable(lazy(() => import('pages/Settings')));
const SettingsUserTabPersonal = Loadable(lazy(() => import('modules/settings/components/TabPersonal')));
const SettingsUserTabInfo = Loadable(lazy(() => import('modules/settings/components/TabInfo')));
const SettingsUserTabGeneral = Loadable(lazy(() => import('modules/settings/components/GeneralSettingsTab')));
const SettingsUserTabInvoices = Loadable(lazy(() => import('modules/settings/components/Invoices')));
const SettingsUserTabOffers = Loadable(lazy(() => import('modules/settings/components/Offers')));
const SettingsUserTabVenue = Loadable(lazy(() => import('pages/Settings/venues')));
const SettingsUserTabEmailTemplates = Loadable(lazy(() => import('modules/settings/components/EmailTemplates')));
const SettingsUserTabEmailTemplate = Loadable(lazy(() => import('modules/settings/components/EmailTemplate')));

const SettingsUserTabVenueCreate = Loadable(lazy(() => import('pages/Settings/venues/Create')));
const SettingsUserTabVenueEdit = Loadable(lazy(() => import('pages/Settings/venues/Edit')));

export const SettingsRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'settings',
          element: <SettingsPage />,
          children: [
            {
              path: 'personal',
              element: <SettingsUserTabPersonal />
            },
            {
              path: 'general-settings',
              element: (
                <AuthGuard role={['tenant_admin', 'customer', 'admin']}>
                  <SettingsUserTabGeneral />
                </AuthGuard>
              )
            },
            {
              path: 'venues',
              element: (
                <AuthGuard role={['tenant_admin', 'customer', 'admin', 'venue_manager']}>
                  <SettingsUserTabVenue />
                </AuthGuard>
              )
            },
            {
              path: '/settings/venue',
              children: [
                {
                  path: ':id',
                  element: (
                    <AuthGuard role={['tenant_admin', 'customer', 'admin', 'venue_manager']}>
                      <SettingsUserTabVenueEdit />
                    </AuthGuard>
                  )
                },
                {
                  path: 'create',
                  element: (
                    <AuthGuard role={['tenant_admin', 'customer', 'admin', 'venue_manager']}>
                      <SettingsUserTabVenueCreate />
                    </AuthGuard>
                  )
                }
              ]
            },
            {
              path: 'invoices',
              element: (
                <AuthGuard role={['tenant_admin', 'customer', 'admin', 'venue_manager']}>
                  <SettingsUserTabInvoices />
                </AuthGuard>
              )
            },
            {
              path: SETTINGS_ROUTES.OFFERS,
              element: (
                <AuthGuard role={['tenant_admin', 'customer', 'admin', 'venue_manager']}>
                  <SettingsUserTabOffers />
                </AuthGuard>
              )
            },
            {
              path: 'email-templates',
              element: (
                <AuthGuard role={['tenant_admin', 'customer', 'admin', 'venue_manager']}>
                  <SettingsUserTabEmailTemplates />
                </AuthGuard>
              )
            },
            {
              path: 'email-template',
              children: [
                {
                  path: ':code',
                  element: (
                    <AuthGuard role={['tenant_admin', 'customer', 'admin', 'venue_manager']}>
                      <SettingsUserTabEmailTemplate />
                    </AuthGuard>
                  )
                }
              ]
            },
            {
              path: 'info',
              element: <SettingsUserTabInfo />
            }
          ]
        }
      ]
    }
  ]
};
