import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxWrapper = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  height: '100vh',
  flexDirection: 'column'
}));

export const OrganisationPlanPreviewLayoutContainer = styled(Box)(() => ({
  width: '1020px',
  margin: '0 auto',

  '& > .MuiBox-root': { '&:not(:last-child)': { marginBottom: 10 } },

  '@media print': {
    width: '100%'
  }
}));
