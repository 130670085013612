import { FormattedMessage } from 'react-intl';
import { DataPie20Regular } from '@fluentui/react-icons';
import { NavItemType } from 'shared/types/menu';

const icons = {
  DataPie20Regular
};

const chartsMap: NavItemType = {
  id: 'group-charts-map',
  title: <FormattedMessage id="charts-map" />,
  type: 'group',
  children: [
    {
      id: 'react-chart',
      title: <FormattedMessage id="charts" />,
      type: 'collapse',
      icon: icons.DataPie20Regular,
      children: [
        {
          id: 'apexchart',
          title: <FormattedMessage id="apexchart" />,
          type: 'item',
          url: '/mantis/charts/apexchart'
        },
        {
          id: 'org-chart',
          title: <FormattedMessage id="org-chart" />,
          type: 'item',
          url: '/mantis/charts/org-chart'
        }
      ]
    }
  ]
};

export default chartsMap;
