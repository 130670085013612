import { Box, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import EditorHeader from 'modules/editor/components/EditorHeader';
import { EEditorType } from 'modules/editor/types/editor';
import OfferModalSendEmail from 'modules/offers/components/OfferModalSendEmail';
import ModalEditOffer from 'modules/offers/containers/ModalEditOffer';
import OfferStatusSelect from 'modules/offers/containers/OfferStatusSelect';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { IOfferEditor } from 'shared/types/offer';

interface IOfferEditorHeader {
  isLoading: boolean;
}

const OfferEditorHeader = ({ isLoading }: IOfferEditorHeader) => {
  const intl = useIntl();
  const theme = useTheme();
  const [isEditOffer, setIsEditOffer] = useState(false);
  const [isSendEmail, setIsSendEmail] = useState(false);

  const { values, handleChange } = useFormikContext<IOfferEditor>();

  const handleEditOffer = (isOpen: boolean) => setIsEditOffer(isOpen);

  const handleSendEmail = (isOpen: boolean) => {
    setIsSendEmail(isOpen);
  };

  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '999',
        backgroundColor: theme.palette.background.paper
      }}
    >
      <EditorHeader
        type={EEditorType.Offers}
        onEdit={() => handleEditOffer(true)}
        onSendEmail={() => handleSendEmail(true)}
        isLoading={isLoading}
        title={intl.formatMessage({ id: 'offer.editor-offer' })}
      >
        <OfferStatusSelect value={values.state} handleChange={handleChange} />
      </EditorHeader>
      {isEditOffer && values.id && (
        <ModalEditOffer offerId={values.id} isOpenModal={isEditOffer} closeModal={() => handleEditOffer(false)} />
      )}
      {isSendEmail && <OfferModalSendEmail isOpenDialog={isSendEmail} closeDialog={() => handleSendEmail(false)} />}
    </Box>
  );
};

export default OfferEditorHeader;
