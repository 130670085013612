import { useEffect, useMemo, useRef, useState } from 'react';
import { Layout } from 'react-grid-layout';
import { useDispatch } from 'store';
import { updateOrganisationPlanLayoutPartially } from 'store/organisation-plan-template/organisation-plan-template.slice';
import { getOrganisationPlanWidgetRowsAmount } from 'modules/organisation-plan-templates/utils/getOrganisationPlanWidgetRowsAmount';

interface IUseUpdateOrganisationPlanLayoutPartially {
  widgetId: string;
  sectionId: string;
  layoutItem: Layout;
  dependencies?: Array<unknown>;
  additionalLayoutChanges?: Partial<Layout>;
  additionAmountOfHeight?: number;
  customerHeight?: null | number | boolean;
}
/**
 A custom React hook that updates an organisation plan layout partially by updating the widget's height and applying additional layout changes if provided.
 @function useUpdateOrganisationPlanLayoutPartially
 @param {Object} params - The parameters for the hook.
 @param {string} params.widgetId - The unique identifier of the widget.
 @param {string} params.sectionId - The unique identifier of the section containing the widget.
 @param {Layout} params.layoutItem - The layout item representing the widget in the grid layout.
 @param {Partial<Layout>} [params.additionalLayoutChanges] - Optional additional layout changes to be applied to the widget.
 @param {number} [params.additionAmountOfHeight=0] - Optional additional amount of height to be added to the widget's height.
 @returns {React.RefObject<HTMLDivElement>} - The reference to the HTMLDivElement used to calculate the height of the widget.
 */

const useUpdateOrganisationPlanLayoutPartially = ({
  widgetId,
  sectionId,
  layoutItem,
  additionalLayoutChanges,
  dependencies = [],
  additionAmountOfHeight = 0
}: IUseUpdateOrganisationPlanLayoutPartially) => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [isWidgetMounted, setIsWidgetMounted] = useState(false);

  useEffect(() => {
    setIsWidgetMounted(true);
    return () => setIsWidgetMounted(false);
  }, []);

  const useEffectDependecies = useMemo(() => {
    return dependencies ?? [];
  }, [dependencies]);

  useEffect(() => {
    if (!isWidgetMounted || typeof layoutItem?.h !== 'number' || typeof ref.current?.offsetHeight !== 'number') return;
    const newHeightForWidget = getOrganisationPlanWidgetRowsAmount(ref!.current.offsetHeight);

    if (layoutItem?.h === newHeightForWidget) return;

    dispatch(
      updateOrganisationPlanLayoutPartially({
        layout: {
          h: newHeightForWidget + additionAmountOfHeight,
          i: widgetId,
          minH: newHeightForWidget + additionAmountOfHeight,
          ...additionalLayoutChanges
        },
        sectionId
      })
    );
  }, [
    isWidgetMounted,
    widgetId,
    sectionId,
    layoutItem?.h,
    layoutItem.w,
    dispatch,
    additionAmountOfHeight,
    additionalLayoutChanges,
    useEffectDependecies
  ]);

  return ref;
};

export default useUpdateOrganisationPlanLayoutPartially;
