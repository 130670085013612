import * as React from 'react';
import OrganisationPlanTemplateSection from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateSection';
import { IOrganisationPlanSection } from 'modules/organisation-plan-templates/types/organisationPlanSection';
import { useSelector } from 'store';
import { selectOrganizationPlanSectionById } from 'store/organisation-plan-template/organisation-plan-template.selectors';

interface IOrgPlanSectionWrapperProps {
  sectionId: IOrganisationPlanSection['sectionId'];
  sectionIndex: number;
  isPaddingSection: boolean;
}

const OrgPlanSectionWrapper = ({ sectionId, sectionIndex, isPaddingSection }: IOrgPlanSectionWrapperProps): React.ReactElement | null => {
  const section = useSelector((state) => selectOrganizationPlanSectionById(state, sectionId));

  if (!section) return null;
  return (
    <OrganisationPlanTemplateSection
      isPaddingSection={isPaddingSection}
      key={section.sectionId}
      section={section}
      sectionIndex={sectionIndex}
    />
  );
};

export default OrgPlanSectionWrapper;
