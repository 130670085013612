import { Box } from '@mui/material';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { QuestionnairePageType } from 'modules/questionnaires/types/questionnaries';
import { FONT_FAMILY_OPTIONS } from 'modules/settings/constants';
import { EmailTemplatePageType } from 'modules/settings/types';
import { ReactNode, useEffect, useState } from 'react';
import { Editor, EditorProps, EditorState as EditorType } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useIntl } from 'react-intl';
import { CustomToolbar } from './DraftTextEditor.styles';

interface DraftTextEditorProps {
  /**
   * Editor value html string
   */
  value: string;
  /**
   * Editor change handler
   * @param newValue - editor new value
   */
  onChange: (newValue: string) => void;
  /**
   * Disable editor toolbar on focus
   * @default false
   */
  disableToolbarOnFocusLost?: boolean;

  page: QuestionnairePageType | EmailTemplatePageType;

  isMount?: boolean;
}

/**
 * Common draft text editor
 * @param value
 * @param onChange
 * @param disableToolbarOnFocusLost
 * @constructor
 */
const DraftTextEditor = ({ value, onChange, disableToolbarOnFocusLost = false, page, isMount }: DraftTextEditorProps) => {
  const intl = useIntl();
  const html = htmlToDraft(value);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap))
  );
  const [panelVisibility, setPanelVisibility] = useState(true);

  useEffect(() => {
    if (isMount) return;
    setEditorState(
      EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(value).contentBlocks, htmlToDraft(value).entityMap))
    );
  }, [isMount, value]);

  const onEditorStateChange = (editor: EditorType) => {
    const draftText = draftToHtml(convertToRaw(editor.getCurrentContent()));
    const isEmptyText = !editor.getCurrentContent().getPlainText().trim().length;
    setEditorState(editor);
    onChange(isEmptyText ? '' : draftText);
  };

  const onPanelVisibility = () => {
    setPanelVisibility(true);
  };

  const onPanelHidden = () => {
    setPanelVisibility(false);
  };

  const currentLanguage = intl.locale;

  const toolbarHideProps: EditorProps = disableToolbarOnFocusLost
    ? {
        toolbarHidden: panelVisibility,
        onFocus: onPanelHidden,
        onBlur: onPanelVisibility
      }
    : {};

  const EditorByType: Record<QuestionnairePageType | 'EmailTemplate', ReactNode> = {
    [QuestionnairePageType.Customs]: (
      <CustomToolbar isPanelHidden={false}>
        <Editor
          {...toolbarHideProps}
          localization={{ locale: currentLanguage }}
          toolbar={{
            fontFamily: {
              options: FONT_FAMILY_OPTIONS
            },
            fontSize: {
              options: [
                8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 34, 36, 38, 40, 42, 44,
                46, 48, 50, 52, 54
              ]
            }
          }}
          toolbarStyle={{ position: 'absolute', minWidth: '50vw', top: '-100px', maxWidth: '60vw' }}
          editorStyle={{ overflow: 'hidden', width: '100%', top: '0px' }}
          wrapperStyle={{ height: '100%' }}
          placeholder={intl.formatMessage({ id: 'builder.enter-text-here' })}
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
        />
      </CustomToolbar>
    ),
    [QuestionnairePageType.WelcomePage]: (
      <CustomToolbar isPanelHidden={panelVisibility}>
        <Editor
          {...toolbarHideProps}
          localization={{ locale: currentLanguage }}
          toolbar={{
            fontFamily: {
              options: FONT_FAMILY_OPTIONS
            },
            fontSize: {
              options: [
                8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 34, 36, 38, 40, 42, 44,
                46, 48, 50, 52, 54
              ]
            }
          }}
          toolbarStyle={{ position: 'relative', top: '0px' }}
          editorStyle={{ overflow: 'hidden', width: '100%' }}
          wrapperStyle={{ height: '100%' }}
          placeholder={intl.formatMessage({ id: 'builder.enter-text-here' })}
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
        />
      </CustomToolbar>
    ),
    [QuestionnairePageType.WelcomePageRequest]: (
      <CustomToolbar isPanelHidden={panelVisibility}>
        <Editor
          {...toolbarHideProps}
          localization={{ locale: currentLanguage }}
          toolbar={{
            fontFamily: {
              options: FONT_FAMILY_OPTIONS
            },
            fontSize: {
              options: [
                8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 34, 36, 38, 40, 42, 44,
                46, 48, 50, 52, 54
              ]
            }
          }}
          toolbarStyle={{ position: 'relative', top: '0px' }}
          editorStyle={{ overflow: 'hidden', width: '100%' }}
          wrapperStyle={{ height: '100%' }}
          placeholder={intl.formatMessage({ id: 'builder.enter-text-here' })}
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
        />
      </CustomToolbar>
    ),
    [QuestionnairePageType.GuestsAndRooms]: (
      <CustomToolbar isPanelHidden={panelVisibility}>
        <Editor
          {...toolbarHideProps}
          localization={{ locale: currentLanguage }}
          toolbar={{
            fontFamily: {
              options: FONT_FAMILY_OPTIONS
            },
            fontSize: {
              options: [
                8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 34, 36, 38, 40, 42, 44,
                46, 48, 50, 52, 54
              ]
            }
          }}
          toolbarStyle={{ position: 'relative', top: '0px' }}
          editorStyle={{ overflow: 'hidden', width: '100%', padding: '0px 10px' }}
          wrapperStyle={{ height: '50%' }}
          placeholder={intl.formatMessage({ id: 'editor-placeholder' })}
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
        />
      </CustomToolbar>
    ),
    [EmailTemplatePageType.EmailTemplate]: (
      <CustomToolbar isPanelHidden={false} isEmail={true}>
        <Editor
          {...toolbarHideProps}
          localization={{ locale: currentLanguage }}
          toolbar={{
            fontFamily: {
              options: FONT_FAMILY_OPTIONS
            },
            fontSize: {
              options: [
                8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 34, 36, 38, 40, 42, 44,
                46, 48, 50, 52, 54
              ]
            }
          }}
          toolbarStyle={{ position: 'relative', top: '0px' }}
          editorStyle={{ overflow: 'hidden', width: '100%', padding: '0px 10px' }}
          wrapperStyle={{ height: '50%' }}
          placeholder={intl.formatMessage({ id: 'editor-placeholder' })}
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
        />
      </CustomToolbar>
    )
  };

  return <Box className="custom-text-widget-appearance-settings">{EditorByType[page]}</Box>;
};

export default DraftTextEditor;
