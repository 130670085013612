export const NUMBER_FORMAT_PHONE = '+###############';
export const INDIVIDUAL_TAX = 19;
export const LEGAL_TAX = 7;
export const BLOCKED_SYMBOL_FOR_INPUT_INTEGER = 'E+-.,';
export const BLOCKED_SYMBOL_FOR_INPUT = 'eE+-';
export const BLOCKED_SYMBOL_MINUS = '-';
export const BLOCKED_SYMBOL_FOR_TABLE = 'e+-';
export const DEFAULT_LANGUAGE_SETTINGS = 'de';
export const DEFAULT_SYMBOL_SETTINGS = '€';
export const SETTINGS_PRICE_FORMAT = [
  { code: 'en', locales: 'en-IN', currency: 'USD', symbol: '$' },
  { code: 'de', locales: 'de-DE', currency: 'EUR', symbol: '€' },
  { code: 'de-GE', locales: 'de-DE', currency: 'EUR', symbol: '€' }
];
export const ARTICLE_NUMBER_ALREADY_USED = 'Article number already used.';
export const MAX_SIZE_FILE = 10483280; // 10 MB
