import { styled } from '@mui/material/styles';
import { Box, TableRow as MuiTableRow } from '@mui/material';

interface ITypeOfTable {
  isOptionalTable: boolean;
}
export const TableHeaderRow = styled(MuiTableRow)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '.MuiTableCell-root': {
    textTransform: 'capitalize'
  }
}));

export const Wrapper = styled(Box)<ITypeOfTable>(() => ({
  padding: '0px 33px 1px 33px',
  position: 'relative'
}));
