/**
 An enum representing types of images.
 @enum {string}
 @readonly
 @property {string} External - The type for external images. The type for images stored externally, such as on a CDN or other external service
 @property {string} Internal - The type for internal images. Internal - The type for images stored internally, such as in a local file system or database.
 */
export enum typeImages {
  External = 'External',
  Internal = 'Internal'
}

/**
 Generates a URL for the given image based on its type.
 @param {string} url - The URL of the image.
 @param {string} type - The type of the image, either 'Internal' or 'External'.
 @returns {string} - The URL of the image, with the appropriate prefix added for internal images.
 If the type is 'External', the input URL is returned unchanged.
 If the type is 'Internal', the input URL is prefixed with the value of the REACT_APP_STORAGE_URL environment variable.
 */
export const getStorageFileUrl = (url: string, type: string) => {
  if (!url) return url;
  return type === typeImages.Internal ? `${import.meta.env.VITE_REACT_APP_STORAGE_URL}/${url}` : url;
};
