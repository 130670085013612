import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
// import reportWebVitals from './shared/configs/reportWebVitals';
import { LicenseInfo } from '@mui/x-license';
import 'regenerator-runtime/runtime';
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import { router } from './app/AppRoutes';
import { AuthProvider } from './shared/contexts/AuthContext';
import { ConfigProvider } from './shared/contexts/ConfigContext';
import { GeneralSettingsProvider } from './shared/contexts/GeneralSettingsContext';
import { store } from './store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

LicenseInfo.setLicenseKey(
  'c5f2f5afe8ba96f40882efa01a88ee41Tz05NjQzMCxFPTE3NTU2NzgwMDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=='
);

const container = document.getElementById('root');
const root = createRoot(container!);

// turn off strictmode for production builds because of issues with the react-draft-wysiwyg library
root.render(
  // <StrictMode>
  <AuthProvider>
    <ReduxProvider store={store}>
      {/* <PersistGate loading={null} persistor={persister}> */}
      <ConfigProvider>
        <QueryClientProvider client={queryClient}>
          <GeneralSettingsProvider>
            <HelmetProvider>
              <RouterProvider router={router} />
            </HelmetProvider>
          </GeneralSettingsProvider>
        </QueryClientProvider>
      </ConfigProvider>
      {/* </PersistGate> */}
    </ReduxProvider>
  </AuthProvider>
  // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint.
// reportWebVitals();
