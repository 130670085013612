import { useMemo } from 'react';
import { Layout } from 'react-grid-layout';
import { Box, Grid } from '@mui/material';
import { CircleSmall20Filled } from '@fluentui/react-icons';
import { useSelector } from 'store';
import { selectIsEvent } from 'store/organisation-plan-template/organisation-plan-template.selectors';
import {
  IOrganisationPlanChoiceOptionReplacementLabels,
  SingleChoiceOrganisationPlanWidgetType
} from 'modules/organisation-plan-templates/types/organisationPlanChoiceWidgetType';
import { renderInputLabel } from 'modules/organisation-plan-preview/utils/renderInputLabelHandler';
import PreviewRenderOption from 'modules/organisation-plan-preview/components/PreviewRenderOption';
import PreviewWidgetWrapperOrgPlan from 'modules/organisation-plan-preview/components/PreviewWidgetWrapperOrgPlan';
import * as Styles from './PreviewChoiceWidget.styles';
import PreviewArticleValuesChoiceWidget from './PreviewArticleValuesChoiceWidget';

interface IPreviewChoiceWidgetProps {
  element: SingleChoiceOrganisationPlanWidgetType;
  layoutItem: Layout;
}

const PreviewChoiceWidget = ({ element, layoutItem }: IPreviewChoiceWidgetProps) => {
  const {
    label,
    widgetId,
    replacementLabel,
    replacementOptionsLabels,
    selectedOption,
    showOnlySelectedAnswer,
    isMainLabelHidden,
    articles,
    optionLabels,
    sectionId
  } = element;
  const isEvent = useSelector(selectIsEvent);

  const renderOptionsHandler = useMemo(() => {
    const checkedOptions = Array.isArray(selectedOption) ? new Set(selectedOption) : new Set([selectedOption]);
    const isShowOnlySelectedAnswer = isEvent ? showOnlySelectedAnswer : false;
    const selectedController = (id: string, replacementLabel: string | null, label: string) => {
      let isSelected = checkedOptions.has(id);
      return (
        <Styles.InputValueTypographyWrapper isSelected={isSelected}>
          {renderInputLabel(replacementLabel, label)}
          <PreviewArticleValuesChoiceWidget id={id} articles={articles} optionLabels={optionLabels} />
        </Styles.InputValueTypographyWrapper>
      );
      // return !!checkedOptions.has(id);
    };
    let renderOptions = [] as IOrganisationPlanChoiceOptionReplacementLabels[];
    if (replacementOptionsLabels && replacementOptionsLabels.length) {
      if (isShowOnlySelectedAnswer) {
        renderOptions = replacementOptionsLabels.filter((labelObj: { id: string }) => checkedOptions.has(labelObj.id));
      } else {
        renderOptions = [...replacementOptionsLabels];
      }
      const allLabelHidden = renderOptions.every((option) => option.isLabelHidden);
      if (allLabelHidden) {
        return null;
      }

      return renderOptions.map((labelObj) => {
        return (
          <Box key={labelObj.id} sx={{ display: labelObj.isLabelHidden ? 'none' : 'block' }}>
            <Styles.GridWrapper container>
              <Styles.IconTypeGridWrapper item display="flex">
                <CircleSmall20Filled style={{ marginRight: '10px' }} />
              </Styles.IconTypeGridWrapper>
              <Grid item display="flex" justifyContent="space-between" alignItems="center">
                {selectedController(labelObj.id, labelObj.replacementLabel, labelObj.label)}
              </Grid>
            </Styles.GridWrapper>
          </Box>
        );
      });
    }
  }, [articles, optionLabels, replacementOptionsLabels, showOnlySelectedAnswer, isEvent, selectedOption]);

  return (
    <PreviewWidgetWrapperOrgPlan widgetId={widgetId} sectionId={sectionId} layoutItem={layoutItem}>
      <PreviewRenderOption
        element={renderOptionsHandler}
        label={renderInputLabel(replacementLabel, label)}
        isMainLabelHidden={!!isMainLabelHidden}
      />
    </PreviewWidgetWrapperOrgPlan>
  );
};

export default PreviewChoiceWidget;
