import { useIntl } from 'react-intl';

export const useDefaultBuilderPagesTitle = () => {
  const intl = useIntl();

  return {
    welcomePageRequest: intl.formatMessage({ id: 'builder.welcome-page' }),
    welcomePage: intl.formatMessage({ id: 'builder.welcome-page' }),
    guestsAndRooms: intl.formatMessage({ id: 'builder.guest-rooms' }),
    title: intl.formatMessage({ id: 'builder.title' })
  };
};
