import * as React from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useAddImageToUser } from 'api/hooks/user/useAddImageToUser';
import { useCreateUser } from 'api/hooks/user/useCreateUser';
import { prepareUserSendData } from 'modules/users/utils/prepareDataForUser';
import { IUser, IUserFormData } from 'shared/types/user';
import { useDispatch, useSelector } from 'store';
import { selectPaginationUsers } from 'store/pagination/pagination.selectors';
import useGeneralSettings from 'shared/hooks/useGeneralSettings';
import { AlertMessagesColor } from 'shared/types/alert-messages';
import { snackbarAlert, textError } from 'shared/utils/infoText';
import CreateCustomerForm from 'modules/events/components/CreateCustomerForm';
import { EventsQueryKeys } from 'modules/events/hooks/useGetCustomerInf';
import { useEditUser } from 'api/hooks/user/useEditUser';
import { useGetUserById } from 'api/hooks/user/useGetUser';

interface ICreateUserProps {
  userId?: IUser['id'];
  closeCreateUserModel: () => void;
  updateUserValue?: (user: IUser) => void;
  open: boolean;
}

const CreateUserModal = ({ closeCreateUserModel, userId, updateUserValue, open }: ICreateUserProps): React.ReactElement => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const pagination = useSelector(selectPaginationUsers);
  const queryClient = useQueryClient();

  const { user: currentUserData } = useGeneralSettings();
  const { data: userData, isLoading: isUserFetching } = useGetUserById({ id: userId ? userId.toString() : undefined });
  const { isLoading, mutateAsync } = useCreateUser({
    pagination,
    options: {
      onError(error: any) {
        let message = textError(error);
        dispatch(snackbarAlert(message, AlertMessagesColor.Error));
      }
    }
  });

  const onSuccessResult = () => {
    queryClient.invalidateQueries(EventsQueryKeys.GetEventsCustomer);
    closeCreateUserModel();
    const messageTranslateKey = userId ? 'events.user-updated-successfully' : 'events.user-created-successfully';
    dispatch(snackbarAlert(intl.formatMessage({ id: messageTranslateKey }), AlertMessagesColor.Success));
  };

  const { isLoading: isUpdating, mutateAsync: updateAsync } = useEditUser({
    id: userId,
    pagination,
    options: {
      onError(error: any) {
        let message = textError(error);
        dispatch(snackbarAlert(message, AlertMessagesColor.Error));
      }
    }
  });

  const { mutate: mutateImages, isLoading: isImageSaving } = useAddImageToUser({
    onSuccess: () => {
      onSuccessResult();
    },
    options: {
      onError(error: any) {
        let message = textError(error, intl.formatMessage({ id: 'events.image-upload-failed' }));
        dispatch(snackbarAlert(message, AlertMessagesColor.Warning));
      }
    }
  });

  const handleSubmitCreate = async (formData: IUserFormData, files: FileList | null) => {
    const data = prepareUserSendData(formData);
    const userResponse = await mutateAsync(data, {
      onSuccess: files?.length ? undefined : onSuccessResult
    });
    if (files) {
      mutateImages({ id: userResponse.id, file: files[0] });
    }
  };

  const handleSubmitEdit = async (formData: IUserFormData, files: any, userId: IUser['id']) => {
    const data = {
      ...prepareUserSendData(formData),
      id: userId
    };
    const userResponse = await updateAsync(data, {
      onSuccess: files?.length ? undefined : onSuccessResult
    });
    if (updateUserValue) {
      updateUserValue(userResponse);
    }

    if (files) {
      mutateImages({ id: userId, file: files[0] });
    }
  };

  const handleSubmit = async (formData: IUserFormData, files: FileList | null) => {
    if (userId) {
      handleSubmitEdit(formData, files, userId);
    } else {
      handleSubmitCreate(formData, files);
    }
  };

  return (
    <CreateCustomerForm
      open={open}
      data={userData ?? null}
      onCancel={closeCreateUserModel}
      closeCreateUserModel={closeCreateUserModel}
      onSubmit={handleSubmit}
      isLoading={isLoading || isImageSaving || isUpdating || isUserFetching}
      currentUserRole={currentUserData?.role}
    />
  );
};

export default CreateUserModal;
