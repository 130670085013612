import { Layout } from 'react-grid-layout';
import { ILinkObject } from 'modules/builder/components/SettingsBar/LinkingTab/types';
import { OmitDistributive } from 'shared/types';
import { QuestionnaireTemplatePageItem } from 'shared/types/questionnaire-template';
import { IArticle, IArticleFormData, IArticleSendData } from 'modules/articles/types';
import { AddSubPageFn, EditPageFn } from 'modules/events/types';
import { IAppearanceSettings } from 'modules/questionnaires/types';
import { IQuestionnaireImageItemData, QuestionnairePageType } from 'modules/questionnaires/types/questionnaries';
import { VisibilityObjectType } from 'modules/builder/components/SettingsBar/VisibilityTab/types';
import { MultipleChoiceWidgetType, SingleChoiceWidgetType } from './choiceWidgetsType';
import { DividerWidgetType } from './dividerWidget';
import { ImageWidgetType, MultipleImgChoiceWdigetType, SingleImgChoiceWidgetType, TopBannerWidgetType } from './imageWidgetsTypes';
import { InputIntegerWidgetType } from './inputIntegerWidget';
import { TableWidgetType } from './tableWidgetType';
import { InputTextWidgetType } from './textInputWidgetType';
import { TextWidgetType } from './textWidgetTypes';
import { TimeWidgetType } from './timePickerType';
import { IDownloadAreaWidgetsType, IUploadAreaWidgetsType } from './uploadAreaWidgetsType';
import { ITimeRangeWidgetType, ITimeRangeWidgetTypeDB } from './timeRangeWidgetType';
import { DateGroupWidgetType, DateGroupWidgetTypeDB } from './dateGroupWidgetType';

export interface BaseWidget {
  widgetId: string;
  links?: ILinkObject[] | null;
  isHidden?: boolean;
  value?: string | number | null;
  selectedOption?: string | number;
  selectedOptions?: string[];
  isLockedForCustomer?: boolean;
  isHiddenForCustomer?: boolean;
  visibility?: VisibilityObjectType;
}

export enum BUILDER_WIDGET_TYPES {
  image = 'image',
  table = 'table',
  divider = 'divider',
  topBanner = 'topBanner',
  inputInteger = 'inputInteger',
  singleImageChoice = 'singleImageChoice',
  multipleImageChoice = 'multipleImageChoice',
  singleChoice = 'singleChoice',
  multipleChoice = 'multipleChoice',
  text = 'text',
  uploadArea = 'uploadArea',
  time = 'time',
  inputText = 'inputText',
  download = 'download',
  timeRange = 'timeRange',
  dateGroup = 'dateGroup'
}

export type WidgetsType =
  | ImageWidgetType
  | TableWidgetType
  | TopBannerWidgetType
  | DividerWidgetType
  | InputIntegerWidgetType
  | SingleChoiceWidgetType
  | SingleImgChoiceWidgetType
  | MultipleImgChoiceWdigetType
  | MultipleChoiceWidgetType
  | TextWidgetType
  | IUploadAreaWidgetsType
  | TimeWidgetType
  | InputTextWidgetType
  | IDownloadAreaWidgetsType
  | ITimeRangeWidgetType
  | DateGroupWidgetType;

export type WidgetsTypeOnlySupportedByOrganisationPlan =
  | ImageWidgetType
  | TableWidgetType
  | InputIntegerWidgetType
  | SingleChoiceWidgetType
  | SingleImgChoiceWidgetType
  | MultipleImgChoiceWdigetType
  | MultipleChoiceWidgetType
  | IUploadAreaWidgetsType
  | TimeWidgetType
  | InputTextWidgetType;

export type WidgetsTypeWithoutId = OmitDistributive<WidgetsType, 'widgetId'>;

export interface IAciveWidgetForSettings {
  widgetId: WidgetsType['widgetId'];
  optionId?: WidgetsType['widgetId'];
}

export type BuilderPageItemType<T = unknown> = Omit<QuestionnaireTemplatePageItem<T>, 'id' | 'child' | 'questionnairePageType'> & {
  id: string;
  type: QuestionnairePageType;
  isSaved?: boolean;
  appearanceSettings?: IAppearanceSettings;
  childPages: BuilderPageItemType<T>[] | null;
  addSubPageFnCreate?: AddSubPageFn;
  editPage?: EditPageFn;
  navigateTo?: (questionnaireId: string, pageId: string, subPageId?: string) => string;
};

type LayoutForNewWidget = Omit<Layout, 'i' | 'x' | 'y'> & Partial<Pick<Layout, 'x' | 'y'>>;

export interface ISideBarWidget {
  imgUrl: string;
  label: string;
  layout: LayoutForNewWidget;
  widget: WidgetsTypeWithoutId;
  formatId?: string;
}

export interface ISettingsForWidget {
  price?: boolean;
  settings?: boolean;
  linking?: boolean;
  visibility?: boolean;
  restrictions?: boolean;
  customisation?: boolean;
  optionSettings?: Omit<ISettingsForWidget, 'optionSettings'>;
}

export enum TabsKey {
  price = 'price',
  customisation = 'customisation',
  settings = 'settings',
  linking = 'linking',
  restrictions = 'restrictions',
  visibility = 'visibility'
}

export interface IWelcomePageFormData {
  headerPhoto?: IQuestionnaireImageItemData | null;
  greetings: { label: string; group: string };
  content: string | null;
}

export interface IGuestAndRoomsCustomQuestion extends IAdditionalFields {
  id: string;
  label: string;
  isCheck: boolean;
  description?: string;
  value?: number;
}

export interface IArticlePage {
  id: number;
  questionnaireArticle: IArticle;
  usedArticle: IArticle;
  questionnaireTemplateId: number;
  questionnaireTemplatePageId: number;
  widgetId: string;
  widgetOptionId?: string;
}

export interface IGuestAndRoomsPageFormData {
  headerPhoto: IQuestionnaireImageItemData | null;
  numberOfGuests: string;
  guestQuestions: IGuestAndRoomsCustomQuestion[];
  rooms?: string;
  roomsQuestions: IGuestAndRoomsCustomQuestion[];
  articles?: IArticlePage[];
  usedArticles?: IUsedArticles[];
  usedWidgets?: WidgetsType[];
  dateSection?: IGuestAndRoomsDateSection;
  guestQuestionsIsCheck?: boolean;
  roomsIsCheck?: boolean;
}
export interface IGuestAndRoomsPageFormDataDB {
  headerPhoto: IQuestionnaireImageItemData | null;
  guestQuestions?: IGuestAndRoomsCustomQuestion[];
  roomsQuestions?: IGuestAndRoomsCustomQuestion[];
  dateSection?: IGuestAndRoomsDateSectionDB;
  articles?: IArticlePage[];
  usedArticles?: IUsedArticles[];
  usedWidgets?: WidgetsType[];
  rooms?: string;
  guestSection: {
    question: string;
    isCheck: boolean;
    fields: IGuestAndRoomsCustomQuestion[];
  };
  roomsSection: {
    question?: string;
    isCheck: boolean;
    fields: IGuestAndRoomsCustomQuestion[];
  };
}

export interface IUsedArticlesQuestionnairesPages {
  id: number;
  questionnaireArticleId: number;
  questionnaireId: number;
  questionnairePageId: number;
  usedArticle: IArticle;
  widgetId: string;
  widgetOptionId: string;
}
export interface IUsedArticles {
  widgetId: string;
  optionId?: string;
  usedArticle: IArticleFormData;
}
export interface IUsedArticlesForSend {
  widgetId: string;
  widgetOptionId?: string;
  usedArticle: IArticleSendData;
}

export interface IGuestAndRoomsDateSection {
  widgetId?: string;
  question: string;
  isCheck: boolean;
  preferredDate: ITimeRangeWidgetType;
  estimatedTime: ITimeRangeWidgetType;
  alternateDates: DateGroupWidgetType;
  isDisplayedAtRequest: boolean;
  isDisplayedAtEvent: boolean;
  pickerType: 'dateTime' | 'date' | 'dateRange' | 'dateTimeRange';
  isLockedForCustomer: boolean;
  isJustDateLockedForCustomer: boolean;
  isAlternateDatesDisplayed: boolean;
  amountOfAlternateDates: number;
  configMode?: 'room-config' | 'guest-config' | 'date-config';
}

export interface IGuestAndRoomsDateSectionDB {
  question: string;
  isCheck: boolean;
  preferredDate: ITimeRangeWidgetTypeDB | null;
  estimatedTime: ITimeRangeWidgetTypeDB;
  alternateDates: DateGroupWidgetTypeDB;
  isDisplayedAtRequest: boolean;
  isDisplayedAtEvent: boolean;
  pickerType: 'dateTime' | 'date' | 'dateRange' | 'dateTimeRange';
  isLockedForCustomer: boolean;
  isJustDateLockedForCustomer: boolean;
  isAlternateDatesDisplayed: boolean;
  amountOfAlternateDates: number;
  configMode?: 'room-config' | 'guest-config' | 'date-config';
}

export interface IGuestAndRoomsPageFormDataInstance {
  headerPhoto: IQuestionnaireImageItemData | null;
  numberOfGuests: string;
  guestQuestions: IGuestAndRoomsCustomQuestion[];
  rooms: string;
  roomsQuestions: IGuestAndRoomsCustomQuestion[];
  roomsIsCheck: boolean;
  articles?: QuestionnaireTemplatePageItem['articles'];
  usedArticles: IUsedArticles[] | [];
  selectBuilderWidgetId?: string;
  selectUsedArticleWidget?: IUsedArticles;
  usedWidgets?: WidgetsType[];
  dateSection: IGuestAndRoomsDateSection;
  guestQuestionsIsCheck: boolean;
}

export interface IPlaygroundPageFormData {
  layout: Layout[];
  usedWidgets: WidgetsType[];
  usedArticles?: IUsedArticles[] | [];
}

export enum BuilderLayoutVariant {
  Layout1,
  Layout2,
  Layout3
}

export interface LayoutWithWidgetsConfig {
  layout: Omit<Layout, 'i'>[];
  widgets: WidgetsTypeWithoutId[];
}

export interface IAdditionalFields {
  numericallyValue?: number | null;
  secondUnitValue?: number | null;
  countOfGuests?: number | null;
}
