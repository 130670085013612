import { Box, Button, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Greeting = styled(Box)(() => ({
  fontStyle: 'normal',
  paddingTop: 22,
  paddingBottom: 10
}));

export const ButtonStack = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  margin: 0
}));

export const ButtonImport = styled(Button)(() => ({
  width: 224,
  lineHeight: 1,
  minHeight: '41px',
  margin: '20px 0 10px 10px',
  color: '#000000'
}));

export const BlockTooltip = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end'
}));

export const TooltipBox = styled(Box)(() => ({
  cursor: 'pointer',
  position: 'absolute',
  top: '-10px',
  left: '10px',
  width: '100%',
  height: '100%',
  padding: '0',
  margin: '0'
}));
