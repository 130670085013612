import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

const LogoMain = () => {
  const logoEventCloud = '/assets/images/icons/logo-dark.svg';
  const theme = useTheme();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Mantis" width="100" />
     *
     */
    <>
      <img src={theme.palette.mode === 'dark' ? logoEventCloud : logoEventCloud} alt="Event Cloud" width="35" height="35" />
      <Typography
        variant="h4"
        align="center"
        sx={{
          pl: 1,
          color: theme.palette.mode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.A100
        }}
      >
        Event Cloud
      </Typography>
    </>
  );
};

export default LogoMain;
