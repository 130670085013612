const ROOT_LINK_LIST = '/requests';
const ROOT_LINK_SINGLE = '/request';

export const REQUESTS_ROUTES = {
  ROOT_LINK: ROOT_LINK_LIST,
  LINK_BY_REQUEST_ID: `${ROOT_LINK_SINGLE}/:requestId`,
  GET_LINK_BY_REQUEST_ID: (id: string) => `${ROOT_LINK_SINGLE}/${id}`
};

export const QUESTIONNAIRES_REQUEST_PREVIEW_ROUTES = {
  ROOT_LINK: '/request/questionnaires/preview',
  LINK_BY_QUESTIONNAIRE: ':questionnaireId',
  LINK_BY_PAGE_ID: ':questionnaireId/:pageId',
  LINK_BY_PAGE_ID_AND_SUBPAGE_ID: ':questionnaireId/:pageId/:subPageId',
  PAGE_BY_ID: (questionnaireId: string, pageId: string, subPageId?: string) =>
    `/request/questionnaires/preview/${questionnaireId}${pageId ? `/${pageId}` : ''}${subPageId ? `/${subPageId}` : ''}`,
  GET_LINK_BY_QUESTIONNAIRE_ID: (id: string) => `/request/questionnaires/preview/${id}`
};

export const QUESTIONNAIRES_REQUEST_EDIT_ROUTES = {
  ROOT_LINK: '/request/questionnaires/',
  EDIT_LINK_BY_QUESTIONNAIRE: ':questionnaireId',
  EDIT_LINK_BY_PAGE_ID: ':questionnaireId/:pageId',
  EDIT_LINK_BY_PAGE_ID_AND_SUBPAGE_ID: ':questionnaireId/:pageId/:subPageId',
  EDIT_PAGE_BY_ID: (questionnaireId: string, pageId: string, subPageId?: string) =>
    `/request/questionnaires/${questionnaireId}${pageId ? `/${pageId}` : ''}${subPageId ? `/${subPageId}` : ''}`,
  GET_LINK_BY_QUESTIONNAIRE_ID: (id: string) => `/request/questionnaires/${id}`
};

export const QUESTIONNAIRES_REQUEST_PLAYGROUND_ROUTES = {
  ROOT_LINK: '/request/playground',
  LINK_PLAYGROUND_PAGE_ID: ':questionnaireId/:pageId',
  LINK_PLAYGROUND_PAGE_ID_AND_SUBPAGE_ID: ':questionnaireId/:pageId/:subPageId',
  PLAYGROUND_PAGE_BY_ID: (questionnaireId: string, pageId: string, subPageId?: string) =>
    `/request/playground/${questionnaireId}${pageId ? `/${pageId}` : ''}${subPageId ? `/${subPageId}` : ''}`
};
