import { Dispatch, SetStateAction, useState } from 'react';
import { useGetDataByRole } from 'shared/hooks/useGetDataByRole';
import CreateCustomerFormAdditionalEmailAndPhoneInfo from './CreateCustomerFormAdditionalEmailAndPhoneInfo';
import CreateCustomerFormAdditionalPartnerInfo from './CreateCustomerFormAdditionalPartnerInfo';
import CreateCustomerFormAdditionalPartnerSwitcherButton from './CreateCustomerFormAdditionalPartnerSwitcherButton';
import CreateCustomerFormEmailPhoneInfo from './CreateCustomerFormEmailPhoneInfo';
import CreateCustomerFormInitials from './CreateCustomerFormInitials';
import CreateCustomerFormProfilePicture from './CreateCustomerFormProfilePicture';

interface ICustomerGeneralInfoSectionProps {
  setUploadFileInput: Dispatch<SetStateAction<FileList | null>>;
}

const CustomerGeneralInfoSection = ({ setUploadFileInput }: ICustomerGeneralInfoSectionProps) => {
  const [isAdditionalPartnerShown, setAdditionalPartnerShown] = useState(false);
  const { disableForEventManager } = useGetDataByRole();

  return (
    <>
      <CreateCustomerFormProfilePicture setUploadFileInput={setUploadFileInput} />
      <CreateCustomerFormInitials />
      {isAdditionalPartnerShown && <CreateCustomerFormAdditionalPartnerInfo />}
      {!disableForEventManager && (
        <CreateCustomerFormAdditionalPartnerSwitcherButton
          isAdditionalPartnerShown={isAdditionalPartnerShown}
          setAdditionalPartnerShown={setAdditionalPartnerShown}
        />
      )}

      <CreateCustomerFormEmailPhoneInfo />
      <CreateCustomerFormAdditionalEmailAndPhoneInfo />
    </>
  );
};

export default CustomerGeneralInfoSection;
