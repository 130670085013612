import { useQuery } from 'react-query';
import axios from 'axios';
import { API_QUESTIONNAIRES_ENDPOINTS } from 'api/endpoints/questionnaires';
import { IQuestionnaire } from 'modules/questionnaires/types';
import { EQuestionnairesSource } from 'shared/types/questionnaire-template';

interface IuseGetQuestionnaireInfoByIdParams {
  entitySource: EQuestionnairesSource;
  id?: string | undefined;
}

function fetchQuestionnaireInfoById(id: string, entitySource: EQuestionnairesSource): Promise<IQuestionnaire> {
  const api = API_QUESTIONNAIRES_ENDPOINTS[entitySource];
  return axios.get(api.GET_BY_ID(id)).then((res) => res.data);
}

export function useGetQuestionnaireInfoById({ id, entitySource }: IuseGetQuestionnaireInfoByIdParams) {
  return useQuery<IQuestionnaire>(['questionnaire', { id }], () => fetchQuestionnaireInfoById(id ?? '', entitySource), {
    enabled: !!id,
    refetchOnMount: true
  });
}
