import GridLayout, { Layout } from 'react-grid-layout';
import { Box } from '@mui/material';
import { useSelector } from 'store';
import { selectIsEvent, selectOrganizationPlanAllWidgets } from 'store/organisation-plan-template/organisation-plan-template.selectors';
import * as Styles from 'modules/organisation-plan-templates/components/OrganisationPlanTemplatePlaygroundSection/OrganisationPlanTemplatePlaygroundSection.styles';
import { GRID_LAYOUT_SIZE_ORGANISATION_PLAN } from 'modules/organisation-plan-templates/constants/organisationPlanTemplateBuilder';
import PreviewWidget from 'modules/organisation-plan-preview/components/OrganisationPlanPreviewWidgetSection/preview-widgets/PreviewWidget';
import { filterLayoutAndWidgetWithActiveLinkedWidgetId, filterLayoutArrayByIsHiddenField, filteredTemplateByAnswers } from './utils';

interface OrganisationPlanTemplatePlaygroundSectionProps {
  layout: GridLayout.Layout[];
  sectionId: string;
}

const OrganisationPlanPreviewTemplatePlaygroundSection = ({ layout }: OrganisationPlanTemplatePlaygroundSectionProps) => {
  const isEvent = useSelector(selectIsEvent);

  const allWidgets = useSelector(selectOrganizationPlanAllWidgets);
  const widgets = isEvent ? allWidgets : allWidgets.map((w) => ({ ...w, showOnlySelectedAnswer: false }));
  const filteredByLinkedWidgetId = filterLayoutAndWidgetWithActiveLinkedWidgetId(layout, widgets);
  const filteredByAnswers = filteredTemplateByAnswers(filteredByLinkedWidgetId.layoutArray, filteredByLinkedWidgetId.widgets);
  const filterData = isEvent ? filteredByAnswers : filteredByLinkedWidgetId;
  const filteredLayout = filterLayoutArrayByIsHiddenField(filterData.layoutArray, filterData.widgets);
  const filteredLayoutWithoutResizable = filteredLayout.map((item) => ({
    ...item,
    isResizable: false,
    resizeHandles: []
  }));
  return (
    <Styles.BoxWrapper>
      <GridLayout
        margin={[GRID_LAYOUT_SIZE_ORGANISATION_PLAN.rowGap, GRID_LAYOUT_SIZE_ORGANISATION_PLAN.rowGap]}
        containerPadding={[0, 0]}
        className="organisation-plan-layout"
        layout={filteredLayoutWithoutResizable}
        cols={GRID_LAYOUT_SIZE_ORGANISATION_PLAN.col}
        rowHeight={GRID_LAYOUT_SIZE_ORGANISATION_PLAN.rowHeight}
        width={GRID_LAYOUT_SIZE_ORGANISATION_PLAN.widthGrid}
        isDraggable={false}
        isResizable={false}
        compactType="vertical"
        draggableHandle=".on-draggable"
        onDrag={(_layout, _oldItem, _newItem, _placeholder, event) => event.preventDefault()}
      >
        {filteredLayoutWithoutResizable.map((item: Layout) => (
          <Box key={item.i}>
            <PreviewWidget key={item.i} layoutItem={item} />
          </Box>
        ))}
      </GridLayout>
    </Styles.BoxWrapper>
  );
};

export default OrganisationPlanPreviewTemplatePlaygroundSection;
