import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Button, CircularProgress, InputLabel, Stack, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTheme } from '@mui/material/styles';
import DialogImportWarning from 'modules/invoices/components/DialogImportWarning';
import { IEditorArticle } from 'modules/editor/types/editor';
import { EOfferEditorArticleOptionalType, IOfferEditor } from 'shared/types/offer';
import { useGetOfferArticleItemsOptional } from 'api/hooks/offers/useGetOfferArticleItemsOptional';
import { addBasePriceUnitWordingToArticle, addInnerIdToArticle, updateOrderArticle } from 'modules/invoices/utils/prepareData';
import { checkIsImportedOptionalArticle, generateListOfferArticlesFromDBOptionalArticles } from 'modules/offers/utils/prepareData';
import * as Styles from './OptionalTextImportButtons.styles';

interface IIsImportAgain {
  isOpen: boolean;
  items: IEditorArticle[];
  type: EOfferEditorArticleOptionalType | null;
}

const initialIsImportAgain: IIsImportAgain = { isOpen: false, items: [], type: null };

const OptionalTextImportButtons = () => {
  const theme = useTheme();
  const intl = useIntl();

  const [importedAgain, setImportedAgain] = useState<IIsImportAgain>(initialIsImportAgain);

  const { getFieldProps, values, setFieldValue } = useFormikContext<IOfferEditor>();

  const { mutateAsync: getArticles, isLoading: isArticlesLoading } = useGetOfferArticleItemsOptional();

  const handleImportArticles = async () => {
    if (!values?.id) return;
    const type = EOfferEditorArticleOptionalType.OptionalImported;
    const res = await getArticles(values?.id);

    const articlesWithInnerId = addInnerIdToArticle(res);
    const resArticles = addBasePriceUnitWordingToArticle(articlesWithInnerId, intl);
    const isImportedArticle = checkIsImportedOptionalArticle(
      values?.articlesOptional as IEditorArticle<EOfferEditorArticleOptionalType>[],
      type
    );
    if (isImportedArticle) {
      return setImportedAgain({
        isOpen: true,
        items: resArticles,
        type: EOfferEditorArticleOptionalType.OptionalImported
      });
    }
    setFieldValue('articlesOptional', updateOrderArticle(values?.articlesOptional, resArticles));
  };

  const handleImportWithReplacement = () => {
    if (!importedAgain?.type) return;
    const newArticles = generateListOfferArticlesFromDBOptionalArticles(
      EOfferEditorArticleOptionalType.OptionalImported,
      (values?.articlesOptional as IEditorArticle<EOfferEditorArticleOptionalType>[]) ?? [],
      (importedAgain?.items as IEditorArticle<EOfferEditorArticleOptionalType>[]) ?? []
    );
    setFieldValue('articlesOptional', updateOrderArticle(newArticles));
    handleCloseWarningImport();
  };

  const handleImportAll = () => {
    setFieldValue('articlesOptional', updateOrderArticle(values?.articlesOptional, importedAgain?.items));
    handleCloseWarningImport();
  };

  const handleCloseWarningImport = () => {
    setImportedAgain(initialIsImportAgain);
  };

  return (
    <>
      <Styles.WrapperContent>
        <Stack spacing={1}>
          <InputLabel>
            <FormattedMessage id="offer.editor-optional-message" />
          </InputLabel>
          <TextField
            sx={{ backgroundColor: theme.palette.background.paper }}
            multiline
            minRows={2}
            fullWidth
            id="optionalText"
            variant="outlined"
            placeholder={intl.formatMessage({ id: 'settings.autosize-height-based-on-content-lines' })}
            {...getFieldProps('settingsTemplate.optionalText')}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box>
            <h3>
              <FormattedMessage id="offer.editor-optional-articles" />
            </h3>
          </Box>
          <Box>
            <Styles.ButtonStack>
              <Styles.BlockTooltip>
                <Button
                  fullWidth
                  size="medium"
                  variant="outlined"
                  color="secondary"
                  onClick={handleImportArticles}
                  disabled={isArticlesLoading || !values?.data}
                  startIcon={isArticlesLoading && <CircularProgress size={'20px'} />}
                >
                  <FormattedMessage id="offer.editor-import-optional-articles" />
                </Button>
              </Styles.BlockTooltip>
            </Styles.ButtonStack>
          </Box>
        </Stack>

        <DialogImportWarning
          isOpen={importedAgain?.isOpen}
          closeDialog={handleCloseWarningImport}
          handleImportWithReplacement={handleImportWithReplacement}
          handleImportAll={handleImportAll}
        />
      </Styles.WrapperContent>
    </>
  );
};

export default OptionalTextImportButtons;
