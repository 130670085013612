import { useIntl } from 'react-intl';
import { Person20Regular } from '@fluentui/react-icons';
import { IGuestDisplayOption, IOrgPlanGuestOptions } from 'shared/types/organisation-plan-preview';
import {
  OrganisersAndGuestsInfoDescription,
  OrganisersAndGuestsInfoItem
} from './OrganisationPlanPreviewOrganisersAndGuestsSection.styles';
import * as Styles from './OrganisationPlanPreviewOrganisersAndGuestsSection.styles';

interface IOrganisationPlanPreviewNumberOfGuestsInfoOptionProps {
  option: IGuestDisplayOption;
  guestOptionsValues: IOrgPlanGuestOptions[];
}
const OrganisationPlanPreviewNumberOfGuestsInfoOption = ({
  option,
  guestOptionsValues
}: IOrganisationPlanPreviewNumberOfGuestsInfoOptionProps) => {
  const intl = useIntl();
  const countOfGuest = guestOptionsValues?.find((guest) => guest?.id === option?.id)?.count;
  const guestInfo = intl.formatMessage({ id: 'organisation-plan.number-of-guests' });

  return countOfGuest ? (
    <OrganisersAndGuestsInfoItem>
      <Styles.BoxWrapper mr={0.75}>
        <Person20Regular />
      </Styles.BoxWrapper>
      <OrganisersAndGuestsInfoDescription>{`${option?.label}: ${countOfGuest} ${guestInfo}`}</OrganisersAndGuestsInfoDescription>
    </OrganisersAndGuestsInfoItem>
  ) : null;
};

export default OrganisationPlanPreviewNumberOfGuestsInfoOption;
