import { Box, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IAppearanceSettings } from 'modules/questionnaires/types';

interface IModalEditorTextStyleProps {
  appearanceSettings?: IAppearanceSettings;
}

export const BoxContent = styled(Box)(() => ({
  // position: 'absolute' as 'absolute',
  // top: '50%',
  // left: '50%',
  height: '100%'
  // width: '960px',
  // transform: 'translate(-50%, -50%)',
  // padding: '22px'
}));

export const DialogWrapper = styled(Dialog)<IModalEditorTextStyleProps>(({ appearanceSettings }) => ({
  '& .MuiPaper-root .DraftEditor-root': {
    fontFamily: appearanceSettings?.fontFamily,
    fontSize: appearanceSettings?.textSize
  },
  backgroundColor: '#ffffff90'
}));
