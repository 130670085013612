import { CheckboxChecked20Filled, CheckboxUnchecked20Regular } from '@fluentui/react-icons';
import {
  Autocomplete,
  Checkbox,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  UseAutocompleteProps
} from '@mui/material';
import { debounce } from 'lodash';
import { useQuestionnaireCustomerInfinite } from 'modules/events/hooks/useGetCustomerInf';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAuth } from 'shared/contexts/AuthContext';
import { IContactPeople } from 'shared/types/event';

interface ISelectContactPerson {
  value?: IContactPeople[] | IContactPeople | null;
  isMultipleSelect?: boolean;
  handleChange: (selectedPersons: IContactPeople[] | IContactPeople | null | []) => void;
  label?: string;
  placeholder: string;
  isError?: boolean;
  isDisabled?: boolean;
  errorText?: string;
}

const SelectContactPerson = ({
  label,
  placeholder,
  value,
  isMultipleSelect,
  handleChange,
  isError,
  isDisabled,
  errorText
}: ISelectContactPerson) => {
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const selectedValueString = useRef<string | null>(null);

  const debouncedResults = useMemo(() => {
    return debounce(setSearchValue, 300);
  }, []);

  useEffect(() => {
    if (!debouncedResults) return;
    if (selectedValueString.current === inputValue) {
      debouncedResults('');
      return;
    }
    debouncedResults(inputValue);
  }, [inputValue, debouncedResults]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  }, []);

  const intl = useIntl();
  const { tokenInfo } = useAuth();

  const {
    data: allContactPerson = [],
    fetchNextPage,
    hasNextPage
  } = useQuestionnaireCustomerInfinite(tokenInfo!.tenant, searchValue, true);

  const observer = useRef<IntersectionObserver | null>(null);

  const lastOptionElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (observer.current) {
        observer.current.disconnect();
        observer.current = null;
      }

      if (node && hasNextPage && fetchNextPage) {
        observer.current = new IntersectionObserver(async (entries) => {
          if (entries[0].isIntersecting) {
            fetchNextPage();
          }
        });

        observer.current.observe(node);
      }
    },
    [fetchNextPage, hasNextPage]
  );

  const lastElement = allContactPerson[allContactPerson.length - 1];

  const handleInputChange: UseAutocompleteProps<IContactPeople, undefined, undefined, undefined>['onInputChange'] = (
    _,
    newInputValue,
    reason
  ) => {
    if (reason === 'input') {
      setInputValue(newInputValue);
      selectedValueString.current = null;
    }
    if (reason === 'reset') {
      if (newInputValue.length) {
        selectedValueString.current = newInputValue;
        setInputValue(newInputValue);
        return;
      }
      selectedValueString.current = null;
      setInputValue(newInputValue);
    }

    if (reason === 'clear' && newInputValue === '') {
      setInputValue(newInputValue);
      selectedValueString.current = null;
    }
  };

  return (
    <Stack spacing={1.25}>
      {!!label && (
        <InputLabel htmlFor="contactPeople">
          <FormattedMessage id={label} />
        </InputLabel>
      )}
      <Autocomplete
        sx={{
          '& .MuiInputBase-root': { padding: '0px !important' },
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': { padding: '8px 12px !important', minWidth: '100%' }
        }}
        autoComplete={false}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        disabled={!!isDisabled}
        multiple={!!isMultipleSelect}
        value={isMultipleSelect ? (value ?? []) : (value ?? null)}
        id="contactPeople"
        filterOptions={(options) => options}
        disableCloseOnSelect={!!isMultipleSelect}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={allContactPerson ?? []}
        onChange={(_event, selectedPersons: IContactPeople[] | IContactPeople | null) => handleChange(selectedPersons)}
        getOptionLabel={(option) => `${option?.name ?? ''} ${option?.surname ?? ''}`.trim()}
        renderInput={(params) => <TextField {...params} placeholder={intl.formatMessage({ id: placeholder })} />}
        ListboxProps={{ role: 'list-box' }}
        renderOption={(props, option, { selected }) => {
          const mainContact = `${option?.name ?? ''} ${option?.surname ?? ''}`.trim();
          const partnerContact = !!option?.details?.partner?.name
            ? `${option?.details?.partner?.name ?? ''} ${option?.details?.partner?.surname ?? ''}`.trim()
            : null;
          const primaryText = partnerContact ? `${mainContact}, ${partnerContact}` : mainContact;
          const secondaryText = option?.details?.companyName && option?.details?.companyName;

          return (
            <>
              {isMultipleSelect ? (
                <MenuItem
                  divider
                  sx={{ padding: '0px !important' }}
                  {...props}
                  ref={lastElement.id === option.id ? lastOptionElementRef : null}
                >
                  <Checkbox icon={<CheckboxUnchecked20Regular />} checkedIcon={<CheckboxChecked20Filled />} checked={selected} />
                  <ListItemText
                    sx={{ padding: option?.details?.companyName ? '8px 0' : '0px' }}
                    primary={primaryText}
                    secondary={secondaryText}
                  />
                </MenuItem>
              ) : (
                <MenuItem
                  divider
                  {...props}
                  sx={{ padding: '0px !important' }}
                  ref={lastElement.id === option.id ? lastOptionElementRef : null}
                >
                  <ListItemText sx={{ padding: '8px 12px', whiteSpace: 'pre-wrap' }} primary={primaryText} secondary={secondaryText} />
                </MenuItem>
              )}
            </>
          );
        }}
      />

      {!!isError && (
        <FormHelperText error={isError}>
          {!!errorText ? <>{errorText}</> : <FormattedMessage id="events.contact-person-is-required" />}
        </FormHelperText>
      )}
    </Stack>
  );
};

export default SelectContactPerson;
