import { FormattedMessage } from 'react-intl';
import { Box, Divider, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { getStorageFileUrl, typeImages } from 'shared/utils/getStorageFileUrl';
import * as BaseStyles from 'modules/invoices/components/Invoices.styles';
import countries from 'shared/data/countries';
import { useGetCurrentTenant } from 'api/hooks/tenant/useGetCurrentTenant';
import { EditorType, ValuesEditorType } from 'modules/editor/types/editor';
import { intlEditor } from 'modules/editor/utils/prepareData';
import * as Styles from './EditorDetails.styles';

const logoSrc = '/assets/images/builder/sidebar-logo.svg';

interface IData {
  intlId: string;
  value: string | null;
}
export interface IEditorDetails {
  templateLogo?: string;
  type: EditorType;
  data: IData[];
}

const EditorDetails = ({ templateLogo, type, data }: IEditorDetails) => {
  const { values } = useFormikContext<ValuesEditorType>();
  const { data: tenantData } = useGetCurrentTenant();
  const tenantLogo = tenantData?.loginViewSetting ? tenantData.loginViewSetting.loginLogoUrl : '';
  const logoUrl = templateLogo || tenantLogo;

  return (
    <BaseStyles.WrapperContent pt="40px">
      <Styles.Address>
        <Styles.ArticleContainer>
          <Styles.ArticleColumn>
            <Styles.ArticleTitle>
              <FormattedMessage id="invoices.invoice-editor-senders-address" />
            </Styles.ArticleTitle>
            {values.senderAddress?.corporateName && <Typography>{values.senderAddress.corporateName}</Typography>}
            {values.senderAddress?.street && <Typography>{values.senderAddress.street}</Typography>}
            <Typography>
              {values.senderAddress?.zipCode} {values.senderAddress?.city}
            </Typography>
            <Typography>{countries.find((item) => item.code === values?.senderAddress?.country)?.label}</Typography>
          </Styles.ArticleColumn>
          <Box>
            <Styles.LogoImg variant="rounded" src={(logoUrl && getStorageFileUrl(logoUrl, typeImages.Internal)) || logoSrc} alt="logo" />
          </Box>
        </Styles.ArticleContainer>
        <Styles.ArticleContainer>
          <Styles.ArticleColumn>
            <Styles.ArticleTitle>
              <FormattedMessage id={intlEditor.details.billedTo[type]} />
            </Styles.ArticleTitle>
            <Typography>{values?.recipient?.details?.companyName}</Typography>
            <Typography>{`${values?.recipient?.name ?? ''} ${values?.recipient?.surname ?? ''}`}</Typography>
            {values?.recipient?.details?.partner?.name && (
              <Typography>{`${values?.recipient?.details?.partner?.name} ${values?.recipient?.details?.partner?.surname}`}</Typography>
            )}
            {values.recipient?.details?.address?.street && <Typography>{values.recipient?.details?.address?.street}</Typography>}
            <Typography>
              {values.recipient?.details?.address?.postalCode ?? ''} {values.recipient?.details?.address?.city}
            </Typography>
            {!!values.recipient?.details?.address?.country && (
              <Typography>{countries.find((item) => item.code === values.recipient?.details?.address?.country)?.label ?? ''}</Typography>
            )}
          </Styles.ArticleColumn>
          <Styles.ArticleColumn>
            <Styles.ArticleContent>
              {data.map(({ intlId, value }, index) => (
                <Box key={index}>
                  <Styles.ArticleTitle>
                    <FormattedMessage id={intlId} />
                  </Styles.ArticleTitle>
                  <Typography>{value}</Typography>
                </Box>
              ))}
            </Styles.ArticleContent>
          </Styles.ArticleColumn>
        </Styles.ArticleContainer>
      </Styles.Address>
      <Divider className="p-bottom" />
    </BaseStyles.WrapperContent>
  );
};

export default EditorDetails;
