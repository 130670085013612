import { memo, ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import * as Styles from './Input.styles';

interface IReplacementTextInput {
  value?: string | null;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  isImageChoice?: boolean;
}
const ReplacementTextInput = memo(({ value, onChange, isImageChoice }: IReplacementTextInput) => {
  const intl = useIntl();

  return (
    <Styles.ReplacementInputField
      isImageChoice={isImageChoice}
      placeholder={intl.formatMessage({ id: 'organisation.replacement' })}
      onChange={onChange}
      value={value}
    />
  );
});

export default ReplacementTextInput;
