import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, CircularProgress, Grid, IconButton } from '@mui/material';
import { Dismiss20Regular } from '@fluentui/react-icons';
import { IInvoice, IInvoiceDialogForm } from 'shared/types/invoice';
import { IUser } from 'shared/types/user';
import InvoiceModalEventsAndType from 'modules/invoices/components/InvoiceModalEventsAndType';
import InvoiceModalTemplatesAndValue from 'modules/invoices/components/InvoiceModalTemplatesAndValue';
import InvoiceModalRecipientAndVenue from 'modules/invoices/components/InvoiceModalRecipientAndVenue';
import InvoiceModalUserActions from 'modules/invoices/components/InvoiceModalUserActions';
import InvoiceModalNumberAndState from 'modules/invoices/components/InvoiceModalNumberAndState';
import InvoiceModalDateFields from 'modules/invoices/components/InvoiceModalDateFields';
import CreateUserModal from 'modules/events/modals/CreateUserModal';
import { prepareContactPersonByUser, prepareInvoiceDataForForm } from 'modules/invoices/utils/prepareData';
import ModalDialog from 'shared/components/ModalContainer/ModalDialog';

interface IUserDialogState {
  isOpen: boolean;
  isNew: boolean | null;
}

interface IInvoiceModalProps {
  invoice?: IInvoice;
  isOpenModal: boolean;
  onSubmit: (data: IInvoiceDialogForm) => void;
  isSaving?: boolean;
  closeModal: () => void;
}

const InvoiceFormModal = ({ invoice, isOpenModal, onSubmit, closeModal, isSaving }: IInvoiceModalProps) => {
  const intl = useIntl();
  const [userDialogState, setUserDialogState] = useState<IUserDialogState>({ isNew: null, isOpen: false });

  const openCreateUserModel = (isNew: boolean): void => {
    setUserDialogState({ isNew, isOpen: true });
  };

  const closeCreateUserModel = (): void => {
    setUserDialogState({ isNew: null, isOpen: false });
  };

  const invoiceSchema = Yup.object().shape({
    type: Yup.string().required(intl.formatMessage({ id: 'invoices.modal-field-required' })),
    state: Yup.string().required(intl.formatMessage({ id: 'invoices.modal-field-required' })),
    valueType: Yup.string().required(intl.formatMessage({ id: 'invoices.modal-field-required' })),
    invoiceDate: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: 'invoices.modal-field-required' })),
    invoiceTemplate: Yup.object()
      .nullable()
      .required(intl.formatMessage({ id: 'invoices.modal-field-required' }))
      .shape({
        id: Yup.number().required(intl.formatMessage({ id: 'invoices.modal-field-required' }))
      }),
    recipient: Yup.object()
      .nullable()
      .required(intl.formatMessage({ id: 'invoices.modal-field-required' }))
      .shape({
        id: Yup.number().required(intl.formatMessage({ id: 'invoices.modal-field-required' }))
      }),
    venue: Yup.object()
      .nullable()
      .required(intl.formatMessage({ id: 'invoices.modal-field-required' }))
      .shape({
        id: Yup.number().required(intl.formatMessage({ id: 'invoices.modal-field-required' }))
      })
  });

  const formik = useFormik<IInvoiceDialogForm>({
    initialValues: prepareInvoiceDataForForm(invoice ?? null),
    validationSchema: invoiceSchema,
    onSubmit: async (values) => {
      await onSubmit(values);
      resetForm();
    }
  });

  const { handleSubmit, values, isValid, setValues, resetForm } = formik;

  const updateRecipientValue = (userResponse: IUser) => {
    setValues({
      ...values,
      recipient: userResponse,
      event: { ...values?.event, contactPeople: [...(values?.event?.contactPeople ?? []), prepareContactPersonByUser(userResponse)] }
    });
  };

  useEffect(() => {
    if (!invoice) return;
    setValues(prepareInvoiceDataForForm(invoice));
  }, [invoice, setValues]);

  const handleCloseModal = () => {
    resetForm();
    closeModal();
  };

  const header = (
    <>
      {invoice?.id ? <FormattedMessage id="invoices.modal-title-update" /> : <FormattedMessage id="invoices.modal-title-create" />}
      <IconButton disabled={isSaving} size="small" onClick={handleCloseModal}>
        {isSaving ? <CircularProgress size={20} /> : <Dismiss20Regular />}
      </IconButton>
    </>
  );

  const body = (
    <>
      {' '}
      <Grid container spacing={3}>
        <InvoiceModalNumberAndState />
        <InvoiceModalEventsAndType />
        <InvoiceModalTemplatesAndValue />
        <InvoiceModalRecipientAndVenue />
        <InvoiceModalUserActions openUserModal={openCreateUserModel} />
        <InvoiceModalDateFields />
      </Grid>
    </>
  );

  const actions = (
    <>
      {' '}
      <Button color="error" onClick={handleCloseModal}>
        <FormattedMessage id="users.cancel" />
      </Button>
      <Button
        startIcon={isSaving ? <CircularProgress size={'20px'} /> : ''}
        onClick={() => handleSubmit()}
        variant="contained"
        disabled={isSaving || !isValid}
      >
        {invoice?.id ? <FormattedMessage id="users.save" /> : <FormattedMessage id="events.create" />}
      </Button>
    </>
  );

  return (
    <>
      <ModalDialog
        pageTitle={invoice?.number}
        headerContent={header}
        bodyContent={body}
        actionBarContent={actions}
        formikValue={formik}
        isDialogOpen={isOpenModal && !userDialogState.isOpen}
        onSubmit={handleSubmit}
        onClose={handleCloseModal}
      />
      {userDialogState.isOpen && (
        <CreateUserModal
          open={userDialogState.isOpen}
          closeCreateUserModel={closeCreateUserModel}
          userId={userDialogState.isNew ? undefined : values?.recipient?.id}
          updateUserValue={updateRecipientValue}
        />
      )}
    </>
  );
};

export default InvoiceFormModal;
