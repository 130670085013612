import { IQuestionnaireImageItemData, IQuestionnaireImageItemDataSingleWidget } from 'modules/questionnaires/types/questionnaries';
import { BUILDER_WIDGET_TYPES, BaseWidget } from './index';

export enum IMAGE_FIT_TYPE {
  contain = 'contain',
  cover = 'cover'
}

export interface ImageWidgetType extends BaseWidget {
  type: BUILDER_WIDGET_TYPES.image;
  data: IQuestionnaireImageItemData[] | null;
  label: string;
  typeImage: IMAGE_FIT_TYPE;
}

export interface TopBannerWidgetType extends BaseWidget {
  type: BUILDER_WIDGET_TYPES.topBanner;
  data: IQuestionnaireImageItemData[] | null;
}

export interface MultipleImgChoiceWdigetType extends BaseWidget {
  type: BUILDER_WIDGET_TYPES.multipleImageChoice;
  dataImage: IQuestionnaireImageItemDataSingleWidget[] | null;
  label: string;
  selectedOptions: string[];
  typeImage: IMAGE_FIT_TYPE;
  minAmountOptions?: string | number | null;
  maxAmountOptions?: string | number | null;
}

export interface SingleImgChoiceWidgetType extends BaseWidget {
  type: BUILDER_WIDGET_TYPES.singleImageChoice;
  dataImage: IQuestionnaireImageItemDataSingleWidget[] | null;
  label: string;
  selectedOption: string | number;
  typeImage: IMAGE_FIT_TYPE;
}
