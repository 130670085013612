import { CaretDownFilled } from '@fluentui/react-icons';
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - Select ||============================== //

export default function Select(theme: Theme) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: CaretDownFilled
      },
      styleOverrides: {
        root: {
          '& .MuiSelect-icon': {
            fontSize: '1.6rem',
            marginRight: '0.1rem',
            padding: '0.2rem',
            '& svg': {
              color: theme.palette.secondary.main
            }
          }
        }
      }
    }
  };
}
