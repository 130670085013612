import { lazy } from 'react';
import Loadable from 'shared/components/Loadable';
import AuthGuard from 'shared/components/route-guard/AuthGuard';
import MainLayout from 'shared/layouts/MainLayout';

const ArticlePage = Loadable(lazy(() => import('pages/Articles/index')));
const CreateArticle = Loadable(lazy(() => import('pages/Articles/CreateArticle')));
const EditArticle = Loadable(lazy(() => import('pages/Articles/EditArticle')));

export const ArticlesRoutes = {
  path: '/',
  element: (
    <AuthGuard role={['venue_manager', 'tenant_admin', 'admin']}>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/articles',
      element: <ArticlePage />
    },
    {
      path: 'article',
      children: [
        {
          path: ':id',
          element: <EditArticle />
        },
        {
          path: 'create',
          element: <CreateArticle />
        }
      ]
    }
  ]
};
