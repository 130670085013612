import { Box, Toolbar } from '@mui/material';
import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'shared/components/Header';
import * as Styles from './BuilderLayout.styles';

const BuilderLayout = (): ReactElement => {
  return (
    <Styles.BoxWrapper>
      <Header />
      <Box component="main" sx={{ width: '100%', maxHeight: '100dvh', overflowY: 'hidden', flexGrow: 1, p: { xs: 1, sm: 1.5 } }}>
        <Toolbar variant="dense" />
        <Styles.NoContainerBoxWrapper>
          <Outlet />
        </Styles.NoContainerBoxWrapper>
      </Box>
    </Styles.BoxWrapper>
  );
};

export default BuilderLayout;
