import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { IInvoiceEditor } from 'shared/types/invoice';
import { useDispatch, useSelector } from 'store';
import { snackbarAlert } from 'shared/utils/infoText';
import { AlertMessagesColor } from 'shared/types/alert-messages';
import useEmailTemplateData from 'shared/hooks/useEmailTemplateData';
import { prepareDataToSendEmailInvoice } from 'modules/invoices/utils';
import { useCurrentDateFormat, useCurrentTimeFormat } from 'shared/hooks/regionalSettings';
import { TimeFormatEnum } from 'shared/configs/config';
import { selectPaginationInvoices } from 'store/pagination/pagination.selectors';
import { dateCreateInvoiceOfEvent } from 'modules/events/utils';
import { ITenantPayload } from 'shared/types/tenants';
import { useSendEmailInvoice } from 'api/hooks/invoices/useSendEmailInvoice';
import { IModalSendEmail } from 'modules/modalSendEmail/types';
import ModalSendEmail from 'modules/modalSendEmail/components/ModalSendEmail';

interface IDialogSendEmail {
  isOpenDialog: boolean;
  closeDialog: () => void;
  tenant?: ITenantPayload;
}

const InvoiceModalSendEmail = ({ isOpenDialog, closeDialog, tenant }: IDialogSendEmail) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const pagination = useSelector(selectPaginationInvoices);
  const [isErrorSendEmail, setIsErrorSendEmail] = useState<boolean>(false);
  const currentDateFormat = useCurrentDateFormat();
  const timeFormat = useCurrentTimeFormat();
  const isTwelveHoursFormat = timeFormat === TimeFormatEnum.Twelve;
  const { values: invoiceEditor } = useFormikContext<IInvoiceEditor>();

  const { subject, template } = useEmailTemplateData({ code: invoiceEditor?.type.toLowerCase() });

  const { mutateAsync: sendEmail, isLoading: isSendEmail } = useSendEmailInvoice({
    options: {
      onError() {
        setIsErrorSendEmail(true);
      }
    }
  });

  const invoiceEmail = useMemo(
    () => ({
      ...invoiceEditor,
      customUrl: tenant?.customUrl ? `${import.meta.env.VITE_REACT_APP_SITE_URL}/login/${tenant?.customUrl}` : '',
      performanceDate: invoiceEditor?.baseDates?.performanceDate
        ? dateCreateInvoiceOfEvent(
            currentDateFormat?.format,
            isTwelveHoursFormat,
            invoiceEditor?.baseDates?.performanceDate ? invoiceEditor?.baseDates?.performanceDate?.toString() : ''
          )
        : '',
      invoiceDate: invoiceEditor?.baseDates?.invoiceDate
        ? dateCreateInvoiceOfEvent(
            currentDateFormat?.format,
            isTwelveHoursFormat,
            invoiceEditor?.baseDates?.invoiceDate ? invoiceEditor?.baseDates?.invoiceDate?.toString() : ''
          )
        : ''
    }),
    [invoiceEditor, currentDateFormat?.format, isTwelveHoursFormat, tenant]
  );

  const onSuccessResult = () => {
    dispatch(
      snackbarAlert(
        intl.formatMessage({ id: 'invoices.invoice-editor-send-email-success-message-title' }),
        AlertMessagesColor.Success,
        intl.formatMessage({ id: 'invoices.invoice-editor-send-email-success-message-subtitle' })
      )
    );
    closeDialog();
  };

  const handleOnSubmit = async (values: IModalSendEmail) => {
    await sendEmail(
      { invoiceId: values.id!, subject: values.subject, body: values.message, articles: invoiceEditor?.articles, pagination },
      {
        onSuccess: () => {
          onSuccessResult();
        }
      }
    );
  };

  const handleCloseErrorModal = () => {
    setIsErrorSendEmail(false);
  };
  const nameAttachFile = `${intl.formatMessage({ id: 'invoices.types-invoice' })}_${invoiceEditor.number}`;
  return (
    <ModalSendEmail
      isOpenDialog={isOpenDialog}
      closeDialog={closeDialog}
      data={prepareDataToSendEmailInvoice({
        invoice: invoiceEmail,
        subject,
        template
      })}
      type={'invoices'}
      nameAttachFile={nameAttachFile}
      onSubmit={handleOnSubmit}
      isSendEmail={isSendEmail}
      handleCloseErrorModal={handleCloseErrorModal}
      isErrorSendEmail={isErrorSendEmail}
    />
  );
};

export default InvoiceModalSendEmail;
