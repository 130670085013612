import { SignOut20Regular } from '@fluentui/react-icons';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useAuth } from 'shared/contexts/AuthContext';

const LogOut = () => {
  const { logout } = useAuth();
  return (
    <MenuItem color="primary" sx={{ borderTop: `3px solid #00000020` }} onClick={logout}>
      <ListItemIcon>
        <SignOut20Regular />
      </ListItemIcon>
      <ListItemText primary={<FormattedMessage id="customer-view.logout" />} />
    </MenuItem>
  );
};

export default LogOut;
