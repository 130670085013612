import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Checkbox, FormControl, FormGroup, IconButton, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ChevronDown16Regular, ChevronUp16Regular } from '@fluentui/react-icons';
import { useDispatch, useSelector } from 'store';
import { selectHeaderOptions } from 'store/organisation-plan-template/organisation-plan-template.selectors';
import { setHeaderOptionByField } from 'store/organisation-plan-template/organisation-plan-template.slice';
import { IGuestAndRoomsCustomQuestion } from 'modules/builder/types';
import { INavigationCheckboxType } from 'modules/organisation-plan-templates/types/navigationCheckboxType';
import * as Styles from './OrganisationPlanTemplateOptionsSection.styles';

interface OrganisationPlanTemplateCheckboxSectionProps {
  checkBoxStatus: 'all' | 'few' | 'none';
}

const OrganisationPlanTemplateCheckboxSection = ({ checkBoxStatus }: OrganisationPlanTemplateCheckboxSectionProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useTheme();
  const headerOptions = useSelector(selectHeaderOptions);
  const [isNumberOfGuestsMenuOpen, setIsNumberOfGuestsMenuOpen] = useState(false);
  const checkboxesData: INavigationCheckboxType[] = [
    {
      label: intl.formatMessage({ id: 'organisation.show-event-name' }),
      value: 'isEventNameChosen',
      checked: headerOptions.showEventName,
      isExpandable: false,
      changeValueHandler: () => dispatch(setHeaderOptionByField({ field: 'showEventName', value: !headerOptions.showEventName }))
    },
    {
      label: intl.formatMessage({ id: 'organisation.show-event-date' }),
      value: 'isEventDateChosen',
      checked: headerOptions.showEventDate,
      isExpandable: false,
      changeValueHandler: () => dispatch(setHeaderOptionByField({ field: 'showEventDate', value: !headerOptions.showEventDate }))
    },
    {
      label: intl.formatMessage({ id: 'organisation.show-contact-person' }),
      value: 'isContactPersonChosen',
      checked: headerOptions.showContactPerson,
      isExpandable: false,
      changeValueHandler: () => dispatch(setHeaderOptionByField({ field: 'showContactPerson', value: !headerOptions.showContactPerson }))
    },
    {
      label: intl.formatMessage({ id: 'organisation.show-number-of-guests' }),
      value: 'isNumberOfGuestsChosen',
      checked: headerOptions.showNumberOfGuests,
      isExpandable: true,
      changeValueHandler: () => dispatch(setHeaderOptionByField({ field: 'showNumberOfGuests', value: !headerOptions.showNumberOfGuests }))
    },
    {
      label: intl.formatMessage({ id: 'organisation.show-event-manager' }),
      value: 'isEventManagerChosen',
      checked: headerOptions.showEventManager,
      isExpandable: false,
      changeValueHandler: () => dispatch(setHeaderOptionByField({ field: 'showEventManager', value: !headerOptions.showEventManager }))
    }
  ];

  const changeGuestsMenuChosenOptionsHandler = (id: string) => {
    dispatch(
      setHeaderOptionByField({
        field: 'guestDisplayOptions',
        value: headerOptions?.guestDisplayOptions?.map((option: IGuestAndRoomsCustomQuestion) =>
          option.id === id ? { ...option, isCheck: !option.isCheck } : option
        )
      })
    );
  };

  return (
    <Styles.BoxWrapper>
      <FormControl>
        <FormGroup row sx={{ justifyContent: 'center', alignItems: 'center' }}>
          {checkboxesData.map((checkbox) => {
            if (!checkbox.isExpandable) {
              return (
                <Styles.FormControlLabelNoExpandableWrapper
                  key={checkbox.value}
                  label={checkbox.label}
                  control={<Checkbox value={checkbox.value} checked={checkbox.checked} onChange={() => checkbox.changeValueHandler()} />}
                />
              );
            } else {
              return (
                <Styles.BoxExpandableSectionWrapper key={checkbox.value}>
                  <Box onClick={() => setIsNumberOfGuestsMenuOpen(!isNumberOfGuestsMenuOpen)}>
                    <Styles.FormControlLabelOuterWrapper
                      label={checkbox.label}
                      control={
                        <Checkbox
                          style={{
                            color: checkBoxStatus === 'none' ? '#8c8c8c' : theme.palette.primary.main
                          }}
                          value={checkbox.value}
                          checked={checkBoxStatus === 'all'}
                          indeterminate={checkBoxStatus === 'few'}
                          disabled
                          onChange={() => checkbox.changeValueHandler()}
                        />
                      }
                    />
                    {isNumberOfGuestsMenuOpen ? (
                      <IconButton size="small">
                        <ChevronUp16Regular />
                      </IconButton>
                    ) : (
                      <IconButton size="small">
                        <ChevronDown16Regular />
                      </IconButton>
                    )}
                  </Box>
                  <Styles.BoxExpandableMenuWrapper isNumberOfGuestsMenuOpen={isNumberOfGuestsMenuOpen}>
                    <FormControl>
                      <FormGroup>
                        {headerOptions?.guestDisplayOptions?.length ? (
                          headerOptions?.guestDisplayOptions?.map((option: IGuestAndRoomsCustomQuestion) => {
                            return (
                              <Tooltip
                                arrow
                                key={option.id}
                                title={option.label}
                                disableHoverListener={option.label.length < 20}
                                placement="right"
                              >
                                <Styles.FormControlLabelInnerWrapper
                                  label={option.label}
                                  control={
                                    <Checkbox
                                      value="guestMenuOption"
                                      checked={option.isCheck}
                                      onChange={() => changeGuestsMenuChosenOptionsHandler(option.id)}
                                    />
                                  }
                                />
                              </Tooltip>
                            );
                          })
                        ) : (
                          <Styles.NoDataOptionsContainer>
                            <Typography variant="h4">
                              <FormattedMessage id="organisation.no-options-have-been-provided" />
                            </Typography>
                          </Styles.NoDataOptionsContainer>
                        )}
                      </FormGroup>
                    </FormControl>
                  </Styles.BoxExpandableMenuWrapper>
                </Styles.BoxExpandableSectionWrapper>
              );
            }
          })}
        </FormGroup>
      </FormControl>
    </Styles.BoxWrapper>
  );
};

export default OrganisationPlanTemplateCheckboxSection;
