import { fetchQuestionnaireTemplatePages } from 'api/services/questionnaires-templates-api-service';
import { EQuestionnairesSource, QuestionnaireTemplatePageItem } from 'shared/types/questionnaire-template';
import { QuestionnairePageType } from 'modules/questionnaires/types/questionnaries';
import { BuilderPageItemType } from './types';

interface IquestPageType {
  welcomePage: string;
  welcomePageRequest: string;
  guestsAndRooms: string;
  title: string;
}

interface IBuilderGoToNextPage {
  allPages: BuilderPageItemType[];
  idActiveWelcomePage?: string;
  subPageId?: string;
}

const defaultPageNameByType = (formatStrings: IquestPageType): Record<QuestionnairePageType, string> => ({
  [QuestionnairePageType.WelcomePage]: formatStrings.welcomePage,
  [QuestionnairePageType.WelcomePageRequest]: formatStrings.welcomePageRequest,
  [QuestionnairePageType.GuestsAndRooms]: formatStrings.guestsAndRooms,
  [QuestionnairePageType.Customs]: formatStrings.title
});

export const mapQuestionnaireTemplatePageToBuilderPageItem = (
  item: QuestionnaireTemplatePageItem,
  formatStrings: IquestPageType
): BuilderPageItemType => ({
  ...item,
  id: item.id.toString(),
  name: item.name === '' ? defaultPageNameByType(formatStrings)[item.questionnairePageType] : item.name,
  type: item.questionnairePageType,
  childPages:
    item.child &&
    item.child.map((childItem) => ({
      ...childItem,
      id: childItem.id.toString(),
      name: childItem.name,
      type: QuestionnairePageType.Customs,
      childPages: null
    }))
});

/**
 * Additional method to check if page exist and will be use for concatenate with base array of pages
 * The result is used for concatenate with main array of pages
 * * */
const outputPage = (item?: QuestionnaireTemplatePageItem): QuestionnaireTemplatePageItem[] => {
  return !!item ? [item] : [];
};

/**
 * Method to get the pages in the correct order from the database.
 * Correct order: ['WelcomePageForRequest', 'WelcomePageForEvent', 'GuestRooms', ...]
 * @param data - Fetches pages data from questionnaire api and maps to builder pages entities
 */
const getPagesInCorrectOrderFromDB = (data: QuestionnaireTemplatePageItem[]) => {
  const welcomePageRequest = data.find((i) => i.questionnairePageType === QuestionnairePageType.WelcomePageRequest);
  const welcomePageEvent = data.find((i) => i.questionnairePageType === QuestionnairePageType.WelcomePage);
  const pagesWithoutWelcomePages = data.filter(
    (p) =>
      p.questionnairePageType !== QuestionnairePageType.WelcomePageRequest && p.questionnairePageType !== QuestionnairePageType.WelcomePage
  );
  return [...outputPage(welcomePageRequest), ...outputPage(welcomePageEvent), ...pagesWithoutWelcomePages];
};

/**
 * Method to fetch builder pages items
 * @description Fetches pages data from questionnaire api and maps to builder pages entities
 * @param id - questionnaire template id
 * @param isEvent - template or event
 * @param formatStrings - initial title of page
 * @param _tenantId - tenant id. Optional.
 * */

interface IfetchBuilderPagesParams {
  id: number;
  _tenantId?: number;
  entitySource: EQuestionnairesSource;
  formatStrings: IquestPageType;
}

export const fetchBuilderPages = async ({
  id,
  entitySource,
  formatStrings,
  _tenantId
}: IfetchBuilderPagesParams): Promise<BuilderPageItemType[]> => {
  let data = await fetchQuestionnaireTemplatePages(id, entitySource);

  let pages = [...data];
  switch (true) {
    case entitySource === EQuestionnairesSource.event:
      pages = data?.filter((page) => page.questionnairePageType !== QuestionnairePageType.WelcomePageRequest) ?? [];
      break;
    case entitySource === EQuestionnairesSource.request:
      pages = data?.filter((page) => page.questionnairePageType !== QuestionnairePageType.WelcomePage) ?? [];
      break;
    default:
      pages = getPagesInCorrectOrderFromDB(data);
      break;
  }
  const res = pages && pages.map((page) => mapQuestionnaireTemplatePageToBuilderPageItem(page, formatStrings));
  return res;
};

/**
 * The method of determining the types of BuilderBasePages and their order, depending on the event
 * @entitySource - can be one value from enum: event, template or request.
 */
export const getMainBuilderPages = (entitySource?: EQuestionnairesSource) => {
  if (entitySource === EQuestionnairesSource.event) {
    return [QuestionnairePageType.WelcomePage, QuestionnairePageType.GuestsAndRooms];
  }
  if (entitySource === EQuestionnairesSource.request) {
    return [QuestionnairePageType.WelcomePageRequest, QuestionnairePageType.GuestsAndRooms];
  }
  return [QuestionnairePageType.WelcomePageRequest, QuestionnairePageType.WelcomePage, QuestionnairePageType.GuestsAndRooms];
};

/**
 * util function to get id of the next page in builder/ preview
 * @param allPages -  array of all pages for questionnaire
 * @param idActiveWelcomePage - id of current WelcomePage
 */
export const builderGoToNextPage = ({ allPages, idActiveWelcomePage }: IBuilderGoToNextPage) => {
  const indexActivePage = allPages.findIndex((page) => page.id === idActiveWelcomePage);
  const nextIndex = indexActivePage + 1 <= allPages.length ? indexActivePage + 1 : 0;
  return allPages[nextIndex]?.id;
};
