import { useState, useCallback, useRef, useEffect } from 'react';
import * as React from 'react';
import { useAutocomplete } from '@mui/base/useAutocomplete';
import { Checkbox, Chip, MenuItem, TextField, List, ListItemText, Divider, Card, Box, Typography, Stack, Radio } from '@mui/material';
import { TagQuestionMarkRegular } from '@fluentui/react-icons';
import { SxProps } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  id: number;
  name?: string;
  surname?: string;
  fullName?: string;
}

interface IAutocompleteEvent<T> {
  options?: T[];
  loading: boolean;
  onChange: (data: T[] | null) => void;
  multiple: boolean;
  values?: T[] | null;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  placeholder?: string;
  disabled?: boolean;
  sx?: SxProps;
  open?: boolean;
  onInputChange: (event: React.SyntheticEvent, value: string, reason: string) => void;
}

const useStyles = makeStyles({
  paper: {
    marginTop: '15px'
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    marginTop: '8px'
  },
  chip: {
    margin: 2
  }
});

const AutocompleteTagsInfiniteSelect = <T extends Props>({
  options,
  loading,
  onChange,
  values,
  fetchNextPage,
  hasNextPage,
  placeholder,
  disabled = false,
  multiple,
  open,
  onInputChange,
  sx
}: IAutocompleteEvent<T>): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<T[]>(values || []);
  const [tagBoxHeight, setTagBoxHeight] = useState<number>(0);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastOptionElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (observer.current) {
        observer.current.disconnect();
        observer.current = null;
      }

      if (node && hasNextPage && fetchNextPage) {
        observer.current = new IntersectionObserver(async (entries) => {
          if (entries[0].isIntersecting) {
            fetchNextPage();
          }
        });

        observer.current.observe(node);
      }
    },
    [fetchNextPage, hasNextPage]
  );

  useEffect(() => {
    const boxHeight = document.getElementById('selectedTagsBox')?.clientHeight;
    if (boxHeight) {
      setTagBoxHeight(boxHeight);
    }
  }, [selectedValue]); // Add selectedValue to dependencies

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
    id: 'autocomplete-tags-infinite-select',
    options: options || [],
    multiple,
    value: selectedValue, // Synchronize with selectedValue
    getOptionLabel: (option) => (option as T)?.name ?? (option as T)?.fullName ?? '',
    inputValue,
    onInputChange: (event, newInputValue) => {
      setInputValue(newInputValue);
      onInputChange(event, newInputValue, 'input');
    },
    onChange: (_event, selectedObject: T | T[] | null) => {
      if (Array.isArray(selectedObject)) {
        setSelectedValue(selectedObject);
        onChange(selectedObject);
      } else if (selectedObject) {
        setSelectedValue([selectedObject]);
        onChange([selectedObject]);
      } else {
        setSelectedValue([]);
        onChange([]);
      }
    },
    open
  });

  const removeChip = (option: T) => {
    const updatedValue = selectedValue.filter((item) => item.id !== option.id);
    setSelectedValue(updatedValue);
    onChange(updatedValue);
  };

  const value = selectedValue;

  return (
    <div
      {...getRootProps()}
      style={{
        width: '100%',
        position: 'relative'
      }}
    >
      <Card
        sx={{
          position: 'relative',
          backgroundColor: 'transparent',
          borderRadius: '0px',
          boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px'
        }}
      >
        <TextField
          {...getInputProps()}
          placeholder={placeholder ?? intl.formatMessage({ id: 'dashboard.search-tags' })}
          color="primary"
          variant="outlined"
          fullWidth
          disabled={disabled}
          sx={sx}
          size="medium"
          autoFocus
        />
        <Divider />
        {groupedOptions.length > 0 ? (
          <List
            {...getListboxProps()}
            style={{
              overflow: 'auto',
              padding: '0',
              maxHeight: `calc(100vh - 430px - ${tagBoxHeight}px)`
            }}
          >
            {groupedOptions
              .filter((option) => !(option as any).options) // Removes grouped options
              .map((option, index) => {
                const lastElement = options ? options[options.length - 1] : null;
                const typedOption = option as T;

                const isSelected = multiple ? value.some((item) => item.id === typedOption.id) : value[0]?.id === typedOption.id;

                return (
                  <MenuItem
                    divider
                    sx={{ padding: '0 0 0 10px !important' }}
                    {...getOptionProps({ option: typedOption, index })}
                    key={typedOption.id}
                    ref={lastElement && lastElement.id === typedOption.id ? lastOptionElementRef : null}
                  >
                    {multiple ? (
                      <Checkbox sx={{ pointerEvents: 'none' }} checked={isSelected} />
                    ) : (
                      <Radio sx={{ pointerEvents: 'none' }} checked={isSelected} />
                    )}
                    <ListItemText primary={`${typedOption.name} ${typedOption.surname ?? ''} ${typedOption.fullName ?? ''}`.trim()} />
                  </MenuItem>
                );
              })}
          </List>
        ) : (
          <Stack p="50px" width="100%" justifyContent="center" alignItems="center" gap="20px">
            <TagQuestionMarkRegular fontSize="48px" style={{ opacity: '0.3' }} />
            <FormattedMessage id={'dashboard.no-tags-found'} />
          </Stack>
        )}
      </Card>
      <Box id="selectedTagsBox" sx={{ padding: '10px 15px' }}>
        {value.length === 0 ? (
          <Typography sx={{ opacity: '0.2' }}>
            <FormattedMessage id={'dashboard.no-tags-selected'} />
          </Typography>
        ) : (
          value.map((option) => (
            <Chip
              key={option.id}
              label={`${option.name} ${option.surname ?? ''} ${option.fullName ?? ''}`.trim()}
              onDelete={() => removeChip(option)}
              className={classes.chip}
            />
          ))
        )}
      </Box>
    </div>
  );
};

export default AutocompleteTagsInfiniteSelect;
