import HeaderDefault from './HeaderDefault';
import HeaderWithSidebar from './HeaderWithSidebar';

interface Props {
  withSidebar?: boolean;
  open?: boolean;
  handleDrawerToggle?: () => void;
}

const Header = ({ open = false, handleDrawerToggle, withSidebar }: Props) => {
  if (withSidebar) {
    return <HeaderWithSidebar open={open} handleDrawerToggle={handleDrawerToggle} />;
  }

  return <HeaderDefault />;
};

export default Header;
