import { ReactElement } from 'react';
import useGeneralSettings from 'shared/hooks/useGeneralSettings';
import { Roles } from 'modules/auth/types/index';

interface Props {
  roles: Array<Roles>;
  children: ReactElement;
}

/**
 * Roles guard wrapper for feature flags
 * @roles - Array of roles that can have access to this feature.
 * @children - React children components.
 * @returns {Element | null} Returns the element or null based on role.
 */

const FeatureRoleGuard = ({ roles, children }: Props) => {
  const { user: currentUserData } = useGeneralSettings();
  if (typeof currentUserData?.role === 'string' && roles.includes(currentUserData.role)) {
    return children;
  }
  return null;
};

export default FeatureRoleGuard;
