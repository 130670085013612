import { Slide, Theme, alpha } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// ==============================|| OVERRIDES - DIALOG ||============================== //

export default function Dialog(theme: Theme) {
  return {
    MuiDialog: {
      defaultProps: {
        TransitionComponent: Transition,
        onContextMenu: (e: any) => e.stopPropagation()
      },
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
            backdropFilter: 'blur(1px) brightness(60%) saturate(95%)',
            WebkitBackdropFilter: 'blur(1px) brightness(60%) saturate(95%)'
          },
          '& .MuiDialog-paper': {
            p: 0,
            backgroundColor: theme.palette.mode === 'dark' ? '#010101' : '#f0f0f0',
            borderRadius: '12px',
            boxShadow:
              theme.palette.mode === 'dark'
                ? 'rgba(0, 0, 0, 0.1) 0px 10px 36px 0px, rgba(255, 255, 255, 0.08) 0px 0px 0px 1px inset'
                : 'rgba(0, 0, 0, 0.2) 0px 10px 36px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px inset',
            '& .MuiDialogTitle-root': {
              backgroundColor: theme.palette.mode === 'dark' ? alpha('#000', 0.05) : alpha('#fff', 0.11),
              padding: '10px 10px 10px 25px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'sticky',
              top: 0,
              zIndex: 99
            },
            '& .MuiDialogContent-root': {
              backgroundColor: 'transparent'
            },
            '& .MuiDialogActions-root': {
              backgroundColor: theme.palette.mode === 'dark' ? alpha('#000', 0.05) : alpha('#fff', 0.11),
              padding: '10px',
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'center',
              position: 'sticky',
              bottom: 0,
              zIndex: 99
            }
          }
        }
      }
    }
  };
}
