import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, DialogActions, useTheme } from '@mui/material';
import * as Styles from 'modules/builder/components/widgets/TextWidget/ModalEditorTextStyle.styles';
import { BoxContent } from 'modules/builder/components/widgets/TextWidget/ModalEditorTextStyle.styles';
import TextEditor from 'modules/builder/components/widgets/TextWidget/TextEditor';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
interface ModalEditTextProps {
  value: string;
  onChange: ({ pureText, editedText }: { pureText: string; editedText: string }) => void;
  disableToolbarOnFocusLost?: boolean;
  isOpenModal: boolean;
  handleClose: () => void;
}

const OrgPlanModalEdit = ({ value, onChange, isOpenModal, handleClose }: ModalEditTextProps) => {
  const [valueState, setValueState] = useState<{ pureText: string; editedText: string }>({ pureText: '', editedText: '' });
  const theme = useTheme();

  return (
    <>
      <Styles.DialogWrapper
        PaperProps={{
          style: {
            maxWidth: '1010px',
            height: '80vh',
            maxHeight: '600px',
            borderRadius: '8px',
            border: `1px solid ${theme.palette.secondary.light}`
          }
        }}
        fullWidth
        open={isOpenModal}
        onClose={handleClose}
        aria-labelledby="edit-text-title"
        aria-describedby="alert-dialog-description"
        sx={{ '& .MuiDialogContent-root': { padding: 'none !important' } }}
      >
        <BoxContent>
          <TextEditor value={value} onChange={(newValue) => setValueState(newValue)} />
        </BoxContent>
        <DialogActions sx={{ backgroundColor: theme.palette.secondary.lighter, borderTop: `1px solid ${theme.palette.secondary.light}` }}>
          <Button variant="outlined" onClick={handleClose}>
            <FormattedMessage id="builder.close" />
          </Button>
          <Button color={'primary'} onClick={() => onChange(valueState)} variant="contained" size="medium">
            <FormattedMessage id="users.save" />
          </Button>
        </DialogActions>
      </Styles.DialogWrapper>
    </>
  );
};

export default OrgPlanModalEdit;
