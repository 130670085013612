import { Calendar20Regular } from '@fluentui/react-icons';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxWrapper = styled(Box)(() => ({
  width: 'calc(100% - 16px)',
  padding: '8px 16px 0px 8px'
}));

export const TypographyValueWrapper = styled(Typography)(() => ({
  color: '#8C8C8C',
  fontWeight: 400,
  fontSize: '14px'
}));

export const TypographyLabelWrapper = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '14px',
  color: theme.palette.text.primary
}));

export const CalendarOutlinedIcon = styled(Calendar20Regular)(() => ({
  color: '#FAAD14',
  fontSize: '16px',
  marginRight: '10px'
}));
