import { useQuery } from 'react-query';
import axios from 'axios';
import { IInvoiceSettings } from 'shared/types/tenants';

const INVOICE_SETTINGS_QUERY_KEY = 'invoice-settings-number';

interface IUseGetByIdProps {
  tennantId?: string;
  enabled?: boolean;
}

interface IfetchInvoiceSettingsNumberResponse {
  formattedNumber: IInvoiceSettings['pattern'];
}

const fetchInvoiceSettingsNumber = (tennantId: string): Promise<IfetchInvoiceSettingsNumberResponse> => {
  return axios.get(`/tenants/${tennantId}/invoice-settings/number`).then((res) => res.data);
};

export function useGetInvoiceNextNumber({ tennantId, enabled = true }: IUseGetByIdProps) {
  return useQuery([INVOICE_SETTINGS_QUERY_KEY, { tennantId }], () => fetchInvoiceSettingsNumber(tennantId ?? ''), {
    enabled: !!tennantId && enabled,
    refetchOnMount: true,
    keepPreviousData: false
  });
}
