import { useEffect, useRef, useState } from 'react';
import { Layout } from 'react-grid-layout';
import { useIntl } from 'react-intl';
import { IconButton, Tooltip } from '@mui/material';
import { Edit20Regular } from '@fluentui/react-icons';
import { useDispatch } from 'store';
import {
  updateOrganisationPlanLayoutPartially,
  updateOrganisationPlanWidget
} from 'store/organisation-plan-template/organisation-plan-template.slice';
import * as Styles from 'modules/builder/components/widgets/TextWidget/TextWidget.styles';
import PreviewOrganisationPlanTextWidget from 'modules/organisation-plan-preview/components/OrganisationPlanPreviewWidgetSection/preview-widgets/PreviewTextWidget';
import { OrganisationPlanTextWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanTextWidgetType';
import { getOrganisationPlanWidgetRowsAmount } from 'modules/organisation-plan-templates/utils/getOrganisationPlanWidgetRowsAmount';
import OrgPlanModalEditText from './OrgPlanModalEditText';

interface ITextWidgetProps {
  element: OrganisationPlanTextWidgetType;
  layoutItem: Layout;
}

const OrganisationPlanTextWidget = ({ element, layoutItem }: ITextWidgetProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { content, widgetId, type, sectionId } = element;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const updateContent = ({ pureText, editedText }: { pureText: string; editedText: string }) => {
    dispatch(updateOrganisationPlanWidget({ widgetId, widget: { type, content: editedText, layout: editedText, value: pureText } }));
    handleClose();
  };
  const refBlock = useRef<HTMLDivElement>(null);
  const refText2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof refBlock?.current?.offsetHeight !== 'number' || typeof refText2?.current?.offsetHeight !== 'number') return;
    const textHeight = getOrganisationPlanWidgetRowsAmount(refText2?.current?.offsetHeight);
    if (textHeight > 1) {
      dispatch(
        updateOrganisationPlanLayoutPartially({
          layout: {
            h: textHeight,
            i: widgetId
          },
          sectionId: sectionId || ''
        })
      );
    }
  }, [refBlock?.current?.offsetHeight, refText2?.current?.offsetHeight, content, layoutItem?.w, dispatch, widgetId, sectionId]);

  return (
    <Styles.BoxSection ref={refBlock}>
      <Styles.BoxContent>
        <Tooltip arrow title={intl.formatMessage({ id: 'organisation.edit-you-text' })}>
          <IconButton onClick={handleOpen} color="primary" aria-label="upload picture" component="label">
            <Edit20Regular />
          </IconButton>
        </Tooltip>
      </Styles.BoxContent>
      <section ref={refText2}>
        <OrgPlanModalEditText handleClose={handleClose} value={content} onChange={updateContent} isOpenModal={open} />
        <PreviewOrganisationPlanTextWidget element={element} />
      </section>
    </Styles.BoxSection>
  );
};

export default OrganisationPlanTextWidget;
