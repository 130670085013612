import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxWrapper = styled(Box)(() => ({
  marginBottom: '20px'
}));

interface IGeneralLabelTypographyWrapper {
  isMainLabelHidden?: boolean;
  isSelected?: boolean;
}
export const GeneralLabelTypographyWrapper = styled(Typography)<IGeneralLabelTypographyWrapper>(({ isMainLabelHidden }) => ({
  fontWeight: 700,
  fontSize: '14px',
  color: '#262626',
  marginBottom: '0px',
  display: isMainLabelHidden ? 'none' : 'block'
}));

export const GridWrapper = styled(Grid)(() => ({
  display: 'flex',
  flexWrap: 'nowrap'
}));

export const IconTypeGridWrapper = styled(Grid)(() => ({
  alignItems: 'center'
}));

export const InputValueTypographyWrapper = styled(Typography)<IGeneralLabelTypographyWrapper>(({ isSelected }) => ({
  fontWeight: 500,
  fontSize: '14px',
  color: '#262626',
  textDecoration: !isSelected ? 'line-through' : 'none !important',
  textDecorationStyle: 'double'
}));
