import { useIntl } from 'react-intl';
import InvoiceFormModal from 'modules/invoices/modals/InvoiceFormModal';
import { IInvoiceDialogForm } from 'shared/types/invoice';
import { useEditInvoice } from 'api/hooks/invoices/useEditInvoice';
import { textError } from 'shared/utils/infoText';
import { useSelector } from 'store';
import { selectPaginationInvoices } from 'store/pagination/pagination.selectors';
import { useInfoMessage } from 'shared/hooks/useInfoMessage';
import { prepareInvoiceDataForSave } from 'modules/invoices/utils/prepareData';
import { useGetInvoiceInfoById } from 'api/hooks/invoices/useGetInvoiceInfoById';

interface IInvoicesModalEditInvoice {
  isOpenModal: boolean;
  invoiceId?: number;
  closeModal: () => void;
  changedEvent?: () => void;
  initialEventId?: number;
}

const ModalEditInvoice = ({ isOpenModal, invoiceId, closeModal, initialEventId, changedEvent }: IInvoicesModalEditInvoice) => {
  const intl = useIntl();
  const pagination = useSelector(selectPaginationInvoices);
  const { successMessage, errorMessage } = useInfoMessage();

  const { data: invoiceEdit } = useGetInvoiceInfoById({
    id: invoiceId ? String(invoiceId) : undefined
  });

  const { mutateAsync: mutateEditInvoice, isLoading: isEventEditSaving } = useEditInvoice({
    id: invoiceId,
    pagination,
    options: {
      onError(error) {
        errorMessage(textError(error), closeModal);
      }
    }
  });

  const handleSaveEdit = async (data: IInvoiceDialogForm) => {
    if (!data?.id) return;
    await mutateEditInvoice(prepareInvoiceDataForSave(data), {
      onSuccess: () => {
        if (!!initialEventId && initialEventId !== data?.event?.id && changedEvent) {
          changedEvent();
        }
        successMessage(intl.formatMessage({ id: 'invoices.update-invoice-success' }), closeModal);
      }
    });
  };

  return (
    <InvoiceFormModal
      invoice={invoiceEdit}
      isOpenModal={isOpenModal}
      isSaving={isEventEditSaving}
      onSubmit={handleSaveEdit}
      closeModal={closeModal}
    />
  );
};

export default ModalEditInvoice;
