import { useMediaQuery } from '@mui/material';
import { ReactNode, createContext } from 'react';
import config from 'shared/configs/config';
import useLocalStorage from 'shared/hooks/useLocalStorage';
import { CustomizationProps, FontFamily, I18n, PresetColor, ThemeDirection, ThemeMode } from 'shared/types/config';

const initialState: CustomizationProps = {
  ...config,
  onChangeContainer: () => {},
  onChangeLocalization: (_lang: I18n) => {},
  onChangeMode: (_mode: ThemeMode) => {},
  onChangePresetColor: (_theme: PresetColor) => {},
  onChangeDirection: (_direction: ThemeDirection) => {},
  onChangeMiniDrawer: (_miniDrawer: boolean) => {},
  onChangeFontFamily: (_fontFamily: FontFamily) => {},
  prefersDarkMode: false, // Initialwert
  resolvedMode: 'light' // Initialwert
};

const ConfigContext = createContext(initialState);

type ConfigProviderProps = {
  children: ReactNode;
};

function ConfigProvider({ children }: ConfigProviderProps) {
  const [config, setConfig] = useLocalStorage('mantis-react-ts-config', initialState);

  const onChangeContainer = () => {
    setConfig({
      ...config,
      container: !config.container
    });
  };

  const onChangeLocalization = (lang: I18n) => {
    setConfig({
      ...config,
      i18n: lang
    });
  };

  const onChangeMode = (mode: ThemeMode) => {
    setConfig({
      ...config,
      mode
    });
  };

  const onChangePresetColor = (theme: PresetColor) => {
    setConfig({
      ...config,
      presetColor: theme
    });
  };

  const onChangeDirection = (direction: ThemeDirection) => {
    setConfig({
      ...config,
      themeDirection: direction
    });
  };

  const onChangeMiniDrawer = (miniDrawer: boolean) => {
    setConfig({
      ...config,
      miniDrawer
    });
  };

  const onChangeFontFamily = (fontFamily: FontFamily) => {
    setConfig({
      ...config,
      fontFamily
    });
  };

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: true });
  const resolvedMode: 'light' | 'dark' = config.mode === 'system' ? (prefersDarkMode ? 'dark' : 'light') : config.mode;

  return (
    <ConfigContext.Provider
      value={{
        ...config,
        prefersDarkMode,
        resolvedMode,
        onChangeContainer,
        onChangeLocalization,
        onChangeMode,
        onChangePresetColor,
        onChangeDirection,
        onChangeMiniDrawer,
        onChangeFontFamily
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export { ConfigContext, ConfigProvider };
