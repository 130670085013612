import { ForwardRefExoticComponent, RefAttributes, forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { LinkTarget, NavItemType } from 'shared/types/menu';
import { RootStateProps } from 'shared/types/root';
import { activeItem } from 'store/menu';

interface Props {
  item: NavItemType;
  level: number;
  action?: () => void;
}

const NavItem = ({ item, level }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen, openItem } = menu;

  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps: {
    component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
    href?: string;
    target?: LinkTarget;
  } = { component: forwardRef((props, _ref) => <Link {...props} to={item.url!} target={itemTarget} />) };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const Icon = item.icon!;
  const itemIcon = item.icon ? <Icon style={{ fontSize: '1.5rem' }} /> : false;

  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  const { pathname } = useLocation();

  // active menu item on page load
  useEffect(() => {
    if (pathname && pathname.includes('product-details')) {
      if (item.url && item.url.includes('product-details')) {
        dispatch(activeItem({ openItem: [item.id] }));
      }
    }

    if (pathname === item.url) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
  }, [pathname]);

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      selected={isSelected}
      sx={{
        height: '44px',
        zIndex: 1201,
        borderRadius: '6px',
        margin: '1.5px 8px',
        padding: '7px 10px',
        ...(drawerOpen && {
          '&.Mui-selected': {
            '&::before': {
              content: '""',
              position: 'absolute',
              width: 3,
              height: '40%',
              right: '0px',
              top: '30%',
              bgcolor: theme.palette.primary.main,
              borderRadius: '4px'
            }
          }
        })
      }}
    >
      {itemIcon && (
        <Tooltip arrow title={item.title && !drawerOpen ? <Typography>{item.title}</Typography> : ''} placement="right">
          <ListItemIcon>{itemIcon}</ListItemIcon>
        </Tooltip>
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && (
        <ListItemText
          primary={
            <Typography ml="10px" variant="h6">
              {item.title}
            </Typography>
          }
        />
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

export default NavItem;
