import { Box, Typography } from '@mui/material';
import { IColumnForUseTable, ITransformResponse, TableDashboardWidgetType } from 'modules/dashboard/types/TableDashboardWidgetType';
import TableCellStatus from 'modules/events/components/TableCellStatus';
import FormatPrice from 'modules/pricing-sidebar/components/FormatPrice';
import { FormattedMessage } from 'react-intl';
import { CellProps, Column } from 'react-table';
import { useCurrentDateFormatter } from 'shared/hooks/regionalSettings';
import * as Styles from './TableDashboardWidget.styles';

const uniqueColumnsArray: IColumnForUseTable[] = [
  { label: 'Event', intlId: 'invoices.table-column-event', columnName: 'eventName' },
  { label: 'Event Date', intlId: 'events.event-Date', columnName: 'eventDate' },
  { label: 'Due Date', intlId: 'dashboard-table.due-date', columnName: 'dueDate' },
  { label: 'Venue', intlId: 'settings.venue', columnName: 'venue' },
  { label: 'Number of Guests', intlId: 'dashboard-table.number-of-guests', columnName: 'numberOfGuests' },
  { label: 'Questionnaire Revenue', intlId: 'dashboard-table.questionnaire-revenue', columnName: 'questionnaireRevenue' },
  { label: 'Event State', intlId: 'dashboard-table.event-state', columnName: 'eventState' },
  { label: 'Invoice Amount', intlId: 'dashboard-table.invoice-amount', columnName: 'invoiceAmount' },
  { label: 'Questionnaire', intlId: 'events.questionnaire', columnName: 'questionnaire' },
  { label: 'Event-Manager', intlId: 'tenants.event-manager', columnName: 'eventManager' },
  { label: 'Event type', intlId: 'events.event-type', columnName: 'eventType' },
  { label: 'Customer', intlId: 'tenants.customer', columnName: 'customer' },
  { label: 'Day of creation', intlId: 'dashboard-table.day-of-creation', columnName: 'dayOfCreation' },
  { label: 'Pre-payment', intlId: 'dashboard-table.pre-payment', columnName: 'invoicePrePaymentAmount' },
  { label: 'Number of Events', intlId: 'dashboard-table.number-of-events', columnName: 'numberOfEvents' },
  { label: 'Invoice ID-Number', intlId: 'dashboard-table.invoice-id-number', columnName: 'invoiceId' },
  { label: 'Invoice State', intlId: 'dashboard-table.invoice-state', columnName: 'invoiceState' },
  { label: 'Month', intlId: 'dashboard-table.month', columnName: 'month' },
  { label: 'Year', intlId: 'dashboard-table.year', columnName: 'year' },
  { label: 'Invoice', intlId: 'events.invoice', columnName: 'invoice' }
];

interface ITransformResponseReturn {
  columns: Column<IColumnForUseTable>[] | [];
  data: IColumnForUseTable[] | [];
}

export const transformResponse = (response?: ITransformResponse): ITransformResponseReturn => {
  if (!response) {
    return { columns: [], data: [] };
  }

  const {
    newRows: rows,
    tableData: { titles, footers },
    tagsTranslations,
    widget
  } = response;

  const widgetWithType = widget as TableDashboardWidgetType;
  const titlesArray: IColumnForUseTable[] = titles.map((title: { name: string }, index: number) => {
    const column: IColumnForUseTable | undefined = uniqueColumnsArray.find((column) => column.columnName === title.name);
    const columnConfig = widgetWithType?.data?.columns[index];

    if (!!column) {
      return { ...column, isCurrency: columnConfig?.isCurrency };
    }
    const translatedTagType = tagsTranslations.find((tag: { type: string; intlId: string }) => tag.type === columnConfig?.tagType)?.intlId;

    return {
      label: `${columnConfig?.name} ${translatedTagType}`,
      columnName: title.name,
      isCurrency: columnConfig?.isCurrency
    };
  });

  const columns: Column<IColumnForUseTable>[] | [] = titlesArray.map((title) => ({
    Header: (
      <Box>
        <Typography sx={Styles.HeaderSecondaryText}>
          <FormattedMessage id="dashboard-table.value-of" />
        </Typography>
        <Typography sx={Styles.HeaderMainText}>{title.intlId ? <FormattedMessage id={title.intlId} /> : title.label}</Typography>
      </Box>
    ),
    id: title.columnName,
    className: `cell-center ${title.columnName}`,
    accessor: () => {
      return title.columnName;
    },
    intlId: title.intlId ?? null,
    Cell: ({ cell }: CellProps<any, any>) => {
      const value = cell.row.original?.[cell.column.id] ?? '';
      if (title.columnName === 'eventState') {
        return <TableCellStatus type="Event" status={value} fullLength />;
      }
      if (title.columnName === 'eventDate' || title.columnName === 'dueDate' || title.columnName === 'dayOfCreation') {
        const formattedDate = useCurrentDateFormatter(value.toString());
        return <Typography>{formattedDate}</Typography>;
      }
      return <Typography>{title.isCurrency ? <FormatPrice value={value} /> : value}</Typography>;
    },
    Footer: () => {
      const value = footers?.length ? footers?.find((obj: any) => obj.name === title.columnName)?.value || 0 : 0;
      if (value === null || value === undefined || value === 0) {
        const placeholder = '-';
        return <Styles.FooterText>{placeholder}</Styles.FooterText>;
      } else {
        return <Styles.FooterText>{title.isCurrency ? <FormatPrice value={value} /> : value}</Styles.FooterText>;
      }
    }
  }));
  return { columns, data: rows };
};
