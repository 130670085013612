import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxWrapper = styled(Box)(() => ({
  display: 'flex',
  width: '100%'
}));

export const BoxMainComponent = styled(Box)(() => ({
  width: 'calc(100% - 260px)',
  flexGrow: 1
}));

export const TableLayoutWrapper = styled(Box)(() => ({
  position: 'relative',
  minHeight: 'calc(100vh - 110px)',
  display: 'flex',
  flexDirection: 'column'
}));

export const BoxOutletWrapper = styled(Box)(() => ({
  position: 'relative',
  height: 'calc(100vh - 50px)',
  width: '100%',
  overflow: 'auto'
}));
