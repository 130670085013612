import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { updateOrganisationPlanLayoutPartially } from 'store/organisation-plan-template/organisation-plan-template.slice';
import { getWidgetRowsAmount } from 'modules/builder/utils/builder-widgets';
import * as Styles from 'modules/organisation-plan-templates/components/widgets/InputWithReplacementWidget.styles';

type IOrganisationPlanImageChoiceWrapperProps = {
  widgetId: string;
  children: JSX.Element;
  sectionId: string;
};
const OrganisationPlanImageChoiceWrapper = ({ widgetId, children, sectionId }: IOrganisationPlanImageChoiceWrapperProps) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current?.offsetHeight) {
      const newHeightForWidget = getWidgetRowsAmount(ref.current.offsetHeight);
      dispatch(
        updateOrganisationPlanLayoutPartially({
          layout: { h: newHeightForWidget, i: widgetId, minH: newHeightForWidget, maxH: newHeightForWidget },
          sectionId
        })
      );
    }
  }, [dispatch, widgetId, sectionId]);

  return <Styles.BoxWrapper ref={ref}>{children}</Styles.BoxWrapper>;
};

export default OrganisationPlanImageChoiceWrapper;
