import { useQuery } from 'react-query';
import axios from 'axios';

enum TimeRangeTypes {
  Past = 'Past',
  Future = 'Future',
  Today = 'Today',
  LastWeek = 'LastWeek',
  ThisWeek = 'ThisWeek',
  NextWeek = 'NextWeek',
  Next7Days = 'Next7Days',
  Next30Days = 'Next30Days',
  Last7Days = 'Last7Days',
  Last30Days = 'Last30Days',
  LastMonth = 'LastMonth',
  ThisMonth = 'ThisMonth',
  NextMonth = 'NextMonth',
  Last3Months = 'Last3Months',
  Next3Months = 'Next3Months',
  Last6Months = 'Last6Months',
  Next6Months = 'Next6Months',
  LastYear = 'LastYear',
  ThisYear = 'ThisYear',
  NextYear = 'NextYear'
}

const fetchTimeRangeFilter = (): Promise<TimeRangeTypes[]> => {
  return axios.get('/dashboards/filters/time-ranges').then((res) => res.data);
};

export function useGetTimeRangeFilter() {
  return useQuery(['time-range'], fetchTimeRangeFilter);
}
