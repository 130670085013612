import { useIntl } from 'react-intl';
import { ListItemIcon, ListItemText } from '@mui/material';
import * as Styles from 'shared/components/ContextMenu/ContextMenu.styles';
import { replaceAllSpace } from 'shared/utils/editText';
import { IListOptionContextMenu } from 'shared/components/ContextMenu/index';

interface IOptionOfMenu {
  option: IListOptionContextMenu;
  setIsCloseMenu: (data: boolean) => void;
}
const OptionOfMenu = ({ option, setIsCloseMenu }: IOptionOfMenu) => {
  const intl = useIntl();

  const handleOptionClick = (optionOnClick: () => void, isCloseMenu?: boolean) => {
    optionOnClick();
    if (typeof isCloseMenu === 'undefined' || isCloseMenu) {
      return setIsCloseMenu(true);
    }
  };
  return (
    <Styles.BoxMenuItem
      divider
      disabled={option.disabledMenu ?? false}
      isBorderTop={option.isBorderTop}
      isBorderBottom={option.isBorderBottom}
      key={replaceAllSpace(option.messageId)}
      onClick={() => option.onClick && handleOptionClick(option.onClick, option.isCloseMenu)}
    >
      {!!option.icon && <ListItemIcon className={option?.className}>{option.icon}</ListItemIcon>}
      <ListItemText className={option?.className} primary={intl.formatMessage({ id: option.messageId })} />
    </Styles.BoxMenuItem>
  );
};

export default OptionOfMenu;
