import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { IPaginatedResult } from 'shared/types/paginatedResult';
import { API_OFFERS_ENDPOINTS, EOffersQueryKeys, IOfferArticleBaseAndOptionalType, IOfferBD, IOfferItemsPayload } from 'shared/types/offer';
import { IEditorArticle } from 'modules/editor/types/editor';

type MutationContext =
  | {
      previousInvoices: IPaginatedResult<IOfferBD[]> | undefined;
      newInvoice: IOfferBD;
    }
  | undefined;

const updateOfferItems = (offerInfo: IOfferItemsPayload): Promise<IOfferArticleBaseAndOptionalType[]> => {
  return axios.put(API_OFFERS_ENDPOINTS.PUT_ITEMS(offerInfo.id), offerInfo).then((res) => res.data);
};

interface IuseUpdateOfferItems<TError> {
  options?: Omit<
    UseMutationOptions<IEditorArticle[], TError, IOfferItemsPayload, MutationContext>,
    'mutationFn' | 'onSuccess' | 'variables'
  >;
  onSuccess?: ((data: IEditorArticle[], variables: IOfferItemsPayload, context: MutationContext) => void | Promise<unknown>) | undefined;
}

export function useUpdateOfferItems<TError>({ options = {}, onSuccess }: IuseUpdateOfferItems<TError>) {
  const queryClient = useQueryClient();

  return useMutation(updateOfferItems, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([EOffersQueryKeys.GetOfferEditor, { offerId: variables.id }]);
      queryClient.invalidateQueries([EOffersQueryKeys.GetOfferItemsSelected, { offerId: variables.id }]);
      if (onSuccess) {
        onSuccess(data, variables, context);
      }
    }
  });
}
