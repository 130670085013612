import { FormattedMessage, useIntl } from 'react-intl';
import { FormHelperText, Grid, InputLabel, Stack, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { IOfferDialogForm } from 'shared/types/offer';
import { useCurrentDateFormat } from 'shared/hooks/regionalSettings';
import { Calendar24Regular } from '@fluentui/react-icons';

const OfferModalRequestAndValidDate = () => {
  const intl = useIntl();
  const { values, errors, touched, setFieldValue } = useFormikContext<IOfferDialogForm>();
  const dateFormat = useCurrentDateFormat();

  const handleChangeValidUntilDate = (value: Date | null) => {
    if (!value) return;
    setFieldValue('validUntil', value);
  };

  return (
    <>
      {!!values.id && !!values.request?.name && (
        <Grid item xs={12} sm={6}>
          <Stack spacing={1.25}>
            <InputLabel htmlFor="offer.request">
              <FormattedMessage id="offer.modal-field-request" />
            </InputLabel>
            <TextField
              InputProps={{
                readOnly: true
              }}
              id="offer.request"
              fullWidth
              value={values.request?.name}
              name="number"
              placeholder={intl.formatMessage({ id: 'settings.enter' })}
            />
          </Stack>
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25}>
          <InputLabel>
            <FormattedMessage id="offer.modal-field-offer-valid-until" />
          </InputLabel>
          <DatePicker
            value={values.validUntil ? new Date(values.validUntil.toString()) : null}
            format={dateFormat?.format}
            onChange={handleChangeValidUntilDate}
            slots={{ openPickerIcon: Calendar24Regular }}
          />
          {Boolean(touched['validUntil'] && errors['validUntil']) && (
            <FormHelperText error={Boolean(touched['validUntil'] && errors['validUntil'])}>{errors['validUntil']}</FormHelperText>
          )}
        </Stack>
      </Grid>
    </>
  );
};

export default OfferModalRequestAndValidDate;
