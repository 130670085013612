import { useQuery } from 'react-query';
import axios from 'axios';
import { InvoiceStatus } from 'shared/types/invoice';

const fetchInvoiceStatesFilter = (): Promise<InvoiceStatus[]> => {
  return axios.get('/dashboards/filters/invoices/states').then((res) => res.data);
};

export function useGetInvoiceStates() {
  return useQuery(['invoices-states'], fetchInvoiceStatesFilter, {
    select: (data) =>
      data.map((item) => {
        return { value: item, label: item };
      })
  });
}
