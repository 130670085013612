import { Divider } from '@mui/material';
import { useFormikContext } from 'formik';
import { ValuesEditorType } from 'modules/editor/types/editor';
import { calculateFinalAmount, calculateNetTotalForGross, calculateTotal, listTaxValues } from 'modules/editor/utils/editResultCalculation';
import * as BaseStyles from 'modules/invoices/components/Invoices.styles';
import FormatPrice from 'modules/pricing-sidebar/components/FormatPrice';
import { QuestionnaireTaxType } from 'modules/questionnaires/types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import NumericInput from 'shared/components/NumberInputField';
import { useCurrentCurrencyFormat } from 'shared/hooks/regionalSettings';
import { ICalculateParams } from 'shared/types/invoice';
import { BLOCKED_SYMBOL_MINUS } from 'shared/сonstants';
import * as Styles from './EditorResultCalculation.styles';
import { finalAmount } from './EditorResultCalculation.styles';

const EditorResultCalculation = () => {
  const intl = useIntl();
  const currencySign = useCurrentCurrencyFormat();

  const { values, setFieldValue } = useFormikContext<ValuesEditorType>();

  const totalAmount = useMemo(() => {
    return calculateTotal(values.articles, values?.taxType);
  }, [values.articles, values.taxType]);

  const taxValues = useMemo(() => {
    return listTaxValues(values.articles, values?.taxType, intl);
  }, [values.articles, values?.taxType, intl]);

  const additionalTotalNet =
    values.taxType === QuestionnaireTaxType.Gross
      ? [
          {
            title: `${intl.formatMessage({ id: 'invoices.invoice-editor-result-total-net' })} (${currencySign})`,
            value: calculateNetTotalForGross(totalAmount, taxValues, values.discount ?? 0),
            name: 'totalAmountNet'
          }
        ]
      : [];

  const params: ICalculateParams[] = [
    {
      title: `${intl.formatMessage({ id: 'invoices.invoice-editor-result-total' })} (${currencySign})`,
      value: totalAmount,
      name: 'totalAmount'
    },
    ...taxValues,
    {
      title: intl.formatMessage({ id: 'invoices.invoice-editor-result-discount' }),
      value: values?.discount,
      name: 'discount',
      isInput: true,
      blockedSymbols: BLOCKED_SYMBOL_MINUS
    },
    ...additionalTotalNet
  ];

  return (
    <BaseStyles.WrapperContent>
      <Styles.Container>
        {params.map((param) => (
          <Styles.Section key={param.name}>
            <Styles.Title>{param.title}</Styles.Title>
            <Styles.Value>
              {!param.isInput && <FormatPrice value={param.value ?? 0} isHideSymbol />}
              {param.isInput && (
                <NumericInput
                  isDecimal
                  maxDecimalDigits={2}
                  textAlign="right"
                  name={param.name}
                  value={param.value ?? 0}
                  onHandleInputChange={(value) => setFieldValue(param.name, value)}
                  startAdornment="%"
                />
              )}
            </Styles.Value>
          </Styles.Section>
        ))}
      </Styles.Container>
      <Divider />
      <Styles.Container>
        <Styles.Section>
          <Styles.Title>{intl.formatMessage({ id: 'invoices.invoice-editor-final-amount' }) + ` (${currencySign})`}</Styles.Title>
          <Styles.Value>
            <FormatPrice value={calculateFinalAmount(values, taxValues) ?? 0} styles={finalAmount} isHideSymbol />
          </Styles.Value>
        </Styles.Section>
      </Styles.Container>
    </BaseStyles.WrapperContent>
  );
};

export default EditorResultCalculation;
