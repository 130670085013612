import { EQuestionnairesSource } from 'shared/types/questionnaire-template';

export const BASE_URL = 'questionnaires';
const BASE_URL_TEMPLATE = `${BASE_URL}/template`;
export const BASE_URL_TEMPLATES = `${BASE_URL}/templates`;

const API_PATH = {
  [EQuestionnairesSource.event]: BASE_URL,
  [EQuestionnairesSource.template]: BASE_URL_TEMPLATE,
  [EQuestionnairesSource.request]: `${BASE_URL}`
};

const getApiEndpointForEntity = (entityName: EQuestionnairesSource) => {
  const url = API_PATH[entityName];
  const isTemplates = EQuestionnairesSource.template === entityName;

  return {
    GET_BY_ID: (id: string) => `${isTemplates ? BASE_URL_TEMPLATES : url}/${id}`,
    GET_QUESTIONNAIRE_PAGES: `${url}/pages/list`,
    POST_QUESTIONNAIRE_PAGES: `${url}/pages`,
    GET_QUESTIONNAIRE_PAGE_BY_ID: (id: number) => `${url}/pages/${id}`,
    PUT_QUESTIONNAIRE_PAGE_BY_ID: (id: number) => `${url}/pages/${id}`,
    DELETE_QUESTIONNAIRE_PAGE_BY_ID: (id: number) => `${url}/pages/${id}`,
    GET_LINKS_BY_QUESTIONNAIRE_ID: (questionnaireId: number) =>
      `${isTemplates ? BASE_URL_TEMPLATES : url}/${questionnaireId}/linking-rules/`,
    PUT_LINKS_BY_QUESTIONNAIRE_ID: (questionnaireId: number) =>
      `${isTemplates ? BASE_URL_TEMPLATES : url}/${questionnaireId}/linking-rules/`,
    GET_CATEGORIES_BY_ID: (id: string) => `${isTemplates ? BASE_URL_TEMPLATES : url}/${id}/guest-categories`,
    PUT_UPDATE_PAGES_ORDER: (id: string) => `${isTemplates ? BASE_URL_TEMPLATES : url}/${id}/pages-order`
  };
};

export const API_QUESTIONNAIRES_ENDPOINTS = {
  [EQuestionnairesSource.event]: getApiEndpointForEntity(EQuestionnairesSource.event),
  [EQuestionnairesSource.template]: getApiEndpointForEntity(EQuestionnairesSource.template),
  [EQuestionnairesSource.request]: getApiEndpointForEntity(EQuestionnairesSource.request)
};
