import { useQuery } from 'react-query';
import axios from 'axios';
import { DashboardQueryKey } from 'shared/types/dasboard';
import { IInitialColumnsArray } from 'modules/dashboard/components/widgets/TableDashboardWidget/utils/transformDefaultColumnsData';

const fetchTableWidgetColumns = (): Promise<IInitialColumnsArray[]> => {
  return axios.get(`dashboard/table/columns`).then((res) => res.data);
};

export function useGetDashboardTableWidgetColumns() {
  const queryKey = [DashboardQueryKey.DashboardTableColumns];
  return useQuery(queryKey, fetchTableWidgetColumns);
}
