import { UseMutationOptions, UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import {
  IOrganisationPlanTemplate,
  IOrganisationPlanTemplatePayload,
  OrganisationPlanTemplateBuilderItem,
  OrganisationPlanTemplatePostPayload
} from 'shared/types/organisation-plan';
import { IPaginatedResult } from 'shared/types/paginatedResult';
import { IPagination } from 'shared/types/table';

interface IUpdateQuestionnaireTemplateIdResponse {
  id: number;
  questionnaireTemplateId: number;
  name: string;
}

export interface IUpdateQuestionnaireTemplateId {
  pageId: number;
  questionnaireTemplateId: number;
  data: IOrganisationPlanTemplatePayload;
}

type MutationContext =
  | {
      previousOrganisationPlans: IPaginatedResult<IOrganisationPlanTemplate> | undefined;
      newOrganisationPlan: OrganisationPlanTemplatePostPayload;
    }
  | undefined;

const getOrganizationPlanTemplateByID = (
  pageId: number,
  questionnaireTemplateId: number
): Promise<IUpdateQuestionnaireTemplateIdResponse> => {
  return axios.get(`questionnaires/templates/${questionnaireTemplateId}/plans/${pageId}`).then((res) => res.data);
};

const createNewOrganisationPlanTemplate = (
  organisationPlanTemplateInfo: OrganisationPlanTemplatePostPayload
): Promise<IOrganisationPlanTemplate> => {
  return axios
    .post(`questionnaires/templates/${organisationPlanTemplateInfo.questionnaireTemplateId}/plans`, organisationPlanTemplateInfo)
    .then((res) => res.data);
};

const updateOrganisationPlanTemplate = ({
  pageId,
  questionnaireTemplateId,
  data
}: IUpdateQuestionnaireTemplateId): Promise<IUpdateQuestionnaireTemplateIdResponse> => {
  return axios.put(`questionnaires/templates/${questionnaireTemplateId}/plans/${pageId}`, data).then((res) => res.data);
};

export const useGetOrganizationPlanTemplateById = (
  id: number,
  questionnaireTemplateId: number
): UseQueryResult<OrganisationPlanTemplateBuilderItem> => {
  return useQuery([`questionnaires/templates/${questionnaireTemplateId}/plans/${id}`], () =>
    getOrganizationPlanTemplateByID(id, questionnaireTemplateId)
  );
};

export function useCreateOrganisationPlanTemplate<TError>({
  options = {},
  onSuccess,
  pagination
}: {
  options?: Omit<
    UseMutationOptions<IOrganisationPlanTemplate, TError, OrganisationPlanTemplatePostPayload, MutationContext>,
    'mutationFn' | 'onSuccess' | 'variables'
  >;
  onSuccess?: () => void;
  pagination: IPagination;
}) {
  const queryClient = useQueryClient();

  return useMutation(createNewOrganisationPlanTemplate, {
    ...options,
    // Update cache after success request
    onSuccess: (data) => {
      const previousOrganisationPlanTemplates: IPaginatedResult<IOrganisationPlanTemplate> | undefined = queryClient.getQueryData([
        'organisation-plan-templates',
        pagination
      ]);
      if (!previousOrganisationPlanTemplates) {
        onSuccess && onSuccess();
        return;
      }
      const prev = previousOrganisationPlanTemplates?.data ? previousOrganisationPlanTemplates.data : [];
      queryClient.setQueryData(['organisation-plan-templates', pagination], {
        ...previousOrganisationPlanTemplates,
        totalRecords: previousOrganisationPlanTemplates.totalRecords + 1,
        data: [...prev, data]
      });

      if (onSuccess) {
        onSuccess();
      }
    },
    // Always refetch after error:
    onSettled: (_, error) => {
      if (error) {
        // do something
        queryClient.invalidateQueries(['organisation-plan-templates', pagination]);
      }
    }
  });
}

export function useUpdateOrganisationPlanTemplate<TError>({
  options = {},
  onSuccess
}: {
  options?: Omit<
    UseMutationOptions<OrganisationPlanTemplateBuilderItem, TError, any, MutationContext>,
    'mutationFn' | 'onSuccess' | 'variables'
  >;
  onSuccess?: () => void;
}) {
  return useMutation(updateOrganisationPlanTemplate, {
    ...options,
    // Update cache after success request
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
    // Always refetch after error:
    onSettled: () => {}
  });
}
