import { useContext } from 'react';
import { ConfigContext } from 'shared/contexts/ConfigContext';

// ==============================|| CONFIG - HOOKS  ||============================== //

const useConfig = () => {
  const context = useContext(ConfigContext);
  return context;
};

export default useConfig;
