import {
  ArrowLeft20Regular,
  ChevronDown20Regular,
  ChevronRight20Regular,
  Circle12Regular,
  ClipboardSearchRegular,
  Dismiss20Regular,
  LinkSquare20Regular,
  Search20Regular
} from '@fluentui/react-icons';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { debounce } from 'lodash';
import { ChangeEvent, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import CollapsibleSection from './CollapsibleSection';
import { ILinkingPageNav, ILinkObjectForSelect, UpdateLinkHandlerType, UpdateSelectedFnType } from './types';

interface ISelectSourceDialogProps {
  open: boolean;
  onClose: () => void;
  options: ILinkingPageNav[];
  linkObj: ILinkObjectForSelect;
  updateLinkHandler: UpdateLinkHandlerType<ILinkObjectForSelect>;
}

const SelectSourceDialog = ({ open, onClose, options, linkObj, updateLinkHandler }: ISelectSourceDialogProps): ReactElement => {
  const [isSearchMode, setIsSearchMode] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<ILinkingPageNav[]>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const intl = useIntl();

  const performSearch = useCallback(
    debounce((query: string) => {
      if (!query.trim()) {
        setSearchResults([]);
        return;
      }

      const lowerCaseQuery = query.toLowerCase().trim();
      const results = options
        .filter((option) => {
          const matchInWidgets = option.widgets?.some((widget) => widget.label.toLowerCase().includes(lowerCaseQuery));
          const matchInSubPages = option.subPages?.some((subPage) => {
            const matchInSubPageWidgets = subPage.widgets?.some((widget) => widget.label.toLowerCase().includes(lowerCaseQuery));
            return subPage.label.toLowerCase().includes(lowerCaseQuery) || matchInSubPageWidgets;
          });
          return option.label.toLowerCase().includes(lowerCaseQuery) || matchInWidgets || matchInSubPages;
        })
        .map((option) => {
          const filteredSubPages = option.subPages
            ?.filter((subPage) => {
              const matchInSubPageWidgets = subPage.widgets?.some((widget) => widget.label.toLowerCase().includes(lowerCaseQuery));
              return subPage.label.toLowerCase().includes(lowerCaseQuery) || matchInSubPageWidgets;
            })
            .map((subPage) => {
              const filteredWidgets = subPage.widgets?.filter((widget) => widget.label.toLowerCase().includes(lowerCaseQuery));
              return { ...subPage, widgets: filteredWidgets };
            });

          const filteredWidgets = option.widgets?.filter((widget) => widget.label.toLowerCase().includes(lowerCaseQuery));
          return { ...option, subPages: filteredSubPages, widgets: filteredWidgets };
        });

      setSearchResults(results);
    }, 300),
    [options]
  );

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    performSearch(query);
  };

  const enterSearchMode = () => {
    setIsSearchMode(true);
  };

  const exitSearchMode = () => {
    setIsSearchMode(false);
    setSearchQuery('');
    setSearchResults([]);
  };

  useEffect(() => {
    if (isSearchMode && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isSearchMode]);

  const selectSourceOption: UpdateSelectedFnType = (option, linkId) => {
    updateLinkHandler({ linkId, link: { source: option } });
    onClose();
  };

  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle minWidth={600} sx={{ paddingLeft: isSearchMode ? '10px !important' : '15px !important' }}>
        {!isSearchMode ? (
          <Stack direction="row" gap="10px" justifyContent="space-between" alignItems="center" width="100%">
            <Stack direction="row" gap="10px" justifyContent="start" alignItems="center">
              <LinkSquare20Regular />
              <FormattedMessage id="builder.please-select-source-option" />
            </Stack>
            <Stack direction="row" gap="10px">
              <Button
                startIcon={<Search20Regular />}
                size="small"
                onClick={enterSearchMode}
                aria-label="search"
                variant="outlined"
                color="secondary"
              >
                <FormattedMessage id="builder.search" />
              </Button>
              <IconButton size="small" onClick={onClose} aria-label="close dialog">
                <Dismiss20Regular />
              </IconButton>
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row" gap="10px" alignItems="center" justifyContent="space-between" width="100%">
            <IconButton size="small" onClick={exitSearchMode} aria-label="back to main view">
              <ArrowLeft20Regular />
            </IconButton>
            <TextField
              inputRef={searchInputRef}
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={intl.formatMessage({ id: 'builder.search-term-placeholder' })}
              variant="outlined"
              fullWidth
            />
            <IconButton size="small" onClick={onClose} aria-label="close dialog">
              <Dismiss20Regular />
            </IconButton>
          </Stack>
        )}
      </DialogTitle>
      <DialogContent dividers sx={{ padding: '0' }}>
        {!isSearchMode ? (
          options.map((option) => (
            <CollapsibleSection
              key={option.id}
              page={option}
              updateSelectedId={selectSourceOption}
              selectedOption={linkObj?.source}
              linkId={linkObj.linkId}
              setIsSelectOpen={onClose}
            />
          ))
        ) : (
          <SimpleTreeView
            aria-label="search-results"
            slots={{
              expandIcon: ChevronRight20Regular,
              collapseIcon: ChevronDown20Regular,
              endIcon: Circle12Regular
            }}
            sx={{ overflowX: 'hidden', flexGrow: 1 }}
          >
            {searchResults.length > 0 ? (
              searchResults.map((option) => (
                <CollapsibleSection
                  key={option.id}
                  page={option}
                  updateSelectedId={selectSourceOption}
                  selectedOption={linkObj?.source}
                  linkId={linkObj.linkId}
                  setIsSelectOpen={onClose}
                />
              ))
            ) : (
              <Stack width="100%" justifyContent="center" alignItems="center">
                <ClipboardSearchRegular fontSize="5rem" style={{ opacity: 0.2, marginTop: '50px' }} />
                <Typography sx={{ mb: '70px', opacity: 0.2 }} variant="h5">
                  <FormattedMessage id="builder.no-results-found" />
                </Typography>
              </Stack>
            )}
          </SimpleTreeView>
        )}
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onClose} variant="outlined">
          <FormattedMessage id="builder.cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectSourceDialog;
