import { RootState } from 'store/index';
import { usedWidgetsEntityAdapter } from 'store/dashboard/dashboard.slice';

export const selectIsDashboardCustomize = (state: RootState) => state.dashboard.isDashboardCustomize;
export const { selectById: selectDashboardsWidgetsById, selectAll: selectAllDashboardsWidgets } =
  usedWidgetsEntityAdapter.getSelectors<RootState>((state) => state.dashboard.usedWidgets);
export const selectDashboardName = (state: RootState) => state.dashboard.dashboardName;
export const selectActiveDashboardId = (state: RootState) => state.dashboard.activeDashboardId;
export const selectDashboard = (state: RootState) => state.dashboard.activeDashboard;
export const selectDashboardLayout = (state: RootState) => state.dashboard.layout;
export const selectIsDirtyDashboardState = (state: RootState) => state.dashboard.isDataChanged;
