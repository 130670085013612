import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { IPaginatedResult } from 'shared/types/paginatedResult';
import {
  API_INVOICES_ENDPOINTS,
  EInvoicesQueryKeys,
  IEmailInvoice,
  IInvoice,
  IInvoiceFormData,
  IInvoiceListItem,
  InvoiceStatus
} from 'shared/types/invoice';

type MutationContext =
  | {
      previousInvoices: IPaginatedResult<IInvoiceListItem> | undefined;
      newInvoice: IInvoice;
    }
  | undefined;

const sendEmailInvoice = (invoiceInfo: IEmailInvoice): Promise<IEmailInvoice> => {
  return axios
    .post(`${API_INVOICES_ENDPOINTS.SEND_EMAIL(invoiceInfo.invoiceId)}`, {
      body: invoiceInfo.body,
      subject: invoiceInfo.subject
    })
    .then((res) => res.data);
};

interface IuseUpdateInvoiceItems<TError> {
  options?: Omit<UseMutationOptions<IEmailInvoice, TError, IEmailInvoice, MutationContext>, 'mutationFn' | 'onSuccess' | 'variables'>;
  onSuccess?: ((data: IEmailInvoice, variables: IEmailInvoice, context: MutationContext) => void | Promise<unknown>) | undefined;
}

export function useSendEmailInvoice<TError>({ options = {}, onSuccess }: IuseUpdateInvoiceItems<TError>) {
  const queryClient = useQueryClient();
  return useMutation(sendEmailInvoice, {
    ...options,
    onSuccess: (data, variables, context) => {
      const keyInvoiceList = [EInvoicesQueryKeys.GetInvoices, variables.pagination];
      const keyInvoiceEditor = [EInvoicesQueryKeys.GetInvoiceEditor, { invoiceId: variables?.invoiceId }];
      const keyInvoiceItems = [EInvoicesQueryKeys.GetInvoiceItems, { invoiceId: variables?.invoiceId }];

      const previousInvoiceEditor: IInvoiceFormData | undefined = queryClient.getQueryData(keyInvoiceEditor);
      queryClient.setQueryData(keyInvoiceEditor, {
        ...previousInvoiceEditor,
        isSentEmail: true,
        state: InvoiceStatus?.SentPerEmail
      });

      queryClient.setQueryData(keyInvoiceItems, variables?.articles);

      const previousInvoices: IPaginatedResult<IInvoiceListItem> | undefined = queryClient.getQueryData(keyInvoiceList);
      const prev = previousInvoices?.data ? previousInvoices.data : [];
      const newData = prev.map((invoice) => {
        if (invoice.id !== variables?.invoiceId) return invoice;
        return { ...invoice, ...data };
      });
      queryClient.setQueryData(keyInvoiceList, { ...previousInvoices, data: newData });

      if (onSuccess) {
        onSuccess(data, variables, context);
      }
    }
  });
}
