import { Theme } from '@mui/material';

// ==============================|| OVERRIDES - Menu ||============================== //

export default function Menu(theme: Theme) {
  return {
    MuiMenu: {
      styleOverrides: {
        root: {
          padding: 0,
          '& .MuiPaper-root': {
            backgroundColor: theme.palette.background.paper,
            boxShadow:
              theme.palette.mode === 'dark'
                ? 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(255, 255, 255, 0.08) 0px 0px 0px 1px inset'
                : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px inset'
          },
          '& .MuiMenu-list': {
            padding: 0,
            '& .MuiMenuItem-root': {
              padding: '8px 12px',
              color: theme.palette.text.primary,
              '& svg': {
                color: theme.palette.text.primary
              },
              '& a:-webkit-any-link': {
                textDecoration: 'none',
                color: theme.palette.text.primary
              }
            },
            '& .option-list__text-delete': {
              color: '#ff4d4f !important',
              svg: {
                color: '#ff4d4f !important'
              }
            }
          },
          '& a:-webkit-any-link': {
            textDecoration: 'none',
            color: theme.palette.text.primary
          }
        }
      }
    }
  };
}
