export enum EVisibilityFields {
  event = 'event',
  request = 'request'
}

export type VisibilityObjectType = Record<EVisibilityFields, boolean>;

type VisibilityObjectParam = {
  name: EVisibilityFields;
  value: boolean;
};

export type VisibilityChangeFnType = (obj: VisibilityObjectParam) => void;
