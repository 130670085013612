import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useGetQuestionnairesPagesByIdForOrgPlan } from 'api/hooks/organisation-plan-preview/useGetQuestionnairesPagesById';
import { useGetQuestionnairesPagesById } from 'api/hooks/questionnaires/useGetQuestionnairesPagesById';
import { EQuestionnairesSource } from 'shared/types/questionnaire-template';
import { IOrganisationPlanPreview } from 'shared/types/organisation-plan-preview';
import {
  orgPlanApplyLinkingRules,
  setIsEvent,
  setIsPreviewOrgPlan
} from 'store/organisation-plan-template/organisation-plan-template.slice';
import { useDispatch, useSelector } from 'store';
import {
  selectIsEvent,
  selectOrganizationPlanAllSections,
  selectOrganizationPlanAllWidgets
} from 'store/organisation-plan-template/organisation-plan-template.selectors';
import { IOrganisationPlanSection } from 'modules/organisation-plan-templates/types/organisationPlanSection';
import { filteredTemplateSectionsIdByAnswers } from 'modules/organisation-plan-preview/components/OrganisationPlanPreviewTemplatePlaygroundSection/utils';
import OrganisationPlanPreviewTemplateSection from 'modules/organisation-plan-preview/components/OrganisationPlanPreviewTemplateSection';
import { sortSectionByOrder } from 'modules/organisation-plan-preview/utils/getWidgetsOrgPlanPreviewLayout';
import * as Styles from './OrganisationPlanPreviewTemplateSectionsContainer.styles';

interface IOrganisationPlanPreviewTemplateSectionsContainerProps {
  orgPlanData?: IOrganisationPlanPreview | undefined;
}

const OrganisationPlanPreviewTemplateSectionsContainer = ({ orgPlanData }: IOrganisationPlanPreviewTemplateSectionsContainerProps) => {
  const sections = useSelector(selectOrganizationPlanAllSections);
  const allWidgets = useSelector(selectOrganizationPlanAllWidgets);
  const isEvent = useSelector(selectIsEvent);

  const dispatch = useDispatch();
  const { token } = useParams();

  const { data: allPages } = useGetQuestionnairesPagesById({
    id: orgPlanData?.builder.questionnaireId ? orgPlanData?.builder.questionnaireId : undefined,
    entitySource: EQuestionnairesSource.event,
    querySkip: !!token
  });
  const { data: allPagesForEventOrgPlan } = useGetQuestionnairesPagesByIdForOrgPlan(
    orgPlanData?.builder.questionnaireId ? orgPlanData?.builder.questionnaireId : undefined,
    orgPlanData?.urlAccessToken
  );

  useEffect(() => {
    dispatch(setIsEvent(!!orgPlanData));
  }, [orgPlanData, dispatch]);

  useEffect(() => {
    dispatch(setIsPreviewOrgPlan(true));
    return () => {
      dispatch(setIsPreviewOrgPlan(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (allPages && !allPagesForEventOrgPlan) {
      dispatch(orgPlanApplyLinkingRules({ allQuestionnairesPages: allPages }));
    }
    if (!allPages && allPagesForEventOrgPlan) {
      dispatch(orgPlanApplyLinkingRules({ allQuestionnairesPages: allPagesForEventOrgPlan }));
    }
  }, [allPages, allPagesForEventOrgPlan, dispatch]);

  const filteredSectionsByIsHiddenField = sections.filter((section) => !section.isHidden);
  const filteredSectionsByAnswers: IOrganisationPlanSection[] = filteredTemplateSectionsIdByAnswers(
    allWidgets,
    filteredSectionsByIsHiddenField
  );
  const sectionPreview = isEvent ? filteredSectionsByAnswers : filteredSectionsByIsHiddenField;
  const sectionsSortByOrder = sortSectionByOrder(sectionPreview);

  if (!sectionPreview?.length) {
    return <Box></Box>;
  }

  return (
    <Box>
      {!!sectionPreview?.length &&
        sectionsSortByOrder?.map((section: IOrganisationPlanSection, index: number) => (
          <Styles.PreviewTemplateSectionWrapper key={section.sectionId} className="section-break-print">
            <OrganisationPlanPreviewTemplateSection section={section} sectionIndex={index} />
          </Styles.PreviewTemplateSectionWrapper>
        ))}
    </Box>
  );
};

export default OrganisationPlanPreviewTemplateSectionsContainer;
