import { MoreVertical24Regular } from '@fluentui/react-icons';
import { IconButton, MenuItem, Typography } from '@mui/material';
import { IOrgPlanLinkObjectForSelect } from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateLinkingTab/types';
import { IOrganisationPlanSection } from 'modules/organisation-plan-templates/types/organisationPlanSection';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'store';
import {
  organizationPlanTemplateBuilderActiveLinkingSettings,
  selectAllOrgPlanLinks,
  selectOrganizationPlanSectionById
} from 'store/organisation-plan-template/organisation-plan-template.selectors';
import {
  deleteOrganisationPlanSection,
  removeOrgPlanLinks,
  updateActiveLinkingSettings
} from 'store/organisation-plan-template/organisation-plan-template.slice';
import * as Styles from './SectionContextMenu.styles';

interface ISectionContextMenuInterface {
  section: IOrganisationPlanSection;
}

const SectionContextMenu = ({ section }: ISectionContextMenuInterface) => {
  const dispatch = useDispatch();
  const activeLinkingSettings = useSelector(organizationPlanTemplateBuilderActiveLinkingSettings);
  const sectionData = useSelector((state) => selectOrganizationPlanSectionById(state, section.sectionId));
  const allLinks: IOrgPlanLinkObjectForSelect[] = useSelector(selectAllOrgPlanLinks);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const handleClickContextMenu = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
    if (!event) return;
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseContextMenu = () => {
    setAnchorEl(null);
  };

  const handleClickLinkSettings = () => {
    dispatch(updateActiveLinkingSettings({ sectionId: section.sectionId, widgetId: null }));
    handleCloseContextMenu();
  };

  const deleteSection = () => {
    dispatch(deleteOrganisationPlanSection({ sectionId: section.sectionId }));
    const sectionLinks = allLinks.filter((link) => link?.target?.sectionId === section.sectionId);
    const widgetIdsForCurrentSection = section.layout.map(({ i }) => i);
    const widgetLinks = widgetIdsForCurrentSection.map((widgetId) => allLinks.filter((link) => link?.target?.widgetId === widgetId)).flat();
    const links = sectionLinks.concat(widgetLinks);
    if (links.length) {
      dispatch(removeOrgPlanLinks(links.map(({ linkId }) => linkId)));
    }
    if (activeLinkingSettings?.sectionId) {
      if (section.sectionId === activeLinkingSettings.sectionId) {
        dispatch(updateActiveLinkingSettings(null));
      }
    }
    if (activeLinkingSettings?.widgetId) {
      const isWidgetInSection = sectionData?.layout?.find((widget) => widget.i === activeLinkingSettings?.widgetId);
      if (isWidgetInSection) {
        dispatch(updateActiveLinkingSettings(null));
      }
    }
  };
  return (
    <>
      <IconButton sx={{ marginLeft: 'auto' }} onClick={handleClickContextMenu} size="large" color="secondary">
        <MoreVertical24Regular />
      </IconButton>
      <Styles.Menu
        id="context-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseContextMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={deleteSection}>
          <Typography>
            <FormattedMessage id="users.delete" />
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleClickLinkSettings}>
          <Typography>
            <FormattedMessage id="organisation.settings-link" />
          </Typography>
        </MenuItem>
      </Styles.Menu>
    </>
  );
};

export default SectionContextMenu;
