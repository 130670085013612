import { ReactNode, useMemo } from 'react';
import * as React from 'react';
import { Layout } from 'react-grid-layout';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { OrganisationPlanInfoFromQuestionnaireWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanInfoFromQuestionnaireWidgetType';
import { useDispatch, useSelector } from 'store';
import {
  organizationPlanTemplateBuilderActiveLinkingSettings,
  selectAllOrgPlanLinks,
  selectOrganizationPlanWidgetById
} from 'store/organisation-plan-template/organisation-plan-template.selectors';
import {
  deleteOrganisationPlanWidget,
  removeOrgPlanLinks,
  setEditWidgetSettings,
  updateActiveLinkingSettings,
  updateShowOnlySelectedAnswerWidget
} from 'store/organisation-plan-template/organisation-plan-template.slice';
import { ORGANISATION_PLAN_WIDGET_TYPES } from 'modules/organisation-plan-templates/types';
import {
  MultipleChoiceOrganisationPlanWidgetType,
  SingleChoiceOrganisationPlanWidgetType
} from 'modules/organisation-plan-templates/types/organisationPlanChoiceWidgetType';
import { OrganisationPlanDividerWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanDividerWidgetType';
import {
  MultipleImageChoiceWithReplacementWidgetType,
  SingleImageChoiceWithReplacementWidgetType
} from 'modules/organisation-plan-templates/types/organisationPlanImageWidgetTypes';
import { OrganisationPlanInputNumberWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanInputNumberWithReplacementType';
import { OrganisationPlanInputTextWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanInputTextWithReplacementType';
import { OrganisationPlanNotesWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanNotesWidgetType';
import { OrganisationPlanTableWithReplacementWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanTableWithReplacementWidgetType';
import { OrganisationPlanTextWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanTextWidgetType';
import { OrganisationPlanTimePickerWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanTimePickerWithReplacementType';
import { OrganisationPlanUploadAreaWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanUploadAreaWithReplacementType';
import { IOrgPlanLinkObjectForSelect } from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateLinkingTab/types';
import OrganisationPlanMultipleChoiceWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanChoiceWithReplacementWidget/OrganisationPlanMultipleChoiceWidget';
import OrganisationPlanSingleChoiceWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanChoiceWithReplacementWidget/OrganisationPlanSingleChoiceWidget';
import OrganisationPlanDividerWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanDividerWidget';
import OrganisationPlanMultipleImageChoiceWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanImageChoiceWithReplacementWidget/OrganisationPlanMultipleImageChoiceWidget';
import OrganisationPlanSingleImageChoiceWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanImageChoiceWithReplacementWidget/OrganisationPlanSingleImageChoiceWidget';
import OrganisationPlanInfoFromAnswer from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanInfoFromAnswer';
import OrganisationPlanInputNumberWithReplacementWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanInputNumberWithReplacementWidget';
import OrganisationPlanTextWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanInputTextWidget/OrganisationPlanTextWidget';
import OrganisationPlanInputTextWithReplacementWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanInputTextWithReplacementWidget';
import OrganisationPlanNotesWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanNotesWidget';
import OrganisationPlanTableWithReplacementWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanTableWithReplacementWidget';
import OrganisationPlanTimePickerWithReplacementWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanTimePickerWithReplacementWidget';
import OrganisationPlanUploadAreaWithReplacementWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanUploadAreaWithReplacementWidget';
import { useGetQuestionnairesPagesById } from 'api/hooks/questionnaires/useGetQuestionnairesPagesById';
import { EQuestionnairesSource } from 'shared/types/questionnaire-template';
import * as Styles from './OrganisationPlanTemplateWidgetWrapper.styles';
import DragHandle from 'modules/builder/components/WidgetWrapper/DragHandle';
import ContextMenu, { IListOptionContextMenu } from 'shared/components/ContextMenu';

interface IOrganisationPlanWidgetWrapperProps {
  layoutItem: Layout;
}

const OrganisationPlanTemplateWidgetWrapper: React.FC<IOrganisationPlanWidgetWrapperProps> = ({ layoutItem }) => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const { questionnaireTemplateId } = useParams();

  const activeLinkingSettings = useSelector(organizationPlanTemplateBuilderActiveLinkingSettings);
  const element = useSelector((state) => selectOrganizationPlanWidgetById(state, layoutItem.i));
  const links: IOrgPlanLinkObjectForSelect[] = useSelector(selectAllOrgPlanLinks);

  const { data: builderPages = [] } = useGetQuestionnairesPagesById({
    id: questionnaireTemplateId ? Number(questionnaireTemplateId) : undefined,
    entitySource: EQuestionnairesSource.template
  });

  const editWidgetElement = () => {
    if (!element) return;
    let chosenPage = '';
    builderPages.forEach((builderPage) => {
      if (builderPage.hasOwnProperty('usedWidgets')) {
        const foundWidgetById = builderPage.usedWidgets?.find((widget) => widget.widgetId === element.linkedWidgetId);
        if (foundWidgetById) {
          chosenPage = `${builderPage.name}with_page_id${builderPage.id}`;
        } else {
          if (builderPage.hasOwnProperty('childPages')) {
            builderPage.childPages?.forEach((builderSubPage) => {
              if (builderSubPage.hasOwnProperty('usedWidgets')) {
                const foundWidgetById = builderSubPage.usedWidgets?.find((widget) => widget.widgetId === element.linkedWidgetId);
                if (foundWidgetById) {
                  chosenPage = `${builderSubPage.name}with_page_id${builderSubPage.id}`;
                }
              }
            });
          }
        }
      }
    });

    dispatch(
      setEditWidgetSettings({
        pageOfChosenWidget: chosenPage,
        chosenWidget: element
      })
    );
  };

  const deleteWidgetElement = () => {
    if (!element) return;
    dispatch(deleteOrganisationPlanWidget({ widgetId: element.widgetId, sectionId: element.sectionId }));
    const linksForThisWidget = links.filter((link) => link?.target?.widgetId === element.widgetId);
    if (linksForThisWidget.length) {
      dispatch(removeOrgPlanLinks(links.map(({ linkId }) => linkId)));
    }
    if (element.widgetId === activeLinkingSettings?.widgetId) {
      dispatch(updateActiveLinkingSettings(null));
    }
  };

  const openWidgetLinkSettingsSection = () => {
    if (!element) return;
    dispatch(updateActiveLinkingSettings({ sectionId: null, widgetId: element.widgetId }));
  };

  const showOnlySelectedAnswer = () => {
    if (!element) return;
    const showOnlySelectedAnswer = 'showOnlySelectedAnswer' in element ? !element.showOnlySelectedAnswer : true;
    dispatch(updateShowOnlySelectedAnswerWidget({ widgetId: element.widgetId, widget: element, showOnlySelectedAnswer }));
  };

  if (!element) {
    return null;
  }

  const widgetComponentByType: Record<ORGANISATION_PLAN_WIDGET_TYPES, ReactNode> = {
    [ORGANISATION_PLAN_WIDGET_TYPES.inputOrganisationPlanText]: (
      <OrganisationPlanTextWidget layoutItem={layoutItem} element={element as OrganisationPlanTextWidgetType} />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.notes]: (
      <OrganisationPlanNotesWidget layoutItem={layoutItem} element={element as OrganisationPlanNotesWidgetType} />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.infoFromQuestionnaire]: (
      <OrganisationPlanInfoFromAnswer element={element as OrganisationPlanInfoFromQuestionnaireWidgetType} />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.divider]: (
      <OrganisationPlanDividerWidget isPreview={false} element={element as OrganisationPlanDividerWidgetType} />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.inputText]: (
      <OrganisationPlanInputTextWithReplacementWidget
        layoutItem={layoutItem}
        element={element as OrganisationPlanInputTextWithReplacementType}
      />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.inputInteger]: (
      <OrganisationPlanInputNumberWithReplacementWidget
        layoutItem={layoutItem}
        element={element as OrganisationPlanInputNumberWithReplacementType}
      />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.time]: (
      <OrganisationPlanTimePickerWithReplacementWidget
        layoutItem={layoutItem}
        element={element as OrganisationPlanTimePickerWithReplacementType}
      />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.singleChoice]: (
      <OrganisationPlanSingleChoiceWidget layoutItem={layoutItem} element={element as SingleChoiceOrganisationPlanWidgetType} />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.multipleChoice]: (
      <OrganisationPlanMultipleChoiceWidget layoutItem={layoutItem} element={element as MultipleChoiceOrganisationPlanWidgetType} />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.singleImageChoice]: (
      <OrganisationPlanSingleImageChoiceWidget
        layoutItem={layoutItem}
        isSingleChoice={true}
        widget={element as SingleImageChoiceWithReplacementWidgetType}
      />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.multipleImageChoice]: (
      <OrganisationPlanMultipleImageChoiceWidget
        layoutItem={layoutItem}
        isSingleChoice={false}
        widget={element as MultipleImageChoiceWithReplacementWidgetType}
      />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.table]: (
      <OrganisationPlanTableWithReplacementWidget
        layoutItem={layoutItem}
        widget={element as OrganisationPlanTableWithReplacementWidgetType}
      />
    ),
    [ORGANISATION_PLAN_WIDGET_TYPES.uploadArea]: (
      <OrganisationPlanUploadAreaWithReplacementWidget
        layoutItem={layoutItem}
        widget={element as OrganisationPlanUploadAreaWithReplacementType}
      />
    )
  };

  const isWidgetChoiceType = (type: ORGANISATION_PLAN_WIDGET_TYPES) =>
    type === ORGANISATION_PLAN_WIDGET_TYPES.singleChoice ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.multipleChoice ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.singleImageChoice ||
    type === ORGANISATION_PLAN_WIDGET_TYPES.multipleImageChoice;

  const isWidgetEditable = (type: ORGANISATION_PLAN_WIDGET_TYPES) => {
    return !(
      type === ORGANISATION_PLAN_WIDGET_TYPES.infoFromQuestionnaire ||
      type === ORGANISATION_PLAN_WIDGET_TYPES.inputOrganisationPlanText ||
      type === ORGANISATION_PLAN_WIDGET_TYPES.notes ||
      type === ORGANISATION_PLAN_WIDGET_TYPES.divider
    );
  };

  const listOptions = useMemo<IListOptionContextMenu[]>(
    () => [
      {
        messageId: 'users.edit',
        onClick: editWidgetElement,
        isHidden: !isWidgetEditable(element.type as ORGANISATION_PLAN_WIDGET_TYPES)
      },
      {
        messageId: 'organisation.settings-link',
        onClick: openWidgetLinkSettingsSection
      },
      {
        messageId: `${
          element.hasOwnProperty('showOnlySelectedAnswer')
            ? intl.formatMessage({ id: 'organisation.show-all-answers' })
            : intl.formatMessage({ id: 'organisation.show-only-selected-answers' })
        }`,
        onClick: showOnlySelectedAnswer,
        disabled: false,
        isHidden: !isWidgetChoiceType(element.type as ORGANISATION_PLAN_WIDGET_TYPES)
      },
      {
        messageId: 'users.delete',
        onClick: deleteWidgetElement,
        isBorderTop: true
      }
    ],
    [element]
  );
  const menuComp = (element: ORGANISATION_PLAN_WIDGET_TYPES) => {
    if (!element || element)
      return (
        <Box position="absolute" top="0" right="0" zIndex="20">
          <ContextMenu listOptions={listOptions} />
        </Box>
      );
  };

  return (
    <>
      <DragHandle isDraggable={true} />
      <Styles.WidgetWrapper>
        {menuComp(element.type as ORGANISATION_PLAN_WIDGET_TYPES)}
        {widgetComponentByType[element.type] || null}
      </Styles.WidgetWrapper>
    </>
  );
};

export default OrganisationPlanTemplateWidgetWrapper;
