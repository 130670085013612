import { BuilderPageItemType } from 'modules/builder/types';
import { IOrgPlanLinkObjectForSelect } from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateLinkingTab/types';
import { OrganisationPlanWidgetsType } from 'modules/organisation-plan-templates/types';
import { IOrganisationPlanSection } from 'modules/organisation-plan-templates/types/organisationPlanSection';
import { compareUserAnswerToLinkRule, getLinkWithWidgets } from 'modules/questionnaires/utils/linkingDataTransform';
import { LinkingAction } from 'shared/types/questioner-template-link';

interface IApplyLinkingRulesOnSectionsParams {
  allQuestionnairesPages: BuilderPageItemType[];
  links: IOrgPlanLinkObjectForSelect[];
  sections: IOrganisationPlanSection[];
}
/**
 * Util function to update `isHidden` field for section entity according to link rules.
 * @param param.allQuestionnairesPages all pages from questionnaire. need to get widgets with answer.
 * @param param.links all organisation plan links
 * @param param.sections org plan section array
 * @returns array of section
 */
export const applyLinkingRulesOnSections = ({
  allQuestionnairesPages,
  links,
  sections
}: IApplyLinkingRulesOnSectionsParams): IOrganisationPlanSection[] => {
  return sections.map((section) => {
    const linksForSection = links.filter((link) => link.target.sectionId === section.sectionId);
    if (!linksForSection) return section;
    const sourceLinksWithWidget = allQuestionnairesPages ? getLinkWithWidgets(allQuestionnairesPages, linksForSection) : [];
    const linksWithWidgetIgnoredHidden = sourceLinksWithWidget.filter((link) => !link.widget.isHidden);
    const defaultIsHidden = linksForSection.some((link) => link.action === LinkingAction.show);
    const actions = compareUserAnswerToLinkRule(linksWithWidgetIgnoredHidden);
    if (!actions.length) {
      return { ...section, isHidden: defaultIsHidden };
    }
    const isHidden = actions[actions.length - 1] === LinkingAction.hide;
    return { ...section, isHidden };
  });
};

interface IApplyLinkingOnOrgPlanWidgetsParams {
  allQuestionnairesPages: BuilderPageItemType[];
  links: IOrgPlanLinkObjectForSelect[];
  widgets: OrganisationPlanWidgetsType[];
}
/**
 * Util function to update `isHidden` field for org plan widget entity according to link rules.
 * @param param.allQuestionnairesPages all pages from questionnaire. need to get widgets with answer.
 * @param param.links all organisation plan links
 * @param param.widgets org plan widgets array
 * @returns array of widgets
 */
export const applyLinkingOnOrgPlanWidgets = ({
  allQuestionnairesPages,
  links,
  widgets
}: IApplyLinkingOnOrgPlanWidgetsParams): OrganisationPlanWidgetsType[] => {
  return widgets.map((widget) => {
    if (widget.isHidden) return widget;
    const linksForWidget = links.filter((link) => link.target.widgetId === widget.widgetId);
    if (!linksForWidget) return widget;
    const sourceLinksWithWidget = allQuestionnairesPages ? getLinkWithWidgets(allQuestionnairesPages, linksForWidget) : [];
    const linksWithWidgetIgnoredHidden = sourceLinksWithWidget.filter((link) => !link.widget.isHidden);
    const defaultIsHidden = linksForWidget.some((link) => link.action === LinkingAction.show);
    const actions = compareUserAnswerToLinkRule(linksWithWidgetIgnoredHidden);
    if (!actions.length) {
      return { ...widget, isHidden: defaultIsHidden };
    }
    const isHidden = actions[actions.length - 1] === LinkingAction.hide;
    return { ...widget, isHidden };
  });
};
