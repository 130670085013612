import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GRID_LAYOUT_SIZE_ORGANISATION_PLAN } from 'modules/organisation-plan-templates/constants/organisationPlanTemplateBuilder';

export const BoxWrapper = styled(Box)(() => ({
  height: '100%',
  position: 'relative',
  // width => grid size + mui grid padding + side borders
  width: GRID_LAYOUT_SIZE_ORGANISATION_PLAN.widthGrid + 2
}));

export const BoxEmptyInfoContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,

  h4: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#8C8C8C'
  }
}));

export const WidgetWrapperBox = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.secondary.light}`,
  borderRadius: '6px',
  backgroundColor: theme.palette.background.paper,
  maxWidth: '100%'
}));

export const AddButtonIcon = styled(IconButton)(() => ({
  span: {
    color: '#262626',

    svg: {
      width: '100%',
      height: '100%'
    }
  }
}));
