import { Box, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxWrapper = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
}));

export const TabsBoxWrapper = styled(Box)(() => ({
  borderBottom: '1px solid #F0F0F0'
}));

export const TabsContainer = styled(Tabs)(() => ({
  '& .MuiButtonBase-root': { padding: '12px 5px' },
  '& .MuiTabs-flexContainer': { justifyContent: 'center' }
}));

export const ButtonsContainer = styled(Box)(() => ({
  marginTop: '20px',

  '& .info-save-button': {
    marginBottom: '10px'
  }
}));

export const OrganisationPlanTabPanelContainer = styled(Box)(() => ({
  padding: '20px'
}));
