import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import { useDispatch } from 'store';
import { updateOrganisationPlanLayoutPartially } from 'store/organisation-plan-template/organisation-plan-template.slice';
import { OrganisationPlanTextWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanTextWidgetType';
import { getOrganisationPlanWidgetRowsAmount } from 'modules/organisation-plan-templates/utils/getOrganisationPlanWidgetRowsAmount';
import * as Styles from './PreviewTextInputWidget.styles';

interface IPreviewTextInputWidgetProps {
  element: OrganisationPlanTextWidgetType;
}

const PreviewTextInputWidget = ({ element }: IPreviewTextInputWidgetProps) => {
  const { content, sectionId } = element;
  const intl = useIntl();
  const dispatch = useDispatch();
  function createMarkup(text: string) {
    return { __html: text };
  }
  const refBlock = useRef<HTMLDivElement>(null);
  const refText2 = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (typeof refBlock?.current?.offsetHeight !== 'number' || typeof refText2?.current?.offsetHeight !== 'number') return;
    const textHeight = getOrganisationPlanWidgetRowsAmount(refText2?.current?.offsetHeight);
    if (textHeight > 1) {
      dispatch(
        updateOrganisationPlanLayoutPartially({
          layout: {
            h: textHeight,
            i: element.widgetId
          },
          sectionId: sectionId || ''
        })
      );
    }
  }, [refBlock?.current?.offsetHeight, dispatch, element.widgetId, sectionId]);

  return (
    <Styles.BoxWrapper ref={refText2}>
      <Styles.TypographyWrapper ref={refBlock}>
        {content ? <Box dangerouslySetInnerHTML={createMarkup(content)} /> : intl.formatMessage({ id: 'organisation.no-content-provided' })}
      </Styles.TypographyWrapper>
    </Styles.BoxWrapper>
  );
};

export default PreviewTextInputWidget;
