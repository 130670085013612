import { ReactNode, useEffect, useState } from 'react';
import * as React from 'react';
import { OverridableStringUnion } from '@mui/types';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { Box, Dialog, IconButton, IconButtonPropsColorOverrides, Menu, Stack, useMediaQuery } from '@mui/material';
import { MoreVertical20Regular } from '@fluentui/react-icons';
import FeatureRoleGuard from 'modules/auth/components/FeatureRoleGuard';
import { Roles } from 'modules/auth/types';
import { replaceAllSpace } from 'shared/utils/editText';
import OptionOfMenu from './components/OptionOfMenu';

export interface IListOptionContextMenu {
  onClick?: (isCloseMenu?: boolean) => void;
  icon?: ReactNode;
  disabledIcon?: ReactNode;
  isDisabledIcon?: boolean;
  messageId: string;
  roles?: Roles[];
  color?: OverridableStringUnion<
    'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    IconButtonPropsColorOverrides
  >;
  disabledMenu?: boolean;
  className?: string;
  isCloseMenu?: boolean;
  isBorderTop?: boolean;
  isBorderBottom?: boolean;
  isHidden?: boolean;
}

interface IContextMenu {
  listOptions: IListOptionContextMenu[];
  isShowMenu?: boolean;
  sxContainer?: SxProps<Theme>;
  sxMenu?: SxProps<Theme>;
  sxIconButton?: SxProps<Theme>;
  icon?: ReactNode;
  origin?: 'top' | 'bottom';
  size?: 'small' | 'medium' | 'large';
  children?: ReactNode;
  isRightClick?: boolean;
  noIcon?: boolean;
  isExternal?: boolean;
  externalAnchor?: { mouseX: number; mouseY: number } | null;
}

/**
 * Component to generate context menu from listOptions
 * @param listOptions - option in context menu
 * @param isShowMenu -  context menu is opened or closed
 * @param sxContainer - additional styles of context
 * @param sxMenu - additional styles of menu
 * @param sxIconButton - additional styles of icon button
 * @param icon - icon of context menu
 * @param origin - origin of context menu: "top" | "bottom"
 * @param size - size of context menu button: "small" | "medium" | "large"
 * @param children - children of context menu
 * @param isRightClick - context menu is opened by right click
 * @param noIcon - context menu has no icon
 * @param onContextMenu - function to show context menu
 * @param isExternal - context menu is external
 * @param externalAnchor - external anchor of context menu
 */

const ContextMenu = ({
  listOptions,
  isShowMenu = true,
  sxContainer,
  sxMenu,
  sxIconButton,
  icon,
  origin,
  size,
  children,
  isRightClick,
  noIcon,
  isExternal = false,
  externalAnchor = null
}: IContextMenu) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
    if (!event) return;
    event.stopPropagation();
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY
    });
  };

  useEffect(() => {
    if (isExternal && externalAnchor) {
      setContextMenu(externalAnchor);
    }
  }, [externalAnchor, isExternal]);

  const handleCloseMenu = () => {
    setContextMenu(null);
  };

  const handleOptionClick = (isCloseMenu: boolean) => {
    isCloseMenu && handleCloseMenu();
  };

  const baseStyles: SxProps<Theme> = {
    position: 'relative',
    justifyContent: 'center',
    alignItems: ' center'
  };

  const isOpen = Boolean(contextMenu);

  const handleContextMenuPropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleRightClickOnChildren = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isRightClick) return;
    setContextMenu({
      mouseX: e.clientX,
      mouseY: e.clientY
    });
  };

  return (
    <Stack onContextMenu={handleContextMenuPropagation} direction="row" spacing={!!children ? 1 : 0} sx={sxContainer ?? baseStyles}>
      <Box width="100%" onContextMenu={handleRightClickOnChildren}>
        {children}
      </Box>
      {!noIcon && (
        <IconButton className="not-draggable" size={size ? size : 'small'} onClick={handleClickMenu} sx={sxIconButton}>
          {icon ? icon : <MoreVertical20Regular className="dots-active" />}
        </IconButton>
      )}
      {isShowMenu && contextMenu && !isMobile && (
        <Menu
          id="simple-menu"
          anchorReference="anchorPosition"
          anchorPosition={{ top: contextMenu.mouseY, left: contextMenu.mouseX }}
          keepMounted
          open={isOpen}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: origin ? (origin === 'top' ? 'top' : 'bottom') : 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: origin ? (origin === 'top' ? 'top' : 'bottom') : 'top',
            horizontal: 'right'
          }}
          sx={sxMenu}
        >
          {listOptions
            .filter((option) => !option.isHidden)
            .map((option, index) => {
              if (!!option?.roles) {
                return (
                  <FeatureRoleGuard key={replaceAllSpace(option.messageId)} roles={option.roles}>
                    <OptionOfMenu option={option} setIsCloseMenu={handleOptionClick} />
                  </FeatureRoleGuard>
                );
              }
              return <OptionOfMenu key={index} option={option} setIsCloseMenu={handleOptionClick} />;
            })}
        </Menu>
      )}
      {isShowMenu && contextMenu && isMobile && (
        <Dialog id="simple-menu" keepMounted open={isOpen} onClose={handleCloseMenu}>
          {listOptions
            .filter((option) => !option.isHidden)
            .map((option, index) => {
              if (!!option?.roles) {
                return (
                  <FeatureRoleGuard key={replaceAllSpace(option.messageId)} roles={option.roles}>
                    <OptionOfMenu option={option} setIsCloseMenu={handleOptionClick} />
                  </FeatureRoleGuard>
                );
              }
              return <OptionOfMenu key={index} option={option} setIsCloseMenu={handleOptionClick} />;
            })}
        </Dialog>
      )}
    </Stack>
  );
};

export default ContextMenu;
