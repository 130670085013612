import { IOrgPlanLinkObjectForSelect } from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateLinkingTab/types';
import { ActiveLinkingSettingsType } from 'store/organisation-plan-template/organization-plan-template.types';

/**
 * Util function that filters all links by `link.target` field by guid section or widget.
 * @param target.sectionId guid of section
 * @param target.widgetId guid of widget
 * @param allLinks array of all links
 * @returns array of links
 */
export const filterOrgPlanLinksByTarget = (
  { sectionId, widgetId }: ActiveLinkingSettingsType,
  allLinks: IOrgPlanLinkObjectForSelect[]
): IOrgPlanLinkObjectForSelect[] => {
  if (sectionId) {
    return allLinks.filter((link) => link?.target?.sectionId === sectionId);
  }
  return allLinks.filter((link) => link?.target?.widgetId === widgetId);
};
