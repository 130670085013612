import { useCallback, useEffect, useRef } from 'react';
import { useBeforeUnload, useBlocker } from 'react-router-dom';

interface IUsePromptSystem {
  message: string;
  when: boolean;
}

export const usePromptSystem = ({ message, when }: IUsePromptSystem) => {
  const isShowSystemWindow = when && typeof message === 'string';
  const hookGet = useCallback(() => (isShowSystemWindow ? !window.confirm(message) : false), [isShowSystemWindow, message]);
  let blocker = useBlocker(hookGet);
  let prevState = useRef(blocker.state);

  useEffect(() => {
    if (!when) return;
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker, when]);

  useBeforeUnload(
    useCallback(
      (event) => {
        if (isShowSystemWindow) {
          event.preventDefault();
          event.returnValue = message;
        }
      },
      [isShowSystemWindow, message]
    ),
    { capture: true }
  );
};
