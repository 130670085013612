import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'store';
import { snackbarAlert } from 'shared/utils/infoText';
import { AlertMessagesColor } from 'shared/types/alert-messages';
import useEmailTemplateData from 'shared/hooks/useEmailTemplateData';
import { useCurrentDateFormat, useCurrentTimeFormat } from 'shared/hooks/regionalSettings';
import { TimeFormatEnum } from 'shared/configs/config';
import { selectPaginationInvoices } from 'store/pagination/pagination.selectors';
import { IModalSendEmail } from 'modules/modalSendEmail/types';
import ModalSendEmail from 'modules/modalSendEmail/components/ModalSendEmail';
import { IOfferEditor } from 'shared/types/offer';
import { prepareDataToSendEmailOffer } from 'modules/offers/utils/prepareData';
import { useGetCurrentTenant } from 'api/hooks/tenant/useGetCurrentTenant';
import { applyFormatToDateTimePickerValue } from 'modules/organisation-plan-preview/components/OrganisationPlanPreviewWidgetSection/preview-widgets/PreviewTimePickerWidget/util';
import { useSendEmailOffer } from 'api/hooks/offers/useSendEmailOffer';
import { useGetOfferById } from 'api/hooks/offers/useGetOfferById';

interface IDialogSendEmail {
  isOpenDialog: boolean;
  closeDialog: () => void;
}

const OfferModalSendEmail = ({ isOpenDialog, closeDialog }: IDialogSendEmail) => {
  const [isErrorSendEmail, setIsErrorSendEmail] = useState<boolean>(false);

  const { values: offerEditor } = useFormikContext<IOfferEditor>();
  const pagination = useSelector(selectPaginationInvoices);

  const intl = useIntl();
  const { offerId } = useParams();
  const dispatch = useDispatch();
  const currentDateFormat = useCurrentDateFormat();
  const timeFormat = useCurrentTimeFormat();
  const isTwelveHoursFormat = timeFormat === TimeFormatEnum.Twelve;
  const { data: tenant } = useGetCurrentTenant();

  const { data: offerEditorData } = useGetOfferById({ offerId: Number(offerId) });

  const { subject, template } = useEmailTemplateData({ code: offerEditor?.type.toLowerCase() });

  const { mutateAsync: sendEmail, isLoading: isSendEmail } = useSendEmailOffer({
    options: {
      onError() {
        setIsErrorSendEmail(true);
      }
    }
  });

  const getDateWithSettings = useCallback(
    (value?: Date | string | null) => {
      return applyFormatToDateTimePickerValue({
        type: 'dateTime',
        date: !!value ? value.toString() : null,
        dateFormat: currentDateFormat?.format,
        isTwelveHoursFormat
      });
    },
    [currentDateFormat?.format, isTwelveHoursFormat]
  );

  const offerEmail = useMemo(
    () => ({
      ...offerEditor,
      customUrl: tenant?.customUrl
        ? `<a href="${import.meta.env.VITE_REACT_APP_SITE_URL}/login/${tenant?.customUrl}">${intl.formatMessage({
            id: 'settings.email-templates-variable-title-go-to-login'
          })}</a>`
        : '',
      goToRequest: !!offerEditorData?.request?.id
        ? `<a href="[[go_to_request]]">${intl.formatMessage({
            id: 'auth.go-to-request'
          })}</a>`
        : '',
      requestedDate: getDateWithSettings(offerEditor?.data?.requestedEventDate),
      offerDate: getDateWithSettings(offerEditor?.baseDates?.offerDate),
      validUntil: getDateWithSettings(offerEditor?.baseDates?.validUntil)
    }),
    [offerEditor, intl, tenant, getDateWithSettings, offerEditorData?.request?.id]
  );

  const onSuccessResult = () => {
    const title = intl.formatMessage({ id: 'invoices.invoice-editor-send-email-success-message-title' });
    const subTitle = intl.formatMessage({ id: 'offer.editor-send-email-success-message-subtitle' });
    dispatch(snackbarAlert(title, AlertMessagesColor.Success, subTitle));
    closeDialog();
  };

  const handleOnSubmit = async (values: IModalSendEmail) => {
    await sendEmail(
      { id: values.id!, subject: values.subject, body: values.message, articles: offerEditor?.articles, pagination },
      {
        onSuccess: () => {
          onSuccessResult();
        }
      }
    );
  };

  const handleCloseErrorModal = () => {
    setIsErrorSendEmail(false);
  };
  const nameAttachFile = `${intl.formatMessage({ id: 'offer.types-offer' })}_${offerEditor.number}`;
  return (
    <ModalSendEmail
      isOpenDialog={isOpenDialog}
      closeDialog={closeDialog}
      data={prepareDataToSendEmailOffer({
        offer: offerEmail,
        subject,
        template
      })}
      type={'offers'}
      nameAttachFile={nameAttachFile}
      onSubmit={handleOnSubmit}
      isSendEmail={isSendEmail}
      handleCloseErrorModal={handleCloseErrorModal}
      isErrorSendEmail={isErrorSendEmail}
    />
  );
};

export default OfferModalSendEmail;
