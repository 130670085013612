import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ImageUploadWidgetBoxWrapper = styled(Box)(() => ({
  width: '100%',
  height: '100%'
}));

export const ImageUploadWidgetValueBoxWrapper = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  position: 'relative',
  borderRadius: '0'
}));

export const ImageBoxContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  borderRadius: '6px',
  overflow: 'hidden'
}));

export const ImageContainer = styled('img')(() => ({
  width: '100%',
  height: '100%'
}));

export const NoImageContainer = styled('img')(() => ({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  borderRadius: '6px',
  overflow: 'hidden'
}));

export const ShadowBox = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  background: `repeating-linear-gradient(-45deg, #00000030, #00000030 10px, #00000015 10px, #00000015 20px)`,
  position: 'absolute',
  borderRadius: '6px',
  zIndex: 1
}));
