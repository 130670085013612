import { Desktop20Regular, WeatherMoon20Regular, WeatherSunny20Regular } from '@fluentui/react-icons';
import { alpha, Card, Stack, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import useConfig from 'shared/hooks/useConfig';
import { ThemeMode } from 'shared/types/config';

const ThemeModeLayout = () => {
  const { mode, onChangeMode } = useConfig();
  const theme = useTheme();

  const handleModeChange = (_event: React.MouseEvent<HTMLElement>, newMode: ThemeMode | null) => {
    if (newMode !== null) {
      onChangeMode(newMode);
    }
  };

  return (
    <Card
      sx={{
        borderRadius: '8px',
        boxShadow: 'rgba(0, 0, 0, 0.01) 0px 6px 6px 0px, rgba(0, 0, 0, 0.07) 0px 0px 0px 1px',
        backgroundColor: alpha(theme.palette.secondary.lighter, 0.5)
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <ToggleButtonGroup
          size="small"
          sx={{
            borderRadius: '10px',
            width: '100%',
            padding: '4px',
            '& .Mui-selected': {
              backgroundColor: `${theme.palette.background.paper} !important`,
              boxShadow: `rgba(0, 0, 0, 0.03) 0px 6px 6px 0px, rgba(0, 0, 0, 0.07) 0px 0px 0px 1px`
            }
          }}
          value={mode}
          exclusive
          onChange={handleModeChange}
          aria-label="Theme mode"
        >
          <ToggleButton
            size="small"
            fullWidth
            sx={{ border: 'none', borderRadius: '6px !important' }}
            value="light"
            aria-label="Light mode"
          >
            <Stack direction="row" justifyContent="center" alignItems="center" gap="10px">
              <WeatherSunny20Regular />
              <FormattedMessage id="settings.appearance-light" defaultMessage="Light" />
            </Stack>
          </ToggleButton>
          <ToggleButton
            size="small"
            fullWidth
            sx={{ border: 'none', borderRadius: '6px !important' }}
            value="system"
            aria-label="System mode"
          >
            <Stack direction="row" justifyContent="center" alignItems="center" gap="10px">
              <Desktop20Regular />
              <FormattedMessage id="settings.appearance-system" defaultMessage="System" />
            </Stack>
          </ToggleButton>
          <ToggleButton size="small" fullWidth sx={{ border: 'none', borderRadius: '6px !important' }} value="dark" aria-label="Dark mode">
            <Stack direction="row" justifyContent="center" alignItems="center" gap="10px">
              <WeatherMoon20Regular />
              <FormattedMessage id="settings.appearance-dark" defaultMessage="Dark" />
            </Stack>
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
    </Card>
  );
};

export default ThemeModeLayout;
