// types
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IPagination } from 'shared/types/table';
import { ERequestsQueryKeys } from 'shared/types/requests';

export type PaginationKeysType =
  | 'users'
  | 'tenants'
  | 'events'
  | 'questionnaires'
  | 'venues'
  | 'articles'
  | 'organisationPlans'
  | 'customerEvents'
  | 'invoices'
  | 'notes'
  | ERequestsQueryKeys.GetRequests;
type PaginationStateType = Record<PaginationKeysType, IPagination>;

// initial state
const initialState: PaginationStateType = {
  users: { pageNumber: 1, pageSize: 10 },
  tenants: { pageNumber: 1, pageSize: 10 },
  events: { pageNumber: 1, pageSize: 10 },
  questionnaires: { pageNumber: 1, pageSize: 10 },
  venues: { pageNumber: 1, pageSize: 10 },
  articles: { pageNumber: 1, pageSize: 10 },
  organisationPlans: { pageNumber: 1, pageSize: 10 },
  customerEvents: { pageNumber: 1, pageSize: 5 },
  invoices: { pageNumber: 1, pageSize: 10 },
  notes: { pageNumber: 1, pageSize: 99 },
  [ERequestsQueryKeys.GetRequests]: { pageNumber: 1, pageSize: 10 }
};

interface IUpdatePagination extends IPagination {
  key: PaginationKeysType;
}

// ==============================|| SLICE - MENU ||============================== //
const pagination = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    updatePagination(state, { payload: { key, ...pagination } }: PayloadAction<IUpdatePagination>) {
      state[key] = pagination;
    }
  }
});

export default pagination.reducer;

export const { updatePagination } = pagination.actions;
