import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { IAppearanceSettings, QuestionnaireTaxType } from 'modules/questionnaires/types';
import useGeneralSettings from 'shared/hooks/useGeneralSettings';
import { formatPriceByLanguage } from 'shared/utils/price';

export interface IBaseSettings {
  code: string;
  symbol: string;
  taxType: QuestionnaireTaxType;
  appearanceSettings?: IAppearanceSettings;
  timeFormat?: string;
  individualTax?: number;
  legalTax?: number;
  dateSyntax?: string;
}

interface IFormatPrice {
  value: string | number;
  styles?: SxProps<Theme>;
  isHideSymbol?: boolean;
}

/**
 * Output price in german number format or format selected in  tenant's regional settings
 * @param value: price
 * @param styles: additional styles
 * @param isHideSymbol: flag to hide currency symbol
 */
const FormatPrice = ({ value, isHideSymbol }: IFormatPrice) => {
  const { regionalSettings } = useGeneralSettings();
  const decimalSeparator = regionalSettings?.currency?.decimalSeparator || '.';
  const groupSeparator = regionalSettings?.currency?.thousandSeparator || ',';
  const currencySymbol = regionalSettings?.currency?.symbol;
  const currencyCode = regionalSettings?.currency?.code;
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  if (isNaN(numericValue)) return <></>;

  switch (currencyCode) {
    case 'USD':
      return <>{`${!isHideSymbol ? currencySymbol : ''}${formatPriceByLanguage(numericValue, decimalSeparator, groupSeparator)}`}</>;
    case 'CAD':
      return <>{`${!isHideSymbol ? currencySymbol : ''}${formatPriceByLanguage(numericValue, decimalSeparator, groupSeparator)}`}</>;
    case 'AUD':
      return <>{`${!isHideSymbol ? currencySymbol : ''}${formatPriceByLanguage(numericValue, decimalSeparator, groupSeparator)}`}</>;
    case 'NZD':
      return <>{`${!isHideSymbol ? currencySymbol : ''}${formatPriceByLanguage(numericValue, decimalSeparator, groupSeparator)}`}</>;
    case 'EUR':
      return <>{`${formatPriceByLanguage(numericValue, decimalSeparator, groupSeparator)} ${!isHideSymbol ? currencySymbol : ''}`}</>;
    case 'CHF':
      return <>{`${formatPriceByLanguage(numericValue, decimalSeparator, groupSeparator)} ${!isHideSymbol ? currencySymbol : ''}`}</>;
    case 'GBP':
      return <>{`${!isHideSymbol ? currencySymbol : ''}${formatPriceByLanguage(numericValue, decimalSeparator, groupSeparator)}`}</>;
    case 'NOK':
      return <>{`${formatPriceByLanguage(numericValue, decimalSeparator, groupSeparator)} ${!isHideSymbol ? currencySymbol : ''}`}</>;
    case 'DKK':
      return <>{`${formatPriceByLanguage(numericValue, decimalSeparator, groupSeparator)} ${!isHideSymbol ? currencySymbol : ''}`}</>;
    case 'SEK':
      return <>{`${formatPriceByLanguage(numericValue, decimalSeparator, groupSeparator)} ${!isHideSymbol ? currencySymbol : ''}`}</>;
    case 'ILS':
      return <>{`${!isHideSymbol ? currencySymbol : ''}${formatPriceByLanguage(numericValue, decimalSeparator, groupSeparator)}`}</>;
    default:
      return <>{`${!isHideSymbol ? currencySymbol : ''}${formatPriceByLanguage(numericValue, decimalSeparator, groupSeparator)}`}</>;
  }
};

export default FormatPrice;
