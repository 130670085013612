import { Box, styled } from '@mui/material';

interface IActionButton {
  isActive?: boolean;
  isFixed?: boolean;
}

export const ReplacementTextButton = styled(Box)<IActionButton>(({ theme, isActive }) => ({
  padding: '0 4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '25px',
  backgroundColor: isActive
    ? theme.palette.mode === 'dark'
      ? theme.palette.secondary.lighter
      : theme.palette.secondary.light
    : theme.palette.secondary.lighter,
  borderRadius: isActive ? '4px' : '4px 0 0 4px',
  border: `1px solid ${theme.palette.secondary.light}`,
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  '& svg': {
    color: isActive ? theme.palette.primary.main : theme.palette.secondary.main
  }
}));

export const HideLabelButton = styled(Box)<IActionButton>(({ theme, isActive }) => ({
  padding: '0 4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '25px',
  backgroundColor: isActive
    ? theme.palette.mode === 'dark'
      ? theme.palette.secondary.lighter
      : theme.palette.secondary.light
    : theme.palette.secondary.lighter,
  borderRadius: isActive ? '4px' : '0 4px 4px 0',
  border: `1px solid ${theme.palette.secondary.light}`,
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  '& svg': {
    color: isActive ? theme.palette.primary.main : theme.palette.secondary.main
  }
}));

export const SingleActionButton = styled(Box)<IActionButton>(({ theme, isActive, isFixed }) => ({
  padding: '0 4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '25px',
  backgroundColor: isActive
    ? theme.palette.mode === 'dark'
      ? theme.palette.secondary.lighter
      : theme.palette.secondary.light
    : theme.palette.secondary.lighter,
  borderRadius: '4px',
  border: `1px solid ${theme.palette.secondary.light}`,
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
    '& svg': {
      color: theme.palette.primary.main
    }
  },
  '& svg': {
    color: isActive ? theme.palette.primary.main : theme.palette.secondary.main
  },
  position: isFixed ? 'absolute' : 'relative',
  top: 8,
  right: 8,
  zIndex: 2
}));
