import { useFormikContext } from 'formik';
import EditorDetails, { IEditorDetails } from 'modules/editor/components/EditorDetails';
import { useFormatCurrentDate } from 'shared/hooks/regionalSettings';
import { IOfferEditor } from 'shared/types/offer';
import { EEditorType } from 'modules/editor/types/editor';

const OfferEditorDetails = () => {
  const { values } = useFormikContext<IOfferEditor>();
  const templateLogo = typeof values?.settingsTemplate?.logoImagePath === 'string' ? values?.settingsTemplate?.logoImagePath : '';
  const formatFn = useFormatCurrentDate();

  const dataAdditional: IEditorDetails['data'] = [
    { intlId: 'offer.modal-field-serial-number', value: values.number ?? '' },
    {
      intlId: 'offer.modal-field-requested-event-date',
      value: values.baseDates?.requestedEventDate ? formatFn(values.baseDates?.requestedEventDate as string) : null
    },
    {
      intlId: 'offer.modal-field-event-date',
      value: values.baseDates?.eventDate ? formatFn(values.baseDates?.eventDate as string) : null
    },
    { intlId: 'offer.date-of-offer', value: formatFn(values.baseDates?.offerDate as string) },
    { intlId: 'offer.modal-field-offer-valid-until', value: formatFn(values.baseDates?.validUntil as string) }
  ].filter((date) => !!date.value);

  return <EditorDetails type={EEditorType.Offers} templateLogo={templateLogo} data={dataAdditional} />;
};

export default OfferEditorDetails;
