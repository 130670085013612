import { PayloadAction, current } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import { usedWidgetsEntityAdapter } from 'store/dashboard/dashboard.slice';
import { ICheckHasDataChangesDashboard, IDashboardState, ISetInitialDataDashboardPayload } from './dashboard.interfaces';

export const checkHasDataChangesDashboard = (
  state: IDashboardState,
  { payload: { layout, widgets, dashboardName } }: PayloadAction<ICheckHasDataChangesDashboard>
) => {
  let changedFields = [...state.initialState.changedFields];

  if (widgets) {
    const allWidgetsForCurrentPageWidget = usedWidgetsEntityAdapter.getSelectors().selectAll(state.usedWidgets);
    const pureWidgets = current(state.initialState.widgets);
    const isEqualWidgets = isEqual(pureWidgets, allWidgetsForCurrentPageWidget);

    if (isEqualWidgets) {
      changedFields = changedFields.filter((field) => field !== 'widgets');
    } else if (!changedFields.includes('widgets')) {
      changedFields.push('widgets');
    }
  }

  if (layout) {
    const filteredData = state.layout.map((obj) => {
      return pickBy(obj, (v) => v !== undefined);
    });
    const pureLayout = current(state.initialState.layout).map((obj) => {
      return pickBy(obj, (v) => v !== undefined);
    });
    const isEqualLayouts = isEqual(pureLayout, filteredData);

    if (isEqualLayouts) {
      changedFields = changedFields.filter((field) => field !== 'layouts');
    } else if (!changedFields.includes('layouts')) {
      changedFields.push('layouts');
    }
  }

  if (dashboardName) {
    const filteredData = state.dashboardName;
    const pureLayout = state.initialState.dashboardName;
    const isEqualNames = isEqual(pureLayout, filteredData);

    if (isEqualNames) {
      changedFields = changedFields.filter((field) => field !== 'dashboardName');
    } else if (!changedFields.includes('dashboardName')) {
      changedFields.push('dashboardName');
    }
  }

  state.initialState.changedFields = changedFields;
  state.isDataChanged = changedFields.length > 0;
};

export const setInitialDataDashboardReducer = (
  state: IDashboardState,
  { payload: { layout, widgets, dashboardName } }: PayloadAction<ISetInitialDataDashboardPayload>
) => {
  state.layout = layout;
  state.initialState.layout = layout;
  state.initialState.dashboardName = dashboardName;
  if (widgets) {
    state.initialState.widgets = widgets;
  }
};

export const setInitialStateDashboardReducer = (
  state: IDashboardState,
  { payload: { layout, widgets, dashboardName } }: PayloadAction<ICheckHasDataChangesDashboard>
) => {
  if (widgets) {
    state.initialState.widgets = usedWidgetsEntityAdapter.getSelectors().selectAll(state.usedWidgets);
  }

  if (layout) {
    state.initialState.layout = state.layout;
  }
  if (dashboardName) {
    state.initialState.dashboardName = state.dashboardName;
  }

  state.initialState.changedFields = [];
  state.isDataChanged = false;
};
