import { Box, useTheme } from '@mui/material';
import { useGetCurrentTenant } from 'api/hooks/tenant/useGetCurrentTenant';
import { useFormikContext } from 'formik';
import EditorHeader from 'modules/editor/components/EditorHeader';
import { EEditorType } from 'modules/editor/types/editor';
import InvoiceModalSendEmail from 'modules/invoices/components/InvoiceModalSendEmail';
import InvoiceStatusSelect from 'modules/invoices/components/InvoiceStatusSelect';
import { INVOICE_TYPE } from 'modules/invoices/constants/allInvoiceTable';
import ModalEditInvoice from 'modules/invoices/containers/ModalEditInvoice';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { EInvoiceType, IInvoiceEditor } from 'shared/types/invoice';

interface IInvoiceEditorHeader {
  isLoading: boolean;
}

const InvoiceEditorHeader = ({ isLoading }: IInvoiceEditorHeader) => {
  const intl = useIntl();
  const theme = useTheme();
  const { data: tenantData } = useGetCurrentTenant();
  const [isEditInvoice, setIsEditInvoice] = useState(false);
  const [isSendEmail, setIsSendEmail] = useState(false);

  const { values, handleChange } = useFormikContext<IInvoiceEditor>();

  const handleEdit = () => setIsEditInvoice(true);

  const handleCloseEditInvoice = () => setIsEditInvoice(false);

  const handleCloseDialog = () => {
    setIsSendEmail(false);
  };
  const handleSendEmail = () => {
    setIsSendEmail(true);
  };
  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: '999',
        backgroundColor: theme.palette.background.paper
      }}
    >
      <EditorHeader
        type={EEditorType.Invoices}
        onEdit={handleEdit}
        onSendEmail={handleSendEmail}
        isLoading={isLoading}
        title={intl.formatMessage({ id: INVOICE_TYPE[values?.type ?? EInvoiceType.invoice].intlId })}
      >
        <InvoiceStatusSelect name="state" value={values?.state} isSentEmail={!!values?.isSentEmail} handleChange={handleChange} />
      </EditorHeader>
      {isEditInvoice && <ModalEditInvoice invoiceId={values?.id} isOpenModal={isEditInvoice} closeModal={handleCloseEditInvoice} />}
      {isSendEmail && <InvoiceModalSendEmail tenant={tenantData} isOpenDialog={isSendEmail} closeDialog={handleCloseDialog} />}
    </Box>
  );
};

export default InvoiceEditorHeader;
