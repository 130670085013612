import { FormattedMessage } from 'react-intl';
import { IconButton, Typography } from '@mui/material';
import { Info48Regular } from '@fluentui/react-icons';
import { OrganisationPlanInfoFromQuestionnaireWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanInfoFromQuestionnaireWidgetType';
import { useDispatch } from 'store';
import {
  setActiveInfoFromQuestionnaireSettings,
  setActiveInfoFromQuestionnaireSettingsSectionId
} from 'store/organisation-plan-template/organisation-plan-template.slice';
import * as Styles from './OrganisationPlanInfoFromAnswer.styles';

interface IInfoFromAnswerProps {
  element: OrganisationPlanInfoFromQuestionnaireWidgetType;
}

const OrganisationPlanInfoFromAnswer = ({ element }: IInfoFromAnswerProps) => {
  const dispatch = useDispatch();
  const { widgetId, sectionId } = element;

  const openInfoFromQuestionnaireSettings = () => {
    dispatch(setActiveInfoFromQuestionnaireSettings(widgetId));
    dispatch(setActiveInfoFromQuestionnaireSettingsSectionId(sectionId));
  };
  return (
    <Styles.WidgetWrapper>
      <Styles.Button onClick={openInfoFromQuestionnaireSettings} fullWidth variant="contained">
        <Styles.ContentWrapper component="span">
          <IconButton sx={{ width: '48px' }} disabled>
            <Info48Regular />
          </IconButton>
          <Typography sx={{ color: 'black' }}>
            <FormattedMessage id="organisation.info-from-questionnaire" />
          </Typography>
        </Styles.ContentWrapper>
      </Styles.Button>
    </Styles.WidgetWrapper>
  );
};

export default OrganisationPlanInfoFromAnswer;
