import { RootState } from 'store/index';
import { TopBannerWidgetType } from 'modules/builder/types/imageWidgetsTypes';
import { BUILDER_WIDGET_TYPES } from 'modules/builder/types';
import { linksEntityAdapter, usedWidgetsEntityAdapter } from './builder.slice';

export const selectLayout = (state: RootState) => state.builder.layout;
export const selectDraggableItem = (state: RootState) => state.builder.draggableItem;

export const { selectAll: selectAllLink } = linksEntityAdapter.getSelectors<RootState>((state) => state.builder.links);

export const { selectById: selectUsedWidgetById, selectAll: selectAllWidgets } = usedWidgetsEntityAdapter.getSelectors<RootState>(
  (state) => state.builder.usedWidgets
);

export const selectActivePageIdOnEdit = (state: RootState): string | null => state.builder.activePageIdOnEdit;
export const selectTopBannerFromAllWidgets = (state: RootState) =>
  selectAllWidgets(state).find((widget) => widget.type === BUILDER_WIDGET_TYPES.topBanner) as TopBannerWidgetType | undefined;
export const selectIsDirtyState = (state: RootState) => state.builder.isDataChanged;
export const selectIsTenantExist = (state: RootState) => state.builder.isTenantExist;
export const selectActiveWidgetForSettings = (state: RootState) => state.builder.aciveWidgetForSettings;
export const selectUsedArticles = (state: RootState) => state.builder.usedArticles;
export const selectCategories = (state: RootState) => state.builder.categories;
export const selectPriceCustomer = (state: RootState) => state.builder.priceCustomer;
export const selectErrorAdditionalFields = (state: RootState) => state.builder.errorAdditionalFields;
export const selectQuestionnaireSettings = (state: RootState) => state.builder.settingsLanguage;

export const selectWidgetsWithRestrictionMinMaxOptions = (state: RootState) => state.builder.widgetsWithRestrictionMinMaxOptions;

export const selectIsTopBannerExist = (state: RootState) => state.builder.isTopBannerAlreadyExist;
export const selectIsCalculateNeed = (state: RootState) => state.builder.isCalculateNeed;

export const selectIsPageSettingsOpen = (state: RootState) => state.builder.isPageSettingsOpen;
export const selectPageVisability = (state: RootState) => state.builder.pageVisibility;
