import { ChangeEvent, useState } from 'react';
import { Checkbox, Radio, Stack } from '@mui/material';
import usePrevious from 'shared/hooks/usePrevious';
import ReplacementTextButtons from 'shared/components/ReplacementText/Buttons';
import ReplacementTextInput from 'shared/components/ReplacementText/Input';
import * as Styles from './Heading.styles';

interface IReplacementTextHeading {
  value?: string | null;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  content?: string;
  isLabelHiddenValue?: boolean;
  onLabelHiddenChange?: (value: boolean) => void;
  onReplacementLabelHiddenChange?: () => void;
  isHeadingReplacementOpen?: (value: boolean) => void;
  isCheckbox?: boolean;
  isImageChoice?: boolean;
}
const ReplacementTextHeading = ({
  value,
  onChange,
  content,
  isLabelHiddenValue,
  onLabelHiddenChange,
  onReplacementLabelHiddenChange,
  isHeadingReplacementOpen,
  isCheckbox = undefined,
  isImageChoice = false
}: IReplacementTextHeading) => {
  const isReplacementValue = value ? !!value : false;
  const [isReplacementValueShown, setIsReplacementValueShown] = useState(isReplacementValue);
  const [isLabelHidden, setIsLabelHidden] = useState(isLabelHiddenValue);

  const prevValue = usePrevious(value);

  const replaceValueCallback = () => {
    setIsReplacementValueShown(!isReplacementValueShown);
    if (isHeadingReplacementOpen) {
      isHeadingReplacementOpen(!isReplacementValueShown);
    }
    prevValue && isReplacementValueShown && onReplacementLabelHiddenChange?.();
  };
  const labelShownCallback = () => {
    setIsLabelHidden(!isLabelHidden);
    onLabelHiddenChange?.(!isLabelHidden);
  };

  const replacementText = <ReplacementTextInput isImageChoice={isImageChoice} onChange={onChange} value={value} />;

  const originalText = <Styles.OriginalTextWrapper isImageChoice={isImageChoice}>{content}</Styles.OriginalTextWrapper>;

  return (
    <Styles.LabelBoxWrapper>
      <Styles.LabelRow isHidden={isLabelHidden}>
        <Stack width="100%" direction="row" spacing={0}>
          {isCheckbox === undefined ? null : isCheckbox ? (
            <Checkbox sx={{ padding: '0 9px' }} disabled />
          ) : (
            <Radio sx={{ padding: '0 9px' }} disabled />
          )}
          {isReplacementValueShown ? replacementText : originalText}
        </Stack>
        <ReplacementTextButtons
          isReplacementValueShown={isReplacementValueShown}
          replaceValueCallback={replaceValueCallback}
          isLabelShown={isLabelHidden}
          labelShownCallback={labelShownCallback}
        />
      </Styles.LabelRow>
    </Styles.LabelBoxWrapper>
  );
};

export default ReplacementTextHeading;
