import { Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

interface IStyleImage {
  customImageSize?: boolean;
}

export const BoxWrapper = styled(Box)<IStyleImage>(({ customImageSize }) => ({
  img: {
    maxWidth: '106px',
    maxHeight: '82px',
    width: customImageSize ? '72px' : 'auto',
    height: customImageSize ? '20px' : 'auto'
  }
}));

export const WidgetItem = styled(Card)(({ theme }) => ({
  cursor: '-webkit-grab',
  borderRadius: '8px',
  gap: '20px',
  width: '100%',
  minHeight: '170px',
  aspectRatio: '1 / 1.1',
  background: theme.palette.secondary.lighter,
  border: `1px dashed ${theme.palette.secondary.light}`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  boxShadow: 'none'
}));
