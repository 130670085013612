import { IEVENT_TYPE } from 'modules/events/types';
import { EventCreationStatusType, EventType } from 'shared/types/event';

export const EVENTS_ROUTES = {
  ROOT_LINK: '/events'
};

export const QUESTIONNAIRES_EVENT_PREVIEW_ROUTES = {
  ROOT_LINK: '/event/questionnaires/preview',
  LINK_BY_QUESTIONNAIRE: ':questionnaireId',
  LINK_BY_PAGE_ID: ':questionnaireId/:pageId',
  LINK_BY_PAGE_ID_AND_SUBPAGE_ID: ':questionnaireId/:pageId/:subPageId',
  PAGE_BY_ID: (questionnaireId: string, pageId: string, subPageId?: string) =>
    `/event/questionnaires/preview/${questionnaireId}${pageId ? `/${pageId}` : ''}${subPageId ? `/${subPageId}` : ''}`,
  GET_LINK_BY_QUESTIONNAIRE_ID: (id: string) => `/event/questionnaires/preview/${id}`
};

export const QUESTIONNAIRES_EVENT_EDIT_ROUTES = {
  ROOT_LINK: '/event/questionnaires/',
  EDIT_LINK_BY_QUESTIONNAIRE: ':questionnaireId',
  EDIT_LINK_BY_PAGE_ID: ':questionnaireId/:pageId',
  EDIT_LINK_BY_PAGE_ID_AND_SUBPAGE_ID: ':questionnaireId/:pageId/:subPageId',
  EDIT_PAGE_BY_ID: (questionnaireId: string, pageId: string, subPageId?: string) =>
    `/event/questionnaires/${questionnaireId}${pageId ? `/${pageId}` : ''}${subPageId ? `/${subPageId}` : ''}`,
  GET_LINK_BY_QUESTIONNAIRE_ID: (id: string) => `/event/questionnaires/${id}`
};

export const QUESTIONNAIRES_EVENT_PLAYGROUND_ROUTES = {
  ROOT_LINK: '/event/playground',
  LINK_PLAYGROUND_PAGE_ID: ':questionnaireId/:pageId',
  LINK_PLAYGROUND_PAGE_ID_AND_SUBPAGE_ID: ':questionnaireId/:pageId/:subPageId',
  PLAYGROUND_PAGE_BY_ID: (questionnaireId: string, pageId: string, subPageId?: string) =>
    `/event/playground/${questionnaireId}${pageId ? `/${pageId}` : ''}${subPageId ? `/${subPageId}` : ''}`
};

export const EVENT_TYPE: IEVENT_TYPE = {
  [EventType.Wedding]: {
    color: '#9254DE',
    intlId: 'questionnaires.wedding'
  },
  [EventType.Meeting]: {
    color: '#36CFC9',
    intlId: 'questionnaires.meeting'
  },
  [EventType.Corporate]: {
    color: '#FFA940',
    intlId: 'questionnaires.corporate'
  },
  [EventType.Private]: {
    color: '#597EF7',
    intlId: 'questionnaires.private'
  },
  [EventType.Other]: {
    color: '#FF6B6B',
    intlId: 'questionnaires.other'
  }
};

export const EVENT_STATUS = {
  [EventCreationStatusType.Pending]: {
    name: 'Pending',
    intlId: 'events.pending',
    color: '#F39C12'
  },
  [EventCreationStatusType.InPlanning]: {
    name: 'InPlanning',
    intlId: 'events.state-in-planning',
    color: '#2ECC71'
  },
  [EventCreationStatusType.Ready]: {
    name: 'Ready',
    intlId: 'events.state-ready',
    color: '#3498DB'
  },
  [EventCreationStatusType.EventDay]: {
    name: 'EventDay',
    intlId: 'events.state-event-day',
    color: '#9B59B6'
  },
  [EventCreationStatusType.PostEvent]: {
    name: 'PostEvent',
    intlId: 'events.state-post-event',
    color: '#95A5A6'
  },
  [EventCreationStatusType.Invoiced]: {
    name: 'Invoiced',
    intlId: 'events.state-invoiced',
    color: '#5e84aa'
  },
  [EventCreationStatusType.Canceled]: {
    name: 'Canceled',
    intlId: 'events.state-cancelled',
    color: '#797c86'
  },
  [EventCreationStatusType.OnHold]: {
    name: 'OnHold',
    intlId: 'events.state-on-hold',
    color: '#bb976b'
  },
  [EventCreationStatusType.SyncFailed]: {
    name: 'SyncFailed',
    intlId: 'events.state-sync-failed',
    color: '#E74C3C'
  },
  [EventCreationStatusType.Closed]: {
    name: 'Closed',
    intlId: 'events.state-closed',
    color: '#597EF7'
  }
};

export const REQUEST_ORDER_FIELDS: { [key: string]: string } = {
  isActive: 'eventState',
  name: 'name',
  eventType: 'eventType',
  questionnaire: 'questionnaire.name',
  venue: 'venue.name',
  eventManager: 'eventManager',
  eventDate: 'eventDate',
  deadLine: 'deadLine'
};
