import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TextFieldWrapper = styled(TextField)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  marginLeft: '10px',
  marginTop: '10px',
  marginBottom: '10px',
  '& .MuiFilledInput-root': {
    backgroundColor: theme.palette.secondary.lighter,
    borderRadius: '4px',
    border: `1px solid ${theme.palette.secondary.light}`,
    paddingTop: '10px',
    '&:before': {
      display: 'none'
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.secondary.lighter,
      '&:after': {
        display: 'none'
      }
    }
  }
}));
