import { IntlFormatters } from 'react-intl';
import { Layout } from 'react-grid-layout';
import { v4 as uuidv4 } from 'uuid';
import { IPaginateDashboardParam } from 'modules/dashboard/hooks/useGetDashboardInfinite';
import { IDashboard } from 'store/dashboard/dashboard.interfaces';
import { DASHBOARD_WIDGET_TYPES, ETagData, ITagInfo, ITags, ITagsTranslations, WidgetsDashboardType } from 'modules/dashboard/types';
import { ITableDashboardWidgetColumn, TableDashboardWidgetColumnType } from 'modules/dashboard/types/TableDashboardWidgetType';
import { labelSelector } from 'modules/dashboard/components/widgets/TableDashboardWidget/utils/updateColumnsWithTags';
import { DASHBOARD_LAYOUT_SIZE } from 'modules/dashboard/constants';

/**
 * Updates the specified item in the dashboard data array.
 *
 * @param {IPaginateDashboardParam} dashboardData - The dashboard data to be updated.
 * @param {IDashboard} updatedData - The updated data to replace the item with.
 * @returns {IPaginateDashboardParam} - The updated dashboard data.
 */
export function updateDataInArray(dashboardData: IPaginateDashboardParam, updatedData: IDashboard): IPaginateDashboardParam {
  const updatedPages = dashboardData.pages.map((page) => {
    const updatedPageData = page.data.map((item) => {
      if (item.id === updatedData.id) {
        return updatedData;
      }
      return item;
    });
    return {
      ...page,
      data: updatedPageData
    };
  });

  return {
    ...dashboardData,
    pages: updatedPages
  };
}

/**
 * This function combines widgets and layout into a single object.
 *
 * @param {WidgetsDashboardType} widgets - The widgets to be included in the new object.
 * @param {Layout} layout - The layout to be included in the new object.
 * @returns {object} - The combined object that includes both widgets and layout.
 */
export const combineWidgetsAndLayout = (widgets: WidgetsDashboardType, layout: Layout) => {
  return { ...widgets, layout };
};

/**
 * Creates an array of time range options from the given data.
 *
 * @param {string[] | null | undefined} data - An array of time strings or a falsy value.
 * @returns {Array<{ value: string; label: string }>} An array of objects with `value` and `label` properties.
 */
export const createTimeRangeOptions = (data: string[] | null | undefined) => {
  if (!data) return [];
  return data.map((item) => ({
    value: item || 'all',
    label: item || 'All times'
  }));
};

/**
 * Determines if the given text contains the search text.
 *
 * @param {string} text - The main string to search within.
 * @param {string} searchText - The string to search for.
 * @returns {boolean} True if the `text` contains the `searchText`, false otherwise.
 */

export const containsText = (text: string, searchText: string) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

/**
 * Generates a unique key for the given tag.
 *
 * @param {ITableDashboardWidgetColumn} tag - The tag object containing an id and name.
 * @returns {string} A unique string key based on the tag's id and name.
 */
const getKey = (tag: ITableDashboardWidgetColumn): string => `${tag?.id ?? ''}-${tag?.name}-${tag?.tagType}`;

/**
 * Filters and returns a list of unique tags from the combined list of previous columns and all tags.
 * Duplicates are determined based on the unique key generated for each tag.
 *
 * @param {ITableDashboardWidgetColumn[]} previousColumns - The previous list of tags (columns).
 * @param {ITableDashboardWidgetColumn[]} allTags - The new list of tags (columns) to be checked for uniqueness.
 * @returns {ITableDashboardWidgetColumn[]} A list of unique tags with duplicates removed.
 */
export const filterUniqueTags = (
  previousColumns: ITableDashboardWidgetColumn[],
  allTags: ITableDashboardWidgetColumn[]
): ITableDashboardWidgetColumn[] => {
  const seen = new Set();
  previousColumns.forEach((tag) => seen.add(getKey(tag)));

  return allTags.filter((tag) => {
    const key = getKey(tag);
    if (!seen.has(key)) {
      seen.add(key);
      return true;
    }
    return false;
  });
};

/**
 * Generates tag information object based on input data.
 *
 * @param {Object} tag - The tag object.
 * @param {string} tagData - The tag data.
 * @param {Array<Object>} tagsInfo - An array of tag information objects.
 * @param {Array<Object>} tagsTranslations - An array of tag translation objects.
 * @returns {Object} The generated tag information object.
 */

export const generateInfoTag = (tag: ITagInfo, tagData: ETagData, tagsInfo: ITags[], tagsTranslations: ITagsTranslations[]) => {
  const tagInfoName = tagsInfo.find((tagInfo) => tagInfo.id === tag.articleTagId);
  return {
    id: tag.articleTagId,
    label: (tagInfoName?.name || '') + ' ' + labelSelector(tagData, tagsTranslations),
    name: tagInfoName?.name || '',
    type: TableDashboardWidgetColumnType.Tag,
    tagType: tagData,
    order: 0,
    isCurrency: tagData === ETagData.Revenue
  };
};

const generateDataForWidgetByType = (widgetType: DASHBOARD_WIDGET_TYPES, generateTranslate: IntlFormatters['formatMessage']) => {
  switch (widgetType) {
    case DASHBOARD_WIDGET_TYPES.table:
      return {
        primaryColumnLabel: 'Event',
        primaryColumn: 'eventName',
        primaryColumnIntlId: 'invoices.table-column-event',
        name: generateTranslate({ id: 'dashboard.custom-table' }),
        columns: [
          { label: 'Event', intlId: 'invoices.table-column-event', name: 'eventName', type: 'def', order: 0 },
          { label: 'Event Date', intlId: 'events.event-Date', name: 'eventDate', type: 'def', order: 1 },
          { label: 'Due Date', intlId: 'dashboard-table.due-date', name: 'dueDate', type: 'def', order: 2 },
          { label: 'Venue', intlId: 'settings.venue', name: 'venue', type: 'def', order: 3 },
          { label: 'Event State', intlId: 'dashboard-table.event-state', name: 'eventState', type: 'def', order: 4 },
          { label: 'Event Type', intlId: 'events.event-type', name: 'eventType', type: 'def', order: 5 }
        ]
      };

    case DASHBOARD_WIDGET_TYPES.keyNumbers:
      return {
        keyNumbers: [
          { systemId: uuidv4(), type: 'EventsAvgCountPerVenue' },
          { systemId: uuidv4(), type: 'GuestsTotalCount' },
          { systemId: uuidv4(), type: 'QuestionnaireTotalRevenue' },
          { systemId: uuidv4(), type: 'InvoicesTotalRevenue' },
          { systemId: uuidv4(), type: 'InvoicesTotalCount' }
        ]
      };

    case DASHBOARD_WIDGET_TYPES.chart:
      return { data: {} };

    case DASHBOARD_WIDGET_TYPES.pieChart:
      return { data: {} };

    default:
      throw new Error(`Unsupported widget type: ${widgetType}`);
  }
};

const generateLayoutForWidgetByType = (widgetType: DASHBOARD_WIDGET_TYPES): Omit<Layout, 'i'> => {
  switch (widgetType) {
    case DASHBOARD_WIDGET_TYPES.table:
      return { x: 12, y: Infinity, h: 35, w: Infinity, static: false, isDraggable: true };

    case DASHBOARD_WIDGET_TYPES.keyNumbers:
      return { x: 12, y: Infinity, h: 15, w: Infinity, static: false, isDraggable: true };

    case DASHBOARD_WIDGET_TYPES.chart:
      return { x: 12, y: Infinity, h: 35, w: Infinity, static: false, isDraggable: true };

    case DASHBOARD_WIDGET_TYPES.pieChart:
      return { x: 0, y: Infinity, h: 55, w: 15, static: false, isDraggable: true };

    default:
      return { x: 12, y: Infinity, h: 15, w: Infinity, static: false, isDraggable: true };
  }
};

/**
 * Generates a new dashboard element based on provided widget type and dashboard ID.
 *
 * @param {DASHBOARD_WIDGET_TYPES} widgetType - The type of the dashboard widget.
 * @param {string} activeDashboardId - The ID of the active dashboard.
 *
 * @returns {Object} A new dashboard element object.
 */
export const getNewDashboardElement = (
  widgetType: DASHBOARD_WIDGET_TYPES,
  activeDashboardId: string,
  generateTranslate: IntlFormatters['formatMessage']
) => {
  return {
    layout: generateLayoutForWidgetByType(widgetType),
    widget: {
      groupId: uuidv4(),
      userDashboardId: +activeDashboardId,
      dashboardWidgetTags: [],
      data: generateDataForWidgetByType(widgetType, generateTranslate),
      filters: {
        venues: [],
        eventTypes: [],
        eventStates: [],
        invoiceStates: [],
        eventManagers: [],
        questionnaires: [],
        timeRange: {
          from: null,
          to: null,
          type: 'Custom'
        }
      },
      dashboardWidgetType: widgetType
    }
  };
};

/**
 * generate link for dashboard csv by widget id and token
 * @param widgetId widgetId id
 */
const generateLinkForTableCsvById = (widgetId: number) => {
  return `${import.meta.env.VITE_REACT_APP_API_URL}/dashboard/table/csv/?widgetId=${widgetId}`;
};

/**
 * download csv by widget id
 * @param widgetId widgetId id and token
 */
export const downloadCsvByWidgetId = (widgetId: number) => {
  window.open(generateLinkForTableCsvById(widgetId), '_blank');
};

/**
 * Converts a camelCase string into a spaced string.
 * E.g., "LastWeek" becomes "Last Week", "Next6Months" becomes "Next 6 Months".
 *
 * @param {string} str - The input camelCase string.
 * @returns {string} The formatted string with spaces.
 */
export const convertString = (str: string) => {
  return str.replace(/([A-Z])|(\d+)/g, ' $1$2').trim();
};

export const getDashboardWidgetRowsAmount = (widgetOffsetHeight: number) => {
  const numberOfRows =
    (widgetOffsetHeight + DASHBOARD_LAYOUT_SIZE.rowGap) / (DASHBOARD_LAYOUT_SIZE.rowHeight + DASHBOARD_LAYOUT_SIZE.rowGap);
  return Math.floor(numberOfRows + 2);
};
