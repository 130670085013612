import { AppBar, AppBarProps, Toolbar } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import HeaderContent from './HeaderContent';

interface Props {}

const HeaderDefault: React.FC<Props> = () => {
  const theme = useTheme();

  const appBar: AppBarProps = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      backgroundColor: alpha(theme.palette.background.paper, 0.81),
      backdropFilter: 'blur(15px) saturate(50%) brightness(80%)',
      WebkitBackdropFilter: 'blur(15px) saturate(50%) brightness(80%)',
      boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 0px 1px'
    }
  };

  return (
    <AppBar {...appBar}>
      <Toolbar variant="dense">
        <HeaderContent />
      </Toolbar>
    </AppBar>
  );
};

export default HeaderDefault;
