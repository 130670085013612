import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import Loadable from 'shared/components/Loadable';
import AuthGuard from 'shared/components/route-guard/AuthGuard';
import MainLayout from 'shared/layouts/MainLayout';
import BuilderLayout from 'modules/builder/layouts/BuilderLayout';
import { QUESTIONNAIRES_EVENT_EDIT_ROUTES, QUESTIONNAIRES_EVENT_PLAYGROUND_ROUTES, QUESTIONNAIRES_EVENT_PREVIEW_ROUTES } from './constants';

const EventsPage = Loadable(lazy(() => import('pages/Events')));
const EventsPreviewByQuestionnaire = Loadable(lazy(() => import('pages/Events/EventsPreviewByQuestionnaire')));
const EventPreviewById = Loadable(lazy(() => import('pages/Events/EventPreviewById')));
const EditPageById = Loadable(lazy(() => import('pages/Events/Edit/EditPageById')));
const EventsEditByQuestionnaire = Loadable(lazy(() => import('pages/Events/Edit/EventsEditByQuestionnaire')));
const BuilderPlayground = Loadable(lazy(() => import('modules/questionnaires/containers/PlaygroundEventContainer')));

export const EventsRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/events',
          element: <EventsPage />
        }
      ]
    },
    {
      path: QUESTIONNAIRES_EVENT_PREVIEW_ROUTES.ROOT_LINK,
      element: (
        <AuthGuard>
          <Outlet />
        </AuthGuard>
      ),
      children: [
        {
          path: QUESTIONNAIRES_EVENT_PREVIEW_ROUTES.LINK_BY_QUESTIONNAIRE,
          element: (
            <AuthGuard role={['tenant_admin', 'customer', 'venue_manager', 'event_manager']}>
              <EventsPreviewByQuestionnaire />
            </AuthGuard>
          )
        },
        {
          path: QUESTIONNAIRES_EVENT_PREVIEW_ROUTES.LINK_BY_PAGE_ID,
          element: (
            <AuthGuard role={['tenant_admin', 'customer', 'venue_manager', 'event_manager']}>
              <EventPreviewById />
            </AuthGuard>
          )
        },
        {
          path: QUESTIONNAIRES_EVENT_PREVIEW_ROUTES.LINK_BY_PAGE_ID_AND_SUBPAGE_ID,
          element: (
            <AuthGuard role={['tenant_admin', 'customer', 'venue_manager', 'event_manager']}>
              <EventPreviewById />
            </AuthGuard>
          )
        }
      ]
    },
    {
      path: QUESTIONNAIRES_EVENT_EDIT_ROUTES.ROOT_LINK,
      element: (
        <AuthGuard>
          <BuilderLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: QUESTIONNAIRES_EVENT_EDIT_ROUTES.EDIT_LINK_BY_QUESTIONNAIRE,
          element: (
            <AuthGuard role={['tenant_admin', 'customer', 'venue_manager']}>
              <EventsEditByQuestionnaire />
            </AuthGuard>
          )
        },
        {
          path: QUESTIONNAIRES_EVENT_EDIT_ROUTES.EDIT_LINK_BY_PAGE_ID,
          element: (
            <AuthGuard role={['tenant_admin', 'customer', 'venue_manager']}>
              <EditPageById />
            </AuthGuard>
          )
        },
        {
          path: QUESTIONNAIRES_EVENT_EDIT_ROUTES.EDIT_LINK_BY_PAGE_ID_AND_SUBPAGE_ID,
          element: (
            <AuthGuard role={['tenant_admin', 'customer', 'venue_manager']}>
              <EditPageById />
            </AuthGuard>
          )
        }
      ]
    },
    {
      path: QUESTIONNAIRES_EVENT_PLAYGROUND_ROUTES.ROOT_LINK,
      element: (
        <AuthGuard>
          <Outlet />
        </AuthGuard>
      ),
      children: [
        {
          path: QUESTIONNAIRES_EVENT_PLAYGROUND_ROUTES.LINK_PLAYGROUND_PAGE_ID,
          element: (
            <AuthGuard role={['tenant_admin', 'customer', 'venue_manager']}>
              <BuilderPlayground />
            </AuthGuard>
          )
        },
        {
          path: QUESTIONNAIRES_EVENT_PLAYGROUND_ROUTES.LINK_PLAYGROUND_PAGE_ID_AND_SUBPAGE_ID,
          element: (
            <AuthGuard role={['tenant_admin', 'customer', 'venue_manager']}>
              <BuilderPlayground />
            </AuthGuard>
          )
        }
      ]
    }
  ]
};
