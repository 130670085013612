import { ChangeEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { Form, FormikProvider, useFormik } from 'formik';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DismissCircle20Regular } from '@fluentui/react-icons';
import RemoveDialog from 'shared/components/RemoveDialog';
import ModalSendEmailHeader from 'modules/modalSendEmail/components/ModalSendEmailHeader';
import { EditorType } from 'modules/editor/types/editor';
import { IModalSendEmail } from 'modules/modalSendEmail/types';
import ModalSendEmailActions from './ModalSendEmailActions';
import ModalSendEmailBody from './ModalSendEmailBody';
import { Dialog } from '@mui/material';

interface IDialogSendEmail {
  type: EditorType;
  isOpenDialog: boolean;
  closeDialog: () => void;
  data: IModalSendEmail;
  nameAttachFile: string;
  onSubmit: (data: IModalSendEmail) => void;
  isSendEmail: boolean;
  handleCloseErrorModal: () => void;
  isErrorSendEmail: boolean;
}

const ModalSendEmail = ({
  type,
  data,
  isOpenDialog,
  closeDialog,
  nameAttachFile,
  onSubmit,
  isSendEmail,
  handleCloseErrorModal,
  isErrorSendEmail
}: IDialogSendEmail) => {
  const intl = useIntl();
  const [isSubjectReady, setIsSubjectReady] = useState<boolean>(false);

  const formik = useFormik<IModalSendEmail>({
    initialValues: data,
    onSubmit: async () => {}
  });

  const { resetForm, values, setFieldValue, handleSubmit } = formik;

  useEffect(() => {
    if (!data) return;
    resetForm({ values: data });
  }, [data, resetForm]);

  const handleEditMessage = (newValue: string) => {
    !isSubjectReady && setIsSubjectReady(true);
    setFieldValue('message', newValue);
  };

  const handleChangeSubject = (newValueNumber: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldValue('subject', newValueNumber.target.value);
  };

  const handleOnSubmit = () => {
    onSubmit(values);
  };

  return (
    <Dialog maxWidth="md" open={isOpenDialog} onClose={closeDialog}>
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <ModalSendEmailHeader type={type} onClose={closeDialog} />
            <ModalSendEmailBody
              isSubjectReady={isSubjectReady}
              handleEditMessage={handleEditMessage}
              nameAttachFile={nameAttachFile}
              handleChangeSubject={handleChangeSubject}
              type={type}
            />
            <ModalSendEmailActions isSendEmail={isSendEmail} handleOnSubmit={handleOnSubmit} closeDialog={closeDialog} />
          </Form>
        </LocalizationProvider>
      </FormikProvider>
      <RemoveDialog
        isLoading={false}
        title={intl.formatMessage({ id: 'send-email.error-message-title' })}
        subText={intl.formatMessage({ id: 'send-email.error-message-subtitle' })}
        isOpen={isErrorSendEmail}
        close={handleCloseErrorModal}
        remove={handleCloseErrorModal}
        textButtonRemove={intl.formatMessage({ id: 'view.ok' })}
        colorButtonRemove="primary"
        iconTop={<DismissCircle20Regular className="error" />}
        isShowCancel={false}
      />
    </Dialog>
  );
};

export default ModalSendEmail;
