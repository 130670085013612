import { rolesUser } from 'shared/configs/config';
import { IUser, IUserFormData, UserPostPayload } from 'shared/types/user';

export const prepareUserSendData = (params: IUserFormData): UserPostPayload => {
  const { additionalContacts, email, phoneNumber, role, name, surname, gender, clearIcon } = params;
  const aditionalContactsData = additionalContacts.filter((contact) => contact.email.length || contact.phoneNumber);
  const details = {
    companyName: params.companyName,
    tinNumber: params.tinNumber,
    address: {
      country: params.country,
      state: '',
      city: params.city,
      street: params.street,
      postalCode: params.postalCode
    },
    partner:
      !!params.partnerName && !!params.partnerSurname
        ? {
            name: params.partnerName,
            surname: params.partnerSurname,
            gender: params.partnerGender ?? 'Male'
          }
        : null
  };
  const res = { additionalContacts: aditionalContactsData, email, phoneNumber, role, name, surname, gender, details, clearIcon };
  return res;
};

export const prepareUserDataForForm = (user: IUser | null): Omit<IUserFormData, 'logo'> => {
  return {
    gender: user?.gender ?? 'Male',
    name: user?.name ?? '',
    surname: user?.surname ?? '',
    email: user?.email ?? '',
    phoneNumber: user?.phoneNumber ?? '',
    additionalContacts: user?.additionalContacts ?? [],
    role: user?.role ?? (rolesUser[0].value as IUser['role']),
    partnerGender: user?.details?.partner?.gender ?? 'Male',
    partnerName: user?.details?.partner?.name ?? '',
    partnerSurname: user?.details?.partner?.surname ?? '',
    companyName: user?.details?.companyName ?? '',
    tinNumber: user?.details?.tinNumber ?? '',
    street: user?.details?.address?.street ?? '',
    postalCode: user?.details?.address?.postalCode ?? '',
    city: user?.details?.address?.city ?? '',
    country: user?.details?.address?.country ?? '',
    submit: null,
    clearIcon: false
  };
};
export const prepareUserDataForEventForm = (user: IUser | null): Omit<IUserFormData, 'logo'> => {
  return {
    gender: user?.gender ?? 'Male',
    name: user?.name ?? '',
    surname: user?.surname ?? '',
    email: user?.email ?? '',
    phoneNumber: user?.phoneNumber ?? '',
    additionalContacts: user?.additionalContacts ?? [],
    role: 'customer',
    partnerGender: user?.details?.partner?.gender ?? 'Male',
    partnerName: user?.details?.partner?.name ?? '',
    partnerSurname: user?.details?.partner?.surname ?? '',
    companyName: user?.details?.companyName ?? '',
    tinNumber: user?.details?.tinNumber ?? '',
    street: user?.details?.address?.street ?? '',
    postalCode: user?.details?.address?.postalCode ?? '',
    city: user?.details?.address?.city ?? '',
    country: user?.details?.address?.country ?? '',
    clearIcon: false,
    submit: null
  };
};
