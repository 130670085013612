import { FormattedMessage, useIntl } from 'react-intl';
import { Box, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { IUserFormData } from 'shared/types/user';

const CreateCustomerFormAdditionalPartnerInfo = () => {
  const intl = useIntl();
  const { values, errors, touched, handleBlur, handleChange } = useFormikContext<IUserFormData>();

  return (
    <Box sx={{ p: '0 0 20px 0' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={2}>
          <Stack spacing={1.25}>
            <InputLabel htmlFor="personal-select">
              <FormattedMessage id="users.select" />
            </InputLabel>
            <Select fullWidth name="partnerGender" defaultValue="Male">
              <MenuItem value="Male">
                <FormattedMessage id="users.mr" />
              </MenuItem>
              <MenuItem value="Female">
                <FormattedMessage id="users.ms" />
              </MenuItem>
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Stack spacing={1.25}>
            <InputLabel htmlFor="user-name">
              <FormattedMessage id="events.partner-name" />
            </InputLabel>
            <TextField
              fullWidth
              id="partnerName"
              value={values.partnerName}
              name="partnerName"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'users.name' })}
            />
            {touched.partnerName && errors.partnerName && (
              <FormHelperText error id="user-partnerName-helper">
                {errors.partnerName}
              </FormHelperText>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Stack spacing={1.25}>
            <InputLabel htmlFor="partnerSurname">
              <FormattedMessage id="events.partner-surname" />
            </InputLabel>
            <TextField
              fullWidth
              id="partnerSurname"
              value={values.partnerSurname}
              name="partnerSurname"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'settings.surname' })}
            />
            {touched.partnerSurname && errors.partnerSurname && (
              <FormHelperText error id="user-surname-helper">
                {errors.partnerSurname}
              </FormHelperText>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateCustomerFormAdditionalPartnerInfo;
