import { ETagData, ITagsTranslations } from 'modules/dashboard/types';

export const labelSelector = (tagData: ETagData, tagsTranslations?: ITagsTranslations[]) => {
  const tagFounded = tagsTranslations?.find((tag) => tag.type === tagData);
  switch (tagData) {
    case ETagData.Revenue:
      return tagFounded ? `(${tagFounded.intlId})` : '(Revenue)';
    case ETagData.NumberBookings:
      return tagFounded ? `(${tagFounded.intlId})` : '(Bookings)';
    case ETagData.NumberUnitsSold:
      return tagFounded ? `(${tagFounded.intlId})` : '(Units sold)';
  }
};
