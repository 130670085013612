import { lazy } from 'react';
import Loadable from 'shared/components/Loadable';
import AuthGuard from 'shared/components/route-guard/AuthGuard';
import DashboardLayout from 'shared/layouts/DashboardLayout';

const DashboardAnalytics = Loadable(lazy(() => import('pages/Dashboard/Analytics')));

export const DashboardRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/dashboard',
          element: <DashboardAnalytics />
        }
      ]
    }
  ]
};
