import { Layout } from 'react-grid-layout';
import { useIntl } from 'react-intl';
import { OrganisationPlanInputNumberWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanInputNumberWithReplacementType';
import { labelOrganisationPlan } from 'modules/organisation-plan-preview/utils/labels';
import PreviewWidgetWrapperOrgPlan from 'modules/organisation-plan-preview/components/PreviewWidgetWrapperOrgPlan';
import {
  GeneralLabelTypographyWrapper,
  TypographyValueWrapper
} from 'modules/organisation-plan-preview/components/OrganisationPlanPreview.styles';
import * as Styles from './PreviewNumberInputWidget.styles';

interface IPreviewNumberInputWidgetProps {
  element: OrganisationPlanInputNumberWithReplacementType;
  layoutItem: Layout;
}

const PreviewNumberInputWidget = ({ element, layoutItem }: IPreviewNumberInputWidgetProps) => {
  const intl = useIntl();
  const { replacementLabel, label, isMainLabelHidden, widgetId, sectionId } = element;
  const usedArticle = element?.articles?.find((article) => article?.widgetId === element?.linkedWidgetId)?.article;
  const priceWidget = usedArticle?.price;
  const textValue = labelOrganisationPlan(element, priceWidget);
  const messageError = intl.formatMessage({ id: 'organisation.no-value-provided' });
  const labelAfterCheck = replacementLabel || label || intl.formatMessage({ id: 'organisation.no-label-provided' });
  return (
    <PreviewWidgetWrapperOrgPlan widgetId={widgetId} sectionId={sectionId} layoutItem={layoutItem}>
      {!element?.isMainLabelHidden && (
        <Styles.BoxLabelWrapper>
          <GeneralLabelTypographyWrapper isMainLabelHidden={!!isMainLabelHidden}>{labelAfterCheck}</GeneralLabelTypographyWrapper>
          <TypographyValueWrapper>{textValue.length ? textValue : `: ${messageError}`}</TypographyValueWrapper>
        </Styles.BoxLabelWrapper>
      )}
    </PreviewWidgetWrapperOrgPlan>
  );
};

export default PreviewNumberInputWidget;
