export enum LinkingAction {
  show = 'show',
  hide = 'hide'
}

export interface ILinkSource {
  label?: string | null;
  optionId: string;
  pageId: number;
  subPageId: number | null;
  widgetId: string;
  pageSystemId?: string | null;
  subPageSystemId?: string | null;
}
export interface ILinkTarget {
  pageId?: number;
  subPageId?: number | null;
  widgetId?: string | null;
  pageSystemId: string | null;
  subPageSystemId?: string | null;
}
export interface ILinkRule {
  linkId: string;
  action: LinkingAction;
  source: ILinkSource;
  target: ILinkTarget;
}
