import { isOrganisationPlanWidgetOnly } from 'modules/organisation-plan-templates/utils/isOrganisationPlanWidgetOnly';
import { IOrganisationPlanPreview } from 'shared/types/organisation-plan-preview';
import { IOrganisationPlanSection } from 'modules/organisation-plan-templates/types/organisationPlanSection';

interface IPreparedHeaderOptionsProps {
  organisationPlanWidgetsData?: IOrganisationPlanPreview;
}

/**
 * Util function to get widgets for organisation plan preview layout.
 * @param param.organisationPlanWidgetsData organisation plan preview data.
 * @returns object with prepared widgets data.
 */
export const getWidgetsOrgPlanPreviewLayout = ({ organisationPlanWidgetsData }: IPreparedHeaderOptionsProps) => {
  const widgets = (organisationPlanWidgetsData?.builder?.items || []).map((w) => {
    if (isOrganisationPlanWidgetOnly(w.type)) {
      return {
        ...w,
        ...w.layout,
        noteText: w.noteText,
        widgetId: w.widgetKey
      };
    }
    const isHidden = w.widget?.isHidden ?? w.widget?.layout?.isHidden;
    return {
      ...w.widget?.layout,
      ...w.alternativeLabels,
      isHidden: isHidden ?? false,
      widgetKey: w.widgetKey,
      sectionId: w.sectionId,
      showOnlySelectedAnswer: w.showOnlySelectedAnswer,
      linkedWidgetId: w.widget?.widgetId,
      widgetId: w.widgetKey,
      articles: w.widget?.articles
    };
  });

  return widgets;
};

/**
 * Util function for sorting sections by the 'order' parameter
 * @param sections sections of OrganisationPlan
 * @returns sections
 */
export const sortSectionByOrder = (sections?: IOrganisationPlanSection[] | []): IOrganisationPlanSection[] | [] => {
  if (!sections || !sections?.length) return [];
  return sections?.sort((a, b) => a.order - b.order);
};
