import { FormattedMessage, useIntl } from 'react-intl';
import { DialogTitle, IconButton } from '@mui/material';
import { Dismiss20Regular } from '@fluentui/react-icons';
import { modalSendIntl } from 'modules/modalSendEmail/constants';
import { EditorType } from 'modules/editor/types/editor';

interface IInvoiceModalSendEmailHeader {
  type: EditorType;
  onClose: () => void;
}
const ModalSendEmailHeader = ({ type, onClose }: IInvoiceModalSendEmailHeader) => {
  const intl = useIntl();

  return (
    <DialogTitle>
      <FormattedMessage id={intl.formatMessage({ id: modalSendIntl.header[type] })} />
      <IconButton size="small" onClick={onClose}>
        <Dismiss20Regular />
      </IconButton>
    </DialogTitle>
  );
};

export default ModalSendEmailHeader;
