import { lazy } from 'react';
import Loadable from 'shared/components/Loadable';
import AuthGuard from 'shared/components/route-guard/AuthGuard';
import CommonLayout from 'shared/layouts/CommonLayout';
import { CUSTOMER_QUESTIONNAIRES_ROUTES } from './constants';

const CustomerView = Loadable(lazy(() => import('pages/Questionnaires/CustomerView')));

// ==============================|| Customer Questionnaires ROUTING ||============================== //

export const CustomerQuestionnairesRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <CommonLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: CUSTOMER_QUESTIONNAIRES_ROUTES.customerQuestionnaires,
          element: <CustomerView />
        }
      ]
    }
  ]
};
