import { IInvoiceSendEmail, IPrepareDataToSendEmailInvoice } from 'shared/types/invoice';

interface IGetTextVariable {
  values: IInvoiceSendEmail;
  text: string;
  customUrl?: string;
}

const prepareInvoiceVariables = (values?: IInvoiceSendEmail): { [key: string]: string } => {
  return {
    '[[name]]': values?.recipient?.name ?? '',
    '[[first_name]]': values?.recipient?.name ?? '',
    '[[last_name]]': values?.recipient?.surname ?? '',
    '[[partner_first_name]]': values?.data?.contactPeople?.length ? values?.data?.contactPeople[0]?.name : '',
    '[[partner_name]]': values?.data?.contactPeople ? values?.data?.contactPeople[0]?.name : '',
    '[[partner_last_name]]': values?.data?.contactPeople ? values?.data?.contactPeople[0]?.surname : '',
    '[[go_to_login]]': values?.customUrl ?? '',
    '[[email]]': values?.data?.eventManager?.email ?? '',
    '[[phone_number]]': values?.data?.eventManager?.phoneNumber ?? '',
    '[[invoice_number]]': values?.number ?? '',
    '[[performance_date]]': values?.performanceDate ? values?.performanceDate?.toString() : '',
    '[[invoice_date]]': values?.invoiceDate ?? ''
  };
};

/**
 * function to convert text-template to output text
 * @param values invoice
 * @param text text that needs to be converted
 */
export const getTextVariableInvoice = ({ values, text }: IGetTextVariable): string => {
  const regex = /(\[\[)\w+(\]\])/g;
  return text?.replace(regex, (match) => {
    return prepareInvoiceVariables(values)[match];
  });
};

/**
 * Function to prepare data for Form Send Email
 * @param invoice - Invoice for Form Send Email
 * @param subject - subject of emailTemplate
 * @param template - template of emailTemplate
 */
export const prepareDataToSendEmailInvoice = ({ invoice, subject, template }: IPrepareDataToSendEmailInvoice) => {
  return {
    id: invoice.id,
    number: invoice.number,
    message: getTextVariableInvoice({ values: invoice, text: template }),
    subject: getTextVariableInvoice({ values: invoice, text: subject }),
    recipient: invoice?.recipient,
    data: invoice?.data
  };
};
