import { ArrowLeft20Regular, Eye20Regular } from '@fluentui/react-icons';
import { Button, Stack, Toolbar } from '@mui/material';
import SaveButtonContainer from 'modules/organisation-plan-templates/containers/SaveButtonContainer';
import { FormattedMessage } from 'react-intl';

interface IOrganisationPlanTemplateHeader {
  isPreviewMode?: boolean;
  handlePreviewMode: () => void;
  handleClose: () => void;
}

const OrganisationPlanTemplateHeader = ({ isPreviewMode = false, handlePreviewMode, handleClose }: IOrganisationPlanTemplateHeader) => {
  return (
    <Toolbar disableGutters variant="dense" sx={{ position: 'fixed', top: '0', left: 0, width: '100vw', zIndex: '3' }}>
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        padding="10px"
        borderBottom="1px solid #00000010"
      >
        <Button size="small" variant="outlined" onClick={handleClose}>
          <FormattedMessage id="builder.close" />
        </Button>
        <Button
          size="small"
          id={'preview-mode'}
          variant="contained"
          startIcon={isPreviewMode ? <ArrowLeft20Regular /> : <Eye20Regular />}
          onClick={handlePreviewMode}
        >
          {isPreviewMode ? <FormattedMessage id="builder.back-to-builder" /> : <FormattedMessage id="builder.preview-page" />}
        </Button>

        <SaveButtonContainer isPreviewMode={isPreviewMode} />
      </Stack>
    </Toolbar>
  );
};

export default OrganisationPlanTemplateHeader;
