import { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { IOrganisationPlanSection } from 'modules/organisation-plan-templates/types/organisationPlanSection';
import { addAndOrderLayoutElement } from 'shared/utils/addAndOrderLayoutElement';
import { OrganisationPlanWidgetsType } from 'modules/organisation-plan-templates/types';
import { reorderArray } from 'modules/builder/utils/reorderArray';
import { applyLinkingOnOrgPlanWidgets, applyLinkingRulesOnSections } from 'modules/organisation-plan-preview/utils/linkingDataTransform';
import {
  MultipleChoiceOrganisationPlanWidgetType,
  SingleChoiceOrganisationPlanWidgetType
} from 'modules/organisation-plan-templates/types/organisationPlanChoiceWidgetType';
import {
  MultipleImageChoiceWithReplacementWidgetType,
  SingleImageChoiceWithReplacementWidgetType
} from 'modules/organisation-plan-templates/types/organisationPlanImageWidgetTypes';
import { orgPlanLinksEntityAdapter, sectionsEntityAdapter, usedWidgetsEntityAdapter } from './organisation-plan-template.slice';
import {
  IAddNewOrganisationPlanElementPayload,
  IAddNewOrganisationPlanSectionPayload,
  IApplyOrgPlanLinkingRulesPayload,
  IDeleteOrganisationPlanSectionPayload,
  IDeleteOrganisationPlanWidget,
  IImportAllWidgetPayload,
  IOrganisationPlanTemplateState,
  IReorderSectionsByDndParams,
  IUpdateOrganisationPlanPartiallyLayout,
  IUpdateOrganisationPlanWidgetPayload,
  IUpdateShowOnlySelectedAnswerWidgetPayload
} from './organization-plan-template.types';

export const setManyWidgetsReducer = (
  state: IOrganisationPlanTemplateState,
  { payload: { widgets, sectionId, activeInfo } }: PayloadAction<IImportAllWidgetPayload>
) => {
  const section = sectionsEntityAdapter.getSelectors().selectById(state.sections, sectionId);
  const updateWidgets = widgets.map((el) => {
    const newId = uuidv4();
    return {
      ...el,
      layout: {
        ...el.layout,
        i: newId
      },
      widget: {
        ...el?.widget,
        widgetId: newId
      }
    };
  });
  const widgetLayout = updateWidgets.map((el) => el.layout);
  const newWidgets = updateWidgets.filter((el) => el.widget.widgetId !== activeInfo).map((el) => el.widget);
  if (section) {
    const newLayout = [...section.layout, ...widgetLayout].filter((layout) => layout.i !== '__dropping-elem__' && layout.i !== activeInfo);
    const newSection: IOrganisationPlanSection = { ...section, layout: newLayout };
    sectionsEntityAdapter.updateOne(state.sections, { id: sectionId, changes: newSection });
  }
  const allWidgets = usedWidgetsEntityAdapter.getSelectors().selectAll(state.usedWidgets);
  usedWidgetsEntityAdapter.setAll(state.usedWidgets, [...allWidgets, ...newWidgets]);
  if (activeInfo) usedWidgetsEntityAdapter.removeOne(state.usedWidgets, activeInfo);
};

export const addNewOrganisationPlanElementReducer = (
  state: IOrganisationPlanTemplateState,
  { payload: { layout, widget, linkedWidgetId, sectionId } }: PayloadAction<IAddNewOrganisationPlanElementPayload>
) => {
  const widgetId = uuidv4();
  const section = sectionsEntityAdapter.getSelectors().selectById(state.sections, sectionId);
  if (section) {
    const { layout: updatedLayout } = addAndOrderLayoutElement(section.layout, layout, widgetId);
    const newSection: IOrganisationPlanSection = { ...section, layout: updatedLayout };
    sectionsEntityAdapter.updateOne(state.sections, { id: sectionId, changes: newSection });
  }
  usedWidgetsEntityAdapter.addOne(state.usedWidgets, {
    ...widget,
    widgetId,
    linkedWidgetId,
    sectionId
  } as OrganisationPlanWidgetsType);
};

export const updateOrganisationPlanLayoutPartialReducer = (
  state: IOrganisationPlanTemplateState,
  { payload: { layout, sectionId } }: PayloadAction<IUpdateOrganisationPlanPartiallyLayout>
) => {
  const section = sectionsEntityAdapter.getSelectors().selectById(state.sections, sectionId);
  if (section) {
    const newLayout = section.layout
      .map((layoutItem) => {
        if (layoutItem.i === layout.i) {
          return {
            ...layoutItem,
            ...layout
          };
        }
        return layoutItem;
      })
      .filter((layout) => layout.i !== '__dropping-elem__');

    const newSection: IOrganisationPlanSection = { ...section, layout: newLayout };
    sectionsEntityAdapter.updateOne(state.sections, { id: sectionId, changes: newSection });
  }
};

export const deleteOrganisationPlanSectionReducer = (
  state: IOrganisationPlanTemplateState,
  { payload: { sectionId } }: PayloadAction<IDeleteOrganisationPlanSectionPayload>
) => {
  const sectionForDelete = sectionsEntityAdapter.getSelectors().selectById(state.sections, sectionId);
  const allWidgets = usedWidgetsEntityAdapter.getSelectors().selectAll(state.usedWidgets);
  if (!sectionForDelete) return;
  const layoutIds = sectionForDelete?.layout.map((layout) => layout.i);
  const newWidgets = allWidgets.filter((widget) => !layoutIds.includes(widget.widgetId));
  sectionsEntityAdapter.removeOne(state.sections, sectionId);
  usedWidgetsEntityAdapter.setAll(state.usedWidgets, newWidgets);
};

export const reorderSectionsByDndReducer = (
  state: IOrganisationPlanTemplateState,
  { payload: { indexFrom, indexTo } }: PayloadAction<IReorderSectionsByDndParams>
) => {
  const sections = sectionsEntityAdapter.getSelectors().selectAll(state.sections);
  const newSections = reorderArray(indexFrom, indexTo, sections);
  const newSectionWithCorrectOrderField = newSections.map((section, index) => {
    return {
      ...section,
      order: index + 1
    };
  });
  sectionsEntityAdapter.setAll(state.sections, newSectionWithCorrectOrderField);
};

export const orgPlanApplyLinkingRulesReducer = (
  state: IOrganisationPlanTemplateState,
  { payload: { allQuestionnairesPages } }: PayloadAction<IApplyOrgPlanLinkingRulesPayload>
) => {
  const sections = sectionsEntityAdapter.getSelectors().selectAll(state.sections);
  const allWidgets = usedWidgetsEntityAdapter.getSelectors().selectAll(state.usedWidgets);
  const allLinks = orgPlanLinksEntityAdapter.getSelectors().selectAll(state.links);
  const newSections = applyLinkingRulesOnSections({ allQuestionnairesPages, links: allLinks, sections });
  const newWidgets = applyLinkingOnOrgPlanWidgets({ allQuestionnairesPages, links: allLinks, widgets: allWidgets });
  sectionsEntityAdapter.setAll(state.sections, newSections);
  usedWidgetsEntityAdapter.setAll(state.usedWidgets, newWidgets);
};

export const deleteOrganisationPlanWidgetReducer = (
  state: IOrganisationPlanTemplateState,
  { payload: { widgetId, sectionId } }: PayloadAction<IDeleteOrganisationPlanWidget>
) => {
  const section = sectionsEntityAdapter.getSelectors().selectById(state.sections, sectionId);
  if (section) {
    const newSection: IOrganisationPlanSection = {
      ...section,
      layout: section.layout.filter((layoutItem) => layoutItem.i !== widgetId)
    };
    sectionsEntityAdapter.updateOne(state.sections, { id: sectionId, changes: newSection });
  }
  usedWidgetsEntityAdapter.removeOne(state.usedWidgets, widgetId);
};

export const updateShowOnlySelectedAnswerWidgetReducer = (
  state: IOrganisationPlanTemplateState,
  { payload: { widgetId, widget, showOnlySelectedAnswer } }: PayloadAction<IUpdateShowOnlySelectedAnswerWidgetPayload>
) => {
  const prevWidgetsState = { ...state.usedWidgets.entities[widgetId] };

  const formattedWidget = {
    ...widget,
    showOnlySelectedAnswer
  };

  usedWidgetsEntityAdapter.updateOne(state.usedWidgets, {
    id: widgetId,
    changes: { ...prevWidgetsState, ...formattedWidget } as
      | SingleChoiceOrganisationPlanWidgetType
      | MultipleChoiceOrganisationPlanWidgetType
      | SingleImageChoiceWithReplacementWidgetType
      | MultipleImageChoiceWithReplacementWidgetType
  });
};

export const updateOrganisationPlanWidgetReducer = (
  state: IOrganisationPlanTemplateState,
  { payload: { widgetId, widget } }: PayloadAction<IUpdateOrganisationPlanWidgetPayload>
) => {
  const prevWidgetsState = { ...state.usedWidgets.entities[widgetId] };
  const formattedWidget = {
    ...widget,
    showOnlySelectedAnswer: true
  };

  usedWidgetsEntityAdapter.updateOne(state.usedWidgets, {
    id: widgetId,
    changes: { ...prevWidgetsState, ...formattedWidget } as OrganisationPlanWidgetsType
  });
};

export const addNewOrganisationPlanSectionReducer = (
  state: IOrganisationPlanTemplateState,
  { payload: { defaultSectionName } }: PayloadAction<IAddNewOrganisationPlanSectionPayload>
) => {
  const sectionOrder = sectionsEntityAdapter.getSelectors().selectTotal(state.sections) + 1;
  const section: IOrganisationPlanSection = {
    sectionId: uuidv4(),
    order: sectionOrder,
    layout: [],
    name: `${defaultSectionName} ${sectionOrder}`,
    isHidden: false
  };
  sectionsEntityAdapter.addOne(state.sections, section);
};
