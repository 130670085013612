import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { ISideBarWidget, IUsedArticles, WidgetsType } from 'modules/builder/types';
import { ILinkObjectForSelect } from 'modules/builder/components/SettingsBar/LinkingTab/types';
import { IArticleWidgetPrice, IErrorMessageNumerically } from 'modules/builder/types/articlesPrice';
import { ICategoryPrice } from 'modules/articles/types';
import { DEFAULT_LANGUAGE_SETTINGS, DEFAULT_SYMBOL_SETTINGS } from 'shared/сonstants';
import { IBaseSettings } from 'modules/pricing-sidebar/components/FormatPrice';
import { QuestionnaireTaxType } from 'modules/questionnaires/types';
import { EVisibilityFields, VisibilityObjectType } from 'modules/builder/components/SettingsBar/VisibilityTab/types';
import {
  addLayoutWithWidgetsForPreviewReducer,
  addNewElementReducer,
  checkHasDataChanges,
  deleteWidgetReducer,
  duplicateWidgetReducer,
  setInitialDataReducer,
  setInitialStateReducer,
  updateBuilderLayoutPartiallyReducer,
  updateBuilderLayoutReducer,
  updateChoiceWidgetAnswerReducer,
  updateWidgetReducer
} from './builder.action-creators';
import { IBuilderState, IPriceCustomerBuilder, IUpdateLinkPayload } from './buider.interfaces';

export const usedWidgetsEntityAdapter = createEntityAdapter<WidgetsType>({
  selectId: (widget) => widget.widgetId
});

export const linksEntityAdapter = createEntityAdapter<ILinkObjectForSelect>({
  selectId: (link) => link.linkId
});

// initial state
const initialState: IBuilderState = {
  // TODO: refactor layout to entity adapter
  layout: [],
  usedWidgets: usedWidgetsEntityAdapter.getInitialState({}),
  links: linksEntityAdapter.getInitialState({}),
  pageVisibility: { event: true, request: true },
  activePageIdOnEdit: null,
  aciveWidgetForSettings: null,
  activeQuestionnaireId: null,
  isTopBannerAlreadyExist: false,
  isPageSettingsOpen: false,
  usedArticles: [],
  categories: [],
  isDataChanged: false,
  initialState: {
    widgets: [],
    layout: [],
    links: [],
    articles: [],
    changedFields: []
  },
  priceCustomer: {
    pages: [],
    totalPrice: 0
  },
  draggableItem: null,
  errorAdditionalFields: [],
  isCalculateNeed: false,
  settingsLanguage: {
    code: DEFAULT_LANGUAGE_SETTINGS,
    symbol: DEFAULT_SYMBOL_SETTINGS,
    taxType: QuestionnaireTaxType.Gross
  },
  isTenantExist: true,
  widgetsWithRestrictionMinMaxOptions: []
};

// ==============================|| SLICE - Builder ||============================== //
const builder = createSlice({
  name: 'builder',
  initialState,
  reducers: {
    checkHasDataChanges,
    updateBuilderLayout: updateBuilderLayoutReducer,
    updateBuilderLayoutPartially: updateBuilderLayoutPartiallyReducer,
    setInitialData: setInitialDataReducer,
    addLayoutWithWidgetsForPreview: addLayoutWithWidgetsForPreviewReducer,
    setWidgets(state, { payload }: PayloadAction<WidgetsType[]>) {
      usedWidgetsEntityAdapter.setAll(state.usedWidgets, payload);
    },
    deleteWidget: deleteWidgetReducer,
    setPageVisability(state, { payload }: PayloadAction<VisibilityObjectType>) {
      state.pageVisibility = payload;
    },
    updatePageVisability(state, { payload }: PayloadAction<{ [key in EVisibilityFields]?: boolean }>) {
      state.pageVisibility = { ...state.pageVisibility, ...payload };
    },
    setInitialState: setInitialStateReducer,
    setPermanentLinks(state, action: PayloadAction<ILinkObjectForSelect[]>) {
      state.initialState.links = action.payload;
    },
    addNewElement: addNewElementReducer,
    updateWidget: updateWidgetReducer,
    updateChoiceWidgetAnswer: updateChoiceWidgetAnswerReducer,
    setIsCalculateNeed(state, action: PayloadAction<boolean>) {
      state.isCalculateNeed = action.payload;
    },
    setActivePageIdOnEdit(state, action: PayloadAction<string | null>) {
      state.activePageIdOnEdit = action.payload;
    },
    setDraggableItem(state, action: PayloadAction<ISideBarWidget | null>) {
      state.draggableItem = action.payload;
    },
    setIsTenantExist(state, action: PayloadAction<boolean>) {
      state.isTenantExist = action.payload;
    },
    setIdWidgetForSettings(state, { payload }: PayloadAction<IBuilderState['aciveWidgetForSettings']>) {
      state.isPageSettingsOpen = false;
      state.aciveWidgetForSettings = payload;
      builder.caseReducers.checkHasDataChanges(state, { type: 'checkHasDataChanges', payload: { links: true } });
    },
    duplicateWidget: duplicateWidgetReducer,
    setLinks(state, action: PayloadAction<ILinkObjectForSelect[]>) {
      linksEntityAdapter.setAll(state.links, action.payload);
    },
    addLink(state, action: PayloadAction<Omit<ILinkObjectForSelect, 'linkId'>>) {
      const linkId = uuidv4();
      linksEntityAdapter.addOne(state.links, { ...action.payload, linkId });
      // We do not affect dirty form because the new link does not have a source field
      // link without source is not valid
    },
    updateLink(state, { payload }: PayloadAction<IUpdateLinkPayload>) {
      const prevLinkState = { ...state.usedWidgets.entities[payload.linkId] };
      linksEntityAdapter.updateOne(state.links, { id: payload.linkId, changes: { ...prevLinkState, ...payload.link } });
      builder.caseReducers.checkHasDataChanges(state, { type: 'checkHasDataChanges', payload: { links: true } });
    },
    removeLink(state, { payload }: PayloadAction<ILinkObjectForSelect['linkId']>) {
      linksEntityAdapter.removeOne(state.links, payload);
      builder.caseReducers.checkHasDataChanges(state, { type: 'checkHasDataChanges', payload: { links: true } });
    },
    resetBuilder() {
      return initialState;
    },
    updatePageSettingsFlag(state, { payload }: PayloadAction<boolean>) {
      state.aciveWidgetForSettings = null;
      state.isPageSettingsOpen = payload;
    },
    addUsedArticles(state, { payload }: PayloadAction<IArticleWidgetPrice[]>) {
      state.usedArticles = payload;
    },
    addCategories(state, { payload }: PayloadAction<ICategoryPrice[]>) {
      state.categories = payload;
    },
    setUsedArticles(state, { payload }: PayloadAction<{ usedArticlesNew: IUsedArticles[] }>) {
      state.usedArticles = payload.usedArticlesNew ?? [];
      builder.caseReducers.checkHasDataChanges(state, { type: 'checkHasDataChanges', payload: { articles: true } });
    },
    deleteUsedArticle(state, { payload }: PayloadAction<{ optionId: string }>) {
      state.usedArticles = state.usedArticles.filter((i) => i.optionId !== payload.optionId);
    },
    updatePriceCustomer(state, { payload }: PayloadAction<IPriceCustomerBuilder>) {
      state.priceCustomer = {
        pages: payload.pages,
        totalPrice: payload.totalPrice
      };
    },
    updateErrorAdditionalFields(state, { payload }: PayloadAction<IErrorMessageNumerically[]>) {
      state.errorAdditionalFields = payload;
    },
    addSettings(state, { payload }: PayloadAction<IBaseSettings>) {
      state.settingsLanguage = payload;
    },
    addWidgetWithRestrictionMinMaxOptions(state, { payload }: PayloadAction<string>) {
      if (!state.widgetsWithRestrictionMinMaxOptions.includes(payload)) {
        state.widgetsWithRestrictionMinMaxOptions = [...state.widgetsWithRestrictionMinMaxOptions, payload];
      }
    },
    removeWidgetWithRestrictionMinMaxOptions(state, { payload }: PayloadAction<string>) {
      state.widgetsWithRestrictionMinMaxOptions = state.widgetsWithRestrictionMinMaxOptions.filter((widget) => widget !== payload);
    }
  }
});

export const {
  updateBuilderLayout,
  updateBuilderLayoutPartially,
  addNewElement,
  setWidgets,
  setIsTenantExist,
  updateWidget,
  setActivePageIdOnEdit,
  deleteWidget,
  setIdWidgetForSettings,
  duplicateWidget,
  setDraggableItem,
  setPermanentLinks,
  resetBuilder,
  setInitialData,
  setLinks,
  addLink,
  setInitialState,
  updateLink,
  removeLink,
  updatePageSettingsFlag,
  addUsedArticles,
  addCategories,
  setUsedArticles,
  deleteUsedArticle,
  updatePriceCustomer,
  updateErrorAdditionalFields,
  updateChoiceWidgetAnswer,
  setIsCalculateNeed,
  addLayoutWithWidgetsForPreview,
  addSettings,
  addWidgetWithRestrictionMinMaxOptions,
  removeWidgetWithRestrictionMinMaxOptions,
  setPageVisability,
  updatePageVisability
} = builder.actions;

export default builder.reducer;
