import useGeneralSettings from 'shared/hooks/useGeneralSettings';
import { checkRoleGuard } from 'shared/utils/userRoles';
import { INVOICE_STATE_CHIPS } from 'modules/invoices/constants/allInvoiceTable';
import { InvoiceStatus } from 'shared/types/invoice';
import { EventCreationStatusType } from 'shared/types/event';
import { ERequestStatus } from 'shared/types/requests';
import { REQUEST_STATE_CHIPS } from 'modules/requests/constant/stateRequest';

export const useGetDataByRole = () => {
  const { user: currentUserData } = useGeneralSettings();

  const isEventManager = !checkRoleGuard(['tenant_admin', 'venue_manager'], currentUserData?.role);

  return {
    idUser: currentUserData?.id,
    disableForEventManager: isEventManager,
    invoiceStateChips: INVOICE_STATE_CHIPS,
    invoiceDisabledStatus: [InvoiceStatus.SentPerEmail],
    requestStateChips: REQUEST_STATE_CHIPS,
    requestDisabledStatus: [ERequestStatus.SentPerEmail, ERequestStatus.CreationFailed],
    eventDisabledState: [
      EventCreationStatusType.Ready,
      EventCreationStatusType.Pending,
      EventCreationStatusType.EventDay,
      EventCreationStatusType.PostEvent,
      EventCreationStatusType.SyncFailed,
      EventCreationStatusType.Invoiced
    ]
  };
};
