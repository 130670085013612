/**
 Converts HTML string to plain text by creating an element of the given tag, setting its innerHTML to the provided value,
 and returning its text content. If text content is not available, returns inner text instead.
 @param {string} tag - The tag name of the HTML element to create.
 @param {string} value - The HTML string to convert to plain text.
 @returns {string} - The resulting plain text string.
 */
export const getPlainTextFromHTML = (tag: string, value: string) => {
  const element = document.createElement(tag);
  element.innerHTML = value;
  return element.textContent || element.innerText;
};
