import { useQuery } from 'react-query';
import axios from 'axios';
import { IOrganisationPlanPreview } from 'shared/types/organisation-plan-preview';

const fetchOrganisationPlanWidgetsForPreview = (token: string): Promise<IOrganisationPlanPreview> => {
  return axios.get(`organization-plans/public/builder?token=${token}`).then((res) => {
    return res.data;
  });
};

export function useGetOrganisationPlanWidgetsForPreview(token?: string) {
  return useQuery(
    ['organisation-plan-preview', token],
    () => {
      if (token) return fetchOrganisationPlanWidgetsForPreview(token);
      else return undefined;
    },
    {
      enabled: !!token
    }
  );
}
