import { BUILDER_WIDGET_TYPES, BaseWidget } from './index';

export enum TABLE_COLUMN_TYPES {
  string = 'string',
  number = 'number',
  dateTime = 'dateTime',
  date = 'date',
  time = 'time'
}

type GuidType = string;

export interface ITableWidgetColumnRules {
  type: keyof typeof TABLE_COLUMN_TYPES;
  interactive: boolean;
  columnId: GuidType;
}

export interface ITableWidgetRowRules {
  interactive: boolean;
  rowId: GuidType;
}

export interface IDataColumn {
  columnId: string;
  value: string | null;
}

export interface IDataObject {
  [key: string]: IDataColumn;
}

export interface IDataRow {
  rowId: GuidType;
  data: IDataObject;
}

type ActiveCheckboxType = null | GuidType[];

export interface TableWidgetType extends BaseWidget {
  type: typeof BUILDER_WIDGET_TYPES.table;
  columnSettings: ITableWidgetColumnRules[];
  rowSettings: ITableWidgetRowRules[];
  _activeColumn: ActiveCheckboxType;
  _activeRow: ActiveCheckboxType;
  isAddNewLineBtnShown?: boolean;
  isDragAndDropAllowed?: boolean;
  dataTitles: IDataColumn[];
  dataContent: IDataRow[];
  columnsNumber: number;
  rowsNumber: number;
  label: string;
  value: string;
}
