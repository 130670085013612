import { TableDashboardWidgetColumnType } from 'modules/dashboard/types/TableDashboardWidgetType';
import { TableModalDataTest } from 'modules/dashboard/components/widgets/TableDashboardWidget/components/TableModal/TableModalData';
import { ETagData } from 'modules/dashboard/types';

interface IInitialColumnsData {
  name: string;
  isCurrency: boolean;
}

export interface IInitialColumnsArray {
  name: string;
  columns: IInitialColumnsData[];
}

interface ITransformedColumnData {
  IntlId?: string;
  label: string;
  name: string;
  type: TableDashboardWidgetColumnType;
  tagType: ETagData | null;
  order: number;
  isCurrency: boolean;
}

export interface ITransformedTableData {
  primaryColumnLabel: string;
  primaryColumn: string;
  primaryColumnIntlId?: string;
  columns: ITransformedColumnData[];
}

interface ITransformedDateResult {
  transformedData: ITransformedTableData[];
  initialColumns: ITransformedColumnData[];
}

export const transformDefaultColumnsData = (data: IInitialColumnsArray[], primaryColumn: string): ITransformedDateResult => {
  if (data) {
    const transformedData = data.map((item) => {
      const foundedTable = TableModalDataTest.find(({ primaryColumn }) => primaryColumn === item.name);
      const columns = item.columns.map((column, index: number) => ({
        label: foundedTable?.columns.find(({ name }) => name === column.name)?.label,
        intlId: foundedTable?.columns.find(({ name }) => name === column.name)?.intlId,
        name: column.name,
        type: TableDashboardWidgetColumnType.Def,
        tagType: null,
        order: index + 1,
        isCurrency: column.isCurrency
      }));

      columns.unshift({
        label: foundedTable?.primaryColumnLabel,
        intlId: foundedTable?.primaryColumnIntlId,
        name: foundedTable?.primaryColumn ?? '',
        type: TableDashboardWidgetColumnType.Def,
        tagType: null,
        order: 0,
        isCurrency: false
      });

      return {
        primaryColumnLabel: foundedTable?.primaryColumnLabel || '',
        primaryColumn: foundedTable?.primaryColumn || '',
        primaryColumnIntlId: foundedTable?.primaryColumnIntlId || '',
        columns: columns as ITransformedColumnData[]
      };
    });

    const initialColumns = transformedData.find((type) => type.primaryColumn === primaryColumn)?.columns;

    return { transformedData, initialColumns: initialColumns || [] };
  }

  const transformedData = TableModalDataTest.map((item) => ({
    primaryColumnLabel: item.primaryColumnLabel,
    primaryColumn: item.primaryColumn,
    primaryColumnIntlId: item.primaryColumnIntlId,
    columns: item.columns.map((column, index: number) => ({
      label: column.label,
      intlId: column.intlId,
      name: column.name,
      type: TableDashboardWidgetColumnType.Def,
      tagType: null,
      order: index + 1,
      isCurrency: false
    }))
  }));

  const initialColumns = transformedData.find((type) => type.primaryColumn === primaryColumn)?.columns;

  return { transformedData, initialColumns: initialColumns || [] };
};
