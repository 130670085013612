import { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { Grid, Stack, TextField, useTheme } from '@mui/material';
import * as BaseStyles from 'modules/invoices/components/Invoices.styles';
import EditorClosingText from 'modules/editor/components/EditorClosingText';
import { EditorType, ValuesEditorType } from 'modules/editor/types/editor';
import * as Styles from './EditorFooter.styles';

interface IEditorFooter {
  type: EditorType;
}
const EditorFooter = ({ type }: IEditorFooter) => {
  const intl = useIntl();
  const theme = useTheme();
  const { values, setFieldValue } = useFormikContext<ValuesEditorType>();

  const handleChangeFooterBox = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, indexData: number) => {
    const newFooterTexts =
      values?.settingsTemplate?.footerTexts?.map((item, index) => (index === indexData ? { ...item, text: e.target.value } : item)) ?? [];
    setFieldValue('settingsTemplate', { ...values.settingsTemplate, footerTexts: newFooterTexts });
  };

  return (
    <BaseStyles.WrapperContent>
      <Grid container sx={{ paddingBottom: 10 }}>
        <EditorClosingText type={type} />
        <Styles.Content container spacing={4}>
          <Grid item container spacing={1.25} wrap="nowrap">
            {values?.settingsTemplate?.footerTexts?.map((footer, index) => (
              <Grid key={'footer' + footer?.id + 'ind' + index} item flexGrow={1}>
                <Stack spacing={1.25}>
                  <TextField
                    sx={{ backgroundColor: theme.palette.background.paper }}
                    fullWidth
                    multiline
                    minRows={3}
                    value={footer.text}
                    onChange={(ev) => handleChangeFooterBox(ev, index)}
                    placeholder={intl.formatMessage({ id: 'settings.add-contact-details-here' })}
                  />
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Styles.Content>
      </Grid>
    </BaseStyles.WrapperContent>
  );
};

export default EditorFooter;
