import { Box, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { CalendarStar20Regular, Call20Regular, PersonSupport20Regular, Star20Regular } from '@fluentui/react-icons';
import {
  OrganisersAndGuestsInfoDescription,
  OrganisersAndGuestsInfoItem
} from './OrganisationPlanPreviewOrganisersAndGuestsSection.styles';

interface IOrganisationPlanPreviewGuestsSectionOptionInfoProps {
  type: 'eventName' | 'eventDate' | 'contactPerson' | 'manager';
  content: string;
}

const OrganisationPlanPreviewGuestsSectionOptionInfo = ({ type, content }: IOrganisationPlanPreviewGuestsSectionOptionInfoProps) => {
  const optionByType = {
    eventName: {
      icon: <Star20Regular />
    },
    eventDate: {
      icon: <CalendarStar20Regular />
    },
    contactPerson: {
      icon: <Call20Regular />
    },
    manager: {
      icon: <PersonSupport20Regular />
    }
  };

  const theme = useTheme();

  return (
    <OrganisersAndGuestsInfoItem>
      <Box display="flex" mr={0.75} sx={{ color: theme.palette.primary.main }}>
        {optionByType[type].icon}
      </Box>
      <OrganisersAndGuestsInfoDescription>
        {type !== 'eventDate' ? content : format(new Date(content), 'dd.MM.yyyy')}
      </OrganisersAndGuestsInfoDescription>
    </OrganisersAndGuestsInfoItem>
  );
};

export default OrganisationPlanPreviewGuestsSectionOptionInfo;
