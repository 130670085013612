import { useQuery } from 'react-query';
import axios from 'axios';
import { API_OFFERS_ENDPOINTS, EOffersQueryKeys, IOfferArticleBaseAndOptionalType } from 'shared/types/offer';

const fetchOfferItemsSelected = (id: number): Promise<IOfferArticleBaseAndOptionalType[]> => {
  return axios.get(API_OFFERS_ENDPOINTS.GET_ITEMS_OF_OFFER(id)).then((res) => res.data);
};

export function useGetOfferItemsSelected(offerId?: number) {
  const queryKey = [EOffersQueryKeys.GetOfferItemsSelected, { offerId }];
  return useQuery(queryKey, () => (offerId ? fetchOfferItemsSelected(offerId) : undefined), {
    enabled: typeof offerId === 'number'
  });
}
