import { useInfiniteQuery } from 'react-query';
import { useMemo } from 'react';
import axios from 'axios';

interface Venues {
  id: number;
  name: string;
}

interface IPageParamVenues {
  data: Venues[];
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
}
const fetchVenueFilter = async (page = 1, searchVenue: string) => {
  return axios
    .get(`${import.meta.env.VITE_REACT_APP_API_URL}/dashboards/filters/venues`, {
      params: {
        name: searchVenue,
        pageNumber: page,
        pageSize: 1000
      }
    })
    .then((res) => {
      return res.data;
    });
};

export const useGetVenuesFilter = (searchVenue: string) => {
  const query = useInfiniteQuery({
    queryKey: ['venue', searchVenue],
    queryFn: async ({ pageParam = 1 }) => await fetchVenueFilter(pageParam, searchVenue),
    getNextPageParam: (lastPage: IPageParamVenues, allPages: IPageParamVenues[]) => {
      const MAX_USERS = Math.ceil(lastPage.totalRecords / 10);
      if (allPages.length < MAX_USERS) {
        return allPages.length + 1;
      } else {
        return undefined;
      }
    }
  });

  const data = useMemo(() => {
    return query.data?.pages.map((x) => x.data).flat(1);
  }, [query.data?.pages]);

  return { ...query, data };
};
