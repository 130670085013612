import { useState } from 'react';
import { useIntl } from 'react-intl';
import { dispatch } from 'store';
import { openSnackbar } from 'store/snackbar';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success

function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const intl = useIntl();
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      dispatch(
        openSnackbar({
          open: true,
          message: intl.formatMessage({ id: 'organisation.copied-to-clipboard-successfully' }),
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
}

export default useCopyToClipboard;
