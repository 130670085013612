import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, CardHeader, Divider, Grid, InputLabel, Stack, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { IUserFormData } from 'shared/types/user';

const CustomerCompanyInfoSection = () => {
  const intl = useIntl();
  const inputRef = useRef();
  const { values, handleBlur, handleChange } = useFormikContext<IUserFormData>();

  return (
    <>
      <CardHeader title={intl.formatMessage({ id: 'auth.company' })} sx={{ fontSize: '0.875rem', padding: '0 0 15px 0' }} />
      <Divider />
      <Box sx={{ p: '20px 0' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor="company-name">
                <FormattedMessage id="auth.company-name" />
              </InputLabel>
              <TextField
                fullWidth
                id="company-name"
                value={values.companyName}
                name="companyName"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: 'auth.company-name' })}
                inputRef={inputRef}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor="tin-number">
                <FormattedMessage id="users.tin-number" />
              </InputLabel>
              <TextField
                fullWidth
                id="tin-number"
                value={values.tinNumber}
                name="tinNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: 'users.tin-number' })}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CustomerCompanyInfoSection;
