import { IEvent } from 'shared/types/event';
import { IRequestListItem } from 'shared/types/requests';

export const SET_ISMAXIMIZED_PROFILE = 'SET_ISMAXIMIZED_PROFILE';
export const SET_ISMOBILE = 'SET_ISMOBILE';
export const SET_CURRENT_VIEW = 'SET_CURRENT_VIEW';
export const SET_IS_CUSTOMER_IMAGE_REDUCED = 'SET_IS_CUSTOMER_IMAGE_REDUCED';
export const PROFILE_TYPE = 'PROFILE_TYPE';
export const EVENT_DATA = 'EVENT_DATA';
export const REQUEST_DATA = 'REQUEST_DATA';

export interface SetIsMaximizedProfileAction {
  type: typeof SET_ISMAXIMIZED_PROFILE;
  payload: boolean;
}

export interface SetIsMobileAction {
  type: typeof SET_ISMOBILE;
  payload: boolean;
}

export interface SetCurrentViewAction {
  type: typeof SET_CURRENT_VIEW;
  payload: string;
}

export interface SetIsCustomerImageReducedAction {
  type: typeof SET_IS_CUSTOMER_IMAGE_REDUCED;
  payload: boolean;
}

export interface ProfileType {
  type: typeof PROFILE_TYPE;
  payload: string;
}

export interface EventData {
  type: typeof EVENT_DATA;
  payload: IEvent | null;
}

export interface RequestData {
  type: typeof REQUEST_DATA;
  payload: IRequestListItem | null;
}
export type ProfileActionTypes =
  | SetIsMaximizedProfileAction
  | SetIsMobileAction
  | SetCurrentViewAction
  | SetIsCustomerImageReducedAction
  | ProfileType
  | EventData
  | RequestData;

export interface ProfileState {
  isMobile: boolean;
  isMaximizedProfile: boolean;
  isCustomerImageReduced: boolean;
  currentView: string;
  profileType: string;
  eventData?: IEvent | null;
  requestData?: IRequestListItem | null;
}
