import { FormikErrors } from 'formik';
import { FormikTouched } from 'formik/dist/types';
import { IImgData } from 'shared/components/LogoPicture/PreviewImg';
import { IQuestionnaireArticles } from 'shared/types/questionnaire-article';
import { IVenue } from 'shared/types/venue';
import { IGuestAndRoomsPageFormDataInstance } from 'modules/builder/types';
import { IBasePricing } from 'modules/pricing-sidebar/components/BasePricing';

export enum ArticlesQueryKeys {
  Articles = 'master-articles',
  Tags = 'article-tags'
}

export const API_ARTICLES_ENDPOINTS = {
  GET_ARTICLES: `${import.meta.env.VITE_REACT_APP_API_URL}/master-articles`,
  GET_TAGS: `${import.meta.env.VITE_REACT_APP_API_URL}/article-tags`
};

export enum ArticlePriceType {
  Numerically = 'Numerically',
  onRequest = 'OnRequest',
  FlatRate = 'FlatRate'
}

export enum ArticlePriceNumericallyType {
  EachFurther = 'EachFurther',
  All = 'All'
}
export enum ArticleLevelType {
  MasterArticle = 'MasterArticle',
  QuestionnaireArticle = 'QuestionnaireArticle',
  EventArticle = 'EventArticle',
  RequestArticle = 'RequestArticle'
}

export type IIdName = {
  id: number;
  name: string;
};
export interface INameId {
  id?: number;
  name: string;
}

export type IArticleActive = {
  id: number;
  isActivated: boolean;
  tenantId: number;
};

interface ICategory {
  id: string;
  isCheck: boolean;
  name: string;
  count?: number | null;
  widget?: {
    id: string;
    isCheck: boolean;
    label: string;
  };
}

export interface ICategoriesReq {
  categories: Array<ICategory>;
  questionnaireId: number;
}

export type ICategoryPrice = {
  id: string;
  name: string;
  pricePerName: number;
  count?: number | null;
};

export interface IPriceRangeInfoSave {
  amount: number;
  amountPrice: string;
}

export interface IPriceDynamic {
  amount: number | string;
  amountPrice: string;
}

export interface IGuestCategory {
  checked: boolean;
  id: number;
  name: string;
  questionnaireId: number;
  systemId: string;
  count: number | null;
}
export enum ETaxType {
  Legal = 'Legal',
  Individual = 'Individual',
  Custom = 'Custom'
}

export type IPrice = {
  articleName?: string;
  basePrice: number;
  netto: number;
  brutto: number;
  tax: number;
  taxType: ETaxType;
  unit: string;
  articlePriceType: ArticlePriceType;
  flatRateInfo: {
    id?: number;
    priceId?: number;
    isTotalPrice: boolean;
    pricePerGuest: number;
    categories?: ICategoryPrice[];
    linkedGuestCategories?: string[] | [];
    secondUnit?: string;
  };
  guestCategories?: IGuestCategory[];
  numericallyInfo: {
    minValue: number;
    maxValue: number;
    basePrice: number;
    portionStep: number;
    userChoice: number;
    priceRangeInfo?: IPriceRangeInfoSave[];
    numericallyInfoType: ArticlePriceNumericallyType;
    secondUnit: string;
    userInput?: number;
    isMinBlock?: boolean;
    isMaxBlock?: boolean;
  };
  onRequestInfo: {
    onRequestPrice: string;
    id?: number;
    useIntegerField?: boolean;
    priceId?: number;
  };
  categoryPrices: string[] | [];
  selectCategoryPrices?: string[] | [];
  currentCalculatedPrice: number;
  discount?:
    | {
        isPersentage: boolean;
        amount: number;
        startPeriod: Date | string;
        endPeriod: Date | string;
      }
    | {};
};

type IPricePerGuestSend = {
  id?: number;
  priceId?: number;
  isTotalPrice: boolean;
  pricePerGuest: number;
  linkedGuestCategories?: string[];
  secondUnit: string;
};

type IPriceSendData = {
  articleName?: string;
  basePrice: number;
  netto: number;
  brutto: number;
  tax: number;
  taxType: ETaxType;
  unit: string;
  articlePriceType: string;
  flatRateInfo?: IPricePerGuestSend;
  numericallyInfo?: {
    minValue?: number | undefined | string;
    maxValue?: number | undefined | string;
    basePrice: number;
    portionStep: number;
    userChoice: number;
    priceRangeInfo?: {
      amount: number;
      amountPrice: string;
    }[];
    numericallyInfoType: ArticlePriceNumericallyType;
    secondUnit: string;
    userInput?: number;
    isMinBlock?: boolean;
    isMaxBlock?: boolean;
  };
  onRequestInfo?: IPrice['onRequestInfo'];
  currentCalculatedPrice: number;
  discount?:
    | {
        isPersentage: boolean;
        amount: number;
        startPeriod: Date | string;
        endPeriod: Date | string;
      }
    | {};
};

export interface IPhotosLibrary {
  url: string;
  fileUrl: string;
  id: number;
  type: string;
}

export type IArticle = {
  id?: number;
  number?: string;
  photos?: Array<IPhotosLibrary>;
  photoIdsForDelete?: IArticleFormData['photoIdsForDelete'];
  isActive: boolean;
  isActivated?: boolean;
  name: string;
  articlenumber: number;
  articleType: string;
  shortDescription: string;
  longDescription: string;
  tags: IIdName[];
  TagNames?: IIdName[] | [];
  NewPhotos?: IPhotosLibrary[] | [];
  venues: IVenue[];
  fields: [{ [key: string]: string | number }] | [];
  price: IPrice;
  restrictedVenues?: IVenue[];
  articleMasterId?: number;
  applyChangesToQuestionnaireArticles?: number[];
  applyChangesToEventArticles?: number[];
  questionnaireArticles?: IQuestionnaireArticles[];
};

export interface IArticleForOrgPlanPreview {
  article?: IArticle;
  widgetOptionId?: string;
}

export type IArticleSendData = {
  id?: number | string;
  name: string;
  number: string;
  articlenumber: number;
  shortDescription: string;
  longDescription: string;
  isActivated: boolean;
  articleType: string;
  photos?: IArticleFormData['photos'];
  photoIdsForDelete?: IArticleFormData['photoIdsForDelete'];
  price: IPriceSendData;
  NewPhotos?: IPhotosLibrary[] | [];
  TagNames?: string[] | Array<{ name: string }> | [];
  tags?: INameId[] | [];
  restrictedVenues: { id: number }[];
  applyChangesToQuestionnaireArticles?: number[];
  applyChangesToEventArticles?: number[];
  articleMasterId?: number;
  tenantId?: number;
  articleLevel?: ArticleLevelType;
  guestCategories?: IGuestCategory[];
};

export type IBasePricingData = {
  articleName?: string;
  netto: number | string;
  brutto: number | string;
  tax: number | string;
  taxType: ETaxType;
};

export type IArticleActiveOL = {
  masterArticleId: number;
  active: boolean;
  tenantId: number;
};

export interface IArticleFormData {
  articleName?: string;
  id?: string | number;
  number: string;
  photos?: Array<IPhotosLibrary> | [];
  photoIdsForDelete?: Array<number> | [];
  isActive: boolean;
  name: string;
  articlenumber: number;
  articletype: string;
  shortdescription: string;
  longdescription: string;
  tags: INameId[];
  venues: IVenue[] | [];
  fields: [{ [key: string]: string | number }] | [];
  price: number;
  netto: number | string;
  brutto: number | string;
  tax?: number | string;
  taxType: ETaxType;
  flatrate?: boolean;
  flatratepriceperguest: number | undefined;
  articlePriceType: IPrice['articlePriceType'];
  categoryPrices: string[] | [];
  categories?: ICategoryPrice[];
  unit: string;
  secondunit: string;
  numericallyInfoType?: ArticlePriceNumericallyType;
  numericallyUserChoice?: number | string;
  numericallyMinValue?: number | string;
  numericallyMaxValue?: number | string;
  numericallyBasePrice?: number | string;
  numericallyPortionPrice?: number | string;
  numericallyPriceRangeInfo?: IPriceDynamic[];
  isMinBlock?: boolean;
  isMaxBlock?: boolean;
  numericallyUserInput?: number | undefined;
  onRequest?: string;
  onRequestUseIntegerField?: boolean;
  onRequestUseIntegerFieldDisable?: boolean;
  applyChangesToQuestionnaireArticles?: number[];
  applyChangesToEventArticles?: number[];
  articleMasterId?: number;
  articleLevel?: ArticleLevelType;
  selectBuilderWidgetId?: string;
  guestCategories?: IGuestCategory[];
  logo?: undefined;
  newTags?: string;
  articlevariant?: ArticlesType;
  submit: null;
}

export interface IArticleFormDataAll {
  articleName?: string;
  id?: string | number | undefined;
  photos?: Array<IPhotosLibrary> | [];
  photoIdsForDelete?: Array<number> | [];
  isActive: boolean;
  name: string;
  articlenumber: number;
  articletype: string;
  shortdescription: string;
  longdescription: string;
  tags: IIdName[] | INameId[];
  venues?: IVenue[] | [];
  newVenues?: string[];
  fields: [{ [key: string]: string | number }] | [];
  price: number;
  netto: number | string;
  brutto: number | string;
  tax: number;
  taxType?: ETaxType;
  flatrate?: boolean;
  flatratepriceperguest: number | undefined;
  articlePriceType: IPrice['articlePriceType'];
  categoryPrices: string[] | [];
  categories?: ICategoryPrice[];
  unit: string;
  secondunit: string;
  numericallyInfoType?: ArticlePriceNumericallyType;
  numericallyUserChoice?: number | string;
  numericallyMinValue?: number | string;
  numericallyMaxValue?: number | string;
  numericallyBasePrice?: number | string;
  numericallyPortionPrice?: number | string;
  numericallyPriceRangeInfo?: IPriceDynamic[];
  isMinBlock?: boolean;
  isMaxBlock?: boolean;
  numericallyUserInput?: number | undefined;
  onRequest?: string;
  applyChangesToQuestionnaireArticles?: number[];
  applyChangesToEventArticles?: number[];
  articleMasterId?: number;
  articleLevel?: ArticleLevelType;
  selectBuilderWidgetId?: string;
  submit: null;
  logo: IImgData | File | Array<IImgData | File> | [] | undefined;
  newTags?: string[] | string;
  articlevariant: string;
}

export interface INewArticleBuildFormData {
  articleName?: string;
  id?: string | number;
  isActive: boolean;
  name: string;
  number: string;
  articlenumber: number;
  articletype: string;
  price: number;
  netto: number | string;
  brutto: number | string;
  tax: number;
  taxType: ETaxType;
  flatrate?: boolean;
  flatratepriceperguest: number | undefined;
  articlePriceType: IPrice['articlePriceType'];
  categoryPrices: string[] | [];
  categories?: ICategoryPrice[];
  unit: string;
  secondunit: string;
  numericallyInfoType?: ArticlePriceNumericallyType;
  numericallyUserChoice?: number | string;
  numericallyMinValue?: number | string;
  numericallyMaxValue?: number | string;
  numericallyBasePrice?: number | string;
  numericallyPortionPrice?: number | string;
  numericallyPriceRangeInfo?: IPriceDynamic[];
  isMinBlock?: boolean;
  isMaxBlock?: boolean;
  onRequest?: string;
  numericallyUserInput?: number | undefined;
  applyChangesToQuestionnaireArticles?: number[];
  applyChangesToEventArticles?: number[];
  articleMasterId?: number;
  shortdescription: string;
  longdescription: string;
  tags: IIdName[];
  venues: IVenue[] | [];
  fields: [{ [key: string]: string | number }] | [];
  submit: null;
}

export type ArticlePostPayload = Omit<IArticleSendData, 'id'>;

export type ISaveNettoBrutto = {
  netto: string | number;
  brutto: string | number;
  tax: number | string;
  taxType?: ETaxType;
};

export type IArticleTabs = {
  values: IArticleFormData;
  sendValue: (name: string, val: string | number | boolean, category?: string[] | []) => void;
  handleTab: (name: string) => void;
  hideCategoryFlatRate?: boolean;
  saveBruttoNetto: (data: ISaveNettoBrutto) => void;
  tenantTaxSettings: IBasePricing['tenantTaxSettings'];
  tags?: INameId[];
  onHandleSetTags?: (data: INameId[]) => void;
};

export type IArticleTabsNumerically = {
  values: IArticleFormData;
  sendValue: (name: string, val: string | number | boolean, category?: string[] | []) => void;
  handleTab: (name: string) => void;
  hideCategoryFlatRate?: boolean;
  formikData: IGuestAndRoomsPageFormDataInstance['selectUsedArticleWidget'];
  saveBruttoNetto: (data: ISaveNettoBrutto) => void;
  errors: FormikErrors<IArticleFormData>;
  touched: FormikTouched<IArticleFormData>;
  tenantTaxSettings: IBasePricing['tenantTaxSettings'];
  tags?: INameId[];
  onHandleSetTags?: (data: INameId[]) => void;
};

export type IArticleTabsInitial = {
  values: IArticleFormData;
  sendValue: (name: string, val: string | number | boolean, category?: string[] | []) => void;
  handleTab: (name: string) => void;
  hideCategoryFlatRate?: boolean;
  formikData?: IGuestAndRoomsPageFormDataInstance['selectUsedArticleWidget'] | undefined;
  errors?: FormikErrors<IArticleFormData>;
  touched?: FormikTouched<IArticleFormData>;
};

export type IArticleTabsFlatRate = {
  values: IArticleFormData;
  sendValue: (name: string, val: string | number | boolean, category?: string[] | []) => void;
  handleTab: (name: string) => void;
  hideCategoryFlatRate?: boolean;
  isPricePerGuest?: boolean;
  saveBruttoNetto: (data: ISaveNettoBrutto) => void;
  errors: FormikErrors<IArticleFormData>;
  touched: FormikTouched<IArticleFormData>;
  currencyCode?: string;
  tenantTaxSettings: IBasePricing['tenantTaxSettings'];
  tags?: INameId[];
  onHandleSetTags?: (data: INameId[]) => void;
};

export type IArticleRemove = {
  isOpen: boolean;
  article: IArticleActive | null;
};
interface Article {
  id: number;
  name: string;
  type: string;
}

export interface IVariant {
  id: number;
  name: string;
  type: string;
  articles: Article[];
}

export interface IModifiedVariant {
  id: number;
  name: string;
  type: string;
  articleId?: string;
  articleName: string;
  articleType: string;
}

export enum ArticlesType {
  QuestionnaireArticle = 'QuestionnaireArticle',
  EventArticle = 'EventArticle',
  RequestArticle = 'RequestArticle',
  MasterArticle = 'MasterArticle',
  MainVariant = 'MainVariant'
}
