import useGeneralSettings from 'shared/hooks/useGeneralSettings';

type RGBColor = { r: number; g: number; b: number };

// Utility function to convert a hex color to its RGB components
const hexToRgb = (hex: string): RGBColor => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return { r, g, b };
};

// Utility function to convert RGB components to a hex color
const rgbToHex = (r: number, g: number, b: number): string => `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;

// Function to blend two colors
const blend = (color1: RGBColor, color2: RGBColor, ratio: number): RGBColor => {
  const r = Math.round(color1.r * (1 - ratio) + color2.r * ratio);
  const g = Math.round(color1.g * (1 - ratio) + color2.g * ratio);
  const b = Math.round(color1.b * (1 - ratio) + color2.b * ratio);
  return { r, g, b };
};

export const generatePalette = (mainColor: string, endColor: string): string[] => {
  const mainRgb = hexToRgb(mainColor);
  const endRgb = hexToRgb(endColor);
  const palette: string[] = [];

  for (let i = 0; i < 10; i++) {
    const ratio = i / 9; // Adjusted to start with 0/9 for the main color
    const blendedColor = blend(mainRgb, endRgb, ratio);
    palette.push(rgbToHex(blendedColor.r, blendedColor.g, blendedColor.b));
  }

  return palette;
};

export const ColorPaletteLight = () => {
  const customerSettings = useGeneralSettings();
  const mainColor = customerSettings.loginViewSetting?.loginAccentColor || '';
  const currentColorSettings = generatePalette(mainColor, '#ffffff');
  localStorage.setItem('customColorsLight', JSON.stringify(currentColorSettings));
  return currentColorSettings;
};

export const ColorPaletteDark = () => {
  const customerSettings = useGeneralSettings();
  const mainColor = customerSettings.loginViewSetting?.loginAccentColor || '';
  const currentColorSettings = generatePalette(mainColor, '#000000');
  localStorage.setItem('customColorsDark', JSON.stringify(currentColorSettings));
  return currentColorSettings;
};
