/// <reference types="vite-plugin-svgr/client" />
import { useEffect, useRef, useState } from 'react';
import { Delete16Regular } from '@fluentui/react-icons';
import { useDispatch } from 'store';
import { deleteOrganisationPlanWidget } from 'store/organisation-plan-template/organisation-plan-template.slice';
import { OrganisationPlanDividerWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanDividerWidgetType';
import DividerArrow from './assets/divider-arrow.svg?react';
import * as Styles from './OrganisationPlanDividerWidget.styles';

interface IOrganisationPlanDividerWidgetProps {
  element: OrganisationPlanDividerWidgetType;
  isPreview: boolean;
}

const OrganisationPlanDividerWidget = ({ element, isPreview }: IOrganisationPlanDividerWidgetProps) => {
  const [iconSize, setIconSize] = useState(0);
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref?.current?.clientHeight) {
      setIconSize(ref?.current?.clientHeight);
    }
  }, [ref?.current?.clientHeight]);

  const deleteDividerWidgetHandler = () => {
    dispatch(deleteOrganisationPlanWidget({ widgetId: element.widgetId, sectionId: element.sectionId }));
  };

  return (
    <Styles.BoxWrapper isPreview={isPreview} ref={ref}>
      <Styles.DividerArrowIcon iconSize={iconSize}>
        <DividerArrow />
      </Styles.DividerArrowIcon>
      <Styles.DeleteIcon size="small" iconSize={iconSize} onClick={deleteDividerWidgetHandler}>
        <Delete16Regular />
      </Styles.DeleteIcon>
    </Styles.BoxWrapper>
  );
};

export default OrganisationPlanDividerWidget;
