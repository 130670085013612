import { useCallback, useRef } from 'react';
import { InfiniteQueryObserverBaseResult } from 'react-query/types/core/types';

interface IUseSelectorLastOptionElementRef {
  fetchNextPage: InfiniteQueryObserverBaseResult['fetchNextPage'];
  hasNextPage: InfiniteQueryObserverBaseResult['hasNextPage'];
}

/* Hook to find last option element in the List of options in Selector / Autocomplete.
 * This hook uses for loading  data  of  the next page from backend when user sees last element.
 */
export default function useLastOptionElementRef({ fetchNextPage, hasNextPage }: IUseSelectorLastOptionElementRef) {
  const observer = useRef<IntersectionObserver | null>(null);
  const lastOptionElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (observer.current) {
        observer.current.disconnect();
        observer.current = null;
      }

      if (node && hasNextPage && fetchNextPage) {
        observer.current = new IntersectionObserver(async (entries) => {
          if (entries[0].isIntersecting) {
            fetchNextPage();
          }
        });

        observer.current.observe(node);
      }
    },
    [fetchNextPage, hasNextPage]
  );
  return { lastOptionElementRef: lastOptionElementRef };
}
