import { FormattedMessage } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from '@mui/material';

type StyleButton = 'error' | 'warning' | 'success' | 'primary';

interface IRemoveDialogProps {
  isOpen: boolean;
  isLoading: boolean;
  title: string;
  subText?: string | React.ReactNode;
  textButtonRemove?: string;
  iconTop?: React.ReactNode;
  colorButtonRemove?: StyleButton;
  close: () => void;
  remove: (eventId: number | undefined) => void;
  eventId?: number;
  isSubmit?: boolean;
  isShowCancel?: boolean;
}

export default function RemoveDialog({
  isLoading,
  isOpen,
  close,
  subText,
  title,
  textButtonRemove,
  iconTop,
  colorButtonRemove,
  remove,
  eventId,
  isSubmit,
  isShowCancel = true
}: IRemoveDialogProps) {
  const theme = useTheme();
  return (
    <Dialog maxWidth="xs" open={isOpen} onClose={close} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {subText && (
        <DialogContent dividers sx={{ padding: '25px', textAlign: 'left' }}>
          <DialogContentText id="alert-dialog-description">{subText}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <>
          {isShowCancel && (
            <Button variant="outlined" onClick={close} disabled={isLoading}>
              <FormattedMessage id="users.cancel" />
            </Button>
          )}
        </>
        <Button
          type={isSubmit ? 'submit' : 'button'}
          color={colorButtonRemove ? colorButtonRemove : 'error'}
          variant="contained"
          onClick={() => remove(eventId)}
          disabled={isLoading}
        >
          {textButtonRemove ? textButtonRemove : <FormattedMessage id="users.delete" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
