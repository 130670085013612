import { useMemo } from 'react';
import { Layout } from 'react-grid-layout';
import { Box, Checkbox, Radio } from '@mui/material';
import { getStorageFileUrl } from 'shared/utils/getStorageFileUrl';
import { useSelector } from 'store';
import { selectIsEvent } from 'store/organisation-plan-template/organisation-plan-template.selectors';
import { IOrganisationPlanChoiceOptionReplacementLabels } from 'modules/organisation-plan-templates/types/organisationPlanChoiceWidgetType';
import { SingleImageChoiceWithReplacementWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanImageWidgetTypes';
import { labelOrganisationPlan } from 'modules/organisation-plan-preview/utils/labels';
import { renderInputLabel } from 'modules/organisation-plan-preview/utils/renderInputLabelHandler';
import PreviewRenderOption from 'modules/organisation-plan-preview/components/PreviewRenderOption';
import PreviewWidgetWrapperOrgPlan from 'modules/organisation-plan-preview/components/PreviewWidgetWrapperOrgPlan';
import * as Styles from 'modules/organisation-plan-preview/components/OrganisationPlanPreviewWidgetSection/preview-widgets/PreviewImageChoiceWidget/PreviewImageChoiceWidget.styles';

interface IPreviewImageChoiceWidgetProps {
  element: SingleImageChoiceWithReplacementWidgetType;
  layoutItem: Layout;
  isCheckbox: boolean;
}

const PreviewCheckBoxImage = ({ element, isCheckbox, layoutItem }: IPreviewImageChoiceWidgetProps) => {
  const NoImage = '/assets/images/builder/noimage.png';
  const {
    label,
    replacementLabel,
    selectedOption,
    showOnlySelectedAnswer,
    replacementOptionsLabels,
    dataImage,
    isMainLabelHidden,
    widgetId,
    sectionId
  } = element;
  const isEvent = useSelector(selectIsEvent);

  const renderOptionsHandler = useMemo(() => {
    const checkedOptions = Array.isArray(selectedOption) ? new Set(selectedOption) : new Set([selectedOption]);
    const renderController = (id: string) => {
      if (isCheckbox) {
        return <Checkbox checked={checkedOptions.has(id)} />;
      }
      return <Radio disabled={true} checked={checkedOptions.has(id)} name="radio-buttons" />;
    };

    const isShowOnlySelectedAnswer = isEvent ? showOnlySelectedAnswer : false;
    let renderOptions: IOrganisationPlanChoiceOptionReplacementLabels[] | undefined = [];
    if (Array.isArray(replacementOptionsLabels)) {
      if (isShowOnlySelectedAnswer) {
        renderOptions = replacementOptionsLabels?.filter((labelObj: { id: string }) => checkedOptions.has(labelObj.id));
      } else {
        renderOptions = [...replacementOptionsLabels];
      }
    } else {
      const useRenderOptions = element?.dataImage!.find(
        (option) => option.id === element.selectedOption
      ) as IOrganisationPlanChoiceOptionReplacementLabels;
      renderOptions = isShowOnlySelectedAnswer ? [useRenderOptions] : (dataImage as IOrganisationPlanChoiceOptionReplacementLabels[]);
    }
    const allImagesHidden = renderOptions.every((option) => option.isImageHidden);
    const allLabelHidden = renderOptions.every((option) => option.isLabelHidden);

    if (allImagesHidden && allLabelHidden) {
      return null;
    }
    if (allImagesHidden) {
      return renderOptions.map((option, index) => {
        return <Box key={index}>{renderInputLabel(option.replacementLabel, option.labelImage)}</Box>;
      });
    }
    const allOptions = renderOptions?.map((option) => {
      const articleOption = element?.articles?.find((article) => article.widgetOptionId === option?.id);
      const priceOption = articleOption?.article?.price;

      const textValue = labelOrganisationPlan(option, priceOption);

      return (
        <Styles.BoxImageWrapper
          sx={{ position: 'relative', display: option.isLabelHidden && option.isImageHidden ? 'none' : 'block' }}
          key={option.id}
          isOptionSelected={checkedOptions.has(option.id)}
        >
          <Styles.BoxDashedImageContainer sx={{ opacity: option.isImageHidden ? '0' : '1' }}>
            {option?.data?.length ? (
              <img
                style={{ objectFit: 'cover' }}
                src={getStorageFileUrl(option?.data[0]?.fileUrl, option?.data[0]?.type)}
                alt={`image_${option.id}`}
              />
            ) : (
              <img src={NoImage} alt={`no_image_${option.id}`} />
            )}
          </Styles.BoxDashedImageContainer>
          <Styles.BoxImageLabelContainer>
            <Box sx={{ position: option.isImageHidden ? 'absolute' : 'unset', top: 0 }}>
              <Styles.ImageLabelValueTypography sx={{ opacity: option.isLabelHidden ? '0' : '1' }} m={2}>
                {renderInputLabel(option.replacementLabel, option.labelImage)}
                {textValue}
              </Styles.ImageLabelValueTypography>
            </Box>

            <Styles.BoxImageLabelController>{renderController(option.id)}</Styles.BoxImageLabelController>
          </Styles.BoxImageLabelContainer>
        </Styles.BoxImageWrapper>
      );
    });

    return <Styles.BoxImagesWrapper sx={{ display: allImagesHidden ? 'block' : 'flex' }}>{allOptions}</Styles.BoxImagesWrapper>;
  }, [
    dataImage,
    replacementOptionsLabels,
    showOnlySelectedAnswer,
    isEvent,
    element?.articles,
    element?.dataImage,
    element.selectedOption,
    isCheckbox,
    selectedOption
  ]);

  return (
    <PreviewWidgetWrapperOrgPlan widgetId={widgetId} sectionId={sectionId} layoutItem={layoutItem}>
      <PreviewRenderOption
        element={renderOptionsHandler}
        label={renderInputLabel(replacementLabel, label)}
        isMainLabelHidden={!!isMainLabelHidden}
      />
    </PreviewWidgetWrapperOrgPlan>
  );
};

export default PreviewCheckBoxImage;
