import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { Theme, ThemeOptions, ThemeProvider, TypographyVariantsOptions, createTheme } from '@mui/material/styles';
import { ReactNode, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import useConfig from 'shared/hooks/useConfig';
import useGeneralSettings from 'shared/hooks/useGeneralSettings';
import { CustomShadowProps } from 'shared/themes/types';
import { checkLoginPage } from 'shared/utils/login';
import { generatePalette } from './customColorPalette';
import componentsOverride from './overrides/components';
import Palette from './palette';
import CustomShadows from './shadows';
import Typography from './typography';

type ThemeCustomizationProps = {
  children: ReactNode;
};

export default function ThemeCustomization({ children }: ThemeCustomizationProps) {
  const { themeDirection, resolvedMode, presetColor, fontFamily, modeForPlayground } = useConfig();
  const location = useLocation();
  const customerSettings = useGeneralSettings();

  useEffect(() => {
    const mainColor = customerSettings.loginViewSetting?.loginAccentColor || '';
    const lightColorSettings = generatePalette(mainColor, '#ffffff');
    const darkColorSettings = generatePalette(mainColor, '#000000');

    localStorage.setItem('customColorsLight', JSON.stringify(lightColorSettings));
    localStorage.setItem('customColorsDark', JSON.stringify(darkColorSettings));
  }, [customerSettings.loginViewSetting?.loginAccentColor]);

  const themeMode = useMemo(() => {
    const isLoginPage = checkLoginPage(location.pathname);

    if (
      location.pathname.includes('playground') ||
      location.pathname.includes('builder') ||
      location.pathname.includes('event/questionnaires') ||
      location.pathname.includes('organisation-plan-templates') ||
      location.pathname.includes('organization-plan') ||
      location.pathname.includes('check-mail') ||
      location.pathname.includes('password/reset') ||
      location.pathname.includes('questionnaires-list') ||
      isLoginPage
    ) {
      return modeForPlayground === 'system' ? resolvedMode : modeForPlayground;
    }

    return resolvedMode;
  }, [resolvedMode, modeForPlayground, location.pathname]);

  const theme: Theme = useMemo(() => Palette(themeMode, presetColor), [themeMode, presetColor]);
  const themeTypography: TypographyVariantsOptions = useMemo(() => Typography(themeMode, fontFamily), [themeMode, fontFamily]);
  const themeCustomShadows: CustomShadowProps = useMemo(() => CustomShadows(theme), [theme]);
  const themeOptions: ThemeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1300,
          xl: 1536
        }
      },
      direction: themeDirection,
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8
        }
      },
      palette: theme.palette,
      customShadows: themeCustomShadows,
      typography: themeTypography,
      shape: {
        borderRadius: 6
      }
    }),
    [themeDirection, theme, themeTypography, themeCustomShadows]
  );

  const themes: Theme = createTheme(themeOptions);
  themes.components = componentsOverride(themes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
