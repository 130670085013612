import { ReactElement } from 'react';
import { Typography } from '@mui/material';
import * as Styles from './OrganisationPlanWidgetsPaletteItem.styles';

interface IOrganisationPlanWidgetsPaletteItemProps {
  imgUrl: string;
  label: string;
  customImageSize?: boolean;
}

const OrganisationPlanWidgetsPaletteItem = ({ label, imgUrl, customImageSize }: IOrganisationPlanWidgetsPaletteItemProps): ReactElement => {
  return (
    <Styles.WidgetItem draggable>
      <Styles.BoxWrapper customImageSize={customImageSize}>
        <img src={imgUrl} alt={label} />
      </Styles.BoxWrapper>
      <Typography>{label}</Typography>
    </Styles.WidgetItem>
  );
};

export default OrganisationPlanWidgetsPaletteItem;
