import { ChangeEventHandler } from 'react';
import { Layout } from 'react-grid-layout';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import { useDispatch } from 'store';
import { updateOrganisationPlanWidget } from 'store/organisation-plan-template/organisation-plan-template.slice';
import useUpdateOrganisationPlanLayoutPartially from 'modules/organisation-plan-templates/hooks/useUpdateOrganisationPlanLayoutPartially';
import { OrganisationPlanNotesWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanNotesWidgetType';
import { OrganisationPlanTextWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanTextWidgetType';
import * as Styles from './OrganisationPlanNotesWidget.styles';

interface INotesWidgetProps {
  element: OrganisationPlanNotesWidgetType;
  layoutItem: Layout;
}

const OrganisationPlanNotesWidget = ({ element, layoutItem }: INotesWidgetProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { type, sectionId, widgetId } = element;
  const ref = useUpdateOrganisationPlanLayoutPartially({ widgetId, sectionId: sectionId || '', layoutItem });
  const onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    const newValue = e.target.value;
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: element.widgetId,
        widget: { type: type as OrganisationPlanTextWidgetType['type'], value: newValue }
      })
    );
  };

  return (
    <Box ref={ref}>
      <Styles.TextFieldWrapper
        variant="filled"
        fullWidth={true}
        multiline={true}
        minRows={1}
        maxRows={7}
        placeholder={intl.formatMessage({ id: 'organisation.notes' })}
        id="outlined-name"
        value={element.noteText}
        onChange={onChange}
        disabled={true}
      />
    </Box>
  );
};

export default OrganisationPlanNotesWidget;
