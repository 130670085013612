import { useIntl } from 'react-intl';
import { Box, Link } from '@mui/material';
import { IUploadAreaData, IUploadedFile } from 'modules/builder/types/uploadAreaWidgetsType';
import { renderFileExtensionIconHandler } from 'modules/organisation-plan-preview/utils/renderFileExtensionIconHandler';
import * as Styles from '././PreviewUploadWidget.styles';

interface IUploadPreviewProps {
  data: IUploadAreaData;
  isInlinePreview?: boolean;
  isInlineLink?: boolean;
}

const UploadedFilesPreview = ({ data, isInlinePreview, isInlineLink }: IUploadPreviewProps) => {
  const intl = useIntl();

  const isImageFile = (fileName: string) => {
    if (fileName) {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
      const fileExtension = fileName.split('.').pop()?.toLowerCase();
      return imageExtensions.includes(fileExtension!);
    }
  };

  const renderFileContent = (file: IUploadedFile) => {
    const fileUrl = `${import.meta.env.VITE_REACT_APP_STORAGE_URL}/${file?.url}`;
    const isShowImageIcon = isInlineLink && isInlinePreview;
    const fileIcon = isShowImageIcon && renderFileExtensionIconHandler(file?.fileName);
    const fileName = file?.fileName;
    const imgAlt = `image_${file?.widgetId}`;

    if (isImageFile(fileName!)) {
      if (isShowImageIcon) {
        return (
          <Box>
            <Link href={fileUrl}>
              <Styles.TypographyLink>{fileName}</Styles.TypographyLink>
            </Link>
            <img style={Styles.ImageSize} src={fileUrl} alt={imgAlt} />
          </Box>
        );
      }
      if (isInlineLink) {
        return (
          <Link href={fileUrl}>
            <Styles.TypographyLink>{fileName}</Styles.TypographyLink>
          </Link>
        );
      }
      if (isInlinePreview) {
        return <img style={Styles.ImageSize} src={fileUrl} alt={imgAlt} />;
      }
      return (
        <Styles.BoxImage>
          <img src={fileUrl} alt={imgAlt} />
        </Styles.BoxImage>
      );
    } else {
      return (
        <Styles.ContainerNoImage>
          <Styles.NoImageLink href={fileUrl}>
            {fileIcon && <img src={fileIcon} alt={imgAlt} />}
            <Styles.TypographyLink>{fileName}</Styles.TypographyLink>
          </Styles.NoImageLink>
        </Styles.ContainerNoImage>
      );
    }
  };

  const renderContent = () => {
    if (data.uploadedFile?.length) {
      return data.uploadedFile.map((file) => {
        return (
          <Box sx={{ flexBasis: 'min-content', minWidth: '200px' }} key={file.fileName}>
            {renderFileContent(file)}
          </Box>
        );
      });
    } else {
      return intl.formatMessage({ id: 'organisation-plan-preview.no-provided-file' });
    }
  };

  return <>{renderContent()}</>;
};

export default UploadedFilesPreview;
