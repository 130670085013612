import { useQuery } from 'react-query';
import axios from 'axios';
import { IEmailTemplateData } from './useCreateEmailTemplate';

interface IEmailTemplateTypeRequest {
  type: string;
}

export interface IEmailTemplateType {
  code: string;
}
export interface IEmailTemplateRoute {
  name: string;
  route: string;
}

export interface IEmailTemplateVariable extends IEmailTemplateType {
  name: string;
  intlId?: string;
  description: string;
  isRequired: boolean;
}

const fetchAllEmailTemplates = (): Promise<IEmailTemplateType[]> => {
  return axios.get(`email-templates/types`).then((res) => {
    return res.data;
  });
};

const fetchEmailTemplateVariables = (type: string): Promise<IEmailTemplateVariable[]> => {
  return axios.get(`email-templates/types/${type}/variables`, {}).then((res) => {
    return res.data;
  });
};

const fetchEmailTemplateData = (type: string): Promise<{ data: IEmailTemplateData; status: number }> => {
  const getData = axios.get(`email-templates/types/${type}`, {});
  return getData
    .catch(() => {
      return { data: [], status: 500 };
    })
    .then((res) => {
      return {
        data: res.data,
        status: res.status
      };
    });
};

export function useGetAllEmailTemplates() {
  return useQuery(['email-templates'], fetchAllEmailTemplates);
}

export function useGetEmailTemplateVariablesByType({ type }: IEmailTemplateTypeRequest) {
  return useQuery(['email-template-variable', { type }], () => fetchEmailTemplateVariables(type), { cacheTime: 1 });
}

export function useGetEmailTemplateDataByType({ type }: IEmailTemplateTypeRequest) {
  return useQuery(['email-template-data', { type }], () => fetchEmailTemplateData(type), { cacheTime: 1 });
}
