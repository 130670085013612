import { UseMutationOptions, useMutation } from 'react-query';
import axios from 'axios';
import { IEditorArticle } from 'modules/editor/types/editor';
import { API_OFFERS_ENDPOINTS } from 'shared/types/offer';

const fetchOfferArticleItemsOptional = (id: number): Promise<IEditorArticle[]> => {
  return axios.get(API_OFFERS_ENDPOINTS.GET_ITEMS_OPTIONAL(id)).then((res) => res.data);
};

interface IuseGetOfferArticleItemsOptionalParams<TError, TContext> {
  options?: Omit<UseMutationOptions<IEditorArticle[], TError, number, TContext>, 'mutationFn' | 'onSuccess' | 'variables'>;
  onSuccess?: (response: IEditorArticle[]) => void;
}

export function useGetOfferArticleItemsOptional<TError, TContext>({
  options
}: IuseGetOfferArticleItemsOptionalParams<TError, TContext> = {}) {
  return useMutation(fetchOfferArticleItemsOptional, { ...options });
}
