import { AlertMessagesColor } from 'shared/types/alert-messages';
import { snackbarAlert } from 'shared/utils/infoText';
import { MAX_SIZE_FILE } from 'shared/сonstants';
import { dispatch } from 'store';

const checkFileSize = (file: File | null, textError: string) => {
  if (file && file.size >= MAX_SIZE_FILE) {
    dispatch(snackbarAlert(textError, AlertMessagesColor.Error));
    return false;
  }
  return true;
};

export default checkFileSize;
