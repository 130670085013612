import { sortOrderType } from 'modules/builder/components/widgets/TableWidget/types';
import { FieldNamesForSortingType } from 'modules/invoices/types';
import { IEditorArticle, IEditorArticleItem } from 'modules/editor/types/editor';

/**
 * util function that can be used as a callback in sorting function
 * @param first value #1
 * @param second value #2
 * @param sortOrder `asc` | `desc`
 * @returns number
 */
const sortComparator = (first: number | string, second: number | string, sortOrder: 'asc' | 'desc'): number => {
  if (first < second) {
    return sortOrder === 'asc' ? -1 : 1;
  }
  if (first > second) {
    return sortOrder === 'asc' ? 1 : -1;
  }
  return 0;
};

/**
 * util function to find max number of certain fiels in price items
 * @param article invoice article
 * @param fieldName field for search max value `count` | `priceBrutto` | `priceNetto`
 * @returns number
 */

const getMaxNumberFromItems = (
  article: IEditorArticle,
  fieldName: keyof Pick<IEditorArticleItem, 'count' | 'priceBrutto' | 'priceNetto'>
): number => {
  return article.priceItems.reduce((acc, item) => {
    const value = item?.[fieldName] ?? 0;
    if (value > acc) return value;
    return acc;
  }, 0);
};

interface ISortTableParams {
  articles: IEditorArticle[];
  sortedColumnId: FieldNamesForSortingType;
  order: sortOrderType;
}

/**
 * util function for sorting of invoice articles
 * @param param.articles
 * @param param.sortedColumnId
 * @param param.order
 * @returns array of articles
 */
export const sortTable = ({ articles, sortedColumnId, order }: ISortTableParams) => {
  if (!sortedColumnId || !order) {
    return articles;
  }
  const articlesForSorting = [...articles];
  if (sortedColumnId === 'priceBrutto' || sortedColumnId === 'priceNetto' || sortedColumnId === 'count') {
    articlesForSorting.sort((row1, row2) => {
      const maxNumberRow1 = getMaxNumberFromItems(row1, sortedColumnId);
      const maxNumberRow2 = getMaxNumberFromItems(row2, sortedColumnId);
      return sortComparator(maxNumberRow1, maxNumberRow2, order);
    });
  }
  if (sortedColumnId === 'unit') {
    articlesForSorting.sort((row1, row2) => {
      const first = row1.priceItems.find((item) => !!item.unit)?.unit?.toUpperCase() ?? Infinity;
      const second = row2.priceItems.find((item) => !!item.unit)?.unit?.toUpperCase() ?? Infinity;
      return sortComparator(first, second, order);
    });
  }
  if (sortedColumnId === 'tax' || sortedColumnId === 'totalGross' || sortedColumnId === 'totalNet') {
    articlesForSorting.sort((row1, row2) => {
      const first = row1[sortedColumnId] ?? Infinity;
      const second = row2[sortedColumnId] ?? Infinity;
      return sortComparator(first, second, order);
    });
  }
  if (sortedColumnId === 'name') {
    articlesForSorting.sort((row1, row2) => {
      const first = row1[sortedColumnId]?.toUpperCase() ?? Infinity;
      const second = row2[sortedColumnId]?.toUpperCase() ?? Infinity;
      return sortComparator(first, second, order);
    });
  }
  return articlesForSorting.map((article, index) => ({ ...article, order: index + 1 }));
};
