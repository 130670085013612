import { Dismiss20Regular } from '@fluentui/react-icons';
import { Box, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import ColorScheme from 'shared/components/Header/HeaderContent/Customization/ColorScheme';
import ThemeFont from 'shared/components/Header/HeaderContent/Customization/ThemeFont';
import ThemeMode from 'shared/components/Header/HeaderContent/Customization/ThemeMode';
import SimpleBar from 'shared/components/SimpleBar';

interface IPanelCustomizer {
  handleToggle: () => void;
}

const PanelCustomizer = ({ handleToggle }: IPanelCustomizer) => {
  const theme = useTheme();

  const themeMode = useMemo(() => <ThemeMode />, []);
  const themeColor = useMemo(() => <ColorScheme />, []);
  const themeFont = useMemo(() => <ThemeFont />, []);

  return (
    <>
      <DialogTitle
        sx={{
          padding: '10px 10px 10px 20px',
          borderBottom: `1px solid ${theme.palette.divider}`,
          backgroundColor: 'transparent'
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <FormattedMessage id="settings.appearance" defaultMessage="Appearance" />
          <IconButton size="small" onClick={handleToggle}>
            <Dismiss20Regular />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider />
      <SimpleBar
        sx={{
          '& .simplebar-content': {
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <Box>
          <Stack gap="10px" p="20px">
            <Stack>
              <Typography variant="subtitle1" color="textPrimary">
                <FormattedMessage id="settings.appearance-theme-mode" defaultMessage="Theme Mode" />
              </Typography>
              <Typography variant="caption" color="textSecondary">
                <FormattedMessage id="settings.appearance-theme-mode-description" defaultMessage="Choose Light, Dark or System Mode." />
              </Typography>
            </Stack>
            {themeMode}
          </Stack>
          <Divider />
          <Stack gap="10px" p="20px 20px 20px 20px">
            <Stack>
              <Typography variant="subtitle1" color="textPrimary">
                <FormattedMessage id="settings.appearance-theme-color" defaultMessage="Theme Color" />
              </Typography>
              <Typography variant="caption" color="textSecondary">
                <FormattedMessage id="settings.appearance-theme-color-description" defaultMessage="Choose your theme color." />
              </Typography>
            </Stack>
            {themeColor}
          </Stack>
          <Divider />
          <Stack gap="10px" p="20px 10px 20px 20px">
            <Stack>
              <Typography variant="subtitle1" color="textPrimary">
                <FormattedMessage id="settings.appearance-theme-font" defaultMessage="Theme Font Type" />
              </Typography>
              <Typography variant="caption" color="textSecondary">
                <FormattedMessage id="settings.appearance-theme-font-description" defaultMessage="Choose your theme font type." />
              </Typography>
            </Stack>
            {themeFont}
          </Stack>
        </Box>
      </SimpleBar>
    </>
  );
};

export default PanelCustomizer;
