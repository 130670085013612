import { Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxWrapper = styled(Box)(() => ({
  display: 'flex',
  width: '100%'
}));

export const ContainerWrapper = styled(Container)(() => ({
  width: '100%',
  position: 'relative',
  minHeight: 'calc(100vh - 60px)',
  display: 'flex',
  flexDirection: 'column'
}));

export const NoContainerBoxWrapper = styled(Box)(() => ({
  position: 'relative',
  minHeight: 'calc(100vh - 60px)',
  display: 'flex',
  flexDirection: 'column'
}));
