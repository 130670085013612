import { FormattedMessage } from 'react-intl';
import {
  BuildingBankRegular,
  CalendarQuestionMarkRegular,
  HomeRegular,
  NotebookQuestionMarkRegular,
  PeopleRegular,
  Question20Regular,
  ReceiptMoneyRegular,
  StarRegular,
  TagMultipleRegular
} from '@fluentui/react-icons';
import { NavItemType } from 'shared/types/menu';
import { IUser } from 'shared/types/user';
import { INVOICES_ROUTES } from 'modules/invoices/constants/paths';

const icons = {
  BuildingBankRegular,
  HomeRegular,
  Question20Regular,
  TagMultipleRegular,
  StarRegular,
  PeopleRegular,
  ReceiptMoneyRegular,
  NotebookQuestionMarkRegular,
  CalendarQuestionMarkRegular
};

const menuItemsGlobalAdmin: NavItemType = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="sidebar.home-dashboard" />,
      type: 'item',
      url: '/dashboard',
      breadcrumbs: true,
      icon: icons.HomeRegular
    },
    {
      id: 'Tenants',
      title: <FormattedMessage id="tenants.tenants" />,
      type: 'item',
      url: '/tenants',
      breadcrumbs: false,
      icon: icons.BuildingBankRegular
    },
    {
      id: 'Users',
      title: <FormattedMessage id="users.users" />,
      type: 'item',
      url: '/users',
      breadcrumbs: false,
      icon: icons.PeopleRegular
    },
    {
      id: 'Questionnaires',
      title: <FormattedMessage id="questionnaires.questionnaires" />,
      type: 'item',
      url: '/questionnaires',
      breadcrumbs: false,
      icon: icons.NotebookQuestionMarkRegular
    }
  ]
};

const menuItemsTenantAdmin: NavItemType = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="sidebar.home-dashboard" />,
      type: 'item',
      url: '/dashboard',
      breadcrumbs: false,
      icon: icons.HomeRegular
    },
    {
      id: 'Events',
      title: <FormattedMessage id="events.events" />,
      type: 'item',
      url: '/events',
      breadcrumbs: false,
      icon: icons.StarRegular
    },
    {
      id: 'Requests',
      title: <FormattedMessage id="requests.page-name" />,
      type: 'item',
      url: '/requests',
      breadcrumbs: false,
      icon: icons.CalendarQuestionMarkRegular
    },
    {
      id: 'Invoices',
      title: <FormattedMessage id="invoices.page-name" />,
      type: 'item',
      url: INVOICES_ROUTES.ROOT_LINK,
      breadcrumbs: false,
      icon: icons.ReceiptMoneyRegular
    },
    {
      id: 'Users',
      title: <FormattedMessage id="users.users" />,
      type: 'item',
      url: '/users',
      breadcrumbs: false,
      icon: icons.PeopleRegular
    },
    {
      id: 'Questionnaires',
      title: <FormattedMessage id="questionnaires.questionnaires" />,
      type: 'item',
      url: '/questionnaires',
      breadcrumbs: false,
      icon: icons.NotebookQuestionMarkRegular
    },
    {
      id: 'Article Master',
      title: <FormattedMessage id="articles.article-Master" />,
      type: 'item',
      url: '/articles',
      breadcrumbs: false,
      icon: icons.TagMultipleRegular
    }
  ]
};

const menuItemsEventManager: NavItemType = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="sidebar.home-dashboard" />,
      type: 'item',
      url: '/dashboard',
      breadcrumbs: false,
      icon: icons.HomeRegular
    },
    {
      id: 'Events',
      title: <FormattedMessage id="events.events" />,
      type: 'item',
      url: '/events',
      breadcrumbs: false,
      icon: icons.StarRegular
    },
    {
      id: 'Invoices',
      title: <FormattedMessage id="invoices.page-name" />,
      type: 'item',
      url: INVOICES_ROUTES.ROOT_LINK,
      breadcrumbs: false,
      icon: icons.ReceiptMoneyRegular
    },

    {
      id: 'Users',
      title: <FormattedMessage id="users.users" />,
      type: 'item',
      url: '/users',
      breadcrumbs: false,
      icon: icons.PeopleRegular
    }
  ]
};

const dashboardLocal: NavItemType = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="sidebar.home-dashboard" />,
      type: 'collapse',
      icon: icons.HomeRegular,
      children: [
        {
          id: 'default',
          title: <FormattedMessage id="default" />,
          type: 'item',
          url: '/mantis/dashboard/default',
          breadcrumbs: false
        },
        {
          id: 'analytics',
          title: <FormattedMessage id="analytics" />,
          type: 'item',
          url: '/mantis/dashboard/analytics'
        },
        {
          id: 'components',
          title: <FormattedMessage id="components" />,
          type: 'item',
          url: '/mantis/components-overview/buttons',
          icon: Question20Regular,
          target: true,
          chip: {
            label: 'new',
            color: 'primary',
            size: 'small',
            variant: 'combined'
          }
        }
      ]
    },
    {
      id: 'Tenants',
      title: <FormattedMessage id="tenants.tenants" />,
      type: 'item',
      url: '/mantis/tenants',
      breadcrumbs: false,
      icon: icons.BuildingBankRegular
    },
    {
      id: 'Users',
      title: <FormattedMessage id="users.users" />,
      type: 'item',
      url: '/mantis/users',
      breadcrumbs: false,
      icon: icons.PeopleRegular
    },
    {
      id: 'Questionnaires',
      title: <FormattedMessage id="questionnaires.questionnaires" />,
      type: 'item',
      url: '/mantis/questionnaires',
      breadcrumbs: false,
      icon: icons.NotebookQuestionMarkRegular
    },
    {
      id: 'Article Master',
      title: <FormattedMessage id="articles.article-Master" />,
      type: 'item',
      url: '/mantis/articles',
      breadcrumbs: false,
      icon: icons.TagMultipleRegular
    }
  ]
};

export const getMenuItems = (role?: IUser['role']) => {
  switch (role) {
    case 'admin':
      return {
        items: [menuItemsGlobalAdmin]
      };
    case 'tenant_admin':
      return {
        items: [menuItemsTenantAdmin]
      };
    case 'event_manager':
      return {
        items: [menuItemsEventManager]
      };
    default:
      return {
        items: [menuItemsTenantAdmin]
      };
  }
};

const dashboard = dashboardLocal;
export default dashboard;
