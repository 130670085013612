import { Box, Toolbar } from '@mui/material';
import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import * as Styles from './OrganisationPlanTemplateLayout.styles';

const OrganisationPlanTemplateLayout = (): ReactElement => {
  return (
    <Box maxHeight="100dvh">
      <Toolbar variant="dense" />
      <Styles.BoxOutletWrapper>
        <Outlet />
      </Styles.BoxOutletWrapper>
    </Box>
  );
};

export default OrganisationPlanTemplateLayout;
