import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { snackbarAlert } from 'shared/utils/infoText';
import { AlertMessagesColor } from 'shared/types/alert-messages';

export const useInfoMessage = (): {
  successMessage: (answer: string, voidClose?: () => void) => void;
  errorMessage: (answer: string, voidClose?: () => void) => void;
} => {
  const dispatch = useDispatch();

  const outputMessage = useCallback(
    (isErrorMessage: boolean, answer: string, voidClose?: () => void) => {
      const colorMessage = isErrorMessage ? AlertMessagesColor.Error : AlertMessagesColor.Success;
      dispatch(snackbarAlert(answer, colorMessage));
      voidClose && voidClose();
    },
    [dispatch]
  );

  const successMess = useCallback((answer: string, voidClose?: () => void) => outputMessage(false, answer, voidClose), [outputMessage]);
  const errorMess = useCallback((answer: string, voidClose?: () => void) => outputMessage(true, answer, voidClose), [outputMessage]);

  return {
    successMessage: successMess,
    errorMessage: errorMess
  };
};
