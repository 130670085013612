import { useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'store';
import { selectOrgPlanSectionsIds } from 'store/organisation-plan-template/organisation-plan-template.selectors';
import { addNewOrganisationPlanSection } from 'store/organisation-plan-template/organisation-plan-template.slice';
import OrgPlanSectionWrapper from 'modules/organisation-plan-templates/containers/OrgPlanSectionWrapper';
import SectionDndContainer from 'modules/organisation-plan-templates/containers/SectionDndContainer/index';
import * as Styles from './OrganisationPlanTemplateSectionsContainer.styles';

interface IOrganisationPlanTemplateSectionsContainerProps {
  isPaddingSection: boolean;
}

const OrganisationPlanTemplateSectionsContainer = ({ isPaddingSection }: IOrganisationPlanTemplateSectionsContainerProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const sectionsIds = useSelector(selectOrgPlanSectionsIds) as string[];

  const addNewSection = useCallback(() => {
    dispatch(addNewOrganisationPlanSection({ defaultSectionName: intl.formatMessage({ id: 'organisation-plan.new-section-name' }) }));
  }, [dispatch, intl]);

  useEffect(() => {
    if (!sectionsIds.length) addNewSection();
  }, [addNewSection, sectionsIds.length]);

  return (
    <main>
      <Box sx={{ width: '991px' }}>
        <SectionDndContainer>
          {sectionsIds.map((sectionId, index) => (
            <OrgPlanSectionWrapper isPaddingSection={isPaddingSection} key={sectionId} sectionId={sectionId} sectionIndex={index} />
          ))}
        </SectionDndContainer>
      </Box>
      <Styles.BtnWrapper>
        <Button variant="contained" onClick={addNewSection}>
          <FormattedMessage id="organisation-plan.new-section-btn" />
        </Button>
      </Styles.BtnWrapper>
    </main>
  );
};

export default OrganisationPlanTemplateSectionsContainer;
