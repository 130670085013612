import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const OrganisersAndGuestsInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexFlow: 'wrap',
  justifyContent: 'center',
  padding: '10px 10px 0 10px',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0 0 6px 6px',
  '& > .MuiBox-root': { '&:not(:last-child)': { marginRight: 32 } },

  '@media print': {
    color: 'black',
    fontSize: 14,
    backgroundColor: 'white',
    padding: '5px',
    '& > .MuiBox-root': { '&:not(:last-child)': { marginRight: 16 } }
  }
}));

export const OrganisersAndGuestsInfoItem = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: 10,

  '@media print': {
    paddingBottom: 0
  }
}));

export const OrganisersAndGuestsInfoTitle = styled(Typography)(({ theme }) => ({
  padding: 20,
  lineHeight: '24px',
  fontWeight: 700,
  fontSize: 24,
  textAlign: 'center',
  borderBottom: `1px dashed ${theme.palette.secondary.light}`,
  color: theme.palette.primary.main,
  '@media print': {
    fontSize: 28,
    padding: '10px 0',
    lineHeight: '24px',
    borderBottom: '1px dashed #d9d9d9',
    color: 'black'
  }
}));

export const OrganisersAndGuestsInfoDescription = styled(Typography)(() => ({
  fontSize: 16,

  '@media print': {
    fontSize: 14,
    padding: '5px',
    color: 'black'
  }
}));

export const BoxWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex'
}));
