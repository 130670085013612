import OrganisationPlanTemplatePageById from 'pages/OrganisationPlanTemplate/OrganisationPlanTemplatePageById';
import AuthGuard from 'shared/components/route-guard/AuthGuard';
import { ORGANISATION_PLAN_TEMPLATE_ROUTES } from './constants/organisationPlanTemplateBuilder';
import OrganisationPlanTemplateLayout from './layouts/OrganisationPlanTemplateLayout';

export const OrganisationPlanTemplateRoutes = {
  path: '/',
  children: [
    {
      path: ORGANISATION_PLAN_TEMPLATE_ROUTES.ORGANISATION_PLAN_TEMPLATE,
      element: (
        <AuthGuard>
          <OrganisationPlanTemplateLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ':organisationPlanId/:questionnaireTemplateId',
          element: <OrganisationPlanTemplatePageById />
        }
      ]
    }
  ]
};
