import { useLocation } from 'react-router-dom';
import { EQuestionnairesSource } from 'shared/types/questionnaire-template';

export const useGetQuestionnairesSourse = (): EQuestionnairesSource => {
  // TODO: sergio. handle case when hook can be call outside of builder / questionnaire pages

  const { pathname } = useLocation();
  if (pathname.includes('/event/')) {
    return EQuestionnairesSource.event;
  }
  if (pathname.includes('/request/')) {
    return EQuestionnairesSource.request;
  }
  return EQuestionnairesSource.template;
};
