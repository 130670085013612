import { IGuestDisplayOption, IOrgPlanGuestOptions } from 'shared/types/organisation-plan-preview';
import OrganisationPlanPreviewNumberOfGuestsInfoOption from './OrganisationPlanPreviewNumberOfGuestsInfoOption';

interface IOrganisationPlanPreviewNumberOfGuestsInfoProps {
  guestNumberOptions: IGuestDisplayOption[];
  guestOptionsValues: IOrgPlanGuestOptions[];
}

const OrganisationPlanPreviewNumberOfGuestsInfo = ({
  guestNumberOptions,
  guestOptionsValues
}: IOrganisationPlanPreviewNumberOfGuestsInfoProps) => {
  const checkedOptions = guestNumberOptions?.filter((option) => option.isCheck);

  return (
    <>
      {checkedOptions?.map((option) => (
        <OrganisationPlanPreviewNumberOfGuestsInfoOption key={option.id} option={option} guestOptionsValues={guestOptionsValues} />
      ))}
    </>
  );
};

export default OrganisationPlanPreviewNumberOfGuestsInfo;
