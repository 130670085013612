import { FormattedMessage, useIntl } from 'react-intl';
import { MenuItem, Select, SelectProps } from '@mui/material';
import { useGetDataByRole } from 'shared/hooks/useGetDataByRole';

interface IStatusOption {
  [key: string]: {
    intlId: string;
    color: string;
    bgColor: string;
  };
}
interface IStatusSelect {
  name: string;
  value: string;
  handleChange: SelectProps['onChange'];
  options: IStatusOption;
  disabledStatus?: string[];
}
const StatusSelect = ({
  name,
  handleChange,
  value,
  options,
  disabledStatus,
  ref: _ref,
  type: _type,
  ...rest
}: IStatusSelect & SelectProps) => {
  const intl = useIntl();
  const { requestDisabledStatus } = useGetDataByRole();
  return (
    <Select
      {...rest}
      value={value ?? null}
      name={name}
      onChange={handleChange}
      placeholder={intl.formatMessage({ id: 'events.select-here' })}
      inputProps={{ placeholder: intl.formatMessage({ id: 'events.select-here' }) }}
    >
      {Object.entries(options)
        .filter(([value]) => !String(requestDisabledStatus).includes(value))
        .map(([value, object]) => {
          return (
            <MenuItem divider disabled={String(disabledStatus).includes(value)} key={`select-state-${object.intlId}`} value={value}>
              <FormattedMessage id={object.intlId} />
            </MenuItem>
          );
        })}
    </Select>
  );
};

export default StatusSelect;
