import * as React from 'react';
import { BoxProps } from '@mui/material';
import * as Styles from './OrganisationPlanTabPanel.styles';

interface OrganisationPlanTabPanelProps {
  children?: React.ReactNode;
  activeValue: number;
  value: number | null;
}
const OrganisationPlanTabPanel = ({ children, value, activeValue, ...other }: OrganisationPlanTabPanelProps & BoxProps) => {
  return (
    <Styles.BoxWrapper
      role="info-from-questionnaire-tab-panel"
      hidden={value !== activeValue}
      id={`settings-info-from-questionnaire-tab-panel-${activeValue}`}
      aria-labelledby={`settings-tab-${activeValue}`}
      {...other}
    >
      {value === activeValue && <Styles.ChildrenBoxContainer>{children}</Styles.ChildrenBoxContainer>}
    </Styles.BoxWrapper>
  );
};

export default OrganisationPlanTabPanel;
