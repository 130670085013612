import { BUILDER_WIDGET_TYPES } from './types';

export const DROPPING_ELEMENT = '__dropping-elem__';

export const BACKGROUND_INPUT_DISABLE = '#F7F7F7';

export const ACCEPTED_WIDGETS = [
  BUILDER_WIDGET_TYPES.singleChoice,
  BUILDER_WIDGET_TYPES.multipleChoice,
  BUILDER_WIDGET_TYPES.singleImageChoice,
  BUILDER_WIDGET_TYPES.multipleImageChoice
];
