import { useEffect, useState } from 'react';
import 'cropperjs/dist/cropper.css';
import { IMAGE_FIT_TYPE } from 'modules/builder/types/imageWidgetsTypes';
import { IQuestionnaireImageItemDataSingleWidget } from 'modules/questionnaires/types/questionnaries';
import ReplacementTextHeading from 'shared/components/ReplacementText/Heading';
import { dispatch } from 'store';
import { updateOrganisationPlanWidget } from 'store/organisation-plan-template/organisation-plan-template.slice';
import { ORGANISATION_PLAN_WIDGET_TYPES } from 'modules/organisation-plan-templates/types';
import { IOrganisationPlanChoiceOptionReplacementLabels } from 'modules/organisation-plan-templates/types/organisationPlanChoiceWidgetType';
import OrganisationPlanImageUploadWidget from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanImageChoiceWithReplacementWidget/OrganisationPlanImageUploadWidget';
import {
  BoxWidget,
  ImageChoiceWidgetBox
} from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanImageChoiceWithReplacementWidget/OrganisationPlanSingleImageChoiceWidget/OrganisationPlanSingleImageChoiceWidget.styles';
import { additionalPropsStyle, containerPropsStyle } from './OrganisationPlanImageChoice.styles';
import * as Styles from './OrganisationPlanImageChoice.styles';

interface IOrganisationPlanImageChoiceProps {
  data: IQuestionnaireImageItemDataSingleWidget[] | null;
  value: any;
  multiple: boolean;
  isSingleChoice: boolean;
  id: string;
  typeImage: IMAGE_FIT_TYPE;
  typeWidget: string;
  replacementOptionsLabels?: IOrganisationPlanChoiceOptionReplacementLabels[];
}
const OrganisationPlanImageChoice = ({
  data,
  multiple,
  isSingleChoice,
  typeImage,
  id,
  typeWidget,
  replacementOptionsLabels
}: IOrganisationPlanImageChoiceProps) => {
  const [replacedOptionLabels, setReplacedOptionLabels] = useState<IOrganisationPlanChoiceOptionReplacementLabels[] | undefined>(
    replacementOptionsLabels
  );

  useEffect(() => {
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: id,
        widget: {
          type: isSingleChoice ? ORGANISATION_PLAN_WIDGET_TYPES.singleImageChoice : ORGANISATION_PLAN_WIDGET_TYPES.multipleImageChoice,
          replacementOptionsLabels: replacedOptionLabels
        }
      })
    );
  }, [id, isSingleChoice, replacedOptionLabels]);

  const changeOptionVariablesHandler = (
    option: IQuestionnaireImageItemDataSingleWidget,
    replacementLabel?: string,
    isLabelHidden?: boolean,
    isImageHidden?: boolean
  ) => {
    if (data?.length) {
      const currentReplacementOptions = (replacedOptionLabels || data) as IOrganisationPlanChoiceOptionReplacementLabels[];
      const updatedReplacementOptionsLabels = currentReplacementOptions?.map((optionLabel) => {
        if (optionLabel.id === option.id) {
          return {
            ...optionLabel,
            replacementLabel: replacementLabel || '',
            isLabelHidden:
              typeof isLabelHidden !== 'undefined' && typeof isLabelHidden === 'boolean' ? isLabelHidden : optionLabel.isLabelHidden,
            isImageHidden:
              typeof isImageHidden !== 'undefined' && typeof isImageHidden === 'boolean' ? isImageHidden : optionLabel.isImageHidden
          };
        }
        return optionLabel;
      });
      if (updatedReplacementOptionsLabels?.length) {
        setReplacedOptionLabels(updatedReplacementOptionsLabels);
      }
    }
  };

  const handleOptionReplacementLabelChange = (
    option: IQuestionnaireImageItemDataSingleWidget,
    _replacementLabel?: string,
    isMainLiableOpen?: boolean
  ) => {
    if (data?.length) {
      const currentReplacementOptions = (replacedOptionLabels || data) as IOrganisationPlanChoiceOptionReplacementLabels[];
      const updatedReplacementOptionsLabels = currentReplacementOptions?.map((optionLabel) => {
        if (optionLabel.id === option.id) {
          return {
            ...optionLabel,
            replacementLabel: isMainLiableOpen ? '' : null
          };
        }
        return optionLabel;
      });
      if (updatedReplacementOptionsLabels?.length) {
        setReplacedOptionLabels(updatedReplacementOptionsLabels);
      }
    }
  };

  return (
    <ImageChoiceWidgetBox>
      {data?.map((option) => {
        return (
          <Styles.SectionBox key={option.id}>
            <BoxWidget>
              <OrganisationPlanImageUploadWidget
                multiple={multiple}
                containerProps={containerPropsStyle}
                additionalProps={additionalPropsStyle}
                single={true}
                value={option.data}
                id={id}
                typeImage={typeImage}
                typeWidget={typeWidget}
                isImageHidden={replacementOptionsLabels?.find((i) => i.id === option.id)?.isImageHidden}
                onImageHiddenChange={(val) => changeOptionVariablesHandler(option, undefined, undefined, val)}
              />
            </BoxWidget>
            <ReplacementTextHeading
              isImageChoice={true}
              onChange={(event) => changeOptionVariablesHandler(option, event.target.value)}
              value={replacementOptionsLabels?.find((i) => i.id === option.id)?.replacementLabel}
              content={option.labelImage}
              isLabelHiddenValue={replacementOptionsLabels?.find((i) => i.id === option.id)?.isLabelHidden}
              onLabelHiddenChange={(val) => changeOptionVariablesHandler(option, undefined, val)}
              onReplacementLabelHiddenChange={() => changeOptionVariablesHandler(option, '')}
              isHeadingReplacementOpen={(value) => handleOptionReplacementLabelChange(option, '', value)}
            />
          </Styles.SectionBox>
        );
      })}
    </ImageChoiceWidgetBox>
  );
};

export default OrganisationPlanImageChoice;
