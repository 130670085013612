import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Add20Regular, Delete20Regular, Edit20Regular } from '@fluentui/react-icons';
import { Button, InputLabel, Stack } from '@mui/material';
import { replaceAllSpace } from 'shared/utils/editText';
import * as Styles from './LogoPicture.styles';

interface ILogoPicture {
  isTitle?: boolean;
  titleField: string;
  uploadFileInput: any;
  valuesLogo: any;
  deleteLogo: () => void;
  saveData: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isEdit?: boolean;
  isMultiple?: boolean;
  isDisabled?: boolean;
}

const LogoPicture = ({
  isTitle = true,
  titleField,
  uploadFileInput,
  valuesLogo,
  deleteLogo,
  saveData,
  isEdit,
  isMultiple,
  isDisabled
}: ILogoPicture) => {
  const shouldPlacehoderBeVisible = () => {
    if (valuesLogo) return false;
    return !uploadFileInput?.length;
  };

  const jsxImage = () => {
    if (!uploadFileInput?.length && !valuesLogo) return null;
    const url = uploadFileInput?.length ? uploadFileInput : valuesLogo;
    return (
      <Styles.BoxImage>
        <img style={Styles.imgStyles} src={url} alt="avatar" />
      </Styles.BoxImage>
    );
  };

  return (
    <Stack spacing={1.25}>
      {isTitle && <InputLabel htmlFor={`logo-${titleField.replace(/\W/g, '')}`}>{titleField}</InputLabel>}

      {shouldPlacehoderBeVisible() ? (
        <Button
          disabled={isDisabled}
          color="secondary"
          variant="dashed"
          component="label"
          sx={Styles.buttonAddStyles}
          startIcon={<Add20Regular />}
        >
          <FormattedMessage id="users.upload-photo" />
          <input
            name={`logo-${replaceAllSpace(titleField)}`}
            hidden
            type="file"
            multiple={isMultiple ? isMultiple : false}
            onChange={saveData}
          />
        </Button>
      ) : (
        <>
          {isEdit ? (
            <Styles.ButtonEdit disabled={isDisabled} color="secondary">
              <Styles.ButtonText>
                <Button disabled={isDisabled} component="label" startIcon={<Edit20Regular />}>
                  <input name={`logo-${replaceAllSpace(titleField)}`} hidden type="file" onChange={(e: any) => saveData(e)} />
                </Button>
              </Styles.ButtonText>
              {jsxImage()}
            </Styles.ButtonEdit>
          ) : (
            <Styles.BtnNoEdit disabled={isDisabled} color="secondary" onClick={deleteLogo}>
              <Styles.ButtonText>
                <Delete20Regular />
              </Styles.ButtonText>
              {jsxImage()}
            </Styles.BtnNoEdit>
          )}
        </>
      )}
    </Stack>
  );
};

export default LogoPicture;
