import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import LogoPicture from 'shared/components/LogoPicture';
import { IUserFormData } from 'shared/types/user';
import { getStorageFileUrl, typeImages } from 'shared/utils/getStorageFileUrl';
import checkFileSize from 'modules/organisation-plan-preview/utils/checkFileSize';
import { useGetDataByRole } from 'shared/hooks/useGetDataByRole';

interface ICreateUserFormProfilePictureProps {
  setUploadFileInput: Dispatch<SetStateAction<FileList | null>>;
}

const CreateCustomerFormProfilePicture = ({ setUploadFileInput }: ICreateUserFormProfilePictureProps) => {
  const intl = useIntl();
  const { values, setFieldValue } = useFormikContext<IUserFormData>();
  const [logoURL, setLogoURL] = useState<string>('');
  const { disableForEventManager } = useGetDataByRole();

  const handleUploadLogo = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const file = event.target.files && event.target.files.length ? event.target.files[0] : null;

    if (!checkFileSize(file, intl.formatMessage({ id: 'builder.max-size-image' }))) {
      return;
    }
    if (file) {
      setLogoURL(URL.createObjectURL(file));
      setUploadFileInput(event.currentTarget.files);
    }
  };

  const handleDelete = () => {
    setLogoURL('');
    setUploadFileInput(null);
    setFieldValue('logo', undefined);
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <LogoPicture
            titleField={intl.formatMessage({ id: 'events.profile-picture' })}
            uploadFileInput={logoURL}
            valuesLogo={values.logo && getStorageFileUrl(values.logo, typeImages.Internal)}
            deleteLogo={handleDelete}
            saveData={handleUploadLogo}
            isDisabled={disableForEventManager}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateCustomerFormProfilePicture;
