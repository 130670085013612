import { Layout } from 'react-grid-layout';
import { BaseDashboardWidget, DASHBOARD_WIDGET_TYPES, ETagData, ITagInfo } from './index';

export enum TableDashboardWidgetColumnType {
  Def = 'def',
  Tag = 'tag'
}

export interface TableDashboardWidgetType extends BaseDashboardWidget {
  dashboardWidgetType: DASHBOARD_WIDGET_TYPES.table;
  data: ITableDashboardWidgetData;
}

interface ITableDashboardWidgetData {
  primaryColumnLabel?: string;
  primaryColumn: string;
  name?: string;
  columns: ITableDashboardWidgetColumn[];
  withFooters: boolean;
  isActive?: boolean;
}

export interface ITableDashboardWidgetColumn {
  id?: number;
  label: string;
  name: string;
  type: TableDashboardWidgetColumnType;
  tagType: ETagData | null;
  order: number;
  intlId?: string;
  isCurrency: boolean;
}

export interface IColumnForUseTable {
  intlId?: string;
  columnName: string;
  label: string;
  isCurrency?: boolean;
  accessor?: string;
}

interface ITableColumnTitles {
  name: string;
}
interface ITableColumnResult {
  name: string;
  value?: number;
}
interface ITagsTranslations {
  type: ETagData;
  intlId: string;
}

export interface ITransformResponse {
  tableData: {
    titles: ITableColumnTitles[];
    rows: IColumnForUseTable[] | [];
    footers?: ITableColumnResult[];
    totalCount: number;
    pageNumber: number;
    pageSize: number;
  };
  newRows: IColumnForUseTable[] | [];
  tagsTranslations: ITagsTranslations[];
  widget?: BaseDashboardWidget;
  layout?: Layout | null;
  dashboardWidgetTags?: ITagInfo[];
  currency?: string;
  dateFormat: string;
  settingsLanguage: string;
}
