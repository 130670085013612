import { Add20Regular } from '@fluentui/react-icons';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DropzoneWrapper = styled(Button)(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  display: 'flex',
  padding: theme.spacing(2, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.secondary.lighter,
  border: `1px dashed ${theme.palette.secondary.light}`,
  height: '104px',
  width: '50%',
  marginLeft: '32px',
  alignItems: 'center',
  textTransform: 'none',

  '&:disabled': {
    color: '#262626'
  }
}));

export const NoFileTypographyWrapper = styled(Typography)(() => ({
  fontWeight: '400',
  fontSize: '14px',
  color: '#8C8C8C'
}));

export const PlusOutlinedWrapper = styled(Add20Regular)(() => ({
  marginBottom: '10px'
}));

export const UploadWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: '#FAFAFA',
  border: `1px dashed ${theme.palette.secondary.light}`,
  borderRadius: 2,
  padding: '22px 0',
  textAlign: 'center'
}));
