import { useGetTenantById } from 'api/hooks/tenant/useGetTenant';
import { format, parseISO } from 'date-fns';
import { formatNumber } from 'modules/dashboard/components/widgets/KeyFigureWidget/utils/formatValue';
import { DATA_SYNTAX, DateSyntaxFormat } from 'modules/settings/constants';
import { useCallback, useMemo } from 'react';
import { INDIVIDUAL_TAX, LEGAL_TAX } from 'shared/сonstants';
import { useSelector } from 'store';
import { selectQuestionnaireSettings } from 'store/builder/builder.selectors';
import useGeneralSettings from './useGeneralSettings';

/** This is hook for getting current date format selected in the general settings. */
export const useCurrentDateFormat = (): DateSyntaxFormat => {
  const { tenant } = useGeneralSettings();
  const { dateSyntax } = useSelector(selectQuestionnaireSettings);
  const dateSyntaxFormat = tenant?.regionalSettings?.dateSyntax ?? dateSyntax;
  return DATA_SYNTAX.find((i) => i.value === dateSyntaxFormat) ?? DATA_SYNTAX[0];
};

/**
 * This is hook for getting current date from string formatted according regional settings.
 * @param {string} date - date in string format [not required]
 * */
export const useCurrentDateFormatter = (date?: string) => {
  const currentDateFormat = useCurrentDateFormat();
  const dateFormat = currentDateFormat?.format || 'dd.MM.yyyy';

  return format(parseISO(date || new Date().toISOString()), dateFormat);
};

type useFormatCurrentDateReturnType = (date?: string) => string;
/**
 * This is hook just copy of useCurrentDateFormatter, need it because useCurrentDateFormatter you cannot use inside functions, conditions etc.
 * @param {string} date - date in string format [not required]
 * */
export const useFormatCurrentDate = (): useFormatCurrentDateReturnType => {
  const currentDateFormat = useCurrentDateFormat();
  const formatFn = useCallback(
    (date?: string) => {
      const dateFormat = currentDateFormat?.format || 'dd.MM.yyyy';
      return format(parseISO(date || new Date().toISOString()), dateFormat);
    },
    [currentDateFormat?.format]
  );
  return formatFn;
};

/** This is hook for getting current time format selected in the general settings. */
export const useCurrentTimeFormat = () => {
  const { tenant } = useGeneralSettings();
  const { timeFormat } = useSelector(selectQuestionnaireSettings);
  return tenant?.regionalSettings?.timeFormat ?? timeFormat;
};

/** This is hook for getting current time format selected in the general settings. */
export const useCurrentTimeFormatter = (date?: string, timeFormat?: string) => {
  const defaultTimeFormat = timeFormat ?? useCurrentTimeFormat(); // returns twelve or twentyFour
  console.log('defaultTimeFormat', defaultTimeFormat);
  const formattedTtime = format(parseISO(date || new Date().toISOString()), defaultTimeFormat === 'Twelve' ? 'hh:mm a' : 'HH:mm');
  return formattedTtime;
};

/** This hook formats the date and time according to the current settings. */
export const useCurrentDateTimeFormatter = (dateTime?: string) => {
  const currentDateFormat = useCurrentDateFormat();
  const dateFormat = currentDateFormat?.format || 'dd.MM.yyyy';

  const defaultTimeFormat = useCurrentTimeFormat(); // gibt 'twelve' oder 'twentyFour' zurück
  const timeFormat = defaultTimeFormat === 'Twelve' ? 'hh:mm a' : 'HH:mm';

  const dateTimeFormat = `${dateFormat} ${timeFormat}`;

  return format(parseISO(dateTime || new Date().toISOString()), dateTimeFormat);
};

/** This hook formats numbers according to the current settings.
 * @param {number} value - number to format
 * @returns {string} - formatted number
 */
export const useCurrentNumberFormatter = (value: string) => {
  const { regionalSettings } = useGeneralSettings();
  const groupSeparator = regionalSettings?.currency?.thousandSeparator || ' ';
  const decimalSeparator = regionalSettings?.currency?.decimalSeparator || ',';
  const formattedValue = formatNumber(parseFloat(value), groupSeparator, decimalSeparator, 2);
  return formattedValue;
};

/** This is hook for getting current currency symbol from currency code selected in the general settings. */
export const useCurrentCurrencyFormat = () => {
  const { regionalSettings } = useGeneralSettings();
  return regionalSettings?.currency?.symbol;
};

/** This is hook for getting settings currency symbol from currency code and taxes of the tenant general settings. */
export const useSettingsPanelPrice = (tenantId?: number) => {
  const { regionalSettings } = useGeneralSettings();
  const { user } = useGeneralSettings();
  const isAdmin = user?.role === 'admin';
  const { data: tenant } = useGetTenantById({
    id: tenantId?.toString()
  });
  const currencySign = regionalSettings?.currency?.symbol;
  return useMemo(
    () => ({
      legalTax: regionalSettings?.legalTax ?? LEGAL_TAX,
      individualTax: regionalSettings?.individualTax ?? INDIVIDUAL_TAX,
      currencyCode: isAdmin && tenantId ? tenant?.regionalSettings.currency.symbol : currencySign
    }),
    [regionalSettings, tenant, currencySign, isAdmin, tenantId]
  );
};
