import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Box, CardActions, Collapse, Divider, Tooltip } from '@mui/material';
import { Code20Regular, Copy20Regular } from '@fluentui/react-icons';
import IconButton from 'shared/components/@extended/IconButton';
import SyntaxHighlight from 'shared/components/SyntaxHighlight';

interface Props {
  codeString: string;
  codeHighlight: boolean;
}

const Highlighter = ({ codeString, codeHighlight }: Props) => {
  const [highlight, setHighlight] = useState(codeHighlight);

  return (
    <>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Box sx={{ display: 'flex' }}>
          <CopyToClipboard text={codeString}>
            <Tooltip arrow title="Copy the source" placement="top-end">
              <IconButton color="secondary" size="small" sx={{ fontSize: '0.875rem' }}>
                <Copy20Regular />
              </IconButton>
            </Tooltip>
          </CopyToClipboard>
          <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />
          <Tooltip arrow title="Show the source" placement="top-end">
            <IconButton
              sx={{ fontSize: '0.875rem' }}
              size="small"
              color={highlight ? 'primary' : 'secondary'}
              onClick={() => setHighlight(!highlight)}
            >
              <Code20Regular />
            </IconButton>
          </Tooltip>
        </Box>
      </CardActions>
      <Collapse in={highlight}>{highlight && <SyntaxHighlight>{codeString}</SyntaxHighlight>}</Collapse>
    </>
  );
};

export default Highlighter;
