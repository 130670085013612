import { RadioButton20Filled, RadioButton20Regular } from '@fluentui/react-icons';
import { Theme } from '@mui/material/styles';
import { ExtendedStyleProps } from 'shared/types/extended';
import getColors from 'shared/utils/getColors';

function getColorStyle({ color, theme }: ExtendedStyleProps) {
  const colors = getColors(theme, color);
  const { lighter, main, dark } = colors;

  return {
    '&:hover': {
      backgroundColor: lighter,
      borderRadius: '4px',
      margin: '3px !important',
      padding: '6px !important',
      '& .icon': {
        borderColor: main
      }
    },
    '&.Mui-focusVisible': {
      outline: `2px solid ${dark}`,
      outlineOffset: -4
    }
  };
}

// ==============================|| OVERRIDES - CHECKBOX ||============================== //

export default function Radio(theme: Theme) {
  const { palette } = theme;

  return {
    MuiRadio: {
      defaultProps: {
        className: 'size-small',
        icon: <RadioButton20Regular />,
        checkedIcon: <RadioButton20Filled />
      },
      styleOverrides: {
        root: {
          color: palette.secondary[300]
        },
        colorPrimary: getColorStyle({ color: 'primary', theme }),
        colorSecondary: getColorStyle({ color: 'secondary', theme }),
        colorSuccess: getColorStyle({ color: 'success', theme }),
        colorWarning: getColorStyle({ color: 'warning', theme }),
        colorInfo: getColorStyle({ color: 'info', theme }),
        colorError: getColorStyle({ color: 'error', theme })
      }
    }
  };
}
