import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { IPaginatedResult } from 'shared/types/paginatedResult';
import {
  API_INVOICES_ENDPOINTS,
  EInvoicesQueryKeys,
  IInvoice,
  IInvoiceEditorDetails,
  IInvoiceListItem,
  IInvoicePatchPayload
} from 'shared/types/invoice';
import { IPagination } from 'shared/types/table';

type MutationContext =
  | {
      previousInvoices: IPaginatedResult<IInvoiceListItem> | undefined;
      newInvoice: IInvoice;
    }
  | undefined;

const updateInvoice = (invoiceInfo: IInvoicePatchPayload): Promise<IInvoiceEditorDetails> => {
  return axios.patch(API_INVOICES_ENDPOINTS.GET_BY_ID(invoiceInfo.id), invoiceInfo).then((res) => res.data);
};

export function usePatchUpdateInvoice<TError>({
  options = {},
  onSuccess,
  id,
  pagination
}: {
  options?: Omit<UseMutationOptions<IInvoice, TError, IInvoicePatchPayload, MutationContext>, 'mutationFn' | 'onSuccess' | 'variables'>;
  onSuccess?: ((data: IInvoice, variables: IInvoicePatchPayload, context: MutationContext) => void | Promise<unknown>) | undefined;
  id?: number;
  pagination: IPagination;
}) {
  const queryClient = useQueryClient();
  const invoiceListQueryKey = [EInvoicesQueryKeys.GetInvoices, pagination];

  return useMutation(updateInvoice, {
    ...options,
    // Update cache after success request
    onSuccess: (data, variables, context) => {
      const previousInvoices: IPaginatedResult<IInvoiceListItem> | undefined = queryClient.getQueryData(invoiceListQueryKey);
      const prev = previousInvoices?.data ? previousInvoices.data : [];
      const newData = prev.map((invoice) => {
        if (invoice.id !== data.id) return invoice;
        return { ...invoice, ...data };
      });
      queryClient.setQueryData(invoiceListQueryKey, { ...previousInvoices, data: newData });
      queryClient.invalidateQueries(['invoice', { id: `${id}` }]);

      if (onSuccess) {
        onSuccess(data, variables, context);
      }
    },
    // Always refetch after error:
    onSettled: (_, error) => {
      if (error) {
        queryClient.invalidateQueries(invoiceListQueryKey);
      }
    }
  });
}
