import { EEnvVariablesVariant } from 'shared/types/availableEnvironmentsTypes';
import { NavItemType } from 'shared/types/menu';

/**
 * Funtion to skip nav-items for some environment.
 *
 * `currentEnv` get by side-efect from `import.meta.env.VITE_REACT_APP_RUNNED_ENV`
 * @param navItems Array of nav-items
 * @returns array of nav-items
 */
const filterNavItemsByEnvironment = (navItems: NavItemType[]): NavItemType[] => {
  const currentEnv: EEnvVariablesVariant =
    (import.meta.env.VITE_REACT_APP_RUNNED_ENV as EEnvVariablesVariant | undefined) ?? EEnvVariablesVariant.Development;

  return navItems.filter((item) => {
    const isMatched = item.env === undefined || item.env.includes(currentEnv);
    if (isMatched && item.children) {
      item.children = filterNavItemsByEnvironment(item.children);
    }
    return isMatched || (item.children && item.children.length > 0);
  });
};

export default filterNavItemsByEnvironment;
