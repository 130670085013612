import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxTableContainerWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'left',
  width: '100%',
  margin: 'none !important',
  padding: 'none !important'
}));

export const TableWrapper = styled('table')(() => ({
  borderSpacing: '1px',
  borderCollapse: 'separate',
  backgroundColor: '#707070',
  borderRadius: '8px',
  width: '100%'
}));

export const TableHeadWrapper = styled('thead')(() => ({
  display: 'table-header-group',
  verticalAlign: 'middle',
  borderColor: 'transparent'
}));

export const TableHeadRowWrapper = styled('tr')(() => ({
  display: 'table-row',
  verticalAlign: 'middle',
  borderColor: 'inherit',
  '& th:first-child': {
    borderTopLeftRadius: '7px'
  },
  '& th:last-child': {
    borderTopRightRadius: '7px'
  }
}));

export const TableHeadCellWrapper = styled('th')(() => ({
  fontSize: 14,
  fontWeight: 600,
  textTransform: 'none',
  textAlign: 'left',
  background: '#e0e0e0',
  padding: '5px 10px',
  verticalAlign: 'center'
}));

export const TableBodyWrapper = styled('tbody')(() => ({
  display: 'table-row-group',
  borderBottomLeftRadius: '7px',
  borderBottomRightRadius: '7px',
  background: 'initial'
}));

export const TableBodyRowWrapper = styled('tr')(() => ({
  display: 'table-row',
  verticalAlign: 'inherit',
  background: 'white',
  '& td': {
    padding: '8px 10px 8px 10px',
    verticalAlign: 'center'
  },
  '&:last-child': {
    '& td:first-child': {
      borderBottomLeftRadius: '7px'
    },
    '& td:last-child': {
      borderBottomRightRadius: '7px'
    }
  }
}));
