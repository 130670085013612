import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormHelperText, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import AutocompleteInfiniteSelect from 'shared/components/AutocompleteInfiniteSelect';
import { useGetEventListInfinite } from 'modules/invoices/hooks/useGetEventListInfinite';
import { EInvoiceType, IInvoiceDialogForm } from 'shared/types/invoice';
import { IEvent } from 'shared/types/event';
import { useGetDataByRole } from 'shared/hooks/useGetDataByRole';

const INVOICE_TYPES = [
  { value: EInvoiceType.invoice, intId: 'invoices.types-invoice' },
  { value: EInvoiceType.prepayment, intId: 'invoices.types-prepayment' }
];

const InvoiceModalEventsAndType = () => {
  const [searchValue, setSearchValue] = useState('');

  const intl = useIntl();
  const { values, errors, touched, handleChange, setValues } = useFormikContext<IInvoiceDialogForm>();
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useGetEventListInfinite({ isActive: true, searchValue });

  const eventOptions = data as Partial<IEvent<string>>[];

  const { disableForEventManager } = useGetDataByRole();
  const disableParamForEventManagerInOldInvoice = disableForEventManager && !!values?.id;

  const handleChangeEvent = (selectedObject: Partial<IEvent<string>> | null) => {
    if (!selectedObject) return;
    const contactPerson = selectedObject?.contactPeople?.length ? selectedObject?.contactPeople?.[0] : null;
    setValues({
      ...values,
      event: selectedObject,
      valueType: selectedObject.questionnaire?.taxType ?? values.valueType,
      venue: selectedObject.venue ?? null,
      recipient: contactPerson ? contactPerson : values.recipient,
      performanceDate: selectedObject.eventDate ?? values.performanceDate
    });
  };

  const updateSearch = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25}>
          <InputLabel htmlFor="Questionnaire">
            <FormattedMessage id="invoices.modal-field-event" />
          </InputLabel>
          <AutocompleteInfiniteSelect
            options={eventOptions}
            hasNextPage={hasNextPage}
            handleChange={handleChangeEvent}
            loading={isFetchingNextPage}
            isOptionEqualToValue={(option) => option.id === values?.event?.id}
            fetchNextPage={fetchNextPage}
            placeholder={intl.formatMessage({ id: 'events.select-here' })}
            values={values?.event ?? null}
            disabled={disableForEventManager}
            updateSearch={updateSearch}
          />
          {Boolean(touched['event'] && errors['event']) && (
            <FormHelperText error={Boolean(touched['event'] && errors['event'])}>{errors['event']}</FormHelperText>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25}>
          <InputLabel htmlFor="Questionnaire">
            <FormattedMessage id="invoices.modal-field-type" />
          </InputLabel>
          <Select value={values.type} name="type" onChange={handleChange} disabled={disableParamForEventManagerInOldInvoice}>
            {INVOICE_TYPES.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                <FormattedMessage id={item.intId} />
              </MenuItem>
            ))}
          </Select>
          {Boolean(touched['type'] && errors['type']) && (
            <FormHelperText error={Boolean(touched['type'] && errors['type'])}>{errors['type']}</FormHelperText>
          )}
        </Stack>
      </Grid>
    </>
  );
};

export default InvoiceModalEventsAndType;
