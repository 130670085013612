import { FormattedMessage } from 'react-intl';
import { FormHelperText, Grid, InputLabel, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useCurrentDateFormat } from 'shared/hooks/regionalSettings';
import { IOfferDialogForm } from 'shared/types/offer';
import { Calendar24Regular } from '@fluentui/react-icons';

interface IOfferModalDateFieldsProps {
  isRequestedEventDateShown: boolean;
}

const OfferModalDateFields = ({ isRequestedEventDateShown }: IOfferModalDateFieldsProps) => {
  const dateFormat = useCurrentDateFormat();
  const { values, errors, touched, setFieldValue } = useFormikContext<IOfferDialogForm>();

  const handleChangeOfferDate = (value: Date | null) => {
    if (!value) return;
    setFieldValue('offerDate', value);
  };

  const handleChangeRequestedDate = (value: Date | null) => {
    if (!value) return;
    setFieldValue('requestedEventDate', value);
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25}>
          <InputLabel>
            <FormattedMessage id="offer.modal-field-offer-date" />
          </InputLabel>
          <DatePicker
            value={values.offerDate ? new Date(values.offerDate.toString()) : null}
            format={dateFormat?.format}
            onChange={handleChangeOfferDate}
            slots={{ openPickerIcon: Calendar24Regular }}
          />
          {Boolean(touched['offerDate'] && errors['offerDate']) && (
            <FormHelperText error={Boolean(touched['offerDate'] && errors['offerDate'])}>{errors['offerDate']}</FormHelperText>
          )}
        </Stack>
      </Grid>
      {isRequestedEventDateShown && (
        <Grid item xs={12} sm={6}>
          <Stack spacing={1.25}>
            <InputLabel htmlFor="Questionnaire">
              <FormattedMessage id="offer.modal-field-requested-event-date" />
            </InputLabel>
            <DatePicker
              value={values.requestedEventDate ? new Date(values.requestedEventDate.toString()) : null}
              format={dateFormat?.format}
              onChange={handleChangeRequestedDate}
              slots={{ openPickerIcon: Calendar24Regular }}
            />
            {Boolean(touched['requestedEventDate'] && errors['requestedEventDate']) && (
              <FormHelperText error={Boolean(touched['requestedEventDate'] && errors['requestedEventDate'])}>
                {errors['requestedEventDate']}
              </FormHelperText>
            )}
          </Stack>
        </Grid>
      )}
    </>
  );
};

export default OfferModalDateFields;
