import { Box } from '@mui/material';
import { ArticlePriceType, IArticleForOrgPlanPreview } from 'modules/articles/types';
import { IOrganisationPlanChoiceOptionLabels } from 'modules/organisation-plan-templates/types/organisationPlanChoiceWidgetType';

interface IPreviewArticleValuesChoiceWidgetProps {
  id: string;
  articles?: IArticleForOrgPlanPreview[];
  optionLabels: IOrganisationPlanChoiceOptionLabels[];
}

const PreviewArticleValuesChoiceWidget = ({ id, articles, optionLabels }: IPreviewArticleValuesChoiceWidgetProps) => {
  let result = '';
  if (articles?.length) {
    const filteredArticles = articles.filter((a) => a?.widgetOptionId === id);
    const optionLabel = optionLabels?.find((o) => o.id === filteredArticles[0]?.widgetOptionId);
    const isFirstUnitValueProvided = filteredArticles[0]?.article?.price?.unit;

    if (filteredArticles?.length) {
      if (filteredArticles[0]?.article?.price?.articlePriceType === ArticlePriceType.Numerically) {
        const isSecondUnitValueProvided = filteredArticles[0]?.article?.price?.numericallyInfo?.secondUnit;
        if (optionLabel) {
          if (optionLabel?.numericallyValue) {
            if (isFirstUnitValueProvided) {
              result = `: ${optionLabel?.numericallyValue} (${isFirstUnitValueProvided})`;
            } else {
              result = `: ${optionLabel?.numericallyValue}`;
            }
          }
          if (isSecondUnitValueProvided && optionLabel?.secondUnitValue) {
            result += `; ${optionLabel?.secondUnitValue} (${isSecondUnitValueProvided});`;
          }
        }
      } else if (
        filteredArticles[0]?.article?.price?.articlePriceType === ArticlePriceType.FlatRate &&
        !filteredArticles[0]?.article?.price?.flatRateInfo?.isTotalPrice
      ) {
        if (optionLabel?.countOfGuests) {
          result = `: ${optionLabel?.countOfGuests}`;
        }
        if (isFirstUnitValueProvided && result) {
          result += ` (${isFirstUnitValueProvided});`;
        }
      }
    }
  }
  return <Box sx={{ display: 'inline', fontWeight: 400 }}>{result}</Box>;
};

export default PreviewArticleValuesChoiceWidget;
