import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IBoxImageWrapperProps {
  isOptionSelected: boolean;
}
interface IGeneralLabelTypographyWrapper {
  isMainLabelHidden: boolean;
}

export const BoxWrapper = styled(Box)(() => ({
  marginBottom: '20px',
  width: '100%'
}));

export const GeneralLabelTypographyWrapper = styled(Typography)<IGeneralLabelTypographyWrapper>(({ isMainLabelHidden }) => ({
  fontWeight: 700,
  fontSize: '14px',
  color: '#262626',
  marginBottom: '0px',
  display: isMainLabelHidden ? 'none' : 'block'
}));

export const BoxImagesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between'
}));

export const BoxImageWrapper = styled(Box)<IBoxImageWrapperProps>(({ isOptionSelected, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  opacity: !isOptionSelected ? 0.5 : 'initial',
  // border: isOptionSelected ? `1px solid ${theme.palette.primary.main}` : 'initial',
  borderRadius: '6px',
  pointerEvents: !isOptionSelected ? 'none' : 'initial',
  background: !isOptionSelected
    ? theme.palette.mode === 'dark'
      ? `repeating-linear-gradient(-45deg, #ffffff50, #ffffff50 10px, #ffffff25 10px, #ffffff25 20px)`
      : `repeating-linear-gradient(-45deg, #00000050, #00000050 10px, #00000025 10px, #00000025 20px)`
    : '',
  boxShadow: 'none'
}));

export const BoxDashedImageContainer = styled(Box)(() => ({
  border: '1px dashed #D9D9D9',
  display: 'flex',
  flex: '1 0 auto',
  borderRadius: '6px',
  width: '206px',
  height: '188px',
  '&>img': {
    objectFit: 'contain',
    width: '100%',
    height: '100%'
  }
}));

export const BoxImageLabelContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const ImageLabelValueTypography = styled(Typography)(() => ({
  fontWeight: 450,
  fontSize: '14px'
}));

export const BoxImageLabelController = styled(Box)(() => ({
  textAlign: 'center'
}));
