import { lazy } from 'react';
import Loadable from 'shared/components/Loadable';
import AuthGuard from 'shared/components/route-guard/AuthGuard';
import MainLayout from 'shared/layouts/MainLayout';

const UsersPage = Loadable(lazy(() => import('pages/Users')));
const CreateUser = Loadable(lazy(() => import('pages/Users/CreateUser')));
const EditUser = Loadable(lazy(() => import('pages/Users/EditUser')));

export const UserRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '/users',
          element: <UsersPage />
        },
        {
          path: '/user',
          children: [
            {
              path: ':id',
              element: <EditUser />
            },
            {
              path: 'create',
              element: <CreateUser />
            }
          ]
        }
      ]
    }
  ]
};
