import { getPlainTextFromHTML } from 'shared/utils/getPlainTextFromHTML';
import { MultipleChoiceWidgetType, SingleChoiceWidgetType } from 'modules/builder/types/choiceWidgetsType';
import { MultipleImgChoiceWdigetType, SingleImgChoiceWidgetType } from 'modules/builder/types/imageWidgetsTypes';
import { InputIntegerWidgetType } from 'modules/builder/types/inputIntegerWidget';
import { TableWidgetType } from 'modules/builder/types/tableWidgetType';
import { InputTextWidgetType } from 'modules/builder/types/textInputWidgetType';
import { TimeWidgetType } from 'modules/builder/types/timePickerType';
import { IUploadAreaWidgetsType } from 'modules/builder/types/uploadAreaWidgetsType';
import { IQuestionnaireImageItemDataSingleWidget } from 'modules/questionnaires/types/questionnaries';
import { ORGANISATION_PLAN_WIDGET_TYPES } from 'modules/organisation-plan-templates/types';
import {
  MultipleChoiceOrganisationPlanWidgetType,
  SingleChoiceOrganisationPlanWidgetType
} from 'modules/organisation-plan-templates/types/organisationPlanChoiceWidgetType';
import {
  MultipleImageChoiceWithReplacementWidgetType,
  SingleImageChoiceWithReplacementWidgetType
} from 'modules/organisation-plan-templates/types/organisationPlanImageWidgetTypes';
import { OrganisationPlanInputNumberWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanInputNumberWithReplacementType';
import { OrganisationPlanInputTextWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanInputTextWithReplacementType';
import { OrganisationPlanTableWithReplacementWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanTableWithReplacementWidgetType';
import { OrganisationPlanTimePickerWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanTimePickerWithReplacementType';

export const generateInputTextWidget = (widgetData: InputTextWidgetType) => {
  return {
    layout: {
      i: widgetData.widgetId,
      x: 0,
      y: Infinity,
      h: 7,
      w: 100,
      isDraggable: true
    },
    widget: {
      type: ORGANISATION_PLAN_WIDGET_TYPES.inputText,
      label: widgetData.label as string,
      replacementLabel: '',
      value: widgetData.value as string,
      widgetId: widgetData.widgetId
    } as OrganisationPlanInputTextWithReplacementType
  };
};

export const generateInputNumberWidget = (widgetData: InputIntegerWidgetType) => {
  return {
    layout: {
      i: widgetData.widgetId,
      x: 0,
      y: Infinity,
      h: 7,
      w: 100,
      isDraggable: true
    },
    widget: {
      type: ORGANISATION_PLAN_WIDGET_TYPES.inputInteger,
      label: widgetData.label as string,
      replacementLabel: '',
      value: widgetData.value as number,
      widgetId: widgetData.widgetId
    } as OrganisationPlanInputNumberWithReplacementType
  };
};

export const generateTimePickerWidget = (widgetData: TimeWidgetType) => {
  return {
    layout: {
      i: widgetData.widgetId,
      x: 0,
      y: Infinity,
      h: 7,
      w: 100,
      isDraggable: true
    },
    widget: {
      type: ORGANISATION_PLAN_WIDGET_TYPES.time,
      date: widgetData.date,
      label: widgetData.label,
      typeValue: widgetData.typeValue,
      replacementLabel: '',
      widgetId: widgetData.widgetId
    } as OrganisationPlanTimePickerWithReplacementType
  };
};

export const generateSingleChoiceWidget = (widgetData: SingleChoiceWidgetType) => {
  const widgetDataWithReplacementOptions = widgetData.optionLabels.map((optionData) => ({ ...optionData, replacementLabel: '' }));
  return {
    layout: {
      i: widgetData.widgetId,
      x: 0,
      y: Infinity,
      h: 4,
      minH: 5,
      w: 100,
      isDraggable: true
    },
    widget: {
      type: ORGANISATION_PLAN_WIDGET_TYPES.singleChoice,
      optionLabels: widgetData.optionLabels,
      label: widgetData.label,
      selectedOption: widgetData.selectedOption,
      replacementLabel: '',
      replacementOptionsLabels: widgetDataWithReplacementOptions,
      widgetId: widgetData.widgetId,
      showOnlySelectedAnswer: true
    } as SingleChoiceOrganisationPlanWidgetType
  };
};

export const generateMultipleChoiceWidget = (widgetData: MultipleChoiceWidgetType) => {
  const widgetDataWithReplacementOptions = widgetData.optionLabels.map((optionData) => ({ ...optionData, replacementLabel: '' }));
  return {
    layout: {
      i: widgetData.widgetId,
      x: 0,
      y: Infinity,
      h: 5,
      minH: 5,
      w: 100,
      isDraggable: true
    },
    widget: {
      type: ORGANISATION_PLAN_WIDGET_TYPES.multipleChoice,
      optionLabels: widgetData.optionLabels,
      label: widgetData.label,
      selectedOptions: widgetData.selectedOptions,
      replacementLabel: '',
      replacementOptionsLabels: widgetDataWithReplacementOptions,
      widgetId: widgetData.widgetId,
      showOnlySelectedAnswer: true
    } as MultipleChoiceOrganisationPlanWidgetType
  };
};

export const generateMultipleChoiceForGuestAndRoomsWidget = (widgetData: MultipleChoiceWidgetType) => {
  const widgetDataWithReplacementOptions = widgetData.optionLabels.map((optionData) => ({ ...optionData, replacementLabel: '' }));
  return {
    layout: {
      i: widgetData.widgetId,
      x: 0,
      y: Infinity,
      h: 10,
      w: 100,
      isDraggable: true
    },
    widget: {
      type: ORGANISATION_PLAN_WIDGET_TYPES.multipleChoice,
      optionLabels: widgetData.optionLabels,
      label: getPlainTextFromHTML('p', widgetData.label),
      selectedOptions: widgetData.selectedOptions,
      replacementLabel: '',
      replacementOptionsLabels: widgetDataWithReplacementOptions,
      widgetId: widgetData.widgetId,
      showOnlySelectedAnswer: true
    } as MultipleChoiceOrganisationPlanWidgetType
  };
};

export const generateSingleImageChoiceWidget = (widgetData: SingleImgChoiceWidgetType) => {
  const widgetDataWithReplacementOptions = widgetData.dataImage?.map((optionData) => ({ ...optionData, replacementLabel: '' }));
  return {
    layout: {
      i: widgetData.widgetId,
      x: 0,
      y: Infinity,
      h: 5,
      w: 100,
      isDraggable: true
    },
    widget: {
      type: ORGANISATION_PLAN_WIDGET_TYPES.singleImageChoice,
      dataImage: widgetData.dataImage,
      selectedOption: widgetData.selectedOption,
      typeImage: widgetData.typeImage,
      label: widgetData.label,
      replacementLabel: '',
      replacementDataImage: widgetDataWithReplacementOptions as IQuestionnaireImageItemDataSingleWidget[],
      widgetId: widgetData.widgetId,
      showOnlySelectedAnswer: true
    } as SingleImageChoiceWithReplacementWidgetType
  };
};

export const generateMultipleImageChoiceWidget = (widgetData: MultipleImgChoiceWdigetType) => {
  const widgetDataWithReplacementOptions = widgetData.dataImage?.map((optionData) => ({ ...optionData, replacementLabel: '' }));
  return {
    layout: {
      i: widgetData.widgetId,
      x: 0,
      y: Infinity,
      h: 6,
      w: 100,
      isDraggable: true
    },
    widget: {
      type: ORGANISATION_PLAN_WIDGET_TYPES.multipleImageChoice,
      dataImage: widgetData.dataImage,
      selectedOptions: widgetData.selectedOptions,
      typeImage: widgetData.typeImage,
      label: widgetData.label,
      replacementLabel: '',
      replacementDataImage: widgetDataWithReplacementOptions as IQuestionnaireImageItemDataSingleWidget[],
      widgetId: widgetData.widgetId,
      showOnlySelectedAnswer: true
    } as MultipleImageChoiceWithReplacementWidgetType
  };
};

export const generateTableWidget = (widgetData: TableWidgetType) => {
  return {
    layout: {
      i: widgetData.widgetId,
      x: 0,
      y: Infinity,
      h: 5,
      minH: 5,
      w: 100,
      isDraggable: true
    },
    widget: {
      ...widgetData,
      replacementLabel: '',
      widgetId: widgetData.widgetId
    } as OrganisationPlanTableWithReplacementWidgetType
  };
};

export const generateUploadAreaWidget = (widgetData: IUploadAreaWidgetsType) => {
  return {
    layout: {
      i: widgetData.widgetId,
      x: 0,
      y: Infinity,
      h: 10,
      w: 100,
      isDraggable: true
    },
    widget: { ...widgetData, replacementLabel: '', widgetId: widgetData.widgetId }
  };
};
