import { Box } from '@mui/material';
import { FieldArray } from 'formik';
import DynamicEmailAndPhone from './DynamicEmailAndPhone';

const CreateCustomerFormAdditionalEmailAndPhoneInfo = () => {
  return (
    <Box sx={{ p: '20px 0', mt: '-40px' }}>
      <FieldArray name="additionalContacts" component={DynamicEmailAndPhone} />
    </Box>
  );
};

export default CreateCustomerFormAdditionalEmailAndPhoneInfo;
