import { SelectProps } from '@mui/material';
import { REQUEST_STATE_CHIPS } from 'modules/requests/constant/stateRequest';
import StatusSelect from 'shared/components/StatusSelect';
import { ERequestStatus } from 'shared/types/requests';

interface IOfferStatusSelect {
  value: ERequestStatus;
  handleChange: SelectProps['onChange'];
}

const OfferStatusSelect = ({ handleChange, value, ref: _ref, type: _type, ...rest }: IOfferStatusSelect & SelectProps) => {
  const filteredStatuses = Object.entries(REQUEST_STATE_CHIPS)
    .filter(([key]) => key !== ERequestStatus.CreationFailed)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  return <StatusSelect {...rest} name="state" value={value} handleChange={handleChange} options={filteredStatuses} />;
};

export default OfferStatusSelect;
