import { Layout } from 'react-grid-layout';
import {
  IArticlePage,
  IGuestAndRoomsDateSectionDB,
  IUsedArticlesForSend,
  IUsedArticlesQuestionnairesPages,
  WidgetsType
} from 'modules/builder/types';
import {
  IQuestionnaireImageItemData,
  QuestionnairePageItemArticle,
  QuestionnairePageType
} from 'modules/questionnaires/types/questionnaries';
import { Tenant, Venue } from 'modules/questionnaires/types';
import { VisibilityObjectType } from 'modules/builder/components/SettingsBar/VisibilityTab/types';

type QuestionnaireTemplateChildPageType<T> = Omit<QuestionnaireTemplatePageItem<T>, 'child'>;

export interface QuestionnaireTemplatePageItem<T = unknown> {
  id: number;
  name: string;
  pageNumber: number;
  questionnairePageType: QuestionnairePageType;
  child: QuestionnaireTemplateChildPageType<T>[];
  parentPageId: number | undefined;
  data: T;
  usedWidgets?: WidgetsType[];
  usedArticles?: IUsedArticlesQuestionnairesPages[];
  headerImageId: number;
  headerImage: IQuestionnaireImageItemData;
  layout: Layout[];
  questionnaireTemplatePageArticles: QuestionnairePageItemArticle[] | null;
  articles: IArticlePage[];
  parentPageSystemId: string;
  parentSystemPageId?: string; // TODO: should be one field parentPageSystemId or parentSystemPageId (used to support events pages)
  systemId: string;
  isHidden?: boolean;
  questionnaireId: number;
  visibility?: VisibilityObjectType;
}

export interface CreateUpdateQuestionnaireTemplatePageRequestData<L = unknown, D = unknown, W = unknown> {
  name: string;
  questionnairePageType: QuestionnairePageType;
  tenantId: number;
  isHidden?: boolean;
  questionnaireTemplateId?: number;
  questionnaireId?: number | string;
  headerImageId?: number | null;
  layout?: L | null;
  data?: D | null;
  usedWidgets?: W | null;
  parentPageId?: number | null;
  visibility?: VisibilityObjectType;
  questionnaireTemplatePageArticles?: QuestionnairePageItemArticle[] | null;
  usedArticles?: IUsedArticlesForSend[];
  dateSection?: IGuestAndRoomsDateSectionDB;
}

export enum QuestionnaireTemplatesQueryKeys {
  GetTemplatePageById = 'questionnaires/template/page',
  GetQuestionnerPageById = 'questionnaires/page',
  GetQuestionnaireById = 'questionnaire',
  GetQuestionnaires = 'questionnaires'
}

export interface IOrderPagePayload {
  pageId: number;
  position: number;
}

export type OrderPagePayloadType = IOrderPagePayload[];

export interface IFormatData {
  numberOfGuests: string;
  rooms: string;
  numberOfAdult: string;
  numberOfChildren: string;
  numberOfYoungPeople: string;
  typeNameHere: string;
  room1: string;
  room2: string;
  dateSectionQuestion: string;
  preferredEventDate: string;
  estimatedTime: string;
  alternateDates: string;
}

export interface IQuestionnaireListItem {
  id: number;
  tenantId: number;
  name: string;
  isActive: boolean;
  taxType: string;
  eventType: string;
  venue: Venue;
  createdAt: string;
  tenant: Tenant;
  activeEventCount: number;
}

export enum EQuestionnairesSource {
  event = 'event',
  request = 'request',
  template = 'template'
}
