import { FormattedMessage } from 'react-intl';
import { ContactCard20Regular, DataLine20Regular, Database20Regular } from '@fluentui/react-icons';
import { NavItemType } from 'shared/types/menu';

const widget: NavItemType = {
  id: 'group-widget',
  title: <FormattedMessage id="widgets" />,
  type: 'group',
  children: [
    {
      id: 'statistics',
      title: <FormattedMessage id="statistics" />,
      type: 'item',
      url: '/mantis/widget/statistics',
      icon: ContactCard20Regular
    },
    {
      id: 'data',
      title: <FormattedMessage id="data" />,
      type: 'item',
      url: '/mantis/widget/data',
      icon: Database20Regular
    },
    {
      id: 'chart',
      title: <FormattedMessage id="chart" />,
      type: 'item',
      url: '/mantis/widget/chart',
      icon: DataLine20Regular
    }
  ]
};

export default widget;
