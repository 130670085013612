import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';
import { StarSettings20Regular } from '@fluentui/react-icons';
import { useGetEventsFilter } from 'modules/dashboard/hooks/useGetEventTypesFilter';
import { useUpdateWidgetById } from 'api/hooks/dashboard/useUpdateWidgetByID';
import { textError } from 'shared/utils/infoText';
import { useInfoMessage } from 'shared/hooks/useInfoMessage';
import { IUpdateWidgetStore } from 'modules/dashboard/components/widgets/TableDashboardWidget/components/UpperHeaderTable';
import { containsText } from 'modules/dashboard/utils';
import { WidgetsDashboardType } from 'modules/dashboard/types';
import GeneralFilter from './GeneralFilter';

interface IEventTypesFilter {
  widget?: WidgetsDashboardType;
  isCustomize: boolean;
  updateWidgetFilters: ({ field, value }: IUpdateWidgetStore) => void;
}
const EventTypesFilter = ({ widget, updateWidgetFilters, isCustomize }: IEventTypesFilter) => {
  const { successMessage, errorMessage } = useInfoMessage();
  const [searchText, setSearchText] = useState('');
  const intl = useIntl();
  const { mutateAsync: updateWidgetById, isLoading } = useUpdateWidgetById({
    onSuccess() {
      successMessage(intl.formatMessage({ id: 'dashboard.dashboard-widget-edited-successfully' }));
    },
    options: {
      onError() {
        errorMessage(textError(intl.formatMessage({ id: 'dashboard.dashboard-widget-edited-failed' })));
      }
    },
    widgetId: widget?.id?.toString() || ''
  });
  const { data: eventsData } = useGetEventsFilter();

  const handleEventStatesChange = async (data: (number | string)[]) => {
    if (!widget) return;
    if (!widget.id || isCustomize) updateWidgetFilters({ field: 'eventTypes', value: data });

    // Check if the value has changed
    const hasValueChanged = !isEqual(widget.filters?.eventTypes, data);

    if (widget.id && !isCustomize && hasValueChanged) {
      await updateWidgetById(
        {
          ...widget,
          filters: {
            ...widget.filters,
            eventTypes: data as string[]
          },
          systemId: widget.systemId
        },
        {
          onSuccess: () => {
            updateWidgetFilters({ field: 'eventTypes', value: data });
          }
        }
      );
    }
  };

  const onInputChange = (searchText: string) => {
    setSearchText(searchText);
  };

  const displayedOptions = useMemo(
    () => eventsData && eventsData.filter((option) => containsText(option.label, searchText)),
    [eventsData, searchText]
  );

  return (
    <GeneralFilter
      onInputChange={onInputChange}
      sx={{}}
      values={widget?.filters?.eventTypes || []}
      onChange={handleEventStatesChange}
      sxControl={{}}
      label={intl.formatMessage({ id: 'dashboard-table.all-event-types' })}
      tooltipLabel={intl.formatMessage({ id: 'dashboard-table.filter-by-eventtype' })}
      icon={<StarSettings20Regular />}
      textAllOptions={intl.formatMessage({ id: 'dashboard-table.all-event-types' })}
      allOptions={displayedOptions || []}
      isUpdated={isLoading}
    />
  );
};

export default EventTypesFilter;
