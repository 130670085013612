import { styled } from '@mui/material/styles';
import { Box, Grid, Stack, Typography } from '@mui/material';

export const Content = styled(Grid)(() => ({
  padding: '10px',
  height: '100%',
  '& .MuiSelect-select.MuiSelect-outlined': {
    padding: '9px 12px'
  }
}));

export const DisabledButtonIcon = styled(Box)(() => ({
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  left: '10px',
  marginLeft: '10px',
  width: '80%',
  height: '100%',
  padding: '0',
  margin: '0'
}));

export const StackSide = styled(Stack)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  '& .MuiTypography-root': {
    padding: '8px 6px'
  }
}));
export const StackSideRight = styled(Stack)(({ theme }) => ({
  color: theme.palette.secondary.dark
}));

export const BoldText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontWeight: 600,
  margin: '0px 5px 0 30px',
  textWrap: 'nowrap'
}));

export const boxTax = {
  width: 85,
  '& .MuiOutlinedInput-root': {
    width: '100%'
  }
};

export const boxState = {
  width: 155,
  '& .MuiOutlinedInput-root': {
    width: '100%'
  }
};
