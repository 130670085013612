import { useEffect, useMemo, useRef, useState } from 'react';
import { Layout } from 'react-grid-layout';
import { useDispatch } from 'store';
import { updateDashboardLayoutPartially } from 'store/dashboard/dashboard.slice';
import { getDashboardWidgetRowsAmount } from 'modules/dashboard/utils';

interface IUseUpdateLayoutWidget {
  widgetSystemId: string;
  dependencies?: Array<unknown>;
  layout?: Layout;
}

/** This hook uses to update widget height (`layout.h: {newHeight}`) when content of widget has changed;
 * `(!) Important`: if param `layout` is missing - update of height widget will be skipped!
 * @param widgetSystemId: systemId of widget
 * @param dependencies: list of dependencies for border redraw
 * @param layout: layout of widget
 */
const useUpdateLayoutDashboardWidget = ({ widgetSystemId, dependencies = [], layout }: IUseUpdateLayoutWidget) => {
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [isWidgetMounted, setIsWidgetMounted] = useState(false);

  useEffect(() => {
    setIsWidgetMounted(true);
    return () => setIsWidgetMounted(false);
  }, []);

  const useEffectDependencies = useMemo(() => {
    return dependencies ?? [];
  }, [dependencies]);
  useEffect(() => {
    const heightWidget = ref?.current?.getBoundingClientRect()?.height;
    if (!isWidgetMounted || typeof layout?.h !== 'number' || typeof heightWidget !== 'number') return;

    const newHeightForWidget = getDashboardWidgetRowsAmount(heightWidget);

    if (layout?.h === newHeightForWidget) return;

    dispatch(
      updateDashboardLayoutPartially({
        ...layout,
        h: newHeightForWidget,
        i: widgetSystemId
      })
    );
  }, [isWidgetMounted, dispatch, layout?.h, useEffectDependencies]);
  return ref;
};

export default useUpdateLayoutDashboardWidget;
