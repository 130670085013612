import { ChangeEvent } from 'react';
import { Card, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import useConfig from 'shared/hooks/useConfig';
import { FontFamily } from 'shared/types/config';

const ThemeFont = () => {
  const theme = useTheme();

  const { fontFamily, onChangeFontFamily } = useConfig();

  const handleFontChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeFontFamily(event.target.value as FontFamily);
  };

  const fonts = [
    {
      id: 'archivio',
      value: "'Archivo', sans-serif",
      label: 'Archivo'
    },
    {
      id: 'montserrat',
      value: "'Montserrat', sans-serif",
      label: 'Montserrat'
    },
    {
      id: 'inter',
      value: "'Inter', sans-serif",
      label: 'Inter'
    },
    {
      id: 'public-sans',
      value: "'Public Sans', sans-serif",
      label: 'Public Sans'
    },
    {
      id: 'poppins',
      value: "'Poppins', sans-serif",
      label: 'Poppins'
    },
    {
      id: 'roboto',
      value: "'Roboto', sans-serif",
      label: 'Roboto'
    },
    {
      id: 'm-plus-code-latin',
      value: "'M PLUS Code Latin', sans-serif",
      label: 'M PLUS Code Latin'
    },
    {
      id: 'manrope',
      value: "'Manrope', sans-serif",
      label: 'Manrope'
    }
  ];

  const sortedFonts = [...fonts].sort((a, b) => a.label.localeCompare(b.label));

  return (
    <RadioGroup row aria-label="payment-card" name="payment-card" value={fontFamily} onChange={handleFontChange} sx={{ ml: '10px' }}>
      <Grid container spacing={1} p="0px">
        {sortedFonts.map((item, index) => (
          <Grid item key={index} xs={6} sx={{ '& .MuiFormControlLabel-root': { width: '100%' } }}>
            <FormControlLabel
              sx={{ '& .MuiTypography-root': { width: '100% !important' } }}
              control={<Radio value={item.value} sx={{ display: 'none' }} />}
              label={
                <Card
                  sx={{
                    borderRadius: '6px',
                    width: '100%',
                    boxShadow: 'rgba(0, 0, 0, 0.01) 0px 6px 6px 0px, rgba(0, 0, 0, 0.07) 0px 0px 0px 1px',
                    bgcolor: fontFamily === item.value ? 'primary.lighter' : alpha(theme.palette.secondary.lighter, 0.5),
                    p: '8px 12px',
                    '&:hover': { bgcolor: 'primary.lighter' }
                  }}
                  {...(fontFamily === item.value && { boxShadow: true, shadow: theme.customShadows.primary })}
                >
                  <Stack width="100%" spacing={0} alignItems="center" justifyContent="center">
                    <Typography width="100%" variant="h5" color="textPrimary" sx={{ fontFamily: item.value }}>
                      Aa 123
                    </Typography>
                    <Typography width="100%" variant="caption" color="textSecondary" sx={{ fontSize: 11, fontFamily: item.value }}>
                      {item.label}
                    </Typography>
                  </Stack>
                </Card>
              }
            />
          </Grid>
        ))}
      </Grid>
    </RadioGroup>
  );
};

export default ThemeFont;
