import { UseMutationOptions, useMutation } from 'react-query';
import axios from 'axios';
import { IEditorArticle } from 'modules/editor/types/editor';

const fetchPrepaymentItems = (id: number): Promise<IEditorArticle[]> => {
  return axios.get(`events/${id}/items/prepayments`).then((res) => res.data);
};

interface IuseGetInvoicePrepaymentItems<TError, TContext> {
  options?: Omit<UseMutationOptions<IEditorArticle[], TError, number, TContext>, 'mutationFn' | 'onSuccess' | 'variables'>;
  onSuccess?: (response: IEditorArticle[]) => void;
}

export function useGetInvoicePrepaymentItems<TError, TContext>({ options }: IuseGetInvoicePrepaymentItems<TError, TContext>) {
  return useMutation(fetchPrepaymentItems, { ...options });
}
