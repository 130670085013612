import { useQuery } from 'react-query';
import axios from 'axios';
import { TableDashboardWidgetType } from 'modules/dashboard/types/TableDashboardWidgetType';

const fetchInfoShareWidget = async (token: string): Promise<TableDashboardWidgetType> => {
  return axios.get(`dashboard-widgets/${token}`).then((res) => res.data);
};

export function useGetWidgetByToken(token?: string) {
  return useQuery<TableDashboardWidgetType>(['widget-share', { token }], () => fetchInfoShareWidget(token ?? ''), {
    enabled: !!token,
    refetchOnMount: false
  });
}
