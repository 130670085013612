import { Layout } from 'react-grid-layout';
import { KeyNumbersWidgetType } from './KeyNumbersWidgetType';
import { WeatherWidgetType } from './WeatherWidgetType';
import { PieChartWidgetType } from './PieChartWidgetType';
import { CustomListWidgetType } from './CustomListWidgetType';
import { EventListWidgetType } from './EventListWidgetType';
import { ChartWidgetType } from './ChartWidgetType';
import { TableDashboardWidgetType } from './TableDashboardWidgetType';

export interface ITagInfo {
  dashboardWidgetId?: number;
  articleTagId: number;
  statisticType: ETagData;
  name?: string;
}

export interface IGeneralValue {
  value: number;
  label: string;
}

export interface BaseDashboardWidget {
  id: number;
  groupId: string;
  userDashboardId: number;
  dashboardWidgetTags: ITagInfo[];
  /**
   * field used just to pass all fetched tags
   */
  dataTags?: ITags[];
  tags?: { id: number; statisticType: string }[];
  systemId: string;
  layout?: Layout;
  filters: {
    venues: number[];
    eventTypes: string[];
    eventStates: string[];
    invoiceStates: string[];
    eventManagers: number[];
    questionnaires: number[];
    timeRange: {
      from: Date | null;
      to: Date | null;
      type: string;
    };
  };
}

export enum DASHBOARD_WIDGET_TYPES {
  table = 'Table',
  keyNumbers = 'KeyNumbers',
  weather = 'Weather',
  pieChart = 'PieChart',
  customList = 'CustomList',
  eventList = 'EventList',
  chart = 'Chart'
}

export interface ITags {
  id: number;
  name: string;
}

export type TagDataType = 'Revenue' | 'Sold' | 'Booked' | 'NumberBookings' | 'NumberUnitsSold';

export enum ETagData {
  Revenue = 'Revenue',
  Sold = 'Sold',
  Booked = 'Booked',
  NumberBookings = 'NumberBookings',
  NumberUnitsSold = 'NumberUnitsSold'
}

export interface ITagsTranslations {
  type: ETagData;
  intlId: string;
}

export type WidgetsDashboardType =
  | TableDashboardWidgetType
  | KeyNumbersWidgetType
  | WeatherWidgetType
  | PieChartWidgetType
  | CustomListWidgetType
  | EventListWidgetType
  | ChartWidgetType;
