import { EQuestionnairesSource } from 'shared/types/questionnaire-template';
import { BUILDER_WIDGET_TYPES, BuilderLayoutVariant, LayoutWithWidgetsConfig } from 'modules/builder/types';
import { IMAGE_FIT_TYPE } from 'modules/builder/types/imageWidgetsTypes';
import { createInitPhotos } from 'modules/builder/utils/builder-widgets/choiseImage';
import { createInitLabels } from 'modules/builder/utils/builder-widgets/singleChoice';

export const GRID_LAYOUT_SIZE = {
  rowHeight: 1,
  rowGap: 10,
  widthGrid: 926,
  col: 48,
  mobileColumnsCount: 1,
  gridPaddingDesktop: 40,
  gridPaddingMobile: 10
};

export const HIDE_ADD_NEW_PAGE_AMOUNT = 3;
export const HIDE_ADD_NEW_PAGE_AMOUNT2 = {
  [EQuestionnairesSource.template]: 3,
  [EQuestionnairesSource.request]: 2,
  [EQuestionnairesSource.event]: 2
};

export const BUILDER_ROUTES = {
  BUILDER: '/builder',
  BUILDER_PAGE_BY_ID: (questionnaireId: string, pageId?: string, subPageId?: string) =>
    `/builder/${questionnaireId}${pageId ? `/${pageId}` : ''}${subPageId ? `/${subPageId}` : ''}`,
  PLAYGROUND_PAGE_BY_ID: (questionnaireId: string, pageId: string, subPageId?: string) =>
    `/playground/${questionnaireId}${pageId ? `/${pageId}` : ''}${subPageId ? `/${subPageId}` : ''}`
};

export const BUILDER_DEFAULT_LAYOUTS: Record<BuilderLayoutVariant, LayoutWithWidgetsConfig> = {
  [BuilderLayoutVariant.Layout1]: {
    layout: [
      {
        w: 48,
        h: 8,
        x: 0,
        y: 0,
        moved: false,
        static: false
      },
      {
        w: 17,
        h: 16,
        x: 0,
        y: 8,
        moved: false,
        static: false
      },
      {
        w: 31,
        h: 16,
        x: 24,
        y: 0,
        minW: 3,
        minH: 8,
        maxH: 8,
        moved: false,
        static: false,
        resizeHandles: ['e']
      },
      {
        w: 48,
        h: 8,
        x: 0,
        y: 40,
        moved: false,
        static: false
      }
    ],
    widgets: [
      {
        type: BUILDER_WIDGET_TYPES.text,
        content: '',
        hasScroll: false
      },
      {
        type: BUILDER_WIDGET_TYPES.image,
        data: null,
        label: '',
        typeImage: IMAGE_FIT_TYPE.contain
      },
      {
        type: BUILDER_WIDGET_TYPES.singleChoice,
        optionLabels: createInitLabels(3),
        label: '',
        selectedOption: '',
        numericallyValue: null,
        secondUnitValue: null,
        countOfGuests: null
      },
      {
        type: BUILDER_WIDGET_TYPES.inputText,
        label: '',
        value: '',
        numericallyValue: null,
        secondUnitValue: null,
        countOfGuests: null
      }
    ]
  },
  [BuilderLayoutVariant.Layout2]: {
    layout: [],
    widgets: []
  },
  [BuilderLayoutVariant.Layout3]: {
    layout: [
      {
        w: 48,
        h: 8,
        x: 0,
        y: 0,
        moved: false,
        static: false
      },
      {
        w: 48,
        h: 20,
        x: 4,
        y: 1,
        moved: false,
        static: false
      },
      {
        w: 48,
        h: 8,
        x: 0,
        y: 20,
        moved: false,
        static: false
      }
    ],
    widgets: [
      {
        type: BUILDER_WIDGET_TYPES.text,
        content: '',
        hasScroll: false
      },
      {
        type: BUILDER_WIDGET_TYPES.singleImageChoice,
        label: '',
        typeImage: IMAGE_FIT_TYPE.contain,
        dataImage: createInitPhotos(4),
        selectedOption: ''
      },
      {
        type: BUILDER_WIDGET_TYPES.inputText,
        label: '',
        value: '',
        numericallyValue: null,
        secondUnitValue: null,
        countOfGuests: null
      }
    ]
  }
};
