import { useState } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { FormattedMessage } from 'react-intl';
import { Button, Collapse } from '@mui/material';
import { IOrganisationPlanSection } from 'modules/organisation-plan-templates/types/organisationPlanSection';
import { selectDraggableItemOrganizationPlan } from 'store/organisation-plan-template/organisation-plan-template.selectors';
import { useDispatch, useSelector } from 'store';
import { setTitleOfSection } from 'store/organisation-plan-template/organisation-plan-template.slice';
import EditableLabel from 'modules/builder/components/EditableLabel';
import OrganisationPlanTemplatePlaygroundSection from 'modules/organisation-plan-templates/components/OrganisationPlanTemplatePlaygroundSection';
import SectionContextMenu from 'modules/organisation-plan-templates/components/SectionContextMenu';
import * as Styles from './OrganisationPlanTemplateSection.styles';

interface IOrganisationPlanTemplateSection {
  section: IOrganisationPlanSection;
  sectionIndex: number;
  isPaddingSection: boolean;
}

const OrganisationPlanTemplateSection = ({ section, sectionIndex, isPaddingSection }: IOrganisationPlanTemplateSection) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(true);

  const itemWidget = useSelector(selectDraggableItemOrganizationPlan);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleTitleSection = (title: string) => {
    dispatch(setTitleOfSection({ sectionId: section.sectionId, title }));
  };

  return (
    <Draggable draggableId={section.sectionId} index={sectionIndex}>
      {(provided) => (
        <Styles.SectionWrapper component="article" key={section.sectionId} ref={provided.innerRef} {...provided.draggableProps}>
          <Styles.SectionHeader isExpanded={expanded} {...provided.dragHandleProps}>
            <EditableLabel value={section.name} name="organisation-plan-section-title" onSubmit={handleTitleSection} />
            <Styles.HeaderActionsContainer>
              <Button sx={{ width: '100%' }} onClick={handleExpandClick} size="small">
                {expanded ? (
                  <FormattedMessage id="organisation-plan.collapse-section-btn" />
                ) : (
                  <FormattedMessage id="organisation-plan.uncollapse-section-btn" />
                )}
              </Button>
              <SectionContextMenu section={section} />
            </Styles.HeaderActionsContainer>
          </Styles.SectionHeader>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Styles.SectionContent isTemplate={true}>
              <OrganisationPlanTemplatePlaygroundSection
                isPaddingSection={isPaddingSection}
                itemWidget={itemWidget}
                layout={section.layout}
                sectionId={section.sectionId}
              />
            </Styles.SectionContent>
          </Collapse>
        </Styles.SectionWrapper>
      )}
    </Draggable>
  );
};

export default OrganisationPlanTemplateSection;
