import { useState } from 'react';
import { Editor, EditorState as EditorType } from 'react-draft-wysiwyg';
import { useIntl } from 'react-intl';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { FONT_FAMILY_OPTIONS } from 'modules/settings/constants';
import { CustomToolbar } from 'shared/components/DraftTextEditor/DraftTextEditor.styles';

interface TextEditorProps {
  value: string;
  onChange: ({ pureText, editedText }: { pureText: string; editedText: string }) => void;
}

const TextEditor = ({ value, onChange }: TextEditorProps) => {
  const intl = useIntl();
  const html = htmlToDraft(value);
  const currentLanguage = intl.locale;

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap))
  );
  const onEditorStateChange = (editor: EditorType) => {
    setEditorState(editor);
    const contentState = editor.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const pureText = contentState.getPlainText();
    const editedText = draftToHtml(rawContentState);
    onChange({ pureText, editedText });
  };

  return (
    <CustomToolbar>
      <Editor
        localization={{ locale: currentLanguage }}
        toolbar={{
          fontFamily: {
            options: FONT_FAMILY_OPTIONS
          },
          fontSize: {
            options: [
              8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 34, 36, 38, 40, 42, 44, 46,
              48, 50, 52, 54
            ]
          }
        }}
        toolbarStyle={{ position: 'relative', top: '0px' }}
        editorStyle={{
          overflow: 'auto',
          width: '100%',
          maxHeight: '450px'
        }}
        wrapperStyle={{ height: '100%', boxShadow: 'none' }}
        placeholder={intl.formatMessage({ id: 'builder.enter-text-here' })}
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
      />
    </CustomToolbar>
  );
};

export default TextEditor;
