import { BuilderPageItemType, IGuestAndRoomsCustomQuestion, IGuestAndRoomsPageFormDataDB } from 'modules/builder/types';
import { OrganisationPlanTemplateBuilderItem } from 'shared/types/organisation-plan';

interface IPreparedHeaderOptionsProps {
  guestAndRoomsPage?: BuilderPageItemType<IGuestAndRoomsPageFormDataDB>;
  organizationPlanTemplateBuilderData: OrganisationPlanTemplateBuilderItem;
  organizationPlanTemplateData?: OrganisationPlanTemplateBuilderItem;
  organizationPlanFormatMessage: string;
}

/**
 * Util function to prepare header data to set template header options.
 * @param param.guestAndRoomsPage guest and rooms page data.
 * @param param.organizationPlanTemplateBuilderData organisation plan template builder item.
 * @param param.organizationPlanTemplateData organisation plan template item.
 * @param param.organizationPlanFormatMessage organisation plan translation.
 * @returns prepared header data for organisation plan.
 */
export const preparedHeaderOptionsOrgPlanTemplate = ({
  guestAndRoomsPage,
  organizationPlanTemplateBuilderData,
  organizationPlanTemplateData,
  organizationPlanFormatMessage
}: IPreparedHeaderOptionsProps) => {
  const guestDisplayOptions = (guestAndRoomsPage?.data?.guestSection?.fields || []).map((o) => ({
    ...o,
    isCheck: organizationPlanTemplateBuilderData?.guestDisplayOptions?.find((i: IGuestAndRoomsCustomQuestion) => i.id === o.id)
      ? organizationPlanTemplateBuilderData?.guestDisplayOptions?.find((i: IGuestAndRoomsCustomQuestion) => i.id === o.id).isCheck
      : o.isCheck
  }));

  return {
    name: organizationPlanTemplateData?.name || organizationPlanFormatMessage,
    showEventName: organizationPlanTemplateBuilderData?.showEventName || false,
    showEventDate: organizationPlanTemplateBuilderData?.showEventDate || false,
    showContactPerson: organizationPlanTemplateBuilderData?.showContactPerson || false,
    showEventManager: organizationPlanTemplateBuilderData?.showEventManager || false,
    showNumberOfGuests: organizationPlanTemplateBuilderData?.showNumberOfGuests || false,
    guestDisplayOptions
  };
};
