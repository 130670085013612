import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { usePromptSystem } from 'shared/hooks/usePromptSystem';
import RemoveDialog from 'shared/components/RemoveDialog';

interface IPromptMessage {
  isDirtyForm: boolean;
  handleConfirm?: () => void;
  isOpenModalWarning?: boolean;
  handleCloseModal?: () => void;
}

/**
 * Component to show prompt of browser if Form is not saved and user wants to close, reload page or go back,
 * or to show Modal Window if user clicks button (like 'Go back') on the Page
 * @param isDirtyForm -  true when  the change in any values in Form
 * @param handleConfirm -  action, when user clicks button Yes
 * @param isOpenModalWarning - is show Modal Window
 * @param handleCloseModal - close Modal Window
 */

const PromptMessage = ({ isDirtyForm, handleConfirm, isOpenModalWarning, handleCloseModal }: IPromptMessage) => {
  const intl = useIntl();
  const [isShowSystemWarning, setIsShowSystemWarning] = useState(false);

  useEffect(() => {
    setIsShowSystemWarning(isDirtyForm);
  }, [isDirtyForm]);

  const handleClickOnRelativeButton = useCallback(() => {
    if (isOpenModalWarning && isDirtyForm) {
      /* When ModalWindow (<RemoveDialog />) will open, state isShowSystemWarning must be 'false':
       *  It needs for hiding Prompt of browser, when  user clicks button YES in ModalWindow */
      setIsShowSystemWarning(false);
    }
    if (isOpenModalWarning && !isDirtyForm && handleConfirm) {
      handleCloseModal && handleCloseModal();
      handleConfirm();
    }
  }, [isOpenModalWarning, handleCloseModal, isDirtyForm, handleConfirm]);

  useEffect(() => {
    handleClickOnRelativeButton();
  }, [isOpenModalWarning, handleClickOnRelativeButton]);

  const handleModalWarningClose = () => {
    setIsShowSystemWarning(true);
    handleCloseModal && handleCloseModal();
  };

  const handleModalWarningConfirm = () => {
    handleConfirm && handleConfirm();
  };

  usePromptSystem({ message: intl.formatMessage({ id: 'invoices.invoice-editor-dialog-go-home-subtitle' }), when: isShowSystemWarning });

  return (
    <>
      {isOpenModalWarning && (
        <RemoveDialog
          isLoading={false}
          title={intl.formatMessage({ id: 'invoices.invoice-editor-dialog-go-home-title' })}
          subText={intl.formatMessage({ id: 'invoices.invoice-editor-dialog-go-home-subtitle' })}
          isOpen={isOpenModalWarning}
          close={handleModalWarningClose}
          remove={handleModalWarningConfirm}
          textButtonRemove={intl.formatMessage({ id: 'settings.confirm-remove-template-confirm-btn' })}
          colorButtonRemove="primary"
        />
      )}
    </>
  );
};

export default PromptMessage;
