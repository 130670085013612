import Loader from 'shared/components/Loader';
import useDispatchOrganisationPlanData from 'modules/organisation-plan-preview/hooks/useDispatchOrganisationPlanData';
import OrganisationPlanPreviewTemplateSectionsContainer from 'modules/organisation-plan-preview/components/OrganisationPlanPreviewTemplateSectionsContainer';
import { WidgetsContainer } from './OrganisationPlanPreviewWidgetSection.styles';

const OrganisationPlanPreviewWidgetSection = () => {
  const { isLoading, organisationPlanInfo } = useDispatchOrganisationPlanData();

  if (isLoading) {
    return <Loader />;
  }
  return (
    <WidgetsContainer>
      <OrganisationPlanPreviewTemplateSectionsContainer orgPlanData={organisationPlanInfo} />
    </WidgetsContainer>
  );
};

export default OrganisationPlanPreviewWidgetSection;
