import { useQuery } from 'react-query';
import axios from 'axios';
import { API_INVOICES_ENDPOINTS, EInvoicesQueryKeys, IInvoiceEditorDetails } from 'shared/types/invoice';

interface IuseGetInvoiceInfoByIdParams {
  invoiceId?: number;
}

const fetchInvoiceEditorById = (invoiceId: number): Promise<IInvoiceEditorDetails> => {
  return axios.get(`${API_INVOICES_ENDPOINTS.GET_BY_ID(invoiceId)}/editor`).then((res) => res.data);
};

export function useGetInvoiceEditorById({ invoiceId }: IuseGetInvoiceInfoByIdParams) {
  return useQuery<IInvoiceEditorDetails>([EInvoicesQueryKeys.GetInvoiceEditor, { invoiceId }], () => fetchInvoiceEditorById(invoiceId!), {
    enabled: !!invoiceId,
    refetchOnMount: true
  });
}
