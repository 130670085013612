import { TABLE_DASHBOARD_PREVIEW_ROUTES } from './constants';
import DashboardTableLayout from './layouts/DashboardTableLayout';
import TableLayout from './layouts/TableLayout';

export const TableRoutes = {
  path: TABLE_DASHBOARD_PREVIEW_ROUTES.ROOT_LINK,
  element: <TableLayout />,
  children: [
    {
      path: TABLE_DASHBOARD_PREVIEW_ROUTES.LINK_BY_TOKEN,
      element: <DashboardTableLayout />
    }
  ]
};
