import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { IPaginatedResult } from 'shared/types/paginatedResult';
import { API_INVOICES_ENDPOINTS, EInvoicesQueryKeys, IInvoiceByEventId, IInvoiceForSave } from 'shared/types/invoice';
import { IPagination } from 'shared/types/table';

type MutationContext =
  | {
      previousInvoices: IPaginatedResult<IInvoiceForSave> | undefined;
      newInvoice: IInvoiceForSave;
    }
  | undefined;

const updateInvoice = (invoiceInfo: IInvoiceForSave): Promise<IInvoiceForSave> => {
  return axios.put(`${API_INVOICES_ENDPOINTS.GET_BY_ID(invoiceInfo.id)}`, invoiceInfo).then((res) => res.data);
};

export function useEditInvoice<TError>({
  options = {},
  onSuccess,
  id,
  pagination
}: {
  options?: Omit<UseMutationOptions<IInvoiceForSave, TError, IInvoiceForSave, MutationContext>, 'mutationFn' | 'onSuccess' | 'variables'>;
  onSuccess?: ((data: IInvoiceForSave, variables: IInvoiceForSave, context: MutationContext) => void | Promise<unknown>) | undefined;
  id?: number;
  pagination: IPagination;
}) {
  const queryClient = useQueryClient();
  const invoiceListQueryKey = [EInvoicesQueryKeys.GetInvoices, pagination];
  const invoiceEditorQueryKey = [EInvoicesQueryKeys.GetInvoiceEditor, { invoiceId: id }];

  return useMutation([{ id: `${id}` }], updateInvoice, {
    ...options,
    onSuccess: (data, variables, context) => {
      const previousInvoices: IPaginatedResult<IInvoiceForSave> | undefined = queryClient.getQueryData(invoiceListQueryKey);
      const prev = previousInvoices?.data ? previousInvoices.data : [];
      const newData = prev.map((invoice) => {
        if (invoice.id !== data.id) return invoice;
        return { ...invoice, ...data };
      });
      queryClient.setQueryData(invoiceListQueryKey, { ...previousInvoices, data: newData });

      // Updating listInvoices of event on the page EventList
      const invoiceListQueryKeyOfEvent = [EInvoicesQueryKeys.GetInvoicesOfEvent, data?.eventId];
      const previousInvoicesOfEvent: IInvoiceByEventId[] | undefined = queryClient.getQueryData(invoiceListQueryKeyOfEvent);
      const newDataListOfEvent = previousInvoicesOfEvent?.map((invoice) => {
        if (invoice.id !== data.id) return invoice;
        return { ...invoice, number: data?.number, type: data?.type, createDate: data?.invoiceDate };
      });
      queryClient.setQueryData(invoiceListQueryKeyOfEvent, newDataListOfEvent);

      queryClient.invalidateQueries(['invoice', { id: `${id}` }]);
      queryClient.invalidateQueries(invoiceEditorQueryKey);
      if (onSuccess) {
        onSuccess(data, variables, context);
      }
    },
    // Always refetch after error:
    onSettled: (_, error) => {
      if (error) {
        queryClient.invalidateQueries(invoiceListQueryKey);
      }
    }
  });
}
