import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { DashboardQueryKey } from 'shared/types/dasboard';
import { WidgetsDashboardType } from 'modules/dashboard/types';

interface IUpdateDashboardDefaultById {
  id: number;
  data: WidgetsDashboardType;
}

const updateDashboardWidgetById = ({ id, data }: IUpdateDashboardDefaultById): Promise<WidgetsDashboardType> => {
  return axios.put(`/dashboard-widgets/${id}`, data);
};

export function useUpdateDashboardWidgetById<TError>({
  options = {},
  onSuccess
}: {
  options?: Omit<UseMutationOptions<IUpdateDashboardDefaultById, TError, { id: number }, any>, 'mutationFn' | 'onSuccess' | 'variables'>;
  onSuccess?: (data: WidgetsDashboardType) => void;
}) {
  const queryClient = useQueryClient();

  return useMutation(updateDashboardWidgetById, {
    ...options,
    onSuccess: ({ data }) => {
      const queryKey = [DashboardQueryKey.Dashboard, { id: data.userDashboardId.toString() }];
      const previousDashboardWidget: WidgetsDashboardType[] | undefined = queryClient.getQueryData(queryKey);
      const prevArray = previousDashboardWidget || [];
      const index = prevArray.length && prevArray.findIndex((item) => item.id === data.id);
      if (index !== -1) {
        prevArray[index] = data;
      }
      queryClient.setQueryData(queryKey, prevArray);
      if (onSuccess) onSuccess(data);
    },
    onSettled: (_, error) => {
      if (error) {
        queryClient.invalidateQueries([DashboardQueryKey.DashboardDefault]);
      }
    }
  });
}
