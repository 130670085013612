import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { validate } from 'uuid';
import ContextMenu, { IListOptionContextMenu } from 'shared/components/ContextMenu/index';
import { TableDashboardWidgetType } from 'modules/dashboard/types/TableDashboardWidgetType';
import { useUpdateShareTable } from 'modules/dashboard/hooks/useUpdateShareTable';
import { TABLE_DASHBOARD_PREVIEW_ROUTES } from 'modules/dashboard/constants';
import { downloadCsvByWidgetId } from 'modules/dashboard/utils';
import useCopyToClipboard from 'shared/hooks/useCopyToClipboard';
import { useGetCurrentTenant } from 'api/hooks/tenant/useGetCurrentTenant';
import { useSearchParams } from 'shared/hooks/useSearchParams';
import { useDispatch } from 'store';
import { addNewWidgetInWidgetGroup } from 'store/dashboard/dashboard.slice';
import TableFormModal from './TableModal/TableFormModal';

interface IHeaderTableActions {
  widget: TableDashboardWidgetType | undefined;
  isCustomize: boolean;
  widgetId?: string;
  isHideHeaderTable?: boolean;
  isGroupWidget: boolean;
}

const HeaderTableActions = ({ widget, widgetId, isHideHeaderTable, isCustomize, isGroupWidget }: IHeaderTableActions) => {
  const [editTable, setEditTable] = useState(false);

  const intl = useIntl();
  const navigate = useNavigate();
  const query = useSearchParams();
  const [, copy] = useCopyToClipboard();
  const dispatch = useDispatch();

  const { data: tenantData } = useGetCurrentTenant();
  const { mutateAsync: updateShareTable } = useUpdateShareTable();

  const copyToClipboard = useCallback(() => {
    copy(window.location.href);
  }, [copy]);

  const handleEditCLick = useCallback((): void => {
    setEditTable(true);
  }, []);

  const closeModalEditTable = (): void => {
    setEditTable(false);
  };

  const handleLoginToTenant = useCallback(() => {
    const tenantName = query.get('tenantName');
    navigate(`/login/${tenantName}`, { replace: true });
  }, [query, navigate]);

  const handleShareTable = useCallback(async (): Promise<void> => {
    if (!widget) return;
    await updateShareTable(
      { id: widget?.id },
      {
        onSuccess: (data) => {
          copy(
            `${import.meta.env.VITE_REACT_APP_SITE_URL}${TABLE_DASHBOARD_PREVIEW_ROUTES.GET_LINK_BY_TOKEN(
              data.sharingToken,
              data.id
            )}?tenantName=${tenantData?.name}`
          );
        }
      }
    );
  }, [copy, tenantData?.name, updateShareTable, widget]);

  const handleAddAdditionalTable = useCallback(() => {
    // Check to be sure that groupId field is GUID
    if (widget?.groupId && validate(widget?.groupId) && widget?.layout) {
      dispatch(addNewWidgetInWidgetGroup({ widget, defaultName: intl.formatMessage({ id: 'dashboard.custom-table' }) }));
    }
  }, [widget, dispatch, intl]);

  const handleGeneratePdf = useCallback((): void => {
    downloadCsvByWidgetId(widget?.id || Number(widgetId));
  }, [widget?.id, widgetId]);

  const listOptions = useMemo<IListOptionContextMenu[]>(() => {
    const options: IListOptionContextMenu[] = [
      {
        onClick: handleGeneratePdf,
        messageId: 'dashboard-table.export-to-csv'
      }
    ];

    if (!isHideHeaderTable) {
      options.push(
        {
          onClick: handleShareTable,
          messageId: 'dashboard-table.share-table'
        },
        {
          onClick: handleEditCLick,
          messageId: 'dashboard-table.edit-widget'
        }
      );
    }
    if (!isHideHeaderTable && isCustomize) {
      options.push({
        onClick: handleAddAdditionalTable,
        messageId: 'dashboard-table.add-additional-table'
      });
    }
    if (isHideHeaderTable) {
      options.push(
        {
          onClick: copyToClipboard,
          messageId: 'organisation.copy-link'
        },
        {
          onClick: handleLoginToTenant,
          messageId: 'dashboard.loginTenant'
        }
      );
    }

    return options;
  }, [
    handleGeneratePdf,
    isHideHeaderTable,
    isCustomize,
    handleShareTable,
    handleEditCLick,
    handleAddAdditionalTable,
    copyToClipboard,
    handleLoginToTenant
  ]);

  return (
    <>
      <ContextMenu listOptions={listOptions} sxContainer={{ transform: 'rotate(90deg)' }} />
      {!!editTable && (
        <TableFormModal
          isGroupWidget={isGroupWidget}
          widget={widget}
          isOpenModal={!!editTable}
          closeModal={closeModalEditTable}
          isCustomize={isCustomize}
        />
      )}
    </>
  );
};

export default HeaderTableActions;
