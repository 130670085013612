import { FC, useState } from 'react';
// import Attachment from '../../../../../assets/images/organisation-plan-template/attachment.svg';
// import AttachmentActive from '../../../../../assets/images/organisation-plan-template/attachment_active.svg';
import { Layout } from 'react-grid-layout';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Stack, Tooltip } from '@mui/material';
import { Image16Regular, Link16Regular } from '@fluentui/react-icons';
import ReplacementTextHeading from 'shared/components/ReplacementText/Heading';
import { useDispatch } from 'store';
import { updateOrganisationPlanWidget } from 'store/organisation-plan-template/organisation-plan-template.slice';
import useUpdateOrganisationPlanLayoutPartially from 'modules/organisation-plan-templates/hooks/useUpdateOrganisationPlanLayoutPartially';
import { OrganisationPlanUploadAreaWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanUploadAreaWithReplacementType';
import * as GeneralStyles from 'modules/organisation-plan-templates/components/widgets/InputWithReplacementWidget.styles';
import { SingleActionButton } from 'shared/components/ReplacementText/Buttons/Button.styles';
import * as Styles from './OrganisationPlanUploadAreaWithReplacementWidget.styles';

interface IOrganisationPlanUploadAreaWithReplacementWidgetProps {
  widget: OrganisationPlanUploadAreaWithReplacementType;
  layoutItem: Layout;
}

const OrganisationPlanUploadAreaWithReplacementWidget: FC<IOrganisationPlanUploadAreaWithReplacementWidgetProps> = ({
  widget,
  layoutItem
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { type, label, widgetId, isMainLabelHidden, replacementLabel, isInlinePreview, isInlineLink, sectionId } = widget;
  const [isHeadingOpen, setIsHeadingOpen] = useState(false);
  const [inlinePreview, setIsInlinePreview] = useState(!!isInlinePreview);
  const inlinePreviewCallback = () => {
    setIsInlinePreview(!inlinePreview);

    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as OrganisationPlanUploadAreaWithReplacementType['type'], isInlinePreview: !inlinePreview }
      })
    );
  };

  const [inlineLink, setIsInlineLink] = useState(!!isInlineLink);
  const inlineLinkCallback = () => {
    setIsInlineLink(!inlineLink);
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as OrganisationPlanUploadAreaWithReplacementType['type'], isInlineLink: !inlineLink }
      })
    );
  };

  // const [isAttachment, setIsAttachment] = useState(false);
  // const attachmentCallback = () => setIsAttachment(!isAttachment);

  const buttons = [
    {
      icon: <Image16Regular />,
      tooltipMessage: intl.formatMessage({ id: 'organisation.inline-preview' }),
      callback: inlinePreviewCallback,
      isActive: inlinePreview
    },
    {
      icon: <Link16Regular />,
      tooltipMessage: intl.formatMessage({ id: 'organisation.inline-link' }),
      callback: inlineLinkCallback,
      isActive: inlineLink
    }
    /*
     TODO: uncomment when attachment will be disacussed with client
    {
      image: isAttachment ? AttachmentActive : Attachment,
      tooltipMessage: intl.formatMessage({ id: 'organisation.attachment' }),
      callback: attachmentCallback,
      isActive: isAttachment
    }*/
  ];

  const ref = useUpdateOrganisationPlanLayoutPartially({ widgetId, sectionId, layoutItem, dependencies: [isHeadingOpen] });

  const replaceGeneralLabelHandler = (replacementLabel: string) => {
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as OrganisationPlanUploadAreaWithReplacementType['type'], replacementLabel }
      })
    );
  };

  const changeGeneralLabelVisibilityHandler = (isMainLabelHidden: boolean) => {
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as OrganisationPlanUploadAreaWithReplacementType['type'], isMainLabelHidden }
      })
    );
  };

  return (
    <section ref={ref}>
      <GeneralStyles.BoxWrapper>
        <ReplacementTextHeading
          onChange={(e) => replaceGeneralLabelHandler(e.target.value)}
          value={replacementLabel}
          content={label || intl.formatMessage({ id: 'organisation.no-label-provided' })}
          isLabelHiddenValue={isMainLabelHidden}
          onLabelHiddenChange={(val) => changeGeneralLabelVisibilityHandler(val)}
          onReplacementLabelHiddenChange={() => replaceGeneralLabelHandler('')}
          isHeadingReplacementOpen={(value) => setIsHeadingOpen(value)}
        />
        <Styles.UploadWrapper>
          <Styles.NoFileTypographyWrapper>
            <FormattedMessage id="organisation.upload-placeholder" />
          </Styles.NoFileTypographyWrapper>
          <Stack direction="row" spacing={1} mt={1} justifyContent="center">
            {buttons.map((i) => (
              <Box key={i.tooltipMessage}>
                <Tooltip enterDelay={2000} arrow placement="top" title={i.tooltipMessage}>
                  <SingleActionButton isFixed={false} onClick={i.callback} isActive={i.isActive}>
                    {i.icon}
                  </SingleActionButton>
                </Tooltip>
              </Box>
            ))}
          </Stack>
        </Styles.UploadWrapper>
      </GeneralStyles.BoxWrapper>
    </section>
  );
};

export default OrganisationPlanUploadAreaWithReplacementWidget;
