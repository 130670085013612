import { AddCircle16Regular, SubtractCircle16Regular } from '@fluentui/react-icons';
import { Button, Grid } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';

interface ICreateCustomerFormAdditionalPartnerSwitcherButtonProps {
  isAdditionalPartnerShown: boolean;
  setAdditionalPartnerShown: Dispatch<SetStateAction<boolean>>;
}

const CreateCustomerFormAdditionalPartnerSwitcherButton = ({
  isAdditionalPartnerShown,
  setAdditionalPartnerShown
}: ICreateCustomerFormAdditionalPartnerSwitcherButtonProps) => {
  return (
    <Grid item sm={12} sx={{ px: '0 0 20px 0' }}>
      <Button
        size="small"
        style={{ borderRadius: '60px' }}
        variant="outlined"
        color="secondary"
        type="button"
        startIcon={isAdditionalPartnerShown ? <SubtractCircle16Regular /> : <AddCircle16Regular />}
        onClick={() => setAdditionalPartnerShown((prev) => !prev)}
      >
        {isAdditionalPartnerShown ? <FormattedMessage id="events.remove-partner" /> : <FormattedMessage id="events.add-partner" />}
      </Button>
    </Grid>
  );
};

export default CreateCustomerFormAdditionalPartnerSwitcherButton;
