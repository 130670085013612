import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GridWrapper = styled(Grid)(({ theme }) => ({
  border: `1px dashed ${theme.palette.secondary.light}`,
  borderRadius: '6px',
  height: '100%',
  width: '350px',
  position: 'sticky',
  top: '10px'
}));
