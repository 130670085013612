export const ORGANISATION_PLAN_PREVIEW_ROUTES = {
  ROOT_LINK: 'organization-plan',
  LINK_BY_TOKEN: ':token',
  GET_LINK_BY_TOKEN: (token: string) => `/organization-plan/${token}`
};

export enum FileExtensionByType {
  pdf = 'pdf',
  docs = 'docs',
  docx = 'docx',
  pptx = 'pptx',
  txt = 'txt',
  csv = 'csv',
  xlsx = 'xlsx',
  png = 'png',
  jpg = 'jpg',
  jpeg = 'jpeg',
  svg = 'svg'
}
