import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, CircularProgress, IconButton } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ArrowLeft24Regular, Dismiss20Regular } from '@fluentui/react-icons';
import { prepareUserDataForEventForm } from 'modules/users/utils/prepareDataForUser';
import { IUserFormData } from 'shared/types/user';
import { IPersonalInformation } from 'modules/events/types';
import ModalDialog from 'shared/components/ModalContainer/ModalDialog';
import CustomerAddressInfoSection from './CustomerAddressInfoSection';
import CustomerCompanyInfoSection from './CustomerCompanyInfoSection';
import CustomerGeneralInfoSection from './CustomerGeneralInfoSection';

const CreateCustomerForm = ({ data, onCancel, onSubmit, isLoading, open }: IPersonalInformation) => {
  const intl = useIntl();
  const [uploadFileInput, setUploadFileInput] = useState<null | FileList>(null);

  const formik = useFormik<IUserFormData>({
    initialValues: {
      logo: uploadFileInput?.length ? URL.createObjectURL(uploadFileInput[0]) : undefined,
      ...prepareUserDataForEventForm(data)
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .max(255)
        .required(intl.formatMessage({ id: 'events.name-is-required' })),
      surname: Yup.string()
        .max(255)
        .required(intl.formatMessage({ id: 'users.surname-is-required' })),
      partnerName: Yup.string().max(255),
      partnerSurname: Yup.string().max(255),
      email: Yup.string()
        .email(intl.formatMessage({ id: 'users.invalid-email-address' }))
        .max(255)
        .required(intl.formatMessage({ id: 'auth.email-is-required' }))
    }),
    onSubmit: (values) => {
      onSubmit(values, uploadFileInput);
    }
  });
  const { handleSubmit, setValues } = formik;

  useEffect(() => {
    if (!data?.id) return;

    setValues({
      ...prepareUserDataForEventForm(data),
      logo: data?.profilePictureUrl ?? undefined
    });
  }, [data, setValues]);

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);

  const titleIntl = intl.formatMessage(data !== null ? { id: 'events.update-customer' } : { id: 'events.create-customer' });

  const header = (
    <>
      <IconButton size="small" onClick={onCancel}>
        <ArrowLeft24Regular />
      </IconButton>
      <FormattedMessage id={titleIntl} />
      <IconButton disabled={isLoading} size="small" onClick={onCancel}>
        {isLoading ? <CircularProgress size={20} /> : <Dismiss20Regular />}
      </IconButton>
    </>
  );

  const body = (
    <>
      <CustomerGeneralInfoSection setUploadFileInput={setUploadFileInput} />
      <CustomerCompanyInfoSection />
      <CustomerAddressInfoSection />
    </>
  );

  const actions = (
    <>
      <Button color="error" onClick={onCancel} disabled={isLoading}>
        <FormattedMessage id="users.cancel" />
      </Button>
      <Button onClick={() => handleSubmit()} variant="contained" disabled={isLoading}>
        {data?.id ? <FormattedMessage id="events.update-customer" /> : <FormattedMessage id="events.create-customer" />}
      </Button>
    </>
  );

  return (
    <ModalDialog
      pageTitle={titleIntl}
      headerContent={header}
      bodyContent={body}
      actionBarContent={actions}
      formikValue={formik}
      isDialogOpen={open}
      onSubmit={handleSubmit}
      onClose={onCancel}
    />
  );
};

export default CreateCustomerForm;
