import {
  ProfileActionTypes,
  ProfileState,
  SET_CURRENT_VIEW,
  SET_IS_CUSTOMER_IMAGE_REDUCED,
  SET_ISMAXIMIZED_PROFILE,
  SET_ISMOBILE,
  PROFILE_TYPE,
  EVENT_DATA,
  REQUEST_DATA
} from './profile.types';

const initialState: ProfileState = {
  isMobile: false,
  isMaximizedProfile: false,
  isCustomerImageReduced: true,
  currentView: 'details',
  profileType: '',
  eventData: null,
  requestData: null
};

const calendarReducer = (state = initialState, action: ProfileActionTypes): ProfileState => {
  switch (action.type) {
    case SET_ISMAXIMIZED_PROFILE:
      return {
        ...state,
        isMaximizedProfile: action.payload
      };
    case SET_IS_CUSTOMER_IMAGE_REDUCED:
      return {
        ...state,
        isCustomerImageReduced: action.payload
      };
    case SET_ISMOBILE:
      return {
        ...state,
        isMobile: action.payload
      };
    case SET_CURRENT_VIEW:
      return {
        ...state,
        currentView: action.payload
      };
    case PROFILE_TYPE:
      return {
        ...state,
        profileType: action.payload
      };
    case EVENT_DATA:
      return {
        ...state,
        eventData: action.payload
      };
    case REQUEST_DATA:
      return {
        ...state,
        requestData: action.payload
      };
    default:
      return state;
  }
};

export default calendarReducer;
