import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxSection = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  padding: `0 0 0 10px`
}));

export const BoxContent = styled(Box)(() => ({
  position: 'absolute',
  right: '36px',
  top: '0px'
}));

export const BackgroundButtonSettings = styled(Box)(() => ({
  width: '77px',
  height: '30px',
  position: 'absolute',
  top: 0,
  right: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.8)'
}));

export const CustomToolbar = styled('div')(({ theme }) => ({
  zIndex: 1000,
  '& .wrapperClassName': {
    border: `none !important`
  },
  '& .rdw-editor-toolbar': {
    backgroundColor: theme.palette.secondary.lighter,
    margin: '0px',
    padding: '10px 5px 5px 5px !important',
    borderRadius: '0px',
    justifyContent: 'left',
    border: 'none !important',
    width: 'fit-content',
    minWidth: 'fit-content',
    '& .svg': {
      fontSize: 'initial !important',
      color: `${theme.palette.primary.main} !important`
    }
  },
  '& .rdw-colorpicker-modal': {
    left: 'unset !important',
    right: '5px !important',
    borderRadius: '6px !important',
    border: 'none !important',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
    height: 'fit-content'
  },
  '& .rdw-link-modal': {
    left: 'unset !important',
    right: '5px !important',
    borderRadius: '6px !important',
    border: 'none !important',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
    height: 'fit-content'
  },
  '& .rdw-image-modal': {
    borderRadius: '6px !important',
    border: 'none !important',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
    height: 'fit-content'
  },
  '& .rdw-emoji-modal': {
    borderRadius: '6px !important',
    border: 'none !important',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
    height: '200px',
    width: '180px'
  },
  '& .rdw-embedded-modal': {
    borderRadius: '6px !important',
    border: 'none !important',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px'
  },
  '& .rdw-dropdown-wrapper': {
    '&:hover': {
      boxShadow: 'none !important',
      borderColor: `${theme.palette.primary.light} !important`
    },
    border: `1px solid ${theme.palette.secondary.light} !important`,
    borderRadius: '4px',
    '& .rdw-dropdown-carettoopen': {
      color: `${theme.palette.primary.main} !important`
    },
    '& .rdw-dropdown-optionwrapper': {
      backgroundColor: `${theme.palette.background.paper} !important`,
      marginTop: '2px',
      borderRadius: '4px',
      border: `1px solid ${theme.palette.secondary.light} !important`,
      '&:hover': {
        boxShadow: 'none !important'
      },
      '& .rdw-dropdown-option': {}
    }
  },

  '& .rdw-option-wrapper': {
    background: 'transparent !important',
    marginRight: `0px !important`,
    border: `none !important`,
    boxShadow: `none !important`,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    height: '20px',
    borderRadius: `4px`
  },

  '& .rdw-option-wrapper:hover': {
    background: `${theme.palette.secondary.light} !important`
  },

  '& .rdw-option-active': {
    background: `${theme.palette.secondary.main} !important`,
    color: 'white !important'
  },

  '& .rdw-italic-wrapper .rdw-option-wrapper': {
    fontSize: '0 !important'
  },

  '& .rdw-editor-main': {
    borderTop: `1px solid ${theme.palette.secondary.light} !important`,
    padding: '0px 15px !important',
    maxHeight: '450px',
    overflow: 'auto',
    '& .public-DraftEditorPlaceholder-root': {
      color: `${theme.palette.text.secondary} !important`
    },
    '& .public-DraftEditor-content': {
      height: '100% !important',
      color: `${theme.palette.text.primary} !important`
    }
  }
}));
