import { QuestionnairePageType } from 'modules/questionnaires/types/questionnaries';
import { BuilderPageItemType } from 'modules/builder/types';

/**
 * util function to get page with certain type from all questionnaires pages
 * @param allPages array of all pages for questionnaire
 * @param pageType type of page
 * @returns page with certain type or undefind
 */
export const findPageByType = <T>(allPages: BuilderPageItemType<T>[], pageType: QuestionnairePageType): BuilderPageItemType | undefined => {
  return allPages.find((item) => item.type === pageType);
};
