import { IUser } from 'shared/types/user';
import { QuestionnaireTaxType } from 'modules/questionnaires/types';
import { IEditorArticle, IEditorForForm } from 'modules/editor/types/editor';
import { IVenue, IVenueRoom } from './venue';
import { IContactPeople, IEvent, IEventInfo } from './event';
import { ERequestStatus, IRequestDB } from './requests';
import { ISettingsTemplate, ISettingsTemplateFooter } from './settingsTemplate';
import { IOfferTemplate, OfferTemplateListItemType } from './settingsOffer';

export enum EOffersQueryKeys {
  GetOffers = 'offers',
  GetSingleOffer = 'offer',
  GetOfferEditor = 'offer-editor',
  GetOfferItemsSelected = 'offer-items'
}

const ROOT = 'offers';

export const API_OFFERS_ENDPOINTS = {
  GET_BY_ID: (id: string | number) => `${ROOT}/${id}`,
  GET_OFFER: (id?: string | number) => `${ROOT}/${id}`,
  SEND_EMAIL: (id: string | number) => `${ROOT}/${id}/send-offer`,
  GET_ITEMS_SELECTED: (id: string | number) => `${ROOT}/${id}/items/selected`,
  GET_ITEMS_OPTIONAL: (id: string | number) => `${ROOT}/${id}/items/optional`,
  GET_ITEMS_OF_OFFER: (id: string | number) => `${ROOT}/${id}/items`,
  PUT_ITEMS: (id: string | number) => `${ROOT}/${id}/items`
};

export enum EOfferType {
  offer = 'Offer'
}

export enum EOfferEditorArticleType {
  Custom = 'Custom',
  Imported = 'Imported'
}

export enum EOfferEditorArticleOptionalType {
  Optional = 'Optional',
  OptionalImported = 'OptionalImported'
}

export enum EArticlesValueIOfferEdit {
  Articles = 'articles',
  ArticlesOptional = 'articlesOptional'
}
export interface IOffer {
  //TODO: update this, because main entity of offer MUST have id
  id?: number;
  number?: string; //TODO: Olya - need delete and use offerNumber
  offerNumber?: string;
  recipient?: IUser;
  recipientId?: number;
  name?: string;
  taxType: QuestionnaireTaxType;
  state: ERequestStatus;
  offerDate: string | Date;
  validUntil: string | Date;
  requestedEventDate: string | Date;
  venue?: IVenueRoom;
  venueId?: number;
  offerTemplateId?: number;
  offerTemplate?: ISettingsTemplate;
  request: IRequestDB;
  requestId: number;
  finalAmount: number;
  tenantId: number;
  isSentEmail?: boolean;
}

export interface IOfferEmailTemplate extends IOffer {
  customUrl?: string;
}
export interface IOfferBase {
  id: number;
  offerNumber: string;
  state: ERequestStatus;
  name?: string;
}

export interface IOfferDetails {
  finalAmount?: number;
  taxType: QuestionnaireTaxType;
  offerTemplate?: IOfferTemplate;
  venue?: IVenue;
  recipient?: IContactPeople;
  articles?: IEditorArticle<EOfferEditorArticleType>[] | [];
  appearance?: IOfferAppearance;
  discount: number;
}

export interface IOfferAppearance {
  welcomeText?: string;
  closingText?: string;
  footers?: ISettingsTemplateFooter[];
  optionalText?: string;
}

export interface IOfferBD extends IOfferBase {
  requestId: number;
  offerDetails: IOfferDetails;
  validUntil: string | null | Date;
  offerDate?: string | null | Date;
  request?: IRequestDB;
  event?: IEvent;
  isSentEmail?: boolean;
  contactPeople?: IContactPeople[];
}

export interface IOfferDialogForm {
  id?: number;
  offerNumber: string;
  state: ERequestStatus;
  taxType: QuestionnaireTaxType;
  offerDate: string | null | Date;
  validUntil: string | null | Date;
  requestedEventDate?: string | null | Date;
  request?: IOfferBD['request'];
  offerTemplate: Partial<OfferTemplateListItemType> | null;
  recipient: Partial<IContactPeople> | null;
  venue: Partial<IEvent<string>['venue']> | null;
  isSentEmail?: boolean;
}

export interface IOfferDetailsForSave {
  taxType: QuestionnaireTaxType;
  offerTemplateId?: number;
  venueId?: number;
  recipientId?: number;
  appearance?: {
    welcomeText?: string;
    closingText?: string;
    footers?: ISettingsTemplateFooter[];
    optionalText?: string;
  };
}

export interface IOfferDetailsSaveWithDiscount extends IOfferDetailsForSave {
  discount: number;
}
export interface IOfferForSave<T = IOfferDetailsForSave> {
  id?: number;
  state: ERequestStatus;
  offerDetails: T;
  offerDate?: string | null | Date;
  validUntil: string | null | Date;
}

export interface IOfferForCreateFromEvent {
  eventId?: number;
  offerDate?: string | null | Date;
  validUntil: string | null | Date;
  offerDetails: IOfferDetailsForSave;
}

export interface IOfferEditorFormData extends Omit<IOffer, 'recipient'> {
  contactDetails?: { id?: number; text: string }[];
  senderAddress?: ISettingsTemplate['senderAddress'];
  legalTax?: number;
  individualTax?: number;
  discount?: number;
  invoiceTemplate?: Omit<OfferTemplateListItemType, 'senderAddress'> & { footers: ISettingsTemplate['footerTexts'] };
  articles: IOfferArticleType[];
  recipient?: IUser;
}

type OfferDataType = Partial<IRequestDB> & Partial<IEventInfo>;
export type IOfferEditorBase = IEditorForForm<ERequestStatus, OfferDataType | null, EOfferType, IOfferTemplate>;

export type IOfferArticleType = IEditorArticle<EOfferEditorArticleType>;
export type IOfferArticleOptionalType = IEditorArticle<EOfferEditorArticleOptionalType>;

export type IOfferArticleBaseAndOptionalType = IOfferArticleType | IOfferArticleOptionalType;

export interface IOfferEditor extends IOfferEditorBase {
  [EArticlesValueIOfferEdit.ArticlesOptional]: IOfferArticleOptionalType[];
}

export interface IOfferSendEmail extends IOfferEditorBase {
  customUrl?: string;
  requestedDate?: string;
  offerDate?: string;
  validUntil?: string;
  confirmAccount?: string;
  goToRequest?: string;
}
export interface IPrepareDataToSendEmailOffer {
  offer: IOfferSendEmail;
  subject: string;
  template: string;
}

export interface IOfferItemsPayload {
  id: number;
  items: IEditorArticle[];
}
