import { useLocation } from 'react-router';
import { useMemo } from 'react';

/**
 * Custom hook to get the search parameters from the current URL location.
 *
 * @returns {URLSearchParams} An instance of URLSearchParams populated with the current search parameters.
 * @example
 * const searchParams = useSearchParams();
 * const value = searchParams.get('key');
 */
export const useSearchParams = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};
