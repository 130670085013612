import { useQuery } from 'react-query';
import axios from 'axios';
import { API_OFFERS_ENDPOINTS, EOffersQueryKeys, IOfferBD } from 'shared/types/offer';

interface IUseGetOfferInfoByIdParams {
  offerId?: number;
}

const fetchOfferEditorById = (offerId: number): Promise<IOfferBD> => {
  return axios.get(`${API_OFFERS_ENDPOINTS.GET_BY_ID(offerId)}`).then((res) => res.data);
};

export function useGetOfferById({ offerId }: IUseGetOfferInfoByIdParams) {
  return useQuery<IOfferBD>([EOffersQueryKeys.GetOfferEditor, { offerId }], () => fetchOfferEditorById(offerId!), {
    enabled: !!offerId,
    refetchOnMount: true
  });
}
