import { IAdditionalFields } from 'modules/builder/types';

export enum QuestionnairePageType {
  WelcomePageRequest = 'WelcomePageRequest', // WelcomePage (Request)
  WelcomePage = 'WelcomePage', // WelcomePage (Event)
  GuestsAndRooms = 'GuestsAndRooms',
  Customs = 'Customs'
}

interface QuestionnairePageItem {
  id: number;
  name: string;
  pageNumber: number;
  type: QuestionnairePageType;
  child: string[] | null;
  parentPageId: number;
  questionnairePageType: QuestionnairePageType;
}

export interface IQuestionnaireImageItemData {
  checked?: boolean;
  id: string | number;
  fileUrl: string;
  type: string;
  labelImage?: string;
  metadata: {
    userName: string;
    link: string;
    downloadLink: string;
    privacyType: string;
  };
  focusOffsetX?: null | number;
  focusOffsetY?: null | number;
}

export interface IQuestionnaireImageItemDataSingleWidget extends IAdditionalFields {
  id: string;
  labelImage: string;
  data: IQuestionnaireImageItemData[] | null;
}

export interface QuestionnairePageItemArticle {
  id: number;
  widgetId: string;
  questionnaireArticleId: number;
}

export interface QuestionnairePageItemDetails extends QuestionnairePageItem {
  headerImageId: number;
  headerImage: IQuestionnaireImageItemData;
  layout: string;
  questionnairePageArticles: QuestionnairePageItemArticle[] | null;
  questionnairePageType: QuestionnairePageType;
}

interface IUnsplashUser {
  id: string;
  updated_at: string;
  username: string;
  name: string;
  first_name: string;
  last_name: string;
  twitter_username: string;
  portfolio_url: string;
  bio: string;
  location: string;
  links: Links;
  profile_image: ProfileImage;
  instagram_username: string;
  total_collections: number;
  total_likes: number;
  total_photos: number;
  accepted_tos: boolean;
  for_hire: boolean;
  social: Social;
}

interface Links {
  self: string;
  html: string;
  photos: string;
  likes: string;
  portfolio: string;
  following: string;
  followers: string;
}

interface ProfileImage {
  small: string;
  medium: string;
  large: string;
}

interface Social {
  instagram_username: string;
  portfolio_url: string;
  twitter_username: string;
  paypal_email: string;
}

export interface IUnsplashPhotos {
  id: string;
  urls: { regular: string };
  type: string;
  links: {
    self: string;
    html: string;
    download: string;
    download_location: string;
  };
  alt_description: string;
  user: IUnsplashUser;
}
