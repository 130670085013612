import { DesignIdeas20Regular } from '@fluentui/react-icons';
import { Drawer, ListItemIcon, ListItemText, MenuItem, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PanelCustomizer from 'shared/components/PanelCustomizer';

interface IMenuListProps {
  onCloseMenu: () => void;
}

const Customization = ({ onCloseMenu }: IMenuListProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
    onCloseMenu();
  };

  const iconBackColorOpen = theme.palette.action.selected;

  return (
    <>
      <MenuItem
        color="secondary"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : 'none' }}
        onClick={handleToggle}
        aria-label="settings toggler"
      >
        <ListItemIcon>
          <DesignIdeas20Regular />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id="settings.appearance" />} />
      </MenuItem>
      <Drawer
        anchor="right"
        onClose={handleToggle}
        open={open}
        ModalProps={{
          sx: {
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              backdropFilter: 'blur(1px)',
              WebkitBackdropFilter: 'blur(1px)'
            }
          }
        }}
        PaperProps={{
          sx: {
            width: isMobile ? '100%' : 400,
            border: 'none',
            backgroundColor: theme.palette.background.paper,
            margin: isMobile ? '0' : '60px 10px 10px 10px',
            borderRadius: isMobile ? '0' : '10px',
            maxHeight: isMobile ? '100dvh' : 'calc(100vh - 20px)',
            height: isMobile ? '100dvh' : 'calc(100vh - 70px)',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px'
          }
        }}
      >
        {open && <PanelCustomizer handleToggle={handleToggle} />}
      </Drawer>
    </>
  );
};

export default Customization;
