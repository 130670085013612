import { HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as React from 'react';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import { useIntl } from 'react-intl';
import { Autocomplete, AutocompleteProps, ListItemText, MenuItem, TextField, Tooltip, UseAutocompleteProps } from '@mui/material';
import { SxProps } from '@mui/system';
import { debounce } from 'lodash';

interface Props {
  id?: number;
  name?: string;
  surname?: string;
}

interface IAutocompleteEvent<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> extends Partial<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>> {
  options: readonly T[];
  loading: boolean;
  handleChange: (data: T | null) => void;
  values?: T | null;
  hasNextPage?: boolean;
  fetchNextPage?: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<unknown, unknown>>;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  sx?: SxProps;
  open?: boolean;
  isOptionEqualToValue?: UseAutocompleteProps<T, undefined, undefined, undefined>['isOptionEqualToValue'];
  valuesForOptionLabel?: string[];
  updateSearch?: (values: string) => void;
  label?: string;
}

const AutocompleteInfiniteSelect = <T extends Props>({
  options,
  loading,
  handleChange,
  values,
  fetchNextPage,
  hasNextPage,
  placeholder,
  disabled = false,
  open,
  isOptionEqualToValue,
  sx,
  valuesForOptionLabel,
  readOnly = false,
  updateSearch,
  label,
  ...rest
}: IAutocompleteEvent<T>): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState<T | null>(null);
  const [inputValue, setInputValue] = useState('');
  const intl = useIntl();
  const selectedValueString = useRef<string | null>(null);

  const debouncedResults = useMemo(() => (updateSearch ? debounce(updateSearch, 300) : null), [updateSearch]);

  useEffect(() => {
    if (!debouncedResults) return;
    if (selectedValueString.current === inputValue) {
      debouncedResults('');
      return;
    }
    debouncedResults(inputValue);
  }, [inputValue, debouncedResults]);

  useEffect(() => () => debouncedResults?.cancel(), [debouncedResults]);

  const observer = useRef<IntersectionObserver | null>(null);

  const lastOptionElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (observer.current) observer.current.disconnect();
      if (node && hasNextPage && fetchNextPage) {
        observer.current = new IntersectionObserver(async (entries) => {
          if (entries[0].isIntersecting) fetchNextPage();
        });
        observer.current.observe(node);
      }
    },
    [fetchNextPage, hasNextPage]
  );

  const handleAutocompleteChange = (_event: React.ChangeEvent<{}>, selectedObject: T | T[] | null) => {
    if (!Array.isArray(selectedObject)) {
      setSelectedValue(selectedObject);
      handleChange(selectedObject);
    }
  };

  const handleRenderOption = (props: HTMLAttributes<HTMLLIElement>, option: T) => {
    const lastElement = options[options.length - 1];
    const displayOption = `${option.name} ${option.surname ?? ''}`;

    return (
      <MenuItem
        divider
        ref={lastElement.id === option.id ? lastOptionElementRef : null}
        {...props}
        sx={{ padding: '0px !important', whiteSpace: 'unset', wordBreak: 'break-word' }}
        value={option.name}
      >
        <ListItemText sx={{ padding: '8px 12px' }} primary={displayOption} />
      </MenuItem>
    );
  };

  const generateOptionLabel = (option?: T) => {
    if (valuesForOptionLabel?.length && option) {
      return valuesForOptionLabel
        .map((valName) => option[valName as keyof T] ?? '')
        .join(' ')
        .trim();
    }
    if (!option?.name && options.length && option?.id) {
      const newValue = options.find((item) => item.id === option.id);
      return newValue?.name ?? '';
    }
    return option?.name ?? '';
  };

  const handleInputChange: UseAutocompleteProps<T, undefined, undefined, undefined>['onInputChange'] = (_, newInputValue, reason) => {
    if (reason === 'input' || reason === 'clear') {
      setInputValue(newInputValue);
      selectedValueString.current = null;
    } else if (reason === 'reset') {
      selectedValueString.current = newInputValue.length ? newInputValue : null;
      setInputValue(newInputValue);
    }
  };

  return (
    <Autocomplete
      inputValue={inputValue}
      onInputChange={handleInputChange}
      filterOptions={(options) => options}
      readOnly={readOnly}
      sx={sx}
      open={open}
      options={options || []}
      loading={!options && loading}
      onChange={handleAutocompleteChange}
      isOptionEqualToValue={isOptionEqualToValue}
      value={selectedValue ?? values ?? null}
      getOptionLabel={generateOptionLabel}
      ListboxProps={{ role: 'list-box' }}
      renderInput={(params) => (
        <Tooltip arrow title={selectedValue?.name ?? values?.name ?? ''}>
          <TextField {...params} label={label} placeholder={placeholder ?? intl.formatMessage({ id: 'events.select-here' })} />
        </Tooltip>
      )}
      renderOption={handleRenderOption}
      disabled={disabled}
      {...rest}
    />
  );
};

export default AutocompleteInfiniteSelect;
