import { ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, InputLabel, Stack, TextField, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';
import { EditorType, ValuesEditorType } from 'modules/editor/types/editor';
import { intlEditor } from 'modules/editor/utils/prepareData';

interface IEditorClosingText {
  type: EditorType;
}
const EditorClosingText = ({ type }: IEditorClosingText) => {
  const intl = useIntl();
  const { values, setFieldValue } = useFormikContext<ValuesEditorType>();
  const theme = useTheme();

  const handleChangeClosingText = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFieldValue('settingsTemplate', { ...values.settingsTemplate, closingText: e.target.value });
  };

  return (
    <Grid container sx={{ padding: '22px 0' }}>
      <Stack spacing={1} width="100%">
        <InputLabel htmlFor="closingText">
          <FormattedMessage id={intlEditor.closingText[type]} />
        </InputLabel>
        <TextField
          sx={{ backgroundColor: theme.palette.background.paper }}
          fullWidth
          multiline
          minRows={2}
          value={values?.settingsTemplate?.closingText}
          name="closingText"
          onChange={handleChangeClosingText}
          placeholder={intl.formatMessage({ id: 'settings.autosize-height-based-on-content-lines' })}
        />
      </Stack>
    </Grid>
  );
};

export default EditorClosingText;
