import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { NotebookQuestionMark20Regular } from '@fluentui/react-icons';
import { useGetAllQuestionnaireInfinite } from 'modules/dashboard/hooks/useGetAllQuestionnaires';
import { useUpdateWidgetById } from 'api/hooks/dashboard/useUpdateWidgetByID';
import { useInfoMessage } from 'shared/hooks/useInfoMessage';
import { textError } from 'shared/utils/infoText';
import { IUpdateWidgetStore } from 'modules/dashboard/components/widgets/TableDashboardWidget/components/UpperHeaderTable';
import { WidgetsDashboardType } from 'modules/dashboard/types';
import GeneralFilter from './GeneralFilter';

interface IQuestionnairesFilter {
  widget?: WidgetsDashboardType;
  isCustomize?: boolean;
  updateWidgetFilters: ({ field, value }: IUpdateWidgetStore) => void;
}
const QuestionnairesFilter = ({ widget, updateWidgetFilters, isCustomize }: IQuestionnairesFilter) => {
  const [searchQuestionnaire, setSearchQuestionnaire] = useState('');

  const {
    data: dataAllQuestionnaire,
    isFetchingNextPage: allQuestionnaireNextPage,
    hasNextPage: allQuestionnaireHasNextPage,
    fetchNextPage: allQuestionnaireFetchNextPage
  } = useGetAllQuestionnaireInfinite(searchQuestionnaire);

  const debouncedRequestQuestionnaire = useMemo(() => {
    return debounce(async (questionnaire: string) => {
      setSearchQuestionnaire(questionnaire.length ? questionnaire : '');
    }, 1000);
  }, []);

  const intl = useIntl();

  const { successMessage, errorMessage } = useInfoMessage();
  const { mutateAsync: updateWidgetById, isLoading } = useUpdateWidgetById({
    onSuccess() {
      successMessage(intl.formatMessage({ id: 'dashboard.dashboard-widget-edited-successfully' }));
    },
    options: {
      onError() {
        errorMessage(textError(intl.formatMessage({ id: 'dashboard.dashboard-widget-edited-failed' })));
      }
    },
    widgetId: widget?.id?.toString() || ''
  });

  const handleChangeQuestionnaire = async (questionnaire: (string | number)[]) => {
    if (!questionnaire) return;
    if (!widget) return;

    const hasValueChanged = !isEqual(widget.filters?.questionnaires, questionnaire);

    if (!widget.id || isCustomize) updateWidgetFilters({ field: 'questionnaires', value: questionnaire });
    if (widget.id && !isCustomize && hasValueChanged) {
      await updateWidgetById(
        {
          ...widget,
          filters: {
            ...widget.filters,
            questionnaires: questionnaire as number[]
          },
          systemId: widget.systemId
        },
        {
          onSuccess: () => {
            updateWidgetFilters({ field: 'questionnaires', value: questionnaire });
          }
        }
      );
    }
  };

  const onChangeInputQuestionnaire = (questionnaire: string) => {
    debouncedRequestQuestionnaire(questionnaire);
  };

  const newValue = dataAllQuestionnaire?.map((el) => {
    return {
      value: el.id,
      label: el.name
    };
  });
  return (
    <GeneralFilter
      sx={{}}
      values={widget?.filters?.questionnaires || []}
      label={intl.formatMessage({ id: 'dashboard-table.all-questionnaires' })}
      tooltipLabel={intl.formatMessage({ id: 'dashboard-table.filter-by-questionnaire' })}
      icon={<NotebookQuestionMark20Regular />}
      textAllOptions={intl.formatMessage({ id: 'dashboard-table.all-questionnaires' })}
      hasNextPage={allQuestionnaireHasNextPage}
      onChange={handleChangeQuestionnaire}
      onInputChange={onChangeInputQuestionnaire}
      isUpdated={isLoading || allQuestionnaireNextPage}
      fetchNextPage={allQuestionnaireFetchNextPage}
      allOptions={newValue || []}
    />
  );
};

export default QuestionnairesFilter;
