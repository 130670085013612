import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import isEqual from 'lodash/isEqual';
import { ReceiptMoney20Regular } from '@fluentui/react-icons';
import { useGetInvoiceStates } from 'modules/dashboard/hooks/useGetInvoiceStates';
import { useUpdateWidgetById } from 'api/hooks/dashboard/useUpdateWidgetByID';
import { useInfoMessage } from 'shared/hooks/useInfoMessage';
import { textError } from 'shared/utils/infoText';
import { IUpdateWidgetStore } from 'modules/dashboard/components/widgets/TableDashboardWidget/components/UpperHeaderTable';
import { containsText } from 'modules/dashboard/utils';
import { WidgetsDashboardType } from 'modules/dashboard/types';
import GeneralFilter from './GeneralFilter';

interface IInvoiceStatesFilter {
  widget?: WidgetsDashboardType;
  isCustomize: boolean;
  updateWidgetFilters: ({ field, value }: IUpdateWidgetStore) => void;
}
const InvoiceStatesFilter = ({ widget, updateWidgetFilters, isCustomize }: IInvoiceStatesFilter) => {
  const [searchText, setSearchText] = useState('');
  const { data: invoiceStatesData } = useGetInvoiceStates();
  const intl = useIntl();

  const { successMessage, errorMessage } = useInfoMessage();
  const { mutateAsync: updateWidgetById, isLoading } = useUpdateWidgetById({
    onSuccess() {
      successMessage(intl.formatMessage({ id: 'dashboard.dashboard-widget-edited-successfully' }));
    },
    options: {
      onError() {
        errorMessage(textError(intl.formatMessage({ id: 'dashboard.dashboard-widget-edited-failed' })));
      }
    },
    widgetId: widget?.id?.toString() || ''
  });

  const handleInvoiceStatesChange = async (data: (number | string)[]) => {
    if (!widget) return;

    const hasValueChanged = !isEqual(widget.filters?.invoiceStates, data);

    if (!widget.id || isCustomize) updateWidgetFilters({ field: 'invoiceStates', value: data });

    if (widget.id && !isCustomize && hasValueChanged) {
      await updateWidgetById(
        {
          ...widget,
          filters: {
            ...widget.filters,
            invoiceStates: data as string[]
          },
          systemId: widget.systemId
        },
        {
          onSuccess: () => {
            updateWidgetFilters({ field: 'invoiceStates', value: data });
          }
        }
      );
    }
  };

  const onInputChange = (searchText: string) => {
    setSearchText(searchText);
  };

  const displayedOptions = useMemo(
    () => invoiceStatesData && invoiceStatesData.filter((option) => containsText(option.label, searchText)),
    [invoiceStatesData, searchText]
  );

  return (
    <GeneralFilter
      onInputChange={onInputChange}
      isUpdated={isLoading}
      sx={{ width: '170px' }}
      values={widget?.filters?.invoiceStates || []}
      sxControl={{}}
      onChange={handleInvoiceStatesChange}
      label={intl.formatMessage({ id: 'dashboard-table.all-invoice-states' })}
      tooltipLabel={intl.formatMessage({ id: 'dashboard-table.filter-by-invoicestate' })}
      icon={<ReceiptMoney20Regular />}
      textAllOptions={intl.formatMessage({ id: 'dashboard-table.all-invoice-states' })}
      allOptions={displayedOptions || []}
    />
  );
};

export default InvoiceStatesFilter;
