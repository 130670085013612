import { IntlShape } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import { QuestionnaireTaxType } from 'modules/questionnaires/types';
import {
  EInvoiceEditorArticleType,
  EInvoiceType,
  IInvoice,
  IInvoiceDialogForm,
  IInvoiceEditor,
  IInvoiceEditorDetails,
  IInvoiceForSave,
  IInvoiceListItem,
  IInvoicePatchPayload,
  IInvoicePostPayload,
  InvoiceStatus
} from 'shared/types/invoice';
import { IUser } from 'shared/types/user';
import { IContactPeople, IEvent } from 'shared/types/event';
import { EEditorArticleItemType, IEditorArticle } from 'modules/editor/types/editor';

interface IRecipientData {
  recipient?: IInvoice['recipient'];
  recipientId?: IInvoice['recipientId'];
}

const recipientInvoiceByEvent = (event?: IEvent): IRecipientData | {} => {
  const isPreselectRecipient = event?.contactPeople?.length === 1;
  if (isPreselectRecipient) {
    return {
      recipient: event?.contactPeople.map((people) => ({ id: people.id, name: `${people.name}`, surname: `${people.surname}` }))[0],
      recipientId: event?.contactPeople[0].id
    };
  }
  return {};
};

const recipientInvoice = (invoice: IInvoice | IInvoiceListItem | null) => {
  if ((invoice as IInvoiceListItem)?.recipient?.fullName) {
    const name = (invoice as IInvoiceListItem)?.recipient?.fullName
      ? { name: (invoice as IInvoiceListItem)?.recipient?.fullName }
      : { name: (invoice as IInvoice)?.recipient?.name, surname: (invoice as IInvoice)?.recipient?.surname };
    return { id: invoice?.recipient?.id, ...name };
  }

  if (!!invoice?.event?.contactPeople?.length) {
    return invoice?.event?.contactPeople[0];
  }
  return null;
};

/**
 * util to prepare data for form
 * @param invoice source object or null for create form
 * @returns invoice form data
 */
export const prepareInvoiceDataForForm = (invoice: IInvoice | null): IInvoiceDialogForm => {
  return {
    id: invoice?.id,
    number: '',
    type: invoice?.type ?? EInvoiceType.invoice,
    state: invoice?.state ?? InvoiceStatus.NewCreated,
    valueType: invoice?.valueType ?? QuestionnaireTaxType.Net,
    invoiceDate: invoice?.invoiceDate ?? new Date(),
    performanceDate: invoice?.performanceDate ?? new Date(),
    event: invoice?.event,
    invoiceTemplate: invoice?.invoiceTemplateId ? { id: invoice.invoiceTemplateId } : null,
    recipient: recipientInvoice(invoice),
    venue: invoice?.venue ?? null,
    isSentEmail: !!invoice?.isSentEmail
  };
};

/**
 * util to prepare initial Invoice data based on the event
 * @param event selected event
 */
export const prepareInvoiceInitialByEvent = (event?: IEvent): IInvoice | undefined => {
  if (!event?.id) return;
  const venueData = event?.venue
    ? {
        venue: {
          id: event?.venue?.id,
          name: event?.venue?.name
        },
        venueId: event?.venue?.id
      }
    : {};

  return {
    number: '',
    ...recipientInvoiceByEvent(event),
    type: EInvoiceType.invoice,
    valueType: event?.questionnaire?.taxType ?? QuestionnaireTaxType.Gross,
    state: InvoiceStatus.NewCreated,
    invoiceDate: new Date(),
    performanceDate: event?.eventDate,
    ...venueData,
    event: { id: event?.id, name: event?.name ?? '', contactPeople: event?.contactPeople, eventManager: event?.eventManager },
    eventId: event?.id,
    finalAmount: 0,
    tenantId: Number(event?.questionnaire?.tenantId)
  };
};

/**
 * util to prepare data for post request
 * @param formData data from form
 * @returns invoice data payload
 */
export const prepareInvoiceFormForPost = (formData: IInvoiceDialogForm): IInvoicePostPayload => {
  const reqData: IInvoicePostPayload = {
    type: formData.type,
    valueType: formData.valueType,
    performanceDate: formData.performanceDate ?? undefined,
    invoiceDate: formData.invoiceDate!,
    eventId: formData.event?.id,
    invoiceTemplateId: formData.invoiceTemplate!.id!,
    recipientId: formData.recipient!.id!,
    venueId: formData.venue!.id!
  };
  return reqData;
};

/**
 * util to prepare data for put request
 * @param invoice data from form
 * @returns invoice data payload
 */
export const prepareInvoiceDataForSave = (invoice: IInvoiceDialogForm | null): IInvoiceForSave => {
  return {
    id: invoice?.id ?? 0,
    number: invoice?.number ?? '',
    type: invoice?.type ?? EInvoiceType.invoice,
    state: invoice?.state ?? InvoiceStatus.NewCreated,
    invoiceDate: invoice?.invoiceDate ?? null,
    valueType: invoice?.valueType ?? QuestionnaireTaxType.Net,
    performanceDate: invoice?.performanceDate ?? null,
    eventId: invoice?.event?.id,
    invoiceTemplateId: invoice?.invoiceTemplate?.id ?? undefined,
    recipientId: invoice?.recipient?.id,
    venueId: invoice?.venue?.id
  };
};

const prepareFooterTexts = (templateInvoice: IInvoiceEditorDetails['appearance']) => {
  const initialCountFooterBox = 1;
  // TODO: get rid of this field in api. Should be footerTexts field as in invoiceTemplate entity
  if (templateInvoice?.footers?.length) {
    return templateInvoice?.footers?.length ? templateInvoice?.footers : new Array(initialCountFooterBox).fill({ text: '' });
  }
  return templateInvoice?.footerTexts?.length ? templateInvoice?.footerTexts : new Array(initialCountFooterBox).fill({ text: '' });
};

export const prepareDataForRecipient = (users?: IContactPeople[]) => {
  return users?.map((user) => ({ ...user, name: `${user.name} ${user.surname}`.trim() })) ?? [];
};

export const prepareContactPersonByUser = (user: IUser): IContactPeople => {
  return {
    id: user.id,
    email: user.email,
    phoneNumber: user.phoneNumber ?? '',
    name: user.name,
    surname: user.surname,
    gender: user.gender,
    isActive: user.isActive ?? false,
    additionalContacts: user.additionalContacts,
    tenant: user?.tenant
  };
};

/**
 * util function to prepare form data for invoice editor
 * @param data invoice editor data
 * @param invoiceItems invoice items (articles)
 * @returns `IInvoiceFormData` object
 */
export const prepareInvoiceEditorToForm = (
  data?: IInvoiceEditorDetails,
  invoiceItems?: IEditorArticle<EInvoiceEditorArticleType>[]
): IInvoiceEditor => {
  if (!data)
    return {
      articles: [] as IEditorArticle<EInvoiceEditorArticleType>[]
    } as IInvoiceEditor;

  const { venue, appearance, address } = data;

  return {
    id: data?.id,
    number: data?.number,
    recipient: data.recipient,
    name: data?.name,
    type: data.type,
    taxType: data.valueType, //Gross
    state: data.state,
    baseDates: {
      invoiceDate: data?.invoiceDate,
      performanceDate: data?.performanceDate
    },
    venue: venue,
    settingsTemplate: {
      ...appearance,
      footerTexts: prepareFooterTexts(appearance)
    },
    data: data?.event,
    finalAmount: data.finalAmount,
    tenantId: data?.tenantId,
    isSentEmail: data?.isSentEmail,
    articles: invoiceItems ?? [],
    senderAddress: address,
    legalTax: 0,
    discount: data?.discount ?? 0
  };
};
/**
 * util function to prepare data to save invoice-editor main data
 * @param invoice
 * @returns `IInvoicePatchPayload` object
 */
export const prepareDataForPatchRequest = (invoice: IInvoiceEditor): IInvoicePatchPayload => {
  return {
    id: invoice.id ?? 0,
    state: invoice.state,
    valueType: invoice.taxType,
    type: invoice?.type,
    discount: invoice.discount ?? 0,
    closingText: invoice.settingsTemplate?.closingText,
    footers: invoice.settingsTemplate?.footerTexts,
    welcomeText: invoice.settingsTemplate?.welcomeText
  };
};

/**
 * util fucntion to return number value or 0;
 * @param value any param that can be a number as well
 * @returns `number`
 */
const defaultValueForNumberFields = (value: any): number => {
  return typeof value === 'number' ? value : 0;
};

/**
 * util function to prepare data to save invoice-editor items (articles)
 * @param articles array of articles (items)
 * @returns `IPageEditorArticle[]`
 */
export const prepareDataForItemsRequest = <AT>(articles: IEditorArticle<AT>[]): IEditorArticle<AT>[] => {
  return articles.map((article) => ({
    ...article,
    tax: defaultValueForNumberFields(article.tax),
    priceItems: article.priceItems.map((priceItem) => ({
      ...priceItem,
      count: defaultValueForNumberFields(priceItem.count),
      priceBrutto: defaultValueForNumberFields(priceItem.priceBrutto),
      priceNetto: defaultValueForNumberFields(priceItem.priceNetto)
    }))
  }));
};

export const generateTooltipByButtonSendEmail = (disableForEventManager: boolean, isSentEmail: boolean, dirtyForm: boolean) => {
  const tooltipSentEmailDisable = disableForEventManager ? 'invoices.invoice-editor-not-permission' : 'invoices.invoice-editor-button-lock';
  const tooltipButtonSendEmail = !isSentEmail && (dirtyForm || disableForEventManager);
  return tooltipButtonSendEmail ? tooltipSentEmailDisable : 'invoices.invoice-editor-send-email-button-tooltip';
};

/**
 * Util function to add wording in `unit` field in case if it Base-price item.
 * @param articles array of articles from event
 * @param intl tool for translate wordings
 * @returns `IPageEditorArticle[]` array of articles
 */
export const addBasePriceUnitWordingToArticle = (articles: IEditorArticle[], intl: IntlShape): IEditorArticle[] => {
  return articles.map((article) => ({
    ...article,
    priceItems:
      article.priceItems?.map((item) => {
        if (item.type === EEditorArticleItemType.Base) {
          return {
            ...item,
            unit: intl.formatMessage({ id: 'invoices.invoice-editor-base-price-unit' })
          };
        }
        return item;
      }) ?? []
  }));
};

/**
 * Util function to update order in article
 * @param articles array of articles
 */
export const addInnerIdToArticle = (articles: IEditorArticle[]) => {
  return articles.map((a) => ({ ...a, _innerId: uuidv4() }));
};

/**
 * Util function to update order in article
 * @param prevArticles array of articles on the page
 * @param articles array of articles from api
 */
export const updateOrderArticle = <T = EInvoiceEditorArticleType>(
  prevArticles: IEditorArticle<T>[] = [],
  articles: IEditorArticle<T>[] = []
): IEditorArticle<T>[] => {
  return [...prevArticles, ...articles].map((a, index) => ({ ...a, order: index + 1 }));
};

/**
 * Util function to sort articles by order
 * @param articles array of articles from api
 */
export const sortOrderArticles = <T>(articles: IEditorArticle<T>[] = []): IEditorArticle<T>[] => {
  return [...articles].sort((a, b) => a.order - b.order);
};

/* Method to check if ItemList has  some article of type, which was imported early */
export const checkIsImportedFromEvent = (articles: IEditorArticle[] = [], type: EInvoiceEditorArticleType): boolean => {
  return !!articles?.some((a) => a.syncKey && a.type === EInvoiceEditorArticleType[type]);
};

/* Method generate List Items with replacement */
export const generateListArticlesFromEventArticles = (
  type: EInvoiceEditorArticleType,
  articles: IEditorArticle[],
  resArticles: IEditorArticle[]
) => {
  const resArticlesClearFromOldImports: IEditorArticle[] = [];
  resArticles.map((res) => {
    const findSyncKey = articles?.find((a) => a?.syncKey === res?.syncKey && a.type === EInvoiceEditorArticleType[type]);
    if (!findSyncKey) {
      resArticlesClearFromOldImports.push(res);
    }
    return res;
  });

  return [...articles, ...resArticlesClearFromOldImports];
};
