import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ChangeEvent } from 'react';
import { Button, DialogContent, FormHelperText, Grid, IconButton, InputLabel, Stack, TextField } from '@mui/material';
import { Attach20Regular, DocumentPdf20Regular } from '@fluentui/react-icons';
import { useFormikContext } from 'formik';
import DraftTextEditor from 'shared/components/DraftTextEditor/DraftTextEditor';
import { generateLinkForPdfById } from 'modules/editor/utils';
import { IModalSendEmail } from 'modules/modalSendEmail/types';
import { EditorType } from 'modules/editor/types/editor';
import * as Styles from './ModalSendEmail.styles';
import { EmailTemplatePageType } from 'modules/settings/types';

interface IModalSendEmailBody {
  type: EditorType;
  handleChangeSubject: (newValueNumber: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleEditMessage: (newValue: string) => void;
  isSubjectReady: boolean;
  nameAttachFile: string;
}

const ModalSendEmailBody = (props: IModalSendEmailBody) => {
  const intl = useIntl();
  const { values, errors } = useFormikContext<IModalSendEmail>();
  return (
    <DialogContent
      dividers
      sx={{
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'auto'
      }}
    >
      <Styles.WrapperContentEmail container>
        <Grid item xs={12} sm={6}>
          <Stack spacing={1.25}>
            <InputLabel htmlFor="modal-email-subject">
              <FormattedMessage id="settings.email-template-subject" />
            </InputLabel>
            <TextField
              id="modal-email-subject"
              fullWidth
              name="subject"
              value={values?.subject}
              placeholder={intl.formatMessage({ id: 'settings.enter' })}
              onChange={props.handleChangeSubject}
            />
          </Stack>
        </Grid>
      </Styles.WrapperContentEmail>
      <Styles.WrapperContentEmail item xs={12}>
        <Stack mt="1px" spacing={1.5}>
          <DraftTextEditor
            page={EmailTemplatePageType.EmailTemplate}
            value={values?.message || ''}
            onChange={props.handleEditMessage}
            isMount={props.isSubjectReady}
          />
          {errors?.message && (
            <FormHelperText error id="standard-weight-helper-text-email">
              {errors?.message}
            </FormHelperText>
          )}
        </Stack>
      </Styles.WrapperContentEmail>

      <Styles.WrapperContentEmail item xs={12}>
        <Stack direction="row" spacing={1.5}>
          <IconButton disabled>
            <Attach20Regular />
          </IconButton>
          <Button
            size="small"
            replace
            component={Link}
            to={values.id ? generateLinkForPdfById(values.id, props.type) : ''}
            target="_blank"
            variant="outlined"
            color="secondary"
            startIcon={<DocumentPdf20Regular />}
            sx={{
              textTransform: 'math-auto'
            }}
          >
            {props.nameAttachFile}
          </Button>
        </Stack>
      </Styles.WrapperContentEmail>
    </DialogContent>
  );
};

export default ModalSendEmailBody;
