import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { Toolbar } from '@mui/material';
import useConfig from 'shared/hooks/useConfig';
import * as Styles from 'modules/organisation-plan-templates/layouts/OrganisationPlanTemplateLayout.styles';

const TableLayout = (): ReactElement => {
  const { container } = useConfig();

  return (
    <Styles.BoxWrapper>
      <Styles.BoxMainComponent component="main" sx={{ p: { xs: 2, sm: 3 } }}>
        <Toolbar />
        {container ? (
          <Styles.TableLayoutWrapper>
            <Outlet />
          </Styles.TableLayoutWrapper>
        ) : (
          <Styles.BoxOutletWrapper>
            <Outlet />
          </Styles.BoxOutletWrapper>
        )}
      </Styles.BoxMainComponent>
    </Styles.BoxWrapper>
  );
};

export default TableLayout;
