import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Divider, FormControl, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { DateRangePicker, LocalizationProvider, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTheme } from '@mui/material/styles';
import isEqual from 'lodash/isEqual';
import { Calendar20Regular } from '@fluentui/react-icons';
import { useGetTimeRangeFilter } from 'modules/dashboard/hooks/useGetTimeRangeFilter';
import '/picker.css';
import { useUpdateWidgetById } from 'api/hooks/dashboard/useUpdateWidgetByID';
import { textError } from 'shared/utils/infoText';
import { useInfoMessage } from 'shared/hooks/useInfoMessage';
import { convertString, createTimeRangeOptions } from 'modules/dashboard/utils';
import { IUpdateWidgetStore } from 'modules/dashboard/components/widgets/TableDashboardWidget/components/UpperHeaderTable';
import { WidgetsDashboardType } from 'modules/dashboard/types';
import * as Styles from './DashboardFilter.styles';

type Option = {
  value: string;
  label: string;
};

interface ITimeRangeFilter {
  widget?: WidgetsDashboardType;
  isCustomize: boolean;
  updateWidgetFilters: ({ field, value }: IUpdateWidgetStore) => void;
}

const TimeRangeFilter = ({ widget, updateWidgetFilters, isCustomize }: ITimeRangeFilter) => {
  const { data } = useGetTimeRangeFilter();
  const intl = useIntl();
  const { successMessage, errorMessage } = useInfoMessage();

  const { mutateAsync: updateWidgetById, isLoading } = useUpdateWidgetById({
    onSuccess() {
      successMessage(intl.formatMessage({ id: 'dashboard.dashboard-widget-edited-successfully' }));
    },
    options: {
      onError() {
        errorMessage(textError(intl.formatMessage({ id: 'dashboard.dashboard-widget-edited-failed' })));
      }
    },
    widgetId: widget?.id?.toString() || ''
  });
  const timeRange = createTimeRangeOptions(data);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = async (option: Option) => {
    if (!widget) return;

    let timeRangeValue = option.value === 'all' ? 'Custom' : option.value;

    const newTimeRange = { from: null, to: null, type: timeRangeValue };

    if (!widget.id || isCustomize) {
      updateWidgetFilters({ field: 'timeRange', value: newTimeRange });
    }

    if (widget.id && !isCustomize && !isEqual(widget.filters?.timeRange, newTimeRange)) {
      await updateWidgetById(
        {
          ...widget,
          filters: {
            ...widget.filters,
            timeRange: newTimeRange
          },
          systemId: widget.systemId
        },
        {
          onSuccess: () => {
            updateWidgetFilters({ field: 'timeRange', value: newTimeRange });
          }
        }
      );
    }
    handleClose();
  };

  const handleDateRangeChange = async (dateRange: [Date | null, Date | null]) => {
    if (dateRange && widget) {
      const newTimeRange = { from: dateRange[0], to: dateRange[1], type: 'Custom' };

      if (!widget.id) {
        updateWidgetFilters({ field: 'timeRange', value: newTimeRange });
      }

      if (widget.id && !isEqual(widget.filters?.timeRange, newTimeRange)) {
        await updateWidgetById(
          {
            ...widget,
            filters: {
              ...widget.filters,
              timeRange: newTimeRange
            },
            systemId: widget.systemId
          },
          {
            onSuccess: () => {
              updateWidgetFilters({ field: 'timeRange', value: newTimeRange });
            }
          }
        );
      }
    }
    handleClose();
  };

  const theme = useTheme();

  return (
    <Box>
      <FormControl>
        <Tooltip arrow enterDelay={1300} title={intl.formatMessage({ id: 'dashboard-table.filter-by-time' })}>
          <Styles.FilterButton disabled={isLoading} onClick={handleClick}>
            <Calendar20Regular />

            {widget?.filters?.timeRange?.type !== 'Custom' ? (
              <Box sx={{ margin: '0 5px' }}>{widget?.filters?.timeRange?.type} </Box>
            ) : (
              widget?.filters?.timeRange?.from &&
              widget?.filters?.timeRange?.to && (
                <Box sx={{ margin: '0 5px' }}>
                  {new Date(widget?.filters?.timeRange?.from).toLocaleDateString() ===
                  new Date(widget?.filters?.timeRange?.to).toLocaleDateString() ? (
                    <Box>{new Date(widget?.filters?.timeRange?.from).toLocaleDateString()}</Box>
                  ) : (
                    <Box>
                      {new Date(widget?.filters?.timeRange?.from).toLocaleDateString()} -{' '}
                      {new Date(widget?.filters?.timeRange?.to).toLocaleDateString()}
                    </Box>
                  )}
                </Box>
              )
            )}
          </Styles.FilterButton>
        </Tooltip>
        <Styles.FilterMenu
          isPadding={true}
          anchorOrigin={{
            vertical: 40,
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Stack
            sx={{
              position: 'sticky',
              top: 0
            }}
          >
            <Styles.DateRangeWrapper>
              <Box sx={{ zIndex: 9999999 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateRangePicker
                    sx={{ width: '100%' }}
                    autoFocus
                    calendars={3}
                    value={
                      widget?.filters?.timeRange?.from && widget?.filters?.timeRange?.to
                        ? [new Date(widget?.filters?.timeRange?.from), new Date(widget?.filters?.timeRange?.to)]
                        : [null, null]
                    }
                    onAccept={(newValue) => handleDateRangeChange(newValue)}
                    slots={{ field: SingleInputDateRangeField }}
                  />
                </LocalizationProvider>
              </Box>
            </Styles.DateRangeWrapper>
            <Divider />
            <Divider />
            <Styles.ResetButtonBox onClick={() => handleOptionClick({ value: 'Custom', label: 'All times' })}>
              <FormattedMessage id="dashboard-table.all-times" />
            </Styles.ResetButtonBox>
            <Divider />
            <Divider />
          </Stack>
          <Box sx={{ height: '250px', overflowY: 'auto' }}>
            {timeRange?.map(
              (option) =>
                option.label !== 'Custom' && (
                  <MenuItem
                    sx={{
                      backgroundColor: widget?.filters?.timeRange?.type === option.value ? theme.palette.primary.lighter : 'inherit',
                      color: widget?.filters?.timeRange?.type === option.value ? theme.palette.primary.main : 'inherit'
                    }}
                    key={option.value}
                    onClick={() => handleOptionClick(option)}
                  >
                    <Typography sx={{ color: theme.palette.text.primary }}>{convertString(option.label)}</Typography>
                  </MenuItem>
                )
            )}
          </Box>
        </Styles.FilterMenu>
      </FormControl>
    </Box>
  );
};

export default TimeRangeFilter;
