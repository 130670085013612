import { Box, BoxProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SectionBox = styled(Box)(() => ({
  position: 'relative',
  paddingBottom: '30px'
}));

export const GeneralLabelTypographyWrapper = styled(Typography)(() => ({
  fontWeight: 450,
  fontSize: '14px'
}));

export const containerPropsStyle: BoxProps = {
  display: 'flex',
  width: '206px',
  height: '171px',
  flexDirection: 'column',
  gap: '10px'
};
export const additionalPropsStyle: BoxProps = {
  width: '206px',
  height: '152px',
  flexDirection: 'column'
};
