import { extractContentFromHtml } from 'modules/organisation-plan-preview/utils/labels';
import * as Styles from './PreviewRenderOption.styles';

interface IPreviewRenderOption {
  element: JSX.Element | JSX.Element[] | null | undefined;
  label: string;
  isMainLabelHidden?: boolean;
}

const PreviewRenderOption = ({ element, label, isMainLabelHidden }: IPreviewRenderOption) => {
  const labelText = extractContentFromHtml(label);
  return (
    <>
      {!!element && (
        <>
          <Styles.GeneralLabelTypographyWrapper isMainLabelHidden={!!isMainLabelHidden}>{labelText}</Styles.GeneralLabelTypographyWrapper>
          {element}
        </>
      )}
    </>
  );
};

export default PreviewRenderOption;
