import { UseMutationOptions, UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { LoginCompanyApiResponce } from 'modules/auth/types';

const getTenantByUrl = (tenantUrl: string | undefined): Promise<LoginCompanyApiResponce> => {
  return axios.get(`/tenants/resolve?url=${tenantUrl}`).then((res) => res.data);
};

export function useGetCompany<TError, TContext>({
  options = {},
  onSuccess
}: {
  options?: Omit<
    UseMutationOptions<LoginCompanyApiResponce, TError, string | undefined, TContext>,
    'mutationFn' | 'onSuccess' | 'variables'
  >;
  onSuccess?: (response: LoginCompanyApiResponce) => void;
}) {
  const queryClient = useQueryClient();

  return useMutation(getTenantByUrl, {
    ...options,
    onSuccess: (data) => {
      queryClient.setQueryData(['company'], data);
      if (onSuccess) {
        onSuccess(data);
      }
    }
  });
}

export function useGetTenantByUrl<TError>({
  options = {},
  tenantUrl
}: {
  options?: Omit<
    UseQueryOptions<LoginCompanyApiResponce, TError, LoginCompanyApiResponce, ['tenant', { url: string }]>,
    'queryKey' | 'queryFn'
  >;
  tenantUrl?: string | null;
}) {
  return useQuery(['tenant', { url: tenantUrl! }], () => getTenantByUrl(tenantUrl!), {
    ...options,
    enabled: !!tenantUrl,
    onSuccess: (data) => {
      if (options.onSuccess) {
        options.onSuccess(data);
      }
    }
  });
}
