import { Box, Button, Typography } from '@mui/material';
import { Theme, styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export const ButtonEdit = styled(Button)(({ theme }) => ({
  position: 'relative',
  padding: 0,
  width: '100%',
  height: '102px',
  border: `1px solid ${theme.palette.secondary.light}`,
  overflow: 'hidden'
}));

export const ButtonText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: 'rgba(0,0,0,0.3)',
  width: '88%',
  height: '88%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,
  color: theme.palette.secondary.light,
  '& .MuiButton-root': {
    width: '100%',
    height: '100%',
    '&:hover': { backgroundColor: 'transparent' },
    '& svg': { color: theme.palette.secondary.light }
  }
}));

export const BoxImage = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  marginBottom: '1rem',
  '& img': {
    height: '100%',
    width: '100%'
  }
}));

export const BtnNoEdit = styled(Button)(({ theme }) => ({
  position: 'relative',
  padding: 0,
  width: '100%',
  height: '102px',
  overflow: 'hidden',
  border: `1px solid ${theme.palette.secondary.light}`
}));

export const imgStyles = {
  width: '100%'
};

export const buttonAddStyles: SxProps<Theme> = {
  width: '100%',
  height: '104px'
};
