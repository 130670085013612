/**
 * Util function to reorder element in array by 2 indexes
 * @param indexFrom previous position index of element
 * @param indexTo next position index of element
 * @param array array
 * @returns array
 */
export const reorderArray = <T>(indexFrom: number, indexTo: number, array: T[]): T[] => {
  const newArray = [...array];
  const [reorderedItem] = newArray.splice(indexFrom, 1);
  newArray.splice(indexTo, 0, reorderedItem);
  return newArray;
};
