import { lazy } from 'react';
import Loadable from 'shared/components/Loadable';
import AuthGuard from 'shared/components/route-guard/AuthGuard';
import { BUILDER_ROUTES } from './constants/builder';
import BuilderLayout from './layouts/BuilderLayout';

const BuilderPageEntry = Loadable(lazy(() => import('pages/Questionnaires/Builder')));
const BuilderPageById = Loadable(lazy(() => import('pages/Questionnaires/BuilderPageById')));
const BuilderPlayground = Loadable(lazy(() => import('modules/builder/containers/PlaygroundTemplateContainer')));

export const BuilderRoutes = {
  path: '/',
  children: [
    {
      path: BUILDER_ROUTES.BUILDER,
      element: (
        <AuthGuard>
          <BuilderLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: ':questionnaireId',
          element: <BuilderPageEntry />
        },
        {
          path: ':questionnaireId/:pageId',
          element: <BuilderPageById />
        },
        {
          path: ':questionnaireId/:pageId/:subPageId',
          element: <BuilderPageById />
        }
      ]
    },
    {
      path: 'playground/:questionnaireId/:pageId',
      element: (
        <AuthGuard>
          <BuilderPlayground />
        </AuthGuard>
      )
    },
    {
      path: 'playground/:questionnaireId/:pageId/:subPageId',
      element: (
        <AuthGuard>
          <BuilderPlayground />
        </AuthGuard>
      )
    }
  ]
};
