import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { DashboardQueryKey } from 'shared/types/dasboard';
import { WidgetsDashboardType } from 'modules/dashboard/types';

const updateWidgetById = (widget: WidgetsDashboardType) => {
  return axios.put(`/dashboard-widgets/${widget.id}`, widget);
};

export function useUpdateWidgetById<TError>({
  options = {},
  onSuccess,
  widgetId
}: {
  options?: Omit<UseMutationOptions<WidgetsDashboardType, TError, WidgetsDashboardType, any>, 'mutationFn' | 'onSuccess' | 'variables'>;
  onSuccess?: () => void;
  widgetId: string;
}) {
  const queryClient = useQueryClient();

  return useMutation(updateWidgetById, {
    ...options,
    onSuccess: () => {
      if (onSuccess) onSuccess();
      queryClient.invalidateQueries(['key-numbers-data', widgetId]);
    },
    onSettled: (_, error) => {
      if (error) {
        queryClient.invalidateQueries([DashboardQueryKey.DashboardDefault]);
      }
    }
  });
}
