import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IGeneralLabelTypographyWrapper {
  isMainLabelHidden: boolean;
}
export const GeneralLabelTypographyWrapper = styled(Typography)<IGeneralLabelTypographyWrapper>(({ isMainLabelHidden }) => ({
  fontWeight: 700,
  fontSize: '14px',
  color: '#262626',
  margin: '0px',
  display: isMainLabelHidden ? 'none' : 'block'
}));
