import { useFormikContext } from 'formik';
import { IInvoiceEditor } from 'shared/types/invoice';
import EditorDetails, { IEditorDetails } from 'modules/editor/components/EditorDetails';
import { useCurrentDateFormatter } from 'shared/hooks/regionalSettings';
import { EEditorType } from 'modules/editor/types/editor';

const InvoiceEditorDetails = () => {
  const { values } = useFormikContext<IInvoiceEditor>();
  const templateLogo = typeof values?.settingsTemplate?.logoImagePath === 'string' ? values?.settingsTemplate?.logoImagePath : '';

  const dataAdditional: IEditorDetails['data'] = [
    { intlId: 'invoices.invoice-editor-invoice-number', value: values.number ?? '' },
    { intlId: 'invoices.invoice-editor-date-of-invoice', value: useCurrentDateFormatter(values.baseDates?.invoiceDate as string) },
    { intlId: 'invoices.invoice-editor-performance-date', value: useCurrentDateFormatter(values.baseDates?.performanceDate as string) }
  ];

  return <EditorDetails type={EEditorType.Invoices} templateLogo={templateLogo} data={dataAdditional} />;
};

export default InvoiceEditorDetails;
