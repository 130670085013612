import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack
} from '@mui/material';
import { Add16Filled, Delete16Regular, Delete20Regular, LockClosed16Filled, TagSearch20Regular } from '@fluentui/react-icons';
import EditableLabel from 'modules/builder/components/EditableLabel';
import { ITableDashboardWidgetColumn, TableDashboardWidgetType } from 'modules/dashboard/types/TableDashboardWidgetType';
import { deleteDashboardWidget, updateWidget } from 'store/dashboard/dashboard.slice';
import { snackbarAlert } from 'shared/utils/infoText';
import { AlertMessagesColor } from 'shared/types/alert-messages';
import { useInfoMessage } from 'shared/hooks/useInfoMessage';
import { useUpdateDashboardWidgetById } from 'api/hooks/dashboard/useUpdateDashboardWidgetById';
import { ETagData, ITagInfo, ITags, ITagsTranslations, WidgetsDashboardType } from 'modules/dashboard/types';
import { useGetDashboardTableWidgetColumns } from 'api/hooks/dashboard/useGetDashboardTableWidgetColumns';
import {
  IInitialColumnsArray,
  ITransformedTableData,
  transformDefaultColumnsData
} from 'modules/dashboard/components/widgets/TableDashboardWidget/utils/transformDefaultColumnsData';
import { reorderAvailableColumns } from 'modules/dashboard/components/widgets/TableDashboardWidget/utils/reorderAvailableColumns';
import ModalTags from 'modules/dashboard/components/widgets/TableDashboardWidget/ModalTags';
import { filterUniqueTags, generateInfoTag } from 'modules/dashboard/utils';
import { DashboardQueryKey } from 'shared/types/dasboard';
import { useGetTagsInfinite } from 'modules/dashboard/hooks/useGetTagsInfinite';
import { transformSelectedTags, transformTags } from 'modules/dashboard/components/widgets/TableDashboardWidget/utils/transformTagsData';
import * as Styles from './TableModal.styles';

interface ITableFormModalProps {
  isOpenModal: boolean;
  closeModal: () => void;
  widget: TableDashboardWidgetType | undefined;
  isCustomize: boolean;
  isGroupWidget: boolean;
}

const TableFormModal = ({ isOpenModal, closeModal, widget, isCustomize, isGroupWidget }: ITableFormModalProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { successMessage } = useInfoMessage();

  const [tableType, setTableType] = useState(widget?.data.primaryColumn || 'eventName');
  const [tableName, setTableName] = useState(widget?.data?.name || intl.formatMessage({ id: 'dashboard.custom-table' }));
  const [isAddColumns, setIsAddColumns] = useState(true);
  const [isOpenTag, setIsOpenTag] = useState(false);
  const [tagsInfo, setTagsInfo] = useState<ITags[]>([]);
  const [transformedColumns, setTransformedColumns] = useState<ITransformedTableData[]>([]);
  const [availableColumns, setAvailableColumns] = useState<ITableDashboardWidgetColumn[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<ITableDashboardWidgetColumn[]>(widget?.data.columns || []);
  const [previousTags, setPreviousTags] = useState<ITagInfo[]>(widget?.dashboardWidgetTags || []);

  const { data: defaultData } = useGetDashboardTableWidgetColumns();
  const { data: fetchedTags, hasNextPage, fetchNextPage, isFetchingNextPage } = useGetTagsInfinite('');

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchedTags, isFetchingNextPage, hasNextPage, fetchNextPage]);

  useEffect(() => {
    if (typeof widget?.data.withFooters === 'boolean') {
      setIsAddColumns(widget?.data.withFooters);
    }
  }, [widget?.data.withFooters]);

  useEffect(() => {
    if (widget?.dashboardWidgetTags && Array.isArray(widget?.dashboardWidgetTags)) {
      setPreviousTags(widget.dashboardWidgetTags);
    }
  }, [widget?.dashboardWidgetTags]);

  useEffect(() => {
    const { transformedData, initialColumns } = transformDefaultColumnsData(defaultData as IInitialColumnsArray[], tableType);
    setTransformedColumns(transformedData);
    if (widget?.data.columns) {
      setAvailableColumns(reorderAvailableColumns(initialColumns, widget?.data.columns));
    } else {
      setAvailableColumns(initialColumns);
      setSelectedColumns([initialColumns[0]]);
    }

    // TODO: Fix this issue
  }, [defaultData, widget?.data.columns]);

  useEffect(() => {
    if (!widget) return;
    if (availableColumns.length && widget.dashboardWidgetTags?.length && !isCreate) {
      const tagsTranslations: ITagsTranslations[] = [
        { type: ETagData.Revenue, intlId: intl.formatMessage({ id: 'dashboard.revenue' }) },
        { type: ETagData.NumberBookings, intlId: intl.formatMessage({ id: 'dashboard-table.bookings' }) },
        { type: ETagData.NumberUnitsSold, intlId: intl.formatMessage({ id: 'dashboard-table.units-sold' }) }
      ];

      const allTags = widget?.dashboardWidgetTags?.map((tag) => generateInfoTag(tag, tag.statisticType, tagsInfo, tagsTranslations));

      setSelectedColumns((prevSelectedColumns) => {
        const uniqueTags = filterUniqueTags(prevSelectedColumns, allTags);
        return [...prevSelectedColumns, ...uniqueTags].map((item, index) => ({ ...item, order: index }));
      });

      setAvailableColumns((prevAvailableColumns) => {
        const uniqueTags = filterUniqueTags(prevAvailableColumns, allTags);
        return [...prevAvailableColumns, ...uniqueTags].map((item, index) => ({ ...item, order: index }));
      });
    }
    // TODO: Fix this issue
  }, [intl, tagsInfo, widget, widget?.dashboardWidgetTags]);

  const handleChangeTableType = (event: SelectChangeEvent<string>) => {
    const newTableType = event.target.value;
    if (newTableType === tableType) return;
    const columns = transformedColumns.find((type: ITransformedTableData) => type.primaryColumn === newTableType)?.columns;
    if (columns) {
      setPreviousTags([]);
      setTableType(newTableType);
      const updatedColumns = columns.map((column, index) => ({ ...column, order: index }));
      setAvailableColumns(updatedColumns);
      setSelectedColumns([updatedColumns[0]]);
    }
  };

  const handleOpenTagModal = () => {
    setIsOpenTag(true);
  };

  const handleCloseTagModal = (tags?: ITags[]) => {
    if (tags) {
      setTagsInfo(tags);
    }
    setIsOpenTag(false);
  };

  const columnButtonHandler = (selectedColumn: ITableDashboardWidgetColumn) => {
    setSelectedColumns((prevSelectedColumns) => {
      if (prevSelectedColumns.find((col) => col.name === selectedColumn.name)) {
        return prevSelectedColumns.filter((column) => column.name !== selectedColumn.name);
      } else {
        const newArr = [...prevSelectedColumns, selectedColumn];
        return newArr.map((column, index) => ({ ...column, order: index }));
      }
    });
  };

  const columnButtonTagsHandler = (selectedColumn: ITableDashboardWidgetColumn) => {
    setSelectedColumns((prevSelectedColumns) =>
      prevSelectedColumns.filter((column) => `${column.name}-${column.tagType}` !== `${selectedColumn.name}-${selectedColumn.tagType}`)
    );
    setPreviousTags((prevSelectedColumns) =>
      prevSelectedColumns.filter(
        (column) => `${column.articleTagId}-${column.statisticType}` !== `${selectedColumn.id}-${selectedColumn.tagType}`
      )
    );
    setAvailableColumns((prevAvailableColumns) =>
      prevAvailableColumns.filter((column) => `${column.name}-${column.tagType}` !== `${selectedColumn.name}-${selectedColumn.tagType}`)
    );
  };

  const handleDragStart = (event: React.DragEvent<HTMLButtonElement>, column: ITableDashboardWidgetColumn) => {
    event.dataTransfer.setData('text/plain', JSON.stringify(column));
  };

  const handleDragOver = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLButtonElement>, index: number) => {
    event.preventDefault();
    const data = event.dataTransfer.getData('text/plain');
    if (data) {
      const column = JSON.parse(data);
      const fromIndex = availableColumns.findIndex((c) => `${c?.id}-${c.label}` === `${c?.id}-${column.label}`);
      if (fromIndex !== -1) {
        const updatedAvailableColumns = [...availableColumns];
        const movedColumn = updatedAvailableColumns.splice(fromIndex, 1)[0];
        updatedAvailableColumns.splice(index, 0, movedColumn);
        setAvailableColumns(updatedAvailableColumns);

        const updatedSelectedColumns = selectedColumns.map((col) => {
          const colIndex = updatedAvailableColumns.findIndex((c) => `${c?.id}-${c.label}` === `${c?.id}-${col.label}`);
          return { ...col, order: colIndex };
        });
        updatedSelectedColumns.sort((a, b) => a.order - b.order);
        const finalUpdatedSelectedColumns = updatedSelectedColumns.map((col, index) => ({
          ...col,
          order: index
        }));
        setSelectedColumns(finalUpdatedSelectedColumns);
      }
    }
  };

  const hadleDeleteConfiguration = () => {
    if (!widget?.systemId) return;
    closeModal();
    dispatch(deleteDashboardWidget({ systemId: widget?.systemId, groupId: widget.groupId }));
  };

  const { isLoading: isCreate, mutateAsync: updateWidgetById } = useUpdateDashboardWidgetById({
    onSuccess: (data: WidgetsDashboardType) => {
      dispatch(
        updateWidget({
          widgetId: data.systemId,
          widget: {
            dashboardWidgetType: data.dashboardWidgetType,
            dashboardWidgetTags: data.dashboardWidgetTags,
            data: data.data
          }
        })
      );
      successMessage(intl.formatMessage({ id: 'dashboard.dashboard-widget-edited-successfully' }));
    },
    options: {
      onError() {
        dispatch(snackbarAlert(intl.formatMessage({ id: 'dashboard.dashboard-widget-edited-failed' }), AlertMessagesColor.Error));
      }
    }
  });

  const handleSave = async () => {
    if (!widget) return;
    const tagsForCalculation = widget.dataTags || fetchedTags;
    const transformedTags = transformSelectedTags({ dataTags: tagsForCalculation, selectedColumns });
    const transformedOldTagsArray = transformTags({ dataTags: tagsForCalculation, fromSelectedTags: transformedTags });

    const dataUpdate: WidgetsDashboardType = {
      filters: {
        ...widget.filters
      },
      id: widget.id,
      userDashboardId: widget.userDashboardId,
      dashboardWidgetType: widget.dashboardWidgetType,
      dashboardWidgetTags: transformedOldTagsArray,
      tags: transformedTags,
      layout: widget.layout,
      data: {
        ...widget.data,
        primaryColumn: tableType,
        columns: selectedColumns,
        withFooters: isAddColumns,
        name: tableName
      },
      groupId: widget.groupId,
      systemId: widget.systemId
    };

    if (!isCustomize) {
      await updateWidgetById({ id: widget.id, data: dataUpdate });
      queryClient.invalidateQueries([DashboardQueryKey.DashboardTable, widget.id]);
    } else {
      dispatch(
        updateWidget({
          widgetId: widget.systemId,
          widget: {
            dashboardWidgetType: widget.dashboardWidgetType,
            dashboardWidgetTags: transformedOldTagsArray,
            tags: transformedTags,
            data: {
              ...widget.data,
              primaryColumn: tableType,
              columns: selectedColumns,
              withFooters: isAddColumns,
              name: tableName
            }
          }
        })
      );
    }

    closeModal();
    setIsAddColumns(true);
  };

  const handleSaveNameTable = (newName: string) => {
    setTableName(newName);
  };

  return (
    <>
      {!isOpenTag && (
        <Dialog fullWidth maxWidth="lg" onClose={closeModal} open={isOpenModal}>
          <DialogTitle>
            <Box sx={{ width: '100%', maxWidth: '500px' }}>
              <EditableLabel value={tableName} onSubmit={handleSaveNameTable} />
            </Box>
            <Box>
              {isGroupWidget && isCustomize && (
                <Button
                  size="small"
                  startIcon={<Delete20Regular />}
                  disabled={isCreate}
                  color="error"
                  variant="text"
                  onClick={hadleDeleteConfiguration}
                >
                  <FormattedMessage id="dashboard-table.delete-this-table-configuration" />
                </Button>
              )}
            </Box>
          </DialogTitle>
          <DialogContent dividers sx={{ p: 2.5 }}>
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <Stack spacing={1.25}>
                  <InputLabel htmlFor="table-type">
                    <FormattedMessage id="dashboard-table.select-main-information" />
                  </InputLabel>
                  <Select
                    fullWidth
                    value={tableType}
                    label={intl.formatMessage({
                      id: `${transformedColumns.find((type) => type.primaryColumn === tableType)?.primaryColumnIntlId}`
                    })}
                    name="tableType"
                    onChange={handleChangeTableType}
                  >
                    {transformedColumns.map((type: ITransformedTableData) => (
                      <MenuItem key={`selector-lang-${type.primaryColumn}`} value={type.primaryColumn}>
                        <FormattedMessage id={`${type.primaryColumnIntlId}`} />
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>
              <Grid item sm={6} sx={{ marginTop: 3.75 }}>
                <Button size="small" color="primary" variant="contained" startIcon={<TagSearch20Regular />} onClick={handleOpenTagModal}>
                  <FormattedMessage id="dashboard.add-tags" />
                </Button>
                <FormControlLabel
                  sx={{ pl: '15px', display: 'inline-box' }}
                  control={<Checkbox checked={isAddColumns} onChange={(e) => setIsAddColumns(e.target.checked)} name="addColumns" />}
                  label={intl.formatMessage({ id: 'dashboard-table.add-up-the-columns' })}
                />
              </Grid>
              <Grid item sx={{ margin: '20px 0' }}>
                <Styles.DndContainerWrapper>
                  {availableColumns.map((column: ITableDashboardWidgetColumn, index: number) => {
                    const isFirstElement = index === 0;
                    const isSelected = isFirstElement || selectedColumns.find((col) => col.name === column.name);
                    return (
                      <Button
                        disabled={isCreate || isFirstElement}
                        disableRipple
                        key={`${column?.id}-${column.label}`}
                        variant={isSelected ? 'outlined' : 'text'}
                        size="small"
                        onClick={column.type === 'tag' ? () => columnButtonTagsHandler(column) : () => columnButtonHandler(column)}
                        draggable={!isFirstElement}
                        onDragStart={(e) => handleDragStart(e, column)}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, index)}
                        startIcon={isFirstElement ? <LockClosed16Filled /> : isSelected ? null : <Add16Filled />}
                        endIcon={isSelected && !isFirstElement && <Delete16Regular />}
                      >
                        {column.intlId ? intl.formatMessage({ id: column.intlId }) : column.label}
                      </Button>
                    );
                  })}
                </Styles.DndContainerWrapper>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button
              disabled={isCreate}
              variant="outlined"
              color="error"
              size="small"
              onClick={closeModal}
              sx={{ textTransform: 'capitalize' }}
            >
              <FormattedMessage id="widgets.cancle" />
            </Button>
            <Button disabled={isCreate} variant="contained" size="small" onClick={handleSave} sx={{ textTransform: 'capitalize' }}>
              <FormattedMessage id="widgets.save" />
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <ModalTags
        previousTags={previousTags}
        multiple
        selectedColumns={selectedColumns}
        widget={widget}
        open={isOpenTag}
        handleClose={handleCloseTagModal}
      />
    </>
  );
};

export default TableFormModal;
