import { INFINITY_QUERY_PAGE_SIZE } from 'api/constants/constant';
import { IPageQueryParams } from 'api/types/infinityQueryPageParams';
import axios from 'axios';
import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { IPaginatedResult } from 'shared/types/paginatedResult';
import { OfferTemplateListItemType } from 'shared/types/settingsOffer';
import { EOfferKey } from './useGetOfferSettings';

interface IUseGetByIdProps {
  id?: string;
  searchValue?: string;
}

interface IPageParam extends IPageQueryParams {
  tenantId: number;
}

const fetchOfferTemplatesByTenantId = (params: IPageParam): Promise<IPaginatedResult<OfferTemplateListItemType>> => {
  return axios.get(`/${EOfferKey.templates}`, { params: { ...params, pageSize: INFINITY_QUERY_PAGE_SIZE } }).then((res) => res.data);
};

export function useGetAllOfferTemplatesInfinite({ id, searchValue }: IUseGetByIdProps) {
  const search = searchValue === '' ? undefined : searchValue;
  const query = useInfiniteQuery({
    queryKey: [EOfferKey.templates, { search }],
    queryFn: async ({ pageParam = 1 }) => await fetchOfferTemplatesByTenantId({ tenantId: Number(id ?? 0), pageNumber: pageParam, search }),
    getNextPageParam: (lastPage: IPaginatedResult<OfferTemplateListItemType>) => {
      const amountOfPages = Math.ceil(lastPage.totalRecords / lastPage.pageSize);
      if (lastPage.pageNumber < amountOfPages) {
        return lastPage.pageNumber + 1;
      } else {
        return undefined;
      }
    }
  });

  const data = useMemo(() => {
    return query.data?.pages
      .map((x) => x.data)
      .flat(1)
      .filter((item) => !!item);
  }, [query.data?.pages]);

  return { ...query, data };
}
