import { UseQueryResult, useQuery } from 'react-query';
import axios from 'axios';
import { mapQuestionnaireTemplatePageToBuilderPageItem } from 'modules/builder/builder-service';
import { QuestionnaireTemplatePageItem } from 'shared/types/questionnaire-template';
import { BuilderPageItemType } from 'modules/builder/types';
import { useDefaultBuilderPagesTitle } from 'api/hooks/questionnaires/useDefaultBuilderPagesTitle';

/**
 * Get questionnaire templates pages list by id and tenant
 * @param id - questionnaire template id
 * @param token - token from org plan for not authorized users
 */
const fetchQuestionnairePagesRequest = async (id: number, token: string): Promise<QuestionnaireTemplatePageItem[]> => {
  try {
    const resp = await axios.get(`organization-plans/public/questionnaire-pages?token=${token}`, {
      params: {
        questionnaireId: id
      }
    });
    return resp.data;
  } catch (e) {
    throw new Error(`Failed to load Questionnaire template pages with template id ${id}`);
  }
};

/**
 * Method to fetch builder pages items
 * @description Fetches pages data from questionnaire api and maps to builder pages entities
 * @param id - questionnaire template id
 * @param token - token from org plan for not authorized users.
 * */
const fetchQuestionnairePages = async (id: number, token: string): Promise<BuilderPageItemType[]> => {
  const data = await fetchQuestionnairePagesRequest(id, token);
  return (
    data &&
    data.map((item) =>
      mapQuestionnaireTemplatePageToBuilderPageItem(item, {
        welcomePageRequest: useDefaultBuilderPagesTitle().welcomePageRequest,
        welcomePage: useDefaultBuilderPagesTitle().welcomePage,
        guestsAndRooms: useDefaultBuilderPagesTitle().guestsAndRooms,
        title: useDefaultBuilderPagesTitle().title
      })
    )
  );
};

/**
 * Query to load questionnaires pages for org plan with not authorized user
 * @param id - questionnaire template id
 * @param token - user token information
 * @example { data, isLoading, error } = useGetQuestionnairesPagesByIdForOrgPlan()
 */
export const useGetQuestionnairesPagesByIdForOrgPlan = <T,>(id?: number, token?: string): UseQueryResult<BuilderPageItemType<T>[]> => {
  const queryKey = ['questionnaires-event-pages-for-org-plan', id];
  return useQuery(queryKey, async () => (id ? fetchQuestionnairePages(id, token!) : undefined), {
    retry: 1,
    enabled: !!id && !!token
  });
};
