import format from 'date-fns/format';
import { OrganisationPlanTimePickerWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanTimePickerWithReplacementType';
import { DateSyntaxFormat } from 'modules/settings/constants';

interface IapplyFormatToDateTimePickerValueParam {
  isTwelveHoursFormat: boolean;
  dateFormat: DateSyntaxFormat['format'];
  type: OrganisationPlanTimePickerWithReplacementType['typeValue'];
  date: OrganisationPlanTimePickerWithReplacementType['date'];
}

/**
 * util function to get date / time / date-time string according to tennant settings.
 * @param param.type type of date value. can be date or time or date-time
 * @param param.date value from questionnaire
 * @param param.dateFormat format of date from settings
 * @param param.isTwelveHoursFormat boolean to know time format 24 / 12
 * @returns string
 */
export const applyFormatToDateTimePickerValue = ({
  type,
  date,
  dateFormat,
  isTwelveHoursFormat
}: IapplyFormatToDateTimePickerValueParam) => {
  if (!date) return 'No date provided';
  const timeFormat = isTwelveHoursFormat ? `h:mm aa` : `HH:mm`;
  if (type === 'date') {
    return dateFormat ? format(new Date(date), dateFormat) : 'No date provided';
  }
  if (type === 'time') {
    return format(new Date(date), timeFormat);
  }
  if (type === 'dateTime') {
    const dateTimeFormat = `${dateFormat} ${timeFormat}`;
    return format(new Date(date), dateTimeFormat);
  }
};
