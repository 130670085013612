import { ContentState, EditorState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { ArticlePriceType, IPrice } from 'modules/articles/types';
import { IOrganisationPlanChoiceOptionReplacementLabels } from 'modules/organisation-plan-templates/types/organisationPlanChoiceWidgetType';
import { OrganisationPlanInputNumberWithReplacementType } from 'modules/organisation-plan-templates/types/organisationPlanInputNumberWithReplacementType';

export const labelOrganisationPlan = (
  element: OrganisationPlanInputNumberWithReplacementType | IOrganisationPlanChoiceOptionReplacementLabels,
  priceWidget: IPrice | undefined
) => {
  if (!priceWidget && !!element?.countOfGuests) {
    return `: ${element.countOfGuests}`;
  }
  if (!priceWidget && !!(element as OrganisationPlanInputNumberWithReplacementType)?.value && !element.countOfGuests) {
    return `: ${(element as OrganisationPlanInputNumberWithReplacementType).value}`;
  }
  const unitText = priceWidget?.unit ? `(${priceWidget?.unit})` : '';
  let outText = '';
  const isPriceNumerically = priceWidget?.articlePriceType === ArticlePriceType.Numerically;
  const isPriceFlatRate = priceWidget?.articlePriceType === ArticlePriceType.FlatRate;
  const isErrorPriceNumericallyValue = isPriceNumerically && !element?.numericallyValue;

  const isErrorPriceFlatRate =
    !(element as OrganisationPlanInputNumberWithReplacementType)?.value && !element?.countOfGuests && isPriceFlatRate;

  switch (true) {
    case isErrorPriceNumericallyValue || isErrorPriceFlatRate || priceWidget === undefined:
      outText = '';
      break;

    case isPriceNumerically:
      const unitNumericallyText = `${element?.numericallyValue ?? ''} (${priceWidget?.unit && `${priceWidget?.unit}`})`;
      const secondText = `${element?.secondUnitValue ?? ''} (${priceWidget?.numericallyInfo?.secondUnit})`;
      if (!!priceWidget?.unit && !!element?.secondUnitValue) {
        outText = `${unitNumericallyText}; ${secondText}`;
        break;
      }
      outText = `${unitNumericallyText} `;
      break;
    case priceWidget?.articlePriceType === ArticlePriceType.FlatRate:
      const valueFlatRate = element.countOfGuests ?? (element as OrganisationPlanInputNumberWithReplacementType)?.value;
      outText = `${valueFlatRate ?? ''} ${unitText}`;
      break;

    case priceWidget?.articlePriceType === ArticlePriceType.onRequest:
      const valueOnRequest = (element as OrganisationPlanInputNumberWithReplacementType)?.value;
      outText = `${valueOnRequest ?? ''}`;
      break;

    default:
      outText = unitText;
  }
  return outText.length ? `: ${outText}` : '';
};

/**
 * Method to get text from html tags (DraftTextEditor)
 * @param label - label of widget
 */
export const extractContentFromHtml = (label: string) => {
  let regexForHTML = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
  const isHtmlTags = regexForHTML.test(label);
  if (!isHtmlTags) return label;
  const html = htmlToDraft(label);
  const editorState = EditorState.createWithContent(ContentState.createFromBlockArray(html.contentBlocks, html.entityMap));
  const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
  return blocks.map((block) => block.text).join(' ');
};
