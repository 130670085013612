import { QuestionnairePageType } from 'modules/questionnaires/types/questionnaries';
import { BuilderPageItemType } from 'modules/builder/types';
import { EVisibilityFields } from 'modules/builder/components/SettingsBar/VisibilityTab/types';
import { EQuestionnairesSource } from 'shared/types/questionnaire-template';
import { HIDE_ADD_NEW_PAGE_AMOUNT2 } from 'modules/builder/constants/builder';

/**
 * Util function to determine the type of the welcome page by QuestionnairePageType
 * @param questionnairePageType - type of page
 * @return string
 */
export const getWelcomePageType = (questionnairePageType?: QuestionnairePageType) => {
  const isWelcomePage = questionnairePageType?.toLowerCase()?.includes('welcomepage');
  if (isWelcomePage) {
    return questionnairePageType === QuestionnairePageType.WelcomePage ? 'event' : 'request';
  }
};

/**
 * Util function to find questionnaire page by page type
 * @param type - type of page
 * @param allPages - all pages of questionnaire
 * @return page with determine type or undefined
 */
export const findBuilderPageByType = <T = unknown>(type: QuestionnairePageType, allPages: BuilderPageItemType[]) => {
  return allPages.find((item) => item.type === type) as BuilderPageItemType<T> | undefined;
};

/**
 * Util function to filter pages by visibility field
 * @param pages all pages
 * @param field field in visibility object that should be used for filtering: `event` or `request`
 * @returns pages
 */
export const filterPagesByVisibility = <T = unknown>(
  pages: BuilderPageItemType<T>[],
  field: EVisibilityFields
): BuilderPageItemType<T>[] => {
  const data = pages
    .filter((page) => {
      return !!page?.visibility?.[field];
    })
    .map((page) => {
      if (page?.childPages?.length) {
        return {
          ...page,
          childPages: page.childPages.filter((subPage) => !!subPage?.visibility?.[field])
        };
      }
      return page;
    });

  return data;
};

/**
 * Util function to get key of visibility object by entity source
 * @param entitySource string, one of enum value
 * @returns `EVisibilityFields`
 */
export const getVisibilityType = (entitySource: EQuestionnairesSource): EVisibilityFields | undefined => {
  let res = undefined;
  switch (true) {
    case entitySource === EQuestionnairesSource.event:
      res = EVisibilityFields.event;
      break;
    case entitySource === EQuestionnairesSource.request:
      res = EVisibilityFields.request;
      break;
  }
  return res;
};

/**
 * util function to get minimum amount of page that need to create new page
 * @param entitySource - entity source of questionnaire
 * @param amountOfPages - lenght of pages array
 * @returns `number`
 */
export const shouldCreateNewPageBtnShow = (entitySource: EQuestionnairesSource, amountOfPages: number): boolean => {
  return amountOfPages > HIDE_ADD_NEW_PAGE_AMOUNT2[entitySource];
};
