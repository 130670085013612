import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { memo, useCallback, useMemo } from 'react';
import { alpha, Box, Button, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import {
  ArrowLeft20Regular,
  Link20Regular,
  Navigation24Filled,
  NotebookQuestionMark20Regular,
  Print20Regular,
  Save20Regular
} from '@fluentui/react-icons';
import { usePutOrganisationPlanPreviewNoteWidgets } from 'api/hooks/organisation-plan-preview/usePutOrganisationPlanPreviewNoteWidgets';
import { HidePrintElement } from 'shared/components/HidePrintElement';
import useCopyToClipboard from 'shared/hooks/useCopyToClipboard';
import { snackbarAlert, textError } from 'shared/utils/infoText';
import { useSelector } from 'store';
import { selectOrganizationPlanAllWidgets } from 'store/organisation-plan-template/organisation-plan-template.selectors';
import { ORGANISATION_PLAN_WIDGET_TYPES } from 'modules/organisation-plan-templates/types';
import { OrganisationPlanNotesWidgetType } from 'modules/organisation-plan-templates/types/organisationPlanNotesWidgetType';
import { AlertMessagesColor } from 'shared/types/alert-messages';
import { useGetOrganisationPlanTemplates } from 'api/hooks/organisation-plan-templates/useGetOrganisationPlanTemplates';
import { IOrganisationPlanPreview } from 'shared/types/organisation-plan-preview';
import { ORGANISATION_PLAN_PREVIEW_ROUTES } from 'modules/organisation-plan-preview/constants';
import { IOrganisationPlanTemplate } from 'shared/types/organisation-plan';
import ContextMenu, { IListOptionContextMenu } from 'shared/components/ContextMenu';
import { Roles } from 'modules/auth/types';
import { useGetCurrentUser } from 'api/hooks/user/useGetUser';
import { PageHeader } from './OrganisationPlanPreviewHeader.styles';
import { EVENTS_ROUTES } from 'modules/events/constants';
import { QUESTIONNAIRES_ROUTES } from 'modules/questionnaires/constants';

interface OrganisationPlanPreviewHeaderProps {
  token?: string;
  organisationPlanWidgetsData?: IOrganisationPlanPreview;
}

interface OrganisationPlanNotesWidgetPayload extends OrganisationPlanNotesWidgetType {
  id: number;
}

const OrganisationPlanPreviewHeader = ({ token, organisationPlanWidgetsData }: OrganisationPlanPreviewHeaderProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, copy] = useCopyToClipboard();
  const usedOrgPlansWidgets = useSelector(selectOrganizationPlanAllWidgets);
  const { data: user } = useGetCurrentUser();
  const isNotAnonym = !!user;
  const isEventManager = user?.role === 'event_manager';
  const borderColor = theme.palette.mode === 'dark' ? alpha(theme.palette.grey[900]!, 0.08) : alpha(theme.palette.grey[900]!, 0.1);

  const goBack = () => navigate(EVENTS_ROUTES.ROOT_LINK);

  const print = () => {
    const body = document.querySelector('body');
    const printContent = document.querySelector('#printable');
    if (body && printContent) {
      const originalContent = body.innerHTML;
      body.innerHTML = printContent.innerHTML;
      window.print();
      body.innerHTML = originalContent;
      window.location.reload();
    }
  };

  const copyToClipboard = () => copy(window.location.href);

  const goToQuestionnaires = useCallback(() => navigate(QUESTIONNAIRES_ROUTES.QUESTIONNAIRES), [navigate]);

  const { isLoading, mutateAsync } = usePutOrganisationPlanPreviewNoteWidgets({
    options: {
      onError(error: any) {
        let message = textError(error);
        navigate(QUESTIONNAIRES_ROUTES.QUESTIONNAIRES);
        dispatch(snackbarAlert(message, AlertMessagesColor.Error));
      }
    }
  });

  const onSuccessResult = () => {
    dispatch(snackbarAlert(intl.formatMessage({ id: 'organisation.preview-is-updated-successfully' }), AlertMessagesColor.Success));
  };

  const updateNotesHandler = useCallback(async () => {
    if (usedOrgPlansWidgets?.length) {
      const noteWidgets = (usedOrgPlansWidgets || []).filter(
        (item) => item.type === ORGANISATION_PLAN_WIDGET_TYPES.notes
      ) as OrganisationPlanNotesWidgetPayload[];
      if (token && noteWidgets && noteWidgets.length) {
        const notes = noteWidgets.map((note) => ({
          id: note.id,
          note: note.noteText
        }));
        await mutateAsync(
          { token, notes },
          {
            onSuccess: () => onSuccessResult()
          }
        );
      }
    }
  }, [mutateAsync, onSuccessResult, token, usedOrgPlansWidgets]);

  const questionnaireId = organisationPlanWidgetsData?.builder?.questionnaireId ?? null;
  const { isLoading: organisationPlanTemplatesLoading, data: allOrganisationPlanTemplates } = useGetOrganisationPlanTemplates(
    questionnaireId,
    'events'
  );

  const handleNavigation = useCallback(
    (urlAccessToken?: string) => {
      urlAccessToken && navigate(ORGANISATION_PLAN_PREVIEW_ROUTES.GET_LINK_BY_TOKEN(urlAccessToken));
    },
    [navigate]
  );

  const listOptions = useMemo<IListOptionContextMenu[] | undefined>(() => {
    const staticItem: IListOptionContextMenu = {
      onClick: goToQuestionnaires,
      messageId: 'organisation.go-to-questionnaire',
      roles: ['admin', 'tenant_admin', 'venue_manager'] as Roles[],
      icon: <NotebookQuestionMark20Regular />,
      isBorderBottom: true
    };

    const dynamicItems =
      allOrganisationPlanTemplates?.map((organisationPlanTemplate: IOrganisationPlanTemplate) => ({
        onClick: () => handleNavigation(organisationPlanTemplate.urlAccessToken),
        messageId: organisationPlanTemplate.name,
        roles: ['admin', 'tenant_admin', 'venue_manager', 'event_manager'] as Roles[],
        isShowMenu: !organisationPlanTemplatesLoading,
        icon: !isEventManager && <Box />
      })) || [];

    return [staticItem, ...dynamicItems];
  }, [allOrganisationPlanTemplates, goToQuestionnaires, handleNavigation, isEventManager, organisationPlanTemplatesLoading]);

  return (
    <HidePrintElement>
      <PageHeader>
        <Stack direction="row" spacing={1} alignItems="center">
          {isNotAnonym && (
            <>
              {listOptions && <ContextMenu size="medium" origin="bottom" icon={<Navigation24Filled />} listOptions={listOptions} />}
              <Box>
                <Button color={'secondary'} onClick={goBack} variant="outlined" size="medium" startIcon={<ArrowLeft20Regular />}>
                  <FormattedMessage id="settings.go-back" />
                </Button>
              </Box>
            </>
          )}
        </Stack>

        <Box sx={{ '.MuiIconButton-root': { '&:not(:last-child)': { mr: 1 } } }}>
          <Tooltip arrow title={intl.formatMessage({ id: 'organisation.print' })}>
            <IconButton sx={{ border: `1px solid ${borderColor}` }} color={'secondary'} onClick={print} size="medium">
              <Print20Regular />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title={intl.formatMessage({ id: 'organisation.copy-link' })}>
            <IconButton sx={{ border: `1px solid ${borderColor}` }} color={'secondary'} onClick={copyToClipboard} size="medium">
              <Link20Regular />
            </IconButton>
          </Tooltip>
          <Button
            startIcon={<Save20Regular />}
            color={'primary'}
            onClick={updateNotesHandler}
            variant="contained"
            size="medium"
            disabled={isLoading}
          >
            <FormattedMessage id="users.save" />
          </Button>
        </Box>
      </PageHeader>
      <Box sx={{ height: 30 }} />
    </HidePrintElement>
  );
};

export default memo(OrganisationPlanPreviewHeader);
