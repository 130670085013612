import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Theme } from '@mui/system';

interface IMainWrapper {
  isCustomize: boolean;
  theme: Theme;
}

export const MainWrapper = styled(Box)<IMainWrapper>(({ isCustomize, theme }) => ({
  flexGrow: 1,
  height: '100vh',
  backgroundColor: isCustomize ? theme.palette.primary.lighter : theme.palette.background.default
}));
