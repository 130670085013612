import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { IPaginatedResult } from 'shared/types/paginatedResult';
import { IPagination } from 'shared/types/table';
import { IUser, UserPostPayload } from 'shared/types/user';

type MutationContext =
  | {
      previousUsers: IPaginatedResult<IUser> | undefined;
      newUser: UserPostPayload;
    }
  | undefined;

const createNewUser = (userInfo: UserPostPayload): Promise<IUser> => {
  return axios.post(`users/`, userInfo).then((res) => res.data);
};

export function useCreateUser<TError>({
  options = {},
  onSuccess,
  pagination
}: {
  options?: Omit<UseMutationOptions<IUser, TError, UserPostPayload, MutationContext>, 'mutationFn' | 'onSuccess' | 'variables'>;
  onSuccess?: () => void;
  pagination: IPagination;
}) {
  const queryClient = useQueryClient();

  return useMutation(createNewUser, {
    ...options,
    // Update cache after success request
    onSuccess: (data) => {
      const previousUsers: IPaginatedResult<IUser> | undefined = queryClient.getQueryData(['users', pagination]);
      if (!previousUsers) {
        onSuccess && onSuccess();
        return;
      }
      const prev = previousUsers?.data ? previousUsers.data : [];
      queryClient.setQueryData(['users', pagination], {
        ...previousUsers,
        totalRecords: previousUsers.totalRecords + 1,
        data: [...prev, data]
      });

      if (onSuccess) {
        onSuccess();
      }
    },

    // Optimistic Update
    /*
    onMutate: async (newUser) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['users']);

      // Snapshot the previous value
      const previousUsers: IPaginatedResult<IUser> | undefined = queryClient.getQueryData(['users']);
      const prev = previousUsers?.data ? previousUsers.data : [];

      // Optimistically update to the new value
      queryClient.setQueryData(['users'], { ...previousUsers, data: [...prev, newUser] });

      // Return a context with the previous and new users
      return { previousUsers, newUser };
    },
    */

    // If the mutation fails, use the context we returned above
    // onError: (err, newUser, context: MutationContext) => {
    //   if (context) {
    //     queryClient.setQueryData(['users'], context?.previousUsers);
    //   }
    // },
    // Always refetch after error:
    onSettled: (_, error) => {
      if (error) {
        // do something
        queryClient.invalidateQueries(['users', pagination]);
      }
    }
  });
}
