import { EInvoiceType, InvoiceStatus } from 'shared/types/invoice';

export const INVOICE_STATE_CHIPS = {
  [InvoiceStatus.NewCreated]: {
    intlId: 'invoices.status-new-created',
    color: '#1890FF',
    bgColor: '#E6F7FF'
  },
  [InvoiceStatus.ReadyToSent]: {
    intlId: 'invoices.status-ready-to-sent',
    color: '#13C2C2',
    bgColor: '#E6FFFB'
  },
  [InvoiceStatus.Clarification]: {
    intlId: 'invoices.status-clarification',
    color: '#EB2F96',
    bgColor: '#FFF0F6'
  },
  [InvoiceStatus.SentPerEmail]: {
    intlId: 'invoices.status-sent-per-email',
    color: '#52C41A',
    bgColor: '#F6FFED'
  },
  [InvoiceStatus.SentPerMail]: {
    intlId: 'invoices.status-sent-per-mail',
    color: '#52C41A',
    bgColor: '#F6FFED'
  },
  [InvoiceStatus.OnHold]: {
    intlId: 'invoices.status-on-hold',
    color: '#FA8C16',
    bgColor: '#FFF7E6'
  },
  [InvoiceStatus.Canceled]: {
    intlId: 'invoices.status-canceled',
    color: '#F5222D',
    bgColor: '#FFF1F0'
  }
};

export const DISABLE_INVOICE_STATUS_AFTER_SENT_EMAIL = [
  InvoiceStatus.NewCreated,
  InvoiceStatus.ReadyToSent,
  InvoiceStatus.SentPerMail,
  InvoiceStatus.OnHold
];

export const INVOICE_TYPE = {
  [EInvoiceType.invoice]: { intlId: 'invoices.types-invoice' },
  [EInvoiceType.prepayment]: { intlId: 'invoices.types-prepayment' }
};
