import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import OrganisationPlanTemplateSectionsContainer from 'modules/organisation-plan-templates/containers/OrganisationPlanTemplateSectionsContainer';
import { getWidgetsAndFilteredSectionsOrgPlanTemplate } from 'modules/organisation-plan-templates/utils/getWidgetsAndFilteredSectionsOrgPlanTemplate';
import { preparedHeaderOptionsOrgPlanTemplate } from 'modules/organisation-plan-templates/utils/preparedHeaderOptionsOrgPlanTemplate';
import { useGetOrganizationPlanTemplateById } from 'api/hooks/organisation-plan-templates/useCreateOrganisationPlanTemplate';
import { useGetOrganizationPlanTemplateBuilderById } from 'api/hooks/organisation-plan-templates/useCreateOrganizationPlanTemplateBuilder';
import { IGuestAndRoomsPageFormDataDB } from 'modules/builder/types';
import OrganisationPlanPreviewMainInfoSection from 'modules/organisation-plan-preview/components/OrganisationPlanPreviewOrganisersAndGuestsSection';
import OrganisationPlanPreviewTemplateSectionsContainer from 'modules/organisation-plan-preview/components/OrganisationPlanPreviewTemplateSectionsContainer';
import { WidgetsContainer } from 'modules/organisation-plan-preview/components/OrganisationPlanPreviewWidgetSection/OrganisationPlanPreviewWidgetSection.styles';
import { organisersAndGuestsSectionPreparedData } from 'modules/organisation-plan-preview/utils/dataFormattersAndConstants';
import OrganisationPlanTemplateHeader from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateHeader';
import OrganisationPlanTemplateOptionsSection from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateOptionsSection';
import OrganisationPlanTemplateSidebar from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateSidebar';
import { QuestionnairePageType } from 'modules/questionnaires/types/questionnaries';
import Loader from 'shared/components/Loader';
import PageTitle from 'shared/components/PageTitle';
import { useDispatch, useSelector } from 'store';
import { selectGetBaseState, selectHeaderOptions } from 'store/organisation-plan-template/organisation-plan-template.selectors';
import { checkHasDataChangesOrgPlan } from 'modules/organisation-plan-templates/utils/state';
import PromptMessage from 'shared/components/PromptMessage';
import {
  setAllHeaderOptions,
  setOrganisationPlanStructure,
  setPrimaryState
} from 'store/organisation-plan-template/organisation-plan-template.slice';
import { useGetQuestionnairesPagesById } from 'api/hooks/questionnaires/useGetQuestionnairesPagesById';
import { EQuestionnairesSource } from 'shared/types/questionnaire-template';
import { findBuilderPageByType } from 'modules/builder/utils';

const OrganisationPlanTemplatePageById = () => {
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);
  const [isOpenModalWarning, setIsOpenModalWarning] = useState(false);
  const [isPaddingSection, setIsPaddingSection] = useState(false);

  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const { organisationPlanId, questionnaireTemplateId } = useParams();

  const headerOptions = useSelector(selectHeaderOptions);
  const primaryState = useSelector(selectGetBaseState);

  const { isLoading, data: allPages } = useGetQuestionnairesPagesById({
    id: questionnaireTemplateId ? Number(questionnaireTemplateId) : undefined,
    entitySource: EQuestionnairesSource.template
  });
  const { data: organizationPlanTemplateData, isLoading: isOrgPlanTemplateLoading } = useGetOrganizationPlanTemplateById(
    Number(organisationPlanId || 0),
    Number(questionnaireTemplateId || 0)
  );
  const { data: organizationPlanTemplateBuilderData, isLoading: isOrgPlanTemplateBuilderLoading } =
    useGetOrganizationPlanTemplateBuilderById(Number(organisationPlanId || 0), Number(questionnaireTemplateId || 0));

  const guestAndRoomsPage = findBuilderPageByType<IGuestAndRoomsPageFormDataDB>(QuestionnairePageType.GuestsAndRooms, allPages ?? []);

  useEffect(() => {
    const links = organizationPlanTemplateBuilderData?.links || [];
    if (!organizationPlanTemplateBuilderData) return;
    const headerOptions = preparedHeaderOptionsOrgPlanTemplate({
      guestAndRoomsPage,
      organizationPlanTemplateBuilderData,
      organizationPlanTemplateData,
      organizationPlanFormatMessage: intl.formatMessage({ id: 'events.organisation-plan' })
    });

    dispatch(setAllHeaderOptions(headerOptions));

    const { widgets, filteredSections } = getWidgetsAndFilteredSectionsOrgPlanTemplate({ organizationPlanTemplateBuilderData });

    dispatch(setOrganisationPlanStructure({ links, sections: filteredSections, usedWidgets: widgets }));
    dispatch(setPrimaryState({ links, sections: filteredSections, usedWidgets: widgets, headerOptions }));
  }, [organizationPlanTemplateBuilderData, organizationPlanTemplateData, guestAndRoomsPage, dispatch, intl]);

  const headerData = organisersAndGuestsSectionPreparedData(headerOptions);

  const isChangeData = checkHasDataChangesOrgPlan(primaryState);

  if (isLoading || isOrgPlanTemplateLoading || isOrgPlanTemplateBuilderLoading) {
    return <Loader />;
  }

  const handlePreviewMode = () => setIsPreviewMode(!isPreviewMode);

  const handleClose = () => {
    if (isChangeData && !isOpenModalWarning) {
      return setIsOpenModalWarning(true);
    }
    navigate(-1);
  };

  const handleHideWarning = () => {
    setIsOpenModalWarning(false);
  };

  const handleIsPagePadding = (isPadding: boolean) => {
    setIsPaddingSection(isPadding);
  };

  return (
    <>
      <PageTitle title={headerOptions?.name} />
      <OrganisationPlanTemplateHeader isPreviewMode={isPreviewMode} handlePreviewMode={handlePreviewMode} handleClose={handleClose} />
      {isPreviewMode ? (
        // TODO: this part can be separate component
        <Stack maxWidth="991px" m="15px auto" gap="10px">
          {!!headerData.length && (
            <OrganisationPlanPreviewMainInfoSection title={intl.formatMessage({ id: 'events.organisation-plan' })} items={headerData} />
          )}
          <WidgetsContainer>
            <OrganisationPlanPreviewTemplateSectionsContainer />
          </WidgetsContainer>
        </Stack>
      ) : (
        <Stack width="100%" m="10px 0" direction="row" spacing={2} justifyContent="center">
          <Stack minWidth="991px">
            <OrganisationPlanTemplateOptionsSection />
            <OrganisationPlanTemplateSectionsContainer isPaddingSection={isPaddingSection} />
          </Stack>

          <OrganisationPlanTemplateSidebar onIsPaddingSection={handleIsPagePadding} />
        </Stack>
      )}

      {!isPreviewMode && (
        <PromptMessage
          isDirtyForm={isChangeData}
          handleConfirm={handleClose}
          isOpenModalWarning={isOpenModalWarning}
          handleCloseModal={handleHideWarning}
        />
      )}
    </>
  );
};

export default OrganisationPlanTemplatePageById;
