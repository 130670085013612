import { IEmailTemplateVariable } from 'api/hooks/email-templates/useGetEmailTemplates';

interface IIEmailTemplatesItem {
  subject: string;
  template: string;
  variables: IEmailTemplateVariable[];
}

export const EMAIL_TEMPLATE_GENERAL = ['forgot-password', 'new-user'];
export const EMAIL_TEMPLATE_INVOICE = ['invoice', 'prepayment'];
export const EMAIL_TEMPLATE_OFFER = ['request', 'offer'];

export const EMAIL_VARIABLES_TRANSLATION_BY_CODE = {
  '[[name]]': 'settings.email-templates-variable-name',
  '[[first_name]]': 'settings.email-templates-variable-first-name',
  '[[last_name]]': 'settings.email-templates-variable-last-name',
  '[[partner_name]]': 'settings.email-templates-variable-partner-name',
  '[[partner_first_name]]': 'settings.email-templates-variable-partner-first-name',
  '[[partner_last_name]]': 'settings.email-templates-variable-partner-last-name',
  '[[go_to_login]]': 'settings.email-templates-variable-go-to-login',
  '[[email]]': 'settings.email-templates-variable-email',
  '[[phone_number]]': 'settings.email-templates-variable-phone-number',
  '[[performance_date]]': 'settings.email-templates-variable-performance-date',
  '[[invoice_number]]': 'settings.email-templates-variable-invoice-number',
  '[[invoice_date]]': 'settings.email-templates-variable-invoice-date',
  '[[go_to_request]]': 'settings.email-templates-variable-go-to-request',
  '[[requested_date]]': 'settings.email-templates-variable-requested-date',
  '[[valid_until_date]]': 'settings.email-templates-variable-valid-until-date',
  '[[offer_number]]': 'settings.email-templates-variable-valid-offer-number',
  '[[offer_date]]': 'settings.email-templates-variable-valid-offer-date',
  '[[confirm_account]]': 'settings.email-templates-variable-valid-confirm-account'
};

/**
 * Util function to get email variable description translation by source (invoice | offer | request ...etc)
 * in case if one code variable should be different according to source
 * for example: invoice `[[name]] = 'invoice name'` and offer `[[name]] = 'offer name'`
 * @param type name of page (source)
 * @returns object with `key: translation_string`
 */
export const getEmailTemplateVariable = (type?: string): { [key: string]: string } => {
  if (type === 'invoice') {
    return {
      ...EMAIL_VARIABLES_TRANSLATION_BY_CODE,
      '[[invoice_number]]': 'settings.email-templates-variable-invoice-number',
      '[[invoice_date]]': 'settings.email-templates-variable-invoice-date'
    };
  }
  return EMAIL_VARIABLES_TRANSLATION_BY_CODE;
};

const EMAIL_INITIAL_VARIABLES: IEmailTemplateVariable[] = [
  {
    name: 'name',
    intlId: EMAIL_VARIABLES_TRANSLATION_BY_CODE['[[name]]'],
    code: '[[name]]',
    description: 'Name of user',
    isRequired: false
  },
  {
    name: 'last_name',
    intlId: EMAIL_VARIABLES_TRANSLATION_BY_CODE['[[last_name]]'],
    code: '[[last_name]]',
    description: 'Lastname of user',
    isRequired: false
  },
  {
    name: 'partner_name',
    intlId: EMAIL_VARIABLES_TRANSLATION_BY_CODE['[[partner_name]]'],
    code: '[[partner_name]]',
    description: 'Partner name of customer',
    isRequired: false
  },
  {
    name: 'partner_last_name',
    intlId: EMAIL_VARIABLES_TRANSLATION_BY_CODE['[[partner_last_name]]'],
    code: '[[partner_last_name]]',
    description: 'Partner lastname of customer',
    isRequired: false
  },
  {
    name: 'go_to_login',
    intlId: EMAIL_VARIABLES_TRANSLATION_BY_CODE['[[go_to_login]]'],
    code: '[[go_to_login]]',
    description: 'link to login page',
    isRequired: false
  },
  {
    name: 'email',
    intlId: EMAIL_VARIABLES_TRANSLATION_BY_CODE['[[email]]'],
    code: '[[email]]',
    description: 'Email',
    isRequired: false
  },
  {
    name: 'phone_number',
    intlId: EMAIL_VARIABLES_TRANSLATION_BY_CODE['[[phone_number]]'],
    code: '[[phone_number]]',
    description: 'phone number of user',
    isRequired: true
  },
  {
    name: 'invoice_number',
    intlId: EMAIL_VARIABLES_TRANSLATION_BY_CODE['[[invoice_number]]'],
    code: '[[invoice_number]]',
    description: 'invoice number',
    isRequired: false
  },
  {
    name: 'performance_date',
    intlId: EMAIL_VARIABLES_TRANSLATION_BY_CODE['[[performance_date]]'],
    code: '[[performance_date]]',
    description: 'Performance date',
    isRequired: false
  },
  {
    name: 'invoice_date',
    intlId: EMAIL_VARIABLES_TRANSLATION_BY_CODE['[[invoice_date]]'],
    code: '[[invoice_date]]',
    description: 'invoice date',
    isRequired: false
  }
];

export const EMAIL_TEMPLATES: { [key: string]: IIEmailTemplatesItem } = {
  invoice: {
    subject: 'Invoice [[invoice_number]]',
    template:
      '<p>Hello dear [[name]] [[last_name]],</p>\n' +
      '<p>Please find attached the invoice for your event on [[performance_date]].</p>\n' +
      '<p><br>If you want to check your booking again you can still log in to your account:&nbsp;</p>\n' +
      '<p><br>[[go_to_login]]</p>\n' +
      '<p></p>\n' +
      '<p>We thank you for your trust and wish you all the best.</p>\n' +
      '<p><br><br>With kind regards</p>',
    variables: EMAIL_INITIAL_VARIABLES
  },
  prepayment: {
    subject: 'Prepayment [[invoice_number]]',
    template:
      '<p>Hello dear [[name]] [[last_name]],</p>\n' +
      '<p>Please find attached the invoice for your event on [[performance_date]].</p>\n' +
      '<p><br>If you want to check your booking again you can still log in to your account:&nbsp;</p>\n' +
      '<p><br>[[go_to_login]]</p>\n' +
      '<p></p>\n' +
      '<p>We thank you for your trust and wish you all the best.</p>\n' +
      '<p><br><br>With kind regards&nbsp;</p>',
    variables: EMAIL_INITIAL_VARIABLES
  },
  request: {
    subject: 'Request at [[venue_name]]',
    template:
      '<p>Hello dear [[name]] [[last_name]],</p>\n' +
      '<p>Below you will find your individual link for your requested event at [[venue_name]]:</p>' +
      '<p><br/>[[go_to_request]]</p>' +
      '<p></p>' +
      '<p>We thank you for your trust and wish you all the best.</p>' +
      '<p><br><br>With kind regards&nbsp;</p>',
    variables: EMAIL_INITIAL_VARIABLES
  },
  offer: {
    subject: 'Offer [[offer_number]]',
    template:
      '<p>Hello dear [[name]] [[last_name]],</p>\n' +
      '<p>Please find attached the offer for your requested event on [[requested_date]].</p>\n' +
      '<p>If you want to adjust your requested services  you can log in to your account:</p>' +
      '<p><br/>[[confirm_account]]  [[go_to_login]]</p>' +
      '<p></p>' +
      '<p>We thank you for your trust and wish you all the best.</p>' +
      '<p><br><br>With kind regards&nbsp;</p>',
    variables: EMAIL_INITIAL_VARIABLES
  },
  default: {
    subject: '',
    template:
      '<p>Hello dear [[name]] [[last_name]],</p>\n' +
      '<p></p>\n' +
      '<p>We thank you for your trust and wish you all the best.</p>\n' +
      '<p><br><br>With kind regards</p>',
    variables: [
      {
        name: 'name',
        code: '[[name]]',
        description: 'Name of user',
        isRequired: false
      },
      {
        name: 'last_name',
        code: '[[last_name]]',
        description: 'Lastname of user',
        isRequired: false
      }
    ]
  }
};
