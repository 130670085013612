import { QuestionnaireTaxType } from 'modules/questionnaires/types';

export const questionnaireEvent: Array<{ id: number; value: string; formatId: string }> = [
  { id: 1, value: 'Wedding', formatId: 'questionnaires.wedding' },
  { id: 2, value: 'Meeting', formatId: 'questionnaires.meeting' },
  { id: 3, value: 'Corporate', formatId: 'questionnaires.corporate' },
  { id: 4, value: 'Private', formatId: 'questionnaires.private' },
  { id: 5, value: 'Other', formatId: 'questionnaires.other' }
];

export const questionnaireTaxtype: Array<{ id: number; value: string; formatId: string }> = [
  { id: 1, value: QuestionnaireTaxType.Net, formatId: 'articles.netto' },
  { id: 2, value: QuestionnaireTaxType.Gross, formatId: 'articles.brutto' }
];

export const articleMasterType: Array<{ id: string; value: string }> = [
  { id: 'InHouseService', value: 'In-house service' },
  { id: 'PartnerService', value: 'Partner service' },
  { id: 'Product', value: 'Product' }
];
