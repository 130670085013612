import { Box, Button, Menu, OutlinedInput, TextField, alpha, styled } from '@mui/material';

interface IFilterProps {
  isPadding?: boolean;
}

export const FilterButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '6px',
  padding: '5px',
  minWidth: '36px',
  minHeight: '36px',

  whiteSpace: 'nowrap',

  '&:hover': {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.primary.main}`
  },
  color: theme.palette.text.primary,
  fontSize: '0.81rem',
  '& svg': {
    color: theme.palette.primary.main
  }
}));

export const FilterInputButton = styled(OutlinedInput)(() => ({
  '& .MuiOutlinedInput-root': {
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent'
      }
    },
    '&.Mui-focused': {
      border: 'none',
      boxShadow: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent'
      }
    },
    '&.Mui-active': {
      border: 'none',
      boxShadow: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent'
      }
    }
  },
  '& .MuiOutlinedInput-input': {
    padding: 0,
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
      boxShadow: 'none'
    },
    '&:focus': {
      border: 'none',
      boxShadow: 'none'
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    boxShadow: 'none'
  }
}));

export const FilterMenu = styled(Menu)<IFilterProps>(({ isPadding }) => ({
  height: 'auto',
  maxHeight: '100%',
  '& .MuiMenuItem-root': {
    padding: isPadding ? '8px 5px 8px 15px !important' : '0px !important',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }
  },
  '& .rs-picker-toggle-textbox': {
    border: 'none !important',
    boxShadow: 'none !important',
    backgroundColor: 'transparent !important'
  },
  '& .rs-picker-toggle-wrapper': {
    minWidth: '100%'
  }
}));

export const DateRangeWrapper = styled(Box)(() => ({
  width: '250px',
  '& .MuiOutlinedInput-root': { boxShadow: 'none !important', border: 'none !important' }
}));

export const ResetButtonBox = styled(Box)(({ theme }) => ({
  textAlign: 'left',
  padding: '8px 15px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1)
  }
}));

export const SearchTextField = styled(TextField)(() => ({
  border: 'none !important',
  '& fieldset': { border: 'none', boxShadow: 'none' },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none', boxShadow: 'none' },
  '& .Mui-focused': { border: 'none !important', boxShadow: 'none !important' }
}));
