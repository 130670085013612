import { Button, Collapse } from '@mui/material';
import OrganisationPlanPreviewTemplatePlaygroundSection from 'modules/organisation-plan-preview/components/OrganisationPlanPreviewTemplatePlaygroundSection';
import * as Styles from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateSection/OrganisationPlanTemplateSection.styles';
import { IOrganisationPlanSection } from 'modules/organisation-plan-templates/types/organisationPlanSection';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface IOrganisationPlanTemplateSection {
  section: IOrganisationPlanSection;
  sectionIndex: number;
}

const OrganisationPlanPreviewTemplateSection = ({ section, sectionIndex }: IOrganisationPlanTemplateSection) => {
  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Styles.PreviewSectionWrapper key={sectionIndex}>
      <Styles.PreviewSectionTitle isExpanded={expanded}>
        <h2 style={{ margin: 0 }}>{section.name}</h2>
        <Styles.HeaderActionsContainer>
          <Button onClick={handleExpandClick} size="small">
            {expanded ? (
              <FormattedMessage id="organisation-plan.collapse-section-btn" />
            ) : (
              <FormattedMessage id="organisation-plan.uncollapse-section-btn" />
            )}
          </Button>
        </Styles.HeaderActionsContainer>
      </Styles.PreviewSectionTitle>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Styles.SectionContent isTemplate={false}>
          <OrganisationPlanPreviewTemplatePlaygroundSection layout={section.layout} sectionId={section.sectionId} />
        </Styles.SectionContent>
      </Collapse>
    </Styles.PreviewSectionWrapper>
  );
};

export default OrganisationPlanPreviewTemplateSection;
