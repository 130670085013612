import { Stack } from '@mui/material';
import TimeRangeFilter from 'modules/dashboard/components/widgets/TableDashboardWidget/components/Filters/TImeRangeFilter';
import VenuesFilter from 'modules/dashboard/components/widgets/TableDashboardWidget/components/Filters/VenuesFilter';
import EventTypesFilter from 'modules/dashboard/components/widgets/TableDashboardWidget/components/Filters/EventTypesFilter';
import EventManagersFilter from 'modules/dashboard/components/widgets/TableDashboardWidget/components/Filters/EventManagersFilter';
import QuestionnairesFilter from 'modules/dashboard/components/widgets/TableDashboardWidget/components/Filters/QuestionnairesFilter';
import InvoiceStatesFilter from 'modules/dashboard/components/widgets/TableDashboardWidget/components/Filters/InvoiceStatesFilter';
import { useSelector } from 'store';
import { selectIsDashboardCustomize } from 'store/dashboard/dasboard.selectors';
import { WidgetsDashboardType } from 'modules/dashboard/types';
import { IUpdateWidgetStore } from 'modules/dashboard/components/widgets/TableDashboardWidget/components/UpperHeaderTable';
// import EventStatesFilter from 'modules/dashboard/components/widgets/TableDashboardWidget/components/Filters/EventStatesFilter';

interface IDashboardFilters {
  widget: WidgetsDashboardType | undefined;
  updateWidgetFilters: ({ field, value }: IUpdateWidgetStore) => void;
}
const DashboardFilters = (props: IDashboardFilters) => {
  const isCustomize = useSelector(selectIsDashboardCustomize);

  return (
    <Stack direction={'row'} spacing={1} justifyItems={'center'} justifyContent={'center'}>
      <TimeRangeFilter isCustomize={isCustomize} {...props} />
      <VenuesFilter isCustomize={isCustomize} {...props} />
      {/* Uncommend if Backend is ready to filter event states */}
      {/* <EventStatesFilter isCustomize={isCustomize} {...props} /> */}
      <EventTypesFilter isCustomize={isCustomize} {...props} />
      <EventManagersFilter isCustomize={isCustomize} {...props} />
      <QuestionnairesFilter isCustomize={isCustomize} {...props} />
      <InvoiceStatesFilter isCustomize={isCustomize} {...props} />
    </Stack>
  );
};

export default DashboardFilters;
