import { FormattedMessage, useIntl } from 'react-intl';
import { useCallback, useState } from 'react';
import { FormHelperText, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { IInvoiceDialogForm } from 'shared/types/invoice';
import { questionnaireTaxtype } from 'shared/data/questionnaires';
import { useGetAllInvoiceTemplateInfinite } from 'api/hooks/invoicesTemplate/useInvoiceTemplate';
import { useAuth } from 'shared/contexts/AuthContext';
import { useGetDataByRole } from 'shared/hooks/useGetDataByRole';
import AutocompleteInfiniteSelect from 'shared/components/AutocompleteInfiniteSelect';

const InvoiceModalTemplatesAndValue = () => {
  const [searchValue, setSearchValue] = useState('');

  const intl = useIntl();
  const { tokenInfo } = useAuth();

  const { values, errors, touched, setFieldValue, handleChange } = useFormikContext<IInvoiceDialogForm>();

  const {
    data: templates = [],
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useGetAllInvoiceTemplateInfinite({ id: tokenInfo?.tenant, searchValue });
  const { disableForEventManager } = useGetDataByRole();
  const disableParamForEventManagerInOldInvoice = disableForEventManager && !!values?.id;

  const handleChangeTemplate = (selectedObject: IInvoiceDialogForm['invoiceTemplate'] | null) => {
    if (!selectedObject) return;
    setFieldValue('invoiceTemplate', selectedObject);
  };

  const updateSearch = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25}>
          <InputLabel htmlFor="Questionnaire">
            <FormattedMessage id="invoices.modal-field-template" />
          </InputLabel>
          <AutocompleteInfiniteSelect
            disabled={disableParamForEventManagerInOldInvoice}
            options={templates}
            loading={!templates && isFetchingNextPage}
            handleChange={handleChangeTemplate}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={values.invoiceTemplate}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            updateSearch={updateSearch}
            placeholder={intl.formatMessage({ id: 'events.select-here' })}
          />
          {Boolean(touched['invoiceTemplate'] && errors['invoiceTemplate']) && (
            <FormHelperText error={Boolean(touched['invoiceTemplate'] && errors['invoiceTemplate'])}>
              {errors['invoiceTemplate']}
            </FormHelperText>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25}>
          <InputLabel htmlFor="Questionnaire">
            <FormattedMessage id="invoices.modal-field-value" />
          </InputLabel>
          <Select
            disabled={disableForEventManager}
            value={values.valueType ?? null}
            name="valueType"
            onChange={handleChange}
            placeholder={intl.formatMessage({ id: 'events.select-here' })}
            inputProps={{ placeholder: intl.formatMessage({ id: 'events.select-here' }) }}
            defaultValue={questionnaireTaxtype[0].value}
          >
            {questionnaireTaxtype.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                <FormattedMessage id={item.formatId} />
              </MenuItem>
            ))}
          </Select>
          {Boolean(touched['valueType'] && errors['valueType']) && (
            <FormHelperText error={Boolean(touched['valueType'] && errors['valueType'])}>{errors['valueType']}</FormHelperText>
          )}
        </Stack>
      </Grid>
    </>
  );
};

export default InvoiceModalTemplatesAndValue;
