import { IInvoiceEditor } from 'shared/types/invoice';
import { IUser } from 'shared/types/user';
import { QuestionnaireTaxType } from 'modules/questionnaires/types';
import { IVenueRoom } from 'shared/types/venue';
import { IContactPeople } from 'shared/types/event';
import { ISettingsTemplateAddress } from 'shared/types/settingsTemplate';
import { IOfferEditor } from 'shared/types/offer';

export type EditorType = 'invoices' | 'offers';

export enum EEditorType {
  Invoices = 'invoices',
  Offers = 'offers'
}

export type ValuesEditorType = IInvoiceEditor | IOfferEditor;

export enum EEditorArticleItemType {
  Custom = 'Custom',
  Base = 'Base',
  RangePrice = 'RangePrice',
  SecondUnit = 'SecondUnit',
  OnRequest = 'OnRequest',
  FlatRateTotal = 'FlatRateTotal',
  FlatRatePerGuest = 'FlatRatePerGuest',
  NumericalAll = 'NumericalAll',
  NumericalEachFurther = 'NumericalEachFurther'
}

export interface IEditorArticleItem {
  invoiceItemId?: number;
  id?: number;
  type: EEditorArticleItemType; //secondUnit
  priceNetto?: number;
  priceBrutto?: number;
  unit: string;
  count: number;
}

export interface IEditorArticle<T = unknown> {
  id?: number;
  _innerId?: string; // need for new created article just from table
  parentInvoiceItemId?: number;
  type: T;
  syncKey?: string;
  order: number;
  name: string;
  tax: number;
  totalPrice?: number;
  totalNet: number;
  totalGross: number;
  priceItems: IEditorArticleItem[];
}

/**
 * The main type of Editor for pages InvoiceEditor or OfferEditor
 * @param S - state (example: InvoiceStatus)
 * @param E - IEventDB or IRequestDB
 * @param T - type of Invoice / Offer
 * @param ST - ISettingsTemplate of Invoice | Offer
 * @param AT - ArticleType of Invoice | Offer
 */
export interface IEditorForForm<S, E, T, ST, AT = unknown> {
  id?: number;
  number?: string;
  recipient?: IUser | IContactPeople;
  name?: string;
  type: T;
  taxType: QuestionnaireTaxType;
  state: S;
  baseDates: { [key: string]: string | Date | undefined };
  venue?: IVenueRoom;
  settingsTemplate?: ST;
  data: E; // event: IEventInfo;
  finalAmount: number;
  tenantId?: number;
  isSentEmail?: boolean;
  articles: IEditorArticle<AT>[] | [];
  senderAddress?: ISettingsTemplateAddress;
  legalTax: number;
  discount?: number;
}
