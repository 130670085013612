import { Layout } from 'react-grid-layout';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Column, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { InfiniteQueryObserverBaseResult } from 'react-query/types/core/types';
import { CircularProgress, Table, TableBody, TableCell, Typography } from '@mui/material';
import { IColumnForUseTable, TableDashboardWidgetType } from 'modules/dashboard/types/TableDashboardWidgetType';
import { IAppearanceSettings } from 'modules/questionnaires/types';
import { HeaderSort } from 'shared/components/HeaderSort';
import { ITableDataParams } from 'api/hooks/dashboard/useGetDashboardTable';
import { ETagData } from 'modules/dashboard/types';
import { DATA_SYNTAX } from 'modules/settings/constants';
import useUpdateLayoutDashboardWidget from 'modules/dashboard/hooks/useUpdateLayoutWidget';
import { useCurrentCurrencyFormat, useCurrentDateFormat } from 'shared/hooks/regionalSettings';
import useLastOptionElementRef from 'shared/hooks/useLastOptionElementRef';
import { IPagination } from 'shared/types/table';
import UpperHeaderTable from './components/UpperHeaderTable';
import { transformResponse } from './mockData';
import HeaderTableActions from './components/HeaderTableActions';
import * as Styles from './TableDashboardWidget.styles';

interface ITableDashboardWidgetProps {
  widget?: TableDashboardWidgetType;
  layout?: Layout;
  appearanceSettings?: IAppearanceSettings;
  isHideHeaderTable?: boolean;
  isFetching: boolean;
  fetchNextPage: InfiniteQueryObserverBaseResult['fetchNextPage'];
  hasNextPage: boolean | undefined;
  tableData: ITableDataParams | undefined;
  newRows: any;
  isCustomize: boolean;
  isGroupWidget: boolean;
  updatePaginationAndFiltering?: (data: IPagination) => void;
}

const TableDashboardWidget: React.FC<ITableDashboardWidgetProps> = ({
  isFetching,
  fetchNextPage,
  newRows,
  hasNextPage,
  tableData,
  widget,
  isHideHeaderTable,
  appearanceSettings,
  isCustomize,
  updatePaginationAndFiltering,
  isGroupWidget,
  layout
}) => {
  const intl = useIntl();

  const { lastOptionElementRef } = useLastOptionElementRef({ fetchNextPage: fetchNextPage, hasNextPage: hasNextPage });
  const { token, id } = useParams();
  const [columnsInfo, setColumnsInfo] = useState<Column<any>[]>([]);
  const [dataInfo, setDataInfo] = useState<IColumnForUseTable[]>([]);
  const [isShowLoader, setShowLoader] = useState(false);

  const currencySymbol = useCurrentCurrencyFormat();
  const currentDateFormat = useCurrentDateFormat();
  const settingsGeneralDefaultLanguage = intl.locale;

  const ref = useUpdateLayoutDashboardWidget({
    widgetSystemId: widget?.systemId ?? '',
    layout: layout,
    dependencies: !!newRows && newRows?.length < 10 ? [newRows?.length] : []
  });

  const tagsTranslations = useMemo(
    () => [
      { type: ETagData.Revenue, intlId: intl.formatMessage({ id: 'dashboard.revenue' }) },
      { type: ETagData.NumberBookings, intlId: intl.formatMessage({ id: 'dashboard-table.bookings' }) },
      { type: ETagData.NumberUnitsSold, intlId: intl.formatMessage({ id: 'dashboard-table.units-sold' }) }
    ],
    [intl]
  );
  const isShareTable = !!token?.length;

  const dateFormat = useMemo(() => currentDateFormat?.format ?? DATA_SYNTAX[0].value, [currentDateFormat]);

  useEffect(() => {
    if (tableData && newRows) {
      const { columns, data } = transformResponse({
        tableData,
        newRows,
        tagsTranslations,
        widget,
        currency: currencySymbol,
        dateFormat,
        settingsLanguage: settingsGeneralDefaultLanguage
      });

      setColumnsInfo(columns);
      setDataInfo(data);
    }
  }, [tableData, newRows, tagsTranslations, widget, currencySymbol, dateFormat, settingsGeneralDefaultLanguage]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    rows,
    state: { pageIndex, pageSize, globalFilter, sortBy }
  } = useTable(
    {
      columns: useMemo(() => columnsInfo, [columnsInfo]),
      data: useMemo(() => dataInfo, [dataInfo]),
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      manualGlobalFilter: true,
      disableMultiSort: true,
      selectRow: false
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (!updatePaginationAndFiltering) return;

    const sorting = sortBy.length
      ? {
          orderField: sortBy[0].id,
          orderType: sortBy[0].desc ? ('desc' as const) : ('asc' as const)
        }
      : {};

    updatePaginationAndFiltering({
      pageNumber: pageIndex + 1,
      pageSize,
      search: globalFilter,
      ...sorting
    });
  }, [updatePaginationAndFiltering, pageIndex, pageSize, sortBy, globalFilter]);

  useEffect(() => {
    setShowLoader(isFetching && !isCustomize);
  }, [isFetching, isCustomize]);

  return (
    <Styles.TableWidgetWrapper isShareTable={isHideHeaderTable} isCustomize={isCustomize} ref={ref}>
      <Styles.TableCard content={false}>
        <Styles.TableUpperHeaderWrapper>
          {isHideHeaderTable ? (
            <>
              <Typography variant="h4" pl="20px">
                {widget?.data?.name ?? intl.formatMessage({ id: 'dashboard.custom-table' })}
              </Typography>
              <HeaderTableActions
                isGroupWidget={isGroupWidget}
                widgetId={id}
                isHideHeaderTable={isHideHeaderTable}
                widget={widget}
                isCustomize={isCustomize}
              />
            </>
          ) : (
            <UpperHeaderTable widget={widget} isCustomize={isCustomize} />
          )}
        </Styles.TableUpperHeaderWrapper>
      </Styles.TableCard>
      <Styles.TableContainerWrapper isShareTable={isShareTable} appearanceSettings={appearanceSettings}>
        {isShowLoader && (
          <Styles.LoaderOverlay>
            <CircularProgress size={80} />
          </Styles.LoaderOverlay>
        )}
        <Table sx={{ width: '100%' }} aria-label="sticky table" {...getTableProps()}>
          <Styles.TableHeadWrapperBox>
            {headerGroups?.map((headerGroup, index) => (
              <Styles.TableHeaderRow {...headerGroup.getHeaderGroupProps()} key={`header-row-${index}`}>
                {headerGroup.headers.map((column: any) => (
                  <Styles.TableHeaderCell
                    {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                    key={`header-cell-${column.id}`}
                    isFirstColumn={!index}
                  >
                    <HeaderSort disableMaxWidth column={{ ...column, intlId: column.intlId }} />
                  </Styles.TableHeaderCell>
                ))}
              </Styles.TableHeaderRow>
            ))}
          </Styles.TableHeadWrapperBox>
          <TableBody {...getTableBodyProps()}>
            {rows?.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={`body-fragment-${row.id}`}>
                  <Styles.TableBodyRow
                    {...row.getRowProps()}
                    ref={rows[rows.length - 1].id === row.id && !isFetching ? lastOptionElementRef : null}
                    key={`body-row-${row.id}`}
                  >
                    {row.cells.map((cell, index) => (
                      <TableCell
                        {...cell.getCellProps()}
                        key={`body-cell-${cell.column.id}`}
                        sx={{ borderRight: !index ? 'none' : 'none', textAlign: !index ? 'start' : 'end' }}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </Styles.TableBodyRow>
                </Fragment>
              );
            })}
          </TableBody>

          {widget?.data.withFooters && (
            <Styles.Footer>
              {footerGroups?.map((footerGroup, index) => (
                <Styles.TableFooterRow {...footerGroup.getFooterGroupProps()} key={`footer-row-${index}`}>
                  {footerGroup.headers.map((column) => (
                    <TableCell {...column.getFooterProps()} key={`footer-cell-${column.id}`}>
                      {column.render('Footer')}
                    </TableCell>
                  ))}
                </Styles.TableFooterRow>
              ))}
            </Styles.Footer>
          )}
        </Table>
      </Styles.TableContainerWrapper>
    </Styles.TableWidgetWrapper>
  );
};

export default TableDashboardWidget;
