import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormHelperText, InputLabel, Stack } from '@mui/material';
import { useGetVenuesInfinity } from 'modules/events/hooks/useGetVenueInf';
import AutocompleteInfiniteSelect from 'shared/components/AutocompleteInfiniteSelect';
import { IVenue } from 'shared/types/venue';

interface ISelectVenue {
  value?: IVenue | null;
  onChange: (selectedVenues: IVenue | null) => void;
  isError?: boolean;
  isDisabled?: boolean;
}
const SelectVenue = ({ value, onChange, isError, isDisabled }: ISelectVenue) => {
  const [searchValue, setSearchValue] = useState('');

  const intl = useIntl();
  const {
    data: dataVenues,
    fetchNextPage: venuesNextPage,
    hasNextPage: venuesHasNextPage,
    isFetchingNextPage: venuesIsFetchingNextPage
  } = useGetVenuesInfinity({ isActive: true, search: searchValue === '' ? undefined : searchValue });

  const updateSearch = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  return (
    <Stack spacing={1.25}>
      <InputLabel htmlFor="venue">
        <FormattedMessage id="settings.venue" />
      </InputLabel>
      <AutocompleteInfiniteSelect
        disabled={!!isDisabled}
        options={dataVenues}
        hasNextPage={venuesHasNextPage}
        handleChange={onChange}
        loading={venuesIsFetchingNextPage}
        values={value}
        fetchNextPage={venuesNextPage}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        placeholder={intl.formatMessage({ id: 'events.select-here' })}
        updateSearch={updateSearch}
      />
      {!!isError && (
        <FormHelperText error={isError}>
          <FormattedMessage id="settings.venue" /> <FormattedMessage id="events.is-required" />
        </FormHelperText>
      )}
    </Stack>
  );
};

export default SelectVenue;
