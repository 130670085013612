import { Alert20Regular, Dismiss20Regular } from '@fluentui/react-icons';
import { alpha, Box, Button, Divider, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { useRegisterSW } from 'virtual:pwa-register/react';

const ReloadPrompt = () => {
  const theme = useTheme();
  const intl = useIntl();

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker
  } = useRegisterSW({
    onRegistered(registration) {
      console.log('Service Worker registriert:', registration);
    },
    onRegisterError(error) {
      console.log('Fehler bei der Registrierung des Service Workers:', error);
    },
    onNeedRefresh() {
      setNeedRefresh(true);
    }
  });

  const close = () => {
    setNeedRefresh(false);
  };

  const containerStyles = {
    padding: 0,
    margin: 0,
    width: 0,
    height: 0
  };

  const cardStyles = {
    position: 'fixed',
    right: 0,
    bottom: 0,
    margin: '16px',
    padding: '12px',
    borderRadius: '10px',
    zIndex: 9999999,
    textAlign: 'left',
    boxShadow:
      theme.palette.mode === 'dark'
        ? 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(255, 255, 255, 0.06) 0px 0px 0px 2px'
        : 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 2px',
    backgroundColor: theme.palette.mode === 'dark' ? alpha('#000000', 0.3) : alpha('#ffffff', 0.3),
    backdropFilter: 'blur(30px)',
    WebkitBackdropFilter: 'blur(30px)',
    transition: 'ease-in-out 0.3s',
    maxWidth: 'calc(100dvw - 0)'
  };

  return (
    <Box sx={containerStyles}>
      {needRefresh && (
        <Box sx={cardStyles}>
          <Stack>
            <Stack mb="10px" direction="row" gap="10px" justifyContent="space-between" alignItems="center">
              <Stack direction="row" gap="10px" justifyContent="space-between" alignItems="center">
                <Alert20Regular />
                <Typography fontWeight={600}>{intl.formatMessage({ id: 'settings.new-app-version-available' })}</Typography>
              </Stack>
              <IconButton size="small" onClick={() => close()}>
                <Dismiss20Regular />
              </IconButton>
            </Stack>
            <Divider sx={{ borderWidth: '1px' }} />
            <Typography m="15px 0" width={440} maxWidth="80dvw">
              {intl.formatMessage({ id: 'settings.new-version-description' })}
            </Typography>
            <Stack mt="10px" direction="row" gap="10px" justifyContent="flex-start" alignItems="center">
              <Button size="small" variant="contained" onClick={() => updateServiceWorker(true)}>
                {intl.formatMessage({ id: 'settings.reload-now' })}
              </Button>
              <Button size="small" color="secondary" onClick={() => close()}>
                {intl.formatMessage({ id: 'settings.reload-later' })}
              </Button>
            </Stack>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default ReloadPrompt;
