import { Dispatch, ReactElement } from 'react';
import { alpha, styled } from '@mui/material';
import { ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { ChevronDown20Regular, ChevronRight20Regular, Circle12Regular } from '@fluentui/react-icons';
import { ILinkObjectForSelect, ILinkingPageNav, ILinkingWidgetNav, UpdateSelectedFnType } from './types';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';

export interface ICollapsibleSectionProps {
  page: ILinkingPageNav;
  selectedOption: ILinkObjectForSelect['source'];
  updateSelectedId: UpdateSelectedFnType;
  linkId: ILinkObjectForSelect['linkId'];
  setIsSelectOpen?: Dispatch<string | null>;
}
const CollapsibleSection = ({
  page,
  updateSelectedId,
  selectedOption,
  linkId,
  setIsSelectOpen
}: ICollapsibleSectionProps): ReactElement => {
  const isWidgetsExist = !!page.widgets && page.widgets.length > 0;
  const isSubPagesExist = !!page.subPages && page.subPages.length > 0;

  const renderWidgetLabelHandler = (label: string) => {
    if (!label) return '';
    if (label.includes('style')) {
      const contentBlock = htmlToDraft(label);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        return contentState.getPlainText();
      }
      return label;
    } else return label;
  };

  const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.content}`]: {
      padding: '8px 12px',
      borderRadius: 0,
      margin: 0
    },
    [`& .${treeItemClasses.iconContainer}`]: {
      '& .close': {
        opacity: 0.2
      }
    },
    [`& .${treeItemClasses.groupTransition}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
    }
  }));

  const chooseMenuItemHandler = (optionId: string, pageSystemId: string, widgetId: string, subPageSystemId?: string) => {
    updateSelectedId({ optionId, pageSystemId, subPageSystemId, widgetId, label: '' }, linkId);
    setIsSelectOpen && setIsSelectOpen(null);
  };

  const renderWidgetsMenu = (widget: ILinkingWidgetNav, subPageId?: string, offset = 4) => {
    if (!widget?.id) return null;
    return (
      <CustomTreeItem label={renderWidgetLabelHandler(widget.label)} itemId={widget.id}>
        {widget.options.map((widgetOption, index) => (
          <CustomTreeItem
            key={index}
            label={widgetOption.label}
            itemId={widgetOption.id}
            onClick={() => chooseMenuItemHandler(widgetOption.id, page.id, widget.id, subPageId)}
          />
        ))}
      </CustomTreeItem>
    );
  };

  return (
    <SimpleTreeView
      aria-label="customized"
      defaultExpandedItems={[
        selectedOption?.pageId?.toString() ?? '',
        selectedOption.subPageId?.toString() ?? '',
        selectedOption?.widgetId?.toString() ?? '',
        selectedOption?.optionId?.toString() ?? ''
      ]}
      slots={{
        expandIcon: ChevronRight20Regular,
        collapseIcon: ChevronDown20Regular,
        endIcon: Circle12Regular
      }}
      sx={{ overflowX: 'hidden', flexGrow: 1 }}
    >
      {(isWidgetsExist || isSubPagesExist) && (
        <CustomTreeItem itemId={page.id} label={page.label}>
          <>
            {page?.widgets?.map((widget) => renderWidgetsMenu(widget))}
            {page?.subPages?.map((subPage) => (
              <>
                {subPage?.widgets && subPage?.widgets?.length > 0 && (
                  <CustomTreeItem itemId={subPage.id} label={subPage.label}>
                    {subPage?.widgets?.map((widget) => renderWidgetsMenu(widget, subPage.id, 6))}
                  </CustomTreeItem>
                )}
              </>
            ))}
          </>
        </CustomTreeItem>
      )}
    </SimpleTreeView>
  );
};

export default CollapsibleSection;
