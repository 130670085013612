import { INFINITY_QUERY_PAGE_SIZE } from 'api/constants/constant';
import { IPageQueryParams } from 'api/types/infinityQueryPageParams';
import axios from 'axios';
import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { IContactPeople } from 'shared/types/event';
import { IPaginatedResult } from 'shared/types/paginatedResult';

export enum EventsQueryKeys {
  GetEventsCustomer = 'event-customer-select'
}

const fetchCustomer = async (
  pageNumber = 1,
  id: string,
  search?: string,
  isActive?: boolean
): Promise<IPaginatedResult<IContactPeople>> => {
  return axios
    .get(`${import.meta.env.VITE_REACT_APP_API_URL}/tenants/${id}/dropdowns/customers`, {
      params: { pageNumber, search, pageSize: INFINITY_QUERY_PAGE_SIZE, isActive }
    })
    .then((res) => {
      const filteredData = res.data.data.filter((item: IContactPeople) => item?.isActive);
      return { ...res.data, data: filteredData ?? [] };
    });
};

export const useQuestionnaireCustomerInfinite = (id: string, searchValue?: string, isActive?: boolean) => {
  const search = useMemo(() => (searchValue === '' ? undefined : searchValue), [searchValue]);

  const query = useInfiniteQuery({
    queryKey: [EventsQueryKeys.GetEventsCustomer, { search, isActive }],
    queryFn: async (params) => {
      const pageParam = params.pageParam as IPageQueryParams;
      return await fetchCustomer(pageParam?.pageNumber ?? 1, id, search, isActive);
    },
    getNextPageParam: (lastPage: IPaginatedResult<IContactPeople>): IPageQueryParams | undefined => {
      const amountOfPages = Math.ceil(lastPage.totalRecords / lastPage.pageSize);
      if (lastPage.pageNumber < amountOfPages) {
        return { pageNumber: lastPage.pageNumber + 1, search };
      } else {
        return undefined;
      }
    }
  });

  const data = useMemo(() => {
    return query.data?.pages.map((x) => x.data).flat(1);
  }, [query.data?.pages]);

  return { ...query, data };
};
