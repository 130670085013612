import { lazy } from 'react';
import { Outlet } from 'react-router';
import Loadable from 'shared/components/Loadable';
import AuthGuard from 'shared/components/route-guard/AuthGuard';
import MainLayout from 'shared/layouts/MainLayout';
import BuilderLayout from 'modules/builder/layouts/BuilderLayout';
import GuestGuard from 'shared/components/route-guard/GuestGuard';
import CommonLayout from 'shared/layouts/CommonLayout';
import {
  QUESTIONNAIRES_REQUEST_EDIT_ROUTES,
  QUESTIONNAIRES_REQUEST_PLAYGROUND_ROUTES,
  QUESTIONNAIRES_REQUEST_PREVIEW_ROUTES,
  REQUESTS_ROUTES
} from './constant/paths';

const RequestsPage = Loadable(lazy(() => import('pages/Requests')));
const RequestsPreviewByQuestionnaire = Loadable(lazy(() => import('pages/Requests/PreviewByQuestionnaire')));
const RequestPreviewById = Loadable(lazy(() => import('pages/Requests/RequestPreviewById')));
const EditPageById = Loadable(lazy(() => import('pages/Requests/EditQuestionnairePageById')));
const RequestsEditByQuestionnaire = Loadable(lazy(() => import('pages/Requests/EditQuestionnaireByQuestionnaireId')));
const BuilderPlayground = Loadable(lazy(() => import('modules/questionnaires/containers/PlaygroundEventContainer')));
const RequestsAnnonymous = Loadable(lazy(() => import('pages/Requests/RequestsAnnonymous')));

export const RequestsRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: REQUESTS_ROUTES.ROOT_LINK,
          element: <RequestsPage />
        }
      ]
    },
    {
      path: QUESTIONNAIRES_REQUEST_PREVIEW_ROUTES.ROOT_LINK,
      element: (
        <AuthGuard>
          <Outlet />
        </AuthGuard>
      ),
      children: [
        {
          path: QUESTIONNAIRES_REQUEST_PREVIEW_ROUTES.LINK_BY_QUESTIONNAIRE,
          element: (
            <AuthGuard role={['tenant_admin', 'customer', 'venue_manager', 'event_manager']}>
              <RequestsPreviewByQuestionnaire />
            </AuthGuard>
          )
        },
        {
          path: QUESTIONNAIRES_REQUEST_PREVIEW_ROUTES.LINK_BY_PAGE_ID,
          element: (
            <AuthGuard role={['tenant_admin', 'customer', 'venue_manager', 'event_manager']}>
              <RequestPreviewById />
            </AuthGuard>
          )
        },
        {
          path: QUESTIONNAIRES_REQUEST_PREVIEW_ROUTES.LINK_BY_PAGE_ID_AND_SUBPAGE_ID,
          element: (
            <AuthGuard role={['tenant_admin', 'customer', 'venue_manager', 'event_manager']}>
              <RequestPreviewById />
            </AuthGuard>
          )
        }
      ]
    },
    {
      path: QUESTIONNAIRES_REQUEST_EDIT_ROUTES.ROOT_LINK,
      element: (
        <AuthGuard>
          <BuilderLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: QUESTIONNAIRES_REQUEST_EDIT_ROUTES.EDIT_LINK_BY_QUESTIONNAIRE,
          element: (
            <AuthGuard role={['tenant_admin', 'customer', 'venue_manager']}>
              <RequestsEditByQuestionnaire />
            </AuthGuard>
          )
        },
        {
          path: QUESTIONNAIRES_REQUEST_EDIT_ROUTES.EDIT_LINK_BY_PAGE_ID,
          element: (
            <AuthGuard role={['tenant_admin', 'customer', 'venue_manager']}>
              <EditPageById />
            </AuthGuard>
          )
        },
        {
          path: QUESTIONNAIRES_REQUEST_EDIT_ROUTES.EDIT_LINK_BY_PAGE_ID_AND_SUBPAGE_ID,
          element: (
            <AuthGuard role={['tenant_admin', 'customer', 'venue_manager']}>
              <EditPageById />
            </AuthGuard>
          )
        }
      ]
    },
    {
      path: QUESTIONNAIRES_REQUEST_PLAYGROUND_ROUTES.ROOT_LINK,
      element: (
        <AuthGuard>
          <Outlet />
        </AuthGuard>
      ),
      children: [
        {
          path: QUESTIONNAIRES_REQUEST_PLAYGROUND_ROUTES.LINK_PLAYGROUND_PAGE_ID,
          element: (
            <AuthGuard role={['tenant_admin', 'customer', 'venue_manager']}>
              <BuilderPlayground />
            </AuthGuard>
          )
        },
        {
          path: QUESTIONNAIRES_REQUEST_PLAYGROUND_ROUTES.LINK_PLAYGROUND_PAGE_ID_AND_SUBPAGE_ID,
          element: (
            <AuthGuard role={['tenant_admin', 'customer', 'venue_manager']}>
              <BuilderPlayground />
            </AuthGuard>
          )
        }
      ]
    },
    {
      path: 'requests/anonymous/',
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: ':prefix',
          element: <RequestsAnnonymous />
        }
      ]
    }
  ]
};
