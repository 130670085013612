import { RootState } from 'store/index';
import { ERequestsQueryKeys } from 'shared/types/requests';

export const selectPaginationUsers = (state: RootState) => state.pagination.users;
export const selectPaginationTenants = (state: RootState) => state.pagination.tenants;
export const selectPaginationEvents = (state: RootState) => state.pagination.events;
export const selectPaginationQuestionnaires = (state: RootState) => state.pagination.questionnaires;
export const selectPaginationVenues = (state: RootState) => state.pagination.venues;
export const selectPaginationArticles = (state: RootState) => state.pagination.articles;
export const selectPaginationOrganisationPlans = (state: RootState) => state.pagination.organisationPlans;
export const selectPaginationCustomerEvents = (state: RootState) => state.pagination.customerEvents;
export const selectPaginationInvoices = (state: RootState) => state.pagination.invoices;
export const selectPaginationNotes = (state: RootState) => state.pagination.notes;
export const selectPaginationRequests = (state: RootState) => state.pagination[ERequestsQueryKeys.GetRequests];
