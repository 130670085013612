import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ModalHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 16px',
  height: '48px',
  '& .MuiIconButton-root': {
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  '& .MuiListItemButton-root': {
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

export const DndListWrapper = styled('li')(() => ({
  listStyle: 'none',
  padding: '0',
  margin: '0',
  '& li': {
    padding: '0',
    margin: '0',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

export const DndContainerWrapper = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  width: 'fit-content'
}));
