import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const WidgetBarTypography = styled(Typography)(() => ({
  padding: '16px 24px',
  color: '#262626',
  fontSize: '1rem',
  fonWeight: '500',
  lineHeight: '24px'
}));

export const WidgetBarWrapper = styled(Box)(({ theme }) => ({
  borderTop: `1px dashed ${theme.palette.secondary.light}`,
  width: '100%',
  height: 'calc(100% - 56px)',
  padding: '15px 5px 10px 15px',
  overflow: 'scroll',
  overflowX: 'hidden',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const WidgetBarWrapperBox = styled(Box)(() => ({
  display: 'grid',
  gap: '10px',
  gridTemplateColumns: 'repeat(auto-fit, minmax(141px, 48%))'
}));
