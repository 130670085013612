export const DASHBOARD_LAYOUT_SIZE = {
  rowHeight: 4,
  rowGap: 10,
  widthGrid: '100%',
  col: 48,
  mobileColumnsCount: 1,
  gridPaddingDesktop: 0,
  gridPaddingMobile: 10
};

export const TABLE_DASHBOARD_PREVIEW_ROUTES = {
  ROOT_LINK: 'table',
  LINK_BY_TOKEN: ':id/:token',
  GET_LINK_BY_TOKEN: (token: string, id: number) => `/table/${id}/${token}`
};

export const HEIGHT_TAG_LIST_MODAL_TAG = 450;
