import { ReactElement, useState } from 'react';
import { Layout } from 'react-grid-layout';
import { Box } from '@mui/material';
import { useDispatch } from 'store';
import ReplacementTextHeading from 'shared/components/ReplacementText/Heading';
import { updateOrganisationPlanWidget } from 'store/organisation-plan-template/organisation-plan-template.slice';
import PreviewWidgetWrapperOrgPlan from 'modules/organisation-plan-preview/components/PreviewWidgetWrapperOrgPlan';
import {
  IOrganisationPlanChoiceOptionLabels,
  SingleChoiceOrganisationPlanWidgetType
} from 'modules/organisation-plan-templates/types/organisationPlanChoiceWidgetType';
import { BoxWrapper } from 'modules/organisation-plan-templates/components/widgets/InputWithReplacementWidget.styles';
import OrganisationPlanChoiceLabel from 'modules/organisation-plan-templates/components/widgets/OrganisationPlanChoiceWithReplacementWidget/OrganisationPlanChoiceLabel';

interface IOrganisationPlanSingleChoiceWidgetProps {
  element: SingleChoiceOrganisationPlanWidgetType;
  layoutItem: Layout;
}
const OrganisationPlanSingleChoiceWidget = ({ element, layoutItem }: IOrganisationPlanSingleChoiceWidgetProps): ReactElement => {
  const { widgetId, sectionId, optionLabels, selectedOption, label, type, replacementLabel, replacementOptionsLabels, isMainLabelHidden } =
    element;
  const dispatch = useDispatch();
  const [isHeadingOpen, setIsHeadingOpen] = useState(false);

  const replaceGeneralLabelHandler = (replacementLabel: string) => {
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as SingleChoiceOrganisationPlanWidgetType['type'], replacementLabel }
      })
    );
  };

  const changeGeneralLabelVisibilityHandler = (isMainLabelHidden: boolean) => {
    dispatch(
      updateOrganisationPlanWidget({
        widgetId: widgetId,
        widget: { type: type as SingleChoiceOrganisationPlanWidgetType['type'], isMainLabelHidden }
      })
    );
  };

  const changeOptionVariablesHandler = (
    option: IOrganisationPlanChoiceOptionLabels,
    replacementLabel?: string,
    isLabelHidden?: boolean
  ) => {
    const updatedReplacementOptionsLabels = replacementOptionsLabels?.map((optionLabel) => {
      if (optionLabel.id === option.id) {
        return {
          ...optionLabel,
          replacementLabel: replacementLabel || '',
          isLabelHidden: isLabelHidden !== undefined ? isLabelHidden : optionLabel.isLabelHidden
        };
      }
      return optionLabel;
    });

    dispatch(updateOrganisationPlanWidget({ widgetId, widget: { type, replacementOptionsLabels: updatedReplacementOptionsLabels } }));
  };

  const handleOptionReplacementLabelChange = (option: IOrganisationPlanChoiceOptionLabels, isMainLableOpen?: boolean) => {
    const updatedReplacementOptionsLabels = replacementOptionsLabels?.map((optionLabel) => {
      if (optionLabel.id === option.id) {
        return {
          ...optionLabel,
          replacementLabel: isMainLableOpen ? '' : null,
          isLabelHidden: optionLabel.isLabelHidden
        };
      }
      return optionLabel;
    });

    dispatch(updateOrganisationPlanWidget({ widgetId, widget: { type, replacementOptionsLabels: updatedReplacementOptionsLabels } }));
  };

  return (
    <PreviewWidgetWrapperOrgPlan
      widgetId={widgetId}
      sectionId={sectionId}
      layoutItem={layoutItem}
      dependencies={[isHeadingOpen, replacementOptionsLabels]}
    >
      <BoxWrapper>
        <ReplacementTextHeading
          onChange={(e) => replaceGeneralLabelHandler(e.target.value)}
          value={replacementLabel || ''}
          content={label}
          isLabelHiddenValue={isMainLabelHidden}
          onLabelHiddenChange={(val) => changeGeneralLabelVisibilityHandler(val)}
          onReplacementLabelHiddenChange={() => replaceGeneralLabelHandler('')}
          isHeadingReplacementOpen={(value) => setIsHeadingOpen(value)}
        />
        <Box>
          {optionLabels.map((labelObj) => (
            <OrganisationPlanChoiceLabel
              key={labelObj.id}
              option={labelObj}
              selectedValues={selectedOption}
              isCheckbox={false}
              replaceOptionLabelsHandler={(e) => changeOptionVariablesHandler(labelObj, e.target.value)}
              replacementLabel={replacementOptionsLabels.find((i) => i.id === labelObj.id)?.replacementLabel}
              isLabelHiddenValue={replacementOptionsLabels?.find((i) => i.id === labelObj.id)?.isLabelHidden}
              onLabelHiddenChange={() => {
                const currentIsLabelHidden = replacementOptionsLabels?.find((i) => i.id === labelObj.id)?.isLabelHidden;
                changeOptionVariablesHandler(labelObj, undefined, !currentIsLabelHidden);
              }}
              onReplacementLabelHiddenChange={() => changeOptionVariablesHandler(labelObj, '')}
              isHeadingReplacementOpen={(value) => handleOptionReplacementLabelChange(labelObj, value)}
            />
          ))}
        </Box>
      </BoxWrapper>
    </PreviewWidgetWrapperOrgPlan>
  );
};

export default OrganisationPlanSingleChoiceWidget;
