import { useTheme } from '@mui/material/styles';
import Logo from 'shared/components/logo';
import DrawerHeaderStyled from './DrawerHeaderStyled';

interface Props {
  open: boolean;
  logo?: JSX.Element;
}

const DrawerHeader = ({ open, logo }: Props) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      {logo ? logo : <Logo isIcon={!open} sx={{ width: open ? 'auto' : 35, height: 35 }} />}
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
