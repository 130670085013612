import { FormattedMessage } from 'react-intl';
import { Button, Grid, Stack } from '@mui/material';
import * as Styles from './ModalSendEmail.styles';

interface IInvoiceModalSendEmailActions {
  closeDialog: () => void;
  handleOnSubmit: () => void;
  isSendEmail: boolean;
}

const ModalSendEmailActions = (props: IInvoiceModalSendEmailActions) => {
  return (
    <>
      <Styles.ButtonsAction>
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button size="small" color="error" onClick={props.closeDialog}>
                <FormattedMessage id="users.cancel" />
              </Button>
              <Button size="small" onClick={props.handleOnSubmit} variant="contained" disabled={props.isSendEmail}>
                <FormattedMessage id="invoices.invoice-editor-send-email-button" />
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Styles.ButtonsAction>
    </>
  );
};

export default ModalSendEmailActions;
