import { NavItemType } from 'shared/types/menu';
import applications from './applications';
import chartsMap from './charts-map';
import dashboard from './dashboard';
import formsTables from './forms-tables';
import other from './other';
import pages from './pages';
import widget from './widget';

const menuMantis: { items: NavItemType[] } = {
  items: [dashboard, widget, applications, formsTables, chartsMap, pages, other]
};

export default menuMantis;
