import { Layout } from 'react-grid-layout';
import { WidgetsType } from 'modules/builder/types';
import { EQuestionnairesSource } from 'shared/types/questionnaire-template';

/**
 * Util function to remove hidden layout items
 * @param layout array of layout
 * @param usedWidgetsArray array of widgets
 * @returns array of layout
 */
export const filterLayoutForHiddenWidgets = (layout: Layout[], usedWidgetsArray: WidgetsType[]): Layout[] => {
  const notHiddenWidgetsIds = usedWidgetsArray.filter((widget) => !widget.isHidden).map((widget) => widget.widgetId);
  return layout.filter((layoutItem) => notHiddenWidgetsIds.includes(layoutItem.i));
};

interface IfilterLayoutAndWidgetByVisibilityParams {
  widgets: WidgetsType[] | undefined;
  layout: Layout[];
  questionnaireSource: EQuestionnairesSource;
  isPreview: boolean;
}
/**
 * Util function to filter widgets and layout by visibility field base on current questionnaire source.
 * @param param.pageData - page of questionnaire
 * @param param.questionnaireSource - current source of entity (event / template / request)
 * @param param.layout - layout of page
 * @returns `{ widgets, layout }` object
 */
export const filterLayoutAndWidgetByVisibility = ({
  widgets,
  questionnaireSource,
  layout,
  isPreview
}: IfilterLayoutAndWidgetByVisibilityParams): { widgets: WidgetsType[]; layout: Layout[] } => {
  if (!isPreview) {
    return {
      widgets: widgets ?? [],
      layout
    };
  }
  const widgetsIdsForRemove = widgets
    ?.filter((widget) => {
      if (questionnaireSource === EQuestionnairesSource.template) return false;
      if (!widget?.visibility) return false;
      return !widget.visibility?.[questionnaireSource];
    })
    .map((widget) => widget.widgetId);
  const filteredWidgets = widgetsIdsForRemove?.length
    ? widgets?.filter((widget) => {
        if (questionnaireSource === EQuestionnairesSource.template) return true;
        if (!widget?.visibility) return true;
        return widget.visibility?.[questionnaireSource];
      })
    : widgets;
  const layoutFiltered = widgetsIdsForRemove?.length
    ? layout.filter((layout) => !widgetsIdsForRemove?.find((widgetId) => layout.i === widgetId))
    : layout;
  return {
    widgets: filteredWidgets ?? [],
    layout: layoutFiltered
  };
};
