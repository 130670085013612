import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconButton from 'shared/components/@extended/IconButton';

const editInputHeight = 32;

export const TextEditStyled = styled(TextField)(() => ({
  width: '100%',
  '&:not(:first-of-type)': {
    marginTop: '12px',
    marginBottom: '12px'
  },
  '& .MuiOutlinedInput-root': {
    padding: 0,
    height: editInputHeight
  }
}));

export const SaveButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '0 6px 6px 0',
  height: editInputHeight
}));
