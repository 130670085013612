import { ReactNode, createContext, useMemo } from 'react';
import { useGetTenantByUrl } from 'api/hooks/company/useGetCompany';
import { useGetTenantById } from 'api/hooks/tenant/useGetTenant';
import { useGetCurrentUser } from 'api/hooks/user/useGetUser';
import { TENANT_URL } from 'modules/auth/constants';
import { ILoginViewSettings, ITenantPayload } from 'shared/types/tenants';
import { IUser } from 'shared/types/user';

interface IGeneralSettingsContext {
  user: IUser | undefined;
  isUserLoading: boolean;
  tenant: ITenantPayload | undefined;
  isTenantLoading: boolean;
  regionalSettings?: ITenantPayload['regionalSettings'];
  loginViewSetting?: ILoginViewSettings;
}

const initialState: IGeneralSettingsContext = {
  user: undefined,
  isUserLoading: false,
  tenant: undefined,
  isTenantLoading: false,
  regionalSettings: undefined,
  loginViewSetting: undefined
};

const GeneralSettingsContext = createContext(initialState);

type GeneralSettingsProviderProps = {
  children: ReactNode;
};

function GeneralSettingsProvider({ children }: GeneralSettingsProviderProps) {
  const { data: user, isLoading: isUserLoading } = useGetCurrentUser();
  const { data: tenant, isLoading: isTenantLoading } = useGetTenantById({
    id: user?.role === 'customer' ? '' : user?.tenant?.id?.toString()
  });
  const tenantUrl = localStorage.getItem(TENANT_URL);
  const { data: tenantData } = useGetTenantByUrl<{ message: string }>({
    tenantUrl: tenantUrl
  });

  const contextData: IGeneralSettingsContext = useMemo(
    () => ({
      user,
      isUserLoading,
      tenant,
      isTenantLoading,
      regionalSettings: tenant?.regionalSettings ?? tenantData?.regionalSettings,
      loginViewSetting: tenant?.loginViewSetting ?? tenantData?.loginViewSetting
    }),
    [user, isUserLoading, tenant, isTenantLoading, tenantData]
  );

  return <GeneralSettingsContext.Provider value={contextData}>{children}</GeneralSettingsContext.Provider>;
}

export { GeneralSettingsProvider, GeneralSettingsContext };
