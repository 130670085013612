import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { IPaginatedResult } from 'shared/types/paginatedResult';
import { ISendEmailOfType } from 'modules/modalSendEmail/types';
import { API_OFFERS_ENDPOINTS, EOffersQueryKeys, IOffer, IOfferEditor } from 'shared/types/offer';
import { ERequestStatus } from 'shared/types/requests';

type MutationContext =
  | {
      previousRequests: IPaginatedResult<IOfferEditor> | undefined;
      newOffer: IOffer;
    }
  | undefined;

const sendEmailOffer = (offerInfo: ISendEmailOfType): Promise<ISendEmailOfType> => {
  return axios
    .post(`${API_OFFERS_ENDPOINTS.SEND_EMAIL(offerInfo.id)}`, {
      body: offerInfo.body,
      subject: offerInfo.subject
    })
    .then((res) => res.data);
};

interface IUseSendEmailOffer<TError> {
  options?: Omit<UseMutationOptions<ISendEmailOfType, TError, ISendEmailOfType, MutationContext>, 'mutationFn' | 'onSuccess' | 'variables'>;
  onSuccess?: ((data: ISendEmailOfType, variables: ISendEmailOfType, context: MutationContext) => void | Promise<unknown>) | undefined;
}

export function useSendEmailOffer<TError>({ options = {}, onSuccess }: IUseSendEmailOffer<TError>) {
  const queryClient = useQueryClient();
  return useMutation(sendEmailOffer, {
    ...options,
    onSuccess: (data, variables, context) => {
      const keyOfferEditor = [EOffersQueryKeys.GetOfferEditor, { offerId: variables?.id }];
      const keyOfferItemsSelected = [EOffersQueryKeys.GetOfferItemsSelected, { offerId: variables?.id }];

      const previousOfferEditor: IOfferEditor | undefined = queryClient.getQueryData(keyOfferEditor);
      const newData = {
        ...previousOfferEditor,
        isSentEmail: true,
        state: ERequestStatus?.SentPerEmail
      };
      queryClient.setQueryData(keyOfferEditor, newData);

      queryClient.setQueryData(keyOfferItemsSelected, variables?.articles);
      if (onSuccess) {
        onSuccess(data, variables, context);
      }
    }
  });
}
