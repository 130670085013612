import { useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Box, Dialog } from '@mui/material';
import { useGetShareTableWidgetsForPreview } from 'modules/dashboard/hooks/useGetShareTable';
import TableDashboardWidget from 'modules/dashboard/components/widgets/TableDashboardWidget';
import { selectIsDashboardCustomize } from 'store/dashboard/dasboard.selectors';
import { useGetWidgetByToken } from 'modules/dashboard/hooks/useGetWidgetByToken';
import { IPagination } from 'shared/types/table';

const DashboardTableLayout = () => {
  const [sorts, setSorts] = useState<IPagination | null>(null);
  const isCustomize = useSelector(selectIsDashboardCustomize);
  const { token } = useParams();

  const {
    data: newRows,
    mainData: tableData,
    hasNextPage,
    fetchNextPage,
    isFetching
  } = useGetShareTableWidgetsForPreview({ token: token || '', dataSort: sorts });

  const { data: widget } = useGetWidgetByToken(token);

  return (
    <>
      <Dialog open={true} fullScreen={true} sx={{ margin: 'auto' }}>
        <Box sx={{ width: '100%' }}>
          <TableDashboardWidget
            isHideHeaderTable={true}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetching={isFetching}
            newRows={newRows}
            tableData={tableData}
            isCustomize={isCustomize}
            widget={widget}
            updatePaginationAndFiltering={setSorts}
            isGroupWidget={false}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default DashboardTableLayout;
