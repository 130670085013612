import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxWrapper = styled(Box)(() => ({
  paddingBottom: '0px',
  '& p': {
    marginBlockStart: 0
  }
}));

export const TypographyWrapper = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '14px'
}));
