import { EntityState } from '@reduxjs/toolkit';
import { IUpdateQuestionnaireTemplateId } from 'api/hooks/organisation-plan-templates/useCreateOrganisationPlanTemplate';
import { OrganisationPlanTemplateBuilderHeaderOptions } from 'shared/types/organisation-plan';
import { IOrgPlanLinkObjectForSelect } from 'modules/organisation-plan-templates/components/OrganisationPlanTemplateLinkingTab/types';
import { ORGANISATION_PLAN_WIDGET_TYPES, OrganisationPlanWidgetsType } from 'modules/organisation-plan-templates/types';
import { IOrganisationPlanSection } from 'modules/organisation-plan-templates/types/organisationPlanSection';
import { isOrganisationPlanWidgetOnly } from './isOrganisationPlanWidgetOnly';

interface IPreparedDataProps {
  organisationPlanId?: string;
  questionnaireTemplateId?: string;
  headerOptions?: OrganisationPlanTemplateBuilderHeaderOptions;
}

interface IPreparedBuilderDataProps {
  usedWidgets: EntityState<OrganisationPlanWidgetsType>;
  commonData: {
    pageId: number;
    questionnaireTemplateId: number;
  };
  headerOptions: OrganisationPlanTemplateBuilderHeaderOptions;
  usedSections: IOrganisationPlanSection[];
  links: IOrgPlanLinkObjectForSelect[];
}

/**
 * Util function to prepare common data for builder and template data.
 * @param param.organisationPlanId organisation plan id.
 * @param param.questionnaireTemplateId questionnaire template id.
 * @param param.headerOptions organisation plan template builder header options.
 * @returns common data and template data.
 */
export const preparedCommonTemplateData = ({ organisationPlanId, questionnaireTemplateId, headerOptions }: IPreparedDataProps) => {
  const commonData = {
    pageId: Number(organisationPlanId || 0),
    questionnaireTemplateId: Number(questionnaireTemplateId || 0)
  };

  const templateData: IUpdateQuestionnaireTemplateId = {
    ...commonData,
    data: {
      id: commonData.pageId,
      questionnaireTemplateId: commonData.questionnaireTemplateId,
      name: headerOptions?.name || ''
    }
  };

  return { commonData, templateData };
};

/**
 * Util function to prepare builder data to update questionnaire template builder.
 * @param param.usedWidgets organisation plan widgets.
 * @param param.commonData questionnaire template id and page id.
 * @param param.headerOptions organisation plan template builder header options.
 * @param param.usedSections organisation plan section.
 * @param param.links organisation plan links.
 * @returns prepared builder data.
 */
export const preparedBuilderData = ({ usedWidgets, commonData, headerOptions, usedSections, links }: IPreparedBuilderDataProps) => {
  const preparedWidgets = (Object.values(usedWidgets?.entities) || []).filter(
    (i) => i?.type !== ORGANISATION_PLAN_WIDGET_TYPES.infoFromQuestionnaire
  );

  return {
    ...commonData,
    data: {
      ...headerOptions,
      sections: usedSections
        .map((section) => ({
          ...section,
          layout: section.layout.filter((item) => preparedWidgets.find((w) => w?.widgetId === item.i))
        }))
        .sort((a, b) => a.order - b.order),
      links,
      items: preparedWidgets.map(
        (w) =>
          w && {
            ...w,
            widgetId: w.linkedWidgetId,
            widgetKey: w.widgetId,
            alternativeLabels: {
              replacementLabel: 'replacementLabel' in w ? w.replacementLabel : undefined,
              isMainLabelHidden: 'isMainLabelHidden' in w ? w.isMainLabelHidden : undefined,
              isInlinePreview: 'isInlinePreview' in w ? w.isInlinePreview : undefined,
              isInlineLink: 'isInlineLink' in w ? w.isInlineLink : undefined,
              replacementOptionsLabels:
                'replacementOptionsLabels' in w
                  ? w.replacementOptionsLabels
                  : 'replacementDataImage' in w
                    ? w.replacementDataImage
                    : undefined
            },
            layout: isOrganisationPlanWidgetOnly(w.type as ORGANISATION_PLAN_WIDGET_TYPES)
              ? {
                  ...w
                }
              : undefined
          }
      )
    }
  };
};
