import { useIntl } from 'react-intl';
import { SignOut24Regular } from '@fluentui/react-icons';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import useConfig from 'shared/hooks/useConfig';
import { I18n } from 'shared/types/config';
import { useAuth } from 'shared/contexts/AuthContext';
import useGeneralSettings from 'shared/hooks/useGeneralSettings';
import NavGroup from 'shared/layouts/MainLayout/Drawer/DrawerContent/Navigation/NavGroup';
import settings from 'shared/components/menu-items/settings';

interface IMenuButton {
  drawerOpen: boolean;
}
const MenuButtom = ({ drawerOpen }: IMenuButton) => {
  const intl = useIntl();
  const { onChangeLocalization } = useConfig();
  const { tenant } = useGeneralSettings();
  const languageCode = tenant?.regionalSettings?.defaultLanguage?.code;

  const { logout } = useAuth();
  const handleLogout = async () => {
    try {
      await logout();
      if (languageCode) {
        onChangeLocalization(languageCode as I18n);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <List
        component="nav"
        sx={{
          width: '100%',
          pb: 0
        }}
      >
        <NavGroup item={settings} />

        <ListItemButton
          sx={{
            height: '44px',
            zIndex: 1201,
            borderRadius: '6px',
            margin: '1.5px 8px',
            padding: '7px 10px'
          }}
          onClick={handleLogout}
        >
          <ListItemIcon
            sx={{
              pl: drawerOpen ? '5px' : '4px',
              pr: drawerOpen ? 1 : 1,
              fontSize: drawerOpen ? '1rem' : '1.25rem'
            }}
          >
            <SignOut24Regular />
          </ListItemIcon>
          {drawerOpen ? <ListItemText primary={intl.formatMessage({ id: 'sidebar.logout' })} /> : ''}
        </ListItemButton>
      </List>
    </>
  );
};

export default MenuButtom;
