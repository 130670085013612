import { EditorType } from 'modules/editor/types/editor';

/**
 * generate link for invoice pdf by invoice id
 * @param id invoice / offer id
 * @param type of PageEdit invoices | offers
 */
export const generateLinkForPdfById = (id: number, type: EditorType) => {
  return `${import.meta.env.VITE_REACT_APP_API_URL}/${type}/${id}/pdf`;
};

/**
 * download pdf by invoice id
 * @param id invoice / offer id
 * @param type of PageEdit invoices | offers
 */
export const downloadPdfByIdEditor = (id: number, type: EditorType) => {
  window.open(generateLinkForPdfById(id, type), '_blank');
};
