import { FormattedMessage } from 'react-intl';
import { FormHelperText, Grid, InputLabel, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { IInvoiceDialogForm } from 'shared/types/invoice';
import { useCurrentDateFormat } from 'shared/hooks/regionalSettings';
import { useGetDataByRole } from 'shared/hooks/useGetDataByRole';
import { Calendar20Regular } from '@fluentui/react-icons';

const InvoiceModalDateFields = () => {
  const dateFormat = useCurrentDateFormat();
  const { values, errors, touched, setFieldValue } = useFormikContext<IInvoiceDialogForm>();
  const { disableForEventManager } = useGetDataByRole();

  const handleChangeInvoiceDate = (value: Date | null) => {
    if (!value) return;
    setFieldValue('invoiceDate', value);
  };

  const handleChangeVenues = (value: Date | null) => {
    if (!value) return;
    setFieldValue('performanceDate', value);
  };

  const disableParamsForEventManagerInOldInvoice = disableForEventManager && !!values?.id;

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25}>
          <InputLabel>
            <FormattedMessage id="invoices.modal-field-invoice-date" />
          </InputLabel>
          <DatePicker
            disabled={disableParamsForEventManagerInOldInvoice}
            value={values.invoiceDate ? new Date(values.invoiceDate.toString()) : null}
            format={dateFormat?.format}
            onChange={handleChangeInvoiceDate}
            slots={{ openPickerIcon: Calendar20Regular }}
          />
          {Boolean(touched['invoiceDate'] && errors['invoiceDate']) && (
            <FormHelperText error={Boolean(touched['invoiceDate'] && errors['invoiceDate'])}>{errors['invoiceDate']}</FormHelperText>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25}>
          <InputLabel htmlFor="Questionnaire">
            <FormattedMessage id="invoices.modal-field-performance-date" />
          </InputLabel>
          <DatePicker
            disabled={disableParamsForEventManagerInOldInvoice}
            value={values.performanceDate ? new Date(values.performanceDate.toString()) : null}
            format={dateFormat?.format}
            onChange={handleChangeVenues}
            slots={{ openPickerIcon: Calendar20Regular }}
          />
          {Boolean(touched['performanceDate'] && errors['performanceDate']) && (
            <FormHelperText error={Boolean(touched['performanceDate'] && errors['performanceDate'])}>
              {errors['performanceDate']}
            </FormHelperText>
          )}
        </Stack>
      </Grid>
    </>
  );
};

export default InvoiceModalDateFields;
