import { FormHelperText, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useGetAllOfferTemplatesInfinite } from 'api/hooks/offersSettings/useOfferTemplate';
import { useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AutocompleteInfiniteSelect from 'shared/components/AutocompleteInfiniteSelect';
import { useAuth } from 'shared/contexts/AuthContext';
import { questionnaireTaxtype } from 'shared/data/questionnaires';
import { IOfferDialogForm } from 'shared/types/offer';

const OfferModalTemplatesAndValue = () => {
  const [searchValue, setSearchValue] = useState('');

  const intl = useIntl();
  const { tokenInfo } = useAuth();
  const { values, errors, touched, setFieldValue, handleChange } = useFormikContext<IOfferDialogForm>();
  const {
    data: templatesData = [],
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useGetAllOfferTemplatesInfinite({ id: tokenInfo?.tenant, searchValue });

  const handleChangeTemplate = (selectedObject: IOfferDialogForm['offerTemplate']) => {
    if (!selectedObject) return;
    setFieldValue('offerTemplate', selectedObject);
  };

  const updateSearch = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25}>
          <InputLabel htmlFor="Questionnaire">
            <FormattedMessage id="invoices.modal-field-template" />
          </InputLabel>
          <AutocompleteInfiniteSelect
            options={templatesData}
            loading={!templatesData && isFetchingNextPage}
            handleChange={handleChangeTemplate}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={values.offerTemplate}
            placeholder={intl.formatMessage({ id: 'events.select-here' })}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            updateSearch={updateSearch}
          />
          {Boolean(touched['offerTemplate'] && errors['offerTemplate']) && (
            <FormHelperText error={Boolean(touched['offerTemplate'] && errors['offerTemplate'])}>{errors['offerTemplate']}</FormHelperText>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack spacing={1.25}>
          <InputLabel htmlFor="Questionnaire">
            <FormattedMessage id="invoices.modal-field-value" />
          </InputLabel>
          <Select
            value={values.taxType ?? null}
            name="taxType"
            onChange={handleChange}
            placeholder={intl.formatMessage({ id: 'events.select-here' })}
            inputProps={{ placeholder: intl.formatMessage({ id: 'events.select-here' }) }}
            defaultValue={questionnaireTaxtype[0].value}
          >
            {questionnaireTaxtype.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                <FormattedMessage id={item.formatId} />
              </MenuItem>
            ))}
          </Select>
          {Boolean(touched['taxType'] && errors['taxType']) && (
            <FormHelperText error={Boolean(touched['taxType'] && errors['taxType'])}>{errors['taxType']}</FormHelperText>
          )}
        </Stack>
      </Grid>
    </>
  );
};

export default OfferModalTemplatesAndValue;
