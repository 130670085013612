import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { IPaginatedResult } from 'shared/types/paginatedResult';
import {
  API_INVOICES_ENDPOINTS,
  EInvoicesQueryKeys,
  IInvoice,
  IInvoiceEditor,
  IInvoiceItemsPayload,
  IInvoiceListItem
} from 'shared/types/invoice';

type MutationContext =
  | {
      previousInvoices: IPaginatedResult<IInvoiceListItem> | undefined;
      newInvoice: IInvoice;
    }
  | undefined;

const updateInvoiceItems = (invoiceInfo: IInvoiceItemsPayload): Promise<IInvoiceEditor['articles']> => {
  return axios.put(`${API_INVOICES_ENDPOINTS.GET_BY_ID(invoiceInfo.invoiceId)}/items`, invoiceInfo).then((res) => res.data);
};

interface IuseUpdateInvoiceItems<TError> {
  options?: Omit<
    UseMutationOptions<IInvoiceEditor['articles'], TError, IInvoiceItemsPayload, MutationContext>,
    'mutationFn' | 'onSuccess' | 'variables'
  >;
  onSuccess?:
    | ((data: IInvoiceEditor['articles'], variables: IInvoiceItemsPayload, context: MutationContext) => void | Promise<unknown>)
    | undefined;
}

export function useUpdateInvoiceItems<TError>({ options = {}, onSuccess }: IuseUpdateInvoiceItems<TError>) {
  const queryClient = useQueryClient();

  return useMutation(updateInvoiceItems, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([EInvoicesQueryKeys.GetInvoiceEditor, { invoiceId: variables.invoiceId }]);
      queryClient.invalidateQueries([EInvoicesQueryKeys.GetInvoiceItems, { invoiceId: variables.invoiceId }]);
      if (onSuccess) {
        onSuccess(data, variables, context);
      }
    }
  });
}
