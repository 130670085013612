import { toNumber } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { sortByColumnType } from 'modules/builder/components/widgets/TableWidget/types';
import { DEFAULT_TABLE_CELL_VALUES } from 'modules/builder/constants/builderWidgets/tableWidget';
import {
  IDataColumn,
  IDataObject,
  IDataRow,
  ITableWidgetColumnRules,
  ITableWidgetRowRules,
  TableWidgetType
} from 'modules/builder/types/tableWidgetType';

const createInitColumnsSettings = (
  numberOfColumns: TableWidgetType['columnsNumber'],
  existingData?: TableWidgetType['columnSettings']
): TableWidgetType['columnSettings'] => {
  const defaultSetting: Omit<ITableWidgetColumnRules, 'columnId'> = { type: 'string' as 'string', interactive: false };
  return new Array(numberOfColumns)
    .fill(null)
    .map((_, index) => (existingData?.[index] ? existingData[index] : { columnId: uuidv4(), ...defaultSetting }));
};

const createInitRowSettings = (
  numberOfRows: TableWidgetType['rowsNumber'],
  existingData?: TableWidgetType['rowSettings'],
  _isPreview?: boolean
): TableWidgetType['rowSettings'] => {
  const defaultSetting: Omit<ITableWidgetRowRules, 'rowId'> = { interactive: true };
  return new Array(numberOfRows)
    .fill(null)
    .map((_, index) => (existingData?.[index] ? existingData[index] : { rowId: uuidv4(), ...defaultSetting }));
};

const createInitialTableDataTitles = (
  columnSettings: TableWidgetType['columnSettings'],
  existingData?: TableWidgetType['dataTitles']
): TableWidgetType['dataTitles'] => {
  return columnSettings.map((column) => {
    const existing = existingData?.find((existingColumn) => existingColumn.columnId === column.columnId);
    return existing ? existing : { columnId: column.columnId, value: `` };
  });
};

const mapColumnsForRowData = (
  columnSettings: TableWidgetType['columnSettings'],
  existingRow?: TableWidgetType['dataContent'][0]
): IDataObject => {
  return columnSettings.reduce((acc, column) => {
    const existingColumn = existingRow?.data?.[column.columnId];
    if (existingColumn) {
      acc[existingColumn.columnId] = existingColumn;
    } else {
      const newValue = DEFAULT_TABLE_CELL_VALUES[column.type];
      acc[column.columnId] = { columnId: column.columnId, value: newValue };
    }
    return acc;
  }, {} as IDataObject);
};

const createInitialTableDataContent = (
  columnSettings: TableWidgetType['columnSettings'],
  rowSettings: TableWidgetType['rowSettings'],
  existingData?: TableWidgetType['dataContent']
): TableWidgetType['dataContent'] => {
  // First, map the existing data to preserve current order and data
  const orderedExistingData =
    existingData?.map((existingRow) => ({
      rowId: existingRow.rowId,
      data: mapColumnsForRowData(columnSettings, existingRow)
    })) || [];

  // Then, for any new row settings not in existing data, create new rows
  const newRows = rowSettings
    .filter((rowSetting) => !existingData?.some((existingRow) => existingRow.rowId === rowSetting.rowId))
    .map((rowSetting) => ({
      rowId: rowSetting.rowId,
      data: mapColumnsForRowData(columnSettings)
    }));

  // Combine the existing ordered data with the new rows
  return [...orderedExistingData, ...newRows];
};

const reduceArrayLength = (data: any[], newArrayLength: number) => {
  const newData = [...data];
  newData.length = newArrayLength;
  return newData;
};

type decreaseNumberOfColumsType = {
  columnsNumber: number;
  dataTitles: IDataColumn[];
  columnSettings: ITableWidgetColumnRules[];
  dataContent: IDataRow[];
  rowSettings: ITableWidgetRowRules[];
};

export const decreaseNumberOfColums = ({
  dataTitles,
  columnsNumber,
  columnSettings,
  dataContent,
  rowSettings
}: decreaseNumberOfColumsType) => {
  const newColumnSettings = reduceArrayLength(columnSettings, columnsNumber);

  return {
    columnsNumber: columnsNumber,
    _activeColumn: null,
    columnSettings: newColumnSettings,
    dataTitles: reduceArrayLength(dataTitles, columnsNumber),
    dataContent: createInitialTableDataContent(newColumnSettings, rowSettings, dataContent)
  };
};

export const increaseNumberOfColums = ({
  dataTitles,
  columnsNumber,
  columnSettings,
  dataContent,
  rowSettings
}: decreaseNumberOfColumsType) => {
  const newColumnSettings = createInitColumnsSettings(columnsNumber, columnSettings);
  return {
    columnsNumber: columnsNumber,
    _activeColumn: null,
    columnSettings: newColumnSettings,
    dataTitles: createInitialTableDataTitles(newColumnSettings, dataTitles),
    dataContent: createInitialTableDataContent(newColumnSettings, rowSettings, dataContent)
  };
};

export const createInitTableWidgetData = () => {
  const columnsNumber = 5;
  const rowsNumber = 4;
  const columnSettings = createInitColumnsSettings(columnsNumber);
  const rowSettings = createInitRowSettings(rowsNumber);

  return {
    columnsNumber,
    rowsNumber,
    dataTitles: createInitialTableDataTitles(columnSettings),
    dataContent: createInitialTableDataContent(columnSettings, rowSettings),
    columnSettings,
    rowSettings,
    _activeColumn: null,
    _activeRow: null
  };
};

export const updateColumnsWithNewType = (
  data: IDataRow[],
  columnIds: TableWidgetType['_activeColumn'],
  type: ITableWidgetColumnRules['type']
): IDataRow[] => {
  if (!columnIds) {
    return data;
  }
  const newValue = DEFAULT_TABLE_CELL_VALUES[type];

  return data.map(({ rowId, data }) => {
    const newData = { ...data };
    columnIds.forEach((columnId) => {
      newData[columnId] = { ...newData[columnId], value: newValue };
    });
    return { rowId, data: newData };
  });
};

export const decreaseNumberOfRows = (
  dataContent: TableWidgetType['dataContent'],
  newRowsNumber: TableWidgetType['rowsNumber'],
  rowSettings: TableWidgetType['rowSettings']
): Partial<TableWidgetType> => {
  const newRowSettings = reduceArrayLength(rowSettings, newRowsNumber);

  return {
    dataContent: reduceArrayLength(dataContent, newRowsNumber),
    rowsNumber: newRowsNumber,
    rowSettings: newRowSettings,
    _activeRow: null
  };
};

interface IincreaseNumberOfRows extends TableWidgetType {
  newRowsNumber: number;
  isPreview?: boolean;
}
export const increaseNumberOfRows = ({ columnSettings, dataContent, newRowsNumber, rowSettings, isPreview }: IincreaseNumberOfRows) => {
  const newRowSettings = createInitRowSettings(newRowsNumber, rowSettings, isPreview);
  const newArrayOfRows: TableWidgetType['dataContent'] = createInitialTableDataContent(columnSettings, newRowSettings, dataContent);
  const _activeRow = null;
  return { dataContent: newArrayOfRows, rowsNumber: newRowsNumber, _activeRow, rowSettings: newRowSettings };
};
export const removeCertainRow = (
  dataContent: TableWidgetType['dataContent'],
  rowSettings: TableWidgetType['rowSettings'],
  rowIdForRemove: IDataRow['rowId'],
  _activeRow: TableWidgetType['_activeRow']
): Partial<TableWidgetType> => {
  const newContentData = dataContent.filter((row) => row.rowId !== rowIdForRemove);
  const newRowSettings = rowSettings.filter((rowSettingsObj) => rowSettingsObj.rowId !== rowIdForRemove);
  const newActiveRow: TableWidgetType['_activeRow'] = _activeRow?.filter((activeRowId) => activeRowId !== rowIdForRemove) ?? null;
  return {
    dataContent: newContentData,
    rowSettings: newRowSettings,
    _activeRow: newActiveRow
  };
};

const getTimeValueIgnoredDate = (value: string) => {
  const date1 = new Date(value);
  const hours = date1.getHours();
  const minutes = date1.getMinutes();
  const finalTime = new Date();
  finalTime.setHours(hours, minutes);
  return finalTime.toISOString();
};

const sortComparator = (first: number | string, second: number | string, sortOrder: 'asc' | 'desc') => {
  if (first < second) {
    return sortOrder === 'asc' ? -1 : 1;
  }
  if (first > second) {
    return sortOrder === 'asc' ? 1 : -1;
  }
  return 0;
};

export const sortByColumn: sortByColumnType = (sortColumnId, sortOrder, rows, columnSettings) => {
  if (!sortColumnId || !sortOrder) {
    return rows;
  }
  const typeOfColumn = columnSettings.find((columnSetting) => columnSetting.columnId === sortColumnId)?.type;
  const filteredEmptyRows = rows.filter((row) => {
    const column = row.data[sortColumnId];
    return typeof column?.value !== 'number' && !column?.value;
  });
  const filteredRows = rows.filter((row) => typeof row.data[sortColumnId].value === 'number' || !!row.data[sortColumnId].value);
  if (typeOfColumn === 'string') {
    filteredRows.sort((row1, row2) => {
      const first = row1.data[sortColumnId]?.value?.toUpperCase() ?? Infinity;
      const second = row2.data[sortColumnId]?.value?.toUpperCase() ?? Infinity;
      return sortComparator(first, second, sortOrder);
    });
  }
  if (typeOfColumn === 'number') {
    filteredRows.sort((row1, row2) => {
      const first = row1.data[sortColumnId] ? toNumber(row1.data[sortColumnId]?.value) : Infinity;
      const second = row2.data[sortColumnId] ? toNumber(row2.data[sortColumnId]?.value) : Infinity;
      return sortComparator(first, second, sortOrder);
    });
  }
  if (typeOfColumn === 'dateTime') {
    filteredRows.sort((row1, row2) => {
      const first: number = row1.data[sortColumnId] ? Date.parse(row1.data[sortColumnId]?.value as string) : Infinity;
      const second: number = row2.data[sortColumnId] ? Date.parse(row2.data[sortColumnId]?.value as string) : Infinity;
      return sortComparator(first, second, sortOrder);
    });
  }
  if (typeOfColumn === 'date') {
    filteredRows.sort((row1, row2) => {
      const first: number = row1.data[sortColumnId]
        ? Date.parse(new Date(row1.data[sortColumnId]?.value as string).toDateString())
        : Infinity;
      const second: number = row2.data[sortColumnId]
        ? Date.parse(new Date(row2.data[sortColumnId]?.value as string).toDateString())
        : Infinity;
      return sortComparator(first, second, sortOrder);
    });
  }
  if (typeOfColumn === 'time') {
    filteredRows.sort((row1, row2) => {
      const first: number = row1.data[sortColumnId]
        ? Date.parse(getTimeValueIgnoredDate(row1.data[sortColumnId]?.value as string))
        : Infinity;
      const second: number = row2.data[sortColumnId]
        ? Date.parse(getTimeValueIgnoredDate(row2.data[sortColumnId]?.value as string))
        : Infinity;
      return sortComparator(first, second, sortOrder);
    });
  }
  return [...filteredRows, ...filteredEmptyRows];
};

export const getIsInteractive = (selectedSettings: { interactive: boolean }[]) => {
  if (selectedSettings.length > 1) {
    const isAllSettingsHasSameValue = selectedSettings.every((setting, _, array) => setting.interactive === array[0].interactive);
    return isAllSettingsHasSameValue ? selectedSettings[0].interactive : undefined;
  }
  return selectedSettings[0]?.interactive ?? undefined;
};
