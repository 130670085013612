import OrganisationPlanTemplateLayout from 'modules/organisation-plan-templates/layouts/OrganisationPlanTemplateLayout';
import { ORGANISATION_PLAN_PREVIEW_ROUTES } from './constants';
import OrganisationPlanPreview from './layouts/OrganisationPlanPreviewLayout';

export const OrganisationPlanRoutes = {
  path: ORGANISATION_PLAN_PREVIEW_ROUTES.ROOT_LINK,
  element: <OrganisationPlanTemplateLayout />,
  children: [
    {
      path: ORGANISATION_PLAN_PREVIEW_ROUTES.LINK_BY_TOKEN,
      element: <OrganisationPlanPreview />
    }
  ]
};
