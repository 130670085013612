import { Layout } from 'react-grid-layout';
import { PayloadAction, current } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import { v4 as uuidv4 } from 'uuid';
import { ILinkObjectForSelect } from 'modules/builder/components/SettingsBar/LinkingTab/types';
import { DROPPING_ELEMENT } from 'modules/builder/constants';
import { BUILDER_WIDGET_TYPES, WidgetsType } from 'modules/builder/types';
import { updateIsHiddenForAllWidgetsOnCurrentPage } from 'modules/questionnaires/utils/linkingDataTransform';
import { addAndOrderLayoutElement } from 'shared/utils/addAndOrderLayoutElement';
import { createInitLabels } from 'modules/builder/utils/builder-widgets/singleChoice';
import { createInitPhotos } from 'modules/builder/utils/builder-widgets/choiseImage';
import { MultipleChoiceWidgetType, SingleChoiceWidgetType } from 'modules/builder/types/choiceWidgetsType';
import { MultipleImgChoiceWdigetType, SingleImgChoiceWidgetType } from 'modules/builder/types/imageWidgetsTypes';
import { getPageIds } from 'shared/utils/getPageIds';
import { filterLayoutAndWidgetByVisibility } from 'modules/questionnaires/utils/filterLayout';
import {
  IAddLayoutWithWidgetsForPreviewPayload,
  IAddNewELementPayload,
  IBuilderState,
  ICheckHasDataChanges,
  ISetInitialDataPayload,
  IUpdateChoiceWidgetAnswerPayload,
  IUpdateWidgetPayload,
  updateLayoutPartiallyPayloadType
} from './buider.interfaces';
import { linksEntityAdapter, usedWidgetsEntityAdapter } from './builder.slice';

export const checkHasDataChanges = (
  state: IBuilderState,
  { payload: { layout, widgets, links, articles } }: PayloadAction<ICheckHasDataChanges>
) => {
  let changedFields = [...state.initialState.changedFields];

  if (widgets) {
    const allWidgetsForCurrentPageWidget = usedWidgetsEntityAdapter.getSelectors().selectAll(state.usedWidgets);
    const pureWidgets = current(state.initialState.widgets);
    const isEqualWidgets = isEqual(pureWidgets, allWidgetsForCurrentPageWidget);

    if (isEqualWidgets) {
      changedFields = changedFields.filter((field) => field !== 'widgets');
    } else if (!changedFields.includes('widgets')) {
      changedFields.push('widgets');
    }
  }

  if (layout) {
    const filteredData = state.layout.map((obj) => {
      return pickBy(obj, (v) => v !== undefined);
    });
    const pureLayout = current(state.initialState.layout).map((obj) => {
      return pickBy(obj, (v) => v !== undefined);
    });
    const isEqualLayouts = isEqual(pureLayout, filteredData);

    if (isEqualLayouts) {
      changedFields = changedFields.filter((field) => field !== 'layouts');
    } else if (!changedFields.includes('layouts')) {
      changedFields.push('layouts');
    }
  }

  if (links) {
    const allWidgetsForCurrentPageLinks = linksEntityAdapter
      .getSelectors()
      .selectAll(state.links)
      .filter((obj: ILinkObjectForSelect) => obj.source.hasOwnProperty('optionId'));
    const pureLinks = state.initialState.links.filter((obj: ILinkObjectForSelect) => obj.source.hasOwnProperty('optionId'));

    const isEqualLinks = isEqual(pureLinks, allWidgetsForCurrentPageLinks);

    if (isEqualLinks) {
      changedFields = changedFields.filter((field) => field !== 'links');
    } else if (!changedFields.includes('links')) {
      changedFields.push('links');
    }
  }

  if (articles) {
    const allWidgetsForCurrentPageArticles = state.usedArticles;
    const pureArticles = current(state.initialState.articles);
    const isEqualLinks = isEqual(pureArticles, allWidgetsForCurrentPageArticles);

    if (isEqualLinks) {
      changedFields = changedFields.filter((field) => field !== 'articles');
    } else if (!changedFields.includes('articles')) {
      changedFields.push('articles');
    }
  }

  state.initialState.changedFields = changedFields;
  state.isDataChanged = changedFields.length > 0;
};

export const updateBuilderLayoutReducer = (state: IBuilderState, { payload }: PayloadAction<Layout[]>) => {
  if (payload?.find((i) => i.i === DROPPING_ELEMENT)) {
    state.layout = payload;
    return;
  }
  const sortedLayout = [...payload].sort((layout1, layout2) => layout1.y - layout2.y);
  state.layout = [...sortedLayout];
  checkHasDataChanges(state, { type: 'checkHasDataChanges', payload: { layout: true } });
};

export const updateBuilderLayoutPartiallyReducer = (state: IBuilderState, { payload }: PayloadAction<updateLayoutPartiallyPayloadType>) => {
  const newLayout = state.layout.map((layout) => {
    if (layout.i === payload.i) {
      return {
        ...layout,
        ...payload
      };
    }
    return layout;
  });
  const sortedLayout = newLayout.sort((layout1, layout2) => layout1.y - layout2.y);
  state.layout = [...sortedLayout];
  checkHasDataChanges(state, { type: 'checkHasDataChanges', payload: { layout: true } });
};

export const setInitialDataReducer = (
  state: IBuilderState,
  { payload: { layout, widgets, articles } }: PayloadAction<ISetInitialDataPayload>
) => {
  state.layout = layout;
  const isTopBannerExist = !!widgets.find((el) => el.type === BUILDER_WIDGET_TYPES.topBanner);
  state.isTopBannerAlreadyExist = isTopBannerExist;
  state.initialState.layout = layout;
  state.initialState.widgets = widgets;
  if (articles) state.initialState.articles = articles;
  usedWidgetsEntityAdapter.setAll(state.usedWidgets, widgets);
};

export const addLayoutWithWidgetsForPreviewReducer = (
  state: IBuilderState,
  { payload: { allPages, currentPage, layout, questionnaireSource } }: PayloadAction<IAddLayoutWithWidgetsForPreviewPayload>
) => {
  const widgets = currentPage.usedWidgets ?? [];
  const { pageId, subPageId } = getPageIds(currentPage);
  const pageIdStirng = pageId.toString();
  const subPageIdString = typeof subPageId === 'number' ? subPageId?.toString() : undefined;
  const links = linksEntityAdapter.getSelectors().selectAll(state.links);
  const allWidgetForPage = updateIsHiddenForAllWidgetsOnCurrentPage(widgets, links, allPages, pageIdStirng, subPageIdString);
  /* filter by visibility done after filtering by linking because 
  in visibility case - widget or page that hidden 
  by visibility should stil do impact */
  const { layout: filteredLayout, widgets: filteredWidgets } = filterLayoutAndWidgetByVisibility({
    isPreview: true,
    layout: layout,
    questionnaireSource: questionnaireSource,
    widgets: allWidgetForPage
  });
  state.layout = filteredLayout;
  usedWidgetsEntityAdapter.setAll(state.usedWidgets, filteredWidgets);
  state.isCalculateNeed = true;
};

export const deleteWidgetReducer = (state: IBuilderState, { payload: id }: PayloadAction<WidgetsType['widgetId']>) => {
  if (state.usedWidgets.entities[id]?.type === BUILDER_WIDGET_TYPES.topBanner) {
    state.isTopBannerAlreadyExist = false;
  }
  state.layout = state.layout.filter((layout) => layout.i !== id);
  usedWidgetsEntityAdapter.removeOne(state.usedWidgets, id);
  state.aciveWidgetForSettings = null;
  if (state.usedArticles?.find((i) => i.widgetId === id)) {
    state.usedArticles = state.usedArticles.filter((i) => i.widgetId !== id);
  }

  checkHasDataChanges(state, { type: 'checkHasDataChanges', payload: { widgets: true } });
};

export const setInitialStateReducer = (
  state: IBuilderState,
  { payload: { layout, widgets, links, articles } }: PayloadAction<ICheckHasDataChanges>
) => {
  if (widgets) {
    state.initialState.widgets = usedWidgetsEntityAdapter.getSelectors().selectAll(state.usedWidgets);
  }

  if (layout) {
    state.initialState.layout = state.layout;
  }
  if (links) {
    state.initialState.links = linksEntityAdapter.getSelectors().selectAll(state.links);
  }

  if (articles) {
    state.initialState.articles = state.usedArticles;
  }

  state.initialState.changedFields = [];
  state.isDataChanged = false;
};

export const addNewElementReducer = (state: IBuilderState, { payload: { layout, widget } }: PayloadAction<IAddNewELementPayload>) => {
  const widgetId = uuidv4();
  const { layout: updatedLayout } = addAndOrderLayoutElement(state.layout, layout, widgetId);
  if (widget?.type === BUILDER_WIDGET_TYPES.topBanner) {
    usedWidgetsEntityAdapter.addOne(state.usedWidgets, { ...widget, widgetId } as WidgetsType);
    state.isTopBannerAlreadyExist = true;
    state.isDataChanged = true;
    return;
  }

  state.layout = updatedLayout;
  if (widget?.type === BUILDER_WIDGET_TYPES.singleChoice || widget?.type === BUILDER_WIDGET_TYPES.multipleChoice) {
    usedWidgetsEntityAdapter.addOne(state.usedWidgets, {
      ...widget,
      widgetId,
      optionLabels: createInitLabels()
    } as WidgetsType);
    return;
  }
  if (widget?.type === BUILDER_WIDGET_TYPES.singleImageChoice || widget?.type === BUILDER_WIDGET_TYPES.multipleImageChoice) {
    usedWidgetsEntityAdapter.addOne(state.usedWidgets, {
      ...widget,
      widgetId,
      dataImage: createInitPhotos()
    } as WidgetsType);
    return;
  }
  usedWidgetsEntityAdapter.addOne(state.usedWidgets, { ...widget, widgetId } as WidgetsType);
};

export const updateWidgetReducer = (state: IBuilderState, { payload: { widgetId, widget } }: PayloadAction<IUpdateWidgetPayload>) => {
  const prevWidgetsState = { ...state.usedWidgets.entities[widgetId] };
  usedWidgetsEntityAdapter.updateOne(state.usedWidgets, {
    id: widgetId,
    changes: { ...prevWidgetsState, ...widget } as WidgetsType
  });
  checkHasDataChanges(state, { type: 'checkHasDataChanges', payload: { widgets: true } });
};

export const updateChoiceWidgetAnswerReducer = (
  state: IBuilderState,
  { payload: { widgetId, widget, allPages, pageId, subPageId } }: PayloadAction<IUpdateChoiceWidgetAnswerPayload>
) => {
  const newWidgetData = { ...state.usedWidgets.entities[widgetId], ...widget };
  const allWidgetsForCurrentPage = usedWidgetsEntityAdapter.getSelectors().selectAll(state.usedWidgets);
  const allWidgetsWithUpdatedWidget = allWidgetsForCurrentPage.map((widget) => {
    if (widget.widgetId === newWidgetData.widgetId) return newWidgetData;
    return widget;
  }) as WidgetsType[];
  const links = linksEntityAdapter.getSelectors().selectAll(state.links);
  const result = updateIsHiddenForAllWidgetsOnCurrentPage(allWidgetsWithUpdatedWidget, links, allPages, pageId, subPageId);

  usedWidgetsEntityAdapter.setAll(state.usedWidgets, result);
  state.isCalculateNeed = true;
};

export const duplicateWidgetReducer = (state: IBuilderState, { payload: widgetId }: PayloadAction<WidgetsType['widgetId']>) => {
  const newId = uuidv4();
  const originalWidgetLayout = state.layout.find((layout) => layout.i === widgetId);
  const originalWidget = state.usedWidgets.entities[widgetId];
  if (!originalWidgetLayout || !originalWidget) {
    console.error(`missed widget with id: ${widgetId}`);
    return;
  }
  state.layout = [
    ...state.layout,
    {
      ...originalWidgetLayout,
      i: newId,
      y: originalWidgetLayout.y + 1,
      x: originalWidgetLayout.x
    }
  ];
  let duplicatedWidget = { ...originalWidget, widgetId: newId } as WidgetsType;
  const isWidgetTextChoice =
    originalWidget?.type === BUILDER_WIDGET_TYPES.singleChoice || originalWidget?.type === BUILDER_WIDGET_TYPES.multipleChoice;
  const isWidgetImgChoice =
    originalWidget?.type === BUILDER_WIDGET_TYPES.singleImageChoice || originalWidget?.type === BUILDER_WIDGET_TYPES.multipleImageChoice;
  if (isWidgetTextChoice) {
    duplicatedWidget = { ...duplicatedWidget } as SingleChoiceWidgetType | MultipleChoiceWidgetType;
    duplicatedWidget = {
      ...duplicatedWidget,
      optionLabels: duplicatedWidget.optionLabels.map(({ ...rest }) => {
        const newOptionId = uuidv4();
        return {
          ...rest,
          id: newOptionId
        };
      })
    };
  }
  if (isWidgetImgChoice) {
    duplicatedWidget = { ...duplicatedWidget } as SingleImgChoiceWidgetType | MultipleImgChoiceWdigetType;
    duplicatedWidget = {
      ...duplicatedWidget,
      dataImage:
        duplicatedWidget?.dataImage?.map(({ ...rest }) => {
          const newOptionId = uuidv4();
          return {
            ...rest,
            id: newOptionId
          };
        }) ?? null
    };
  }
  usedWidgetsEntityAdapter.addOne(state.usedWidgets, duplicatedWidget as WidgetsType);
};
