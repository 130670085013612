import { ITagInfo, ITags, WidgetsDashboardType } from 'modules/dashboard/types';
import { ITableDashboardWidgetColumn } from 'modules/dashboard/types/TableDashboardWidgetType';

interface IOldTagData {
  id: number;
  statisticType: string;
}

interface ITransformTagsParams {
  dataTags?: ITags[];
  widgetTags?: IOldTagData[] | ITagInfo[];
  fromSelectedTags?: IOldTagData[];
}

/**
 * Transforms tags to the needed format including already saved tags previously.
 *
 * @param {ITransformTagsParams} params - The tags data that need for transformation, including old and new.
 * @returns {ITagInfo[]} A list of tags after format transformation.
 */

export const transformTags = (params: ITransformTagsParams): ITagInfo[] => {
  const { dataTags, widgetTags, fromSelectedTags } = params;

  const tagsToTransform = widgetTags || fromSelectedTags;
  if (!dataTags || !tagsToTransform) {
    return [];
  }

  return (tagsToTransform as ITagInfo[]).reduce((accumulator: ITagInfo[], tag: any) => {
    const sourceTagId = typeof tag?.articleTagId === 'number' ? tag?.articleTagId : tag?.id;
    if ('statisticType' in tag) {
      const statisticType = tag.statisticType;
      accumulator.push({
        articleTagId: sourceTagId,
        statisticType
      } as ITagInfo);
    } else {
      accumulator.push(tag as ITagInfo);
    }
    return accumulator;
  }, []);
};

interface ITransformSelectedTagsParams {
  dataTags?: ITags[];
  selectedColumns: ITableDashboardWidgetColumn[];
}

/**
 * Transforms tags from the selected columns to the old format.
 *
 * @param {ITableDashboardWidgetColumn} params - The tags data that need for transformation.
 * @returns {IOldTagData[]} A list of unique tags from selectedColums.
 */

export const transformSelectedTags = (params: ITransformSelectedTagsParams): IOldTagData[] => {
  const { dataTags, selectedColumns } = params;

  return selectedColumns
    .filter((item) => item.type === 'tag')
    .map((column) => {
      const tagName = column.name;
      const foundedTagId = dataTags?.find((tag: ITags) => tag.name === tagName)?.id;
      return {
        id: foundedTagId!,
        statisticType: column.tagType as string
      };
    });
};

/**
 * util function to update field for tags that need for save widget to back-end
 * @param widget `WidgetsDashboardType`
 * @returns `WidgetsDashboardType`
 */
export const transferTagsBeforeSave = (widget: WidgetsDashboardType): WidgetsDashboardType => {
  const tags: WidgetsDashboardType['tags'] = widget.dashboardWidgetTags.map((tag) => ({
    id: tag.articleTagId,
    statisticType: tag.statisticType
  }));
  return { ...widget, tags };
};
